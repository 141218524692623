<template>
  <div class="col-12 col-xl-9 col-lg-8 col-md-12 widget">
   <finished-order-base></finished-order-base>
  </div>
</template>
<script>
import FinishedOrderBase from "./base/FinishedOrdersBase.vue"

export default {
  components: {
    FinishedOrderBase,
  },
};
</script>