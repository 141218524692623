<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget left-aligned">
    <div v-if="machine">
      <h1>
        {{ $t("491") }}
        <card-menu :items="items"></card-menu>
      </h1>
      <div class="row">
        <div class="col-6">
          <router-link
            :to="'/interface/' + machine_id + '/report-quantity'"
            class="fast-link-button indicator"
            :class="{
              'c-green': order.length > 0,
              'c-gray': order.length === 0,
            }"
          >
            <i class="fas fa-thumbs-up"></i>
          </router-link>
        </div>
        <div class="col-6">
          <router-link
            :to="'/interface/' + machine_id + '/scrap-quantity'"
            class="fast-link-button indicator"
            :class="{
              'c-green': order.length > 0,
              'c-gray': order.length === 0,
            }"
          >
            <i class="fas fa-thumbs-down"></i>
          </router-link>
        </div>
        <div class="col-6">
          <router-link
            :to="'/interface/' + machine_id + '/documents'"
            class="fast-link-button indicator"
            :class="{
              'c-green': order.length > 0,
              'c-gray': order.length === 0,
            }"
          >
            <i class="fas fa-file-alt"></i>
          </router-link>
        </div>
        <div class="col-6">
          <router-link
            :to="'/interface/' + machine_id + '/print-pallet-flag'"
            class="fast-link-button indicator"
            :class="{
              'c-green': order.length > 0,
              'c-gray': order.length === 0,
            }"
          >
            <i class="fas fa-print"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import { secondsToHhMm } from "@/helpers/dates";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 180,
    };
  },
  mounted() {
    this.pulse = new Pulse([
      {
        action: "machines/loadMachine",
        interval: 3,
        primaryKey: "machine_id",
        parameters: { machine_id: this.machine_id },
      },
      {
        action: "orders/loadRunningOrderForMachine",
        parameters: { machine_id: this.machine_id, reload: false },
        interval: this.interval,
        primaryKey: "machine_id",
      },
    ]);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
    }),
    ...mapGetters({ machineById: "machines/byId" }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    order() {
      return this.orders[this.machine_id] || [];
    },
    items() {
      return [];
    },
  },
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
  },
};
</script>