import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";
import { getStorage, setStorage, checkStorage } from "../../helpers/storage";
import { execute_json } from "@/helpers/requests";

const state = () => ({
    index: [],
    loaded: false
});

// getters
const getters = {

}

// actions
const actions = {
    loadSetup({ commit, rootState }) {
        if (state.loaded) {
            return new Promise((resolve) => {
                resolve();
            })
        } else {
            if (!checkStorage(rootState.auth.company_id + "_setup_loaded")) {
                return new Promise((resolve, reject) => {
                    execute_json("get_setup_types")
                        .then(({ data }) => {
                            //console.log(data,data[0]);
                            commit('setSetup', {
                                data: data,
                                company_id: rootState.auth.company_id
                            });
                            resolve();
                        })
                        .catch((err) => {
                            reject(err);
                        })
                });
            } else {
                return new Promise((resolve) => {
                    commit('setSetup', {
                        data: getStorage(rootState.auth.company_id + "_setup_loaded"),
                        company_id: rootState.auth.company_id
                    });
                    resolve();
                })
            }
        }
    }
}
// mutations
const mutations = {
    setSetup(state, { data, company_id }) {
        setStorage(company_id + "_setup_loaded", data);
        state.index = data;
        state.loaded = true;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}