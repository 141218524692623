<template>
  <svg
    v-if="points !== null"
    :width="bodyLength + headLength"
    :height="Math.max(bodyWidth, headWidth)"
    :viewBox="
      '0 0 ' + (bodyLength + headLength) + ' ' + Math.max(bodyWidth, headWidth)
    "
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient :id="cid">
        <stop
          :offset="stop.offset"
          :stop-color="stop.color"
          v-for="(stop, i) in stops"
          :key="i"
        ></stop>
      </linearGradient>
    </defs>
    <polygon :points="points" :fill="'url(#' + cid + ')'" stroke="black" stroke-width="2px" />
  </svg>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    colors: {
      default() {
        return [{ color: "green", per: 1 }];
      },
    },
    fuzzy: {
      default: false,
    },
    bodyWidth: {
      default: 65,
    },
    bodyLength: {
      default: 600,
    },
    headLength: {
      default: 100,
    },
    headWidth: {
      default: 140,
    },
  },
  data() {
    return {
      cid: _.uniqueId("svg-arrow-"),
    };
  },
  computed: {
    stops() {
      let stops = [];
      let p = 0;
      for (let i = 0; i < this.colors.length; i++) {
        stops.push({
          offset: p + (this.fuzzy ? 0.02 : 0),
          color: this.colors[i].color,
        });
        p += this.colors[i].per;
        stops.push({
          offset: p + (this.fuzzy ? -0.02 : 0),
          color: this.colors[i].color,
        });
      }
      return stops;
    },
    points() {
      let hDiffBy2 = (this.headWidth - this.bodyWidth) / 2;
      let h = Math.max(this.headWidth, this.bodyWidth);
      let b = this.headLength + this.bodyLength;
      let bl = this.bodyLength;
      let nan = [hDiffBy2, h, b, bl].some((i) => isNaN(i));
      if (nan) {
        return null;
      }
      let bw = this.bodyWidth;

      return `0,${hDiffBy2} ${bl},${hDiffBy2} ${bl},0 ${b},${
        h / 2
      } ${bl},${h} ${bl},${h - hDiffBy2} 0,${h - hDiffBy2} 0,${hDiffBy2}`;
    },
  },
};
</script>

<style>
</style>