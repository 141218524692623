import defaultLayout from '@/layouts/DefaultLayout'

function loadView(view) {
    return () => import(`@/views/${view}.vue`)
}

export const overviewRoutes = [
    {
        path: "/overview/:id",
        name: "overview view",
        meta: {
            requiresAuth: true,
            shouldRemember:true,
            layout: defaultLayout
        },
        component:loadView("overview/view"),
       
    }
];
