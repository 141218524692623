<template>
  <div id="popup-central">
    <div
      class="popup-wrap"
      v-if="hasActivePopup || hasActiveNotification"
      @click.self="tryClose"
    >
      <transition-group name="popup">
        <component
          v-if="activePopup"
          :is="activePopup.type"
          :data="activePopup.data"
          :key="activePopup.id"
          @close="closeActivePopup"
        />
        <popup-notification
          v-else-if="activeNotification"
          :notification="activeNotification"
          :key="activeNotification.id"
          @close="deActivateNotification(activeNotification.id)"
        ></popup-notification>
      </transition-group>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import * as PopupComponents from "./popups";
import _ from "lodash";
import store from "@/store";

export default {
  data() {
    return {
      intervalId: null,
    };
  },
  mounted() {},
  methods: {
    closeActivePopup() {
      store.commit("popups/closeActive");
    },
    tryClose() {
      if (this.hasActivePopup) {
        if (this.activePopup.canClose) {
          this.closeActivePopup();
        }
      } else if (this.hasActiveNotification) {
        this.deActivateNotification(this.activeNotification.id);
      }
    },
    deActivateNotification(id) {
      if (!this.hasActivePopup) {
        store.commit("notifications/deActivate", { id });
      }
    },
  },
  computed: {
    ...mapState({
      notifications: (state) => state.notifications.index,
      popupIndex: (state) => state.popups.index,
    }),
    ...mapGetters({}),
    hasActivePopup() {
      return this.popupIndex.length > 0;
    },
    activePopup() {
      return this.hasActivePopup ? this.popupIndex[0] : null;
    },
    popupNotifications() {
      return this.notifications.filter((n) => n.active && n.popup);
    },
    hasActiveNotification() {
      return this.popupNotifications.length > 0;
    },
    activeNotification() {
      return this.hasActiveNotification ? this.popupNotifications[0] : null;
    },
  },
  components: {
    ...PopupComponents,
  },
};
</script>