<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget left-aligned">
    <div>
      <h1>I listen to stop events</h1>
      <pre>
        {{events.map(e => e.event_message)}}
      </pre>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { mapState} from "vuex";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 180,
      events:[]
    };
  },
  mounted() {
    store.commit("events/addHandler",{event:"stopWO",handler:this.handleEvent});
  },
  beforeUnmount() {
  },
  methods:{
    handleEvent(e){
      this.events.push(e);
    }
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    })
  },
  components: {
    CardMenu,
  },
};
</script>