<template>
  <div class="stopcode-singular">
    <input
      type="text"
      v-model="note"
      placeholder="Add a note..."
      class="white-text"
    />
    <p>{{ historic_stops.length }} stops selected</p>

    <div class="buttons-wrap">
      <a
        href="#"
        class="router-button"
        @click.prevent="back"
        :disabled="loading"
        ><i class="fas fa-chevron-left"></i
      ></a>
      <a
        href="#"
        class="router-button"
        @click.prevent="noteHistory"
        :disabled="loading"
      >
        <loading v-if="loading" :step="step" :maxStep="maxStep" />
        <span v-else>{{ $t("43") }}</span>
      </a>
    </div>

    <keyboard v-model="note" />
  </div>
</template>

<script>
//import { useRouter } from 'vue-router';
import { mapState } from "vuex";
import { allProgress } from "@/helpers/promises";
import store from "@/store";
import _ from "lodash";

export default {
  props: {},
  setup() {},
  data() {
    return {
      note: "",
      loading: false,
      step: 0,
    };
  },
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      historic_stops: (state) => state.bottomnav.data,
    }),
    routerlinks() {
      let viewtype = this.$route.params.viewtype || "machines";
      return {
        codeHistory:
          "/" +
          viewtype +
          "/" +
          this.$route.params.locationtype +
          "/" +
          this.$route.params.location +
          "/history/" +
          this.$route.params.machine +
          "/code",
      };
    },
    uncoded_stopcodes() {
      //return "test";
      return _.map(this.historic_stops, "stop").join(",");
    },
    notable() {
      return !this.historic_stops.map((s) => s.stop_reason).includes("-");
    },
  },
  methods: {
    noteHistory() {
      let promises = [];
      this.loading = true;
      for (let i = 0; i < this.historic_stops.length; i++) {
        let historic_stop = this.historic_stops[i];
        promises.push(
          store.dispatch("uncoded/updateHistoryNote", {
            historic_stop,
            note: this.note,
          })
        );
      }
      this.maxStep = promises.length - 1;
      //allProgress instead of Promise.all
      allProgress(promises, (p) => {
        this.step = p;
      }).then(() => {
        store.dispatch("machines/loadHistoryForMachine", {
          machine_id: this.$route.params.machine,
          period: 7,
        });
        console.log("done");
        store.commit("bottomnav/disable");
        store.commit("config/activateBTable", "-");
        this.loading = false;
      });
    },
    back() {
      store.commit("bottomnav/setType", "history-multiple");
    },
  },
  components: {},
};
</script>

<style lang="scss">
</style>
