import defaultLayout from '@/layouts/DefaultLayout'

function loadView(view) {
  return () => import (/* webpackChunkName: "[request]" */ `@/views/${view}.vue`)
}

export const stopRoutes = [
  {
    path:'/:viewtype/:locationtype/:location/stopcode/:machine',
    name:"stopcode",
    meta:{
      requiresAuth: true,
      layout: defaultLayout,
      title: "stopcode"
    },
    component: loadView("stops/stopcode")
  },
  {
    path:'/:viewtype/:locationtype/:location/uncoded/:machine',
    name:"uncoded",
    meta:{
      requiresAuth: true,
      layout: defaultLayout,
      title: "uncoded"
    },
    component: loadView("stops/uncoded-stops")
  },
  {
    path:'/:viewtype/:locationtype/:location/uncoded/:machine/code',
    name:"code-uncoded",
    meta:{
      requiresAuth: true,
      layout: defaultLayout,
      title: "uncoded - Machine"
    },
    component: loadView("stops/code-uncoded")
  },
  {
    path:'/:viewtype/:locationtype/:location/time-history/code',
    name:"code-uncoded-time-history-location",
    meta:{
      allBehavior:"deny",
      requiresAuth: true,
      layout: defaultLayout,
      title: "uncoded - Machine"
    },
    component: loadView("stops/code-uncoded")
  },
  {
    path:'/:viewtype/:locationtype/:location/history/:machine',
    name:"history",
    meta:{
      requiresAuth: true,
      layout: defaultLayout,
      title: "history"
    },
    component: loadView("stops/history-stops")
  },
  {
    path:'/:viewtype/:locationtype/:location/uncoded-location',
    name:"uncoded-stops-location",
    meta:{
      allBehavior:"deny",
      requiresAuth: true,
      layout: defaultLayout,
      title: "uncoded stops location",
      canHandleMine:true
    },
    component: loadView("stops/uncoded-stops-location")
  },
  {
    path:'/:viewtype/:locationtype/:location/uncoded-location/code',
    name:"code-uncoded-location",
    meta:{
      allBehavior:"deny",
      requiresAuth: true,
      layout: defaultLayout,
      title: "uncoded - Location"
    },
    component: loadView("stops/code-uncoded")
  },
  {
    path:'/:viewtype/:locationtype/:location/history/:machine/code',
    name:"code-history",
    meta:{
      requiresAuth: true,
      layout: defaultLayout,
      title: "history"
    },
    component: loadView("stops/code-uncoded")
  }
];
