import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";

const state = () => ({
    list: [],
});

// getters
const getters = {
    byId: (state) => (id) => {
        return state.list.find(zone => zone.code === id) || { empty: true };
    }
}

// actions
const actions = {
    loadZoneList({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            execute_json("get_location_type", {
                location_type: "zone"
            })
                .then(({ data }) => {
                    commit('setZoneList', {
                        data: data,
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })

        });
    }
}
// mutations
const mutations = {
    setZoneList(state, { data }) {
        state.list = data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}