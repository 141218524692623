import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";

const state = () => ({
    list: [],
    runtimeList: []
});

// getters
const getters = {
    runtimePerMachine: (state) => (machine_id) => {
        let i =0;
        return state.runtimeList.filter(r => r.machine_id == machine_id).map(r => {

            return {
                ...r,
                shift_desc:state.list.find(l => l.value == r.shift)?.description,
                key:i++
            }
        })
    }
}

// actions
const actions = {
    loadShiftList({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            execute_json("get_shifts")
                .then(({ data }) => {
                    commit('setShiftList', {
                        data,
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })

        });
    },
    loadRuntimeByShift({ commit }, { selection_criteria, selection_value }) {
        return new Promise((resolve, reject) => {
            execute_json("get_runtime_by_criteria", {
                selection_criteria,
                selection_value,
                grouped_by_machine: true,
                debug: true
            })
                .then(({ data }) => {
                    commit('setRuntimeList', {
                        data,
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })

        });
    }
}
// mutations
const mutations = {
    setShiftList(state, { data }) {
        state.list = data.map(i => {
            return {
                value: i.shift_code,
                description: i.shift_description
            }
        });
    },
    setRuntimeList(state, { data }) {
        state.runtimeList = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}