<template>
  <canvas
    :id="'chart-' + cid"
    :key="cid"
    :class="{ loading }"
    style="width: 100%; height: 100%"
  ></canvas>
</template>
<script>
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { mapState, mapGetters } from "vuex";
import { typeFunctions, typeComputed } from "@/helpers/graph/types";
import { labelFunctions } from "@/helpers/graph/labels";
import { graphDataFunctions, graphDataSetup } from "@/helpers/graph";
import store from "@/store";
import _ from "lodash";

Chart.plugins.register({
  afterDraw: function (chart) {
    if (
      chart &&
      chart.data &&
      chart.data.datasets?.length &&
      chart.data.datasets.every(d => d.data.every((item) => item === 0))
    ) {
      let ctx = chart.chart.ctx;
      let width = chart.chart.width;
      let height = chart.chart.height;

      chart.clear();
      ctx.save();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText("No data to display", width / 2, height / 2);
      ctx.restore();
    } else if (chart && chart.data && !chart.data.datasets?.length) {
      let ctx = chart.chart.ctx;
      let width = chart.chart.width;
      let height = chart.chart.height;

      chart.clear();
      ctx.save();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText("No data to display", width / 2, height / 2);
      ctx.restore();
    }
  },
});

Chart.defaults.global.legend.onClick = function (e, legendItem) {
  var index = legendItem.datasetIndex;
  var ci = this.chart;
  console.log(e, ci);
  if (!e.ctrlKey) {
    // Do the original logic
    var meta = ci.getDatasetMeta(index);

    // See controller.isDatasetVisible comment
    meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

    // We hid a dataset ... rerender the chart
    ci.update();
  } else {
    for (let i = 0; i < ci.legend.legendItems.length; i++) {
      if (i !== index) {
        let meta = ci.getDatasetMeta(i);
        meta.hidden = meta.hidden === null ? !ci.data.datasets[i].hidden : null;
      }
    }
    ci.update();
  }
};
Chart.defaults.clampLine = Chart.helpers.clone(Chart.defaults.line);
Chart.controllers.clampLine = Chart.controllers.line.extend({
  update: function () {
    // get the min and max values
    //var min = Math.min.apply(null, this.chart.data.datasets[0].data);
    var max = Math.max.apply(null, this.chart.data.datasets[0].data);
    var yScale = this.getScaleForId(this.getDataset().yAxisID);

    // figure out the pixels for these and the value 0
    var top = yScale.getPixelForValue(max);
    var green = yScale.getPixelForValue(
      this.chart.data.datasets[0].greenLimit || 90
    );
    var yellow = yScale.getPixelForValue(
      this.chart.data.datasets[0].yellowLimit || 50
    );
    var bottom = yScale.getPixelForValue(0);
    // build a gradient that switches color at the 0 point
    var ctx = this.chart.chart.ctx;
    if (bottom !== top && isFinite(bottom) && isFinite(top)) {
      var gradient = ctx.createLinearGradient(0, top, 0, bottom);
      var ratioGreen = _.clamp((green - top) / (bottom - top), 0, 1);
      var ratioYellow = _.clamp((yellow - top) / (bottom - top), 0, 1);
      gradient.addColorStop(0, "rgba(0,255,0,0.20)");
      gradient.addColorStop(ratioGreen, "rgba(0,255,0,0.20)");
      gradient.addColorStop(ratioGreen, "rgba(255,255,0,0.20)");
      gradient.addColorStop(ratioYellow, "rgba(255,255,0,0.20)");
      gradient.addColorStop(ratioYellow, "rgba(255,0,0,0.20)");
      gradient.addColorStop(1, "rgba(255,0,0,0.20)");
      this.chart.data.datasets[0].backgroundColor = gradient;
    } else {
    }

    return Chart.controllers.line.prototype.update.apply(this, arguments);
  },
});

export default {
  emits: ["remount", "clickOnGraph"],
  props: {
    task: {},
    type: {},
    period: {},
    machine: {},
    locationtype: {},
    location: {},
    source: {
      default: "machine",
    },
    labelMode: {
      default: "percentages",
    },
    addRuntime: {
      default: true,
    },
    reload: {
      default: false,
    },
    interval: {
      default: null,
    },
    tooltipsEnabled: {
      default: true,
    },
    tlabel: {
      default: null,
    },
    fullscreen: {
      default: false,
    },
    labelStyle: {
      default: null,
    },
    animationDuration: {
      default: 650,
    },
    disableLegend: {
      default: false,
    },
    uses: {
      default: null,
    },
    axisColor: {
      default: "#666",
    },
    gridColor: {
      default: "rgba(0, 0, 0, 0.1)",
    },
    axisLabelColor: {
      default: "#666",
    },
    maxN: {
      default: null,
    },
    fontSize:{
      default:12
    },
    goal:{
      default:null
    }
  },
  data() {
    return {
      cid: _.uniqueId("graph"),
      loaded: false,
      loading: false,
      ctx: null,
      chart: null,
      drawn: false,
      onMobile: true,
      intervalJitter: Math.floor(Math.random() * 10),
      updateInterval: null,
      requests: {
        ...graphDataSetup,
      },
      data: null,
      rawData: null,
    };
  },
  mounted() {
    this.drawn = false;
    this.ctx = document.getElementById("chart-" + this.cid);
    if (this.interval !== null) {
      let updateAndDraw = this.updateAndDraw;
      console.log("adding interval...");
      this.updateInterval = setInterval(function () {
        console.log("calling update from interval");
        updateAndDraw();
      }, (this.interval + this.intervalJitter) * 1000);
    }
    this.loadAndDraw();
  },
  beforeUnmount() {
    if (this.updateInterval) {
      console.log("clearing interval...");
      clearInterval(this.updateInterval);
    }
  },
  methods: {
    ...typeFunctions,
    ...labelFunctions,
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    loadAndDraw() {
      this.loadData()
        .then(() => {
          this.loaded = true;
          this.drawChart();
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    updateAndDraw() {
      this.updateData()
        .then(() => {
          this.loaded = true;
          this.drawChart();
          console.log("redrew chart after interval");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    loadData() {
      return new Promise((resolve, reject) => {
        if (this.current) {
          this.loading = true;
          this[this.current.handler]()
            .then(() => {
              this.loading = false;
              resolve();
            })
            .catch(() => {
              this.loading = false;
              console.log("caught loadData")
            });
        } else {
          console.log("no current");
          reject();
        }
      });
    },
    updateData() {
      return new Promise((resolve, reject) => {
        if (this.current && this[this.current.updateHandler] !== undefined) {
          //console.warn("calling update..");
          this[this.current.updateHandler]()
            .then(() => {
              //console.warn("did the update",this.data);
              resolve();
            })
            .catch((err) => {
              console.warn(err);
              this.loading = false;
              reject();
            });
        } else {
          console.log("no update handler, loading as normal");
          this.loadData().then(()=>{
            resolve();
          }).catch((err)=>{
            reject();
          })
        }
      });
    },
    ...graphDataFunctions,
    drawChart() {
      if (!this.drawn) {
        this.draw();
      } else {
        this.reDraw();
      }
    },
    draw() {
      try {
        this.chart = new Chart(this.ctx, {
          plugins: [ChartDataLabels],
          type: this.uses ? this.uses : this.type,
          data: {
            labels: this.labels,
            datasets: this.datasets,
          },
          options: this.options,
        });
        this.drawn = true;
      } catch (error) {
        console.warn("graph got an error:", error);
      }
    },
    reDraw() {
      this.chart.type = this.type;
      this.chart.data.labels = this.labels;
      this.chart.data.datasets = this.datasets;
      this.chart.options = this.options;
      this.chart.update();
    },
    destroyAndRenew() {
      //prevent multiple calls in a row
      if (this.chart) {
        this.chart.destroy();
        this.drawn = false;
        let monitors = this.$el
          .closest("div")
          .querySelectorAll(".chartjs-size-monitor");
        if (monitors) {
          monitors.forEach((e) => e.remove());
        }
        let canvas = document.getElementById("chart-" + this.cid);
        canvas.removeAttribute("height");
        canvas.removeAttribute("width");
        canvas.removeAttribute("style");
        console.warn("destroyed that graph...", monitors);
      }
      this.drawChart();
      //this.chart.chart.update();
    },
    valueFormatter(val) {
      return this[this.labelMode + "Formatter"]
        ? this[this.labelMode + "Formatter"](val)
        : val;
    },
    labelFormatter(tooltipItem, data) {
      if (this[this.type + "TooltipLabelFormatter"] !== undefined) {
        return this[this.type + "TooltipLabelFormatter"](tooltipItem, data);
      }
      let val = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
      let label = this.labels[tooltipItem.index];
      return label + ": " + this.valueFormatter(val);
    },
    defaultLabels(chart) {
      let data = chart.data;
      let formatter = this.valueFormatter;
      let labelColor = this.axisLabelColor;
      if (data.labels.length && data.datasets.length) {
        return data.labels.map(function (label, i) {
          let meta = chart.getDatasetMeta(0);
          let ds = data.datasets[0];
          let arc = meta.data[i];
          let custom = (arc && arc.custom) || {};
          let getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
          let arcOpts = chart.options.elements.arc;
          let fill = custom.backgroundColor
            ? custom.backgroundColor
            : getValueAtIndexOrDefault(
                ds.backgroundColor,
                i,
                arcOpts.backgroundColor
              );
          let stroke = custom.borderColor
            ? custom.borderColor
            : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
          let bw = custom.borderWidth
            ? custom.borderWidth
            : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

          // We get the value of the current label
          let value =
            chart.config.data.datasets[arc._datasetIndex].data[arc._index];

          let formatted = formatter(value);
          return {
            // Instead of `text: label,`
            // We add the value to the string
            text: formatted + " | " + label,
            color: "white",
            fillStyle: fill,
            strokeStyle: labelColor,
            lineWidth: bw,
            hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
            index: i,
          };
        });
      } else {
        return [];
      }
    },
  },
  computed: {
    ...typeComputed,
    ...mapState({
      isMobile: (state) => state.isMobile,
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({
      configByMachine: "machines/configByMachine",
    }),
    machine_id() {
      if (this.source !== "machine") {
        return null;
      }
      return this.machine.machine_id;
    },
    combinedLocation() {
      if (this.source !== "machine") {
        return this.locationtype + "|" + this.location;
      }
      return undefined;
    },
    config() {
      return this.configByMachine(this.machine_id);
    },
    current() {
      return this.requests[this.task][this.period] || null;
    },
    orderedData() {
      let data = this.limitedDataSet;
      //console.warn("data might be limtied",data);
      return this[this.type + "DataHandler"]
        ? this[this.type + "DataHandler"](data)
        : data.filter((i, index, array) => i[this.current.valueKey] != "0");
    },
    limitedDataSet() {
      if (this.maxN == null) {
        return this.data;
      } else {
        let data = this.data;
        let orderedByValues = _.orderBy(
          data.filter((i) => i[this.current.valueKey] != "0"),
          [
            (item) => {
              return +item[this.current.valueKey];
            },
          ],
          ["desc"]
        );
        if (orderedByValues.length <= this.maxN) {
          //console.log("less then max",data,orderedByValues,this.current.valueKey);
          return orderedByValues;
        }
        let firstNMinusOne = orderedByValues.slice(0, this.maxN - 1);
        let lastOnes = orderedByValues.slice(
          this.maxN - 1,
          orderedByValues.length
        );
        let replacementRecord = lastOnes.reduce((a, b) => {
          a[this.current.valueKey] += b[this.current.valueKey];
          return {
            ...a,
            [this.current.colorKey]: "gray",
            [this.current.labelKey]: "other*",
          };
        });
        firstNMinusOne.push(replacementRecord);
        return firstNMinusOne;
      }
    },
    empty() {
      return this.orderedData.length === 0;
    },
    tcomp() {
      return this.tlabel
        ? this.tlabel
        : this.current
        ? this.current.tlabel
        : null;
    },
    labels() {
      let labels = _.map(this.orderedData, (i) => i[this.current.labelKey]);
      return labels;
    },
    options() {
      let formatterFunc = this.valueFormatter;
      let vm = this;
      return {
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            //anchor:"center",
            color: "#000000",
            align: this.alignDataLabels,
            offset: this.offsetDataLabels,
            display: this.displayDataLabels,
            formatter: function (value, context) {
              return formatterFunc(value, context.dataIndex);
            },
          },
        },
        animation: {
          duration: this.animationDuration,
        },
        scales: this.scales,
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: this.tooltipsEnabled,
          //mode:"label",
          callbacks: {
            title: () => {
              return "";
            },
            label: this.labelFormatter,
          },
        },
        legend: this.legend,
        onResize: function (chart, size) {
          chart.update();
        },
        onClick: function (event, active) {
          console.warn(event, active, this, this.chart.data);
          vm.$emit("clickOnGraph", {
            clicked: active,
            data: this.chart.data,
            chart: this,
            e: event,
          });
        },
      };
    },
    scales() {
      return this[this.type + "ScalesHandler"]
        ? this[this.type + "ScalesHandler"]
        : this.defaultScales;
    },
    defaultScales() {
      return {};
    },
    displayDataLabels() {
      return this[this.type + "DisplayDataLabels"] !== undefined
        ? this[this.type + "DisplayDataLabels"]
        : "auto";
    },
    offsetDataLabels() {
      return this[this.type + "OffsetDataLabels"] !== undefined
        ? this[this.type + "OffsetDataLabels"]
        : "6";
    },
    alignDataLabels() {
      return this[this.type + "AlignDataLabels"] !== undefined
        ? this[this.type + "AlignDataLabels"]
        : "end";
    },
    legend() {
      if (this.disableLegend) {
        return {
          display: false,
        };
      }
      return this[this.type + "Legend"] !== undefined
        ? this[this.type + "Legend"]
        : this.defaultLegend;
    },
    defaultLegend() {
      return {
        display: true,
        labels: {
          generateLabels: this.defaultLabels,
          fontColor: this.axisColor,
          fontSize:this.fontSize
        },
        position: this.isMobile ? "top" : "right",
        align: "start",
      };
    },
    colors() {
      if (this.current.colorKey === null) {
        return null;
      }
      let colors = _.map(this.orderedData, (i) =>
        i[this.current.colorKey].toLowerCase() === "automatic"
          ? "yellow"
          : i[this.current.colorKey].toLowerCase()
      );
      return colors;
    },
    coloring() {
      return this[this.task + this.type + "Coloring"] !== undefined
        ? this[this.task + this.type + "Coloring"]
        : {
            backgroundColor: this.colors,
          };
    },
    helpLines() {
      return this[this.task + this.source + "HelpLines"] !== undefined
        ? this[this.task + this.source + "HelpLines"]
        : [];
    },
    datasets() {
      if (this[this.type + "DataSetFormatter"] !== undefined) {
        return this[this.type + "DataSetFormatter"]();
      }
      let data = _.map(this.orderedData, (i) => +i[this.current.valueKey]);
      return [
        {
          yAxisID: "y-axis-0",
          label: this.period,
          data: data,
          ...this.coloring,
        },
        ...this.helpLines,
      ];
    },
  },
  watch: {
    machine_id() {
      this.loadAndDraw();
    },
    combinedLocation() {
      this.loadAndDraw();
    },
    period() {
      this.loadAndDraw();
    },
    type() {
      this.destroyAndRenew();
      //this.updateAndDraw();
    },
    labelMode() {
      this.drawChart();
    },
    disableLegend() {
      this.drawChart();
    },
    addRuntime() {
      this.loadAndDraw();
    },
    maxN() {
      this.drawChart();
    },
    isMobile() {
      Promise.resolve().then(() => {
        this.drawChart();
      });
    },
    fullscreen() {
      Promise.resolve().then(() => {
        this.drawChart();
      });
    },
    interval() {
      console.warn("interval has changed, changing updater");
      let updateAndDraw = this.updateAndDraw;
      clearInterval(this.updateInterval);
      this.updateInterval = setInterval(function () {
        updateAndDraw();
      }, (this.interval + this.intervalJitter) * 1000);
    },
  },
  components: {},
};
</script>