<template>
  <div class="popup">
    <div>
      <h1>
        {{ data.theader ? $t(data.theader) : data.header }}
      </h1>
      <p>{{ data.tmessage ? $t(data.tmessage) : data.message }}</p>
      <component :is="data.infoComponent" :data="data" v-if="data.infoComponent" />
    </div>

    <component
      :is="data.decisionComponent || 'yes-no'"
      :data="data"
      @resolve="resolve"
      @reject="reject"
    />
  </div>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapGetters } from "vuex";
import { execute_json } from "@/helpers/requests";
import { flattenSingleRow } from "@/requests/requestParcer";
import * as infoComponents from "./info";
import * as decisionComponents from "./decision";
import store from "@/store";

export default {
  props: ["data"],
  emits: ["close"],
  data() {
    return {
      timeout:null
    };
  },
  mounted(){
    if (this.data.timeout){
      let timeoutAction = this.data.timeoutAction;
      this.timeout = setTimeout((()=>{timeoutAction();this.justClose();}).bind(this),this.data.timeout*1000);
    }
  },
  methods: {
    resolve(data) {
      this.data.resolve(data);
      this.justClose();
    },
    reject(data) {
      this.data.reject(this.data.rejectMessage);
      this.justClose();
    },
    justClose() {
      console.log("closing...");
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
      signedInByMachine: "machines/signedInByMachine",
    }),
    machine_id() {
      return this.data.machine_id || this.data.order?.machine_id;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
  },
  watch: {},
  components: {
    ...infoComponents,
    ...decisionComponents,
  },
};
</script>