<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget left-aligned">
    <div v-if="machine">
      <h1>
        {{ $t("5853") }}
        <card-menu :items="items"></card-menu>
      </h1>
      <div class="row xl-sizing xl ">
        <div class="col-12 indicator c-green status-large" v-if="machine.part_no != '-'">
          <h1>{{ machine.part_no }}</h1>
          <h3 style="text-align:left;">{{ machine.part_description }}</h3>
          <h3 style="text-align:left;">{{$t('574')}}: {{machine.work_order_qty}}</h3>
        </div>
        <div class="col-12" v-else>
          <h3>{{$t('3011')}}</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import FlexButton from "@/components/flexbutton/FlexOrderButton";
import { Pulse } from "@/helpers/heartbeat";
import { secondsToHhMm } from "@/helpers/dates";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import { Configuration } from "@/helpers/settings";
import _ from "lodash";

export default {
  props: {
    template: {
      required: true,
    },
  },
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 180,
    };
  },
  mounted() {
    this.pulse = new Pulse({
      action: "machines/loadMachine",
      interval: 3,
      primaryKey: "machine_id",
      parameters: { machine_id: this.machine_id },
    });
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
      machinePath: (state) => state.auth.defaultpath,
      plannedOrders: (state) => state.orders.plannedOrders,
      lastLoc: (state) => state.sidebar.lastLocation,
      lastLocType: (state) => state.sidebar.lastLocationType,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      canUser: "permissions/canUser",
    }),
    canSaveWidgetSettings() {
      return this.canUser("save-widget-settings");
    },
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    items() {
      if (!this.canSaveWidgetSettings) {
        return [];
      }
      return [];
    },
  },
  components: {
    CardMenu,
    FlexButton,
  },
};
</script>