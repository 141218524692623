<template>
  <div class="accordeon-item" :class="{ active: active }">
    <div
      class="header"
      :class="{ done: modelValue && modelValue.isdone === true }"
    >
      <div class="question" @click="$emit('activate')">{{ item.question }}</div>
      <div class="icons" :class="{ notok: status === '4' }">
        <i class="fas fa-lock" v-if="locked"></i>
        <i class="fas fa-save" v-if="!saving && !locked && isChanged"></i>
        <i class="fas fa-spinner fa-spin" v-if="saving"></i>
        <i
          class="fas fa-file-pdf"
          v-if="item.document_link != '-1'"
          @click="$emit('pdf')"
        ></i>
        <i
          class="fas fa-check"
          v-if="
            modelValue === true || (modelValue && modelValue.isdone === true)
          "
        ></i>
        <i
          class="fas fa-times"
          v-if="
            modelValue === false || (modelValue && modelValue.isdone === false)
          "
        ></i>
      </div>
    </div>
    <div class="body" :class="componentName">
      <component
        v-if="componentName"
        :is="componentName"
        v-model="modelValue"
        ref="child"
        :item="item"
        @next="$emit('next')"
        @lock="lock"
        :active="active"
      />
      <pre v-if="componentName === null">
          {{ item }}
      </pre>
    </div>
  </div>
</template>
<script>
import YesNoWithoutDeviation from "./YesNoWithoutDeviation";
import YesNoWithDeviation from "./YesNoWithDeviation.vue";
import NumericalWithBoundaries from "./NumericalWithBoundaries";
import DateTimeSeconds from "./DateTimeSeconds";
import DateTimeMinutes from "./DateTimeMinutes";
import TextBox from "./TextBox";
import TextBoxRequired from "./TextBoxRequired";
import List from "./List";
import axios from "@/requests/axios";

export default {
  emits: ["next", "activate", "update:modelValue", "pdf"],
  props: {
    active: {
      default: false,
    },
    item: {
      required: true,
    },
    modelValue: {
      required: true,
    },
    employee: {},
  },
  mounted() {
    //console.log(this.$refs.child.locked);
  },
  data() {
    return {
      saving: false,
      locked: false,
    };
  },
  watch: {
    modelValue: {
      deep: true,
      handler: function (val) {
        this.$emit("update:modelValue", val);
      },
    },
    active(isActive) {
      if (
        !isActive &&
        !this.locked &&
        this.modelValue.isdone &&
        this.isChanged
      ) {
        this.save();
      }
      if (isActive) {
        //console.warn(this.$el);
        let el = this.$el;
        Promise.resolve().then(() => {
          el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        });
      }
    },
  },
  methods: {
    save() {
      return new Promise((resolve, reject) => {
        this.saving = true;
        this.error = false;
        axios
          .post(
            "balthzar_update_internal_activity_action_list_checklist_21_8_0",
            {
              record_no: this.item.record_no,
              entered_value: this.modelValue.entered_value,
              who: this.employee.value || "-",
              finished_date: new Date().toBalthzarTime(),
              status: this.status, 
            }
          )
          .then((data) => {
            if (this.modelValue.exception_note) {
              axios
                .post("balthzar_internal_activities_set_deviation", {
                  record_no: this.item.record_no,
                  deviation: this.modelValue.exception_note,
                })
                .then(() => {
                  this.saving = false;
                  this.item.entered_value = this.modelValue.entered_value;
                  this.item.exception_note = this.modelValue.exception_note;
                  this.item.checklist_status = this.status;
                  resolve();
                });
            } else {
              this.saving = false;
              this.item.entered_value = this.modelValue.entered_value;
              this.item.exception_note = this.modelValue.exception_note;
              this.item.checklist_status = this.status;
              resolve();
            }
          })
          .catch((err) => {
            console.log(err);
            this.saving = false;
            this.error = true;
            reject();
          });
      });
    },
    lock(val) {
      this.locked = val;
    },
  },
  computed: {
    componentName() {
      let components = [
        "NumericalWithBoundaries", //0
        "YesNoWithDeviation", //1
        "YesNoWithoutDeviation", //2
        "TextBoxRequired", //3
        "TextBox", //4
        "DateTimeSeconds", //5
        "DateTimeMinutes", //6
        null,
        null,
        null,
        "List",
      ];
      if (+this.item.input_type < components.length) {
        return components[+this.item.input_type];
      }
      return null;
    },
    isChanged() {
      return (
        this.modelValue.entered_value != null &&
        (this.modelValue.entered_value != this.item.entered_value ||
          this.modelValue.exception_note != this.item.exception_note)
      );
    },
    isExceptionType() {
      return ["1", "2"].includes(this.item.input_type);
    },
    status() {
      return this.modelValue.isdone
        ? (this.modelValue.entered_value === "0" && this.isExceptionType) ||
          !(
            this.modelValue.exception_note == "" ||
            this.modelValue.exception_note == null
          )
          ? "4"
          : "1"
        : "0";
    },
  },
  components: {
    YesNoWithoutDeviation,
    YesNoWithDeviation,
    NumericalWithBoundaries,
    TextBox,
    TextBoxRequired,
    DateTimeSeconds,
    DateTimeMinutes,
    List,
  },
};
</script>