<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget left-aligned">
    <div v-if="machine">
      <h1>
        {{ config.title ? config.title : $t("6478") }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div class="row padded-10">
        <div class="col-4">
          <flex-button
            :setup="config.setup"
            k="button_1_function"
            :config="config"
          ></flex-button>
        </div>
        <div class="col-4">
          <flex-button
            :setup="config.setup"
            k="button_2_function"
            :config="config"
          ></flex-button>
        </div>
        <div class="col-4">
          <flex-button
            :setup="config.setup"
            k="button_3_function"
            :config="config"
          ></flex-button>
        </div>
        <div class="col-4">
          <flex-button
            :setup="config.setup"
            k="button_4_function"
            :config="config"
          ></flex-button>
        </div>
        <div class="col-4">
          <flex-button
            :setup="config.setup"
            k="button_5_function"
            :config="config"
          ></flex-button>
        </div>
        <div class="col-4">
          <flex-button
            :setup="config.setup"
            k="button_6_function"
            :config="config"
          ></flex-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FlexButton from "@/components/flexbutton/FlexButton";
import _ from "lodash";
import { InterfaceComponent } from "@/helpers/machine";

export default InterfaceComponent(
  {
    data() {
      return {
        cid: _.uniqueId("w"),
        configString: "flex-widget-6",
        config: {
          setup: true,
          title: "Flex-widget",
          captions: true,
          button_1_function: "-",
          button_2_function: "-",
          button_3_function: "-",
          button_4_function: "-",
          button_5_function: "-",
          button_6_function: "-",
        },
      };
    },
    mounted() {
      if (!this.canSaveWidgetSettings) {
        this.config.setValues({ setup: false });
      }
    },
    computed: {
      canSaveWidgetSettings() {
        return this.canUser("save-widget-settings");
      },
      has85() {
        return this.hasSf("85");
      },
      uncoded_used() {
        return this.has85
          ? this.machine.uncoded_stops_24h
          : this.machine.uncoded_stops_shift24h;
      },
      items() {
        if (!this.canSaveWidgetSettings) {
          return [];
        }
        return [
          {
            input: "title",
            type: "text",
          },
          {
            label: "toggle captions",
            handler: () => {
              this.config.setValues({ captions: !this.config.captions });
            },
          },
          {
            label: "toggle setup",
            handler: () => {
              this.config.setValues({ setup: !this.config.setup });
            },
          },
        ];
      },
    },
    components: {
      FlexButton,
    },
  },
  ["planned", "running","notifications"]
);
</script>