import axios from "@/requests/axios";
import { flattenMultiRow, flattenSingleRow } from "@/requests/requestParcer";
import { daybreakDate } from '@/helpers/dates';

const state = () => ({
  h24: {

  },
  today: {

  },
  shift: {

  }
});

// getters
const getters = {

}

// actions
const actions = {
}

// mutations
const mutations = {
  setStopreasons24H(state, { machine_id, stopreasons }) {
    state.h24[machine_id] = stopreasons;
  },
  setStopreasonsToday(state, { machine_id, stopreasons }) {
    state.today[machine_id] = stopreasons;
  },
  setStopreasonsShift(state, { machine_id, stopreasons }) {
    state.shift[machine_id] = stopreasons;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}