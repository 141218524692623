<template>
  <div>
    <Loading v-if="loading" />
    <div v-else>
      <b-table
        :data="setupTypes"
        :columnConfig="columnConfig"
        primary-key="record_no"
        :sort-key="['sort_order']"
        :sort_dir="['asc']"
        :single-select="true"
        :deselect="true"
        :show-headers="false"
        @selectionChanged="onSelectionChanged"
      >
      </b-table>
    </div>
    <button
        class="tabbed-next-button"
        @click="$emit('next')"
        :disabled="disableNext"
        :class="{ disabled: disableNext }"
      >
        <i class="fas fa-chevron-right" v-if="!islast"></i>
        <i class="fas fa-check" v-if="islast"></i>
      </button>
  </div>
</template>
<script>
import { mapState } from "vuex";
import store from "@/store";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      machine_id: this.$route.params.machine,
      columnConfig: {
        description: {
          class: "h50",
        },
      },
      selectedRows: [],
      items: [],
      loading: false,
    };
  },
  mounted() {
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
      setupTypes: (state) => state.setup.index
    }),
    ordersForMachine() {
      return this.orders[this.machine_id] || null;
    },
    disableNext() {
      return this.data[this.tab.dataKey].selected === null;
    }
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length > 0 && this.selectedRows[0]) {
        this.data[this.tab.dataKey].selected = this.selectedRows[0];
      } else {
        this.data[this.tab.dataKey].selected = null;
      }
    },
  },
  watch: {},
};
</script>