import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";
import { getStorage, setStorage, checkStorage } from "../../helpers/storage";

const state = () => ({
    index: [],
    loaded: false
});

// getters
const getters = {
    byId: (state) => (id) => {
        if (!state.loaded) {
            return null;
        }
        return state.index.filter(option => option.record_no == id).map((v, k) => {
            return {
                ...v,
                index: k
            }
        });
    },
}

// actions
const actions = {
    loadLists({ commit, state, dispatch, rootState }, listId) {
        if (state.loaded) {
            //check if listId is loaded, if not, force reload through ajax call
            dispatch("doubleCheckSpecific", listId)
                .then(() => {
                    return new Promise((resolve) => {
                        resolve();
                    })
                })
        } else {
            if (!checkStorage(rootState.auth.company_id + "_lists_loaded")) {
                return new Promise((resolve, reject) => {
                   execute_json("get_all_lists")
                        .then(({ data }) => {
                            //console.log(data,data[0]);
                            commit('setlists', {
                                data: data,
                                company_id: rootState.auth.company_id
                            });
                            resolve();
                        })
                        .catch((err) => {
                            reject(err);
                        })
                });
            } else {
                return new Promise((resolve) => {
                    commit('setlists', {
                        data: getStorage(rootState.auth.company_id + "_lists_loaded"),
                        company_id: rootState.auth.company_id
                    });
                    //check if listId is loaded, if not, force reload through ajax call
                    dispatch("doubleCheckSpecific", listId)
                        .then(() => {
                            resolve();
                        })
                    resolve();
                })
            }
        }
    },
    doubleCheckSpecific({ commit, getters, rootState }, listId) {
        //console.warn("doublechecking specific..");
        if (!listId) {
            return new Promise((resolve) => {
                resolve();
            })
        }
        else if (listId && getters.byId(listId).length) {
            //console.warn("list already exists and has children!");
            return new Promise((resolve) => {
                resolve();
            })
        } else {
            //console.warn("list doesnt exists!");
            return new Promise((resolve, reject) => {
                execute_json("get_all_lists")
                    .then(({ data }) => {
                        //console.log(data,data[0]);
                        commit('setlists', {
                            data: data,
                            company_id: rootState.auth.company_id
                        });
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    })
            });
        }
    }
}
// mutations
const mutations = {
    setlists(state, { data, company_id }) {
        setStorage(company_id + "_lists_loaded", data);
        state.index = data;
        state.loaded = true;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}