<template>
  <div class="numericalwithboundaries">
    <div class="numerical-display" :class="status">
      {{modelValue.entered_value}}
    </div>
    <div class="extra-info">
      {{status === 'red' ? $t('3354') : ""}}
    </div>
    <numerical-input v-model="modelValue.entered_value" :show-output="false" :disabled="locked" :listening="active" />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  emits: ["update:modelValue", "next", "lock"],
  props: {
    modelValue: {},
    item: {},
    active: {},
  },
  mounted(){
    //this.softClamp();
    this.$emit('lock',this.locked);
  },
  data() {
    return {
      status:"green"
    };
  },
  components: { },
  watch: {
    modelValue: {
      deep: true,
      handler: function (val) {
        if (val.entered_value != "") {
          this.softClamp();
          this.modelValue.isdone = true;
        } else {
          this.modelValue.isdone = false;
        }
        this.$emit("update:modelValue", val);
      },
    },
    status(val){
      if (val === "red"){
        this.modelValue.exception_note = "outside limits";
      }else{
        this.modelValue.exception_note = null;
      }
    },
    active(val) {
      if (val){
        this.softClamp();
      }
    },
    locked(val){
      this.$emit('lock',val);
    }
  },
  computed:{
    locked(){
      return !this.item.allow_user_edit || 
      (this.status === 'red' && 
      this.item.lock_if_outside_reasonable_value && 
      this.item.entered_value == this.modelValue.entered_value);
    }
  },
  methods: {
   softClamp(){
     let value = +this.modelValue.entered_value;
     let status = "green";
     if (_.clamp(value,this.item.lower_limit,this.item.upper_limit) !== value){
       status = "yellow";
     }
     if (_.clamp(value,this.item.lower_reasonable_limit,this.item.upper_reasonable_limit) !== value){
       status = "red";
     }
     this.status = status;
   }
  },
};
</script>

<style>
</style>