<template>
  <div
    class="
      col-12 col-xl-6 col-lg-8 col-md-12
      widget widget-allow-overflow
      left-aligned
    "
  >
    <div>
      <h2>{{ $t("3593") }}</h2>
      <card-menu :items="[]"></card-menu>
      <loading v-if="loading" />
      <time-history-machine-graph
        v-else
        :machine_id="$route.params.machine"
      ></time-history-machine-graph>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import TimeHistoryMachineGraph from "@/components/TimeHistoryMachineGraph.vue";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 180,
      selected: [],
      events: [],
      loading: false,
    };
  },
  mounted() {
    //this.load();
  
  },
  beforeUnmount() {
 
  },
  methods: {
   
  },
  computed: {
   
  },
  watch: {
  },
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
    TimeHistoryMachineGraph,
  },
};
</script>