<template>
  <div
    class="time-slot"
    :style="{ width: data.per + '%', background: data.color }"
    :class="{ selected: selected, selectable: data.selectable }"
    @click="addSelection(data.index)"
  >
    <div class="tab-tooltip" :class="{upwards:index > 5}" v-if="!timeslot.filler">
      {{ timeslot.stop_time == "0" ? $t("951") : timeslot.stop_description }}<br>
      {{timeslot.start_time}}-{{timeslot.end_time}}
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";

export default {
  emits: ["addSelect"],
  props: {
    data: {
      required: true,
    },
    timeslot: {
      required: true,
    },
    index:{
      default:0
    }
  },
  data() {
    return {
      cid: _.uniqueId("time-history"),
      selected: false,
      fullscreen: false,
    };
  },
  mounted() {},
  methods: {
    addSelection(index) {
      if (this.timeslot.stop_time != "0" && !this.timeslot.filler) {
        this.selected = !this.selected;
        this.$emit("addSelect", this.timeslot);
      }
    },
    activateStop(stop){
      if (this.timeslot.stop === stop.stop){
        this.selected = true;
      }
    },
    deActivateStop(stop){
      if (this.timeslot.stop === stop.stop){
        this.selected = false;
      }
    }
  },
  computed: {},
  watch: {},
  components: {},
};
</script>