//import  _  from "lodash";
import axios from "@/requests/axios";
import { flattenMultiRow } from '@/requests/requestParcer';
import KeyboardLayouts from "simple-keyboard-layouts";
import { setStorage, getStorage, checkStorage } from "@/helpers/storage";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";

let layouts = new KeyboardLayouts();

const state = () => ({
    keyboardEnabled: getStorage("keyboardEnabled", false) || "1",
    keyboardLanguage: getStorage("keyboardLanguage", false) || null,
    keyboardPosition:JSON.parse(getStorage("keyboardPosition",false)) || null,
    keyboardLayouts: layouts.get(),
    specialLoaded: false,
    specials: []
});

// getters
const getters = {
    Enabled(state) {
        if (state.keyboardEnabled === "0") {
            return false;
        }
        return true;
    },
    Language(state, getters, rootState) {
        if (!getters.Enabled) {
            return null;
        }
        if (state.keyboardLanguage !== null) {
            return state.keyboardLanguage;
        }
        let langCode = rootState.auth.lang_code;
        switch (langCode) {
            case "ENG":
                return "english";
                break;
            case "SWE":
                return "swedish";
                break;
            default:
                return "english";
                break;
        }
    },
    Layout(state, getters) {
        let layout = state.keyboardLayouts[getters.Language]?.layout || state.keyboardLayouts["english"];
        if (state.specials.length) {
            layout = _.cloneDeep(layout);
            layout.default[layout.default.length - 1] += " {special}";
            layout.special = _.chunk(state.specials, 8).map(chunk => chunk.map(s => s.value).join(" "));
            layout.special.push('{normal}');
        }
        return layout;
    },
    Position(state){
        if (state.keyboardPosition){
            return {
                ...state.keyboardPosition,
                transform:"translateX(0)"
            }
        }
        return {};
    }
}

// actions
const actions = {
    loadSpecials({ commit, rootState, rootGetters }) {
        return new Promise((resolve, reject) => {
            if (!window.loadingSpecials) {
                window.loadingSpecials = true;
                execute_json("get_etouch_special_characters")
                    .then(({ data }) => {
                        commit("setSpecials", data);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    })
            }
            else {
                resolve();
            }
        });
    },
}
// mutations
const mutations = {
    toggleKeyboard(state) {
        if (state.keyboardEnabled === "0") {
            setStorage("keyboardEnabled", "1", false);
            state.keyboardEnabled = "1";
        } else {
            setStorage("keyboardEnabled", "0", false);
            state.keyboardEnabled = "0";
        }
    },
    setKeyboardLanguage(state, lang) {
        setStorage("keyboardLanguage", lang, false);
        state.keyboardLanguage = lang;
    },
    loaded(state) {
        state.specialLoaded = true;
    },
    setSpecials(state, data) {
        state.specials = data;
    },
    setKeyboardPosition(state,data){
        state.keyboardPosition = data;
        setStorage("keyboardPosition",JSON.stringify(data),false);
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}