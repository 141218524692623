<template>
  <div class="location-swap">
    <button
      class="btn"
      type="button"
      id="locationSwapButton"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span class="company_name">{{ company_name }}</span>
      <div v-if="loctype !== null">
        <span class="loctype">{{ $t(loctype || "1") }}</span> {{ loc }}
      </div>
      <div v-if="loctype === null">
        <span class="loctype">{{ $t("570") }}</span> {{ $t("570") }}
      </div>
    </button>
    <ul class="dropdown-menu" aria-labelledby="locationSwapButton">
      <li>
        <router-link
          :to="{ name: currentAllName }"
          class="dropdown-item"
          v-if="includeAll"
        >
          {{ $t("570") }}
        </router-link>
        <router-link
          :to="includeEmptyLink"
          class="dropdown-item"
          v-if="includeEmptyAll"
        >
          {{ $t("570") }}
        </router-link>
      </li>
      <li v-for="o in locationsCombined" :key="o.loctype + '|' + o.location">
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="combined = o.loctype + '|' + o.location"
          >{{ $t(o.loctype_key) }} - {{ o.label }}</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";
import _ from "lodash";
export default {
  props: {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      company_name: (state) => state.auth.company_name,
    }),
    currentRouteName() {
      return this.$route.name;
    },
    currentcombined(){
      return this.$route.params.locationtype + "|" + this.loc;
    },
    currentRouteIsLocation() {
      return this.currentRouteName.includes("-location");
    },
    currentLocationName() {
      return this.currentRouteName.replace("-all", "-location");
    },
    currentAllName() {
      return this.currentRouteName.replace("-location", "-all");
    },
    includeMineInMenu() {
      return this.$route.meta.canHandleMine === true;
    },
    includeAll() {
      return (
        this.$route.meta.allBehavior !== "deny" &&
        this.$route.meta.allBehavior !== "empty"
      );
    },
    includeEmptyAll() {
      return this.$route.meta.allBehavior === "empty";
    },
    includeEmptyLink() {
      let params = {
        ...this.$route.params,
        locationtype: "undefined",
        location: "undefined",
      };
      let $route = this.$router.resolve({
        name: this.currentLocationName,
        params,
      });
      return $route.path;
    },
    locationsCombined() {
      let vm = this;
      let combined = [];
      _.each(
        [
          { loctype: "mine", tkey: "1087" },
          { loctype: "department", tkey: "2" },
          { loctype: "zone", tkey: "331" },
          { loctype: "category", tkey: "332" },
        ],
        ({ loctype, tkey }) => {
          _.each(vm["available" + loctype], ({ key, label, tlabel}) => {
            combined.push({
              loctype,
              loctype_key: tkey,
              location: key,
              label: tlabel ? this.$t(tlabel) : label,
            });
          });
        }
      );
      return combined;
    },
    availablemine() {
      return this.includeMineInMenu ? [{"key":"all","label":"all","tlabel":"570"}] : [];
    },
    availabledepartment() {
      let uniqueKeys = [
        ...new Set(this.machines.map((item) => item.department)),
      ];
      let unique = uniqueKeys.map((k) => {
        return {
          key: k,
          label: this.machines.find((o) => o.department === k)
            .department_description,
        };
      });
      return _.filter(unique, (department) => {
        return department.key !== undefined && department.key != "";
      });
    },
    availablezone() {
      let uniqueKeys = [...new Set(this.machines.map((item) => item.zone))];
      let unique = uniqueKeys.map((k) => {
        return {
          key: k,
          label: this.machines.find((o) => o.zone === k).zone_desc,
        };
      });
      return _.filter(unique, (zone) => {
        return zone.key !== undefined && zone.key != "";
      });
    },
    availablecategory() {
      let uniqueKeys = [...new Set(this.machines.map((item) => item.category))];
      let unique = uniqueKeys.map((k) => {
        return {
          key: k,
          label: this.machines.find((o) => o.category === k).cat_desc,
        };
      });
      return _.filter(unique, (category) => {
        return category.key !== undefined && category.key != "";
      });
    },
    loctype() {
      if (
        this.$route.params.locationtype === undefined ||
        this.$route.params.locationtype === "undefined"
      ) {
        return null;
      }
      return this.reverseTkeyLoctype[this.$route.params.locationtype];
    },
    loc() {
      if (
        this.$route.params.location === undefined ||
        this.$route.params.location === "undefined"
      ) {
        return null;
      }
      let loc = this["available" + this.$route.params.locationtype].find(
        (o) => o.key === this.$route.params.location
      );
      return loc ? loc.label : "";
    },
  },
  data() {
    return {
      combined: this.currentcombined,
      reverseTkeyLoctype: {
        department: "2",
        zone: "331",
        category: "332",
        mine:"1087"
      },
    };
  },
  watch: {
    combined(val) {
      let [loctype, loc] = val !== "all" ? val.split("|") : ["undefined","undefined"];
      //console.error(val);
      let params = {
        ...this.$route.params,
        locationtype: loctype,
        location: loc,
      };
      //console.warn(this.currentLocationName,params);
      let $route = this.$router.resolve({
        name: this.currentLocationName,
        params,
      });

      //console.warn($route);

      if ($route.path !== this.$route.path && val !== "all") {
        this.$router.push($route.path);
      }
    },
    $route(to) {
      if (to.meta && to.meta.group === "all") {
        this.combined = "all";
      }else if (to.params.locationtype && to.params.location){
        this.combined = to.params.locationtype + "|" + to.params.location;
      }
    },
  },
  methods: {},
  components: {},
};
</script>