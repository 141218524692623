<template>
  <div class="col-12 col-xl-4 col-lg-4 col-md-6 col-xxl-3 widget">
    <div v-if="!setup && machine && machine.empty !== true">
      <h1>
        <!-- 32 : status -->
        <span v-if="['idPlusDesc', 'id'].includes(style.header)">{{
          machine.machine_id
        }}</span>
        <span v-if="style.header == 'idPlusDesc'">:</span>
        <span v-if="['idPlusDesc', 'desc'].includes(style.header)">{{
          machine.object_description
        }}</span>
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div class="normal-status-wrap spaced">
        <router-link
          :to="'/interface/' + machine.machine_id"
          class="indicator status-large"
          :class="status"
        >
          <div class="vertically-centered-content">
            <!-- machine has a stop reason -->
            <h3>
              {{
                machine.status === "S" ? machine.stop_description : $t("786")
              }}
            </h3>
            <p class="label-value">
              {{
                [
                  ...(config.field_1_function?.header || []),
                  ...(config.field_2_function?.header || []),
                ]
                  .map((h) => $t(h))
                  .join("/")
              }}
            </p>

            <p class="present-value">
              <flex-button
                :setup="setup"
                k="field_1_function"
                :mparam="machine_id"
                :labels="false"
                :config="config"
              ></flex-button>
              <span
                v-if="
                  config.field_1_function != '-' &&
                  config.field_2_function != '-'
                "
                >/</span
              >
              <flex-button
                :setup="setup"
                k="field_2_function"
                :mparam="machine_id"
                :labels="false"
                :config="config"
              ></flex-button>
            </p>
            <p class="label-value">
              {{
                [
                  ...(config.field_3_function?.header || []),
                  ...(config.field_4_function?.header || []),
                ]
                  .map((h) => $t(h))
                  .join("/")
              }}
            </p>

            <p class="present-value">
              <flex-button
                :setup="setup"
                k="field_3_function"
                class="fw"
                :mparam="machine_id"
                :labels="false"
                :config="config"
              ></flex-button>
              <flex-button
                :setup="setup"
                k="field_4_function"
                class="fw"
                :mparam="machine_id"
                :labels="false"
                :config="config"
              ></flex-button>
            </p>
            <p class="label-value">
              {{
                [
                  ...(config.field_5_function?.header || []),
                  ...(config.field_6_function?.header || []),
                ]
                  .map((h) => $t(h))
                  .join("/")
              }}
            </p>
            <p class="present-value">
              <flex-button
                :setup="setup"
                k="field_5_function"
                :mparam="machine_id"
                :labels="false"
                :config="config"
              ></flex-button>
              <span
                v-if="
                  config.field_5_function != '-' &&
                  config.field_6_function != '-'
                "
                >/</span
              >
              <flex-button
                :setup="setup"
                k="field_6_function"
                :mparam="machine_id"
                :labels="false"
                :config="config"
              ></flex-button>
            </p>
          </div>
        </router-link>
      </div>
    </div>
    <div v-else>
      <card-menu :items="items" :config="config"></card-menu>
      <flex-button
        :setup="setup"
        k="field_1_function"
        :mparam="machine_id"
        :allow-planned="false"
        :labels="false"
        :config="config"
      ></flex-button>
      <flex-button
        :setup="setup"
        k="field_2_function"
        :mparam="machine_id"
        :allow-planned="false"
        :labels="false"
        :config="config"
      ></flex-button>
      <flex-button
        :setup="setup"
        k="field_3_function"
        :mparam="machine_id"
        :allow-planned="false"
        :labels="false"
        :config="config"
      ></flex-button>
      <flex-button
        :setup="setup"
        k="field_4_function"
        :mparam="machine_id"
        :allow-planned="false"
        :labels="false"
        :config="config"
      ></flex-button>
      <flex-button
        :setup="setup"
        k="field_5_function"
        :mparam="machine_id"
        :allow-planned="false"
        :labels="false"
        :config="config"
      ></flex-button>
      <flex-button
        :setup="setup"
        k="field_6_function"
        :mparam="machine_id"
        :allow-planned="false"
        :labels="false"
        :config="config"
      ></flex-button>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { Configuration } from "@/helpers/settings";
import FlexButton from "@/components/flexbutton/FlexOrderButton";
import { mapState, mapGetters } from "vuex";
import { shortDateTime } from "@/helpers/dates";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pressedStop: false,
      pressedCancel: false,
      pressedControl: false,
      pulse: null,
      setup: false,
      config: new Configuration(
        "production-status-overview-widget",
        {
          title: "Flex Order",
          field_1_function: {
            key: "ValueGoodQty",
            header: ["581"],
            description: "GoodQty#",
          },
          field_2_function: {
            key: "ValueWoQty",
            header: ["4258"],
            description: "WoQty#",
          },
          field_3_function: {
            key: "ValueWoNo",
            header: ["24"],
            description: "WorkOrder#",
          },
          field_4_function: {
            key: "ValuePartNo",
            header: ["604"],
            description: "Part #",
          },
          field_5_function: {
            key: "ValueOrderFinished",
            header: ["593"],
            description: "Order finished",
          },
          field_6_function: "-",
        },
        "machine_overviews_default"
      ),
    };
  },
  props: {
    machine: {
      required: true,
    },
    style: {
      default() {
        return {};
      },
    },
  },
  methods: {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      runningByMachine: "orders/runningByMachine",
    }),
    machine_id() {
      return this.machine.machine_id;
    },
    order() {
      return this.runningByMachine(this.machine_id);
    },
    status() {
      let status = "";
      if (this.machine.status === "S") {
        if (this.machine.current_stop_reason === "-") {
          status += "status-uncoded";
        } else {
          status += "status-stop";
        }
      } else {
        status += "status-running";
      }
      status +=
        " c-" +
        (this.machine.stop_reason_color &&
        this.machine.stop_reason_color != "Automatic"
          ? this.machine.stop_reason_color.toLowerCase()
          : "") +
        " ";
      return status;
    },
    order_finished_formatted_short() {
      //return shortDateTime("2016-05-08 15:15");
      return shortDateTime(this.machine.order_finished);
    },
    items() {
      return [
        {
          label: "toggle setup",
          handler: () => {
            this.setup = !this.setup;
          },
        },
      ];
    },
  },
  components: {
    CardMenu,
    FlexButton,
  },
};
</script>
<style scoped lang="scss">
.normal-status-wrap {
  &.spaced {
    .indicator {
      padding: 20px;
    }
  }
}
p.present-value {
  white-space: nowrap;
  min-height: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  div {
    display: block;
    &.fw {
      width: 100%;
    }
  }
}
</style>