import axios from "@/requests/axios";
import { flattenMultiRow, flattenSingleRow } from "@/requests/requestParcer";
import { daybreakDate } from '@/helpers/dates';
import { execute_json } from "@/helpers/requests";
import { Notification } from "@/helpers/notifications";

const state = () => ({
  index: {

  }
});

// getters
const getters = {
  materialListByRecordNo: (state) => (record_no) => {
    return state.index[record_no] || [];
  }
}

// actions
const actions = {
  loadMaterialList({ rootState, commit }, { machine }) {
    return new Promise((resolve, reject) => {
      //console.warn(machine);
      execute_json("get_work_order_materials", {
        card_no: machine.p_order ? machine.card_no : null,
        card_no: machine.card_no,
        machine_id: machine.machine_id,
        work_order: machine.work_order_no,
        p_order: machine.p_order || 0,
        op_no: machine.op_number,
        debug: false,
        manufact_order_line_no: machine.line_no || machine.manufactorderlineno || '',
      })
        .then(({ data }) => {
          //console.log(data,data[0]);
          data = data.map(d => {
            return {
              ...d,
              qty_per_piece: d.planned_qty / machine.planned_op_qty || 0
            };
          })
          commit('setMaterialList', { record_no: machine.record_no, materials: data });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  orderMaterials({ commit, rootState }, { machine_id, material }) {
    return new Promise((resolve, reject) => {
      execute_json("order_material", {
        feature: "order_materials",
        machine_id,
        material_no: material.material_no,
        material_description: material.material_desc || ' ',
        started_by: rootState.auth.username
      }).then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err);
      })
    });
  },
  orderReturnMaterials({ commit, rootState }, { machine_id, material }) {
    return new Promise((resolve, reject) => {
      execute_json("order_material", {
        feature: "order_return_materials",
        machine_id,
        material_no: material.material_no,
        material_description: material.material_desc || ' ',
        started_by: rootState.auth.username
      }).then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err);
      })
    });
  },
  materialReportingIn({ commit, rootState }, { machine_id, material_card_no, batch_no, scrap_reason, quantity, warehouse_location }) {
    return new Promise((resolve, reject) => {
      execute_json("perform_material_reporting", {
        machine_id, card_no: material_card_no, quantity,  routine: "IN"
      }).then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err);
      })
    });
  },
  materialReportingOut({ commit, rootState }, { machine_id, material_card_no, batch_no, scrap_reason, quantity, warehouse_location }) {
    return new Promise((resolve, reject) => {
      execute_json("perform_material_reporting", {
        machine_id, card_no: material_card_no, quantity,  routine: "OUT"
      }).then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err);
      })
    });
  },
  materialReportingScrap({ commit, rootState }, { machine_id, material_card_no, batch_no, scrap_reason, quantity, warehouse_location }) {
    return new Promise((resolve, reject) => {
      let n = Notification.request({
        message:"reporting..."
      });
      execute_json("perform_material_reporting", {
        machine_id, card_no: material_card_no, scrap_reason, quantity, routine: "SCRAP"
      }).then(({ data }) => {
        n.ok();
        resolve(data)
      }).catch((err) => {
        n.close();
        reject(err);
      })
    });
  },
}

// mutations
const mutations = {
  setMaterialList(state, { record_no, materials }) {
    state.index[record_no] = materials;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}