<template>
  <div>
    <p class="present-value bold">
      <flex-value :value="machine.card_good_qty" />
    </p>
    <p class="present-value small">
      {{ machine.work_order_qty }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["machine", "order", "planned"],
};
</script>

<style>
</style>