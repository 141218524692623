<template>
  <div class="align-left">
    <label for="">{{ $t("309") }}</label>
    <p class="display-value">
      <b>{{ data.data.work_order_no }}</b>
    </p>
    <label for="">{{ $t("581") }}</label>
    <p class="display-value">
      <b>{{ data.data.card_good_qty }}</b>
    </p>
    <label for="">{{ $t("503") }}</label>
    <p class="display-value">
      <b>{{ data.data.planned_op_qty }}</b>
    </p>
    <label for="">{{ $t("4543") }}</label>
    <p class="display-value">
      <b><span v-if="data.data.card_good_qty - data.data.planned_op_qty > 0">+</span>{{ data.data.card_good_qty - data.data.planned_op_qty }}</b>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    data: {},
  },
};
</script>

<style>
</style>