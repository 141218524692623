<template>
  <div>
    <h2>{{$t('3720')}}</h2>
    <loading v-if="!loaded" />
    <div v-else>
      <c-table
        :data="scrapHistory"
        primary-key="trans_time"
        config-string="scrap_history_tab"
        default-sort-key="trans_time"
        default-sort-dir="desc"
        @selectionChanged="onSelectionChanged"
        :teleport="true"
      >
      </c-table>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import SortSelect from "@/components/sort-select";
import store from "@/store";
export default {
  props: {
    data: {
      required: true,
    },
  },
  data(){
    return {
      loaded:false
    }
  },
  mounted() {
    store.commit("bottomnav/disable");
    let promises = [];
    promises.push(
      store.dispatch("machines/loadMachines", {
        card_no: this.card_no,
      })
    );
    promises.push(
      store.dispatch("goods/loadScrapHistoryForMachine", {
        card_no: this.card_no,
      })
    );
    Promise.all(promises).then(() => {
      this.loaded = true;
      console.log("fully loaded..");
    });
  },
  methods: {
    onSelectionChanged() {
      //console.log("something");
    },
  },
  computed: {
    ...mapState({
      bottomNav: (state) => state.bottomnav,
      machines: (state) => state.machines.index,
      scrapHistories: (state) => state.goods.scrapHistory,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      canUser: "permissions/canUser",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    card_no() {
      return this.data?.order?.selected?.card_no;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    scrapHistory() {
      return this.scrapHistories[this.card_no] || [];
    },
    canManualScrap() {
      return this.canUser("can-manual-scrap");
    },
  },
  watch: {
    $route(to) {
      if (this.loaded && this.machine && this.card_no !== true) {
        this.loaded = false;
        let promises = [];
        promises.push(
          store.dispatch("machines/loadMachines", {
            card_no: this.card_no,
          })
        );
        promises.push(
          store.dispatch("goods/loadScrapHistoryForMachine", {
            card_no: this.card_no,
          })
        );
        Promise.all(promises).then(() => {
          this.loaded = true;
          console.log("fully loaded..");
        });
      }
    },
  },
  components: {
    SortSelect,
  },
};
</script>