import defaultLayout from '@/layouts/DefaultLayout'
import portalComponent from "@/components/portal-component";
import Machines from "@/views/overviews/machines.vue";
import MachineWidgets from "@/views/overviews/WidgetMachines.vue";

function loadView(view) {
  return () => import ( `@/views/${view}.vue`)
}

export const historyRoutes = [
  {
    path:"/history/:machine/week",
    name:'history-machine-week',
    meta:{
      allBehavior:"deny",
      requiresAuth:true,
      shouldRemember:true,
      layout:defaultLayout
    },
    component:loadView("overviews/history/machine/HistoryWeek")
  },
  {
    path:"/history/:machine/day/:week?",
    name:'history-machine-day',
    meta:{
      allBehavior:"deny",
      requiresAuth:true,
      layout:defaultLayout
    },
    component:loadView("overviews/history/machine/HistoryDay")
  },
  {
    path:"/history/:machine/hour/:day?",
    name:'history-machine-hour',
    meta:{
      allBehavior:"deny",
      requiresAuth:true,
      layout:defaultLayout
    },
    component:loadView("overviews/history/machine/HistoryHour")
  },
  {
    path:"/history/:machine/transactions/:date?/:hour?",
    name:'history-machine-transactions',
    meta:{
      allBehavior:"deny",
      requiresAuth:true,
      layout:defaultLayout
    },
    component:loadView("overviews/history/machine/HistoryTransactions")
  },
  {
    path:"/history/:machine/time-history",
    name:'history-machine-time-history',
    meta:{
      allBehavior:"deny",
      shouldRemember:true,
      requiresAuth:true,
      layout:defaultLayout
    },
    component:loadView("overviews/history/machine/HistoryTimeHistory")
  },
];
