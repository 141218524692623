import adminLayout from '@/layouts/AdminLayout'

function loadView(view) {
  return () => import(/* webpackChunkName: "[request]" */ `@/views/${view}.vue`)
}

export const adminRoutes = [
  {
    path: '/admin',
    name: 'admin',
    meta: {
      requiresAuth: true,
      layout: adminLayout
    },
    component: loadView("admin/index")
  },
  {
    path: '/admin/security',
    name: 'Security',
    meta: {
      requiresAuth: true,
      title:"Security settings",
      layout: adminLayout
    },
    component: loadView("admin/security")
  },
  {
    path: '/admin/production-goals',
    name: 'Production goals',
    meta: {
      requiresAuth: true,
      title:"Production goals",
      layout: adminLayout
    },
    component: loadView("admin/production-goals/index")
  },
  {
    path: '/admin/config/widgets/:template',
    name: 'Widget Settings',
    meta: {
      requiresAuth: true,
      layout: adminLayout
    },
    component: loadView("admin/widgets")
  },
  {
    path: '/admin/config/dashboards/:template',
    name: 'Dashboard Settings',
    meta: {
      requiresAuth: true,
      layout: adminLayout
    },
    component: loadView("admin/dashboards")
  },
  {
    path: '/admin/config/tables/:template',
    name: 'Column Settings',
    meta: {
      requiresAuth: true,
      layout: adminLayout
    },
    component: loadView("admin/tables")
  },
  {
    path: '/admin/config/liveviews/:template',
    name: 'Liveview Settings',
    meta: {
      requiresAuth: true,
      layout: adminLayout
    },
    component: loadView("admin/liveviews")
  },
  {
    path: '/admin/config/overview/:id',
    name: 'Overview setting',
    meta: {
      requiresAuth: true,
      layout: adminLayout
    },
    component: loadView("admin/overview")
  },
]

