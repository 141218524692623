<template>
  <div>
    <h2 class="content-block">
      <uncoded-stops-indicator></uncoded-stops-indicator>
      {{ $t("17") }}
    </h2>
    <sort-select target="machines" :periods="false" :showAdmin="false">
      <li>
        <h6 class="dropdown-header">{{ $t("4068") }}</h6>
      </li>
      <li>
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="setHeaderStyle('idPlusDesc')"
          :class="{ selected: configuration.headerStyle == 'idPlusDesc' }"
          >{{ $t("2258") }}+{{ $t("3505")
          }}<span
            class="pull-right"
            v-if="configuration.headerStyle == 'idPlusDesc'"
            ><i class="fas fa-check"></i></span
        ></a>
      </li>
      <li>
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="setHeaderStyle('id')"
          :class="{ selected: configuration.headerStyle == 'id' }"
          >{{ $t("2258")
          }}<span class="pull-right" v-if="configuration.headerStyle == 'id'"
            ><i class="fas fa-check"></i></span
        ></a>
      </li>
      <li>
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="setHeaderStyle('desc')"
          :class="{ selected: configuration.headerStyle == 'desc' }"
          >{{ $t("3505")
          }}<span class="pull-right" v-if="configuration.headerStyle == 'desc'"
            ><i class="fas fa-check"></i></span
        ></a>
      </li>
      <li>
        <h6 class="dropdown-header">{{ $t("905") }}</h6>
      </li>
      <li>
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="setGroupBy(null, null)"
          :class="{ selected: configuration.headerStyle == null }"
        >
          -
          <span class="pull-right" v-if="configuration.headerStyle == null"
            ><i class="fas fa-check"></i></span
        ></a>
      </li>
      <li>
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="setGroupBy('zone', '331')"
          :class="{ selected: configuration.headerStyle == 'zone' }"
          >{{ $t("331")
          }}<span class="pull-right" v-if="configuration.headerStyle == 'zone'"
            ><i class="fas fa-check"></i></span
        ></a>
      </li>
      <li>
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="setGroupBy('category', '332')"
          :class="{ selected: configuration.headerStyle == 'cat' }"
          >{{ $t("332")
          }}<span class="pull-right" v-if="configuration.headerStyle == 'cat'"
            ><i class="fas fa-check"></i></span
        ></a>
      </li>
      <li>
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="setGroupBy('department', '2')"
          :class="{ selected: configuration.headerStyle == 'department' }"
          >{{ $t("2")
          }}<span class="pull-right" v-if="configuration.headerStyle == 'department'"
            ><i class="fas fa-check"></i></span
        ></a>
      </li>
       <li>
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="flipOrderDir"
          >{{$t('1783')}}: {{ configuration.orderDir == 'desc' ? 'desc' : 'asc' }}
        </a>
      </li>
      <li>
        <h6 class="dropdown-header">{{ $t("497") }}</h6>
      </li>
      <li>
        <a href="#" class="dropdown-item" @click="advancedOrdering">{{
          $t("2254")
        }}</a>
      </li>
    </sort-select>
    <my-machines-select v-if="loctype === 'mine'"></my-machines-select>
    <div
      class="row row-24"
      v-for="(group, groupKey) in groupedMachines"
      :key="groupKey"
    >
      <h3 v-if="configuration.groupBy">
        {{ $t(configuration.groupByTerm) }}: {{ groupKey }}
      </h3>
      <production-status
        v-for="machine in group"
        :key="machine.machine_id"
        :machine="machine"
        :style="{ header: configuration.headerStyle }"
      ></production-status>
    </div>
    <router-view></router-view>
    <div class="popup-wrap" @click.self="back" v-if="orderingPopup">
      <div class="popup">
        <div class="row">
          <div class="col-md-6">
            <h2>{{ $t("2254") }}</h2>
            <div class="order-select-wrap">
              <a
                class="order-select"
                v-for="k in machineKeys"
                @click="setOrderBy(k);back();"
                :key="k"
                :class="{selected:k == configuration.orderByTerm}"
                href="#"
                >{{ k }}</a
              >
            </div>
          </div>
          <div class="col-md-6">
            <h2>{{ $t("905") }}</h2>
            <div class="order-select-wrap">
              <a
                class="order-select"
                v-for="k in machineKeys"
                @click="setGroupBy(k,k);back();"
                :class="{selected:k == configuration.groupBy}"
                :key="k"
                href="#"
                >{{ k }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SortSelect from "@/components/sort-select";
import MyMachinesSelect from "@/components/my-machines-select";
import UncodedStopsIndicator from "@/components/uncoded-stops-indicator.vue";
import ProductionStatus from "@/components/overview-widgets/ProductionStatus.vue";
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import _ from "lodash";
import { getStorageWithDefault, setStorage } from "@/helpers/storage";
import { Configuration } from "@/helpers/settings";

import { Pulse } from "@/helpers/heartbeat";
export default {
  setup() {
    return {
      selectedRows: [],
    };
  },
  data() {
    return {
      highlights: [],
      reversePeriods: {
        today: "447",
        shift: "245",
        "24h": "448",
      },
      configuration: new Configuration(
        "machines-widget-overview",
        {
          headerStyle: "idPlusDesc",
          groupBy: null,
          groupByTerm: null,
          orderByTerm: "object_description",
          orderDir:"asc"
        },
        "machine_overviews_default"
      ),
      orderingPopup: false,
      pulse:null,
      //groupBy:"category",
      //groupBy:"department",
      //groupBy: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch("config/loadMachineSorting");
    
    if (store.state.machines.loaded) {
      next();
    } else {
      store.dispatch("machines/loadMachines").then(() => {
        next();
      });
    }
  },
  mounted() {
    store.commit("bottomnav/disable");
    this.pulse = new Pulse([
      {
        action:"orders/loadAllRunningOrders",
        interval:300
      },
      /*
       {
        action:"orders/loadAllPlannedOrders",
        interval:300
      }
      */
    ])
    this.checkValidLocation();
  },
  beforeUnMount(){
    this.pulse?.stop();
  },
  methods: {
    checkValidLocation() {
      let redirectNeeded = false;
      let locationtype = "department";
      console.log("checking valid location", this.$route);
      if (this.$route.meta.group === "all") {
        return true;
      }
      //check if locationtype is valid (department/category/zone)
      if (!["department", "category", "zone", "mine"].includes(this.loctype)) {
        redirectNeeded = true;
      } else {
        locationtype = this.loctype;
      }
      let location = this["available" + locationtype][0];
      //check if location is valid
      if (!this["available" + locationtype].includes(this.loc)) {
        redirectNeeded = true;
      } else {
        location = this.loc;
      }
      if (redirectNeeded) {
        if (this.loctype === "undefined" && this.loc === "undefined") {
          this.$router.push("/machines/all");
        } else {
          this.$router.push("/machines/" + locationtype + "/" + location);
        }
      }
    },
    setHeaderStyle(style) {
      this.configuration.setValues({ headerStyle: style });
      this.configuration.saveValues();
    },
    setGroupBy(groupBy, trans) {
      this.configuration.setValues({ groupBy, groupByTerm: trans });
      this.configuration.saveValues();
    },
    setOrderBy(term){
      this.configuration.setValues({orderByTerm:term});
      this.configuration.saveValues();
    },
    advancedOrdering() {
      this.orderingPopup = true;
    },
    flipOrderDir(){
      this.configuration.setValues({orderDir:this.configuration.orderDir == 'asc' ? 'desc' : 'asc'});
      this.configuration.saveValues();
    },
    back(){
      this.orderingPopup = false;
    }
  },
  computed: {
    ...mapState({
      //machines: (state) => state.machines.index,
      configs: (state) => state.machines.config,
      machinesLoaded: (state) => state.machines.loaded,
      languages: (state) => state.languages,
      config: (state) => state.config,
    }),
    ...mapGetters({
      machines: "machines/nonResourceMachines",
    }),
    machineKeys(){
      return Object.keys(this.machines[0]).sort();
    },
    reversePeriod() {
      return this.reversePeriods[this.config.periods.machines] || "447";
    },
    loctype() {
      return this.$route.params.locationtype;
    },
    loc() {
      return this.$route.params.location;
    },
    availablemine() {
      return ["all"];
    },
    availabledepartment() {
      let unique = [...new Set(this.machines.map((item) => item.department))];
      return _.filter(unique, (department) => {
        return department !== undefined && department != "";
      });
    },
    availablezone() {
      let unique = [...new Set(this.machines.map((item) => item.zone))];
      return _.filter(unique, (zone) => {
        return zone !== undefined && zone != "";
      });
    },
    availablecategory() {
      let unique = [...new Set(this.machines.map((item) => item.category))];
      return _.filter(unique, (category) => {
        return category !== undefined && category != "";
      });
    },
    myMachines() {
      let config = this.config;
      return this.machines.filter((m) => {
        return config.myMachines.includes(m.machine_id);
      });
    },
    filteredMachines() {
      if (this.$route.meta.group === "all") {
        return this.machines;
      }
      if (this.loctype === "mine") {
        return this.myMachines;
      }
      let locationtype = this.loctype;
      let location = this.loc;
      return _.filter(this.machines, (m) => {
        return m[locationtype] == location;
      });
    },
    sortedMachines() {
      return _.orderBy(this.filteredMachines, this.configuration.orderByTerm, this.configuration.orderDir);
    },
    groupedMachines() {
      return this.configuration.groupBy
        ? _.groupBy(this.sortedMachines, this.configuration.groupBy)
        : [this.sortedMachines];
    },
  },
  watch: {
    $route(to) {
      //store.dispatch('machines/randomCycles');
      console.log(to);
      if (to.matched[0].name === "machines") {
        this.checkValidLocation();
      }
    },
  },
  components: {
    SortSelect,
    MyMachinesSelect,
    UncodedStopsIndicator,
    ProductionStatus,
  },
};
</script>

<style scoped lang="scss">
.order-select-wrap{
  max-height:400px;
  overflow-y:scroll;
}
.order-select{
  display:block;
  padding:5px 10px;
  text-align:left;
  color:black;
  text-decoration:none;
  &.selected,&:hover{
    background:black;
    color:white;
  }
}
</style>
