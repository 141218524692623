<template>
  <div>
    <numerical-input v-model="data[tab.dataKey].value"></numerical-input>

    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>
<script>
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  computed: {
    disableNext() {
      return (
        this.data[this.tab.dataKey].value === null ||
        this.data[this.tab.dataKey].value === "0."
      ) && (this.data[this.tab.dataKey].allowNull === undefined || !this.data[this.tab.dataKey].allowNull);
    },
    currentValue() {
      return this.data[this.tab.dataKey].value;
    },
    max() {
      return this.data[this.tab.dataKey].max || 0;
    },
  },
  watch: {
    currentValue(val) {
      if (
        this.max != 0 &&
        this.max != undefined &&
        val > this.max
      ) {
        this.data[this.tab.dataKey].value = this.max;
      }
    },
  },
};
</script>