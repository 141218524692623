<template>
    <div>
        <h1>{{data.line_no !== undefined && data.line_no != "-"  ? data.line_no+" - " : ""}}{{data.machine_id}}</h1>
        <p>{{data.object_description}}</p> 
    </div>
</template>
<script>
export default {
    props:[
        'data'
    ]
}
</script>