<template>
  <div
    class="summary bordered thick vertical-split left-aligned c-border"
    :class="status"
  >
    <div class="stretch align-left">
      <div class="row">
        <div
          class="col col-12 large-text limited-width"
          v-if="showMachineId"
        >
          <h3 class="present-value bold">
            {{ machine.machine_id }} {{ machine.object_description }}
          </h3>
        </div>
        <div class="col-6">
          <p class="label-value">{{ $t("24") }}</p>
          <p class="present-value bold limited-width">
            {{ machine.work_order_no }}
          </p>
        </div>

        <div class="col-6">
          <p class="label-value">{{ orderFinishedPercentage?.toFixed(0) + '%' }} {{$t('2857')}}</p>
          <div class="progress">
            <div
              class="fill"
              :style="{ width: orderFinishedPercentage + '%' }"
            ></div>
          </div>
        </div>
        <div class="col col-12" v-if="machine.part_no != '-'">
          <p class="label-value">{{ $t("604") }}</p>
          <p class="present-value bold limited-width">
            {{ machine.part_no }} {{ machine.part_description }}
          </p>
        </div>
        <div class="col-6">
          <p class="label-value">{{ $t("1640") }}</p>
          <p class="present-value bold"><flex-value :value="TAK" />%</p>
        </div>

        <div class="col-6">
          <p class="label-value">{{ $t(isRunning ? '7078' : '2121' ) }}</p>
          <p class="present-value bold">
            {{ displayTime }}
          </p>
        </div>
      </div>
    </div>
    <div class="bottom c-color large-text" :class="status">
      <div class="horizontal-split" v-if="isRunning">
        <div style="line-height: 44px"><i class="fas fa-play"></i></div>
        <div class="limited-width">
          <div class="small-headers limited-width">
            &nbsp;{{ $t("5856") }}/{{ $t("5855") }}
          </div>
          <div class="values">
            &nbsp;<flex-value
              :value="machine.real_speed"
              :digits="4"
            />/<flex-value
              :value="machine.planned_cycle_time"
              :digits="4"
            /><span class="small-headers">SEK</span>
          </div>
        </div>
      </div>
      <div class="horizontal-split" v-else>
        <div><i class="fas fa-stop"></i></div>
        <div class="limited-width">
          &nbsp;{{ machine.current_stop_reason }} {{ machine.stop_description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { secondsToHhMm } from "@/helpers/dates";
import { InterfaceComponent } from "@/helpers/machine";

export default InterfaceComponent(
  {
    props: {
      machine_id:{

      },
      period:{
        
      }
    },
    computed: {
      status() {
        return this.isRunning
          ? "c-green"
          : "c-" + this.machine.stop_reason_color?.toLowerCase();
      },
      isRunning() {
        return this.machine.status !== "S";
      },
      TAK() {
        switch (this.period) {
          case "shift":
            return this.machine.tak_percent_shift;
            break;
          case "24h":
            return this.machine.tak_percent_24;
            break;
          case "today":
            return this.machine.tak_percent_today;
            break;
          case "calendar":
            return this.machine.tak_percent_calendar;
            break;
        }
      },
      displayTime() {
        return secondsToHhMm(this.displaySeconds);
      },
      displaySeconds() {
        return this.isRunning
          ? this.machine.runtime_after_stop_seconds
          : this.machine.calendar_stop_time_duration;
      },
      orderFinishedPercentage() {
        return (
          (this.machine.card_good_qty / this.machine.work_order_qty) * 100 || 0
        );
      },
      showMachineId(){
        return this.$route.params?.machine === undefined
      }
    },
  },
  []
);
</script>

