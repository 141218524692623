<template>
    <div>
        <h1>{{data.stop_description}}</h1>
        <p>{{data.current_stop_reason || data.stop_reason}} </p>

    </div>
</template>
<script>
export default {
    props:[
        'data'
    ],
    computed:{
        stopcodelink(){
            return "/machines/"+this.$route.params.locationtype+"/"+this.$route.params.location+"/stopcode/"+this.data.machine_id;
        }
    }
}
</script>
<style scoped lang="scss">
    h1,p{
        margin:0;padding:0;
        text-align:left;
    }
    h1{
        font-size:20px;
        font-weight:bold;
        @media screen and (max-width:600px){
            font-size:14px;
        }
    }
    p{
        font-size:11px;
        white-space: nowrap;
    }
</style>