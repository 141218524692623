const premadeColumns = {
    machines: [
        { component: "status", label: "S", class: "minimal", key: "status", pre: true },
        { component: "b-article", tlabel: "107", key: "article", pre: true },
        { component: "stopcode", tlabel: "2322", key: "current_stop_reason", pre: true },
        { tlabel: "755", component: "tPercent", class: "minimal", key: "T", pre: true },
        { tlabel: "1898", component: "aPercent", class: "minimal", key: "A", pre: true },
        { tlabel: "1639", component: "kPercent", class: "minimal", key: "K", pre: true },
        { tlabel: "1640", component: "takPercent", key: "TAK", pre: true },
        { tlabel: "2228", component: "cycles", key: "cycles", pre: true },
        { tlabel: "463", key: "calendar_stop_time_duration_hh_mm", pre: true },
        { component: "machineId", tlabel: "1", label: "Machine", key: "machine_id", pre: true },
        { component: "machineDescription", tlabel: "1", label: "Machine", key: "object_description", pre: true },
        { tlabel: "24", key: "work_order_no", pre: true, component:"b-text-singline" },
        {
            label: "AS%", tlabel: "1933", component: "color-box", class: "small-label", key: "a_percent_shift",
            config: {
                limitType: "clamp",
                greenLimit: "speed_deviation_green_limit",
                yellowLimit: "speed_deviation_yellow_limit",
                clampAround: 100,
                grayValue: 0
            }, pre: true
        },
        {
            label: "AT%", tlabel: "1934", component: "color-box", class: "small-label", key: "a_percent_today",
            config: {
                limitType: "clamp",
                greenLimit: "speed_deviation_green_limit",
                yellowLimit: "speed_deviation_yellow_limit",
                clampAround: 100,
                grayValue: 0,
                class: "",
                transformTo: "fixed"
            }, pre: true
        },
        {
            component: "warning",
            tlabel: "756",
            key: "last_id_key",
            pre: true,
        },
        {
            label: "A24%", tlabel: "1931", component: "color-box", class: "small-label", key: "a_percent_24",
            config:
            {
                limitType: "clamp",
                greenLimit: "speed_deviation_green_limit",
                yellowLimit: "speed_deviation_yellow_limit",
                clampAround: 100,
                grayValue: 0,
                class: "",
                transformTo: "fixed"
            }, pre: true
        },
        {
            pre: true,
            key: "machine_priority",
            component: "machine-priority",
            label: "MP",
        },

        {
            component: "ia-status",
            label: "IA",
            key: "ia-status",
            pre: false,
            configOptions: {
                selection: "string"
            },
            config: {

            },
            dupplicable: true
        },
        {
            component: "production-goal",
            label: "PG",
            key: "production-goal",
            pre: false,
            configOptions: {
                type: "select",
                location: "select",
                target: "select"
            },
            options: {
                type: "productionGoalTypes",
                location: "productionGoalLocations",
                target: "productionGoalTargets"
            },
            config: {

            },
            dupplicable: true
        }, {
            component: "production-goal-text",
            label: "PG",
            key: "production-goal-text",
            pre: false,
            configOptions: {
                type: "select",
                location: "select",
                target: "select"
            },
            options: {
                type: "productionGoalTypes",
                location: "productionGoalLocations",
                target: "productionGoalTargets"
            },
            config: {

            },
            dupplicable: true
        },


        { label: "S", component: "uncodedStatus24h", class: "minimal", key: "uncoded_stops_24h", pre: true },
        { label: "S", component: "uncodedStatus24hShift", class: "minimal", key: "uncoded_stops_shift24h", pre: true },

    ],
    materials: [
        {
            key: "material_no",
            component: "double-row",
            tlabel: "1225",
            config: {
                val1: "material_no",
                val2: "material_desc",
            },
            pre: true
        },
        {
            key: "material_desc",
            component: "double-row",
            tlabel: "1225",
            config: {
                val1: "material_desc",
                val2: "material_no",
            },
            pre: true
        }, 
        {
            key: "material_planned_qty",
            tlabel: "503",
            pre: true
        }, 
        {
            key: "qty_per_piece",
            tlabel: "7036",
            pre: true
        }, 
        {
            key: "material_used_qty",
            tlabel: "700",
            pre: true
        }, {
            key: "material_balance",
            tlabel: "1229",
            pre: true
        },
    ],
    notes: [
        {
            key: 'note_type',
            tlabel: '361',
            component: 'note-type',
            pre: true
        }, {
            key: 'note',
            tlabel: '638',
            component: 'b-text-long',
            pre: true
        }, {
            key: 'signature',
            tlabel: '120',
            component: 'b-text-short',
            pre: true
        }, {
            key: 'trans_time',
            tlabel: '180',
            component: 'date',
            config: {
                short: true
            },
            pre: true
        }
    ],
    uncodedStops: [
        { component: "machineId", tlabel: "1", label: "Machine", key: "machine_id", pre: true },
        { tlabel: "2322", component: "stopcode", key: "current_stop_reason", pre: true },
        { tlabel: "6001", component: "minmax", key: "time", pre: true },
        { tlabel: "2234", key: "tot_stop_time_formatted", pre: true },
        {
            key: "note",
            tlabel: "638",
            pre: true
        }
    ],
    runningOrders: [
        {
            //tlabel:null,
            tlabel: "24",
            label: "order",
            component: "double-row",
            key: "order",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            pre: true
        },
        {
            //tlabel:null,
            tlabel: "107",
            label: "artikel",
            component: "double-row",
            key: "part",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true
        },
        {
            tlabel: "574",
            label: "artikel",
            component: "double-row",
            key: "antal",
            config: {
                val1: "card_good_qty",
                val2: "planned_op_qty",
            },
            pre: true
        },
        {
            tlabel: '41',
            label: "dates",
            component: "double-row",
            key: "dates",
            config: {
                val1: "start_time",
                val2: "original_planned_end_time",
                dates: true,
                short: true
            },
            pre: true
        },
        {
            tlabel: "1767",
            class: "h30",
            key: "card_no",
            pre: true
        },
        {
            component: "order-document",
            tlabel: "871",
            key: "_order-document",
            pre: true,
        },
    ],
    plannedOrders: [
        {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            key: "order",
            pre: true
        },
        {
            tlabel: "107",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            key: "part",
            pre: true
        },
        {
            tlabel: "574",
            component: "double-row",
            config: {
                val1: "produced_op_qty",
                val2: "planned_op_qty",
            },
            key: "antal",
            pre: true
        },
        {
            tlabel: "2704",
            component: "double-row",
            config: {
                val1: "wo_remaining_op_qty",
                val2: "estimated_remaining_time_seconds",
                format2: "hhmm",
                class: "samesize",
            },
            key: "restantal",
            pre: true
        },
        {
            tlabel: "6286",
            component: "double-row",
            config: {
                val1: "planned_op_qty_unit1",
                val2: "card_good_qty_unit1",
                format1: "flexdec",
                format2: "flexdec",
                class: "samesize",
            }, key: "antal1",
            pre: true
        },
        {
            tlabel: "6287",
            component: "double-row",
            config: {
                val1: "planned_op_qty_unit2",
                val2: "card_good_qty_unit2",
                format1: "flexdec",
                format2: "flexdec",
                class: "samesize",
            }, key: "antal2",
            pre: true
        },
        {
            tlabel: "2699",
            component: "double-row",
            config: {
                val1: "planned_start_time",
                val2: "planned_end_time",
                dates: true,
                compDates: true,
                short: true,
            }, key: "dates",
            pre: true
        },
        {
            tlabel: "1767", key: "card_no",
            pre: true
        }, {
            pre: true,
            key: "order_priority",
            component: "order-priority",
            label: "OP",
        },
        {
            pre: true,
            key: "material_status",
            component: "material-status",
            label: "M",
        },
        {
            component: "order-document",
            tlabel: "871",
            key: "_order-document",
            pre: true,
        },
    ],
    finishedOrders: [
        {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            key: "order",
            pre: true,
        },
        {
            tlabel: "52",
            component: "double-row",
            config: {
                val1: "end_time",
                val2: "original_planned_end_time",
                dates: true,
                compDates: false,
                short: true,
            },
            key: "dates",
            pre: true,
        },
        {
            tlabel: "107",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            key: "part",
            pre: true,
        },
        {
            tlabel: "574",
            component: "double-row",
            config: {
                val1: "produced_op_qty",
                val2: "planned_qty",
            },
            key: "antal",
            pre: true,
        },
        {
            tlabel: "1767",
            key: "card_no",
            pre: true,
        },
        {
            label: "A%",
            key: "a_percent",
            class: "h30",
            pre: true,
        },
    ],
    historyTransactions: [
        {
            label: "total_stops",
            key: "total_stops",
            class: "h30",
            pre: true,
        },
        {
            label: "run_time_seconds",
            key: "run_time_seconds",
            class: "h30",
            pre: true,
        },
        {
            label: "stop_time_seconds",
            key: "stop_time_seconds",
            class: "h30",
            pre: true,
        },
    ],
    historicalOrders: [
        {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            key: "order",
            pre: true,
        },
        {
            tlabel: "52",
            component: "double-row",
            config: {
                val1: "end_time",
                val2: "original_planned_end_time",
                dates: true,
                compDates: false,
                short: true,
            },
            key: "dates",
            pre: true,
        },
        {
            tlabel: "107",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            key: "part",
            pre: true,
        },
        {
            tlabel: "574",
            component: "double-row",
            config: {
                val1: "produced_op_qty",
                val2: "planned_op_qty",
            },
            key: "antal",
            pre: true,
        },
        {
            tlabel: "1767",
            key: "card_no",
            pre: true,
        },
        {
            label: "A%",
            key: "a_percent",
            class: "h30",
            pre: true,
        },
    ],
    goodQuantity: [
        {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "card_no",
            },
            key: "work_order_no",
            pre: true,
        },
        {
            tlabel: "604",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            key: "part_no",
            pre: true,
        },
        {
            tlabel: "27",
            component: "date",
            config: {
                short: true
            },
            key: "trans_time",
            pre: true,
        },
        {
            tlabel: "581",
            component: "round",
            config: {
                fixedTo: 1
            },
            key: "good_qty",
            pre: true,
        },
        {
            tlabel: "578",
            key: "employee",
            pre: true,
        },
    ],
    scrapQuantity: [
        {
            //tlabel:null,
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "card_no",
            },
            pre: true,
            key: "work_order_no",
        },
        {
            //tlabel:null,
            tlabel: "604",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
            key: "part_no",
        },
        {
            //tlabel:null,
            tlabel: "27",
            component: "date",
            pre: true,
            key: "trans_time",
        },
        {
            tlabel: "19",
            pre: true,
            key: "description",
        },
        {
            tlabel: "638",
            pre: true,
            key: "note",
        },
        {
            tlabel: "2712",
            pre: true,
            key: "scrap_qty",
        },
        {
            tlabel: "2734",
            pre: true,
            key: "scrap_qty_real",
        },
        {
            tlabel: "578",
            pre: true,
            key: "employee",
        },
    ],
    palletFlags: [
        {
            tlabel: "1767",
            pre: true,
            key: "card_no",
        },
        {
            tlabel: "107",
            label: "artikel",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
            key: "part",
        },
        {
            tlabel: "574",
            label: "artikel",
            component: "double-row",
            config: {
                val1: "good_qty",
            },
            pre: true,
            key: "antal",
        },
        {

            pre: true,
            key: "pallet_no",
        },
        {
            tlabel: "41",
            component: "date",
            config: {
                short: true,
            },
            pre: true,
            key: "date",
        },
        {
            label: "S",
            component: "pallet-flag-status",
            pre: true,
            key: "pallet_flag_status"
        },
        {
            tlabel: "578",
            pre: true,
            key: "employee",
        },
    ],
    internalActivities: [
        {
            tlabel: "179",
            class: "h50",
            pre: true,
            key: "machine_id",
        },
        {
            tlabel: "899",
            class: "h50",
            pre: true,
            key: "action_description",
        },
        {
            tlabel: "1042",
            class: "h50",
            component: "date",
            config: {
                short: true,
            },
            pre: true,
            key: "due_date",
        },
        {
            tlabel: "2484",
            class: "h50",
            pre: true,
            key: "type_description",
        },
    ],
    quickReports: [
        {
            tlabel: "3861",
            component: "translation",
            pre: true,
            key: "report_translation",
        },
        {
            tlabel: "5723",
            pre: true,
            key: "setting_name",
        },
        {
            tlabel: "3561",
            pre: true,
            key: "user_name",
        },
        {
            tlabel: "3864",
            component: "date",
            config: {
                short: true,
            },
            pre: true,
            key: "most_recently_generated",
        },
        {
            tlabel: "3863",
            component: "date",
            config: {
                short: true,
            },
            pre: true,
            key: "next_report_run",
        },
    ],
    productionGoals: [
        {
            tlabel: "462",
            component: "date",
            key: "target_start_time",
            config: {
                short: true,
            },
            pre: true,
        },
        {
            tlabel: "463",
            component: "date",
            key: "target_end_time",
            config: {
                short: true,
            },
            pre: true,
        },
        {
            tlabel: "573",
            component: "location-target",
            key: "location_target",
            pre: true,
        },
        {
            tlabel: "729",
            component: "production-target",
            key: "production_target",
            pre: true,
        }
    ],
    articles: [
        {
            tlabel: "1886",
            class: "h50 nowrap",
            key: "part_no",
            pre: true
        },
        {
            tlabel: "394",
            class: "h50",
            key: "part_description",
            pre: true
        },
        {
            tlabel: "603",
            class: "h50",
            key: "op_no",
            pre: true
        },
    ],
    cavityHistory: [
        {
            tlabel: "777",
            pre: true,
            key: "tool_id"
        },
        {
            tlabel: "785",
            pre: true,
            key: "insert_location"
        },
        {
            tlabel: "784",
            pre: true,
            key: "insert_id"
        },
        {
            tlabel: "41",
            pre: true,
            key: "log_date",
            component: "date"
        },
        {
            tlabel: "631",
            pre: true,
            key: "reason"
        },
        {
            tlabel: "578",
            pre: true,
            key: "employee"
        },
        {
            tlabel: "28",
            pre: true,
            key: "activity"
        }
    ],
    runTime:[
        {
            key:"day_date",
            pre:true,
            component:"date"
        },
        {
            key:"shift_desc"
        }
    ]

};

export { premadeColumns }