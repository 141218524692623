<template>
  <div class="col-12 col-xl-9 col-lg-8 col-md-12 widget">
    <planned-order-base></planned-order-base>
  </div>
</template>
<script>
import PlannedOrderBase from "./base/PlannedOrdersBase.vue"

export default {
  components: {
    PlannedOrderBase,
  },
};
</script>