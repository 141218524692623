<template>
  <span class="flex-value">{{displayValue}}</span>
</template>
<script>
export default {
  props: {
      value:{
          required:true,
      },
      digits:{
          default:3
      }
  },
  computed: {
    displayValue() {
       let parsed = parseFloat(this.value);
        if (parsed >= 10**(this.digits-1) || parsed % 1 === 0){
            return Math.round(parsed);
        }
        for (let d = this.digits - 2;d > 0;d--){
            if (parsed >= 10**d){
                return parsed.toFixed(this.digits-d-1);
            }
        }
        return parsed.toFixed(this.digits-1);
    },
  },
  methods: {
  },
};
</script>
<style scoped>
</style>