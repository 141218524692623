<template>
  <div v-if="goal" class="value">
    <h1>
      {{ currentResultValue }}/{{ currentTargetValue }}({{ currentDiffValue }})
    </h1>
  </div>
</template>
<script>
import { Pulse } from "@/helpers/heartbeat";
import { mapGetters, mapState } from "vuex";
import SvgArrow from "@/components/svg-arrow";
export default {
  props: ["data", "config"],
  data() {
    return {
      pulse: null,
      targetMap: {
        target_good_qty: "result_good_qty",
        target_good_qty_alt_unit: "result_good_qty_alt_unit",
        target_earned_hours: "result_earned_hours",
        //target_scheduled_hours: "result_consumed_scheduled_hours",
      },
      diffMap: {
        target_good_qty: "diff_good_qty",
        target_good_qty_alt_unit: "diff_qty_alt_unit",
        target_earned_hours: "diff_earned_hours",
        //target_scheduled_hours: "diff_earned_hours",
      },
    };
  },
  mounted() {
    if (this.data?.machine_id == undefined) {
      //error
    }
    //pulse on -> activities/loadActivitiesForSelectionOwner,{selectionOwner:auth.state.user}
    this.pulse = new Pulse({
      action: "goals/loadProductionGoals",
      interval: 60,
    });
  },
  beforeUnmount() {
    //pulse off
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.username,
    }),
    ...mapGetters({
      specificGoals: "goals/specificGoals",
    }),
    goals() {
      return this.specificGoals(
        this.config.location?.indexOf("_id") > -1
          ? this.config.location
          : this.config.location + "_id",
        this.currentMachineValues,
        this.config.target,
        this.config.type || null
      );
    },
    goal() {
      return this.config.location && this.config.target
        ? this.goals[0]
        : null || null;
    },
    currentMachineValues() {
      return {
        machine_id: this.data?.machine_id,
        category_id: this.data?.category,
        zone_id: this.data?.zone,
        department_id: this.data?.department,
      };
    },
    currentDrawingValue() {
      return [
        {
          color: "green",
          per:
            (this.currentResultValue -
              (this.currentDiffValue > 0 ? this.currentDiffValue : 0)) /
            this.currentTargetValue,
        },
        {
          color: this.currentDiffValue < 0 ? "red" : "lightgreen",
          per: Math.abs(this.currentDiffValue / this.currentTargetValue),
        },
        {
          color: "gray",
          per: 1,
        },
      ];
    },
    currentResultValue() {
      return this.goal?.[this.targetMap[this.config.target]] || 0;
    },
    currentDiffValue() {
      return this.goal?.[this.diffMap[this.config.target]] || 0;
    },
    currentTargetValue() {
      return this.goal?.[this.config.target] || 0;
    },
  },
  components: {
    SvgArrow,
  },
};
</script>