const historyConfigs = {
    history_week: {
        week_number: {
            label: "week_number",
            key: "week_number",
            class: "h30",
            pre: true,
        },
        total_stops: {
            label: "total_stops",
            key: "total_stops",
            class: "h30",
            pre: true,
        },
        run_time_seconds: {
            label: "run_time_seconds",
            key: "run_time_seconds",
            class: "h30",
            pre: true,
        },
        stop_time_seconds: {
            label: "stop_time_seconds",
            key: "stop_time_seconds",
            class: "h30",
            pre: true,
        },

    },
    history_day: {
        trans_date: {
            label: "trans_date",
            key: "trans_date",
            class: "h30",
            component:"date",
            config:{
                dayOnly:true
            },
            pre: true,
        },
        total_stops: {
            label: "total_stops",
            key: "total_stops",
            class: "h30",
            pre: true,
        },
        run_time_seconds: {
            label: "run_time_seconds",
            key: "run_time_seconds",
            class: "h30",
            pre: true,
        },
        stop_time_seconds: {
            label: "stop_time_seconds",
            key: "stop_time_seconds",
            class: "h30",
            pre: true,
        },
    },
    history_hour: {
        trans_date_hour: {
            label: "hour",
            key: "trans_time",
            class: "h30",
            component:"date",
            config:{
                hourOnly:true
            },
            pre: true,
        },
        total_stops: {
            label: "total_stops",
            key: "total_stops",
            class: "h30",
            pre: true,
        },
        tot_run_time: {
            label: "tot_run_time",
            key: "tot_run_time",
            class: "h30",
            pre: true,
        },
        tot_stop_time: {
            label: "tot_stop_time",
            key: "tot_stop_time",
            class: "h30",
            pre: true,
        },
        trans_time: {
            label: "trans_time",
            key: "trans_time",
            class: "h30",
            pre: true,
        },
    },
    history_transactions: {
        record_no: {
            label: "record_no",
            key: "record_no",
            class: "h30",
            pre: true,
        },
        trans_time: {
            label: "trans_time",
            key: "trans_time",
            class: "h30",
            pre: true,
        },
        io0: {
            label: "io0",
            key: "io0",
            class: "h30",
            pre: true,
        },
        io1: {
            label: "io1",
            key: "io1",
            class: "h30",
            pre: true,
        },
        io2: {
            label: "io2",
            key: "io2",
            class: "h30",
            pre: true,
        },
        io3: {
            label: "io3",
            key: "io3",
            class: "h30",
            pre: true,
        },
        io4: {
            label: "io4",
            key: "io4",
            class: "h30",
            pre: true,
        },
        io5: {
            label: "io5",
            key: "io5",
            class: "h30",
            pre: true,
        },
        io6: {
            label: "io6",
            key: "io6",
            class: "h30",
            pre: true,
        },
        io7: {
            label: "io7",
            key: "io7",
            class: "h30",
            pre: true,
        },
        io8: {
            label: "io8",
            key: "io8",
            class: "h30",
            pre: true,
        },
        io9: {
            label: "io9",
            key: "io9",
            class: "h30",
            pre: true,
        },
        status: {
            label: "status",
            key: "status",
            class: "h30",
            pre: true,
        },
        cycles: {
            label: "cycles",
            key: "cycles",
            class: "h30",
            pre: true,
        },
    },
}

const defaultTableConfigs = {
    ...historyConfigs,
    machines: {
        status: {
            component: "status",
            label: "S",
            class: "minimal",
            pre: true
        },
        machine_id: {
            component: "machineId",
            tlabel: "1",
            label: "Machine",
            pre: true
        },
        current_stop_reason: {
            tlabel: "2322",
            component: "stopcode",
            pre: true
        },
        calendar_stop_time_duration_hh_mm: {
            tlabel: "463",
            pre: true
        },
        cycles: {
            tlabel: "2228",
            component: "cycles",
            pre: true
        },
        T: {
            tlabel: "755",
            component: "tPercent",
            class: "minimal",
            pre: true
        },
        A: {
            tlabel: "1898",
            component: "aPercent",
            class: "minimal",
            pre: true
        },
        K: {
            tlabel: "1639",
            component: "kPercent",
            class: "minimal",
            pre: true
        },
        TAK: {
            tlabel: "1640",
            component: "takPercent",
            pre: true
        },
        work_order_no: {
            tlabel: '24',
            pre: true
        },
        article: {
            component: 'b-article',
            tlabel: '107',
            pre: true
        },
        primakass: {
            component: 'primakass',
            tlabel: '574',
            pre: true
        },
        K2: {
            tlabel: "1639",
            component: "kPercent",
            class: "minimal",
            pre: true
        },
        TAK2: {
            tlabel: "1640",
            component: "takPercent",
            pre: true
        },
        work_order_no2: {
            tlabel: '24',
            pre: true
        },
        article2: {
            component: 'b-article',
            tlabel: '107',
            pre: true
        },
        primakass2: {
            component: 'primakass',
            tlabel: '574',
            pre: true
        },
        order_rem_hours_hh_mm: {
            tlabel: '2012',
            component: 'orderRemaining',
            pre: true
        },
        uncoded_stops_24h: {
            tlabel: "5824",
            pre: true
            //component:"uncodedStops24h"
        },
    },
    line_machines_widget: {
        status: {
            component: "status",
            label: "S",
            class: "minimal",
            pre: true
        },
        machine_id: {
            component: "machineId",
            tlabel: "1",
            label: "Machine",
            pre: true
        },
        current_stop_reason: {
            tlabel: "2322",
            component: "stopcode",
            pre: true
        },
        calendar_stop_time_duration_hh_mm: {
            tlabel: "463",
            pre: true
        },
        cycles: {
            tlabel: "2228",
            component: "cycles",
            pre: true
        },
        T: {
            tlabel: "755",
            component: "tPercent",
            class: "minimal",
            pre: true
        },
        A: {
            tlabel: "1898",
            component: "aPercent",
            class: "minimal",
            pre: true
        },
        K: {
            tlabel: "1639",
            component: "kPercent",
            class: "minimal",
            pre: true
        },
        TAK: {
            tlabel: "1640",
            component: "takPercent",
            pre: true
        },
        work_order_no: {
            tlabel: '24',
            pre: true
        },
        article: {
            component: 'b-article',
            tlabel: '107',
            pre: true
        },
        primakass: {
            component: 'primakass',
            tlabel: '574',
            pre: true
        },
        K2: {
            tlabel: "1639",
            component: "kPercent",
            class: "minimal",
            pre: true
        },
        TAK2: {
            tlabel: "1640",
            component: "takPercent",
            pre: true
        },
        work_order_no2: {
            tlabel: '24',
            pre: true
        },
        article2: {
            component: 'b-article',
            tlabel: '107',
            pre: true
        },
        primakass2: {
            component: 'primakass',
            tlabel: '574',
            pre: true
        },
        order_rem_hours_hh_mm: {
            tlabel: '2012',
            component: 'orderRemaining',
            pre: true
        },
        uncoded_stops_24h: {
            tlabel: "5824",
            pre: true
            //component:"uncodedStops24h"
        },
    },
    machines_a_active: {
        status: {
            component: "status",
            label: "S",
            class: "minimal",
            pre: true
        },
        machine_id: {
            component: "machineId",
            tlabel: "1",
            label: "Machine",
            pre: true
        },
        cycles: {
            tlabel: "2228",
            component: "cycles",
            pre: true
        },
        a_percent_shift: {
            label: "AS%",
            tlabel: "1933",
            component: "color-box",
            class: "small-label",
            config: {
                limitType: "clamp",
                greenLimit: "speed_deviation_green_limit",
                yellowLimit: "speed_deviation_yellow_limit",
                clampAround: 100,
                grayValue: 0,
            },
            pre: true
        },
        a_percent_today: {
            label: "AT%",
            tlabel: "1934",
            component: "color-box",
            class: "small-label",
            config: {
                limitType: "clamp",
                greenLimit: "speed_deviation_green_limit",
                yellowLimit: "speed_deviation_yellow_limit",
                clampAround: 100,
                grayValue: 0,
                class: "",
                transformTo: "fixed"
            },
            pre: true
        },
        a_percent_24: {
            label: "A24%",
            tlabel: "1931",
            component: "color-box",
            class: "small-label",
            config: {
                limitType: "clamp",
                greenLimit: "speed_deviation_green_limit",
                yellowLimit: "speed_deviation_yellow_limit",
                clampAround: 100,
                grayValue: 0,
                class: "",
                transformTo: "fixed"
            },
            pre: true
        },
        real_speed: {
            tlabel: "5251",
            pre: true
        },
        planned_speed: {
            tlabel: "967",
            pre: true
        },
        gain_loss_order_rem_qty_hours: {
            label: "GainLossOrder%",
            component: "color-box",
            config: {
                limitType: "hard",
                greenLimit: 0,
                yellowLimit: 0,
                clampAround: 100,
                grayValue: 0,
                class: "wide",
                transformTo: "hh:mm"
            },
            pre: true
        },
        gain_loss_yearly_qty_hours: {
            label: "GainLossYear%",
            component: "color-box",
            config: {
                limitType: "hard",
                greenLimit: 0,
                yellowLimit: 0,
                clampAround: 100,
                grayValue: 0,
                class: "wide",
                transformTo: "hh:mm"
            },
            pre: true
        }
    },
    uncoded_stops: {
        machine_id: {
            component: "machineId",
            tlabel: "1",
            label: "Machine",
            pre: true
        },
        time: {
            tlabel: '6001',
            component: "minmax",
            pre: true
        },
        tot_stop_time_formatted: {

            tlabel: '2234',
            pre: true
        },
        current_stop_reason: {
            tlabel: "2322",
            component: "stopcode",
            pre: true
        },
    },
    uncoded_stops_machine: {
        machine_id: {
            component: "machineId",
            tlabel: "1",
            label: "Machine",
            pre: true
        },
        time: {
            tlabel: '6001',
            component: "minmax",
        },
        tot_stop_time_hh_mm: {
            tlabel: '217',
            pre: true

        },
        current_stop_reason: {
            tlabel: "2322",
            component: "stopcode",
            pre: true
        },
        note: {
            tlabel: "638",
            pre: true
        }
    },
    uncoded_stops_history: {
        machine_id: {
            component: "machineId",
            tlabel: "1",
            label: "Machine",
            pre: true
        },
        time: {
            tlabel: '6001',
            component: "minmax",
            pre: true
        },
        tot_stop_time_hh_mm: {
            tlabel: '217',
            pre: true
        },
        current_stop_reason: {
            tlabel: "2322",
            component: "stopcode",
            pre: true
        },
        note: {
            tlabel: "638",
            pre: true
        }
    },
    running_orders: {
        order: {
            //tlabel:null,
            tlabel: "24",
            label: "order",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            pre: true
        },
        part: {
            //tlabel:null,
            tlabel: "107",
            label: "artikel",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            label: "artikel",
            component: "double-row",
            config: {
                val1: "card_good_qty",
                val2: "planned_op_qty",
            },
            pre: true,
        },
        dates: {
            tlabel: '41',
            label: "dates",
            component: "double-row",
            config: {
                val1: "start_time",
                val2: "original_planned_end_time",
                dates: true,
                short: true
            },
            pre: true,
        },
        card_no: {
            tlabel: "1767",
            class: "h30",
            pre: true
        },
    },
    running_orders_widget: {
        order: {
            //tlabel:null,
            tlabel: "24",
            label: "order",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            pre: true,
        },
        part: {
            //tlabel:null,
            tlabel: "107",
            label: "artikel",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            label: "artikel",
            component: "double-row",
            config: {
                val1: "card_good_qty",
                val2: "planned_op_qty",
            },
            pre: true,
        },
        dates: {
            tlabel: '41',
            label: "dates",
            component: "double-row",
            config: {
                val1: "start_time",
                val2: "original_planned_end_time",
                dates: true,
                short: true
            },
            pre: true,
        },
        card_no: {
            tlabel: "1767",
            class: "h30"
        },
        pre: true,
    },
    running_orders_tab: {
        order: {
            //tlabel:null,
            tlabel: "24",
            label: "order",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            pre: true,
        },
        part: {
            //tlabel:null,
            tlabel: "107",
            label: "artikel",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            label: "artikel",
            component: "double-row",
            config: {
                val1: "card_good_qty",
                val2: "planned_op_qty",
            },
            pre: true,
        },
        dates: {
            tlabel: '41',
            label: "dates",
            component: "double-row",
            config: {
                val1: "start_time",
                val2: "original_planned_end_time",
                dates: true,
                short: true
            },
            pre: true,
        },
        card_no: {
            tlabel: "1767",
            class: "h30"
        },
        pre: true,
    },
    planned_orders: {
        order: {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            pre: true,
        },
        part: {
            tlabel: "107",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            component: "double-row",
            config: {
                val1: "planned_op_qty",
                val2: "card_good_qty",
                class: "samesize",
            },
            pre: true,
        },

        restantal: {
            tlabel: "2704",
            component: "double-row",
            config: {
                val1: "wo_remaining_op_qty",
                val2: "estimated_remaining_time_seconds",
                format2: "hhmm",
                class: "samesize",
            },
            pre: true,
        },
        antal1: {
            tlabel: "6286",
            component: "double-row",
            config: {
                val1: "planned_op_qty_unit1",
                val2: "card_good_qty_unit1",
                format1: "flexdec",
                format2: "flexdec",
                class: "samesize",
            },
            pre: true,
        },
        antal2: {
            tlabel: "6287",
            component: "double-row",
            config: {
                val1: "planned_op_qty_unit2",
                val2: "card_good_qty_unit2",
                format1: "flexdec",
                format2: "flexdec",
                class: "samesize",
            },
            pre: true,
        },
        dates: {
            tlabel: "2699",
            component: "double-row",
            config: {
                val1: "planned_start_time",
                val2: "planned_end_time",
                dates: true,
                compDates: true,
                short: true,
            },
            pre: true,
        },
        card_no: {
            tlabel: "1767",
            pre: true,
        },
    },
    rework_orders: {
        order: {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            pre: true,
        },
        part: {
            tlabel: "107",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },

        card_no: {
            tlabel: "1767",
            pre: true,
        },
    },
    planning_resource_orders: {
        order: {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            pre: true,
        },
        plannedIn: {
            //tlabel: "24",
            label: "Planned in:",
            component: "double-row",
            config: {
                val1: "_plannedIn",
                val2: "",
                format1: "list"
            },
            pre: true,
        },
        hours: {
            label: "Hours:",
            component: "double-row",
            config: {
                val1: "op_total_remaining_time_hours",
                val2: "",
                format1: "flexdec"
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            component: "double-row",
            config: {
                val1: "produced_op_qty",
                val2: "planned_op_qty",
            },
            pre: true,
        },
        dates: {
            tlabel: "2699",
            component: "double-row",
            config: {
                val1: "planned_start_time",
                val2: "planned_end_time",
                dates: true,
                compDates: true,
                short: true,
            },
            pre: true,
        },
        card_no: {
            tlabel: "1767",
            pre: true,
        },
    },
    planning_resource_orders_priorities: {
        order: {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            pre: true,
        },
        plannedIn: {
            //tlabel: "24",
            label: "Planned in:",
            component: "double-row",
            config: {
                val1: "_plannedIn",
                val2: "",
                format1: "list"
            },
            pre: true,
        },
        hours: {
            label: "Hours:",
            component: "double-row",
            config: {
                val1: "op_total_remaining_time_hours",
                val2: "",
                format1: "flexdec"
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            component: "double-row",
            config: {
                val1: "produced_op_qty",
                val2: "planned_op_qty",
            },
            pre: true,
        },
        dates: {
            tlabel: "2699",
            component: "double-row",
            config: {
                val1: "planned_start_time",
                val2: "planned_end_time",
                dates: true,
                compDates: true,
                short: true,
            },
            pre: true,
        },
        card_no: {
            tlabel: "1767",
            pre: true,
        },
    },
    planned_orders_widget: {
        order: {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            pre: true,
        },
        part: {
            tlabel: "107",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            component: "double-row",
            config: {
                val1: "produced_op_qty",
                val2: "planned_op_qty",
            },
            pre: true,
        },
        dates: {
            tlabel: "2699",
            component: "double-row",
            config: {
                val1: "planned_start_time",
                val2: "planned_end_time",
                dates: true,
                compDates: true,
                short: true,
            },
            pre: true,
        },
        card_no: {
            tlabel: "1767",
            pre: true,
        },
    },
    planned_orders_tab: {
        order: {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            pre: true,
        },
        part: {
            tlabel: "107",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            component: "double-row",
            config: {
                val1: "produced_op_qty",
                val2: "planned_op_qty",
            },
            pre: true,
        },
        dates: {
            tlabel: "2699",
            component: "double-row",
            config: {
                val1: "planned_start_time",
                val2: "planned_end_time",
                dates: true,
                compDates: true,
                short: true,
            },
            pre: true,
        },
        card_no: {
            tlabel: "1767",
            pre: true,
        },
    },
    finished_orders: {
        order: {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            pre: true,
        },
        dates: {
            tlabel: "52",
            component: "double-row",
            config: {
                val1: "end_time",
                val2: "original_planned_end_time",
                dates: true,
                compDates: false,
                short: true,
            },
            pre: true,
        },
        part: {
            tlabel: "107",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            component: "double-row",
            config: {
                val1: "produced_op_qty",
                val2: "planned_qty",
            },
            pre: true,
        },
        card_no: {
            tlabel: "1767",
            pre: true,
        },
    },

    machine_notes: {
        note_type: {
            key: 'note_type',
            tlabel: '361',
            component: 'note-type',
            pre: true
        },
        note: {
            key: 'note',
            tlabel: '638',
            component: 'b-text-long',
            pre: true
        },
        signature: {
            key: 'signature',
            tlabel: '120',
            component: 'b-text-short',
            pre: true
        },
        trans_time: {
            key: 'trans_time',
            tlabel: '180',
            component: 'date',
            config: {
                short: true
            },
            pre: true
        }
    },
    all_machine_notes: {
        note_type: {
            key: 'note_type',
            tlabel: '361',
            component: 'note-type',
            pre: true
        },
        machine_id:{
            key: 'machine_id',
            tlabel: '1',
            class:'h30',
            pre: true
        },
        note: {
            key: 'note',
            tlabel: '638',
            component: 'b-text-long',
            pre: true
        },
        signature: {
            key: 'signature',
            tlabel: '120',
            component: 'b-text-short',
            pre: true
        },
        trans_time: {
            key: 'trans_time',
            tlabel: '180',
            component: 'date',
            config: {
                short: true
            },
            pre: true
        }
    },
    finished_orders_widget: {
        order: {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            pre: true,
        },
        dates: {
            tlabel: "52",
            component: "double-row",
            config: {
                val1: "end_time",
                val2: "original_planned_end_time",
                dates: true,
                compDates: false,
                short: true,
            },
            pre: true,
        },
        part: {
            tlabel: "107",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            component: "double-row",
            config: {
                val1: "produced_op_qty",
                val2: "planned_qty",
            },
            pre: true,
        },
        card_no: {
            tlabel: "1767",
            pre: true,
        },
    },
    finished_orders_tab: {
        order: {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            pre: true,
        },
        dates: {
            tlabel: "52",
            component: "double-row",
            config: {
                val1: "end_time",
                val2: "original_planned_end_time",
                dates: true,
                compDates: false,
                short: true,
            },
            pre: true,
        },
        part: {
            tlabel: "107",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            component: "double-row",
            config: {
                val1: "produced_op_qty",
                val2: "planned_qty",
            },
            pre: true,
        },
        card_no: {
            tlabel: "1767",
            pre: true,
        },
    },
    reporting_history: {
        order: {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "op_number",
            },
            pre: true,
        },
        dates: {
            tlabel: "52",
            component: "double-row",
            config: {
                val1: "end_time",
                val2: "original_planned_end_time",
                dates: true,
                compDates: false,
                short: true,
            },
            pre: true,
        },
        part: {
            tlabel: "107",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            component: "double-row",
            config: {
                val1: "produced_op_qty",
                val2: "planned_op_qty",
            },
            pre: true,
        },
        card_no: {
            tlabel: "1767",
            pre: true,
        },
    },
    good_quantity_history: {
        work_order_no: {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "card_no",
            },
            pre: true,
        },
        part_no: {
            tlabel: "604",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        trans_time: {
            tlabel: "27",
            component: "date",
            config: {
                short: true
            },
            pre: true,
        },
        good_qty: {
            tlabel: "581",
            component: "round",
            config: {
                fixedTo: 1
            },
            pre: true,
        },
        employee: {
            tlabel: "578",
            pre: true,
        },

    },
    good_quantity_tab: {
        work_order_no: {
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "card_no",
            },
            pre: true,
        },
        part_no: {
            tlabel: "604",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        trans_time: {
            tlabel: "27",
            component: "date",
            config: {
                short: true
            },
            pre: true,
        },
        good_qty: {
            tlabel: "581",
            component: "round",
            config: {
                fixedTo: 1
            },
            pre: true,
        },
        employee: {
            tlabel: "578",
            pre: true,
        },

    },
    scrap_quantity_history: {
        work_order_no: {
            //tlabel:null,
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "card_no",
            },
            pre: true,
        },
        part_no: {
            //tlabel:null,
            tlabel: "604",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        trans_time: {
            //tlabel:null,
            tlabel: "27",
            component: "date",
            pre: true,
        },
        description: {
            tlabel: "19",
            pre: true,
        },
        note: {
            tlabel: "638",
            pre: true,
        },
        scrap_qty: {
            tlabel: "2712",
            pre: true,
        },
        scrap_qty_real: {
            tlabel: "2734",
            pre: true,
        },
        employee: {
            tlabel: "578",
            pre: true,
        },
    },
    scrap_history_tab: {
        work_order_no: {
            //tlabel:null,
            tlabel: "24",
            component: "double-row",
            config: {
                val1: "work_order_no",
                val2: "card_no",
            },
            pre: true,
        },
        part_no: {
            //tlabel:null,
            tlabel: "604",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        trans_time: {
            //tlabel:null,
            tlabel: "27",
            component: "date",
            pre: true,
        },
        description: {
            tlabel: "19",
            pre: true,
        },
        note: {
            tlabel: "638",
            pre: true,
        },
        scrap_qty: {
            tlabel: "2712",
            pre: true,
        },
        scrap_qty_real: {
            tlabel: "2734",
            pre: true,
        },
        employee: {
            tlabel: "578",
            pre: true,
        },
    },
    pallet_flag_history: {
        card_no: {
            tlabel: "1767",
            pre: true,
        },
        part: {
            tlabel: "107",
            label: "artikel",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            label: "artikel",
            component: "double-row",
            config: {
                val1: "good_qty",
            },
            pre: true,
        },
        pallet_no: {

            pre: true,
        },
        date: {
            tlabel: "41",
            component: "date",
            config: {
                short: true,
            },
            pre: true,
        },
        employee: {
            tlabel: "578",
            pre: true,
        },
    },
    label_history: {
        card_no: {
            tlabel: "1767",
            pre: true,
        },
        part: {
            tlabel: "107",
            label: "artikel",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            label: "artikel",
            component: "double-row",
            config: {
                val1: "good_qty",
            },
            pre: true,
        },
        pallet_no: {

            pre: true,
        },
        date: {
            tlabel: "41",
            component: "date",
            config: {
                short: true,
            },
            pre: true,
        },
        employee: {
            tlabel: "578",
            pre: true,
        },
    },
    pallet_flag_history_sf362: {
        pallet_flag_status: {
            label: "S",
            component: "pallet-flag-status",
            pre: true,
            key: "pallet_flag_status"
        },
        part: {
            tlabel: "107",
            label: "artikel",
            component: "double-row",
            config: {
                val1: "part_no",
                val2: "part_description",
            },
            pre: true,
        },
        antal: {
            tlabel: "574",
            label: "artikel",
            component: "double-row",
            config: {
                val1: "good_qty",
            },
            pre: true,
        },
        pallet_no: {

            pre: true,
        },
        date: {
            tlabel: "41",
            component: "date",
            config: {
                short: true,
            },
            pre: true,
        },
    },
    internal_activities: {
        machine_id: {
            tlabel: "179",
            class: "h50",
            pre: true,
        },
        action_description: {
            tlabel: "899",
            class: "h50",
            pre: true,
        },
        due_date: {
            tlabel: "1042",
            class: "h50",
            component: "date",
            config: {
                short: true,
            },
            pre: true,
        },
        type_description: {
            tlabel: "2484",
            class: "h50",
            pre: true,
        },
    },
    internal_activities_widget: {
        machine_id: {
            tlabel: "179",
            pre: true,
        },
        action_description: {
            tlabel: "899",
            pre: true,
        },
        due_date: {
            tlabel: "1042",
            component: "date",
            config: {
                short: true,
            },
            pre: true,
        },
        type_description: {
            tlabel: "2484",
            pre: true,
        },
    },
    quick_reports: {
        report_translation: {
            tlabel: "3861",
            component: "translation",
            pre: true,
        },
        setting_name: {
            tlabel: "5723",
            pre: true,
        },
        user_name: {
            tlabel: "3561",
            pre: true,
        },
        most_recently_generated: {
            tlabel: "3864",
            component: "date",
            config: {
                short: true,
            },
            pre: true,
        },
        next_report_run: {
            tlabel: "3863",
            component: "date",
            config: {
                short: true,
            },
            pre: true,
        },
    },
    production_goals: {
        target_start_time: {
            tlabel: "462",
            component: "date",
            config: {
                short: true,
            },
            pre: true,
        },
        target_end_time: {
            tlabel: "463",
            component: "date",
            config: {
                short: true,
            },
            pre: true,
        },
        production_type: {
            key: "production_type"
        },
        location_target: {
            tlabel: "573",
            component: "location-target",
            pre: true,
        },
        production_target: {
            tlabel: "729",
            component: "production-target",
            pre: true,
        }
    },
    t_articles: {
        part_no: {
            tlabel: "1886",
            class: "h50 nowrap",
            key: "part_no",
            pre: true
        },
        part_description: {
            tlabel: "394",
            class: "h50",
            key: "part_description",
            pre: true
        },
        op_no: {
            tlabel: "603",
            class: "h50",
            key: "op_no",
            pre: true
        },
    },
    cavity_history: {
        tool_id: {
            tlabel: "777",
            pre: true,
            key: "tool_id"
        },
        insert_location: {
            tlabel: "785",
            pre: true,
            key: "insert_location"
        },
        insert_id: {
            tlabel: "784",
            pre: true,
            key: "insert_id"
        },
        log_date: {
            tlabel: "41",
            pre: true,
            key: "log_date",
            component: "date"
        },
        reason: {
            tlabel: "631",
            pre: true,
            key: "reason"
        },
        employee: {
            tlabel: "578",
            pre: true,
            key: "employee"
        },
        activity: {
            tlabel: "28",
            pre: true,
            key: "activity"
        }
    },
    materials: {
        material_no: {
            component: "double-row",
            tlabel: "1225",
            config: {
                val1: "material_no",
                val2: "material_desc",
            },
            pre: true,
            key: "material_no"
        },
        material_planned_qty: {
            tlabel: "503",
            pre: true,
            key: "material_planned_qty"
        },
        material_used_qty: {
            tlabel: "700",
            pre: true,
            key: "material_used_qty"
        },
        material_balance: {
            tlabel: "1229",
            pre: true,
            key: "material_balance"
        },
    },
    materials_widget: {
        material_no: {
            component: "double-row",
            tlabel: "1225",
            config: {
                val1: "material_no",
                val2: "material_desc",
            },
            pre: true,
            key: "material_no"
        },
        material_planned_qty: {
            tlabel: "503",
            pre: true,
            key: "material_planned_qty"
        },
        material_used_qty: {
            tlabel: "700",
            pre: true,
            key: "material_used_qty"
        },
        material_balance: {
            tlabel: "1229",
            pre: true,
            key: "material_balance"
        },
    },
    runtime_per_shift:{
        day_date:{
            key:"day_date",
            pre:true,
            component:"date"
        },
        shift_desc:{
            key:"shift_desc"
        },
        shift:{
            key:"shift"
        },
        runtime_hours:{
            key:"runtime_hours"
        },
        a_percent_shift:{
            key:"a_percent_shift"
        }
    }
};

export { defaultTableConfigs }