import { forEach } from "lodash";

export const isSame = function (comp1, comp2) {
    if (typeof comp1 === 'object') {
        return JSON.stringify(comp1) === JSON.stringify(comp2);
    }
    return comp1 === comp2;
}

export const iconize = function (symbol) {
    let color = extractColor(symbol);
    let icon = extractIcon(symbol);
    return { icon, color }
}

function extractColor(str) {
    let keywords = {
        red: ["error", "red"],
        green: ["green"],
        grey: ["grey"],
        blue: ["blue"],
        yellow: ["yellow"]
    };
    let color = "black";
    forEach(keywords, (v, k) => {
        v.forEach(kw => {
            if (str.indexOf(kw) > -1) {
                color = k;
                return color;
            }
        })
    });
    return color;
}

function extractIcon(str) {
    if (['priority_red.jpg', 'priority_yellow.jpg', 'priority_green.jpg'].includes(str)) {
        return "simple-color-box";
    }
    else if (str.indexOf('numeric') > -1) {
        return str.replace("priority_", "").replace(".png", "");
    }
    else {
        switch (str) {
            case "priority_error.png":
                return "fas fa-exclamation-circle";
            case "priority_grey.png":
                return "fas fa-circle";
            case "priority_nav_down_green.png":
                return "fas fa-arrow-circle-down";
            case "priority_nav_right_blue.png":
                return "fas fa-arrow-circle-right";
            case "priority_nav_up_red.png":
                return "fas fa-arrow-circle-up";
            default:
                break;
        }
    }
    return "";
}