<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget" v-if="hasSf341">
    <div v-if="machine">
      <h1>
        {{ $t("65") }}
        <card-menu :items="items"></card-menu>
      </h1>
      <div class="row">
        <div class="col-6">
          <button
            class="fast-link-button small indicator"
            :class="classObj"
            @click="scrap(100)"
          >
            <i class="fas fa-thumbs-down"></i> 100%
          </button>
        </div>

        <div class="col-6">
          <button
            class="fast-link-button small indicator"
            :class="classObj"
            @click="scrap(75)"
          >
            <i class="fas fa-thumbs-down"></i> 75%
          </button>
        </div>
        <div class="col-6">
          <button
            class="fast-link-button small indicator"
            :class="classObj"
            @click="scrap(50)"
          >
            <i class="fas fa-thumbs-down"></i> 50%
          </button>
        </div>
        <div class="col-6">
          <button
            class="fast-link-button small indicator"
            :class="classObj"
            @click="scrap(25)"
          >
            <i class="fas fa-thumbs-down"></i> 25%
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CardMenu from "@/components/card-menu";
import { Notification } from "@/helpers/notifications";
import axios from "@/requests/axios";
import { Pulse } from "@/helpers/heartbeat";
import { execute_json } from "@/helpers/requests";

export default {
  data() {
    return {
      items: [],
      pulse: null,
      interval: 180,
    };
  },
  mounted() {
    this.pulse = new Pulse({
      action: "orders/loadRunningOrderForMachine",
      parameters: { machine_id: this.machine_id, reload: false },
      interval: this.interval,
      primaryKey: "machine_id",
    });
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  methods: {
    scrap(percentage) {
      if (this.machine.p_order == "0" || !this.orderHasFullScrapQty) {
        return true;
      }
      console.log(percentage);
      execute_json("report_scrap_percentage", {
        machine_id: this.machine_id,
        scrap_percentage: percentage,
        p_order: this.machine.p_order,
      })
        .then(({ data }) => {
          let n = new Notification({
            message: this.$t("1373"),
            state: "success",
            theader: "65",
          });
        })
        .catch((err) => {
          console.warn(err);
          let n = Notification.failed(err.statusText, { theader: "65" }); //TODO: right theader
        });
    },
    method2() {},
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      runningByMachine: "orders/runningByMachine",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    hasSf341() {
      return this.machineHasSf(this.machine_id, "341");
    },
    order() {
      return this.runningByMachine(this.machine_id)[0] || {};
    },
    orderHasFullScrapQty() {
      return this.order.full_scrap_qty ? true : false; //falsy = 0, null, undefined, false, ''
    },
    classObj() {
      return {
        "c-gray": this.machine.p_order == "0" || !this.orderHasFullScrapQty,
        " c-green": this.machine.p_order != "0" && this.orderHasFullScrapQty,
      };
    },
  },
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
  },
};
</script>

<style>
</style>