<template>
  <div class="" style="margin: 0px 15px">
    <interface-navigation
      v-if="
        loaded && singleMachine === '-1' && (forceMachineNav || (template?.mode && template?.pages?.length > 1))
      "
      @swiperight="decrementPage"
      @swipeleft="incrementPage"
    ></interface-navigation>
    <div class="widget-wrap" v-if="loaded && machine && machine.empty !== true">
      <!-- normal mode -->
      <div class="row" v-if="template?.mode == undefined">
        <component
          :is="widget"
          v-for="(widget, i) in widgets"
          :template="'widgets_' + widgetTemplate"
          :key="machine_id + '-' + i"
        ></component>
      </div>
      <!-- column mode -->
      <TransitionGroup v-else tag="div" :name="transition">
        <div
          :key="'wrap-' + currentPage"
          class="multicolumn"
          :class="
            'columns-' +
            (widgets.columns?.length +
              widgets.options?.filter((w) => w.double).length)
          "
        >
          <widget-page
            :widgets="widgets"
            :widgetTemplate="widgetTemplate"
            :key="'wp-' + currentPage"
          ></widget-page></div
      ></TransitionGroup>
    </div>
    <loading v-else-if="!loaded" />
    <div v-else class="e404">404: {{ machine_id }} not found.</div>
    <teleport to="#top-right-dropdown">
      <li>
        <router-link
          class="dropdown-item"
          :to="'/admin/config/widgets/' + widgetTemplate"
          v-if="canUser('can-access-admin-panel')"
          >{{ $t("506") }} {{ $t("1460") }}: {{ widgetTemplate }}</router-link
        >
        <h6 class="dropdown-item disabled" v-else>
          {{ $t("1460") }}: {{ widgetTemplate }}
        </h6>
      </li>
    </teleport>
  </div>
</template>
<script>
import store from "@/store";
import { mapGetters, mapState } from "vuex";

import InterfaceNavigation from "@/components/interface-navigation";
import WidgetPage from "./widgetPage.vue";
import * as WidgetComponents from "../../components/widgets";
import * as MustadComponents from "../../components/widgets/company/5103";
import * as EsbeComponents from "../../components/widgets/company/5011";
//testing

export default {
  props:{
    machineIdOverwrite:{
      default:null
    }
  },
  mounted() {
    store.commit("bottomnav/disable");
    store.commit("selections/unsetIfSubtype",{type:'order',subtype:'finished'});
    store
      .dispatch("config/loadConfig", {
        system_function: "widgets",
        template: this.widgetTemplate,
      })
      .then(() => {
        this.loaded = true;
      });
    store.commit("events/addHandler", {
      event: "UserWentInactive",
      handler: this.goToDefaultPage,
    });
    store.commit("events/addHandler", {
      event: "localPageSwap",
      handler: this.incrementPage,
    });
  },
  beforeUnmount() {
    store.commit("events/removeHandler", {
      event: "UserWentInactive",
      handler: this.goToDefaultPage,
    });
    store.commit("events/removeHandler", {
      event: "localPageSwap",
      handler: this.incrementPage,
    });
  },
  computed: {
    ...mapState({
      widgetConfigs: (state) => state.config.widgets,
      singleMachine: (state) => state.auth.web_single_machine,
    }),
    ...mapGetters({
      widgetTemplateNameFromMachineId: "config/widgetTemplateNameFromMachineId",
      canUser: "permissions/canUser",
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    machine_id() {
      return this.machineIdOverwrite || this.$route.params.machine;
    },
    forceMachineNav() {
      return this.machineHasSf(this.machine_id, "365");
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    template() {
      return this.widgetConfigs[this.widgetTemplate];
    },
    widgets() {
      return this.template?.mode !== undefined
        ? this.template.pages[this.currentPage]
        : this.template;
    },
    widgetTemplate() {
      return this.widgetTemplateNameFromMachineId(this.machine_id);
    },
  },
  data() {
    return {
      loaded: false,
      currentPage: 0,
      transition: "slide",
    };
  },
  methods: {
    widgetClass(config) {
      if (config === undefined) {
        return {
          "height-1": true,
        };
      }
      let classes = {
        preferred: config.preferred,
      };

      switch (config.width) {
        case 2:
          classes["col-12 col-xl-6 col-lg-8 col-md-12"] = true;
          break;
        case 3:
          classes["col-12 col-xl-9 col-lg-12 col-md-12"] = true;
        case 4:
          classes["col-12 col-xl-12 col-lg-12 col-md-12"] = true;
        default:
          break;
      }

      classes["height-" + config.height] = true;
      classes["width-" + config.width] = true;
      return classes;
    },
    incrementPage() {
      if (this.template.pages !== undefined) {
        this.transition = "left-slide";
        this.currentPage = (this.currentPage + 1) % this.template.pages.length;
      }
    },
    decrementPage() {
      if (this.template.pages !== undefined) {
        this.transition = "slide";
        this.currentPage =
          (this.currentPage - 1 + this.template.pages.length) %
          this.template.pages.length;
      }
    },
    goToDefaultPage() {
      this.currentPage = 0;
    },
  },
  watch: {
    $route(to) {
      //console.warn("this is maybe the cause of the disable");
      store.commit("bottomnav/disable");
      this.loaded = false;
      this.currentPage = 0;
      store
        .dispatch("config/loadConfig", {
          system_function: "widgets",
          template: this.widgetTemplateNameFromMachineId(to.params.machine),
        })
        .then(() => {
          this.loaded = true;
        });
    },
  },
  components: {
    InterfaceNavigation,
    WidgetPage,
    ...WidgetComponents,
    ...MustadComponents,
    ...EsbeComponents,
  },
};
</script>
<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 1s ease;
}
.slide-leave-to {
  transform: translateX(100vw);
  position: absolute;
  width: 100vw;
}
.slide-enter-from {
  transform: translateX(-100vw);
}
.left-slide-enter-active,
.left-slide-leave-active {
  transition: all 1s ease;
}
.left-slide-leave-to {
  transform: translateX(-100vw);
  position: absolute;
  width: 100vw;
}
.left-slide-enter-from {
  transform: translateX(100vw);
}
@media screen and (max-width: 800px) {
  .slide-enter-active,
  .slide-leave-active,
  .left-slide-enter-active,
  .left-slide-leave-active {
    transition: all 0.5s ease;
  }
}
</style>