<template>
  <div class="b-range">
    <div class="inputs">
      <input type.number="number" :min="min" :max="max" v-model="numMin" @change="syncFromNumerical">
      <input type.number="number" :min="min" :max="max" v-model="numMax" @change="syncFromNumerical" class="right">
    </div>
    <div class="line">
      <div class="range" :style="rangeStyle"></div>
      <div
        class="min dot"
        :style="minStyle"
        :class="{top:onTop=='min'}"
        @mousedown="startDrag('min', $event)"
      ></div>
      <div
        class="max dot"
        :style="maxStyle"
        :class="{top:onTop=='max'}"
        @mousedown="startDrag('max', $event)"
      ></div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  props: {
    modelValue: {
      required: true,
      default() {
        return { min: 0, max: 100 };
      },
    },
    label: {},
    tlabel: {},
    min: {
      default: 0,
    },
    max: {
      default: 100,
    },
    disabled: {
      default: false,
    },
    updateDuringSlide: {
      default: false,
    },
  },
  data() {
    return {
      localValue: this.modelValue,
      dragging: null,
      tempMin: null,
      tempMax: null,
      numMin:this.modelValue.min,
      numMax:this.modelValue.max
    };
  },
  computed: {
    displayMin() {
      return this.tempMin !== null ? this.tempMin : this.localValue.min;
    },
    displayMax() {
      return this.tempMax !== null ? this.tempMax : this.localValue.max;
    },
    minStyle() {
      return {
        left: (this.displayMin * 100) / (this.max - this.min) + "%",
      };
    },
    maxStyle() {
      return {
        left: (this.displayMax * 100) / (this.max - this.min) + "%",
      };
    },
    rangeStyle() {
      return {
        left: (this.displayMin * 100) / (this.max - this.min) + "%",
        width:
          ((this.displayMax - this.displayMin) * 100) / (this.max - this.min) +
          "%",
      };
    },
    localMax(){
      return this.localValue.max;
    },
    onTop(){
      return (this.displayMin) / (this.max - this.min) < 0.2 ? 'max' : 'min';
    }
  },
  watch:{
    tempMin(){
      if (this.tempMin !== null){
        this.numMin = this.tempMin;
      }
    },
    tempMax(){
      if (this.tempMax !== null){
        this.numMax = this.tempMax;
      }
    },
    localMax(){
      if (this.localMax !== this.numMax){
        this.numMax = this.localMax;
      }
      if (+this.localMax < +this.localValue.min){
        this.localValue.min = this.localMax;
        this.numMin = this.localValue.min;
      }
    }
  },
  emits: ["update:modelValue","touched"],
  methods: {
    startDrag(minOrMax, e) {
      e.preventDefault();
      this.dragging = minOrMax;
      document.addEventListener("mousemove", this.drag);
      document.addEventListener("mouseup", this.stopdrag);
    },
    drag(e) {
      e.preventDefault();
      let valFromPos = this.valFromPos(e);

      if (this.dragging == "min") {
        this.tempMin = _.clamp(
          valFromPos,
          this.min,
          this.localValue.max
        ).toFixed(0);
      }
      if (this.dragging == "max") {
        this.tempMax = _.clamp(
          valFromPos,
          this.localValue.min,
          this.max
        ).toFixed(0);
      }
      console.log(valFromPos, e);
    },
    stopdrag(e) {
      e.preventDefault();
      document.removeEventListener("mousemove", this.drag);
      document.removeEventListener("mouseup", this.stopdrag);
      if (this.tempMin !== null) {
        this.localValue.min = this.tempMin;
      }
      if (this.tempMax !== null) {
        this.localValue.max = this.tempMax;
      }
      this.dragging = null;
      this.tempMin = null;
      this.tempMax = null;
      this.sync();
    },
    sync() {
      this.$emit("update:modelValue", this.localValue);
      this.$emit("touched");
    },
    syncFromNumerical(){
      this.localValue.min = this.numMin;
      this.localValue.max = this.numMax;
      this.sync();
    },
    valFromPos(e) {
      var rect = this.$el.getBoundingClientRect();
      var x = e.clientX - rect.left - 10; //x position within the element.
      //var y = e.clientY - rect.top;  //y position within the element.
      return (x / (rect.width - 30)) * (this.max - this.min) + this.min;
    },
  },
};
</script>
<style scoped lang="scss">
.b-range {
  //border: 1px solid black;
  .line {
    position: relative;
    margin: 10px 15px;
    height: 8px;
    background: #ccc;
    .dot {
      top: -9px;
      transform: translateX(-13px);
      height: 26px;
      border-radius: 50%;
      width: 26px;
      background: #405242;
      position: Absolute;
      cursor: pointer;
      z-index:0;
      &.top{
        z-index:1;
      }
    }
    .range {
      position: Absolute;
      background: #405242;
      height: 8px;
      top: 0px;
    }
  }
  .inputs{
    display:flex;
    justify-content:space-between;
    margin-top:10px;
    input{
      width:125px;
      &.right{
        
      text-align:right;
      }
    }
  }
}
</style>