<template>
  <div class="row">
    <div class="date-time col" :class="{ open }">
      <p>{{ $t("41") }}:</p>
      <datepicker v-model="date" :inline="true" @click="open = true" />
    </div>
    <div class="col">
      <div class="hours-minutes">
        <p>{{ $t("27") }}:</p>
        <input
          type="number"
          name="hours"
          v-model="hours"
          min="0"
          max="24"
          @focus="focus = 'hours'"
          pattern="\d*"
        />
        <input
          @focus="focus = 'minutes'"
          type="number"
          name="minutes"
          pattern="\d*"
          v-model="minutes"
          min="0"
          max="59"
        />
        <input
          v-if="showSeconds"
          @focus="focus = 'seconds'"
          type="number"
          name="seconds"
          pattern="\d*"
          v-model="seconds"
          min="0"
          max="59"
        />
        <keyboard
          v-show="focus === 'hours'"
          v-model="hours"
          :numeric="true"
          :keyboard-class="keyboardClassH"
        />
        <keyboard
          v-show="focus === 'minutes'"
          v-model="minutes"
          :numeric="true"
          :keyboard-class="keyboardClassM"
        />
        <keyboard
          v-show="focus === 'seconds'"
          v-model="seconds"
          :numeric="true"
          :keyboard-class="keyboardClassS"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vue3-datepicker";
import _ from "lodash";
export default {
  emits: ["update:modelValue", "next", "blur", "lock"],
  props: {
    modelValue: {
      required: true,
    },
    item: {},
    active: {},
    required: {
      default: false,
    },
    showSeconds: {
      default: true,
    },
  },
  data() {
    return {
      date: this.modelValue.entered_value
        ? new Date(this.modelValue.entered_value)
        : new Date(),
      year: null,
      month: null,
      day: null,
      open: false,
      hours: null,
      minutes: null,
      seconds: null,
      dateTime: null,
      dateLoaded: false,
      focus: null,
      keyboardClassH: _.uniqueId("simple-keyboard-"),
      keyboardClassM: _.uniqueId("simple-keyboard-"),
      keyboardClassS: _.uniqueId("simple-keyboard-"),
    };
  },
  mounted() {
    if (this.modelValue.entered_value) {
      this.date = new Date(this.modelValue.entered_value);
      this.loadDate(this.date);
      this.loadTime(this.date);
      this.dateLoaded = true;
    }
  },
  watch: {
    active(val) {
      if (val) {
        if (!this.dateLoaded) {
          this.loadDate(this.date);
          this.loadTime(this.date);
          this.dateLoaded = true;
        }
      }
    },
    date(val) {
      this.loadDate(val);
      this.update();
    },
    minutes(val) {
      if (this.limitTo(val, 59) != val) {
        this.minutes = "" + this.limitTo(val, 59);
      }
      this.update();
    },
    seconds(val) {
      if (this.limitTo(val, 59) != val) {
        this.seconds = "" + this.limitTo(val, 59);
      }
      this.update();
    },
    hours(val) {
      if (this.limitTo(val, 23) != val) {
        this.hours = "" + this.limitTo(val, 23);
      }
      this.update();
    },
  },
  methods: {
    filterDigits(val) {
      return val.replace(/\D/g, "");
    },
    limitTo(val, max) {
      if (val.length > 2) {
        val = val.replace(/^0+/, "");
        if (+val > max) {
          val = max;
        }
      }
      if (val.length === 1) {
        val = ("" + val).padStart(2, "0");
      }
      console.log(val);
      return val;
    },
    loadDate(val) {
      this.year = val.getFullYear();
      this.month = ("" + (val.getMonth() + 1)).padStart(2, "0");
      this.day = ("" + (val.getDate() + 0)).padStart(2, "0");
      this.open = false;
    },
    loadTime(val) {
      this.hours = ("" + (val.getHours() + 0)).padStart(2, "0");
      this.minutes = ("" + (val.getMinutes() + 0)).padStart(2, "0");
      this.seconds = ("" + (val.getSeconds() + 0)).padStart(2, "0");
    },
    update() {
      let dateTime =
        this.year +
        "-" +
        this.month +
        "-" +
        this.day +
        " " +
        this.hours +
        ":" +
        this.minutes +
        ":" +
        this.seconds;
      this.dateTime = dateTime;
      this.modelValue.entered_value = dateTime;
      this.modelValue.isdone = true;
      this.$emit("update:modelValue", this.modelValue);
    },
  },
  components: {
    Datepicker,
  },
};
</script>

<style lang="scss">
.open .v3dp__datepicker {
  height: 320px;
}
</style>