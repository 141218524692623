<template>
  <div class="machine-singular">
    <p>Signed in: {{ employee.employee_name }}</p>
    <div class="buttons-wrap">
      <a class="router-button" @click.prevent="signOut" href="#">signout</a>
    </div>
  </div>
</template>

<script>
//import { useRouter, useRoute } from 'vue-router';
import { mapGetters, mapState } from "vuex";
import { Notification } from "@/helpers/notifications";
import axios from "@/requests/axios";
import store from "@/store";
import { execute_json } from "@/helpers/requests";

export default {
  props: {},
  setup() {},
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      employee: (state) => state.bottomnav.data,
      notifications: (state) => state.notifications.index,
    }),
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
    }),
    routerlinks() {
      return {};
    },
    machine_id() {
      return this.$route.params.machine;
    },
    shouldQuickNav() {
      return this.machineHasSf(this.machine_id, "375");
    },
  },
  methods: {
    signOut() {
      let note = Notification.request({
        message:
          "Signing out " +
          this.employee.employee_name +
          " from " +
          this.$route.params.machine,
      });
      execute_json("sign_out_employee_at_machine", {
        machine_id: this.$route.params.machine,
        employee_id: this.employee.employee_number,
        logg_message: "-",
      })
        .then(() => {
          note.ok({
            message: "Signed out!",
          });
          store.dispatch(
            "machines/loadSignedInForMachine",
            this.$route.params.machine
          );
        })
        .catch((err) => {
          console.log("axios error", err);
          /*
          note.fail({
            message: err.statusText,
          });
          */
        });

      if (this.shouldQuickNav) {
        this.$router.push(this.$route.path.replace("/signed-in", ""));
      }
      store.commit("bottomnav/disable");
    },
  },
  components: {
    // DxButton
  },
};
</script>

<style lang="scss">
</style>
