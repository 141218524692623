import defaultLayout from '@/layouts/DefaultLayout'

function loadView(view) {
    return () => import(`@/views/${view}.vue`)
}

export const liveviewRoutes = [
    {
        path: "/liveview/:liveview",
        name: "liveview view",
        meta: {
            requiresAuth: true,
            shouldRemember:true,
            layout: defaultLayout
        },
        component:loadView("liveview/view"),
       
    }
];
