<template>
  <div class="numerical-input">
    <Loading v-if="loading" />
    <div v-else>
      <table class="table table-striped">
        <tr>
          <td>{{ $t("3177") }}:</td>
          <td>{{ upper_out_of_bounds }}</td>
        </tr>
        <tr>
          <td>{{ $t("1852") }}:</td>
          <td>{{ upper_tolerance }}</td>
        </tr>
        <tr>
          <td>{{ $t("1853") }}:</td>
          <td>{{ lower_tolerance }}</td>
        </tr>
        <tr>
          <td>{{ $t("3176") }}:</td>
          <td>{{ lower_out_of_bounds }}</td>
        </tr>
      </table>
      <div class="weight-present-box">
        <label>
          {{ $t("6732") }}, {{ this.total_quantity }} {{ $t("84") }}</label
        >
        <p><i class="fas fa-balance-scale"></i> {{ total_weight }}g</p>

        <label> {{ $t("4425") }}</label>
        <p :style="{ color: valueColor }">
          <i class="fas fa-weight"></i>
          {{ data[tab.dataKey].value }}g
        </p>
      </div>
      <button class="btn btn-warning" @click="fetchData">
        <i class="fa fa-sync"></i>{{ $t("6733") }}
      </button>
      &nbsp;
      <button class="btn btn-danger" @click="switchToNumeric">
        <i class="fa fa-pen" v-if="!numeric"></i
        ><i v-else class="fa fa-minus-square"></i> {{ $t("506") }}
      </button>
      <numerical-input
        v-model="data[tab.dataKey].value"
        v-if="numeric"
      ></numerical-input>
    </div>
    <button
      class="tabbed-next-button"
      @click="finish"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <loading v-if="finishing" />
      <i class="fas fa-chevron-right" v-if="!islast && !finishing"></i>
      <i class="fas fa-check" v-if="islast && !finishing"></i>
    </button>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { setStorage } from "@/helpers/storage";
import store from "@/store";
import axios from "@/requests/axios";
import { flattenMultiRow, flattenSingleRow } from "@/requests/requestParcer";
import { Notification } from "@/helpers/notifications";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      insertOptions: [],
      selectedRows: [],
      loading: false,
      finishing: false,
      numeric: false,
      total_weight: null,
      lower_tolerance: null,
      upper_tolerance: null,
      lower_out_of_bounds: null,
      upper_out_of_bounds: null,
      total_quantity: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
    }),
    ...mapGetters({ machineById: "machines/byId" }),
    machine() {
      return this.machineById(this.machine_id);
    },
    machine_id() {
      return this.$route.params.machine;
    },
    card_no() {
      return this.machine.card_no;
    },
    disableNext() {
      return (
        this.data[this.tab.dataKey].value === null ||
        isNaN(+this.data[this.tab.dataKey].value) ||
        +this.data[this.tab.dataKey].value < +this.lower_out_of_bounds ||
        +this.data[this.tab.dataKey].value > +this.upper_out_of_bounds
      );
    },
    valueColor() {
      let val = this.data[this.tab.dataKey].value;
      if (val <= +this.upper_tolerance && val >= +this.lower_tolerance) {
        return "green";
      }
      if (
        val <= +this.upper_out_of_bounds &&
        val >= +this.lower_out_of_bounds
      ) {
        return "orange";
      }
      return "red";
    },
    controlOpen() {
      return this.data[this.tab.dataKey].controlOpen;
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.finishing = false;
      this.data[this.tab.dataKey].value = null;
      execute_json("check_weight_result", {
        card_no: this.card_no,
        part_no: this.machine.part_no,
        op_no: this.machine.op_number,
        machine_id: this.machine_id,
      })
        .then(({ data }) => {
          this.setData(data[0]);
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.data[this.tab.dataKey].value = "";
          this.loading = false;
          //let n = Notification.failed(err.statusText);
        });
    },
    setData({
      total_weight,
      total_quantity,
      unit_weight,
      upper_tolerance,
      lower_tolerance,
      upper_out_of_bounds,
      lower_out_of_bounds,
    }) {
      let storageKey =
        "weightbounds_" + this.card_no + "_" + this.machine.part_no;
      setStorage(
        storageKey,
        JSON.stringify({
          lower_tolerance,
          upper_tolerance,
          lower_out_of_bounds,
          upper_out_of_bounds,
        }),
        false
      );
      this.total_weight = total_weight;
      this.upper_tolerance = upper_tolerance;
      this.upper_out_of_bounds = upper_out_of_bounds;
      this.lower_tolerance = lower_tolerance;
      this.lower_out_of_bounds = lower_out_of_bounds;
      this.data[this.tab.dataKey].value = unit_weight;
      this.data[this.tab.dataKey].lower_tolerance = lower_tolerance;
      this.data[this.tab.dataKey].upper_tolerance = upper_tolerance;
      this.total_quantity = total_quantity;
    },
    switchToNumeric() {
      this.numeric = !this.numeric;
    },
    finish() {
      if (this.finishing) {
        this.finishing = false;
      } else {
        this.$emit("next");
        this.finishing = true;
      }
    },
  },
  watch: {
    disableNext() {
      this.finishing = false;
    },
    controlOpen(v) {
      if (!v) {
        this.finishing = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.weight-present-box {
  p {
    line-height: 42px;
    font-size: 42px;
  }
  i {
  }
}
</style>