import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";
import { getNow } from "@/helpers/dates";
import { getStorage, setStorage, checkStorage } from "../../helpers/storage";
import { execute_json } from "@/helpers/requests";

const state = () => ({
    index: {},
});

// getters
const getters = {
}
// actions
const actions = {
    loadMachineNotes({ commit }, { machine_id, days_back = -7}) {
        return new Promise((resolve, reject) => {
            execute_json("machine_note", {
                operation_type: "select",
                machine_id,
                from_date: getNow(days_back)
            }).then(({ data }) => {
                commit("setMachineNotes", { data, machine_id });
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    createMachineNote({ dispatch }, { note, machine_id }) {
        return new Promise((resolve, reject) => {
            execute_json("machine_note", {
                operation_type: "insert",
                ...note,
                machine_id
            }).then(() => {
                resolve();
            }).catch((err) => {
                reject(err);
            })
        });
    },
    updateMachineNote({ dispatch }, { note }) {

        return new Promise((resolve, reject) => {
            execute_json("machine_note", {
                operation_type: "update",
                ...note,
            }).then(() => {
                resolve();
            }).catch((err) => { reject(err) });
        });
    },
    deleteMachineNotes({ dispatch }, { record_no }) {
        return new Promise((resolve, reject) => {
            execute_json("machine_note", { operation_type: "delete", record_no }).then(() => {
                resolve();
            }).catch((err) => { reject(err) });
        });
    },
}
// mutations
const mutations = {
    setMachineNotes(state, { data, machine_id }) {
        state.index[machine_id] = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}