<template>
  <div>
    <p class="present-value" v-if="config.dataKey === null">
      no dataKey selected
    </p>
    <p class="present-value">
      <flex-value  v-if="valueIsNumeric" :value="value" />
      <span v-else>{{value}}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: ["machine", "order", "planned","config"],
  computed:{
    source(){
      return this[this.config.source];
    },
    value(){
      return this.source[this.config.key]
    },
    valueIsNumeric(){
      return !isNaN(parseFloat(this.value));
    }
  }
};
</script>

<style>
</style>