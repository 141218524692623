const state = () => ({
    shouldShow:false,
    typeOfBottomNav:"machines",
    data:null,
    returnTo:null,
    type:null
});

// getters
const getters = {
 
}

// actions
const actions = {
    
}

// mutations
const mutations = {
    enable(state){
        state.shouldShow = true;
    },
    hide(state){
        state.shouldShow = false;
    },
    disable(state){
        state.returnTo = null;
        state.shouldShow = false;
        state.data = [];
    },
    setData(state,data){
        state.data = data;
    },
    setReturnTo(state,data){
        state.returnTo = data;
    },
    setType(state,type){
        state.type = type;
    },
    init(state,{type,data}){
        state.type = type;
        state.data = data;
        state.shouldShow = true;
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}