import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenSingleRow, flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";

const state = () => ({
    links: [],
    data: {

    },
    valuesPerMachine: {

    },
    processParametersLoaded: false,
    processParameters: []
});

// getters
const getters = {
    interval(state) {
        return state.links.reduce((p, c) => { return p > c.interval ? c.interval : p }, 1000);
    },
    links(state) {
        return [...new Set(state.links.map(link => link.link))];
    },
    settingsPerLink: (state) => (link) => {
        return state.links.filter(L => L.link == link)
    },
    dataForLink: (state) => (link, interval, store) => {
        let firstTime = new Date().getTime() - interval * store * 1000;
        //console.log(firstTime);
        let firstIndex = state.data.timestamps?.findIndex(timestamp => timestamp > firstTime);
        if (state.data.timestamps && state.data.timestamps.length - firstIndex < store) {
            firstIndex = state.data.timestamps.length - store;
        }
        firstIndex = firstIndex < 0 ? 0 : firstIndex;
        //console.log(firstIndex);
        let stamps = state.data.timestamps?.slice(firstIndex, state.data.timestamps.length);
        //console.log(stamps);
        let valueIndex = state.data[link]?.length - stamps?.length;
        let values = state.data[link]?.slice(valueIndex < 0 ? 0 : valueIndex, state.data[link].length);
        return { stamps, values };
    },
    linkByName: (state) => (name, machine_id) => {
        if (!name) {
            return null;
        }
        return state.valuesPerMachine[machine_id].find(v => v.parameter_name == name);
    },
    linkFromProcessParameter: (state) => (record_no) => {
        return state.processParametersLoaded ? state.processParameters.find(p => p.record_no == record_no) : null;
    }

}
// actions
const actions = {
    fetchLinkData({ commit, rootState, state, getters }) {
        return new Promise((resolve, reject) => {
            //console.warn(getters.links);
            //TODO: replace with single execute_json call that fetches multiple values simultaneously!
            if (getters.links.length > 0) {
                execute_json("get_opc_process_values", {
                    record_no_array: getters.links.join(":")
                }).then(({ data }) => {
                    data.forEach(link => {
                        let links = getters.settingsPerLink(link.record_link);
                        commit("addDataTo", { link: link.record_link, data: (+link.last_process_value)?.toFixed(link.no_of_decimals), links, interval: getters.interval });
                    })
                    commit("addDataTo", { link: "timestamps", data: new Date().getTime(), links: state.links, interval: getters.interval });

                    resolve();
                })
            } else {
                resolve();
            }
        })
    },
    loadAvailableValuesPerMachine({ commit }, machine_id) {
        return new Promise((resolve, reject) => {
            axios.post("balthzar_get_process_value_adress_and_limits", { machine_id })
                .then(({ data }) => {
                    commit("setValuesPerMachine", { data: flattenMultiRow(data), machine_id });
                    resolve();
                }).catch((err) => {
                    reject(err)
                })
        });
    },
    loadLiveDataProcessParameters({ commit }) {
        return new Promise((resolve, reject) => {
            axios.post("balthzar_get_machine_process_parameters_for_company_16_1_36", {})
                .then(({ data }) => {
                    let d = flattenMultiRow(data).filter(p => p.show_in_live_data).map(v => { return { ...v, search: v.parameter_name + ":" + v.machine_id } });
                    console.log(d);
                    commit("setProcessParameters", d);
                })
        })
    }
}
// mutations
const mutations = {
    registerLink(state, { cid, interval, link, store }) {
        state.links.push({ cid, interval, link, store });
        state.data = {};
    },
    removeLinksForCid(state, cid) {
        state.links = state.links.filter(link => link.cid !== cid);
    },
    addDataTo(state, { link, data, links, interval }) {

        let start = window.performance.now();
        //console.log(links);
        if (state.data[link] === undefined) {
            state.data[link] = [];
        }
        state.data[link].push(data);
        let keepSeconds = links.reduce((prev, current) => {
            return prev > current.interval * current.store ? prev : current.interval * current.store;
        }, 0);
        let keepElements = Math.ceil(keepSeconds / interval);
        if (state.data[link].length > keepElements) {
            state.data[link].shift();
        }
        let timeTaken = window.performance.now() - start;
        //console.log("%c adding data to " + link + " took " + timeTaken + "ms", "color:white;background:black;");

    },
    setValuesPerMachine(state, { data, machine_id }) {
        state.valuesPerMachine[machine_id] = data;
    },
    setProcessParameters(state, data) {
        state.processParametersLoaded = true;
        state.processParameters = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}