<template>
  <div
  class="icon-sizing"
    :class="{'resizing': resizing == true,
      'disable-auto-expand': hideSideBarOnXl == 'hide',
      [iconSizingClass]:true
    }"
  >
    <component :is="$route.meta.layout">
      <router-view />
    </component>
    <heartbeat />
    <dispatcher />
    <popup-central/>
    <notification-center />
  </div>
</template>

<script>
import Heartbeat from "@/components/Heartbeat";
import NotificationCenter from "@/components/NotificationCenter";
import Dispatcher from "@/components/Dispatcher";
import PopupCentral from "@/components/PopupCentral";
import store from "@/store";

export default {
  components: {
    NotificationCenter,
    Heartbeat,
    Dispatcher,
    PopupCentral,
  },
  computed: {
    hideSideBarOnXl() {
      return store.state.hideSideBarOnXl;
    },
    sidebarOpen() {
      return store.state.sidebar.isOpen;
    },
    iconSizingClass(){
      return 'icon-sizing-'+store.state.iconSizing;
    }
  },
  data() {
    return {
      resizing: false,
    };
  },
  mounted() {
    let vm = this;
    window.addEventListener("resize", function () {
      vm.resizing = true;
      //console.log("resizing!");
      clearTimeout(window.resizer);
      window.resizer = setTimeout(
        function () {
          vm.resizing = false;
          //console.log("stopped resizing!");
        },
        100
      );
      store.commit("checkMobile");
    });
    store.dispatch("checkWS");
  },
  watch: {
    $route() {
      store.commit("sidebar/close");
    },
    sidebarOpen(isOpen) {
      if (isOpen) {
        document.body.classList.add("sidebar-open");
        store.commit("bottomnav/hide");
      } else {
        document.body.classList.remove("sidebar-open");
      }
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
