import store from "@/store";
import _ from 'lodash';
import { ref, watch } from 'vue'
import { getStorage, setStorage } from './storage';
import { isSame } from './comparisons';

//example use:
/*
    config = new Configuration('production-status',{period:'shift',type:'bar'})
    let type = config.type;

*/
export class Configuration {
    constructor(objName, defaultSettings, template = null, useCompany = true, useDB = true, system_function = 'web_widget_settings') {
        this._name = objName;
        this._settings = defaultSettings;
        this._silentUpdate = false;
        this._storeCommit = store.commit;
        this._storeDispatch = store.dispatch;
        this._internalId = _.uniqueId('config-');
        this._useCompany = useCompany;
        this._useDB = useDB;
        this._template = template;
        this.isLoaded = ref(false);
        this._system_function = system_function;

        let keys = Object.keys(defaultSettings);
        keys.forEach((k) => {
            this[k] = ref(defaultSettings[k]);
        });

        this.getValues().then(() => {
            this.isLoaded.value = true;
            if (useDB) {
                watch(store.state.settings.index,
                    (val) => {
                        let shouldRun = !this._silentUpdate;
                        this._silentUpdate = false;
                        if (shouldRun) {
                            this._silentUpdate = true;
                            let settings = val[this._system_function][this.dbFunctionVariable];
                            this.setValues(settings);
                        }
                    }
                    , { deep: true })
            }
        });
    }

    setValues(settings) {
        //non destructive, to allow partial updates and incomplete saves from being loaded
        if (settings) {
            let keys = Object.keys(settings);
            let changed = false;
            keys.forEach((k) => {
                if (this[k] !== undefined && (this[k]?.value || this[k]?.value === false)) {
                    this[k].value = settings[k];
                } else {
                    this[k] = ref(settings[k]);
                }
                if (!isSame(this._settings[k],settings[k]) && k !== 'setup') {
                    changed = true;
                }
                this._settings[k] = settings[k];
            });
            if (changed) {
                //console.warn("got in a new value!", settings, this.dbFunctionVariable, this._system_function);
                this._storeCommit('settings/localSave', {
                    sf: this._system_function,
                    fv: this.dbFunctionVariable,
                    setting: _.cloneDeep(this._settings)
                });
            }
        }
        //store to store
    }

    getValues() {
        return new Promise((resolve, reject) => {
            if (this._useDB) {
                //do a call and set values, reuse values for call during same session
                this._storeDispatch('settings/loadConfigs', { sf: this._system_function, force: false }).then(() => {
                    let values = store.state.settings.index[this._system_function]?.[this.dbFunctionVariable];
                    if (values) {
                        this.setValues(values);
                    }
                    resolve();
                }).catch((err) => {
                    reject(err);
                })
            } else {
                let values = getStorage(this.storageKey);
                if (values) {
                    this.setValues(values);
                }
                resolve();
            }
        })
    }

    saveValues() {
        return new Promise((resolve, reject) => {
            if (this._settings.setup){
                this._settings.setup = false
            }
            if (this._useDB) {
                //do a call and set values
                this._storeDispatch('settings/saveConfig', {
                    sf: this._system_function,
                    fv: this.dbFunctionVariable,
                    setting: this._settings
                }).then(() => {
                    resolve();
                }).catch((err) => {
                    reject(err);
                })

            } else {
                console.warn("saving",this.storageKey,this._settings);
                setStorage(this.storageKey, this._settings);
                resolve();
            }
        })

    }


    get dbFunctionVariable() {
        let fVar = this._template ? this._template + "_" : '';
        fVar += this._name;
        return fVar;
    }

    get storageKey() {
        let storageKey = this._useCompany ? store.state.auth.company_id + "_" : "";
        storageKey += 'configuration_save_';
        storageKey += this.dbFunctionVariable;
        return storageKey;
    }
}
