<template>
  <i class="fas fa-arrow-to-left back-button" @click="handleClick()"></i>
</template>
<script>

import {mapState} from "vuex";
export default {
  props:{
    handler:{
      default:null
    }
  },
  computed:{
    ...mapState({
      path : state => state.auth.path
    })
  },
  methods:{
    handleClick(){
      if (this.handler !== null){
        this.handler();
      }else{
        this.$router.push(this.path);
      }
    }
  }
};
</script>