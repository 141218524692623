<template>
  <div class="numerical-input">
    <div
      style="position: relative;text-align:left;"
      v-for="i in data[tab.dataKey].numDates"
      :key="i"
      :inline="true"
    >
      <datepicker v-model="data[tab.dataKey].values[i - 1]"  class="form-control"/>
    </div>
    <datepicker v-model="newDate" class="form-control" v-if="data[tab.dataKey].numDates < 30"/>
    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>
<script>
import { mapState } from "vuex";
import store from "@/store";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import Datepicker from "vue3-datepicker";
import _ from "lodash";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      machine_id: this.$route.params.machine,
      newDate:null,
    };
  },
  mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
    }),
    disableNext() {
      return this.data[this.tab.dataKey].values.length === 0;
    },
  },
  methods: {},
  watch: {
      newDate(val){
          if (val !== null){
              this.data[this.tab.dataKey].numDates++;
              this.data[this.tab.dataKey].values.push(val);
              this.newDate = null;
          }
      }
  },
  components: {
    Datepicker,
  },
};
</script>