<template>
  <div>
    <p
      class="label-value"
      v-if="
        labels &&
        !setup &&
        currentSetting != '-' &&
        currentSetting != null &&
        currentSetting.header
      "
    >
      {{ currentSetting?.header.map((h) => $t(h)).join("/") }}
    </p>
    <component
      v-if="!setup && currentSetting != '-' && currentSetting != null"
      :is="currentSetting.key"
      :machine="machine"
      :config="currentSetting.config"
      :order="order"
      :planned="pOrders.length ? pOrders[0] : {}"
    ></component>
    <div v-if="setup && loaded">
      <button class="fast-link-button" @click="popup">
        <div class="background">
          <p class="label-value" v-if="config[k] != null && config[k].header">
            {{ config[k]?.header.map((h) => $t(h)).join("/") }}
          </p>
          <component
            v-if="currentSetting != '-'"
            :is="config[k]?.key"
            :machine="machine"
            :config="currentSetting.config"
            :order="order"
            :planned="pOrders.length ? planned : {}"
          ></component>
        </div>
        <i class="fas fa-cog"></i>
      </button>
      <div class="popup-wrap" @click.self="back" v-if="showPopup">
        <div class="popup">
          <h6>Button setup for {{ k }}</h6>
          <div class="flex-button-select-area">
            <div
              v-for="(v, k) in buttonOptions"
              :key="k"
              class="flex-button-picker"
            >
              <button
                class="fast-link-button indicator wide"
                @click="setConfig(v)"
                :class="{ 'c-green': k == currentSetting.key }"
              >
                <div class="background">
                  <p class="label-value" v-if="v != null && v.header">
                    {{ v?.header.map((h) => $t(h)).join("/") }}
                  </p>
                  <component
                    :is="k"
                    :machine="machine"
                    :order="order"
                    :config="currentSetting.config"
                    :planned="pOrders.length ? planned : {}"
                  ></component>
                </div>
              </button>
              <p>{{ v.description }}</p>
            </div>
            <div class="flex-button-picker">
              <button
                class="fast-link-button indicator wide"
                @click="setConfig('-')"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="flex-button-picker big">
              <div style="width: 150px">
                <button
                  class="fast-link-button indicator wide"
                  @click="
                    setConfig({
                      key: 'valueFlexOrder',
                      config: customConfig,
                      header: customConfig.header,
                    })
                  "
                  :class="{ 'c-green': 'valueFlexOrder' == currentSetting.key }"
                >
                  <div class="background">
                    <p
                      class="label-value"
                      v-if="customConfig != null && customConfig.header"
                    >
                      {{ customConfig?.header.map((h) => $t(h)).join("/") }}
                    </p>
                    <component
                      :is="'value-flex-order'"
                      :machine="machine"
                      :config="customConfig"
                      :order="order"
                      :planned="pOrders.length ? planned : {}"
                    ></component>
                  </div>
                </button>
              </div>
              <div style="flex: 1">
                <h6>Custom field:</h6>
                <select
                  name=""
                  id=""
                  class="form-control"
                  placeholder="select data source"
                  v-model="customConfig.source"
                >
                  <option value="machine">machine</option>
                  <option value="order">running order</option>
                  <option value="planned" v-if="allowPlanned">planned order</option>
                </select>
                <select
                  name=""
                  id=""
                  class="form-control"
                  placeholder="select data key"
                  v-model="customConfig.key"
                >
                  <option
                    :value="v.value"
                    v-for="v in sourceKeys"
                    :key="v.value"
                  >
                    {{ v.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ValueOrderOp from "./valuedisplays/order-op.vue";
import ValuePartDesc from "./valuedisplays/part-desc.vue";
import ValueGoodPlanned from "./valuedisplays/good-planned.vue";
import ValueScrap from "./valuedisplays/scrap";
import ValueRestTime from "./valuedisplays/rest-time";
import ValueOrderFinished from "./valuedisplays/order-finished";
import ValuePartNo from "./valuedisplays/part-no.vue";
import ValueWoNo from "./valuedisplays/wo-no.vue";
import ValueWoQty from "./valuedisplays/wo-qty.vue";
import ValueGoodQty from "./valuedisplays/good-qty.vue";
import ValueFlexOrder from "./valuedisplays/flex-order.vue";
import store from "@/store";
export default {
  props: {
    setup: {
      required: true,
      type: Boolean,
    },
    config: {
      required: true,
      type: Object,
    },
    k: {
      required: true,
      type: String,
    },
    mparam: {
      type: String,
    },
    labels: {
      default: true,
    },
    allowPlanned:{
      default:true
    }
  },
  mounted() {
    if (this.setup) {
      store.dispatch("languages/loadDefaultColumns").then(() => {
        this.loaded = true;
      });
    }
    if (
      typeof this.currentSetting == "string" &&
      this.currentSetting != "-" &&
      this.buttonOptions[this.currentSetting] !== undefined
    ) {
      this.setConfig(this.buttonOptions[this.currentSetting]);
    }
  },
  beforeUnmount() {
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders.runningOrders,
      plannedOrders: (state) => state.orders.plannedOrders,
      defaultColumnsTranslations: (state) => state.languages.defaultColumns,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    link() {
      return "/machines/all";
  },
    currentSetting() {
      return this.config[this.k];
    },
    currentButton() {
      return this.currentSetting
        ? this.buttonOptions[this.currentSetting]
        : null || null;
    },
    machine_id() {
      return this.$route.params.machine || this.mparam;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    has85() {
      return this.machineHasSf(this.machine_id, "85");
    },
    uncoded_used() {
      return this.has85
        ? this.machine.uncoded_stops_24h
        : this.machine.uncoded_stops_shift24h;
    },
    status() {
      if (+this.uncoded_used > 1) {
        return "c-red";
      } else if (+this.uncoded_used === 1) {
        return "c-yellow";
      }
      return "c-green";
    },
    to() {
      return this.currentButton
        ? this.currentButton.link.replace(":machine", this.machine_id)
        : null || null;
    },
    order() {
      return this.orders[this.machine_id]?.[0] || {};
    },
    pOrders() {
      return this.plannedOrders[this.machine_id] || [];
    },
    runningOrderColor() {
      return this.order.length > 0 ? "c-green" : "c-gray";
    },
    plannedOrderColor() {
      return this.pOrders.length > 0 ? "c-green" : "c-gray";
    },
    planned() {
      return this.pOrders.length > 0 ? this.pOrders[0] : {};
    },
    color() {
      return this.currentButton?.colorBy
        ? this[this.currentButton.colorBy]
        : null || null;
    },
    value() {
      return this.currentButton?.valueBy
        ? this[this.currentButton.valueBy]
        : null || null;
    },
    source() {
      return this[this.customConfig.source] || {};
    },
    sourceKeys() {
      let defaultColumnsTranslations = this.defaultColumnsTranslations;
      return this.source
        ? Object.keys(this.source)
            .sort()
            .map((k) => {
              let trans = defaultColumnsTranslations[k];
              return {
                value: k,
                label: trans ? k+': '+this.$t(trans) : k,
              };
            }) || []
        : null;
    },
  },
  methods: {
    popup() {
      this.showPopup = true;
    },
    back() {
      this.showPopup = false;
    },
    setConfig(key) {
      this.config.setValues({ [this.k]: key });
      this.back();
    },
  },
  data() {
    return {
      showPopup: false,
      loaded: false,
      cGreen: "c-green",
      customConfig: {
        key: null,
        source: "machine",
        header: [],
        tlabel: null,
      },
      pulse: null,
      buttonOptions: {
        ValueOrderOp: {
          key: "ValueOrderOp",
          header: ["24", "603"],
          description: "Order & OPnr",
        },
        ValuePartDesc: {
          key: "ValuePartDesc",
          header: ["604", "19"],
          description: "Part & Description",
        },
        ValueGoodPlanned: {
          key: "ValueGoodPlanned",
          header: ["581", "4258"],
          description: "Good & Planned qty",
        },
        ValueScrap: {
          key: "ValueScrap",
          header: ["554"],
          description: "Scrap qty",
        },
        ValueRestTime: {
          key: "ValueRestTime",
          header: ["1290"],
          description: "Rest time",
        },
        ValueOrderFinished: {
          key: "ValueOrderFinished",
          header: ["593"],
          description: "Order finished",
        },
        ValueWoNo: {
          key: "ValueWoNo",
          header: ["24"],
          description: "WorkOrder#",
        },
        ValuePartNo: {
          key: "ValuePartNo",
          header: ["604"],
          description: "Part #",
        },
        ValueWoQty: {
          key: "ValueWoQty",
          header: ["4258"],
          description: "WoQty#",
        },
        ValueGoodQty: {
          key: "ValueGoodQty",
          header: ["581"],
          description: "GoodQty#",
        },
      },
    };
  },
  watch: {
    setup() {
      console.warn("setup changed!");
      store.dispatch("languages/loadDefaultColumns").then(() => {
        this.loaded = true;
      });
    },
    customConfig: {
      deep: true,
      handler: function () {
        let transKey =
          this.defaultColumnsTranslations[this.customConfig.key] ||
          this.customConfig.key;
        if (this.customConfig.header[0] != transKey) {
          this.customConfig.header = [transKey];
        }
      },
    },
    currentSetting() {
      if (!this.setup) {
        if (this.pulse !== null) {
          this.pulse.stop();
        }
      }
    },
  },
  components: {
    ValueOrderOp,
    ValuePartDesc,
    ValueGoodPlanned,
    ValueScrap,
    ValueRestTime,
    ValueOrderFinished,
    ValueWoNo,
    ValuePartNo,
    ValueWoQty,
    ValueGoodQty,
    ValueFlexOrder,
  },
};
</script>

<style lang="scss">
.fast-link-button {
  position: relative;
  &.wide {
    max-width: 120px;
    overflow: hidden;
  }
  .chosen-config {
    opacity: 0.5;
    bottom: -20px;
    right: 5px;
    position: absolute;
  }
  .background {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    height: 100%;
    overflow: hidden;
    p {
      margin: 0;
      padding: 0;
      font-size: 10px;
      line-height: 20px;
    }
  }
}
.flex-button-picker {
  display: flex;
  width: 33%;
  &.big {
    width: 100%;
  }
  button {
    max-width: 80px;
  }
  p {
    padding: 10px;
  }
}
.flex-button-select-area {
  display: flex;
  flex-wrap: wrap;
}
</style>