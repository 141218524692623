<template>
  <div class="live-graph-wrap" :class="{ [bgcolor]: true, [mode]: true }">
    <div class="live-graph-overlay">
      <slot></slot>
      <p class="last" v-if="lastValue !== null">
        {{ lastValue }}
        <span v-if="link.showDiff && diff != 0" class="diff">
          <i
            class="fas"
            :class="{ 'fa-angle-up': diff > 0, 'fa-angle-down': diff < 0 }"
          ></i> {{ Math.abs(diff) }}
        </span>
        
      </p>
      <div class="timespan" v-if="link.showTimespan">
        <p><i class="far fa-stopwatch"></i> {{timeFrame}}</p>
      </div>
      <div class="min-max" v-if="link.showMinMax && (max || min)">
        <p class="max"><i class="far fa-arrow-to-top"></i> {{ max }}</p>
        <p class="min"><i class="far fa-arrow-to-bottom"></i> {{ min }}</p>
      </div>
    </div>
    <div class="live-graph">
      <prop-graph
        :yKeys="['y']"
        labelKey="x"
        type="scatter"
        :data="fData"
        v-if="fData"
        :colors="[color]"
        :displayLegend="false"
        :displayLabels="false"
        :animateUpdates="false"
        :beginAtZero="true"
        pointBorderColor="rgba(0,0,0,0.1)"
        lineColor="rgba(0,0,0,0.1)"
        :borderColor="mode == 'simple' ? 'black' : 'white'"
        :showPoints="false"
        :y-min="+link.lower_reasonable_limit || +link.static_lower_reasonable_limit"
        :y-max="+link.upper_reasonable_limit || +link.static_upper_reasonable_limit"
        :show-limits="link.showLimits"
        :upperLimitLine="+link.upper_limit || +link.static_upper_limit"
        :lowerLimitLine=" +link.lower_limit || +link.static_lower_limit"
      ></prop-graph>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PropGraph from "./PropGraph.vue";
import store from "@/store";
import { Pulse } from "@/helpers/heartbeat";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
    };
  },
  mounted() {
    store.commit("livedata/registerLink", {
      cid: this.cid,
      interval: this.interval,
      store: this.store,
      link: this.link?.record_no,
    });
    this.pulse = new Pulse([
      {
        action: "livedata/fetchLinkData",
        interval: +this.interval,
      },
    ]);
  },
  beforeUnmount() {
    store.commit("livedata/removeLinksForCid", this.cid);
    this.pulse.stop();
  },
  props: {
    link: {
      required: true,
    },
    interval: {
      required: true,
    },
    store: {
      required: true,
    },
    mode: {
      default: "simple",
    },
  },
  computed: {
    ...mapGetters({
      dataForLink: "livedata/dataForLink",
    }),
    data() {
      return this.dataForLink(this.link?.record_no, this.interval, this.store);
    },
    fData() {
      if (this.data?.stamps?.length > 0 && this.data?.values?.length > 0) {
        let t0 = new Date().getTime() - this.totalSeconds * 1000;
        return this.data.stamps.map((v, k) => {
          return {
            x: (v - t0) / 1000,
            y: +this.data?.values[k] || 5,
          };
        });
      }
    },
    lastValue() {
      if (!this.data?.values?.length) {
        return null;
      }
      return +this.data?.values[this.data.values.length - 1] || 0;
    },
    color() {
      return this.mode == "colorable"
        ? "rgba(255,255,255,0.5)"
        : "rgba(0,0,0,0.1)";
    },
    bgcolor() {
      return this.lastValue > (this.link.upper_limit || this.link.static_upper_limit)
        ? "red"
        : this.lastValue < (this.link.lower_limit|| this.link.static_lower_limit)
        ? "orange"
        : "green";
    },
    max() {
      if (!this.data?.values?.length) {
        return null;
      }
      return this.data?.values?.reduce(function (a, b) {
        return Math.max(a, b);
      }, -Infinity);
    },
    min() {
      if (!this.data?.values?.length) {
        return null;
      }
      return this.data?.values?.reduce(function (a, b) {
        return Math.min(a, b);
      }, Infinity);
    },
    diff() {
      if (!this.data?.values?.length > 1) {
        return null;
      }
      return (
        +this.data?.values[this.data.values.length - 1] -
          +this.data?.values[this.data.values.length - 2] || 0
      ).toFixed(2);
    },
    totalSeconds() {
      return this.interval * this.store ;
    },
    timeFrame() {
      let times = [
        { label: "d", am: 86400 },
        { label: "h", am: 3600 },
        { label: "m", am: 60 },
        { label: "s", am: 1}
      ];
      let timeString = "";
      let secondsLeft = this.totalSeconds;
      for (let i = 0; i < times.length; i++) {
        let t = times[i];
        let a = secondsLeft / t.am;
        if (a >= 1) {
          timeString += Math.floor(a) + t.label;
          secondsLeft = secondsLeft % t.am;
        }
      }
      return timeString;
    },
  },
  watch: {
    link(link, oldLink) {
      store.commit("livedata/removeLinksForCid", this.cid);
      store.commit("livedata/registerLink", {
        cid: this.cid,
        interval: this.interval,
        store: this.store,
        link: link?.record_no,
      });
    },
    interval(int) {
      this.pulse.stop();
      this.pulse = new Pulse([
        {
          action: "livedata/fetchLinkData",
          interval: +int,
        },
      ]);
    },
  },
  components: { PropGraph },
};
</script>