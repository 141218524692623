<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget min-height">
    <div class="max-align" :class="config.mode">
      <card-menu :items="items" :config="config" style="z-index: 1"></card-menu>
      <live-graph
        v-if="loaded && selectedLink"
        :link="selectedLink"
        :interval="config.interval"
        :store="config.store"
        :mode="config.mode"
      >
        <h1 v-if="config.titleOverride">
          {{ config.titleOverride }}
        </h1>
        <h1 v-else>
          {{ config.selectedValue }}
        </h1>
      </live-graph>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import Graph from "@/components/Graph";
import Chart from "chart.js";
import { mapState, mapGetters } from "vuex";
import { Configuration } from "@/helpers/settings";
import { Pulse } from "@/helpers/heartbeat";
import LiveGraph from "@/components/LiveGraph.vue";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      machine_id: this.$route.params.machine,
      //currentAction: "stops/loadStopreasons24H",
      previousAction: null,
      empty: false,
      ctx: null,
      chart: null,
      drawn: false,
      loaded: false,
      config: new Configuration(
        this.storageKey,
        {
          selectedValue: null,
          titleOverride: null,
          showMinMax: true,
          showTimespan: true,
          showDiff: true,
          showLimits: true,
          interval: 5,
          store: 15,
          listensTo: [],
          mode: "colorable",
        },
        this.template
      ),
      pulse: null,
      activeListeners: [],
      draws: 0,
      // pulse: new Pulse([], {}),
    };
  },
  mounted() {
    store
      .dispatch("livedata/loadAvailableValuesPerMachine", this.machine_id)
      .then(() => {
        this.loaded = true;
      });
  },
  props: {
    storageKey: {
      required: true,
    },
    template: {
      type: String,
    },
  },
  beforeUnmount() {
    this.activeListeners.forEach((event) => {
      store.commit("events/removeHandler", {
        event,
        handler: this.reDrawGraph,
      });
    });
  },
  methods: {
    registerListeners() {
      console.log("remove old listeners...");
      this.activeListeners.forEach((event) => {
        store.commit("events/removeHandler", {
          event,
          handler: this.reDrawGraph,
        });
      });
      this.activeListeners = [];
      console.log("listening...");
      this.config.listensTo.forEach((event) => {
        store.commit("events/addHandler", {
          event,
          handler: this.reDrawGraph,
        });
        this.activeListeners.push(event);
      });
    },
    reDrawGraph() {
      this.draws++;
    },
  },
  computed: {
    ...mapState({
      valuesPerMachine: (state) => state.livedata.valuesPerMachine,
    }),
    availableValues() {
      return this.valuesPerMachine[this.machine_id] || [];
    },
    availableItems() {
      return this.availableValues.map((value) => {
        return {
          label: value.parameter_name,
          handler: () => {
            this.config.setValues({ selectedValue: value.parameter_name });
          },
          selected: this.config.selectedValue == value.parameter_name,
        };
      });
    },
    ...mapGetters({
      interval: "livedata/interval",
      dataForLink: "livedata/dataForLink",
      linkByName: "livedata/linkByName",
    }),
    selectedLink() {
      return {
        ...this.linkByName(this.config.selectedValue, this.machine_id),
        showMinMax: this.config.showMinMax,
        showTimespan: this.config.showTimespan,
        showDiff: this.config.showDiff,
        showLimits: this.config.showLimits,
        draws: this.draws,
      };
    },
    machine() {
      //return this.machineById(this.machine_id);
    },
    configLoaded() {
      return this.config.isLoaded;
    },
    listenToEvents() {
      return ["startWO", "stopWO"].map((n) => {
        return {
          label: n,
          handler: () => {
            if (this.config.listensTo.includes(n)) {
              this.config.setValues({
                listensTo: this.config.listensTo.filter((f) => f != n),
              });
            } else {
              this.config.listensTo.push(n);
            }
            this.registerListeners();
          },
          selected: this.config.listensTo.includes(n),
        };
      });
    },
    items() {
      return [
        {
          input: "titleOverride",
          type: "text",
        },
        {
          header: "polling interval: " + this.config.interval + "s",
        },
        {
          input: "interval",
          type: "range",
          min: 1,
          max: 60,
        },
        {
          header:
            "time shown: " + this.config.interval * this.config.store + "s",
        },
        {
          input: "store",
          type: "range",
          min: 10,
          max: 24 * 60,
        },
        {
          label: "toggle display",
          handler: () => {
            let newMode =
              this.config.mode == "colorable" ? "simple" : "colorable";
            this.config.setValues({ mode: newMode });
          },
        },
        {
          label: "toggle min/max",
          handler: () => {
            this.config.setValues({ showMinMax: !this.config.showMinMax });
          },
          selected: this.config.showMinMax,
        },
        {
          label: "toggle timespan",
          handler: () => {
            this.config.setValues({ showTimespan: !this.config.showTimespan });
          },
          selected: this.config.showTimespan,
        },
        {
          label: "toggle diff",
          handler: () => {
            this.config.setValues({ showDiff: !this.config.showDiff });
          },
          selected: this.config.showDiff,
        },
        {
          label: "toggle limits",
          handler: () => {
            this.config.setValues({ showLimits: !this.config.showLimits });
          },
          selected: this.config.showLimits,
        },
        ...this.listenToEvents,
        {
          header: !this.loaded ? "loading process values..." : "process values",
        },

        ...this.availableItems,
      ];
    },
  },
  watch: {
    configLoaded() {
      if (this.configLoaded) {
        this.registerListeners();
      }
    },
  },
  components: {
    CardMenu,
    Graph,
    LiveGraph,
  },
};
</script>
<style lang="scss">
.colorable {
  h1,
  p.last {
    color: white;
  }
}
</style>