Date.prototype.toBalthzarTime = function () {
  return this.toLocaleString("sv-SE", { timezone: "UTC" });
}

Date.prototype.getWeek = function() {
  var onejan = new Date(this.getFullYear(), 0, 1);
  return new Date().getFullYear() + "" + Math.ceil((((this - onejan) / 86400000) + onejan.getDay() + 1) / 7);
}

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import '@babel/polyfill'
import 'mutationobserver-shim'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { i18n } from './i18n';
import '@/theme/style.scss';
import appdata from './helpers/appdata';


const app = createApp(App).use(store).use(router).use(i18n);
const isProduction = process.env.NODE_ENV === 'production' && !window.Cypress;

if (isProduction) {
  Bugsnag.start({
    apiKey: '3a58d61ec1d2e09eaf47a7ea949c6a0d',
    releaseStage: window?.location?.hostname === 'dev.bzx21.com' ? 'development' : 'production',
    plugins: [new BugsnagPluginVue()],
    appVersion: appdata.version,
    onError: function (event) {
      // Amend event information
      //event.errors[0].errorClass = 'com.example.BadClass'

      // Add additional diagnostic information
      event.addMetadata('company', {
        id: store.state?.auth.company_id,
        name: store.state?.auth.company_name,
        key: store.state?.auth.company_key,
        betaAccess: true
      })
      event.addMetadata('user', {
        name: store.state?.auth.username,
        keyboardLanguage: store.state?.keyboard?.keyboardLanguage
      })

    }
  })

  const bugsnagVue = Bugsnag.getPlugin('vue')

  //initial app setup
  app.use(bugsnagVue);
}
//global components
import Loading from '@/components/loading';
import Back from '@/components/back';
import BTable from '@/components/b-table';
import CTable from '@/components/c-table';
import BList from '@/components/b-list';
import BCheck from '@/components/b-check';
import BSlide from '@/components/b-slide';
import BRange from '@/components/b-range';
import TabbedDisplay from '@/components/tabbed-display';
import NumericalInput from '@/components/numerical-input';
import Keyboard from '@/components/keyboard';
import FlexValue from '@/components/FlexValue';
import VHtml from "@/components/VHtml.js";
import PdfViewer from "@/components/PdfViewer";
import Multiselect from "@/components/multiselect";
import ImageReplaceHtml from "@/components/ImageReplaceHtml.vue";

app.component("Loading", Loading);
app.component("Back", Back);
app.component("BTable", BTable);
app.component("CTable", CTable);
app.component("BCheck", BCheck);
app.component("BSlide", BSlide);
app.component("BRange", BRange);
app.component("BList", BList);
app.component("TabbedDisplay", TabbedDisplay);
app.component("NumericalInput", NumericalInput);
app.component("Keyboard", Keyboard);
app.component("FlexValue", FlexValue);
app.component("VHtml", VHtml);
app.component("PdfViewer", PdfViewer);
app.component("Multiselect", Multiselect);
app.component("ImageReplaceHtml", ImageReplaceHtml);

app.mount('#app');