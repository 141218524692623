<template>
  <div>
    <nav class="navbar main-nav navbar-dark bg-dark fixed-top">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" @click="toggleNav">
          <img
            class=""
            src="@/assets/Balthzar.png"
            alt=""
            style="max-height: 40px"
          />
        </button>
        <component :is="location"></component>
        <top-right-dropdown></top-right-dropdown>
      </div>
    </nav>
    <div class="spacer" style="height: 44px"></div>
    <div
      class="sidenav"
      :class="{ open: navbar.isOpen }"
      @click.self="toggleNav"
    >
      <div class="sidenav-inner-wrap bg-secondary">
        <div class="content-wrap bg-secondary">
          <side-menu
            :hidden-for-machine="hiddenForMachine"
            :side-menu="sideMenu"
          ></side-menu>
        </div>
      </div>
    </div>
    <div class="container-xxxl" :class="{ open: navbar.isOpen }">
      <slot></slot>
    </div>

    <bottom-buttons />
  </div>
</template>
<script>
import BottomButtons from "@/components/bottom-buttons";
import SideMenu from "./submenu/SideMenu";
import TopRightDropdown from "./submenu/TopRightDropdown";
import LocationSwap from "@/components/location-swap";
import LiveviewSwap from "@/components/liveview-swap";
import MachineSwap from "@/components/machine-swap";
import { productionMenuFilter, filterExclude } from "@/helpers/production";
import { loadLanguageFromCache } from "@/i18n";
import { useRoute } from "vue-router";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import { watch, ref } from "vue";
export default {
  setup() {
    const route = useRoute();

    const title = ref(route.meta.title);
    const description = ref("");
    loadLanguageFromCache();
    store.dispatch("permissions/loadUnloadedPermissions");
    store.dispatch("config/loadChildConfigs", { base_code: "webcolumns" });
    watch(
      () => route.path,
      () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
        if (route.params.locationtype) {
          store.commit(
            "sidebar/setLastLocationType",
            route.params.locationtype
          );
        }
        if (route.params.locationtype) {
          store.commit("sidebar/setLastLocation", route.params.location);
        }
      }
    );
    return {
      title,
      description,
    };
  },
  created() {},
  mounted() {
    store
      .dispatch("config/loadBaseConfig", { base_code: "dashboards" })
      .then(() => {
        let promises = [];
        let keys = Object.keys(this.dashboardTemplates);
        keys.forEach((key) => {
          promises.push(
            store.dispatch("config/loadConfig", {
              system_function: "dashboards",
              template: key,
            })
          );
        });
        Promise.all(promises);
      });
    store
      .dispatch("config/loadBaseConfig", { base_code: "liveviews" })
      .then(() => {
        let promises = [];
        let keys = Object.keys(this.liveviewsTemplates);
        keys.forEach((key) => {
          promises.push(
            store.dispatch("config/loadConfig", {
              system_function: "liveviews",
              template: key,
            })
          );
        });
        Promise.all(promises);
      });
    store.dispatch("settings/loadMachinePriorities", {});
    store.dispatch("config/loadOwnOverviews");
    store.dispatch("config/loadBaseConfig", { base_code: "web_overviews" });
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      navbar: (state) => state.sidebar,
      lastLoc: (state) => state.sidebar.lastLocation,
      lastMine: (state) => state.sidebar.lastMine,
      lastMachine: (state) => state.auth.machine,
      lastLocType: (state) => state.sidebar.lastLocationType,
      showCavities: (state) => state.auth.settings.show_cavities,
      showInternal: (state) => state.auth.settings.internal_activity,
      isResourcePlanner: (state) => state.auth.settings.resource_planning,
      bottomnav: (state) => state.bottomnav,
      machines: (state) => state.machines.index,
      userLevel: (state) => state.auth.usertype,
      dashboardTemplates: (state) => state.config.templates.dashboards,
      dashboardConfigs: (state) => state.config.dashboards,
      liveviewsTemplates: (state) => state.config.templates.liveviews,
      liveviewsConfigs: (state) => state.config.liveviews,
      screenWidth: (state) => state.screenWidth,
      webColumns: (state) => state.config.webcolumns,
      ownOverviews: (state) => state.config.ownOverviews,
    }),
    ...mapGetters({
      canUser: "permissions/canUser",
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    uncoded_location() {
      if (this.lastMine) {
        return "/machines/mine/all/uncoded-location";
      }
      if (this.lastLoc && this.lastLocType) {
        return `/machines/${this.lastLocType}/${this.lastLoc}/uncoded-location`;
      } else if (
        this.$route.params.locationtype &&
        this.$route.params.location
      ) {
        return `/machines/${this.$route.params.locationtype}/${this.$route.params.location}/uncoded-location`;
      } else {
        return null;
      }
    },
    interface_link() {
      let machine_id = null;
      if (this.hasActiveMachine && this.activeMachine.empty === undefined) {
        machine_id = this.activeMachine.machine_id;
      } else if (this.lastMachine !== null) {
        machine_id = this.lastMachine;
      } else if (this.machines.length) {
        machine_id = this.machines[0].machine_id;
      }
      return "/interface/" + machine_id;
    },
    machine_statistics_link() {
      let machine_id = null;
      if (this.hasActiveMachine && this.activeMachine.empty === undefined) {
        machine_id = this.activeMachine.machine_id;
      } else if (this.lastMachine !== null) {
        machine_id = this.lastMachine;
      } else if (this.machines.length) {
        machine_id = this.machines[0].machine_id;
      }
      return machine_id ? "/statistics/" + machine_id : null;
    },
    machine_history_link() {
      let machine_id = null;
      if (this.hasActiveMachine && this.activeMachine.empty === undefined) {
        machine_id = this.activeMachine.machine_id;
      } else if (this.lastMachine !== null) {
        machine_id = this.lastMachine;
      } else if (this.machines.length) {
        machine_id = this.machines[0].machine_id;
      }
      return machine_id ? "/history/" + machine_id : null;
    },
    machine_history_dates() {
      let week = this.$route.params.week || new Date().getWeek();
      let day =
        this.$route.params.day || new Date().toBalthzarTime().slice(0, 10);
      let date = this.$route.params.date || day;
      let hour = this.$route.params.hour || "00";
      return {
        week,
        day,
        date,
        hour,
      };
    },
    location_statistics_link() {
      if (this.lastLoc && this.lastLocType) {
        return `/statistics/${this.lastLocType}/${this.lastLoc}`;
      } else if (
        this.$route.params.locationtype &&
        this.$route.params.location
      ) {
        return `/statistics/${this.$route.params.locationtype}/${this.$route.params.location}`;
      } else {
        return null;
      }
    },
    machine_link() {
      if (this.lastMine) {
        return "/machines/mine/all";
      }
      if (this.lastLoc && this.lastLocType) {
        return `/machines/${this.lastLocType}/${this.lastLoc}`;
      } else if (
        this.$route.params.locationtype &&
        this.$route.params.location
      ) {
        return `/machines/${this.$route.params.locationtype}/${this.$route.params.location}`;
      } else {
        return "/machines";
      }
    },
    machine_widgets_link() {
      if (this.lastMine) {
        return "/machine-widgets/mine/all";
      }
      if (this.lastLoc && this.lastLocType) {
        return `/machine-widgets/${this.lastLocType}/${this.lastLoc}`;
      } else if (
        this.$route.params.locationtype &&
        this.$route.params.location
      ) {
        return `/machine-widgets/${this.$route.params.locationtype}/${this.$route.params.location}`;
      } else {
        return "/machine-widgets";
      }
    },
    my_link() {
      return "/mine/all";
      if (this.$route.meta.group === "all") {
        return "/mine/all";
      }
      if (this.lastLoc && this.lastLocType) {
        return `/mine/${this.lastLocType}/${this.lastLoc}`;
      } else if (
        this.$route.params.locationtype &&
        this.$route.params.location
      ) {
        return `/mine/${this.$route.params.locationtype}/${this.$route.params.location}`;
      } else {
        return "/mine";
      }
    },
    a_actives_link() {
      if (this.lastMine) {
        return "/a-actives/mine/all";
      }
      if (this.$route.meta.group === "all") {
        return "/a-actives/all";
      }
      if (this.lastLoc && this.lastLocType) {
        return `/a-actives/${this.lastLocType}/${this.lastLoc}`;
      } else if (
        this.$route.params.locationtype &&
        this.$route.params.location
      ) {
        return `/a-actives/${this.$route.params.locationtype}/${this.$route.params.location}`;
      } else {
        return "/a-actives";
      }
    },
    actives_link() {
      if (this.lastMine) {
        return "/actives/mine/all";
      }
      if (this.$route.meta.group === "all") {
        return "/actives/all";
      }
      if (this.lastLoc && this.lastLocType) {
        return `/actives/${this.lastLocType}/${this.lastLoc}`;
      } else if (
        this.$route.params.locationtype &&
        this.$route.params.location
      ) {
        return `/actives/${this.$route.params.locationtype}/${this.$route.params.location}`;
      } else {
        return "/actives";
      }
    },
    location() {
      if (this.$route.path.includes("/liveview/")) {
        return "liveview-swap";
      }
      if (this.$route.params.machine) {
        return "machine-swap";
      }
      if (this.$route.params.locationtype && this.$route.params.location) {
        return "location-swap";
      }
      if (this.$route.meta.group == "all") {
        return "location-swap";
      }
    },
    activeMachine() {
      if (this.hasActiveMachine) {
        if (this.$route.params.machine) {
          return this.machineById(this.$route.params.machine);
        }
        return this.bottomnav.data[0];
      }
      return {};
    },
    hasActiveMachine() {
      return (
        (this.bottomnav &&
          this.bottomnav.type === "machine-singular" &&
          this.bottomnav.data &&
          this.bottomnav.data.length) ||
        (this.$route.params.machine && this.$route.params.machine !== "all")
      );
    },
    hiddenForMachine() {
      let exclude = [];
      if (this.showCavities === "1") {
        exclude.push(8);
      }
      if (this.liveviewsMenuItems.length == 0) {
        exclude.push("liveviews-menu");
      }
      if (
        !this.hasActiveMachine ||
        !this.machineHasSf(this.activeMachine.machine_id, "32")
      ) {
        //exclude.push("sf32-1");
        exclude.push("sf32-2");
        exclude.push("sf32-3");
      }
      if (!this.showInternal) {
        exclude.push("sf32-1");
      }
      if (!this.isResourcePlanner) {
        exclude.push("r-p");
      }
      if (!this.machineHasSf(this.activeMachine.machine_id, "353")) {
        exclude.push("sf353-1");
      }
      return exclude;
    },
    overviewMenuItems() {
      return this.ownOverviews
        .filter(
          (o) =>
            this.webColumns["web_own_overview_" + o.record_no] !== undefined
        )
        .map((overview) => {
          return {
            icon: "fal fa-table",
            label: overview.overview_name,
            id: "overview-" + overview.record_no,
            to: "/overview/" + overview.record_no,
            disabled: false,
          };
        });
    },
    baseMenu() {
      return [
        /*
         :to="machine_link"
                class="nav-link"
                @mouseup="closeNav"
                >{{ $t("17") }}
        */
        {
          icon: "fas fa-grip-horizontal",
          tlabel: "1",
          label: "interface",
          id: 4242,
          disabled: !this.canUser("can-access-dashboard"),
          to: this.interface_link,
        },
        {
          icon: "fas fa-globe",
          tlabel: "3",
          label: "overviews ",
          id: 5041,
          to: "/",
          dropdown: true,
          disabled: false,
          items: [
            {
              icon: "fa fa-home",
              tlabel: "396",
              id: 100,
              to: "/machines/all",
              disabled: false,
            },
            {
              icon: "fas fa-home",
              tlabel: "17",
              id: 1,
              to: this.machine_link || "/",
              disabled: false,
            },
            {
              icon: "fa fa-grip-horizontal",
              tlabel: "17",
              id: -1,
              to: this.machine_widgets_link || "/",
              disabled: false,
            },
            /*
            {
              icon: "fas fa-home",
              tlabel: "280",
              id: 12,
              to: this.my_link || "/",
              disabled: false,
            },
            */
            {
              icon: "fas fa-home",
              tlabel: "2223",
              id: 11,
              to: this.actives_link || "/",
              disabled: false,
            },
            {
              icon: "fas fa-home",
              tlabel: "1898",
              label: "A% active machines",
              id: 11,
              to: this.a_actives_link || "/",
              disabled: false,
            },
            {
              icon: "fas fa-hand-paper",
              tlabel: "5882",
              id: 2,
              to: this.uncoded_location || "/",
              disabled: this.uncoded_location === null,
            },
            {
              icon: "fas fa-globe",
              tlabel: "2213",
              label: "overviews ",
              id: "own-overviews",
              to: "/",
              dropdown: true,
              disabled: false,
              items: [...this.overviewMenuItems],
            },
          ],
        },
        {
          icon: "fas fa-history",
          tlabel: "612",
          id: "history",
          to: "/",
          disabled: false,
          dropdown: true,
          items: [
            {
              icon: "fas fa-history",
              tlabel: "1",
              id: "history-machine",
              to: "/",
              disabled: false,
              dropdown: true,
              items: [
                {
                  icon: "fas fa-calendar-week",
                  tlabel: "436",
                  id: "history-week",
                  to: this.machine_history_link + "/week",
                  disabled: this.machine_history_link === null,
                  implemented: true,
                },
                {
                  icon: "fas fa-calendar-day",
                  tlabel: "435",
                  id: "history-day",
                  to:
                    this.machine_history_link +
                    "/day/" +
                    this.machine_history_dates.week,
                  disabled: this.machine_history_link === null,
                  implemented: true,
                },
                {
                  icon: "fas fa-hourglass",
                  tlabel: "438",
                  id: "history-hour",
                  to:
                    this.machine_history_link +
                    "/hour/" +
                    this.machine_history_dates.date,
                  disabled: this.machine_history_link === null,
                  implemented: true,
                },
                {
                  icon: "fas fa-home",
                  tlabel: "439",
                  id: "history-transactions",
                  to:
                    this.machine_history_link +
                    "/transactions/" +
                    this.machine_history_dates.date +
                    "/" +
                    this.machine_history_dates.hour,
                  disabled: this.machine_history_link === null,
                  implemented: true,
                },
                {
                  icon: "fas fa-home",
                  tlabel: "3593",
                  id: "history-machine-history",
                  to: this.machine_history_link + "/time-history",
                  disabled: this.machine_history_link === null,
                  implemented: true,
                },
              ],
            },
            {
              icon: "fas fa-sticky-note",
              tlabel: "638",
              id: "sf353-1",
              to: this.machine_link + "/notes/all",
              disabled: !this.machine_link,
              dropdown: false,
            },
          ],
        },
        {
          icon: "fas fa-clock",
          tlabel: "446",
          id: 3,
          to: "/",
          disabled: false,
          dropdown: true,
          items: [
            {
              icon: "fas fa-clock",
              tlabel: "1",
              id: 4,
              to: "/",
              disabled: false,
              dropdown: true,
              items: [
                {
                  icon: "fas fa-home",
                  tlabel: "614",
                  id: 5,
                  to: this.machine_statistics_link + "/sr-short",
                  disabled: this.machine_statistics_link === null,
                  implemented: true,
                },
                {
                  icon: "fas fa-home",
                  tlabel: "6314",
                  id: 5141,
                  to: this.machine_statistics_link + "/sr-daily",
                  disabled: this.machine_statistics_link === null,
                  implemented: true,
                },
                {
                  icon: "fas fa-home",
                  tlabel: "1549",
                  id: 55,
                  to: this.machine_statistics_link + "/av-short",
                  disabled: this.machine_statistics_link === null,
                  implemented: true,
                },
                {
                  icon: "far fa-home",
                  tlabel: "448",
                  id: 6,
                  to: "/",
                  disabled: true,
                  implemented: false,
                },
                {
                  icon: "far fa-home",
                  tlabel: "197",
                  id: 7,
                  to: "/",
                  disabled: true,
                  implemented: false,
                },
              ],
            },
            {
              icon: "fas fa-clock",
              tlabel: "2888",
              id: "locstat",
              to: "/",
              disabled: false,
              dropdown: true,
              items: [
                {
                  icon: "fas fa-home",
                  tlabel: "614",
                  id: "srshortloc",
                  to: this.location_statistics_link + "/sr-short",
                  disabled: this.location_statistics_link === null,
                  implemented: true,
                },
                {
                  icon: "fas fa-home",
                  tlabel: "6314",
                  id: "srdailyloc",
                  to: this.location_statistics_link + "/sr-daily",
                  disabled: this.location_statistics_link === null,
                  implemented: true,
                },
                {
                  icon: "fas fa-home",
                  tlabel: "1549",
                  id: "avshortloc",
                  to: this.location_statistics_link + "/av-short",
                  disabled: this.location_statistics_link === null,
                  implemented: true,
                },
                {
                  icon: "fas fa-home",
                  //tlabel: "1549",
                  label: "Analytics",
                  id: "anshortloc",
                  to: this.location_statistics_link + "/analysis-short",
                  disabled: this.location_statistics_link === null,
                  implemented: true,
                },
                {
                  icon: "fal fa-clock",
                  tlabel: "3593",
                  id: "timehistory",
                  to: this.timeHistoryLink,
                  disabled: false,
                  implemented: true,
                },
              ],
            },
          ],
        },

        {
          icon: "fas fa-search-minus",
          tlabel: "388",
          id: 8,
          disabled: this.toolIdRoute === "/",
          to: this.toolIdRoute,
        },
        {
          icon: "fas fa-flag-checkered",
          tlabel: "6645",
          id: "r-p",
          to: "/planning/" + this.activeMachine.machine_id + "/resources",
          disabled: !this.isResourcePlanner,
          implemented: true,
        },
        {
          icon: "fas fa-shoe-prints",
          tlabel: "3468",
          id: "sf32-1",
          to: "/",
          disabled: !this.showInternal,
          dropdown: true,
          items: [
            {
              icon: "far fa-plus",
              //tlabel: "2605",
              tlabel: "4670",
              id: "sf32-2",
              to: this.createActivityLink,
              disabled: !this.hasActiveMachine,
            },
            {
              icon: "far fa-running",
              //tlabel: "3931",
              tlabel: "4792",
              id: "sf32-3",
              to: this.runActivityLink,
              disabled: !this.hasActiveMachine,
            },
            {
              icon: "fas fa-running",
              //tlabel: "3931",
              tlabel: "3667",
              id: "sf32-4",
              to: this.runUserActivityLink,
              disabled: false,
            },
          ],
        },
        {
          icon: "fas fa-folder",
          tlabel: "34",
          id: "files-menu",
          to: "/",
          disabled: false,
          dropdown: true,
          items: [
            {
              icon: "fal fa-file",
              tlabel: "424",
              id: "files",
              to: "/files",
              disabled: false,
              implemented: true,
            },
            {
              icon: "fa fa-fast-forward",
              tlabel: "3830",
              id: "qreports",
              to: "/files/q-reports",
              disabled: false,
              implemented: true,
            },

            {
              icon: "fa fa-bags-shopping",
              tlabel: "5781",
              id: "order reports",
              to: "/reports/order",
              disabled: false,
              implemented: true,
            },
          ],
        },
        {
          icon: "fas fa-columns",
          label: "Dashboards",
          tlabel: "6684",
          id: "dashboard-menu",
          to: "/",
          disabled: false,
          dropdown: true,
          items: [...this.dashboardMenuItems],
        },
        {
          icon: "fas fa-columns",
          label: "Livedata",
          tlabel: "2653",
          id: "liveviews-menu",
          to: "/",
          disabled: false,
          dropdown: true,
          items: [...this.liveviewsMenuItems],
        },
        {
          icon: "fas fa-binoculars",
          tlabel: "6685",
          id: "data-vis",
          to: "",
          disabled: false,
          implemented: true,
          dropdown: true,
          items: [
            {
              icon: "fas fa-binoculars",
              tlabel: "435",
              id: "data-vis",
              to: "/data/history-day/overview-1",
              disabled: false,
              implemented: true,
            },
            {
              icon: "fas fa-id-card",
              tlabel: "3331",
              id: "data-vis2",
              to: "/data/card-history/overview-1",
              disabled: false,
              implemented: false,
            },
          ],
        },
      ];
    },
    timeHistoryLink() {
      let [empty, viewcode, ...rest] = this.$route.path.split("/");
      if (
        ["all-machines", "files", "statistics"].includes(viewcode) ||
        viewcode === undefined
      ) {
        viewcode = "machines";
      }
      if (this.lastMine) {
        return "/" + viewcode + "/mine/all/time-history";
      }
      return (
        "/" +
        viewcode +
        "/" +
        (this.lastLocType
          ? this.lastLocType
          : this.$route.params.locationtype) +
        "/" +
        (this.lastLoc ? this.lastLoc : this.$route.params.location_statistics) +
        "/time-history"
      );
    },
    createActivityLink() {
      let [empty, viewcode, ...rest] = this.$route.path.split("/");
      if (
        ["all-machines", "files", "statistics"].includes(viewcode) ||
        viewcode === undefined
      ) {
        viewcode = "machines";
      }
      return (
        "/" +
        viewcode +
        "/" +
        (this.lastLocType
          ? this.lastLocType
          : this.$route.params.locationtype) +
        "/" +
        (this.lastLoc ? this.lastLoc : this.$route.params.location_statistics) +
        "/create-activity/" +
        this.activeMachine.machine_id
      );
    },
    runActivityLink() {
      let [empty, viewcode, ...rest] = this.$route.path.split("/");
      if (
        ["all-machines", "files", "statistics"].includes(viewcode) ||
        viewcode === undefined
      ) {
        viewcode = "machines";
      }
      return (
        "/" +
        viewcode +
        "/" +
        (this.lastLocType
          ? this.lastLocType
          : this.$route.params.locationtype) +
        "/" +
        (this.lastLoc ? this.lastLoc : this.$route.params.location_statistics) +
        "/run-activity/" +
        this.activeMachine.machine_id
      );
    },
    runUserActivityLink() {
      let [empty, viewcode, ...rest] = this.$route.path.split("/");
      if (
        ["all-machines", "files", "statistics"].includes(viewcode) ||
        viewcode === undefined
      ) {
        viewcode = "machines";
      }
      return (
        "/" +
        viewcode +
        "/" +
        (this.lastLocType
          ? this.lastLocType
          : this.$route.params.locationtype) +
        "/" +
        (this.lastLoc ? this.lastLoc : this.$route.params.location_statistics) +
        "/run-activity/all"
      );
    },
    sideMenu() {
      let base = productionMenuFilter(this.baseMenu);
      let exclude = this.hiddenForMachine;
      return filterExclude(base, exclude);
    },
    toolIdRoute() {
      if (!this.canUser("can-change-cavities")) {
        return "/";
      }
      if (this.$route.name === "cavities-machine") {
        return this.$route.path;
      }
      return !this.hasActiveMachine ||
        this.activeMachine.tool_id == "" ||
        this.activeMachine.tool_id == "-"
        ? "/"
        : "/cavities/" + this.activeMachine.machine_id;
    },
    dashboards() {
      return Object.keys(this.dashboardTemplates).map((key) => {
        return {
          ...(this.dashboardConfigs[key] || {}),
          key,
        };
      });
    },
    availableDashboards() {
      return this.dashboards.filter((db) => {
        return (
          db.display === true &&
          this.userLevel >= db.userlevel &&
          ((db.suitedForMobile && this.screenWidth <= 600) ||
            (db.suitedForTablet &&
              this.screenWidth <= 1000 &&
              this.screenWidth > 600) ||
            (db.suitedForDesktops &&
              this.screenWidth > 1000 &&
              this.screenWidth < 1600) ||
            (db.suitedForXXL && this.screenWidth >= 1600))
        );
      });
    },
    dashboardMenuItems() {
      return this.availableDashboards.map((db) => {
        return {
          icon: "fal fa-columns",
          label: db.title,
          id: db.key,
          to: "/dashboards/" + db.key,
          disabled: !this.canUser("view-dashboard-templates"),
          implemented: true,
        };
      });
    },
    liveviews() {
      return Object.keys(this.liveviewsTemplates).map((key) => {
        return {
          ...(this.liveviewsConfigs[key] || {}),
          key,
        };
      });
    },
    liveviewsMenuItems() {
      return this.liveviews.map((db) => {
        return {
          icon: "fal fa-columns",
          label: db.label,
          id: db.key,
          to: "/liveview/" + db.key,
          disabled: !this.canUser("view-liveviews-templates"),
          implemented: true,
        };
      });
    },
  },
  methods: {
    toggleNav() {
      //console.log("Bar");
      store.commit("sidebar/toggle");
    },
    closeNav() {
      //console.log("Bar");
      store.commit("sidebar/close");
    },
  },
  components: {
    BottomButtons,
    LocationSwap,
    LiveviewSwap,
    MachineSwap,
    SideMenu,
    TopRightDropdown,
  },
};
</script>
<style lang="scss">
</style>