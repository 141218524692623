import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";
import { getStorage, setStorage, checkStorage } from "../../helpers/storage";

const state = () => ({
    index: {}
});

// getters
const getters = {

}

// actions
const actions = {
    loadConfigs({ commit, state, dispatch, rootState }, compname) {
        if (compname === undefined) {
            return true;
        }
        execute_json("get_user_settings", {
            username: "Default",
            user_name: "Default",
            system_function: "dashboard_widget_" + compname
        })
            .then(({ data }) => {
                console.warn("dashboard_widget", data);
                commit("setConfig", { data, compname });
            })
            .catch((err) => {
                console.log(err);

            });
    },

    saveConfig({ commit, state, dispatch, rootState }, { compname, name, config }) {
        return new Promise((resolve, reject) => {
            execute_json("save_user_setting", {
                user_name: "Default",
                username: "Default",
                user_name: "Default",
                system_function: "dashboard_widget_" + compname,
                function_variable: name,
                user_setting: JSON.stringify(config)
            })
                .then(() => {
                    resolve();
                }).catch(() => { reject() })
        })
    }
}
// mutations
const mutations = {
    setConfig(state, { data, compname }) {
        state.index[compname] = data.map(d => {
            return {
                name: d.function_variable,
                config: JSON.parse(d.string_value)
            }
        });

    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}