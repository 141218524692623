<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget left-aligned">
    <div v-if="machine">
      <h1>
        {{ $t("6478") }}
        <card-menu :items="items"></card-menu>
      </h1>
      <div class="row">
        <div class="col-4">
          <router-link
            :to="machine_link"
            class="fast-link-button indicator c-green"
          >
            <i class="fas fa-globe"></i>
          </router-link>
        </div>
        <div class="col-4">
          <router-link
            :to="'/interface/' + machine_id + '/planned-orders'"
            class="fast-link-button indicator"
            :class="{
              'c-green': pOrders.length > 0,
              'c-gray': pOrders.length === 0,
            }"
          >
            <i class="fas fa-flag-checkered"></i>&nbsp;
            <i class="fas fa-file-alt"></i>
          </router-link>
        </div>
        <div class="col-4">
          <router-link
            :to="'/interface/' + machine_id + '/running-orders'"
            class="fast-link-button indicator"
            :class="{
              'c-green': order.length > 0,
              'c-gray': order.length === 0,
            }"
          >
            <i class="fas fa-flag-checkered"></i>&nbsp;
            <i class="fas fa-running"></i>
          </router-link>
        </div>
        <div class="col-4">
          <router-link
            :to="'/interface/' + machine_id + '/report-quantity'"
            class="fast-link-button indicator"
            :class="{
              'c-green': order.length > 0,
              'c-gray': order.length === 0,
            }"
          >
            <i class="fas fa-thumbs-up"></i>
          </router-link>
        </div>
        <div class="col-4">
          <router-link
            :to="'/interface/' + machine_id + '/scrap-quantity'"
            class="fast-link-button indicator"
            :class="{
              'c-green': order.length > 0,
              'c-gray': order.length === 0,
            }"
          >
            <i class="fas fa-thumbs-down"></i>
          </router-link>
        </div>
        <div class="col-4">
          <router-link
            :to="'/interface/' + machine_id + '/uncoded'"
            class="fast-link-button indicator"
            :class="{
              'c-red': status == 'status-stop',
              'c-yellow':status == 'status-uncoded',
              'c-green': status == 'status-running',
            }"
          >
            <i class="fas fa-hand-paper"></i> {{ uncoded_used }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import { secondsToHhMm } from "@/helpers/dates";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 180,
    };
  },
  mounted() {
    this.pulse = new Pulse([
      {
        action: "machines/loadMachine",
        interval: 3,
        primaryKey: "machine_id",
        parameters: { machine_id: this.machine_id },
      },
      {
        action: "orders/loadRunningOrderForMachine",
        parameters: { machine_id: this.machine_id, reload: false },
        interval: this.interval,
        primaryKey: "machine_id",
      },
      {
        action: "orders/loadPlannedOrdersForMachine",
        parameters: {
          machine_id: this.machine_id,
          erp_resource: this.machine.erp_resource,
          erp_connection_type: this.machine.erp_connection_type,
        },
        interval: this.interval,
        primaryKey: "machine_id",
      },
    ]);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
      machinePath: (state) => state.auth.defaultpath,
      plannedOrders: (state) => state.orders.plannedOrders,
      lastLoc: (state) => state.sidebar.lastLocation,
      lastLocType: (state) => state.sidebar.lastLocationType,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    has85() {
      return this.machineHasSf(this.machine_id, "85");
    },
    uncoded_used() {
      return this.has85
        ? this.machine.uncoded_stops_24h
        : this.machine.uncoded_stops_shift24h;
    },
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    order() {
      return this.orders[this.machine_id] || [];
    },
    pOrders() {
      return this.plannedOrders[this.machine_id] || [];
    },
    items() {
      return [];
    },
    machine_link() {
      if (this.machinePath) {
        return this.machinePath;
      }
      if (this.lastLoc && this.lastLocType) {
        return `/machines/${this.lastLocType}/${this.lastLoc}`;
      } else if (
        this.$route.params.locationtype &&
        this.$route.params.location
      ) {
        return `/machines/${this.$route.params.locationtype}/${this.$route.params.location}`;
      } else {
        return "/machines";
      }
    },
    status() {
      if (+this.uncoded_used > 1) {
        return "status-stop";
      } else if (+this.uncoded_used === 1) {
        return "status-uncoded";
      }
      return "status-running";
    },
  },
  components: {
    CardMenu,
  },
};
</script>