<template>
  <div class="stopcode-singular">
        <!-- 5773 -> selected stop reasons -->
      <p>{{uncoded_stops.length}} {{$t('5773')}}</p>
      <div class="buttons-wrap">
        <!-- 3477 -> edit stop stopreason -->
      <a href="#" class="router-button" @click.prevent="codeUncoded">{{$t('3477')}}</a>
      </div>
  </div>
</template>

<script>
//import { useRouter } from 'vue-router';
import { mapState } from 'vuex';
import store from '@/store';
import _ from 'lodash';


export default {
  props: {
  },
  setup() {
   
  },
  computed:{
    ...mapState({
      bottomnav : state => state.bottomnav,
      uncoded_stops : state => state.bottomnav.data
    }),
    routerlinks(){
        return {
          codeUncoded:'/machines/'+this.$route.params.locationtype+'/'+this.$route.params.location+'/uncoded-location/code'
        }
    },
    uncoded_stopcodes(){
      //return "test";
      return _.map(this.uncoded_stops,'stop').join(",");
    }
   
  },
   methods:{
     codeUncoded(){
       store.commit('uncoded/setSelected',this.uncoded_stops);
       this.$router.push(this.routerlinks.codeUncoded);
     }
    },
  components: {
  }
};
</script>

<style lang="scss">
</style>
