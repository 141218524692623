import { createStore } from 'vuex'
//import Vuex from 'vuex'
import axios from '@/requests/axios';
import {flattenSingleRow} from "@/requests/requestParcer";
import * as StoreModules from "./modules";
import { execute_json } from '@/helpers/requests';
import appdata from '@/helpers/appdata';

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  state: {
    version:appdata.version,
    ws:null,
    pdfRenderMode:localStorage.getItem("pdfRenderMode") || "pdfjs",
    hideSideBarOnXl:localStorage.getItem("hideSideBarOnXl") || "show",
    isMobile:window.innerWidth < 600,
    screenWidth:window.innerWidth,
    iconSizing:localStorage.getItem("iconSizing") || "default",
    stripedTables:localStorage.getItem("stripedTables") == 'false' ? false : true || true,
    invertedHeaders:localStorage.getItem("invertedHeaders") == 'false' ? false : true || true,
  },
  mutations: {
    checkMobile(state){
    state.screenWidth = window.innerWidth;
      state.isMobile = window.innerWidth < 600;
    },
    setWS(state,ws){
      state.ws = ws;
    },
    togglePdfRenderMode(state){
      if (state.pdfRenderMode === 'pdfjs'){
        state.pdfRenderMode = "native";
      }else{
        state.pdfRenderMode = "pdfjs";
      }
      localStorage.setItem("pdfRenderMode",state.pdfRenderMode);
    },
    toggleHideSideBarOnXl(state){
      if (state.hideSideBarOnXl === 'show'){
        state.hideSideBarOnXl = "hide";
      }else{
        state.hideSideBarOnXl = "show";
      }
      localStorage.setItem("hideSideBarOnXl",state.hideSideBarOnXl);
    },
    setIconSizing(state,size){
      state.iconSizing = size;
      localStorage.setItem("iconSizing",size);
    },
    setInvertedHeaders(state,invertedHeaders){
      state.invertedHeaders = invertedHeaders;
      localStorage.setItem("invertedHeaders",invertedHeaders);
    },
    setStripedTables(state,stripedTables){
      state.stripedTables = stripedTables;
      localStorage.setItem("stripedTables",stripedTables);
    }
  },
  actions: {
    checkWS({ commit,state }) {
      if (state.ws !== null){
        return Promise.resolve();
      }
      return new Promise((resolve, reject) => {
        execute_json("get_compiled_version",
          {
          }
        )
        .then(({data})=>{
          commit("setWS",data?.[0]?.res);
          resolve();
        })
        .catch(()=>{
          reject();
        })
      })
    }
  },
  modules: {
    ...StoreModules
  },
  strict: debug
})
