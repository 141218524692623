function allProgress(proms, progress_cb) {
    let d = 0;
    progress_cb(0);
    for (const p of proms) {
        p.then(()=> {    
            d ++;
            progress_cb( d );
        });
    }
    return Promise.all(proms);
}

export { allProgress }