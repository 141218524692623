<template>
  <div>
    <p class="present-value bold">
      <flex-value :value="machine.card_scrap_qty" digits="4" />
    </p>
  </div>
</template>

<script>
export default {
  props: ["machine", "order", "planned"],
};
</script>

<style>
</style>