<template>
  <div class="popup large">
    <loading v-if="loading" />
    <h1>{{ $t("5190") }}</h1>
    <image-replace-html v-if="!loading && value != ''" :value="value"/>
    <div v-if="!loading && value == ''">
      {{ $t("6802") }}
    </div>
    <br />
    <br />
    <button class="btn btn-success" @click="$emit('close')">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapGetters } from "vuex";
import { flattenSingleRow } from "@/requests/requestParcer";
import store from "@/store";

export default {
  props: ["data"],
  emits: ["close"],
  data() {
    return {
      employeeNotOk: false,
      loading: true,
      value: null,
    };
  },
  mounted() {
    store
      .dispatch("orders/loadRunningOrderForMachine", {
        machine_id: this.machine_id,
        reload: true,
      })
      .then(() => {
        if (this.runningOrders?.length > 0) {
          store
            .dispatch("operations/GetOperationTextForCardNo", {
              card_no: this.runningOrders?.[this.runningOrders.length - 1]?.card_no,
            })
            .then((text) => {
              this.value = text;
            })
            .catch((err) => {
              console.warn(err);
            })
            .finally(() => {
              this.loading = false;
            });
           
        }
      });
  },
  methods: {
    handleEnter() {},
  },
  computed: {
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
      signedInByMachine: "machines/signedInByMachine",
      machineById: "machines/byId",
      runningByMachine: "orders/runningByMachine",
    }),
    machine_id() {
      return this.data.machine_id;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    runningOrders() {
      return this.runningByMachine(this.machine_id);
    },
  },
  watch: {},
};
</script>