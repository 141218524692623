<template>
  <div style="width:100%;max-width:1200px;margin:0 auto;min-height:80vh;border:1px solid gray;">
    <loading v-if="loading" />    
    <image-replace-html v-if="!loading && data[tab.dataKey].value != ''" :value="data[tab.dataKey].value"/>
    <div v-if="!loading && data[tab.dataKey].value == null">
      <p>{{ $t("3992") }}</p>
    </div>
    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapGetters } from "vuex";
import { flattenSingleRow } from "@/requests/requestParcer";
import store from "@/store";

export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      employeeNotOk: false,
      loading: true,
    };
  },
  mounted() {
    store
      .dispatch("operations/GetOperationTextForCardNo", {
        card_no: this.data.order?.selected?.card_no,
      })
      .then((text) => {
        this.data[this.tab.dataKey].value = text;
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    handleEnter() {},
  },
  computed: {
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
      signedInByMachine: "machines/signedInByMachine",
      machineById: "machines/byId",
    }),
    disableNext() {
      return this.data[this.tab.dataKey].value === null || this.loading;
    },
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
  },
  watch: {},
};
</script>