<template>
  <div v-if="loaded">
    <nav class="navbar main-nav navbar-dark bg-dark fixed-top">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" @click="toggleNav">
          <img
            class=""
            src="@/assets/Balthzar.png"
            alt=""
            style="max-height: 40px"
          />
        </button>
        <top-right-dropdown />
      </div>
    </nav>
    <div class="spacer" style="height: 44px"></div>
    <div
      class="sidenav"
      :class="{ open: navbar.isOpen }"
      @click.self="toggleNav"
    >
      <div class="sidenav-inner-wrap bg-secondary">
        <div class="content-wrap bg-secondary">
          <side-menu
            :hidden-for-machine="hiddenForMachine"
            :side-menu="sideMenu"
          ></side-menu>
        </div>
      </div>
    </div>
    <div class="container-xxxl" :class="{ open: navbar.isOpen }">
      <slot></slot>
    </div>

    <bottom-buttons />
  </div>
</template>
<script>
import BottomButtons from "@/components/bottom-buttons";
import SideMenu from "./submenu/SideMenu";
import TopRightDropdown from "./submenu/TopRightDropdown";
import LocationSwap from "@/components/location-swap";
import MachineSwap from "@/components/machine-swap";
import { loadLanguageFromCache } from "@/i18n";
import { useRoute } from "vue-router";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";
import { watch, ref } from "vue";
export default {
  setup() {
    const route = useRoute();

    const title = ref(route.meta.title);
    const description = ref("");
    loadLanguageFromCache();

    watch(
      () => route.path,
      () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
      }
    );

    return {
      title,
      description,
    };
  },
  mounted() {
    let promises = [];
    promises.push(store.dispatch("machines/loadMachines"));
    promises.push(store.dispatch("permissions/loadUnloadedPermissions"));
    promises.push(
      store.dispatch("config/loadBaseConfig", { base_code: "widgets" })
    );
    promises.push(
      store.dispatch("config/loadBaseConfig", { base_code: "dashboards" })
    );
    /*
    store.dispatch("machines/loadMachines");
    store.dispatch("permissions/loadUnloadedPermissions");
    store
      .dispatch("config/loadBaseConfig", { base_code: "widgets" })
      .then(() => {
        this.loaded = true;
      });
    store
      .dispatch("config/loadBaseConfig", { base_code: "dashboards" })
      .then(() => {
        this.loaded = true;
      });
      */
    Promise.all(promises).then(() => {
      this.loaded = true;
    });
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState({
      navbar: (state) => state.sidebar,
      lastLoc: (state) => state.sidebar.lastLocation,
      lastLocType: (state) => state.sidebar.lastLocationType,
      bottomnav: (state) => state.bottomnav,
      path: (state) => state.auth.path,
      widgetTemplates: (state) => state.config.templates.widgets,
      dashboardTemplates: (state) => state.config.templates.dashboards,
    }),
    ...mapGetters({ canUser: "permissions/canUser" }),
    hiddenForMachine() {
      let exclude = [];
      if (!this.canUser("view-dashboard-templates")) {
        exclude.push(1);
        exclude.push(11);
      }
      if (
        !(
          this.canUser("change-own-webcolumns") ||
          this.canUser("change-default-webcolumns")
        )
      ) {
        exclude.push(12);
      }
      return exclude;
    },
    widgetTemplateItems() {
      let keys = Object.keys(this.widgetTemplates);
      return keys.map((v, k) => {
        return {
          icon: "fa fa-grip-horizontal",
          //tlabel: "3669",
          label: v,
          id: 200 + k,
          to: "/admin/config/widgets/" + v,
          disabled: false,
        };
      });
    },
    dashboardTemplateItems() {
      let keys = Object.keys(this.dashboardTemplates);
      return keys.map((v, k) => {
        return {
          icon: "fa fa-grip-vertical",
          //tlabel: "3669",
          label: v,
          id: 400 + k,
          to: "/admin/config/dashboards/" + v,
          disabled: false,
        };
      });
    },
    baseMenu() {
      return [
        {
          icon: "fa fa-home",
          tlabel: "6258",
          id: 100,
          to: this.path || "/machines/all",
          disabled: false,
        },
        {
          icon: "fa fa-cog",
          tlabel: "45",
          id: 2,
          to: "/settings",
          disabled: false,
        },
      ];
    },
    sideMenu() {
      let base = this.baseMenu;
      let exclude = this.hiddenForMachine;
      return base.filter((item) => !exclude.includes(item.id));
    },
    toolIdRoute() {
      if (this.$route.name === "cavities-machine") {
        return this.$route.path;
      }
      return !this.hasActiveMachine || this.activeMachine.tool_id == ""
        ? "/"
        : "/cavities/" + this.activeMachine.machine_id;
    },
  },
  methods: {
    toggleNav() {
      console.log("Bar");
      store.commit("sidebar/toggle");
    },
    closeNav() {
      console.log("Bar");
      store.commit("sidebar/close");
    },
    logout() {
      store.dispatch("auth/logout");
    },
    addNewConfig() {
      let name = prompt("new template name");
      name = _.kebabCase(name);
      if (this.widgetTemplates[name] === undefined) {
        if (name.length > 1) {
          store.dispatch("config/addToBaseConfig", {
            name,
            base_code: "widgets",
          });
        } else {
          alert("name too short");
        }
      } else {
        alert("name already taken!");
      }
    },
    addNewDashboard() {
      let name = prompt("new template name");
      name = _.kebabCase(name);
      if (this.dashboardTemplates[name] === undefined) {
        if (name.length > 1) {
          store.dispatch("config/addToBaseConfig", {
            name,
            base_code: "dashboards",
          });
        } else {
          alert("name too short");
        }
      } else {
        alert("name already taken!");
      }
    },
  },
  components: {
    BottomButtons,
    LocationSwap,
    MachineSwap,
    SideMenu,
    TopRightDropdown,
  },
};
</script>
<style lang="scss">
</style>