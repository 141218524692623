import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";

const state = () => ({
    list: [],
    loaded: false,
    palletHistory: {},
    labelHistory:{},
    palletHistoryByCard:{},
});

// getters
const getters = {
    fromToPrinterList(state) {
        return [{ value: '', label: "-" }, ...state.list.map(printer => {
            return {
                value: printer.printer_no,
                label: printer.printer_no + ": " + printer.printer_name
            }
        })];
    }
}

// actions
const actions = {
    loadPrinters({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            execute_json("get_company_printers")
                .then(({ data }) => {
                    //console.log(data,data[0]);
                    commit('setPrinterList', {
                        data: data,
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },
    loadPalletHistoryForMachine({ commit, rootState }, { machine_id }) {
        return new Promise((resolve, reject) => {
            execute_json("get_pallet_flag_history", {
                machine_id,
                number_of_days: 30,
                pallet_flag_type:"pallet_flag" // pallet_flag | label | null
            })
                .then(({ data }) => {
                    //console.log(data,data[0]);
                    commit('setPalletHistory', {
                        data: data,
                        machine_id,
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },
    loadLabelHistoryForMachine({ commit, rootState }, { machine_id,card_no,num_days = 30}) {
        return new Promise((resolve, reject) => {
            execute_json("get_pallet_flag_history", {
                machine_id,
                number_of_days: card_no ? 0 : num_days,
                card_no,
                pallet_flag_type:"label" // pallet_flag | label | null
            })
                .then(({ data }) => {
                    //console.log(data,data[0]);
                    commit('setLabelHistory', {
                        data: data,
                        machine_id,
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },
    loadPalletHistoryForCardNo({ commit, rootState }, { card_no }) {
        return new Promise((resolve, reject) => {
            execute_json("get_pallet_flag_history", {
                card_no,
            })
                .then(({ data }) => {
                    //console.log(data,data[0]);
                    commit('setHistoryForCardNo', {
                        data: data,
                        card_no
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },

}
// mutations
const mutations = {
    setPrinterList(state, { data }) {
        state.list = data;
        state.loaded = true;
    },
    setPalletHistory(state, { data, machine_id }) {
        state.palletHistory[machine_id] = data;
    },
    setLabelHistory(state, { data, machine_id }) {
        state.labelHistory[machine_id] = data;
    },
    setHistoryForCardNo(state, { data, card_no }) {
        state.palletHistoryByCard[card_no] = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}