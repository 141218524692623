<template>
  <div class="yesno">
    <button
      class="yes btn btn-success"
      :disabled="disabled"
      :class="{ gray: modelValue === null || modelValue === '0' }"
      @click="setValue('1')"
    >
      <i class="fas fa-check"></i> {{ $t("2996") }}
    </button>
    &nbsp; &nbsp;
    <button
      class="no btn btn-danger"
      :disabled="disabled"
      :class="{ gray: modelValue === null || modelValue === '1' }"
      @click="setValue('0')"
    >
      <i class="fas fa-times"></i> {{ $t("2997") }}
    </button>
  </div>
</template>
<script>
export default {
  emits: ["update:modelValue","next", "lock"],
  props: {
    modelValue: {
      required: true,
    },
    disabled:{
      default:false
    }
  },
  methods:{
      setValue(val){
          this.$emit('update:modelValue', val);
          this.$emit("next");
      }
  }
};
</script>