<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget left-aligned">
    <div class="left-align">
      <h1 class="">
        {{ $t("6660") }} - {{currentMachineLabels[config.location]}}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div v-if="goal !== null" class="row">
        <div class="col-4">
          <p class="label-value">{{ $t("361") }}:</p>
          <p class="present-value bold">{{ goal.production_type }}</p>
        </div>
        <div class="col-4">
          <p class="label-value">{{ $t("4722") }}/{{ $t("729") }}:</p>
          <p class="present-value bold">
            {{ currentResultValue.toFixed(0) }} / {{ currentTargetValue.toFixed(0) }}
          </p>
        </div>
        <div class="col-4">
          <p class="label-value">{{$t('4543')}}</p>
          <p class="present-value bold">
            {{ currentDiffValue.toFixed(0) }}
          </p>
        </div>

        <div class="col-12">
          <svg-arrow :colors="currentDrawingValue"></svg-arrow>
        </div>
      </div>
      <div v-else>
        {{ $t("6661") }}
      </div>
    </div>
  </div>
</template>

<script>
import CardMenu from "@/components/card-menu";
import SvgArrow from "@/components/svg-arrow";
import { Pulse } from "@/helpers/heartbeat";
import { Configuration } from "@/helpers/settings";
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    template: {
      type: String,
    },
  },
  data() {
    return {
      pulse: null,
      config: new Configuration(
        "production-goal",
        {
          location: "machine_id",
          target: "target_good_qty",
          production_type: null,
        },
        this.template
      ),
      targetMap: {
        target_good_qty: "result_good_qty",
        target_good_qty_alt_unit: "result_good_qty_alt_unit",
        target_earned_hours: "result_earned_hours",
        //target_scheduled_hours: "result_consumed_scheduled_hours",
      },
      diffMap: {
        target_good_qty: "diff_good_qty",
        target_good_qty_alt_unit: "diff_qty_alt_unit",
        target_earned_hours: "diff_earned_hours",
        //target_scheduled_hours: "diff_earned_hours",
      },
    };
  },
  computed: {
    ...mapState({
      allGoals: (state) => state.goals.index,
    }),
    ...mapGetters({
      specificGoals: "goals/specificGoals",
      machineById: "machines/byId",
    }),
    prodTypes() {
      return [
        null,
        ...new Set(
          this.allGoals
            .map((g) => g.production_type)
            .filter((type) => type !== null)
            .sort()
        ),
      ];
    },
    prodTypeItems() {
      return this.prodTypes.map((type) => {
        return {
          label: type === null ? "-" : type,
          handler: () => {
            this.config.setValues({ production_type: type });
          },
          selected: this.config.production_type == type,
        };
      });
    },
    currentDrawingValue() {
      return [
        {
          color: "green",
          per:
            (this.currentResultValue -
              (this.currentDiffValue > 0 ? this.currentDiffValue : 0)) /
            this.currentTargetValue,
        },
        {
          color: this.currentDiffValue < 0 ? "red" : "lightgreen",
          per: Math.abs(this.currentDiffValue / this.currentTargetValue),
        },
        {
          color: "gray",
          per: 1,
        },
      ];
    },
    currentResultValue() {
      return this.goal[this.targetMap[this.config.target]] || 0;
    },
    currentDiffValue() {
      return this.goal[this.diffMap[this.config.target]] || 0;
    },
    currentTargetValue() {
      return this.goal[this.config.target];
    },
    currentMachineValues() {
      return {
        machine_id: this.machine_id,
        category_id: this.machine.category,
        zone_id: this.machine.zone,
        department_id: this.machine.department,
      };
    },
    currentMachineLabels() {
      return {
        machine_id: this.machine.object_description,
        category_id: this.machine.cat_desc,
        zone_id: this.machine.zone_desc,
        department_id: this.machine.department_description,
      };
    },
    goals() {
      return this.specificGoals(
        this.config.location,
        this.currentMachineValues,
        this.config.target,
        this.config.production_type
      );
    },
    goal() {
      return this.goals[this.config.showIndex] || this.goals[0] || null;
    },
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    items() {
      return [
        { header: this.$t("1329") },
        {
          tlabel: "1",
          handler: () => {
            this.config.setValues({ location: "machine_id" });
          },
          selected: this.config.location === "machine_id",
        },
        {
          tlabel: "332",
          handler: () => {
            this.config.setValues({ location: "category_id" });
          },
          selected: this.config.location === "category_id",
        },
        {
          tlabel: "2",
          handler: () => {
            this.config.setValues({ location: "department_id" });
          },
          selected: this.config.location === "department_id",
        },
        {
          tlabel: "331",
          handler: () => {
            this.config.setValues({ location: "zone_id" });
          },
          selected: this.config.location === "zone_id",
        },
        {
          divider: true,
        },
        {
          tlabel: "4541",
          handler: () => {
            this.config.setValues({ target: "target_good_qty" });
          },
          selected: this.config.target === "target_good_qty",
        },
        {
          tlabel: "6630",
          handler: () => {
            this.config.setValues({ target: "target_good_qty_alt_unit" });
          },
          selected: this.config.target === "target_good_qty_alt_unit",
        },
        {
          tlabel: "6631",
          handler: () => {
            this.config.setValues({ target: "target_earned_hours" });
          },
          selected: this.config.target === "target_earned_hours",
        },
        {
          divider: true,
        },
        ...this.prodTypeItems,
      ];
    },
  },
  mounted() {
    this.pulse = new Pulse([
      {
        action: "goals/loadProductionGoals",
        interval: 60,
      },
      {
        action: "machines/loadMachine",
        interval: 60,
        primaryKey: "machine_id",
        parameters: { machine_id: this.machine_id },
      },
    ]);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  components: {
    CardMenu,
    SvgArrow,
  },
};
</script>

<style>
svg {
  max-width: 100%;
}
</style>