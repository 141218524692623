<template>
  <div class="list" v-if="loaded">
    <b-table
      :data="list"
      :column-config="columnConfig"
      sort_type="numerical"
      primary-key="index"
      sort_dir="asc"
      :single-select="!isMultiSelect"
      :deselect="!isMultiSelect"
      :show-headers="false" 
      :initial-high="initialHighlights"
      @selectionChanged="onSelectionChanged"
    >
    </b-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
export default {
  emits: ["update:modelValue"],
  props: {
    id: {
      required: true,
    },
    modelValue: {
      required: true,
    },
  },
  data() {
    return {
      columnConfig: {
        text: {
          class: "h50",
        },
      },
    };
  },
  mounted() {
    store.dispatch("lists/loadLists",this.id);
  },
  computed: {
    ...mapState({
      loaded: (state) => state.lists.loaded,
    }),
    ...mapGetters({
      listById: "lists/byId",
    }),
    list() {
      return this.listById(this.id);
    },
    isMultiSelect() {
      if (this.loaded && this.list[0]) {
        return this.list[0].multi_select;
      } else {
        return false;
      }
    },
    initialHighlights(){
        if (this.modelValue === null){
            return [];
        }
        return this.modelValue.split(";").map((val)=>{
            return this.list.findIndex((item)=>{return item.value === val})
        });
    }
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      let val = selectedRowsData
        .map((option) => {
          return option.value ? option.value : option.text;
        })
        .join(";");
      this.$emit("update:modelValue", val);
    },
  },
};
</script>

<style>
</style>