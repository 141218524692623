<template>
  <div class="machine-singular" v-if="cavity">
    <p>{{ cavity.insert_location }}</p>
    <div class="buttons-wrap">
      <router-link
        class="router-button"
        :disabled="disableEdits"
        :to="routerlinks.open"
        v-if="cavity.status === 0 && !disableEdits"
        >{{ $t("691") }}</router-link
      >
      <router-link class="router-button" 
        :disabled="disableEdits" :to="routerlinks.close" v-if="cavity.status != '0' && !disableEdits">{{
        $t("792")
      }}</router-link>
      <router-link class="router-button" :to="routerlinks.history">{{
        $t("612")
      }}</router-link>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex";

export default {
  props: {},
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      cavity: (state) => state.bottomnav.data,
      showCavities: (state) => state.auth.settings.show_cavities,
    }),
    disableEdits(){
      return this.showCavities != "3"
    },
    routerlinks() {
      return {
        open: "/cavities/" + this.$route.params["machine"] + "/open",
        close: "/cavities/" + this.$route.params["machine"] + "/close",
        history: "/cavities/" + this.$route.params["machine"] + "/history",
      };
    },
  },
  components: {
  },
};
</script>

<style lang="scss">
</style>
