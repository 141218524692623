<template>
  <div class="stopcode-singular">
    <p>{{ historic_stops.length }} stops selected</p>

    <div class="buttons-wrap">
      <a href="#" class="router-button" @click.prevent="codeHistory">{{
        $t("3300")
      }}</a>
      <a
        href="#"
        class="router-button"
        @click.prevent="noteHistory()"
        :class="{ disabled: !notable }"
        :disabled="!notable"
        >{{ $t("638") }}</a
      >
      <a
        href="#"
        class="router-button"
        v-if="hasSf46"
        @click.prevent="splitStop()"
        :class="{ disabled: historic_stops.length > 1 }"
        :disabled="historic_stops.length > 1"
        ><i class="fas fa-split"></i>{{ $t("1563") }}</a
      >
    </div>
    <last-used-stopcodes @coded="reload"></last-used-stopcodes>
  </div>
</template>
 
<script>
//import { useRouter } from 'vue-router';
import { mapState, mapGetters } from "vuex";
import { allProgress } from "@/helpers/promises";
import LastUsedStopcodes from "./subcomponents/LastUsedStopcodes.vue";
import store from "@/store";
import _ from "lodash";

export default {
  props: {},
  setup() {},
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      historic_stops: (state) => state.bottomnav.data,
      lastUsedStops: (state) => state.stopcodes.lastStopReasons,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    routerlinks() {
      return {
        codeHistory: this.codeHistory_link,
      };
    },
    hasSf356() {
      return this.machineHasSf(this.historic_stops[0]?.machine_id, "356");
    },
    hasSf46() {
      return this.machineHasSf(this.historic_stops[0]?.machine_id, "46");
    },
    lastUsedForMachine() {
      return this.lastUsedStops?.[this.historic_stops[0]?.machine_id].slice(
        0,
        3
      );
    },
    uncoded_stopcodes() {
      //return "test";
      return _.map(this.historic_stops, "stop").join(",");
    },
    codeHistory_link() {
      if (this.$route.params.locationtype) {
        let viewtype = this.$route.params.viewtype || "machines";
        return (
          "/" +
          viewtype +
          "/" +
          this.$route.params.locationtype +
          "/" +
          this.$route.params.location +
          "/history/" +
          this.$route.params.machine +
          "/code"
        );
      } else {
        return (
          "/interface/" + this.$route.params.machine + "/history-stops/code"
        );
      }
    },
    notable() {
      return !this.historic_stops.map((s) => s.stop_reason).includes("-");
    },
  },
  methods: {
    codeHistory() {
      store.commit("uncoded/setSelected", this.historic_stops);
      this.$router.push(this.routerlinks.codeHistory);
    },
    noteHistory() {
      if (this.notable) {
        store.commit("bottomnav/enable");
        store.commit("bottomnav/setType", "history-note-multiple");
      }
    },
    splitStop() {
      store.commit("popups/addPopup", {
        type: "split-stop",
        canClose: true,
        data: this.historic_stops[0],
      });
    },
    directCode(code) {
      let promises = [];
      this.loading = true;
      this.buttonText = "Saving...";
      for (let i = 0; i < this.historic_stops.length; i++) {
        let uncoded = this.historic_stops[i];
        let machine_id = uncoded.machine_id;
        let machine = this.machineById(machine_id);
        let currentStopNumberMachine = machine.stop_number;
        console.warn(currentStopNumberMachine, uncoded.stop, uncoded, machine);
        if (currentStopNumberMachine == +uncoded.stop) {
          promises.push(
            store.dispatch("stopcodes/setStopcode", {
              stopcode: code,
              machine_id: machine_id,
            })
          );
          if (uncoded.stop_reason !== "-") {
            promises.push(
              store.dispatch("uncoded/updateUncodedStop", {
                stopcode: code,
                uncoded,
                note: "",
              })
            );
          }
        } else {
          promises.push(
            store.dispatch("uncoded/updateUncodedStop", {
              stopcode: code,
              uncoded,
              note: "",
            })
          );
        }
      }

      //allProgress instead of Promise.all
      allProgress(promises, (p) => {}).then(() => {
        console.log("done");
        store.dispatch("machines/loadHistoryForMachine", {
          machine_id: this.$route.params.machine,
          period: 7,
        });
        store.commit("bottomnav/disable");
      });
    },
    reload() {
      store
        .dispatch("machines/loadHistoryForMachine", {
          machine_id: this.$route.params.machine,
          period: 7,
        })
        .then(() => {
          this.loaded = true;
        });
    },
  },
  components: {
    LastUsedStopcodes,
  },
};
</script>

<style lang="scss">
</style>
