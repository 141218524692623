<template>
  <div class="tabbed-display">
    <div class="tab-bar-top bg-secondary">
      <div class="tab-label" v-if="currentTab.alternativeLabel">
        {{ currentTab.alternativeLabel }}
      </div>
      <div class="tab-label" v-else>
        <!-- 
          494 = enter,
          1051 = select,
          6297 = perform
        -->
        {{ currentTab.action ? $t(currentTab.action) : $t("494") }}
        {{ currentTab.tlabel ? $t(currentTab.tlabel) : currentTab.label }}
      </div>
      <div class="tab-indicators">
        <div
          class="tab-indicator"
          v-for="(t, i) in tabs"
          :key="i"
          :class="{ active: i == currentTabIndex, disabled: t.disabled }"
          @click="
            t.disabled || i > currentTabIndex ? doNothing : activateTab(i)
          "
        >
          <i
            class="far fa-times-square relative"
            v-if="t.disabled"
            @click="toolTip(i)"
          >
            <div class="tab-tooltip" v-if="tooltip === i">
              <h5>{{ tabs[i].tlabel ? $t(tabs[i].tlabel) : tabs[i].label }}</h5>
              <p v-if="tabs[i].guide">{{ tabs[i].guide }}</p>
            </div>
          </i>
          <i
            class="far fa-square"
            v-if="i >= currentTabIndex && !t.disabled"
          ></i>
          <i
            class="far fa-check-square"
            v-if="i < currentTabIndex && !t.disabled"
          ></i>
        </div>
      </div>
      <div class="current-step">
        {{ currentTabIndex + 1 }}/{{ tabs.length }}
      </div>
    </div>
    <p class="config-error" v-if="currentTab.configError">
      Configuration error: please contact support.
    </p>

    <div class="row">
      <div class="col-md">
        <reporter
          v-if="currentTab.reporter !== undefined"
          :config="currentTab.reporter"
        />
        <component
          :is="currentTab.component"
          :tab="currentTab"
          :data="data"
          :islast="isLast"
          @next="next"
        ></component>
      </div>
      <div class="col-md col-left-line" v-if="currentTab.sideComponent">
        <component :is="currentTab.sideComponent" :data="data"
          >side-component</component
        >
      </div>
    </div>
    <button
      class="tabbed-back-button sibling"
      @click="quit"
      v-if="currentTabIndex > 1 && !isNoBack"
    >
      <i class="fas fa-times"></i>
    </button>
    <button class="tabbed-back-button" @click="stepBack">
      <i class="fas fa-times" v-if="isNoBack"></i>
      <i class="fas fa-chevron-left" v-else></i>
    </button>
  </div>
</template>
<script>
import * as TabComponents from "./tabs";
import Reporter from "./Reporter";
import ActivityForm from "./tabs/activity-form/index";
import store from "@/store";

export default {
  emits: ["finished", "quited", "paging"],
  props: {
    tabs: {
      required: true,
    },
    data: {
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      currentTabIndex: null,
      tooltip: null,
    };
  },
  created() {
    this.currentTabIndex = this.tabs.findIndex((t) => t.initialShow) !== -1 ? this.tabs.findIndex((t) => t.initialShow) : this.tabs.length - 1;
  },
  methods: {
    doNothing() {},
    toolTip(i) {
      if (this.tooltip === i) {
        this.tooltip = null;
      } else {
        this.tooltip = i;
      }
    },
    activateTab(i) {
      this.currentTabIndex = i;
    },
    next() {
      if (!this.isLast) {
        this.currentTabIndex++;
        if (this.tabs[this.currentTabIndex].disabled) {
          this.next();
        }
        this.$emit("paging", this.currentTabIndex);
      } else {
        this.$emit("finished");
      }
    },
    stepBack() {
      if (this.isNoBack) {
        this.quit();
      } else {
        this.currentTabIndex--;
        if (this.tabs[this.currentTabIndex].disabled) {
          this.stepBack();
        }
      }
    },
    quit() {
      this.$emit("quited");
      console.log("forget order!");
      store.commit("selections/unset", "order");
    },
  },
  computed: {
    currentTab() {
      return this.tabs[this.currentTabIndex];
    },
    isNoBack() {
      return (
        this.currentTabIndex === 0 ||
        !this.tabs.find((t, i) => {
          return i < this.currentTabIndex && t.disabled === false;
        })
      );
    },
    isLast() {
      return (
        this.currentTabIndex === this.tabs.length - 1 ||
        !this.tabs.find((t, i) => {
          return i > this.currentTabIndex && t.disabled === false;
        })
      );
    },
  },
  components: {
    ...TabComponents,
    ActivityForm,
    Reporter,
  },
};
</script>