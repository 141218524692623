<template>
  <div class="time-history" :class="{ fullscreen }">
    <div class="time-label">
      {{ timeslots[0].belongs_to_hour !== undefined ? timeslots[0].belongs_to_hour : timeslots[0].machine_id }}
    </div>
    <div class="time-wrap" v-if="timeslots[0].empty === undefined">
      <time-slot
        class="time-slot"
        v-for="data in processedData"
        ref="timeslot"
        :key="data.index"
        :data="data"
        :timeslot="timeslots[data.index]"
        :index="index"
        @addSelect="addSelection"
      >
      </time-slot>
    </div>
    <div class="cycles-label" v-if="cycles !== null">
      {{cycles.cycles}}/{{cycles.green}}
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";
import TimeSlot from "./TimeSlot"

export default {
  emits: ["addSelect"],
  props: {
    timeslots: {
      required: true,
    },
    cycles:{
      default:null
    },
    index:{
      default:0
    }
  },
  data() {
    return {
      cid: _.uniqueId("time-history"),
      active: null,
      fullscreen: false,
    };
  },
  mounted() {},
  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    addSelection(slot) {
      this.$emit("addSelect", slot);
    },
    activateStop(stop){
      console.log("yup..");
       _.each(this.$refs.timeslot,(ref) => {
        ref?.activateStop?.(stop);
      });
    },
    deActivateStop(stop){
      console.log("nah..");
       _.each(this.$refs.timeslot,(ref) => {
        ref?.deActivateStop?.(stop);
      });
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
    }),
    ...mapGetters({
      configByMachine: "machines/configByMachine",
    }),
    totalTime() {
      return this.timeslots
        .map((t) => Math.max(+t.stop_time, +t.run_time))
        .reduce((a, b) => a + b, 0) || 1;
    },
    processedData() {
      let inversed = 100 / this.totalTime;
      return this.timeslots.map((t, index) => {
        return {
          index,
          per: Math.max(t.stop_time, t.run_time) * inversed,
          color:
            t.stop_time == "0" ? "green" : 
            t.stop_reason_color.toLowerCase() !== "automatic"
              ? t.stop_reason_color.toLowerCase()
              : "gray",
          //selected: this.selected.findIndex(s => s.stop === t.stop)> -1,
          selectable: t.stop_time !== "0",
        };
      });
    },
    empty() {
      return this.processedData.length === 0;
    },
  },
  watch: {},
  components: {
    TimeSlot
  },
};
</script>