<template>
  <div class="dropdown">
    <button
      class="btn"
      type="button"
      :id="cid"
      data-bs-toggle="dropdown"
      data-bs-offset="-9,-5"
      aria-expanded="false"
      v-if="items.length > 0"
    >
      <i class="fal fa-minus-circle" v-if="isOpen"></i>
      <i class="fal fa-arrow-circle-down" v-else></i>
    </button>
    <button class="btn" v-else>
      <i class="fal fa-times-circle"></i>
    </button>
    <ul
      class="dropdown-menu dropdown-menu-end"
      :aria-labelledby="cid"
      v-if="items.length > 0"
    >
      <li v-for="(l, i) in items" :key="i">
        <div class="dropdown-divider" v-if="l.divider"></div>
        <h6 class="dropdown-header" v-if="l.header">{{ l.header }}</h6>
        <router-link
          class="dropdown-item"
          :class="{ disabled: l.disabled, selected: l.selected }"
          href="#"
          v-if="l.to"
          :to="l.to"
          >{{ l.tlabel ? $t(l.tlabel) : l.label }}
        </router-link>
        <a
          class="dropdown-item"
          href="#"
          :class="{ disabled: l.disabled, selected: l.selected }"
          @click.prevent="l.handler"
          v-if="l.handler && !(config && canSaveWidgetSettings)"
          >{{ l.tlabel ? $t(l.tlabel) : l.label
          }}<span class="pull-right" v-if="l.selected"
            ><i class="fas fa-check"></i></span
        ></a>
        <!-- input -->
        <input
          v-if="!l.select && l.input && config && canSaveWidgetSettings"
          :type="l.type"
          class="form-control"
          :value="config[l.input]"
          :placeholder="l.input"
          :min="l.min"
          :max="l.max"
          @input="setInputValue($event, l.input)"
        />
        <!-- select -->
        <select
          v-if="l.select && config && canSaveWidgetSettings"
          class="form-control"
          :value="config[l.input]"
          :placeholder="l.input"
          @click.prevent.stop="prevent"
          @input.prevent.stop="setInputValue($event, l.input)"
        >
          <option :value="null"></option>
          <option :value="q.record_no" v-for="q in queries" :key="q.record_no">
            {{ q.description }}
          </option>
        </select>
        <!-- if we can save widget settings we don't autoclose -->
        <a
          class="dropdown-item"
          href="#"
          :class="{ disabled: l.disabled, selected: l.selected }"
          @click.prevent.stop="l.handler"
          v-if="l.handler && config && canSaveWidgetSettings"
          >{{ l.tlabel ? $t(l.tlabel) : l.label
          }}<span class="pull-right" v-if="l.selected"
            ><i class="fas fa-check"></i></span
        ></a>
      </li>
      <li v-if="config && canSaveWidgetSettings">
        <div class="dropdown-divider"></div>
        <a href="#" @click.prevent="config.saveValues()" class="dropdown-item"
          ><b>save</b></a
        >
      </li>
    </ul>
  </div>
</template>
<script>
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import store from "@/store";

export default {
  data() {
    return {
      cid: _.uniqueId("mid"),
      isOpen: false,
      sqlLoaded: false,
    };
  },
  computed: {
    ...mapState({
      queries: (state) => state.queries.list,
    }),
    ...mapGetters({ canUser: "permissions/canUser" }),
    canSaveWidgetSettings() {
      return this.canUser("save-widget-settings");
    },
    hasSqlQuerySelection() {
      return this.items.some((i) => i.source === "sql-queries");
    },
  },
  mounted() {
    this.$el.addEventListener("show.bs.dropdown", (e) => {
      this.isOpen = true;
    });
    this.$el.addEventListener("hide.bs.dropdown", (e) => {
      this.isOpen = false;
    });
    if (this.hasSqlQuerySelection) {
      this.loadSqlQueries();
    }
  },
  beforeUnmount() {
    this.$el.removeEventListener("show.bs.dropdown", () => {
      this.isOpen = true;
    });
    this.$el.removeEventListener("hide.bs.dropdown", () => {
      this.isOpen = false;
    });
  },
  methods: {
    setInputValue(e, input) {
      let updateValues = {};
      updateValues[input] = e.target.value;
      this.config.setValues(updateValues);
    },
    loadSqlQueries() {
      //might need to queue this later on.
      this.sqlLoaded = false;
      store.dispatch("queries/loadQueryList").then(() => {
        this.sqlLoaded = true;
      });
    },
    prevent(e){
      e.preventDefault();
      
    }
  },
  watch: {
    hasSqlQuerySelection(has) {
      if (has) {
        this.loadSqlQueries();
      }
    },
  },
  props: ["items", "config"],
};
</script>