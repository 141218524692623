<template>
  <canvas
    :id="'chart-' + cid"
    :key="cid"
    :class="{ loading }"
    style="width: 100%; height: 100%"
  ></canvas>
</template>
<script>
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";


Chart.defaults.global.legend.onClick = function (e, legendItem) {
  var index = legendItem.datasetIndex;
  var ci = this.chart;
  console.log(e, ci);
  if (!e.ctrlKey) {
    // Do the original logic
    var meta = ci.getDatasetMeta(index);

    // See controller.isDatasetVisible comment
    meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

    // We hid a dataset ... rerender the chart
    ci.update();
  } else {
    for (let i = 0; i < ci.legend.legendItems.length; i++) {
      if (i !== index) {
        let meta = ci.getDatasetMeta(i);
        meta.hidden = meta.hidden === null ? !ci.data.datasets[i].hidden : null;
      }
    }
    ci.update();
  }
};
// The original draw function for the line chart. This will be applied after we have drawn our highlight range (as a rectangle behind the line chart).
var originalLineDraw = Chart.controllers.line.prototype.draw;
// Extend the line chart, in order to override the draw function.
Chart.helpers.extend(Chart.controllers.line.prototype, {
  draw: function () {
    var chart = this.chart;
    // Get the object that determines the region to highlight.
    var yHighlightRange = chart.config.data.yHighlightRange;

    // If the object exists.
    if (yHighlightRange !== undefined) {
      var ctx = chart.chart.ctx;

      var yRangeBegin = yHighlightRange.begin;
      var yRangeEnd = yHighlightRange.end;

      var xaxis = chart.scales["x-axis-0"];
      var yaxis = chart.scales["y-axis-0"];

      var yRangeBeginPixel = yaxis.getPixelForValue(yRangeBegin);
      var yRangeEndPixel = yaxis.getPixelForValue(yRangeEnd);

      ctx.save();
      try {
        // The fill style of the rectangle we are about to fill.
        ctx.fillStyle = "rgba(0,255,0,0.2)";
        // Fill the rectangle that represents the highlight region. The parameters are the closest-to-starting-point pixel's x-coordinate,
        // the closest-to-starting-point pixel's y-coordinate, the width of the rectangle in pixels, and the height of the rectangle in pixels, respectively.
        ctx.fillRect(
          xaxis.left,
          Math.min(yRangeBeginPixel, yRangeEndPixel),
          xaxis.right - xaxis.left,
          Math.max(yRangeBeginPixel, yRangeEndPixel) -
            Math.min(yRangeBeginPixel, yRangeEndPixel)
        );
      } catch (error) {
        console.warn("something went wrong during fillRect");
      }
      ctx.restore();
    }

    // Apply the original draw function for the line chart.
    originalLineDraw.apply(this, arguments);
  },
});

export default {
  emits: ["remount"],
  props: {
    dataset: {
      required: true,
    },
    acceptedRange: {
      default: {},
    },
    type: {
      default: "timeline",
    },
    labelMode: {
      default: "hours",
    },
    addRuntime: {
      default: true,
    },
    tooltipsEnabled: {
      default: true,
    },
    tlabel: {
      default: null,
    },
    labelStyle: {
      default: null,
    },
    updateOnDataChange:{
      default:false
    },
    animationDuration: {
      default: 650,
    },
    axisColor: {
      default: "#666",
    },
    lineColor:{
      default:"rgba(0,0,0,0.9)"
    },
    gridColor: {
      default: "rgba(0, 0, 0, 0.1)",
    },
    backgroundEnabled:{
      default:false,
    },
    backgroundColor:{
      default:"rgba(0,0,0,0)"
    },
    axisLabelColor: {
      default: "#666",
    },
    xNumeric: {
      default: false,
    },
    xUnit: {
      default: null,
    },
    xFormat: {
      default: "MMM D",
    },
  },
  data() {
    return {
      cid: _.uniqueId("graph"),
      loaded: false,
      loading: false,
      ctx: null,
      chart: null,
      drawn: false,
      onMobile: true,
    };
  },
  mounted() {
    this.drawn = false;
    this.ctx = document.getElementById("chart-" + this.cid);
    this.loadAndDraw();
  },
  beforeUnmount() {
    console.warn("unmounting");
    this.drawn = false;
    this.chart.destroy();
  },
  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    loadAndDraw() {
      this.drawChart();
    },
    drawChart() {
      if (!this.drawn) {
        this.draw();
      } else {
        this.reDraw();
      }
    },
    draw() {
      try {
        console.warn("drawing!", this.data.datasets[0].data);
        this.chart = new Chart(this.ctx, {
          type: "line",
          options: this.options,
          data: this.data,
        });
        this.drawn = true;
      } catch (err) {
        console.warn("graph error", err);
      }
    },
    reDraw() {
      console.warn("redraw triggered..");
      this.chart.data.datasets[0].data = this.dataset;
      this.chart.update();
    },
    destroyAndRenew() {
      //prevent multiple calls in a row
       //prevent multiple calls in a row
      if (this.chart) {
        this.chart.destroy();
        this.drawn = false;
        let monitors = this.$el
          .closest("div")
          .querySelectorAll(".chartjs-size-monitor");
        if (monitors) {
          monitors.forEach((e) => e.remove());
        }
        let canvas = document.getElementById("chart-" + this.cid);
        canvas.removeAttribute("height");
        canvas.removeAttribute("width");
        canvas.removeAttribute("style");
        console.warn("destroyed that graph...", monitors);
      }
      this.drawChart();
      //this.chart.chart.update();
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({
      configByMachine: "machines/configByMachine",
    }),
    machine_id() {
      if (this.source !== "machine") {
        return null;
      }
      return this.machine.machine_id;
    },
    combinedLocation() {
      if (this.source !== "machine") {
        return this.locationtype + "|" + this.location;
      }
      return undefined;
    },
    config() {
      return this.configByMachine(this.machine_id);
    },
    tcomp() {
      return this.tlabel
        ? this.tlabel
        : this.current
        ? this.current.tlabel
        : null;
    },
    data() {
      return {
        datasets: [
          {
            data: this.dataset,
            borderWidth: 1,
            borderColor: this.lineColor ,
            backgroundColor: this.backgroundEnabled ? this.backgroundColor : "rgba(0,0,0,0)",
            pointBackgroundColor: this.lineColor,
          },
        ],
        yHighlightRange: this.acceptedRange,
      };
    },
    options() {
      let ticks = {};
      if (this.acceptedRange && this.acceptedRange.min !== undefined) {
        ticks = {
          suggestedMin: this.acceptedRange.min,
          suggestedMax: this.acceptedRange.max,
        };
      }
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        animation: {
          duration:0
        },
        scales: {
          xAxes: [
            {
              type: this.xNumeric ? "linear" : "time",
              time: {
                unit: this.xUnit,
                displayFormats: {
                  day: this.xFormat,
                },
              },
            },
          ],
          yAxes: [
            {
              ticks: ticks,
            },
          ],
        },
        onResize: function (chart, size) {
          //console.warn(size);
          chart.update();
        },
      };
    },
  },
  watch: {
    dataset: {
      deep: true,
      handler: function (val) {
        //this.reDraw();
        if (this.updateOnDataChange) {
          //this.chart.update();
          this.reDraw();
          console.warn("totally updating the chart..");
        }
        //console.warn(val);
      },
    },
    acceptedRange: {
      deep: true,
      handler: function () {
        //this.chart.update();
        if (this.updateOnDataChange) {
          this.chart.update(0);
        }
      },
    },
  },
  components: {},
};
</script>