<template>
  <div class="interface-swap">
    <button
      class="btn float-end"
      type="button"
      id="machineSwapDropdown"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ liveview?.label }}
    </button>
    <ul
      class="dropdown-menu dropdown-menu-center"
      aria-labelledby="machineSwapDropdown"
    >
      <li v-for="liveview in liveviewsMenuItems" :key="liveview.id">
        <router-link href="" class="dropdown-item" :to="liveview.to">{{liveview.label}}</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";
import _ from "lodash";
import store from "@/store";
export default {
  props: {},
  mounted() {},
  computed: {
    ...mapState({
      liveviewsTemplates: (state) => state.config.templates.liveviews,
      liveviewsConfigs: (state) => state.config.liveviews,
    }),
    liveviews() {
      return Object.keys(this.liveviewsTemplates).map((key) => {
        return {
          ...(this.liveviewsConfigs[key] || {}),
          key,
        };
      });
    },
    liveview() {
      return this.liveviewsConfigs[this.$route.params.liveview];
    },
    liveviewsMenuItems() {
      return this.liveviews.map((db) => {
        return {
          label: db.label,
          id: db.key,
          to: "/liveview/" + db.key,
        };
      });
    },
  },
  data() {
    return {};
  },
  watch: {},

  methods: {},
  components: {},
};
</script>
<style>
</style>