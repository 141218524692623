<template>
  <div class="time-history-wrap" :class="{ fullscreen }">
    <slot></slot>
    <div class="time-history times" :class="{ fullscreen }">
      <div class="time-label"></div>
      <div class="time-wrap">
        <div
          class="time-indicator"
          :class="{ hasLabel: index % ticksEveryN === 0 }"
          v-for="(hour, index) in hours"
          :key="index"
          :style="{ width: hour.per + '%' }"
        >
          <label for="">{{ hour.time }} </label>
          <label class="nowrap date" v-if="hour.date">{{ hour.date }}</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";
import TimeSlot from "./TimeSlot";

export default {
  emits: ["addSelect"],
  props: {
    start: {
      required: true,
    },
    startTime: {
      required: true,
    },
    end: {
      required: true,
    },
  },
  data() {
    return {
      cid: _.uniqueId("time-history"),
      active: null,
      fullscreen: false,
    };
  },
  mounted() {
  },
  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    startTimeRound() {
      let hDate = new Date(this.start);
      hDate.setHours(hDate.getHours() + 1);
      hDate.setMinutes(0);
      hDate.setSeconds(0);
      return hDate;
    },
  },
  computed: {
    ...mapState({
      screenWidth: (state) => state.screenWidth,
    }),
    ticksEveryN() {
      return Math.ceil((this.hours.length * 64) / this.screenWidth);
    },
    startDate() {
      return new Date(this.start);
    },
    endDate() {
      return new Date(this.end);
    },
    
    hours() {
      let hours = [];
      let inversed = 100 / this.secondsSpanned;
      hours.push({
        per: this.secondsToFirstHour * inversed,
        time: this.startTime.slice(11, 16),
        date: this.startTime.slice(5, 10),
      });
      let day = new Date(this.start).getDay();
      let date = this.startTimeRound();
      //console.warn(day,this.start,date);
      for (let i = 0; i < this.hoursSpanned; i++) {
        date.setHours(date.getHours() + (i === 0 ? 0 : 1));
        let iDay = date.getDay();
        hours.push({
          per: (i === this.hoursSpanned - 1 ? this.secondsLeftOver : 3600) * inversed,
          time: date
            .toBalthzarTime()
            .slice(11, 16),
          date:
            day === iDay
              ? null
              : date.toBalthzarTime().slice(5, 10),
        });
        day = iDay;
      }
      return hours;
    },
    secondsSpanned() {
      return (this.endDate.getTime() - this.startDate.getTime()) / 1000;
    },
    hoursSpanned() {
      return Math.floor((this.secondsSpanned + 500)/ 3600);
    },
    secondsToFirstHour() {
      return (this.startTimeRound().getTime() - this.startDate.getTime()) / 1000;
    },
    secondsLeftOver(){
      return (this.secondsSpanned - this.secondsToFirstHour) % 3600;
    }
  },
};
</script>