<template>
  <div>
    <loading v-if="!loaded" />
    <div v-else style="width: 100%; max-width: 600px; margin: 0 auto">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th>Produced #</th>
            <td v-for="cavity in usedCavities" :key="'produced-' + cavity">
              <input
                class="form-control small"
                :class="{
                  focused:
                    activeKey == 'prodquantity' && activeIndex == cavity - 1,
                }"
                type="number"
                @focus="activateNumPad('prodquantity', cavity - 1)"
                v-model="data[tab.dataKey].prodquantity[cavity - 1]"
                @input="carryUp('prodquantity', cavity - 1)"
              />
            </td>
          </tr>
          <tr>
            <th>Kasserat #</th>
            <td v-for="cavity in usedCavities" :key="'scrap-' + cavity">
              <input
                type="number"
                class="form-control small"
                :class="{
                  focused:
                    activeKey == 'scrapquantity' && activeIndex == cavity - 1,
                }"
                @focus="activateNumPad('scrapquantity', cavity - 1)"
                v-model="data[tab.dataKey].scrapquantity[cavity - 1]"
                @input="carryUp('scrapquantity', cavity - 1)"
              />
            </td>
          </tr>
          <tr>
            <th>Prima #</th>
            <td v-for="cavity in usedCavities" :key="'good-' + cavity">
              <input
                type="number"
                class="form-control small"
                :class="{
                  focused:
                    activeKey == 'goodquantity' && activeIndex == cavity - 1,
                }"
                @focus="activateNumPad('goodquantity', cavity - 1)"
                v-model="data[tab.dataKey].goodquantity[cavity - 1]"
                @input="carryUp('goodquantity', cavity - 1)"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <select
        name="num_copies"
        id=""
        v-model="data[tab.dataKey].num_copies"
        class="form-control"
      >
        <option :value="null"></option>
        <option :value="i" :key="i" v-for="i in 10">{{ i }}</option>
      </select>
      <label for="">{{ $t("406") }}</label>
      <select
        name="printer"
        id=""
        v-model="data[tab.dataKey].printer"
        class="form-control"
      >
        <option :value="null"></option>
        <option
          :value="printer.printer_no"
          :key="printer.printer_no"
          v-for="printer in printers"
        >
          {{ printer.printer_description }}
        </option>
      </select>
      <numerical-input
        v-model="data[tab.dataKey][activeKey][activeIndex]"
        :include-step="true"
        :keypress-listener="false"
        @update:modelValue="carryUp(activeKey, activeIndex)"
        @step="stepThrough"
      ></numerical-input>
    </div>

    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      activeKey: "prodquantity",
      activeIndex: 0,
    };
  },
  methods: {
    carryUp(k, index) {
      let v = this.data[this.tab.dataKey][k][index];
      for (let i = index + 1; i < this.usedCavities; i++) {
        this.data[this.tab.dataKey][k][i] = v;
      }
    },
    activateNumPad(k, index) {
      this.activeKey = k;
      this.activeIndex = index;
    },
    stepThrough() {
      this.activeIndex++;
      if (this.activeIndex >= this.usedCavities) {
        this.activeIndex = 0;
        this.activeKey =
          this.activeKey == "prodquantity"
            ? "scrapquantity"
            : this.activeKey == "scrapquantity"
            ? "goodquantity"
            : "prodquantity";
      }
    },
  },
  mounted() {
    ["prodquantity", "goodquantity", "scrapquantity"].forEach((k) => {
      for (let i = 0; i < this.usedCavities; i++) {
        if (this.data[this.tab.dataKey][k][i] === undefined) {
          this.data[this.tab.dataKey][k][i] = 0;
        }
      }
    });
  },
  computed: {
    ...mapState({
      loaded: (state) => state.printers.loaded,
      printers: (state) => state.printers.list,
    }),
    multiPrintData() {
      return this.data[this.tab.dataKey];
    },
    cavities() {
      return this.data.order?.selected?.insatser || 1;
    },
    usedCavities() {
      return 3; Math.min(
        this.cavities - (this.data.order?.selected?.cavity_reduction || 0),
        3
      );
    },
    disableNext() {
      return (
        !this.loaded ||
        this.data[this.tab.dataKey].printer === null ||
        this.data[this.tab.dataKey].printer == "" ||
        this.data[this.tab.dataKey].num_copies === null
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.focused {
  border-bottom: 2px solid black;
}
th {
  text-align: right;
}
</style>