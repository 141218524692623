<template>
  <div></div>
</template>
<script>
import { mapState } from "vuex";
import _ from "lodash";
import store from "@/store";

export default {
  data() {
    return {
      intervalId: null,
      currentTime: new Date().getTime(),
      internalClock: 0,
      slowDown: 1,
      backgroundMode: false,
      bgModeStarted: null,
    };
  },
  mounted() {
    let vm = this;
    document.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      false
    );
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = window.setInterval(() => {
      if (
        !vm.backgroundMode ||
        (vm.internalClock % 60 === 0 &&
          vm.internalClock - vm.bgModeStarted < 1200)
      ) {
        vm.currentTime = new Date().getTime();
        vm.fireNonKeyed();
        vm.fireKeyed();
      }
      vm.internalClock++;
      /*
      console.log(
        "%c update timer doing it's thing " +
          vm.internalClock +
          " - " +
          vm.currentTime,
        "background: #eee; color: #bada55"
      );
      */
    }, 1000);
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    handleVisibilityChange() {
      if (document.visibilityState == "hidden") {
        this.backgroundMode = true;
        this.bgModeStarted = this.internalClock;
        document.title = (this.$route.meta.title || "BalthzarWeb") + " - zZz";
        console.log(
          "%c went into background mode at " + this.internalClock,
          "background:#000;color:#fff"
        );
      } else {
        this.backgroundMode = false;
        this.bgModeStarted = null;
        document.title = this.$route.meta.title || "BalthzarWeb";
        console.log(
          "%c went out of background mode at " + this.internalClock,
          "background:#000;color:#fff"
        );
      }
    },
    fireNonKeyed() {
      _.each(this.nonKeyed, this.fireIfIntervalPassed);
    },
    fireIfIntervalPassed({ action, interval }) {
      //check if interval has passed since lastFired
      if (
        !this.updateFlags[action] &&
        this.currentTime >=
          this.lastRunPlanned[action] + interval * this.slowDown * 1000
      ) {
        //if it has, fire and update interval
        store.commit("updates/setUpdateFlag", { action, flag: true });
        store
          .dispatch(action)
          .then(() => {
            store.commit("updates/setLastRunPlanned", action);
            store.commit("updates/setUpdateFlag", { action, flag: false });
          })
          .catch(() => {
            //event logging
            store.commit("updates/setLastRunPlanned", action);
            store.commit("updates/setUpdateFlag", { action, flag: false });
          });
        //console.log(`%c update fired for action: ${action}`,'background: #000; color: #fff; font-size:20px;');
      }
      //else, let it go
      //console.log(action,interval);
    },
    fireKeyed() {
      //console.log(this.keyed);
      _.each(this.keyed, this.fireKeyedIfIntervalPassed);
    },
    fireKeyedIfIntervalPassed({ action, parameters, primaryKey, interval }) {
      //check if interval has passed since lastFired
      let primaryPar = parameters[primaryKey];
      if (
        !this.keyedFlags[action][primaryPar] &&
        this.currentTime >=
          this.lastRunKeyed[action][primaryPar] +
            interval * this.slowDown * 1000
      ) {
        //if it has, fire and update interval
        store.commit("updates/setKeyedFlag", {
          action,
          primaryPar,
          flag: true,
        });
        store
          .dispatch(action, parameters)
          .then(() => {
            store.commit("updates/setLastRunKeyed", { action, primaryPar });
            store.commit("updates/setKeyedFlag", {
              action,
              primaryPar,
              flag: false,
            });
          })
          .catch(() => {
            //event logging
            store.commit("updates/setLastRunKeyed", { action, primaryPar });
            store.commit("updates/setKeyedFlag", {
              action,
              primaryPar,
              flag: false,
            });
          });
        //console.log(`%c update fired for action: ${action}`,'background: #222; color: #ff5; font-size:20px;');
        //console.log(parameters);
      }
      //else, let it go
      //console.log(action,interval);
    },
  },
  computed: {
    ...mapState({
      updates: (state) => state.updates,
      lastRunPlanned: (state) => state.updates.lastRunPlanned,
      lastRunKeyed: (state) => state.updates.lastRunKeyed,
      updateFlags: (state) => state.updates.updateFlags,
      keyedFlags: (state) => state.updates.keyedFlags,
    }),
    nonKeyed() {
      const plannedUpdates = this.updates.plannedUpdates;
      const unique = [...new Set(plannedUpdates.map((item) => item.action))];
      return _.map(unique, (action) => {
        return _.minBy(
          _.filter(plannedUpdates, (item) => item.action === action),
          "interval"
        );
      });
    },
    keyed() {
      const keyedUpdates = this.updates.keyedUpdates;
      const unique = [
        ...new Set(
          keyedUpdates.map((item) => {
            return item.action + "|" + item.parameters[item.primaryKey];
          })
        ),
      ];
      return _.map(unique, (action) => {
        return _.minBy(
          _.filter(keyedUpdates, (u) => {
            let parts = action.split("|");
            return (
              u.action == parts[0] && u.parameters[u.primaryKey] == parts[1]
            );
          })
        );
      });
    },
  },
};
</script>