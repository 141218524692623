<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div v-if="machine" class="max-align">
      <h1>
        <!-- 1549 : availability -->
        {{ $t("1549") }} - {{ periodT ? $t(periodT) : "" }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div class="flex has-chart" style="flex: 1">
        <graph
          :period="config.period"
          :machine="machine"
          labelMode="perRaw"
          :interval="interval"
          task="availability"
          type="clampLine"
          labelStyle="short"
          :animationDuration="10"
          v-if="config.isLoaded"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import Graph from "@/components/Graph";
import { Pulse } from "@/helpers/heartbeat";
import { shortDateTime } from "@/helpers/dates";
import { Configuration } from "@/helpers/settings";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 175,
      config: new Configuration(
        "availability-graph",
        {
          period: "shift",
        },
        this.template
      ),
    };
  },
  props: {
    template: {
      type: String,
    },
  },
  beforeUnmount() {},
  methods: {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    periodT() {
      let periods = {
        "24h": "448",
        today: "447",
        shift: "245",
      };
      //return "5";
      return periods[this.config.period] || "-";
    },
    ...mapGetters({
      machineById: "machines/byId",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    items() {
      return [
        { header: this.$t("1329") },
        {
          tlabel: "448",
          handler: () => {
            this.config.setValues({ period: "24h" });
          },
          selected: this.config.period === "24h",
        },
        {
          tlabel: "447",
          handler: () => {
            this.config.setValues({ period: "today" });
          },
          selected: this.config.period === "today",
        },
        {
          tlabel: "245",
          handler: () => {
            this.config.setValues({ period: "shift" });
          },
          selected: this.config.period === "shift",
        },
      ];
    },
    order_finished_formatted_short() {
      return shortDateTime(this.machine.order_finished);
    },
  },
  components: {
    CardMenu,
    Graph,
  },
};
</script>