<template>
  <div class="yesnowithout">
    <yesno v-model="modelValue.entered_value" @next="checkNext()" :disabled="locked" />
  </div>
</template>

<script>
import yesno from "./YesNo";
import _ from "lodash";

export default {
  emits: ["update:modelValue", "next", "lock"],
  props: {
    modelValue: {},
    item: {},
    active: {},
  },
  data() {
    return {
    };
  },
  mounted(){
    this.$emit('lock',this.locked);
  },
  computed:{
    locked(){
      return !this.item.allow_user_edit
    }
  },
  components: { yesno },
  watch: {
    modelValue: {
      deep: true,
      handler: function (val) {
        this.$emit("update:modelValue", val);
      },
    },
    active(val) {
    },
  },
  methods: {
    checkNext() {
        this.modelValue.isdone = true;
      this.$emit("next");
    },
  },
};
</script>

<style>
</style>