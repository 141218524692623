import axios from "@/requests/axios";
import { flattenMultiRow, flattenSingleRow } from "@/requests/requestParcer";
import { setStorage, getStorage } from "@/helpers/storage";
import _ from 'lodash';
import { checkStorage, removeStorage } from "../../helpers/storage";
import { getNow } from "../../helpers/dates";
import { execute_json } from "@/helpers/requests";

const state = () => ({
    index: [],
    indexLoaded: false,
    machineCodes: {

    },
    lastStopReasons: null
});

// getters
const getters = {
    type1Codes: (state) => {
        return state.index.filter(sc => sc.code_type.trim() == "1");
    },
    stopCodesByMachine: (state, getters) => (machine_id) => {
        let codes = state.machineCodes[machine_id] || [];
        return getters.type1Codes.filter(sc => codes.includes(sc.stop_code));
    },
    setupCodesByMachine: (state, getters) => (machine_id) => {
        return getters.stopCodesByMachine(machine_id) ? getters.stopCodesByMachine(machine_id).filter(s => s.setup_time) : [];
    },
    stopCodeByStopReason: (state, getters) => (stop_reason) => {
        if (stop_reason === '-') {
            return {
                stop_reason_color: "lightgray",
                stop_description: "Uncoded stop"
            }
        }
        if (stop_reason == '') {
            return {
                stop_reason_color: "green",
                stop_description: "Runtime"
            }
        }

        return state.index.filter(sc => sc.stop_code == stop_reason)[0] || {};
    }
}

// actions
const actions = {
    loadStopCodesIndex({ state, commit, rootState }, { force }) {
        return new Promise((resolve, reject) => {
            let company_id = rootState.auth.company_id;
            let storageKey = company_id + "_lastUsedStopCodeHandlers";
            if (state.lastStopReasons === null) {
                commit("initLastUsed", storageKey);
            }
            if (state.indexLoaded && force === undefined) {
                resolve();
            } else {
                let storageStr = rootState.auth.company_id + "_stop_codes_index";
                if (!checkStorage(storageStr) || force) {
                    //do call
                    execute_json("get_stop_reasons", {
                        active_only: true,
                        used_only: true
                    })
                        .then(({ data }) => {//
                            setStorage(storageStr, data)
                            commit("setStopCodesIndex", {
                                stopCodes: data
                            });
                            commit('events/beConfident', {}, { root: true });
                            resolve();
                        })
                        .catch((err) => {
                            reject(err);
                        });
                } else {
                    commit("setStopCodesIndex", {
                        stopCodes: getStorage(storageStr)
                    });
                    resolve();
                }
            }
        });
    },
    loadStopcodesForSelectedUncoded({ rootState, dispatch }) {
        let unique = [...new Set(rootState.uncoded.selected.map(item => item.machine_id))];
        let promises = [];
        for (let i = 0; i < unique.length; i++) {
            let machine_id = unique[i];
            promises.push(dispatch('loadStopcodesForMachine', { machine_id }));
            promises.push(dispatch('machines/loadTouchConfigForMachine', { machine_id }, { root: true }));
        }
        return Promise.all(promises);
    },
    loadStopcodesForMachine({ commit, dispatch, rootState, rootGetters }, { machine_id, reloadCachedValues }) {
        //console.warn("stopcodes >");
        //console.warn("machine_id??", machine_id);
        return dispatch("loadStopCodesIndex", {}).then(() => {
            let storageStr = rootState.auth.company_id + "_stop_codes_only_machine_" + machine_id;
            if (!checkStorage(storageStr) || reloadCachedValues) {
                return new Promise((resolve, reject) => {
                    execute_json("get_stop_reason_list_for_machine", {
                        machine_id,
                        only_codes: true
                    })
                        .then(({ data }) => {//
                            commit('setStopCodesForMachine', {
                                machine_id,
                                stopCodes: data,
                                company_id: rootState.auth.company_id
                            });
                            setStorage(storageStr, data);
                            commit('events/beConfident', {}, { root: true });
                            resolve();
                        })
                        .catch((err) => {
                            reject(err);
                        })
                });
            } else {
                return new Promise((resolve) => {
                    commit('setStopCodesForMachine', {
                        machine_id,
                        stopCodes: getStorage(storageStr),
                        company_id: rootState.auth.company_id
                    });
                    resolve();
                })
            }
        }).catch((err) => {
            return new Promise((resolve, reject) => {
                reject(err);
            })
        });
    },
    clearStopcodes({ commit, rootState, dispatch }) {
        let keys = Object.keys(localStorage);
        let storagePath = rootState.auth.company_id + "_stop_codes_only_machine_";
        keys.forEach((key) => {
            if (key.includes(storagePath)) {
                removeStorage(key);
            }
        });
        removeStorage(rootState.auth.company_id + "_stop_codes_index");
        if (rootState.auth.machine) {
            dispatch("loadStopcodesForMachine", { machine_id: rootState.auth.machine });
        }
    },


    setStopcode({ commit, rootState, dispatch }, { machine_id, stopcode, note }) {
        return new Promise((resolve, reject) => {
            execute_json("report_stop_reason", {
                'username': rootState.auth.username,
                machine_id,
                note,
                'stop_reason': stopcode.stop_code
            }).then(() => {
                //commit('updateStopcode', { machine_id, stopcode });
                dispatch("lastUsedStopCodeHandler", { machine_id, stopcode });
                resolve();
            }).catch((err) => {
                reject(err);
            })
        });
    },
    lastUsedStopCodeHandler({ commit, state, rootState }, { machine_id, stopcode }) {
        let company_id = rootState.auth.company_id;
        let storageKey = company_id + "_lastUsedStopCodeHandlers";
        if (state.lastStopReasons === null) {
            commit("initLastUsed", storageKey);
        }
        commit("addStopCodeToLastUsed", { machine_id, stopcode });
        setStorage(storageKey, state.lastStopReasons);
        console.log("stop code set on machine_id: " + machine_id + ", stopcode: " + stopcode);
    }
}

// mutations
const mutations = {
    setStopCodesForMachine(state, { machine_id, stopCodes, company_id }) {
        state.machineCodes[machine_id] = stopCodes.map(o => o.stop_code);
    },
    setStopCodesIndex(state, { stopCodes }) {
        state.index = stopCodes;
        state.indexLoaded = true;
    },
    initLastUsed(state, storageKey) {
        if (checkStorage(storageKey)) {
            state.lastStopReasons = getStorage(storageKey);
        } else {
            state.lastStopReasons = {};
        }
    },
    addStopCodeToLastUsed(state, { machine_id, stopcode }) {
        if (state.lastStopReasons[machine_id] == undefined) {
            state.lastStopReasons[machine_id] = [];
        }
        state.lastStopReasons[machine_id] = state.lastStopReasons[machine_id].filter(sc => sc.stop_code != stopcode.stop_code);
        state.lastStopReasons[machine_id].unshift(stopcode);
        state.lastStopReasons[machine_id] = state.lastStopReasons[machine_id].map((sc,i) => { return {
            ...sc,
            index:i
        }})
        if (state.lastStopReasons[machine_id].length > 10) {
            state.lastStopReasons[machine_id].pop();
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}