<template>
  <div>
    <canvas
      :id="'canvas-page-' + i"
      v-for="i in numPages"
      :key="i"
      style="max-width: 100%"
    ></canvas>
  </div>
</template>

<script>
export default {
  props: {
    blob: {
      default: null,
    },
  },
  data() {
    return {
      pdf: null,
      numPages: 0,
      pageNumber: 1,
      cleanBlob: this.blob,
      rendering: false,
    };
  },
  mounted() {
    if (this.blob !== null) {
      this.renderBlob();
    }
  },
  methods: {
    renderBlob() {
      this.cleanBlob = this.blob.replace("data:application/pdf;base64,", "");
      this.renderPdf();
    },
    renderPdf() {
      var pdfData = atob(this.cleanBlob);
      var pdfjsLib = window["pdfjs-dist/build/pdf"];
      pdfjsLib.GlobalWorkerOptions.workerSrc =
        "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.8.335/pdf.worker.min.js";
      var loadingTask = pdfjsLib.getDocument({ data: pdfData });
      loadingTask.promise.then(
        (pdf) => {
          this.numPages = pdf.numPages;
          this.pdf = pdf;
          this.pageNumber = 1;

          // Fetch the first page
          this.startPageRender();
        },
        function (reason) {
          // PDF loading error
          console.error(reason);
        }
      );
    },
    startPageRender() {
      let vm = this;
      this.pdf.getPage(this.pageNumber).then(function (page) {
        console.log("Page loaded");

        var scale = 1.5;
        var viewport = page.getViewport({ scale: scale });

        // Prepare canvas using PDF page dimensions
        var canvas = document.getElementById("canvas-page-" + vm.pageNumber);
        var context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context
        var renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        var renderTask = page.render(renderContext);
        renderTask.promise.then(function () {
          console.log("Page rendered");
          vm.pageNumber++;
          if (vm.pageNumber <= vm.numPages) {
            vm.startPageRender();
          }
        });
      });
    },
  },
  watch: {
    blob() {
      this.renderBlob();
    },
  },
};
</script>

<style>
</style>