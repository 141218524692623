<template>
  <div>
    <numerical-input v-model="data[tab.dataKey].value"></numerical-input>
    <div v-if="hasSf325 && total_weight != null">
      <br/>
      <div class="weight-present-box">
        <p><i class="fas fa-balance-scale"></i> {{ total_weight?.toFixed(2) }}kg</p>
      </div>
       <button class="btn btn-warning" @click="fetchData">
        <i class="fa fa-sync"></i>{{$t('6733')}}
      </button>
      &nbsp;
      <button class="btn btn-success" @click="acceptWeight">
        <i class="fas fa-clipboard-check"></i> {{$t('1514')}}
      </button>
    </div>
    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { execute_json } from "@/helpers/requests";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  mounted() {
    if (this.hasSf325) {
      this.fetchData();
    }
  },
  data() {
    return {
      loading325: false,
      total_weight: null,
    };
  },
  computed: {
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
    }),
    disableNext() {
      return (
        (this.data[this.tab.dataKey].value === null ||
          this.data[this.tab.dataKey].value === "0.") &&
        (this.data[this.tab.dataKey].allowNull === undefined ||
          !this.data[this.tab.dataKey].allowNull)
      );
    },
    currentValue() {
      return this.data[this.tab.dataKey].value;
    },
    max() {
      return this.data[this.tab.dataKey].max || 0;
    },
    machine_id() {
      return this.$route.params.machine;
    },
    hasSf325() {
      return this.machineHasSf(this.machine_id, "325");
    },
  },
  methods: {
    fetchData() {
      execute_json("check_weight_result", {
        card_no: this.data.order?.selected?.card_no,
        part_no: this.data.order?.selected?.part_no,
        op_no: this.data.order?.selected?.op_number,
        machine_id: this.machine_id,
        scrap_weight:true //added to preempt ws changes that either listens to a flag
      })
        .then(({ data }) => {
          this.setData(data[0]);
          this.loading325 = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading325 = false;
          //let n = Notification.failed(err.statusText);
        });
    },
    setData({ total_weight, scrap_weight }) {
      this.total_weight = scrap_weight  !== undefined ? +scrap_weight :  total_weight / 1000; //or sends back scrap_weight
    },
    acceptWeight(){
      this.data[this.tab.dataKey].value = this.total_weight?.toFixed(2);
    }
  },
  watch: {
    currentValue(val) {
      if (this.max != 0 && this.max != undefined && val > this.max) {
        this.data[this.tab.dataKey].value = this.max;
      }
    },
  },
};
</script><style scoped lang="scss">
.weight-present-box {
  p {
    line-height: 42px;
    font-size: 42px;
  }
  i {
  }
}
</style>