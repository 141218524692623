<template>
  <div>
    <div
      v-if="showMachinePrio"
      class="machine-priority"
      @click.prevent="changePrio"
    >
      <div v-if="icon == null" class="numeric">-</div>
      <div v-else-if="isNumeric" class="numeric">
        {{ numericValue }}
      </div>
      <div v-else>
        <i :class="icon.icon" :style="{ color: icon.color }"></i>
      </div>
    </div>
    <div
      class="popup-wrap"
      @click.self.prevent.stop="changePopup = false"
      v-if="changePopup === true"
    >
      <div class="popup" @click.stop.prevent="changePopup = true">
        <b-table
          v-if="!updating"
          :data="prioArray"
          primary-key="description"
          :column-config="{
            machine_priority: {
              component: 'machine-priority',
              config: {
                disabled: true,
              },
              label: 'MP',
            },
            description: {},
          }"
          class="minimal"
          :single-select="true"
          @selectionChanged="onSelectionChanged"
        >
        </b-table>
        <loading v-else />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "@/requests/axios";
import store from "@/store";
import {execute_json} from "@/helpers/requests";
export default {
  props: ["data", "config"],
  data() {
    return {
      changePopup: false,
      updating: false,
    };
  },
  computed: {
    ...mapState({
      priorities: (state) => state.settings.machinePriorities,
      settings: (state) => state.auth.settings,
    }),
    prioArray(){
      return Object.values(this.priorities);
    },
    showMachinePrio() {
      return this.settings.show_machine_priority >= 2;
    },
    editMachinePrio() {
      return this.settings.show_machine_priority >= 3;
    },
    isNumeric() {
      return this.icon?.icon?.indexOf("numeric_") > -1;
    },
    numericValue() {
      return this.icon?.icon?.replace("numeric_", "");
    },
    icon() {
      return this.data.machine_priority
        ? this.priorities?.[this.data.machine_priority]?.icon
        : null || null;
    },
  },
  methods: {
    changePrio(e) {
      if (this.config?.disabled) {
        return true;
      } else if (this.changePopup) {
        e.preventDefault();
        e.stopPropagation();
        return true;
      } else if (this.editMachinePrio) {
        e.stopPropagation();
        console.warn("CHANGE THE PRIO");
        this.changePopup = true;
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      if (selectedRowsData.length === 1) {
        this.updating = true;
        execute_json("machine_priority", {
          machine_id: this.data.machine_id,
          feature: "set",
          priority: selectedRowsData[0].machine_priority,
        })
          .then(() => {
            this.changePopup = false;
            store.dispatch("machines/loadMachine", {
              machine_id: this.data.machine_id,
            });
            this.updating = false;
          })
          .catch(() => {
            this.updating = false;
          });
      }
    },
  },
};
</script>