<template>
  <div class="numerical-input">
    <div class="numerical-output" v-if="showOutput">
      {{ modelValue }}
    </div>
    <!--<input type="number" :value="modelValue" @input="$emit('update:modelValue',$event.target.value)"> -->
    <div class="numerical-input-buttons">
      <button
        class="btn numerical-input-button"
        v-for="b in buttons"
        :key="b"
        :disabled="disabled"
        @click="buttonPress(b)"
      >
        {{ b }}
      </button>
       <button
        class="btn numerical-input-button"
        v-if="includeStep"
        :disabled="disabled"
        @click="$emit('step')"
      >
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      required: true,
    },
    showOutput: {
      default: true,
    },
    disabled: {
      default: false,
    },
    listening: {
      default: true,
    },
    keypressListener:{
      default:true
    },
    leadingZeros: {
      default: false,
    },
    includeStep:{
      default:false
    }
  },
  emits: ["update:modelValue", "enter","step"],
  data() {
    return {
      buttons: [1, 2, 3, 4, 5, 6, 7, 8, 9, "-", 0, ".", "C"],
      numerics: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
    };
  },
  created() {
    document.addEventListener("keypress", this.keypressHandler);
  },
  beforeUnmount() {
    document.removeEventListener("keypress", this.keypressHandler);
  },
  methods: {
    keypressHandler(e) {
      if (!this.keypressListener){
        return true;
      }
      let buttons = this.buttons;
      let numerics = this.numerics;
      let buttonPress = this.buttonPress;
      let $emit = this.$emit;
      e = e || window.event;
      let charCode = typeof e.which == "number" ? e.which : e.keyCode;
      console.log(charCode);
      let input = String.fromCharCode(charCode);
      console.log(input);
      switch (charCode) {
        case 13:
          $emit("enter");
          break;
        case 8:
          //backsp
          buttonPress("C");
          break;
        case 46:
          buttonPress("C");
          //delete
          break;
        default:
          if (numerics.includes(+input)) {
            buttonPress(+input);
          } else if (buttons.includes(input.toUpperCase())) {
            buttonPress(input.toUpperCase());
          }
      }
    },
    buttonPress(b) {
      if (this.listening && !this.disabled) {
        console.warn(b, this.modelValue);
        let v = null;
        if (this.numerics.includes(b)) {
          if (this.modelValue === null) {
            if (b === 0 && !this.leadingZeros) {
              v = "0.";
            } else {
              v = "" + b;
            }
          } else {
            if (!this.leadingZeros && this.modelValue == "0") {
              v = ""+b;
            } else {
              v = this.modelValue + "" + b;
            }
          }
        } else if (b === "C") {
          v = null;
        } else if (b === ".") {
          if (this.modelValue === null) {
            v = "0.";
          } else {
            v = this.modelValue + ".";
          }
        } else if (b === "-") {
          if (this.modelValue === null) {
            v = "-";
          } else {
            v = "-" + this.modelValue;
          }
        }
        if (v !== null) {
          v = v.replace("--", "");
        }
        this.$emit("update:modelValue", v);
      }
    },
  },
};
</script>