<template>
  <div class="popup large">
    <div class="row" v-if="currentStop === null">
      <div class="col col-12">
        <input
          type="range"
          name=""
          id=""
          min="0"
          max="100"
          v-model="splitPercentage"
          class="form-control"
        />
      </div>
      <div class="col col-12 current-stop align-left">
        <h4>{{ $t("1564") }}</h4>
        <p>
          <span class="setwidth-125">{{ $t("1951") }}:</span
          ><b>{{ data.stop_reason }} {{ data.stop_description }}</b> <br />
          <span class="setwidth-125">{{ $t("2547") }}:</span>
          <b>{{ data.adjusted_start_time }}</b
          ><br /><span class="setwidth-125">{{ $t("217") }}:</span>
          <b>{{ data.maxtime }}</b>
          <br />
          <span class="setwidth-125">{{ $t("3297") }}:</span
          ><b>{{ data.tot_stop_time_hh_mm }}</b>
        </p>
      </div>
      <div class="col col-sm-12 col-md-6 align-left">
        <h4>
          {{ $t("423") }} 1
          <span class="pull-right">{{ splitPercentage }}%</span>
        </h4>
        <p>
          <span class="setwidth-125">{{ $t("1951") }}:</span
          ><b
            >{{ stopreason1.stop_reason }} {{ stopreason1.stop_description }}</b
          >
          <br />
          <span class="setwidth-125">{{ $t("2547") }}:</span>
          <b>{{ stopreason1.adjusted_start_time }}</b
          ><br /><span class="setwidth-125">{{ $t("217") }}:</span>
          <b>{{ splittime }}</b>
          <br />
          <span class="setwidth-125">{{ $t("3297") }}:</span
          ><b>{{ stopreason1.tot_stop_time_hh_mm }}</b>
        </p>

        <button class="btn btn-success" @click="currentStop = 'stopreason1'">
          <i class="fas fa-hand-paper"></i>
        </button>
      </div>
      <div class="col col-sm-12 col-md-6 align-left">
        <h4>
          {{ $t("423") }} 2
          <span class="pull-right">{{ 100 - splitPercentage }}%</span>
        </h4>
        <p>
          <span class="setwidth-125">{{ $t("1951") }}:</span
          ><b
            >{{ stopreason2.stop_reason }} {{ stopreason2.stop_description }}</b
          >
          <br />
          <span class="setwidth-125">{{ $t("2547") }}:</span>
          <b>{{ splittime }}</b
          ><br /><span class="setwidth-125">{{ $t("217") }}:</span>
          <b>{{ stopreason2.maxtime }}</b>
          <br />
          <span class="setwidth-125">{{ $t("3297") }}:</span
          ><b>{{ stopreason2.tot_stop_time_hh_mm }}</b>1  
        </p>

        <button class="btn btn-success" @click="currentStop = 'stopreason2'">
          <i class="fas fa-hand-paper"></i>
        </button>
        <br />
        <br />
        <button class="btn btn-success pull-right" @click="closeAndSave">
          <i class="fas fa-chevron-right" v-if="!closing"></i>
          <loading v-else/>
        </button>
      </div>
    </div>
    <div v-else>
      <div
        class="content-block dx-card responsive-paddings containssf356"
        style="max-height: 400px; overflow-y: scroll"
        v-if="!loading"
      >
        <div class="sf356" v-if="hasSf356 && lastUsedForMachine">
          <b-table
            :data="lastUsedForMachine"
            :columnConfig="stopConfig"
            primary-key="stop_code"
            :show-headers="false"
            sort_dir="asc"
            sortKey="index"
            caption="6692"
            :single-select="true"
            @selectionChanged="onStopcodeSelectionChanged"
          >
          </b-table>
        </div>
        <div v-if="shouldGroupCodes && step == 0">
          <b-table
            :data="groupCodes"
            :columnConfig="groupConfig"
            primary-key="record_no"
            :sort-key="['code_order', 'code']"
            :sort_dir="['asc', 'asc']"
            sort_type="numerical"
            :show-headers="false"
            caption="5792"
            :single-select="true"
            @selectionChanged="onGroupSelectionChanged"
          >
          </b-table>
        </div>
        <div
          v-if="
            (!shouldGroupCodes && step == 0) || (shouldGroupCodes && step == 1)
          "
        >
          <b-table
            :data="filteredStopCodes"
            :columnConfig="stopConfig"
            primary-key="record_no"
            sort_type="numerical"
            :sort-key="['code_order', 'stop_code']"
            :sort_dir="['asc', 'asc']"
            :show-headers="false"
            caption="2319"
            :single-select="true"
            @selectionChanged="onStopcodeSelectionChanged"
          >
          </b-table>
        </div>
      </div>
      <button class="btn btn-success" @click="finishSelection">
        <i
          class="fas"
          :class="selectedStopcodes.length ? 'fa-check' : 'fa-chevron-left'"
        ></i>
      </button>
    </div>
  </div>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapState, mapGetters } from "vuex";
import { secondsToHhMm } from "@/helpers/dates";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";
import { flattenSingleRow } from "@/requests/requestParcer";
import store from "@/store";

export default {
  props: ["data"],
  emits: ["close"],
  data() {
    return {
      employeeNotOk: false,
      loading: true,
      value: null,
      materialData: null,
      splitPercentage: 50,
      splittime: null,
      currentStop: null,
      closing:false,
      stopreason1: {},
      stopreason2: {},
      step: 0,
      selectedGroup: null,
      selectedGroups: [],
      selectedCode: null,
      selectedStopcodes: [],
      groupConfig: {
        description: {
          class: "h50",
        },
      },
      stopConfig: {
        stop_description: {
          class: "h50",
        },
      },
    };
  },
  mounted() {
    this.stopreason1 = _.cloneDeep(this.data);
    this.stopreason2 = _.cloneDeep(this.data);
    this.calcSplitTime();
    let promises = [];
    promises.push(store.dispatch("groups/loadGroups"));
    promises.push(
      store.dispatch("stopcodes/loadStopcodesForMachine", {
        machine_id: this.machine_id,
        reloadCachedValues: false,
      })
    );
    promises.push(
      store.dispatch("machines/loadTouchConfigForMachine", {
        machine_id: this.machine_id,
        reloadCachedValues: false,
      })
    );
    Promise.all(promises).then(() => {
      this.loading = false;
    });
  },
  methods: {
    close() {
      this.$emit("close");
    },
    closeAndSave() {
      if (!this.closing) {
        this.closing = true;
        execute_json("split_stopreason", {
          machine_id: this.machine_id,
          start_stop2: this.splittime,
          stop_no: this.data.stop,
          stopreason1: this.stopreason1.stop_reason,
          stopreason2: this.stopreason2.stop_reason,
        }).then(() => {
          store
            .dispatch("machines/loadHistoryForMachine", {
              machine_id: this.machine_id,
              period: 7,
            })
            .then(() => {
              this.close();
            });
        }).catch(()=>{
          this.closing = false;
        })
      }
    },
    calcSplitTime() {
      let d = new Date(this.data.adjusted_start_time);
      let msStop1 = this.splitPercentage * this.data.tot_stop_time_sec * 10;
      let splittime = new Date(d.getTime() + msStop1).toBalthzarTime();
      this.splittime = splittime;
      this.stopreason1.tot_stop_time_sec = msStop1 / 1000;
      this.stopreason2.tot_stop_time_sec =
        this.data.tot_stop_time_sec - this.stopreason1.tot_stop_time_sec;

      this.stopreason1.tot_stop_time_hh_mm = secondsToHhMm(
        this.stopreason1.tot_stop_time_sec
      );
      this.stopreason2.tot_stop_time_hh_mm = secondsToHhMm(
        this.stopreason2.tot_stop_time_sec
      );
    },
    finishSelection() {
      if (this.selectedCode) {
        let newStop = this.selectedCode;
        //this[this.currentStop].stop = newStop.stop;
        console.log(newStop);
        this[this.currentStop].stop_reason = newStop.stop_code;
        this[this.currentStop].stop_description = newStop.stop_description;
      }
      this.currentStop = null;
      this.selectedStopcodes = [];
      this.selectedGroups = [];
      this.step = 0;
    },
    secondsToHhMm(s) {
      return secondsToHhMm(s);
    },
    onGroupSelectionChanged({ selectedRowsData }) {
      this.selectedGroups = selectedRowsData;
    },
    onStopcodeSelectionChanged({ selectedRowsData }) {
      this.selectedStopcodes = selectedRowsData;
    },
  },
  computed: {
    ...mapState({
      inversed: (state) => state.groups.inversed,
      lastUsedStops: (state) => state.stopcodes.lastStopReasons,
    }),
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
      signedInByMachine: "machines/signedInByMachine",
      configByMachine: "machines/configByMachine",
      stopCodesByMachine: "stopcodes/stopCodesByMachine",
      machineById: "machines/byId",
      runningByMachine: "orders/runningByMachine",
    }),
    machine_id() {
      return this.data.machine_id;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    enableSearchForStops() {
      return this.filteredStopCodes.length > 10;
    },
    enableSearchForGroups() {
      return this.groupCodes.length > 10;
    },
    stopCodesForMachine() {
      return this.stopCodesByMachine(this.machine_id);
    },
    config() {
      return this.configByMachine(this.machine_id);
    },
    hasSf356() {
      return this.machineHasSf(this.machine_id, "356");
    },
    shouldGroupCodes() {
      return this.config.stopreason_by_group;
    },
    groupCodes() {
      let unique = [
        ...new Set(this.stopCodesForMachine.map((item) => item.group_id)),
      ];

      let inversed = this.inversed;
      return _.orderBy(
        _.map(unique, function (id) {
          return inversed[id];
        }).filter((i) => i !== undefined),
        "code_order"
      );
    },
    filteredStopCodes() {
      if (!this.shouldGroupCodes) {
        return this.stopCodesForMachine;
      } else {
        let selectedGroup = this.selectedGroup;
        return _.orderBy(
          _.filter(this.stopCodesForMachine, function (sc) {
            return sc.group_id == selectedGroup;
          }),
          "code_order"
        );
      }
    },
    lastUsedForMachine() {
      return this.lastUsedStops?.[this.machine_id];
    },
  },
  watch: {
    splitPercentage: "calcSplitTime",
    selectedGroups: {
      deep: true,
      handler: function (v) {
        if (v.length) {
          this.selectedGroup = v[0].code;
          this.step = 1;
        }
      },
    },
    selectedStopcodes: {
      deep: true,
      handler: function (v) {
        if (v.length) {
          this.selectedCode = v[0];
          store.commit("bottomnav/enable");
          store.commit("bottomnav/setType", "stopcode-singular");
          store.commit("bottomnav/setData", this.selectedCode);
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.setwidth-125 {
  min-width: 125px;
  display: inline-block;
  @media screen and (max-width: 600px) {
    display: block;
    width: 100%;
  }
}
</style>