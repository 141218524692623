<template>
  <div class="machine-singular">
    <p>{{ material.material_no }}:{{ material.material_desc }}</p>
    <div class="buttons-wrap">
      <button
        class="router-button"
        @click.prevent="orderMaterials"
        href="#"
        v-if="hasSf110"
      >
        {{ $t("3079") }}
      </button>
      <button
        v-if="hasSf110"
        class="router-button"
        @click.prevent="orderReturnMaterials"
        href="#"
      >
        {{ $t("3461") }}
      </button>
    </div>
     <div class="buttons-wrap">
      <!--
         <button
        class="router-button"
        @click.prevent="inMaterials"
        href="#"
      >
        {{ $t("3462") }}
      </button>
         <button
        class="router-button"
        @click.prevent="outMaterials"
        href="#"
      >
        {{ $t("3463") }}
      </button>
      -->
      <button
        class="router-button"
        @click.prevent="scrapMaterials"
        href="#"
      >
        {{ $t("65") }}
      </button>
      
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import { Notification } from "@/helpers/notifications";

export default {
  props: {},
  setup() {},
  methods: {
    orderMaterials() {
      let n = Notification.request({
        theader: "3079",
        message: this.$t("3079"),
      });
      store
        .dispatch("materials/orderMaterials", {
          machine_id: this.machine_id,
          material: this.material,
        })
        .then(() => {
          n.ok({});
        })
        .else(() => {
          n.abort({});
        });
    },
    orderReturnMaterials() {
      let n = Notification.request({
        theader: "3461",
        message: this.$t("3461"),
      });
      store
        .dispatch("materials/orderReturnMaterials", {
          machine_id: this.machine_id,
          material: this.material,
        })
        .then(() => {
          n.ok({});
        })
        .else(() => {
          n.abort({});
        });
    },
    scrapMaterials(){
      store.commit("popups/addPopup", {
        type: "scrap-material",
        canClose: true,
        data: this.material
      });
    },
    inMaterials(){
      store.commit("popups/addPopup", {
        type: "in-material",
        canClose: true,
        data: this.material
      });
    },
    outMaterials(){
      store.commit("popups/addPopup", {
        type: "out-material",
        canClose: true,
        data: this.material
      });
    }
  },
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      material: (state) => state.bottomnav.data,
    }),
    ...mapGetters({
      //machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    hasSf110() {
      return this.machineHasSf(this.machine_id, "110");
    },
    machine_id() {
      return this.$route.params.machine;
    },
    routerlinks() {
      return {
        interface: "/interface/" + this.$route.params.machine,
      };
    },
  },
  components: {
    // DxButton
  },
};
</script>

<style lang="scss">
</style>
