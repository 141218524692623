import { createRouter, createWebHistory } from 'vue-router'
import simpleLayout from '@/layouts/SingleCard'
import defaultLayout from '@/layouts/DefaultLayout'
import dashboardLayout from "@/layouts/DashboardLayout"
import interfaceLayout from '@/layouts/InterfaceLayout'
import store from '@/store'

import { machineRoutes } from "./modules/machines";
import { stopRoutes } from "./modules/stops";
import { cavityRoutes } from './modules/cavities'
import { interfaceRoutes } from './modules/interface'
import { settingRoutes } from './modules/settings'
import { documentRoutes } from './modules/documents'
import { dashboardRoutes } from './modules/dashboards'
import { liveviewRoutes } from './modules/liveviews'
import { overviewRoutes } from './modules/overviews'
import { homeRoutes } from './modules/home'
import { dataRoutes } from './modules/data'
import { adminRoutes } from './modules/admin'
import { historyRoutes } from './modules/history'

function loadView(view) {
  return () => import(/* webpackChunkName: "[request]" */ `@/views/${view}.vue`)
}
function loadComponent(view) {
  return () => import(/* webpackChunkName: "[request]" */ `@/components/${view}.vue`)
}

const routes = [
  {
    path: "/login-form",
    name: "login-form",
    meta: {
      requiresAuth: false,
      layout: simpleLayout,
      title: "Sign In"
    },
    component: loadView("auth/login-form")
  },
  ...settingRoutes,
  ...machineRoutes,
  ...stopRoutes,
  ...cavityRoutes,
  ...interfaceRoutes,
  ...homeRoutes,
  ...dashboardRoutes,
  ...liveviewRoutes,
  ...overviewRoutes,
  ...documentRoutes,
  ...dataRoutes,
  ...adminRoutes,
  ...historyRoutes
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    //return { y : 0 }
    return { top: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.fullPath == from.fullPath) {
    console.log("double nav");
    next(false);
  }
  else if (to.path.indexOf("/undefined/undefined/") === -1 && to.path.indexOf("/undefined/undefined") > -1) {
    let path = to.path.replace("/undefined/undefined", "/all");
    console.log("%c caught a potential redirect loop neatly", "background:green;color:white;")
    next({ path });
  } else {

    if (to.name === "login-form" && store.state.auth.company_key) {
      if (store.state.auth.path) {
        next({ path: store.state.auth.path });
      } else {
        next({ name: "Home" });
      }
    }
    else if ((store.state.auth.dashboard_only == 'true' || store.state.auth.dashboard_only == true) && to.path.indexOf("/dashboards") == -1) {
      next({ path: "/dashboards/default" });
    }
    else if (store.state.auth.only_machine_interface && to.path.indexOf("/machines") !== -1) {
      store.dispatch('machines/loadMachines')
        .then(() => {
          let machineId = store.state.machines.index.map(m => m.machine_id).sort()[0];
          if (store.state.auth.web_single_machine !== '-1') {
            machineId = store.state.auth.web_single_machine;
          } else if (store.state.auth.machine) {
            machineId = store.state.auth.machine;
          }
          console.warn("machine only!", machineId);
          next({ path: "/interface/" + machineId });
        })
    }

    else if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.state.auth.company_key) {
        next({
          name: "login-form",
          query: { redirect: to.fullPath }
        });
      } else {
        if (store.state.machines.loaded) {
          window.document.title = to.meta.title || 'Balthzarweb';
          next();
        } else if (to.meta.layout == defaultLayout || to.meta.layout == interfaceLayout || to.meta.layout == dashboardLayout) {
          store.dispatch('machines/loadMachines')
            .then(() => {
              next();
            })
        } else {
          next();
        }
      }
    } else {
      next();
    }
  }
});

router.afterEach((to, from) => {
  if (to.fullPath == from.fullPath) {
    console.log("double nav");
    return false;
  }
  if (to.meta && to.meta.shouldRemember && to.matched.length === 1) {
    store.commit('auth/lastPath', { path: to.path, env: to.meta.layout == defaultLayout ? "default" : to.meta.layout == interfaceLayout ? "interface" : null });
    console.log("%c remember me path set to: " + to.path, "background:green;color:white");
  }

  if (to.matched.length > 1) {
    for (let i = 1; i < to.matched.length; i++) {
      let route = to.matched[i];
      if (route.meta && route.meta.shouldRemember) {
        store.commit('auth/lastPath', { path: to.path, env: to.meta.layout == defaultLayout ? "default" : to.meta.layout == interfaceLayout ? "interface" : null });
        console.log("%c remember me path set to parent route: " + to.path, "background:blue;color:white");
      }
      if (route.meta && route.meta.storeSuperType !== undefined) {
        store.commit('auth/lastSuperType', route.meta.storeSuperType);
        console.log("%c superType set to: " + route.meta.storeSuperType, "background:green;color:white");
      }
    }
  }
  if (to.params && to.params.machine) {
    store.commit("auth/lastMachine", to.params.machine);
  }
});

export default router
