<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget left-aligned">
    <div v-if="machine">
      <h1>
        {{ $t("30") }}
        <div class="card-menu-sibling" v-if="machine.card_no">
          <button class="btn" @click="details = true">
            <i class="fal fa-info-circle"></i>
          </button>
        </div>
        <card-menu :items="items"></card-menu>
      </h1>
      <div class="row">
        <div class="col-6">
          <p class="label-value">{{ $t("24") }}/{{ $t("603") }}</p>
          <p class="present-value bold">
            {{ machine.work_order_no }}
          </p>
          <p class="present-value small">
            {{ machine.op_number }}
          </p>
        </div>

        <div class="col-6">
          <p class="label-value">{{ $t("604") }}/{{ $t("19") }}</p>
          <p class="present-value bold">
            {{ machine.part_no }}
          </p>
          <p class="present-value small">
            {{ machine.part_description }}
          </p>
        </div>

        <div class="col-6">
          <p class="label-value">{{ $t("581") }}/{{ $t("4258") }}</p>
          <p class="present-value bold">
            <flex-value :value="machine.card_good_qty" />
          </p>
          <p class="present-value small">
            {{ machine.work_order_qty }}
          </p>
        </div>

        <div class="col-6">
          <p class="label-value">{{ $t("554") }}</p>
          <p class="present-value bold">
            <flex-value :value="machine.card_scrap_qty" digits="4" />
          </p>
        </div>

        <div class="col-6">
          <p class="label-value">{{ $t("1290") }}</p>
          <p class="present-value">
            {{ machine.order_rem_hours_hh_mm }}
          </p>
        </div>

        <div class="col-6">
          <p class="label-value">{{ $t("593") }}</p>
          <p class="present-value">
            {{ order_finished_formatted_short }}
          </p>
        </div>
      </div>
    </div>
    <order-details
      v-if="details"
      @close="details = false"
      :machine_id="machine.machine_id"
      :card_no="machine.card_no"
      :p_order="machine.p_order"
      :finished="false"
    ></order-details>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import { shortDateTime } from "@/helpers/dates";
import OrderDetails from "@/components/OrderDetails";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 180,
      details: false,
    };
  },
  mounted() {
    this.pulse = new Pulse(
      {
        action: "machines/loadMachine",
        interval: 3,
        primaryKey: "machine_id",
        parameters: { machine_id: this.machine_id },
      },
      {
        action: "orders/loadRunningOrderForMachine",
        parameters: { machine_id: this.machine_id, reload: false },
        interval: this.interval,
        primaryKey: "machine_id",
      }
    );
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({
      machineById: "machines/byId",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    items() {
      return [];
    },
    order_finished_formatted_short() {
      //return shortDateTime("2016-05-08 15:15");
      return shortDateTime(this.machine.order_finished);
    },
  },
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
    OrderDetails,
  },
};
</script>