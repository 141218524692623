<template>
  <div>
    <Loading v-if="loading" />
    <div v-else class="biscreenwrap">
      <div class="biscreen" :class="screen">
        <div class="screen screen-1">
          <div v-if="renderMode === 'native'" style="height:100%;">
            <embed
              :src="blob"
              v-for="(blob, i) in pdfsLoaded"
              :key="i"
              type=""
              v-show="active === i"
              class="pdftest"
            />
          </div>
          <pdf-viewer v-if="renderMode === 'pdfjs'" :blob="pdfsLoaded[active]"></pdf-viewer>
        </div>
        <div class="screen screen-2">
          <div class="accordeon">
            <accordeon-item
              v-for="(item, k) in formData"
              :key="item.lines_record_no"
              :item="item"
              v-model="answers[k]"
              :active="active === k"
              :employee="data.employee"
              @pdf="screen = 'screen-1'"
              @activate="activate(k)"
              @next="active++"
              ref="children"
            />
          </div>
        </div>
      </div>
    </div>

    <button
      class="tabbed-middle-button mobile-nav"
      @click="screen = 'screen-2'"
      v-if="screen === 'screen-1'"
    >
      <i class="fas fa-list"></i>
    </button>
    <button
      class="tabbed-middle-button mobile-nav"
      @click="screen = 'screen-1'"
      v-else
    >
      <i class="fas fa-file-pdf"></i>
    </button>
    <button
      class="tabbed-next-button"
      @click="saveUnsaved"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <loading v-if="finishing" />
      <i class="fas fa-chevron-right" v-if="!islast && !finishing"></i>
      <i class="fas fa-check" v-if="islast && !finishing"></i>
    </button>
  </div>
</template>
<script>
import { mapState } from "vuex";
import store from "@/store";
import axios from "@/requests/axios";
import { flattenMultiRow, flattenSingleRow } from "@/requests/requestParcer";
import accordeonItem from "./components/AccordeonItem";
import _ from "lodash";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      machine_id: this.$route.params.machine,
      formData: null,
      loading: false,
      lastLoad: -1,
      pdfsLoaded: [],
      finishing: false,
      active: 0,
      screen: "screen-1",
      answers: [],
    };
  },
  mounted() {
    this.loading = true;
    axios
      .post("balthzar_get_internal_activity_action_list_checklist_3_0_R", {
        record_link: this.data.activity.selected.record_no,
      })
      .then(({ data }) => {
        this.formData = flattenMultiRow(data);
        this.loadAnswers();
        this.setStart();
        this.loading = false;
        this.finishEmpty();
        this.loadPDFs();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      username: (state) => state.auth.username,
      machines: (state) => state.machines.index,
      renderMode: (state) => state.pdfRenderMode
    }),
    lockedChildren() {
      let locked = [];
      _.forEach(this.$refs.children, function (ref, key) {
        if (ref.locked) {
          locked.push(key);
        }
      });
      return locked;
    },
    disableNext() {
      return this.answers.findIndex((answer, index) => !answer.isdone) !== -1;
    },
    pdfs() {
      return this.formData.map((i) => i.document_link);
    },
    activePdf() {},
    lockedNavigation(){
      return this.answers[this.active]?.exception_note === "outside limits" && !this.formData[this.active].allow_outside_reasonable_limits;
    }
  },
  methods: {
    activate(k) {
      if (this.lockedNavigation){
        return true;
      }
      if (this.active === k) {
        this.active = -1;
      } else {
        this.active = k;
      }
    },
    saveUnsaved() {
      if (!this.finishing) {
        let promises = [];
        let statusses = [];
        this.finishing = true;
        _.each(this.$refs.children, (ref) => {
          console.log(ref);
          statusses.push(ref.status);
          if (ref.isChanged) {
            promises.push(ref.save());
          }
        });
        Promise.all(promises).then(() => {
          let checkForUnfilled = _.min(statusses) === "0";
          if (!checkForUnfilled && !this.lockedNavigation ) {
            this.data.activityForm.value = _.max(statusses);
            this.$emit("next");
          }
          new Promise((resolve) => setTimeout(resolve, 1500)).then(() => {
            this.finishing = false;
          });
        });
      }
    },
    loadPDFs() {
      console.log(this.pdfs);
      this.loadPDF(0);
    },
    loadAnswers() {
      for (let i = 0; i < this.formData.length; i++) {
        this.answers[i] = this.defaultAnswer(this.formData[i].input_type);
        this.loadAnswer(this.formData[i], i);
      }
    },
    setStart() {
      this.active = this.answers.findIndex((answer) => answer.isdone === false);
      if (this.active === -1) {
        this.active = this.answers.length - 1;
      }
    },
    loadAnswer(item, i) {
      if (!item.entered_value || item.entered_value == "") {
        return;
      }
      this.answers[i].isdone = true;
      this.answers[i].entered_value = item.entered_value;
      this.answers[i].exception_note = item.exception_note;
    },
    defaultAnswer(type) {
      return { entered_value: null, isdone: false, exception_note: null };
    },
    finishEmpty(){
      if (this.formData.length === 0){
        this.$emit("next");
      }
    },
    loadPDF(i) {
      console.log(i, "loading pdf?");
      if (i >= this.formData.length) {
        //done loading
        return true;
      } else {
        this.lastLoad = i;
        if (this.pdfs[i] === "-1") {
          //copy last one
          this.pdfsLoaded[i] = this.pdfsLoaded[i - 1];
          this.loadPDF(i + 1);
        } else {
          axios
            .post("balthzar_get_assembly_pdf", {
              record_no: this.pdfs[i],
            })
            .then(({ data }) => {
              this.pdfsLoaded[i] =
                "data:application/pdf;base64," + flattenSingleRow(data).data;
              this.loadPDF(i + 1);
            });
        }
      }
    },
  },
  components: {
    accordeonItem,
  },
  watch: {},
};
</script>