<template>
  <div class="col-12 col-xl-6 col-lg-8 col-md-12 widget">
    <line-information-base></line-information-base>
  </div>
</template>

<script>
import LineInformationBase from "./base/LineInformationBase.vue";
export default {
  components: {
    LineInformationBase,
  },
};
</script>

<style>
</style>