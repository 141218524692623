<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget" v-if="shouldRender">
    <div>
      <h1>
        {{ $t("2516") }}
        <card-menu :items="items"></card-menu>
      </h1>
      <router-link
        :to="signInLink"
        class="indicator status-large"
        :class="status"
      >
        <div
          class="vertically-centered-content height-limited"
          v-if="rfidError !== null"
        >
          <h3>RFID</h3>
          <span> {{ rfidError }} </span>
        </div>
        <div class="vertically-centered-content height-limited" :class="{'top-justified':signedInMax6.length > 3}" v-else>
          <p
            v-for="operator in signedInMax6"
            :key="operator.record_no"
            :class="{
              'text-xxl':
                operator.employee_number.length +
                  operator.employee_name.length <
                17,
              'text-xl':
                operator.employee_number.length +
                  operator.employee_name.length <
                30,
            }"
          >
            {{ operator.employee_number }}: {{ operator.employee_name }}
          </p>
          <span v-if="signedIn.length === 0">
            {{ $t("1531") }}
          </span>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { execute_json } from "@/helpers/requests";
import store from "@/store";
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      items: [],
      isScanning: false,
      scannedCode: "",
      reading: false,
      rfidError: null,
    };
  },
  mounted() {
    if (this.shouldRender) {
      store.dispatch("machines/loadSignedInForMachine", this.machine_id);
    }
    if (this.shouldBackGroundScan) {
      this.enableScan();
    }
    store.commit("events/addHandler", {
      event: "RFIDModule",
      handler: this.rfidEvent,
    });
  },
  beforeUnmount() {
    this.disableScan();
    store.commit("events/removeHandler", {
      event: "RFIDModule",
      handler: this.rfidEvent,
    });
  },
  computed: {
    ...mapState({}),
    machine_id() {
      return this.$route.params.machine;
    },
    ...mapGetters({
      //machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      signedInByMachine: "machines/signedInByMachine",
    }),
    shouldRender() {
      return this.machineHasSf(this.machine_id, "18");
    },
    warnWhenEmpty() {
      return this.machineHasSf(this.machine_id, "40");
    },
    shouldBackGroundScan() {
      return this.machineHasSf(this.machine_id, "361");
    },
    signedIn() {
      return this.signedInByMachine(this.machine_id);
    },
    signedInMax6() {
      return this.signedIn.slice(0,6);
    },
    status() {
      if (this.signedIn.length > 0 && this.rfidError == null) {
        return "status-running";
      }
      if (this.warnWhenEmpty || this.rfidError !== null) {
        return "status-stop blink";
      }
      return "c-gray";
    },
    signInLink() {
      return "/interface/" + this.machine_id + "/signed-in";
    },
  },
  methods: {
    enableScan() {
      this.disableScan();
      document.addEventListener("keypress", this.handleKeyPress);
      this.isScanning = true;
    },
    rfidEvent({ machine_id, event_message }) {
      if (machine_id == this.machine_id) {
        console.log(event_message);
        this.rfidError = event_message;
        setTimeout(
          (() => {
            this.rfidError = null;
          }).bind(this),
          5000
        );
      }
    },
    handleKeyPress(e) {
      //usually scanners throw an 'Enter' key at the end of read
      if (e.keyCode === 13) {
        if (this.scannedCode.length > 5) {
          this.signIn();

          e.preventDefault();
          /// this.scannedCode ready to use
        }
        this.scannedCode = "";
      } else {
        this.scannedCode += e.key; //while this is not an 'enter' it stores the every key
      }
      console.log(this.scannedCode);
      //run a timeout of 200ms at the first read and clear everything
      if (!this.reading) {
        this.reading = true;
        setTimeout(
          (() => {
            this.scannedCode = "";
            this.reading = false;
          }).bind(this),
          300
        );
      }
    },
    signIn() {
      execute_json("sign_in_at_machine", {
        machine_id: this.machine_id,
        pincode: this.scannedCode,
        no_errors: true,
      }).then(() => {
        store.dispatch("events/loadEvents");
      });
      console.log("sign in!");
    },
    disableScan() {
      document.removeEventListener("keypress", this.handleKeyPress);
      this.isScanning = false;
    },
  },
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
  },
};
</script>
 <style>
.blink.status-stop {
  animation: blink-animation 1.5s infinite;
}
@keyframes blink-animation {
  0% {
    background-color:red;
  }
  50%{
    background-color:orange;
  }
  100%{
    background-color:red;
  }
}
</style>