<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <planned-order-base></planned-order-base>
  </div>
</template>
<script>
import PlannedOrderBase from "./base/PlannedOrdersBase.vue"

export default {
  components: {
    PlannedOrderBase,
  },
};
</script>