<template>
  <live-data-base storageKey="livedata"></live-data-base>
</template>
<script>
import LiveDataBase from "@/components/widgets/base/LiveDataBase";

export default {
  components: {
    LiveDataBase,
  },
};
</script>