import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";

const state = () => ({
    list: [],
});

// getters
const getters = {
}

// actions
const actions = {
    loadAvailabilityList({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            execute_json("get_availability_level_description")
                .then(({ data }) => {
                    commit('setAvailabilityList', {
                        data
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })

        });
    }
}
// mutations
const mutations = {
    setAvailabilityList(state, { data }) {
        state.list = [{ value: 0, description: "-" }, ...data.map(i => {
            return {
                value: i.code,
                description: i.description ? i.description : ""
            }
        })];
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}