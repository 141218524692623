import { flattenMultiRow, flattenSingleRow } from "@/requests/requestParcer";
import { daybreakDate, getMonday, getNow } from "@/helpers/dates";
import axios from "@/requests/axios";
import store from "@/store";

// functions
const shift = {
    handler: "loadStopReasonsLocationShift",
    tlabel: "197",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: "stop_reason_color",
};

function loadStopReasonsLocationShift() {
    return new Promise((resolve, reject) => {
        let webservice = "balthzar_get_statistics_stop_reason_department_3_0_D";
        let webservice2 = "balthzar_get_statistics_department_3_0_J";
        if (this.locationtype === 'zone') {
            webservice = "balthzar_get_statistics_stop_reason_zone_3_0_D";
            webservice2 = "balthzar_get_statistics_zone_3_0_J";
        }
        if (this.locationtype === "category") {
            webservice = "balthzar_get_statistics_stop_reason_category_3_0_D"
            webservice2 = "balthzar_get_statistics_category_3_0_J";
        }
        let shim = {};
        shim[this.locationtype] = this.location;
        axios
            .post(webservice, {
                ...shim,
                choice: 3,
                intervall: -1
            })
            .then(({ data }) => {
                this.data = flattenMultiRow(data);
                if (this.addRuntime) {
                    axios.post(webservice2, {
                        ...shim,
                        choice: 3,
                        intervall: -1
                    })
                        .then(({ data }) => {
                            let runTime = flattenSingleRow(data) !== null ? flattenSingleRow(data).run_time : "0";
                            runTime = runTime || "0";
                            this.data.push({
                                stop_reason_color: "Green",
                                stop_description: this && this.$t ? this.$t('951') : "runtime",
                                stop_time: runTime,
                            });
                            resolve();
                        })
                } else {
                    console.log(this.data);
                    resolve();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const today = {
    handler: "loadStopReasonsLocationToday",
    tlabel: "447",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: "stop_reason_color",
};

function loadStopReasonsLocationToday() {
    return new Promise((resolve, reject) => {
        let webservice = "balthzar_get_statistics_stop_reason_department_3_0_D";
        let webservice2 = "balthzar_get_statistics_department_3_0_I";
        if (this.locationtype === 'zone') {
            webservice = "balthzar_get_statistics_stop_reason_zone_3_0_D";
            webservice2 = "balthzar_get_statistics_zone_3_0_I";
        }
        if (this.locationtype === "category") {
            webservice = "balthzar_get_statistics_stop_reason_category_3_0_D"
            webservice2 = "balthzar_get_statistics_category_3_0_I";
        }
        let shim = {};
        shim[this.locationtype] = this.location;
        axios
            .post(webservice, {
                ...shim,
                choice: 1,
                intervall: -1
            })
            .then(({ data }) => {
                this.data = flattenMultiRow(data);
                if (this.addRuntime) {
                    axios.post(webservice2, {
                        ...shim,
                        choice: 1,
                        intervall: -1
                    })
                        .then(({ data }) => {
                            let runTime = flattenSingleRow(data) !== null ? flattenSingleRow(data).run_time : "0";
                            this.data.push({
                                stop_reason_color: "Green",
                                stop_description: this && this.$t ? this.$t('951') : "runtime",
                                stop_time: runTime,
                            });
                            resolve();
                        })
                } else {
                    console.log(this.data);
                    resolve();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const h24 = {
    handler: "loadStopReasonsLocation24h",
    tlabel: "448",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: "stop_reason_color",
};

function loadStopReasonsLocation24h() {
    return new Promise((resolve, reject) => {
        let webservice = "balthzar_get_statistics_stop_reason_department_3_0_D";
        let webservice2 = "balthzar_get_statistics_department_3_0_I";
        if (this.locationtype === 'zone') {
            webservice = "balthzar_get_statistics_stop_reason_zone_3_0_D";
            webservice2 = "balthzar_get_statistics_zone_3_0_I";
        }
        if (this.locationtype === "category") {
            webservice = "balthzar_get_statistics_stop_reason_category_3_0_D"
            webservice2 = "balthzar_get_statistics_category_3_0_I";
        }
        let shim = {};
        shim[this.locationtype] = this.location;
        axios
            .post(webservice, {
                ...shim,
                choice: 2,
                intervall: -1
            })
            .then(({ data }) => {
                this.data = flattenMultiRow(data);
                if (this.addRuntime) {
                    axios.post(webservice2, {
                        ...shim,
                        choice: 2,
                        intervall: -1
                    })
                        .then(({ data }) => {
                            let runTime = flattenSingleRow(data) !== null ? flattenSingleRow(data).run_time : "0";
                            this.data.push({
                                stop_reason_color: "Green",
                                stop_description: this && this.$t ? this.$t('951') : "runtime",
                                stop_time: runTime,
                            });
                            resolve();
                        })
                } else {
                    console.log(this.data);
                    resolve();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const week = {
    handler: "loadStopReasonsLocationWeek",
    tlabel: "914",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: "stop_reason_color",
};

function loadStopReasonsLocationWeek() {
    return new Promise((resolve, reject) => {
        let start_date = getMonday(new Date()).toBalthzarTime()
        let stop_date = new Date().toBalthzarTime()
        let webservice = "balthzar_get_statistics_stop_reason_department_start_and_stop_date_3_0_C";
        let webservice2 = "balthzar_get_statistics_department_date_3_0_I";
        if (this.locationtype === 'zone') {
            webservice = "balthzar_get_statistics_stop_reason_zone_start_and_stop_date_3_0_C";
            webservice2 = "balthzar_get_statistics_zone_date_3_0_I";
        }
        if (this.locationtype === "category") {
            webservice = "balthzar_get_statistics_stop_reason_category_start_and_stop_date_3_0_C"
            webservice2 = "balthzar_get_statistics_category_date_3_0_I";
        }
        let shim = {};
        shim[this.locationtype] = this.location;
        axios
            .post(webservice, {
                ...shim,
                start_date,
                stop_date
            })
            .then(({ data }) => {
                this.data = flattenMultiRow(data);
                if (this.addRuntime) {
                    axios.post(webservice2, {
                        ...shim,
                        start_date,
                        stop_date
                    })
                        .then(({ data }) => {
                            let runTime = flattenSingleRow(data) !== null ? flattenSingleRow(data).run_time : "0";
                            this.data.push({
                                stop_reason_color: "Green",
                                stop_description: this && this.$t ? this.$t('951') : "runtime",
                                stop_time: runTime,
                            });
                            resolve();
                        })
                } else {
                    console.log(this.data);
                    resolve();
                }

            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const lastweek = {
    handler: "loadStopReasonsLocationLastWeek",
    tlabel: "2136",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: "stop_reason_color",
};

function loadStopReasonsLocationLastWeek() {
    return new Promise((resolve, reject) => {
        let start = getMonday(new Date());
        start.setDate(start.getDate() - 7);
        let start_date = start.toBalthzarTime();
        let stop_date = getMonday(new Date()).toBalthzarTime();
        let webservice = "balthzar_get_statistics_stop_reason_department_start_and_stop_date_3_0_C";
        let webservice2 = "balthzar_get_statistics_department_date_3_0_I";
        if (this.locationtype === 'zone') {
            webservice = "balthzar_get_statistics_stop_reason_zone_start_and_stop_date_3_0_C";
            webservice2 = "balthzar_get_statistics_zone_date_3_0_I";
        }
        if (this.locationtype === "category") {
            webservice = "balthzar_get_statistics_stop_reason_category_start_and_stop_date_3_0_C"
            webservice2 = "balthzar_get_statistics_category_date_3_0_I";
        }
        let shim = {};
        shim[this.locationtype] = this.location;
        axios
            .post(webservice, {
                ...shim,
                start_date,
                stop_date
            })
            .then(({ data }) => {
                this.data = flattenMultiRow(data);
                if (this.addRuntime) {
                    axios.post(webservice2, {
                        ...shim,
                        start_date,
                        stop_date
                    })
                        .then(({ data }) => {
                            let runTime = flattenSingleRow(data) !== null ? flattenSingleRow(data).run_time : "0";
                            this.data.push({
                                stop_reason_color: "Green",
                                stop_description: this && this.$t ? this.$t('951') : "runtime",
                                stop_time: runTime,
                            });
                            resolve();
                        })
                } else {
                    console.log(this.data);
                    resolve();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });

};

const d30 = {
    handler: "loadStopReasonsLocation30d",
    tlabel: "450",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: "stop_reason_color",
};

function loadStopReasonsLocation30d() {
    return new Promise((resolve, reject) => {
        let webservice = "balthzar_get_statistics_stop_reason_department_3_0_D";
        let webservice2 = "balthzar_get_statistics_department_3_0_R";
        if (this.locationtype === 'zone') {
            webservice = "balthzar_get_statistics_stop_reason_zone_3_0_D";
            webservice2 = "balthzar_get_statistics_zone_3_0_R";
        }
        if (this.locationtype === "category") {
            webservice = "balthzar_get_statistics_stop_reason_category_3_0_D"
            webservice2 = "balthzar_get_statistics_category_3_0_R";
        }
        let shim = {};
        shim[this.locationtype] = this.location;
        axios
            .post(webservice, {
                ...shim,
                choice: 2,
                intervall: -30
            })
            .then(({ data }) => {
                this.data = flattenMultiRow(data);
                if (this.addRuntime) {
                    axios.post(webservice2, {
                        ...shim,
                        choice: 2,
                        intervall: -30
                    })
                        .then(({ data }) => {
                            let runTime = flattenSingleRow(data) !== null ? flattenSingleRow(data).run_time : "0";
                            this.data.push({
                                stop_reason_color: "Green",
                                stop_description: this && this.$t ? this.$t('951') : "runtime",
                                stop_time: runTime,
                            });
                            resolve();
                        })
                } else {
                    console.log(this.data);
                    resolve();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const graphStopReasonsLocationDataSetup = {
    shift,
    today,
    "24h": h24,
    week,
    lastweek,
    "30d":d30
};

const graphStopReasonsLocationDataFunctions = {
    loadStopReasonsLocationShift,
    loadStopReasonsLocationToday,
    loadStopReasonsLocation24h,
    loadStopReasonsLocationWeek,
    loadStopReasonsLocationLastWeek,
    loadStopReasonsLocation30d
};


export { graphStopReasonsLocationDataFunctions, graphStopReasonsLocationDataSetup };