<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget left-aligned">
    <div v-if="machine">
      <h1>
        {{ $t("5860") }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div class="row">
        <c-table
          :data="runtimeForMachine"
          config-string="runtime_per_shift"
          primary-key="key"
          :default-sort-key="['day_date', 'shift']"
          :default-sort-dir="['desc', 'asc']"
          :teleport="true"
        ></c-table>
      </div>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import { secondsToHhMm } from "@/helpers/dates";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";
import { Configuration } from "@/helpers/settings";
import { Notification } from "@/helpers/notifications";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 180,
      swapping: false,
      config: new Configuration(
        "runtime-per-shift",
        {
          selection_criteria: "current_week",
          selection_value: 7,
        },
        this.template
      ),
    };
  },
  mounted() {
    store.dispatch("shifts/loadShiftList");
    this.pulse = new Pulse([
      {
        action: "machines/loadMachine",
        interval: 3,
        primaryKey: "machine_id",
        parameters: { machine_id: this.machine_id },
      },
    ]);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      runningOrders: (state) => state.orders.runningOrders,
      machinePath: (state) => state.auth.defaultpath,
      plannedOrders: (state) => state.orders.plannedOrders,
      lastLoc: (state) => state.sidebar.lastLocation,
      lastLocType: (state) => state.sidebar.lastLocationType,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      canUser: "permissions/canUser",
      runtimePerMachine: "shifts/runtimePerMachine",
    }),
    canSaveWidgetSettings() {
      return this.canUser("save-widget-settings");
    },
    runtimeForMachine() {
      return this.runtimePerMachine(this.machine_id);
    },

    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    configLoaded() {
      return this.config.isLoaded;
    },
    selectionCriteria() {
      return this.config.selection_criteria;
    },
    selectionValue() {
      return this.config.selection_value;
    },

    items() {
      let items = [];
      if (!this.canSaveWidgetSettings) {
        return items;
      }
      items.push(
        {
          label: "hours",
          handler: () => {
            this.config.setValues({ selection_criteria: "hours_back" });
          },
          selected: this.config.selection_criteria === "hours_back",
        },
        {
          label: "days",
          handler: () => {
            this.config.setValues({ selection_criteria: "days_back" });
          },
          selected: this.config.selection_criteria === "days_back",
        }
      );
      if (this.config.selection_criteria != "current_week") {
        items.push(
          {
            header:
              (this.selection_criteria == "days_back"
                ? "days back"
                : "hours back") +
              ":" +
              this.config.selection_value,
          },
          {
            input: "selection_value",
            type: "range",
            min: 1,
            max: 24,
          }
        );
      }
      items.push({
        label: "current_week",
        handler: () => {
          this.config.setValues({ selection_criteria: "current_week" });
        },
        selected: this.config.selection_criteria === "current_week",
      });
      return items;
    },
  },
  watch: {
    configLoaded(isLoaded) {
      if (isLoaded) {
        this.pulse.add({
          action: "shifts/loadRuntimeByShift",
          parameters: {
            selection_criteria: this.config.selection_criteria,
            selection_value: this.config.selection_value,
          },
          interval: this.interval,
          primaryKey: "selection_criteria",
        });
      }
    },
    selectionCriteria() {
      this.pulse.setNew({
        action: "shifts/loadRuntimeByShift",
        parameters: {
          selection_criteria: this.config.selection_criteria,
          selection_value: this.config.selection_value,
        },
        interval: this.interval,
        primaryKey: "selection_criteria",
      });
    },
    selectionValue() {
      this.pulse.setNew({
        action: "shifts/loadRuntimeByShift",
        parameters: {
          selection_criteria: this.config.selection_criteria,
          selection_value: this.config.selection_value,
        },
        interval: this.interval,
        primaryKey: "selection_criteria",
      });
    },
  },
  methods: {},
  components: {
    CardMenu,
  },
};
</script>