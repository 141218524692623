<template>
  <div class="popup" v-if="settings">
    <div v-if="settings && loaded">
      <div class="form-group align-left">
        <label for="">{{$t('5406')}} {{$t('6050')}} 1</label>
        <select
          name=""
          id=""
          class="form-control"
          v-model="settings.escalation_level1_message_group"
        >
          <option :value="g.value" v-for="g in groups" :key="g.value">
            {{ g.label }}
          </option>
        </select>
        <label for="">{{$t('5406')}} {{$t('6050')}} 2</label>
        <select
          name=""
          id=""
          class="form-control"
          v-model="settings.escalation_level2_message_group"
        >
          <option :value="g.value" v-for="g in groups" :key="g.value">
            {{ g.label }}
          </option>
        </select>
        <label for="">{{$t('5406')}} {{$t('6050')}} 3</label>
        <select
          name=""
          id=""
          class="form-control"
          v-model="settings.escalation_level3_message_group"
        >
          <option :value="g.value" v-for="g in groups" :key="g.value">
            {{ g.label }}
          </option>
        </select>
      </div>
    </div>

    <button class="btn">
      <i class="fas fa-times" @click="deActivate"></i>
    </button>
    <button class="btn">
      <i class="fas fa-check" @click="toggleMachineNotifications"></i>
    </button>
  </div>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapGetters, mapState } from "vuex";
import { flattenSingleRow } from "@/requests/requestParcer";
import materialLines from "@/components/MaterialLines";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";
import store from "@/store";

export default {
  props: ["data"],
  emits: ["close"],
  data() {
    return {
      settings: null,
      loaded: false,
    };
  },
  mounted() {
    this.settings = _.cloneDeep(this.notificationSettings);
    store.dispatch("messagegroups/loadMessageGroupList").then(() => {
      this.loaded = true;
    });
  },
  methods: {
    handleEnter() {},
    deActivate() {
      this.$emit("close");
    },
    toggleMachineNotifications() {
      execute_json("machine_notification", {
        machine_id: this.machine_id,
        feature: "set",
        escalation_active: !this.settings.escalation_active,
        escalation1_message_group:
          this.settings.escalation_level1_message_group,
        escalation2_message_group:
          this.settings.escalation_level2_message_group,
        escalation3_message_group:
          this.settings.escalation_level3_message_group,
      }).then(() => {
        this.reloadNotifications();
      });
    },
    reloadNotifications() {
      this.data.pulse.shock("notifications");
      this.deActivate();
    },
  },
  computed: {
    ...mapState({
      groups: (state) => state.messagegroups.list,
    }),
    ...mapGetters({
      notificationSettingsBymachine: "machines/notificationSettingsBymachine",
    }),
    machine_id() {
      return this.data.machine_id;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    notificationSettings() {
      return this.notificationSettingsBymachine(this.machine_id);
    },
  },
  watch: {
    notificationSettings() {
      this.settings = _.cloneDeep(this.notificationSettings);
    },
  },
  components: {
    materialLines,
  },
};
</script>