<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget left-aligned">
    <div v-if="machine">
      <h1>
        <span v-if="!config.setup">{{
          config.title ? config.title : $t("30")
        }}</span>
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div
        class="row"
        :style="{ 'margin-top': config.setup ? '-50px' : '0px' }"
      >
        <div class="col-6">
          <flex-button
            :setup="config.setup"
            k="field_1_function"
            :config="config"
          ></flex-button>
        </div>
        <div class="col-6">
          <flex-button
            :setup="config.setup"
            k="field_2_function"
            :config="config"
          ></flex-button>
        </div>
        <div class="col-6">
          <flex-button
            :setup="config.setup"
            k="field_3_function"
            :config="config"
          ></flex-button>
        </div>
        <div class="col-6">
          <flex-button
            :setup="config.setup"
            k="field_4_function"
            :config="config"
          ></flex-button>
        </div>
        <div class="col-6">
          <flex-button
            :setup="config.setup"
            k="field_5_function"
            :config="config"
          ></flex-button>
        </div>
        <div class="col-6">
          <flex-button
            :setup="config.setup"
            k="field_6_function"
            :config="config"
          ></flex-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import FlexButton from "@/components/flexbutton/FlexOrderButton";
import { Pulse } from "@/helpers/heartbeat";
import { secondsToHhMm } from "@/helpers/dates";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import { Configuration } from "@/helpers/settings";
import _ from "lodash";

export default {
  props: {
    template: {
      required: true,
    },
  },
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 180,
      config: new Configuration(
        "flex-order-6",
        {
          setup: false,
          title: "Flex Order",
          field_1_function: "-",
          field_2_function: "-",
          field_3_function: "-",
          field_4_function: "-",
          field_5_function: "-",
          field_6_function: "-",
        },
        this.template
      ),
    };
  },
  mounted() {
    this.pulse = new Pulse([
      {
        action: "machines/loadMachine",
        interval: 3,
        primaryKey: "machine_id",
        parameters: { machine_id: this.machine_id },
      },
      {
        action: "orders/loadRunningOrderForMachine",
        parameters: { machine_id: this.machine_id, reload: false },
        interval: this.interval,
        primaryKey: "machine_id",
      },
      {
        action: "orders/loadPlannedOrdersForMachine",
        parameters: {
          machine_id: this.machine_id,
          erp_resource: this.machine.erp_resource,
          erp_connection_type: this.machine.erp_connection_type,
        },
        interval: this.interval,
        primaryKey: "machine_id",
      },
    ]);
    if (!this.canSaveWidgetSettings) {
      this.config.setValues({ setup: false });
    }
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
      machinePath: (state) => state.auth.defaultpath,
      plannedOrders: (state) => state.orders.plannedOrders,
      lastLoc: (state) => state.sidebar.lastLocation,
      lastLocType: (state) => state.sidebar.lastLocationType,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      canUser: "permissions/canUser",
    }),
    canSaveWidgetSettings() {
      return this.canUser("save-widget-settings");
    },
    has85() {
      return this.machineHasSf(this.machine_id, "85");
    },
    uncoded_used() {
      return this.has85
        ? this.machine.uncoded_stops_24h
        : this.machine.uncoded_stops_shift24h;
    },
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    order() {
      return this.orders[this.machine_id] || [];
    },
    pOrders() {
      return this.plannedOrders[this.machine_id] || [];
    },
    items() {
      if (!this.canSaveWidgetSettings) {
        return [];
      }
      return [
        {
          input: "title",
          type: "text",
        },

        {
          label: "toggle setup",
          handler: () => {
            this.config.setValues({ setup: !this.config.setup });
          },
        },
      ];
    },
    machine_link() {
      if (this.machinePath) {
        return this.machinePath;
      }
      if (this.lastLoc && this.lastLocType) {
        return `/machines/${this.lastLocType}/${this.lastLoc}`;
      } else if (
        this.$route.params.locationtype &&
        this.$route.params.location
      ) {
        return `/machines/${this.$route.params.locationtype}/${this.$route.params.location}`;
      } else {
        return "/machines";
      }
    },
    status() {
      if (+this.uncoded_used > 1) {
        return "status-stop";
      } else if (+this.uncoded_used === 1) {
        return "status-uncoded";
      }
      return "status-running";
    },
  },
  components: {
    CardMenu,
    FlexButton,
  },
};
</script>