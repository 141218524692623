<template>
  <div>
    {{ $t("578") }}
    <numerical-input
      v-model="data[tab.dataKey].value"
      :class="{ 'not-ok': employeeNotOk }"
      v-if="isNumeric"
      @enter="handleEnter"
    ></numerical-input>
    <input
      type="text"
      class="form-control"
      v-else
      v-model="data[tab.dataKey].value"
      :class="{ 'not-ok': employeeNotOk }"
      autofocus
    />

    <keyboard v-model="data[tab.dataKey].value" v-if="!isNumeric" />
    <button
      class="tabbed-next-button"
      @click="okIfNext"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <loading v-if="loading" />
      <i class="fas fa-chevron-right" v-if="!islast && !loading"></i>
      <i class="fas fa-check" v-if="islast && !loading"></i>
    </button>
  </div>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapGetters } from "vuex";
import { flattenSingleRow } from "@/requests/requestParcer";
import { execute_json } from "@/helpers/requests";

export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      employeeNotOk: false,
      loading: false,
    };
  },
  mounted() {
    if (this.shouldAutoFill) {
      this.data[this.tab.dataKey].autofilled = true;
      this.data[this.tab.dataKey].value = this.signedIn[0].employee_number;
      this.$emit("next");
    }
  },
  methods: {
    okIfNext() {
      this.loading = true;
      execute_json("verify_employee", {
        machine_id: this.machine_id || "",
        employee: this.data[this.tab.dataKey].value,
      }).then(({ data }) => {
        if (data[0].is_valid_employee) {
          this.data[this.tab.dataKey].fullname = data[0].balthzar_full_name;
          this.employeeNotOk = false;
          this.$emit("next");
        } else {
          this.employeeNotOk = true;
          let n = Notification.failed("failed to find employee");
        }
        this.loading = false;
        //this.$emit("next");
      });
    },
    handleEnter() {
      this.okIfNext();
    },
  },
  computed: {
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
      signedInByMachine: "machines/signedInByMachine",
    }),
    disableNext() {
      return (
        this.data[this.tab.dataKey].value === null ||
        this.data[this.tab.dataKey].value === "0." ||
        this.loading
      );
    },
    watchValue() {
      return this.data[this.tab.dataKey].value;
    },
    shouldUsePincodes() {
      return this.machineHasSf(this.machine_id, "361");
    },
    machine_id() {
      if (this.data.activity && this.data.activity.selected) {
        return this.data.activity.selected.machine_id;
      }
      return this.$route.params.machine;
    },
    isNumeric() {
      return this.machineHasSf(this.machine_id, "209");
    },
    hasAutoSignInEnabled() {
      return this.machineHasSf(this.machine_id, "178");
    },
    signedIn() {
      return this.signedInByMachine(this.machine_id);
    },
    shouldAutoFill() {
      let hasSingleSignIn = this.signedIn.length === 1;
      let hasNeverAutoFilled =
        this.data[this.tab.dataKey].autofilled === undefined;
      let interfacing = this.$route.path.includes("/interface/");
      //return {hasSingleSignIn,hasNeverAutoFilled,autoEnabled:this.hasAutoSignInEnabled};
      return (
        interfacing &&
        hasSingleSignIn &&
        hasNeverAutoFilled &&
        this.hasAutoSignInEnabled
      );
    },
  },
  watch: {
    watchValue() {
      this.employeeNotOk = false;
    },
  },
};
</script>