import  _  from "lodash";

const state = () => ({
    plannedUpdates:[

    ],
    keyedUpdates:[

    ],
    lastRunPlanned:{

    },
    updateFlags:{

    },
    keyedFlags:{

    },
    lastRunKeyed:{

    }
});

// getters
const getters = {

}

// actions
const actions = {
    addUpdate({state,commit},{action,interval,parameters,primaryKey,source}){
        //console.log("adding update");
        if(primaryKey === null){
            if (!state.lastRunPlanned[action]){
                commit('setLastRunPlannedZero',action);
            }
            if (!state.updateFlags[action]){
                commit('setUpdateFlag',{action,flag:false});
                //console.log("new flag detected",state.updateFlags);
            }
            commit('addPlannedUpdate',{action,interval,source});
        }else{
            let primaryPar = parameters[primaryKey];
            if (!state.lastRunKeyed[action] || !state.lastRunKeyed[action][primaryPar]){
                commit('setLastRunKeyedZero',{action,primaryPar});
            }
            if (!state.keyedFlags[action] || !state.keyedFlags[action][primaryPar]){
                commit('setKeyedFlag',{action,primaryPar,flag:false});
                //console.log("new flag detected",state.keyedFlags);
            }
            commit('addKeyedUpdate',{action,interval,parameters,primaryKey,source});
        }
    },
    removeUpdate({commit},{action,interval,parameters,primaryKey,source}){
        //console.log("removing update");
        if(primaryKey === null){
            commit('removePlannedUpdate',{action,interval,source});
        }else{
            commit('removeKeyedUpdate',{action,interval,parameters,primaryKey,source});
        }
    },
    clearUpdatesByErpResource({state,rootGetters,commit},{machine_id,erp_resource}){
        //console.log("CLEARP UPDATES BY ERP RESOURCE:");
        //console.log(machine_id,erp_resource);
        let machines = rootGetters["machines/machinesByErpResource"](erp_resource);
        //console.log(machines);
        let actions = ["orders/loadRunningOrderForMachine","orders/loadPlannedOrdersForMachine","machines/loadMachine"];
        actions.forEach(action => {
            machines.forEach(machine => {
                commit("setLastRunKeyedZero",{action,primaryPar:machine.machine_id});
                commit("setKeyedFlag",{action,primaryPar:machine.machine_id,flag:false});
            })
        })
    }
}
// mutations
const mutations = {
    // 
    addPlannedUpdate(state,update){
        state.plannedUpdates.push(update);
        //console.log("adding a planned update",state.plannedUpdates);
    },
    removePlannedUpdate(state,{action,source}){
        //console.log("removing them updates",action,source);
        state.plannedUpdates = _.reject(state.plannedUpdates,(u)=>{return u.action == action && u.source == source});
        //console.log(state.plannedUpdates);
    },
    setLastRunPlanned(state,action){
        state.lastRunPlanned[action] = new Date().getTime();
    },
    setLastRunPlannedZero(state,action){
        state.lastRunPlanned[action] = 0;
    },
    setUpdateFlag(state,{action,flag}){
        state.updateFlags[action] = flag;
    },
    // keyed
    addKeyedUpdate(state,update){
        state.keyedUpdates.push(update);
        //console.log("adding a keyed update",state.keyedUpdates);
    },
    removeKeyedUpdate(state,{action,source,primaryKey,parameters}){
        //console.log("removing keyed updates",action,source,parameters,primaryKey);
        state.keyedUpdates = _.reject(state.keyedUpdates,(u)=>{return u.action == action && u.parameters[primaryKey] == parameters[primaryKey] && u.source == source});
        //console.log(state.keyedUpdates);
    },
    setLastRunKeyed(state,{action,primaryPar}){
        if (!state.lastRunKeyed[action]){
            state.lastRunKeyed[action] = {};
        }
        state.lastRunKeyed[action][primaryPar] = new Date().getTime();
    },
    setLastRunKeyedZero(state,{action,primaryPar}){
        if (!state.lastRunKeyed[action]){
            state.lastRunKeyed[action] = {};
        }
        state.lastRunKeyed[action][primaryPar] = 0;
    },
    setKeyedFlag(state,{action,primaryPar,flag}){
        if (!state.keyedFlags[action]){
            state.keyedFlags[action] = {};
        }
        state.keyedFlags[action][primaryPar] = flag;
    },
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}