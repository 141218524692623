<template>
  <div>
    <p class="present-value bold">
      {{ machine.work_order_no }}
    </p>
    <p class="present-value small">
      {{ machine.op_number }}
    </p>
  </div>
</template>

<script>
export default {
    props:['machine','order','planned']
};
</script>

<style>
</style>