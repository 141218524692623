<template>
  <div class="reporter">
      <div v-for="(c,i) in config" :key="i">
          <span v-if="c.val !== null || c.showIfNull === true">
          <b>{{$t(c.tlabel)}}</b>:{{c.val}}
          </span>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        config:{
            required:true
        }
    }
}
</script>

<style>

</style>