const defaultWidgets = [
    "production-status",
    "uncoded-stops",
    "current-order",
    "stopreason-overview-graph",
    "running-order",
    "planned-orders",
    "production-statistics",
    "machine-performance",
    "machine-oee",
    "zone-oee",
    "machine-scrap",
    "dashboard",
];

const allWidgets = [
    "production-status",
    "uncoded-stops",
    "current-order",
    "current-order-links",
    "stopreason-overview-graph",
    "stopreason-overview-graph-2",
    "stopreason-overview-graph-3",
    "stopreason-count-graph",
    "running-order",
    "planned-orders",
    "planned-orders-wide",
    "order-links",
    "production-statistics",
    "machine-performance",
    "machine-oee",
    "zone-oee",
    "machine-scrap",
    "dashboard",
    //"stop-order-listener",
    "signed-in",
    "manual-machine",
    "time-history-machine",
    "internal-activities",
    "availability-graph",
    "stopreasons-daily-graph",
    "weight-process",
    "quick-nav-links",
    "quick-scrap",
    "order-part-links",
    "create-internal-activity",
    "finished-orders",
    "finished-orders-wide",
    "production-goal",
    "production-result",
    "line-information",
    "line-information-wide",
    "rework-order",
    "live-data",
    "live-data-2",
    "live-data-3",
    "flex-widget",
    "flex-widget-4",
    "flex-widget-1",
    "flex-widget-1x2",
    "flex-widget-1x3",
    "flex-widget-2",
    "flex-order",
    "flex-order-4",
    "flex-order-1",
    "flex-order-12",
    "materials",
    "materials-wide",
    "scrap-graph",
    "order-swap",
    "alarm-order",
    "escalate-order",
    "ean-code-verification",
    "traceability",
    "parts-counter-scale",
    "order-summary",
    "group-status",
    "active-part",
    "runtime-per-shift",
    "sql-viewer",
]

export { defaultWidgets, allWidgets }