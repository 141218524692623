<template>
  <stopreason-overview-graph-base storageKey="stopreasons2"></stopreason-overview-graph-base>
</template>
<script>
import StopreasonOverviewGraphBase from "@/components/widgets/base/StopreasonOverviewGraphBase";

export default {
  components: {
    StopreasonOverviewGraphBase,
  },
};
</script>