<template>
  <div class="b-slide">
    <label class="switch">
      <input type="checkbox" :checked="modelValue" @input="sync" />
      <span class="slider round"></span>
    </label>
    <p>
      {{label}}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      required: true,
    },
    label: {},
    tlabel: {},
    disabled: {
      default: false,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    sync() {
      this.$emit("update:modelValue", !this.modelValue);
    },
  },
};
</script>