<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div v-if="machine" class="max-align">
      <h1>
        <span v-if="config.title != ''">{{ config.title }}</span>
        <span v-else>
          {{ $t("5297") }} -
          {{
            reverseT[config.period] != undefined
              ? typeof reverseT[config.period] === "string"
                ? $t(reverseT[config.period])
                : reverseT[config.period].map((i) => $t(i)).join(" + ")
              : config.period
          }}</span
        >
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div style="flex: 1">
        <div
          class="row"
          v-if="config.period === 'card+'"
          style="min-height: 30%; height: auto"
        >
          <div class="col-12 row">
            <div class="col-6">
              <p class="label-value big">
                {{ config.goodHeader != "" ? config.goodHeader : $t("581") }}
              </p>
              <p class="present-value bold big">
                <flex-value :value="machine.card_good_qty" />
              </p>
            </div>
            <div class="col-6">
              <p class="label-value big">
                {{ config.scrapHeader != "" ? config.scrapHeader : $t("554") }}
              </p>
              <p class="present-value bold big">
                <flex-value :value="machine.card_scrap_qty" digits="4" />
              </p>
            </div>
          </div>

          <div class="col-12 row">
            <div class="col-6" v-if="config.altGoodHeader1 != '-'">
              <p class="label-value big">
                {{
                  config.altGoodHeader1 != ""
                    ? config.altGoodHeader1
                    : $t("581")
                }}
              </p>
              <p class="present-value bold big">
                <flex-value :value="machine.card_good_qty / (machine.cavities || 1)" digits="0"/>
              </p>
            </div>
            <div class="col-6" v-if="config.altScrapHeader1 != '-'">
              <p class="label-value big">
                {{
                  config.altScrapHeader1 != ""
                    ? config.altScrapHeader1
                    : $t("554")
                }}
              </p>
              <p class="present-value bold big">
                
                <flex-value :value="machine.card_scrap_qty / (machine.cavities || 1)" digits="0"/>
              </p>
            </div>
          </div>
          <div class="col-12 row">
            <div class="col-6" v-if="config.altGoodHeader2 != '-'">
              <p class="label-value big">
                {{
                  config.altGoodHeader2 != ""
                    ? config.altGoodHeader2
                    : $t("581")
                }}
              </p>
              <p class="present-value bold big">
                <flex-value :value="machine.card_good_qty_altunit2" />
              </p>
            </div>
            <div class="col-6" v-if="config.altScrapHeader2 != '-'">
              <p class="label-value big">
                {{
                  config.altScrapHeader2 != ""
                    ? config.altScrapHeader2
                    : $t("554")
                }}
              </p>
              <p class="present-value bold big">
                <flex-value
                  :value="machine.card_scrap_qty_altunit2"
                  digits="4"
                />
              </p>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-if="config.period === 'shift+'"
          style="min-height: 30%; height: auto"
        >
        <div class="col-12 row">
          <div class="col-6">
            <p class="label-value big">
              {{ config.goodHeader != "" ? config.goodHeader : $t("581") }}
            </p>
            <p class="present-value bold big">
              <flex-value :value="machine.good_qty_shift" />
            </p>
          </div>
          <div class="col-6">
            <p class="label-value big">
              {{ config.scrapHeader != "" ? config.scrapHeader : $t("554") }}
            </p>
            <p class="present-value bold big">
              <flex-value :value="machine.scrap_qty_shift" digits="4" />
            </p>
          </div>
          </div>
          
        <div class="col-12 row">
          <div class="col-6" v-if="config.altGoodHeader1 != '-'">
            <p class="label-value big">
              {{
                config.altGoodHeader1 != "" ? config.altGoodHeader1 : $t("581")
              }}
            </p>
            <p class="present-value bold big">
              
                <flex-value :value="machine.good_qty_shift / (machine.cavities || 1)" digits="0"/>
            </p>
          </div>
          <div class="col-6" v-if="config.altScrapHeader1 != '-'">
            <p class="label-value big">
              {{
                config.altScrapHeader1 != ""
                  ? config.altScrapHeader1
                  : $t("554")
              }}
            </p>
            <p class="present-value bold big">
              
                <flex-value :value="machine.scrap_qty_shift / (machine.cavities || 1)" digits="0"/>
            
            </p>
          </div>
          </div>
          
        <div class="col-12 row">
          <div class="col-6" v-if="config.altGoodHeader2 != '-'">
            <p class="label-value big">
              {{
                config.altGoodHeader2 != "" ? config.altGoodHeader2 : $t("581")
              }}
            </p>
            <p class="present-value bold big">
              <flex-value :value="machine.good_qty_shift_altunit2" />
            </p>
          </div>
          <div class="col-6" v-if="config.altScrapHeader2 != '-'">
            <p class="label-value big">
              {{
                config.altScrapHeader2 != ""
                  ? config.altScrapHeader2
                  : $t("554")
              }}
            </p>
            <p class="present-value bold big">
              <flex-value
                :value="machine.scrap_qty_shift_altunit2"
                digits="4"
              />
            </p>
          </div>
          </div>
        </div>
        <div
          class="row"
          v-if="config.period === 'shift'"
          style="min-height: 30%; height: auto"
        >
          <div class="col-4">
            <p class="label-value big">{{ $t("755") }}</p>
            <p class="present-value bold big">
              <flex-value :value="machine.t_percent_shift"></flex-value>
            </p>
          </div>
          <div class="col-4">
            <p class="label-value big">{{ $t("1898") }}</p>
            <p class="present-value bold big">
              <flex-value :value="machine.a_percent_shift"></flex-value>
            </p>
          </div>
          <div class="col-4">
            <p class="label-value big">{{ $t("1639") }}</p>
            <p class="present-value bold big">
              <flex-value :value="machine.k_percent_shift"></flex-value>
            </p>
          </div>
        </div>

        <div
          class="row"
          v-if="config.period === 'today'"
          style="min-height: 30%; height: auto"
        >
          <div class="col-4">
            <p class="label-value big">{{ $t("755") }}</p>
            <p class="present-value bold big">
              <flex-value :value="machine.t_percent_calendar"></flex-value>
            </p>
          </div>
          <div class="col-4">
            <p class="label-value big">{{ $t("1898") }}</p>
            <p class="present-value bold big">
              <flex-value :value="machine.a_percent_calendar"></flex-value>
            </p>
          </div>
          <div class="col-4">
            <p class="label-value big">{{ $t("1639") }}</p>
            <p class="present-value bold big">
              <flex-value :value="machine.k_percent_calendar"></flex-value>
            </p>
          </div>
          <br />
        </div>
        <div
          class="row"
          style="min-height: 30%; height: auto"
          v-if="config.period === 'today' || config.period === 'today+shift'"
        >
          <h2 v-if="config.period === 'today+shift'" class="align-left">
            {{ $t("447") }}
          </h2>
          <div class="col-6">
            <p class="label-value big">
              {{ config.scrapHeader != "" ? config.scrapHeader : $t("581") }}
            </p>
            <p class="present-value bold big">
              <flex-value :value="goodQtyToday" />
            </p>
          </div>
          <div class="col-6">
            <p class="label-value big">
              {{ config.scrapHeader != "" ? config.scrapHeader : $t("554") }}
            </p>
            <p class="present-value bold big">
              <flex-value :value="scrapQtyToday" digits="4" />
            </p>
          </div>
        </div>
        <div
          class="row"
          style="min-height: 30%; height: auto"
          v-if="
            config.period !== 'today' &&
            !['card+', 'shift+'].includes(config.period)
          "
        >
          <h2 v-if="config.period === 'today+shift'" class="align-left">
            {{ $t("245") }}
          </h2>
          <div class="col-6">
            <p class="label-value big">
              {{ config.goodHeader != "" ? config.goodHeader : $t("581") }}
            </p>
            <p class="present-value bold big">
              <flex-value :value="goodQty" />
            </p>
          </div>
          <div class="col-6">
            <p class="label-value big">
              {{ config.scrapHeader != "" ? config.scrapHeader : $t("554") }}
            </p>
            <p class="present-value bold big">
              <flex-value :value="scrapQty" digits="4" />
            </p>
          </div>
        </div>
        <div
          class="row"
          v-if="config.period == 'today' || config.period == 'shift'"
          style="min-height: 30%; height: auto"
        >
          <div class="col-6">
            <p class="label-value big">{{ $t("951") }}</p>
            <p class="present-value bold big">
              {{ runTime }}
            </p>
          </div>
          <div class="col-6">
            <p class="label-value big">{{ $t("463") }}</p>
            <p class="present-value bold big">
              {{ stopTime }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* description:  */
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import { setStorage, getStorage } from "@/helpers/storage";
import { mapState, mapGetters } from "vuex";
import { Configuration } from "@/helpers/settings";
import { secondsToHhMm } from "@/helpers/dates";
import store from "@/store";
import _ from "lodash";

export default {
  mounted() {
    this.pulse = new Pulse([
      {
        action: "machines/loadMachine",
        interval: 3,
        primaryKey: "machine_id",
        parameters: { machine_id: this.machine_id },
      },
    ]);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 175,
      reverseT: {
        shift: "245",
        today: "447",
        card: "24",
        "today+shift": ["447", "245"],
      },
      config: new Configuration(
        "production-result",
        {
          period: "shift",
          title: "",
          alternativeUnits: false,
          goodHeader: "",
          scrapHeader: "",
          altGoodHeader1: "",
          altScrapHeader1: "",
          altGoodHeader2: "",
          altScrapHeader2: "",
        },
        this.template
      ),
    };
  },
  methods: {},
  computed: {
    ...mapState({}),
    ...mapGetters({
      machineById: "machines/byId",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    goodQty() {
      if (
        this.config.period === "shift" ||
        this.config.period === "today+shift"
      ) {
        if (this.config.alternativeUnits) {
          return this.machine.good_qty_shift_altunit1;
        }
        return this.machine.good_qty_shift;
      }
      if (this.config.period === "card") {
        if (this.config.alternativeUnits) {
          return this.machine.card_good_qty_altunit;
        }
        return this.machine.card_good_qty;
      }
    },
    goodQtyToday() {
      if (this.config.alternativeUnits) {
        return this.machine.good_qty_today_altunit1;
      }
      return this.machine.good_qty_today;
    },
    scrapQty() {
      if (
        this.config.period === "shift" ||
        this.config.period === "today+shift"
      ) {
        if (this.config.alternativeUnits) {
          return this.machine.scrap_qty_shift_altunit1;
        }
        return this.machine.scrap_qty_shift;
      }
      if (this.config.period === "card") {
        if (this.config.alternativeUnits) {
          return this.machine.card_scrap_qty_altunit;
        }
        return this.machine.card_scrap_qty;
      }
    },
    runTime() {
      if (this.config.period === "shift") {
        return secondsToHhMm(this.machine.current_shift_runtime);
      }
      if (this.config.period === "today") {
        return secondsToHhMm(this.machine.today_runtime);
      }
    },
    stopTime() {
      if (this.config.period === "shift") {
        return secondsToHhMm(this.machine.current_shift_calendar_stop_time);
      }
      if (this.config.period === "today") {
        return secondsToHhMm(this.machine.today_calendar_stoptime);
      }
    },
    scrapQtyToday() {
      if (this.config.alternativeUnits) {
        return this.machine.scrap_qty_today_altunit1;
      }
      return this.machine.scrap_qty_today;
    },
    items() {
      return [
        { header: this.$t("1329") },
        {
          tlabel: "24",
          handler: () => {
            this.config.setValues({ period: "card" });
          },
          selected: this.config.period === "card",
        },
        {
          label: this.$t("24") + "+",
          handler: () => {
            this.config.setValues({ period: "card+" });
          },
          selected: this.config.period === "card+",
        },
        {
          tlabel: "447",
          handler: () => {
            this.config.setValues({ period: "today" });
          },
          selected: this.config.period === "today",
        },
        {
          tlabel: "245",
          handler: () => {
            this.config.setValues({ period: "shift" });
          },
          selected: this.config.period === "shift",
        },
        {
          label: this.$t("245") + "+",
          handler: () => {
            this.config.setValues({ period: "shift+" });
          },
          selected: this.config.period === "shift+",
        },
        {
          label: this.$t("447") + " + " + this.$t("245"),
          handler: () => {
            this.config.setValues({ period: "today+shift" });
          },
          selected: this.config.period === "today+shift",
        },
        { divider: true },
        { header: this.$t("497") },
        {
          tlabel: "2734",
          handler: () => {
            this.config.setValues({
              alternativeUnits: !this.config.alternativeUnits,
            });
          },
          selected: this.config.alternativeUnits,
        },
        {
          input: "title",
          type: "text",
        },
        {
          input: "goodHeader",
          type: "text",
        },
        {
          input: "scrapHeader",
          type: "text",
        },
        {
          input: "altGoodHeader1",
          type: "text",
        },
        {
          input: "altScrapHeader1",
          type: "text",
        },
        {
          input: "altGoodHeader2",
          type: "text",
        },
        {
          input: "altScrapHeader2",
          type: "text",
        },
      ];
    },
  },
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
  },
};
</script>

<style>
</style>