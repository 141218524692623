<template>
  <div class="numerical-input">
    <datepicker v-model="data[tab.dataKey].date" class="form-control" />
    <input
      type="number"
      name="hours"
      placeholder="hh"
      v-model="data[tab.dataKey].hours"
      min="0"
      max="23"
      @focus="focus = 'hours'"
      pattern="\d*"
    />
    <input
      @focus="focus = 'minutes'"
      type="number"
      name="minutes"
      placeholder="mm"
      pattern="\d*"
      v-model="data[tab.dataKey].minutes"
      min="0"
      max="59"
    />
    <keyboard
      v-show="focus === 'hours'"
      v-model="data[tab.dataKey].hours"
      :numeric="true"
      :keyboard-class="keyboardClassH"
    />
    <keyboard
      v-show="focus === 'minutes'"
      v-model="data[tab.dataKey].minutes"
      :numeric="true"
      :keyboard-class="keyboardClassM"
    />
    <button
      class="tabbed-next-button"
      @click="setValue"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>
<script>
import { mapState } from "vuex";
import store from "@/store";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import Datepicker from "vue3-datepicker";
import _ from "lodash";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      machine_id: this.$route.params.machine,
      newDate: null,
      focus: null,

      keyboardClassH: _.uniqueId("simple-keyboard-"),
      keyboardClassM: _.uniqueId("simple-keyboard-"),
      keyboardClassS: _.uniqueId("simple-keyboard-"),
    };
  },
  mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
    }),
    disableNext() {
      return (
        this.data[this.tab.dataKey].date == null ||
        this.data[this.tab.dataKey].minutes == null ||
        this.data[this.tab.dataKey].hours == null
      );
    },
    minutes() {
      return this.data[this.tab.dataKey].minutes;
    },
    hours() {
      return this.data[this.tab.dataKey].hours;
    },
  },
  methods: {
    limitTo(val, max) {
      val = "" + val;
      if (val.length > 1) {
        val = val.replace(/^0+/, "");
        if (+val > max) {
          val = max;
        }
      }
      if (val.length === 1) {
        val = ("" + val).padStart(2, "0");
      }
      console.log(val);
      return val;
    },
    setValue(){
        let date = new Date(this.data[this.tab.dataKey].date);
        date.setHours(this.hours);
        date.setMinutes(this.minutes);
        this.data[this.tab.dataKey].value = date.toBalthzarTime();
        this.$emit('next');
    }
  },
  watch: {
    minutes(val) {
      if (this.limitTo(val, 59) != val) {
        this.data[this.tab.dataKey].minutes = "" + this.limitTo(val, 59);
      }
      //this.update();
    },
    hours(val) {
      if (this.limitTo(val, 23) != val) {
        this.data[this.tab.dataKey].hours = "" + this.limitTo(val, 23);
      }
    },
  },
  components: {
    Datepicker,
  },
};
</script>