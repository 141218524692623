import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import { setStorage, getStorage, checkStorage, removeStorage } from "../../helpers/storage";
import { defaultWidgets, allWidgets } from "@/helpers/widgets";
import { defaultTableConfigs } from "@/helpers/tableConfigs";
import { premadeColumns } from "@/helpers/premadeColumns";

const state = () => ({
    sorting: {
        machines: "machine_id",
        machines_a_active: "a_percent_shift"
    },
    myMachines: [],
    templates: {
        widgets: {
            default: [],
        },
        dashboards: {

        },
        liveviews: {

        }
    },
    widgets: {
        default: defaultWidgets,
        all: allWidgets,
    },
    dashboards: {
    },
    liveviews: {

    },
    loaded: {
        children: {
            columns: false
        },
        columns: {

        },
        webcolumns: {
        },
        widgets: {
            default: false
        },
        templates: {
            widgets: false,
            dashboards: false,
            liveviews: false,
        },
        dashboards: {

        },
        liveviews: {

        }
    },
    periods: {
        machines: "shift",
        time_history_zone: "24h"
    },
graphs: {
        type: "pie",
        labels: "hours"
    },
    activeBTable: null,
    items: {
        colorBox: {
            component: "color-box",
            config: {
                limitType: ["hard", "clamp"],
                greenLimit: "input", //key name or numerical value
                yellowLimit: "input", //key name or numerical value
                clampAround: "input", //key name or numerical value
                grayValue: "input", //key name or numerical value
                class: ["wide"],
                transformTo: ["fixed", "hh:mm"]
            }
        }
    },
    webcolumns: {
        available: premadeColumns,
    },
    columns: defaultTableConfigs,
    ownOverviews: []

});

// getters
const getters = {
    widgetTemplateNameFromMachineId: (state) => (machine_id) => {
        let templateKeys = Object.keys(state.templates.widgets);
        for (let i = 0; i < templateKeys.length; i++) {
            let key = templateKeys[i];
            let values = state.templates.widgets[key];
            if (values.includes(machine_id)) {
                return key;
            }
        }
        return "default";
    },
    columnConfigByTemplate: (state, rootState) => (template) => {
        return state.webcolumns[template] || state.columns[template] || {};
    },
    availableColumnsBySource: (state, rootState) => (source) => {
        return state.webcolumns.available[source] || [];
    }
}

// actions
const actions = {
    loadMachineSorting({ state, commit, rootState }) {
        let company_id = rootState.auth.company_id;
        let sorting = getStorage(company_id + "_machines_sorting", "machine_id") || state.sorting.machines;
        let period = getStorage(company_id + "_machines_period", "machine_id") || state.periods.machines;
        commit("setMachineSorting", { sorting, period, company_id });
    },
    loadGraphSettings({ commit, rootState }) {
        let company_id = rootState.auth.company_id;
        let type = "pie";
        if (checkStorage(company_id + "_default_graph_type")) {
            type = getStorage(company_id + "_default_graph_type");
        }
        commit("setSetting", { base: "graphs", key: "type", value: type, storageKey: company_id + "_default_graph_type" });
        let labels = "hours";
        if (checkStorage(company_id + "_default_graph_labels")) {
            labels = getStorage(company_id + "_default_graph_labels");
        }
        commit("setSetting", { base: "graphs", key: "labels", value: labels, storageKey: company_id + "_default_graph_labels" });
        return Promise.resolve();
    },
    putSetting({ commit, rootState }, setting) {
        let company_id = rootState.auth.company_id;
        setting.storageKey = company_id + setting.storageKey;
        commit("setSetting", setting);
    },
    changeMachinesPeriod({ commit, rootState }, { period }) {
        let company_id = rootState.auth.company_id;
        commit("setMachineSorting", { sorting: getStorage(company_id + "_machines_sorting"), period, company_id });
    },
    changeMachinesSorting({ commit, rootState }, { sorting }) {
        let company_id = rootState.auth.company_id;
        commit("setMachineSorting", { period: getStorage(company_id + "_machines_period"), sorting, company_id });
    },
    loadPeriod({ commit, rootState }, { slug }) {
        let company_id = rootState.auth.company_id;
        if (!checkStorage(company_id + "_" + slug + "_period")) {
            setStorage(company_id + "_" + slug + "_period", "h24");
        }
        commit("setPeriod", { period: getStorage(company_id + "_" + slug + "_period"), slug });
    },
    changePeriod({ commit, rootState }, { slug, value }) {
        let company_id = rootState.auth.company_id;
        setStorage(company_id + "_" + slug + "_period", value);
        commit("setPeriod", { period: value, slug });
    },
    loadMyMachines({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            axios.post("balthzar_get_my_machines_3_0_R",
                {
                    who: rootState.auth.username,
                    strTime_span: "TODAY"
                }
            )
                .then(({ data }) => {
                    //console.log(data,data[0]);
                    commit('setMyMachines', flattenMultiRow(data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },
    removeMachine({ commit, rootState }, machine_id) {
        return new Promise((resolve, reject) => {
            execute_json("my_machines", {
                username: rootState.auth.username,
                machine_id,
                feature: "delete"
            })
                .then(({ data }) => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },
    addMachine({ commit, rootState }, machine_id) {
        return new Promise((resolve, reject) => {
            execute_json("my_machines", {
                username: rootState.auth.username,
                machine_id,
                feature: "insert"
            })
                .then(({ data }) => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },
    //functions for saving and loading widget configs
    saveConfig({ state, rootState }, { system_function, template }) {
        return new Promise((resolve, reject) => {
            let company_id = rootState.auth.company_id;
            execute_json("save_user_setting", {
                user_name: rootState.auth.username,
                system_function: system_function,
                function_variable: template,
                user_setting: JSON.stringify(state[system_function][template])
            })
                .then(({ data }) => {
                    setStorage(company_id + "_" + system_function + "_config_" + rootState.auth.username + "_" + template, state[system_function][template]);
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },
    saveNamedConfig({ state, rootState }, { system_function, template, name, value, defaultUser }) {
        return new Promise((resolve, reject) => {
            let company_id = rootState.auth.company_id;
            execute_json("save_user_setting", {
                user_name: defaultUser ? 'Default' : rootState.auth.username,
                system_function: system_function,
                function_variable: template,
                variable_value: name,
                user_setting: JSON.stringify(value)
            })
                .then(({ data }) => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },
    //functions for saving and loading widget configs
    saveDefaultConfig({ state, rootState }, { system_function, template }) {
        return new Promise((resolve, reject) => {
            let company_id = rootState.auth.company_id;
            execute_json("save_user_setting", {
                user_name: "Default",
                system_function: system_function,
                function_variable: template,
                user_setting: JSON.stringify(state[system_function][template])
            })
                .then(({ data }) => {
                    setStorage(company_id + "_" + system_function + "_config_" + rootState.auth.username + "_" + template, state[system_function][template]);
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },
    deleteDefaultConfig({ state, commit, dispatch, rootState }, { system_function, template }) {
        return new Promise((resolve, reject) => {
            let company_id = rootState.auth.company_id;
            execute_json("delete_user_setting", {
                user_name: "Default",
                system_function: system_function,
                function_variable: template,
            })
                .then(({ data }) => {
                    removeStorage(company_id + "_" + system_function + "_config_" + rootState.auth.username + "_" + template);
                    if (system_function === "widgets") {
                        commit("removeFromBaseTemplate", { system_function, template });
                        dispatch("saveBaseConfig", { base_code: "widgets" })
                    }
                    if (system_function === "dashboards") {
                        commit("removeFromBaseTemplate", { system_function, template });
                        dispatch("saveBaseConfig", { base_code: "dashboards" })
                    }
                    if (system_function === "liveviews") {
                        commit("removeFromBaseTemplate", { system_function, template });
                        dispatch("saveBaseConfig", { base_code: "liveviews" })
                    }
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },
    /* called to get the base config initialized, falling back to hardcoded state.templates[base_code] */
    loadBaseConfig({ state, commit, rootState, dispatch }, { base_code }) {
        return new Promise((resolve, reject) => {
            if (state.loaded.templates[base_code] === true) {
                resolve();
            } else {
                let company_id = rootState.auth.company_id;
                let storageString = company_id + "_" + base_code + "_template_overview";
                if (checkStorage(storageString)) {
                    let config = getStorage(storageString);
                    commit("setBaseConfig", { config, base_code });
                    dispatch("loadChildConfigs", { base_code });
                    resolve();
                } else {
                    execute_json("get_user_settings", {
                        username: rootState.auth.username,
                        system_function: "templates",
                        function_variable: base_code,
                    })
                        .then(({ data }) => {
                            //console.warn("base config",data);
                            if (data && data[0] && data[0].string_value) {
                                let config = JSON.parse(data[0].string_value);
                                setStorage(storageString, config);
                                commit("setBaseConfig", { config, base_code });
                            } else {
                                commit("setBaseConfig", { base_code, config: state.templates[base_code] });
                            }
                            dispatch("loadChildConfigs", { base_code }).then(() => {
                                resolve();
                            })
                        })
                        .catch((err) => {
                            reject(err);
                        })
                }
            }
        })
    },
    loadNamedConfigs({ state, rootState }, { system_function, template, name, value }) {
        return new Promise((resolve, reject) => {
            let company_id = rootState.auth.company_id;
            execute_json("get_user_settings", {
                username: rootState.auth.username,
                system_function: system_function,
                function_variable: template,
            })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },
    loadChildConfigs({ state, commit, rootState, dispatch }, { base_code }) {
        return new Promise((resolve, reject) => {
            execute_json("get_user_settings", {
                username: rootState.auth.username,
                system_function: base_code
            })
                .then(({ data }) => {
                    //console.warn("child configs coming in",data);
                    commit("setChildConfigLoaded", base_code);
                    commit("setChildConfigs", { data: data, base_code });
                    resolve();
                })
                .catch((err) => {
                    console.log(err);

                    reject(err);
                });
        });
    },
    loadConfig({ state, commit, rootState }, { system_function, template }) {
        return new Promise((resolve, reject) => {
            if (state.loaded[system_function][template]) {
                resolve();
            } else {
                let company_id = rootState.auth.company_id;
                let storageKey = company_id + "_" + system_function + "_config_" + rootState.auth.username + "_" + template;
                if (checkStorage(storageKey)) {
                    let config = getStorage(storageKey);
                    commit("setConfig", { system_function, template, config });
                } else {
                    execute_json("get_user_settings", {
                        username: rootState.auth.username,
                        system_function: system_function,
                        function_variable: template,
                    })
                        .then(({ data }) => {
                            if (data === null) {
                                //empty config
                                console.warn("empty config!");
                                resolve();
                            }
                            else if (data[0] && data[0].string_value) {
                                let config = JSON.parse(data[0].string_value);
                                setStorage(storageKey, config);
                                commit("setConfig", { system_function, template, config });
                            } else {
                                commit("setConfig", { system_function, template, config: state[system_function][template] });
                            }
                            resolve();
                        })
                        .catch((err) => {
                            console.warn(err);
                            reject(err);
                        })
                }
                resolve();
            }
        })
    },
    ForceConfigFromEvent({ state, commit, rootState }, { function_variable, system_function, user_name }) {
        return new Promise((resolve, reject) => {
            if (user_name === "Default" || rootState.auth.username === user_name) {
                //console.warn("forcing config reload from event");
                let template = function_variable;
                let company_id = rootState.auth.company_id;
                let storageString = system_function == "templates" ?
                    company_id + "_" + function_variable + "_template_overview" :
                    company_id + "_" + system_function + "_config_" + rootState.auth.username + "_" + template;
                execute_json("get_user_settings", {
                    username: rootState.auth.username,
                    system_function,
                    function_variable,
                }).then(({ data }) => {
                    //console.log("user settings!", data, data[0].string_value);
                    let config = [];
                    if (data && data[0] && data[0].string_value) {
                        config = JSON.parse(data[0].string_value);
                    }
                    setStorage(storageString, config);
                    commit("setConfig", { system_function, template, config });
                    resolve();
                }).catch((err) => {
                    console.log("tried to force config...", err);
                    reject(err);
                })
            } else {
                resolve()
            }
        });
    },
    addToBaseConfig({ state, commit, rootState, dispatch }, { base_code, name }) {
        return new Promise((resolve, reject) => {
            commit("newChildConfig", { base_code, name });
            let company_id = rootState.auth.company_id;
            setStorage(company_id + "_" + base_code + "_template_overview", state.templates[base_code]);
            commit("setBaseConfig", { config: state.templates[base_code], base_code });
            execute_json("save_user_setting", {
                user_name: "Default",
                system_function: "templates",
                function_variable: base_code,
                user_setting: JSON.stringify(state.templates[base_code])
            })
                .then(({ data }) => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    saveBaseConfig({ state, commit, rootState, dispatch }, { base_code }) {
        return new Promise((resolve, reject) => {
            let company_id = rootState.auth.company_id;
            setStorage(company_id + "_" + base_code + "_template_overview", state.templates[base_code]);
            commit("setBaseConfig", { config: state.templates[base_code], base_code });
            execute_json("save_user_setting", {
                user_name: "Default",
                system_function: "templates",
                function_variable: base_code,
                user_setting: JSON.stringify(state.templates[base_code])
            })
                .then(({ data }) => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    deleteMachineFromWidgetTemplates({ state, commit, rootState, dispatch }, { machine_id }) {
        return new Promise((resolve, reject) => {
            commit("removeMachineFromWidgetTemplates", { machine_id });
            let company_id = rootState.auth.company_id;
            setStorage(company_id + "_" + "widgets" + "_template_overview", state.templates["widgets"]);
            commit("setBaseConfig", { config: state.templates["widgets"], base_code: "widgets" });
            execute_json("save_user_setting", {
                user_name: "Default",
                system_function: "templates",
                function_variable: "widgets",
                user_setting: JSON.stringify(state.templates["widgets"])
            })
                .then(({ data }) => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    assignMachineToWidgetTemplate({ state, commit, rootState, dispatch }, { template, machine_id }) {
        return new Promise((resolve, reject) => {
            commit("removeMachineFromWidgetTemplates", { machine_id });
            commit("addMachineToWidgetTemplate", { machine_id, template });
            let company_id = rootState.auth.company_id;
            setStorage(company_id + "_" + "widgets" + "_template_overview", state.templates["widgets"]);
            commit("setBaseConfig", { config: state.templates["widgets"], base_code: "widgets" });
            execute_json("save_user_setting", {
                user_name: "Default",
                system_function: "templates",
                function_variable: "widgets",
                user_setting: JSON.stringify(state.templates["widgets"])
            })
                .then(({ data }) => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    loadOwnOverviews({ state, commit, rootState }) {
        return new Promise((resolve, reject) => {
            axios.post("balthzar_get_sql_overview", {

                username: rootState.auth.username,
            }).then(({ data }) => {
                commit("setOwnOverviews", flattenMultiRow(data));
                resolve();
            }).catch((err) => {
                reject(err)
            })
        })
    },
    newOwnOverview({ state, commit, rootState, dispatch }, { name }) {
        return new Promise((resolve, reject) => {
            const isProduction = process.env.NODE_ENV === 'production' && !window.Cypress;
            let baseURL = isProduction ? "/adminws/WebServiceAdmin.asmx" : "https://dev.bzx21.com/adminws/WebServiceAdmin.asmx";

            axios({
                url: "balthzar_save_sql_overview_20_0_27", data: {
                    username: rootState.auth.username,
                    name,
                    sql_query_id: -1,
                    update_frequency: 0
                }, method: "POST", baseURL
            }).then(({ data }) => {
                dispatch("loadOwnOverviews");
                resolve();
            }).catch((err) => {
                reject(err)
            })
        })
    },
    updateOwnOverview({ rootState, dispatch }, { record_no, sql_query_id, update_frequency, overview_name }) {
        return new Promise((resolve, reject) => {
            const isProduction = process.env.NODE_ENV === 'production' && !window.Cypress;
            let baseURL = isProduction ? "/adminws/WebServiceAdmin.asmx" : "https://dev.bzx21.com/adminws/WebServiceAdmin.asmx";

            axios({
                url: "balthzar_update_sql_overview_20_0_27", data: {
                    record_no,
                    username: rootState.auth.username,
                    name: overview_name,
                    sql_query_id,
                    update_frequency
                }, method: "POST", baseURL
            }).then(({ data }) => {
                dispatch("loadOwnOverviews");
                resolve();
            }).catch((err) => {
                reject(err)
            })
        })
    }
}

// mutations
const mutations = {
    setChildConfigLoaded(state, base_code) {
        state.loaded.children[base_code] = true;
    },
    setSetting(state, { base, key, value, storageKey }) {
        state[base][key] = value;
        setStorage(storageKey, value);
    },
    setOwnOverviews(state, data) {
        state.ownOverviews = data;
    },
    setMachineSorting(state, { sorting, period, company_id }) {
        if (sorting !== null) {
            state.sorting.machines = sorting;
            setStorage(company_id + "_machines_sorting", sorting);
        }
        if (period !== null) {
            state.periods.machines = period;
            setStorage(company_id + "_machines_period", period);
        }
    },
    setPeriod(state, { slug, period }) {
        state.periods[slug] = period;
    },
    activateBTable(state, btable) {
        state.activeBTable = btable;
    },
    setMyMachines(state, data) {
        state.myMachines = data.map(m => m.machine_id);
    },
    removeFromWidgetConfig(state, { widgetName, template }) {
        if (state.widgets[template] === undefined) {
            state.widgets[template] = [];
        }
        state.widgets[template] = state.widgets[template].filter(w => w !== widgetName);
    },
    addToWidgetConfig(state, { widgetName, template }) {
        if (state.widgets[template] === undefined) {
            state.widgets[template] = [];
        }
        state.widgets[template].push(widgetName);
    },
    setWidgetConfig(state, { config, template }) {
        state.widgets[template] = config;
        console.log(state.widgets[template], config);
    },
    moveUpInWidgetConfig(state, { widgetName, template }) {
        if (state.widgets[template] === undefined) {
            state.widgets[template] = [];
        }
        let currentIndex = state.widgets[template].indexOf(widgetName);
        if (currentIndex > 0) {
            let newIndex = currentIndex - 1;
            state.widgets[template].splice(currentIndex, 1);
            state.widgets[template].splice(newIndex, 0, widgetName);
        }
    },
    moveDownInWidgetConfig(state, { widgetName, template }) {
        if (state.widgets[template] === undefined) {
            state.widgets[template] = [];
        }
        let currentIndex = state.widgets[template].indexOf(widgetName);
        if (currentIndex < state.widgets[template].length - 1) {
            let newIndex = currentIndex + 1;
            state.widgets[template].splice(currentIndex, 1);
            state.widgets[template].splice(newIndex, 0, widgetName);
        }
    },
    //loading/saving functions
    setConfig(state, { system_function, template, config }) {
        if (system_function.includes("dashboard_widget_")) {
            console.warn("intercepted eroneous update");
            return true;
        }
        if (state[system_function] === undefined) {
            state[system_function] = {};
            state.loaded[system_function] = {};
        }
        //console.log(system_function,template,config,'<-');
        state[system_function][template] = config;
        state.loaded[system_function][template] = true;
    },
    setBaseConfig(state, { config, base_code }) {
        state.loaded.templates[base_code] = true;
        state.templates[base_code] = config;
    },
    setChildConfigs(state, { data, base_code }) {
        data.forEach(config => {
            let { function_variable, system_function, string_value } = config;
            if (state[system_function] === undefined) {
                state[system_function] = {};
                state.loaded[system_function] = {};
            }
            //console.log(system_function,function_variable,string_value,JSON.parse(string_value));
            state[system_function][function_variable] = JSON.parse(string_value);
            state.loaded[system_function][function_variable] = true;
        })
        //console.warn(data);
    },
    newChildConfig(state, { base_code, name }) {
        state.templates[base_code][name] = [];
    },
    removeMachineFromWidgetTemplates(state, { machine_id }) {
        let templates = state.templates.widgets;
        let templateKeys = Object.keys(templates);
        for (let i = 0; i < templateKeys.length; i++) {
            let templateKey = templateKeys[i];
            state.templates.widgets[templateKey] = state.templates.widgets[templateKey].filter(m => m !== machine_id);
        }
    },
    addMachineToWidgetTemplate(state, { machine_id, template }) {
        state.templates.widgets[template].push(machine_id);
    },
    removeFromBaseTemplate(state, { system_function, template }) {
        delete state.templates[system_function][template];
    },
    saveTableConfig(state, { config, template }) {
        console.warn({ config, template });
        state.webcolumns[template] = config;
        console.warn(state.webcolumns[template]);
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}