<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div v-if="machine && machine.work_order_no !== '-'">
      <h1>
        {{ $t("1") }}, {{ $t("5068") }} -
        {{
          rPeriod[config.period] ? $t(rPeriod[config.period]) : config.period
        }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div class="widget-summation">
        <div class="row">
          <div class="col-12">
            <div class="wrap" :class="t">
              <label for="">{{ $t("1898") }}</label>
              <span>{{ a_fixed }}</span>
            </div>
          </div>
          <div class="col-6">
            <div class="wrap status-gray">
              <label for="">{{ $t("967") }}</label>
              <span>{{ machine.planned_speed || "-" }}</span>
            </div>
          </div>
          <div class="col-6">
            <div class="wrap" :class="redClass">
              <label for="">{{ $t("6291") }}</label>
              <span>{{
                machine.gain_loss_order_rem_qty_hours != "0"
                  ? convertedHoursGainLoss
                  : "-"
              }}</span>
            </div>
          </div>
          <div class="col-6">
            <div class="wrap">
              <label for="">{{ $t("5251") }}</label>
              <span>{{ config.period !== 'lastknown' ? machine.real_speed : machine.last_known_cycle_time.toFixed(1)}}</span>
            </div>
          </div>
          <div class="col-6">
            <div class="wrap" :class="redClassYearly">
              <label for="">{{ $t("6292") }}</label>
              <span>{{
                machine.gain_loss_yearly_qty_hours != "0"
                  ? convertedYearsGainLoss
                  : "-"
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h1>
        {{ $t("1") }}, {{ $t("5068") }} -
        {{
          rPeriod[config.period] ? $t(rPeriod[config.period]) : config.period
        }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div class="center-widget">
        <p>This machine does not have an active workorder assigned to it.</p>
      </div>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import { hoursToHhMm } from "@/helpers/dates";
import { mapState, mapGetters } from "vuex";
import { Configuration } from "@/helpers/settings";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      rPeriod: {
        today: "447",
        "24h": "448",
        shift: "245",
        lastknown:"6072",
      },
      config: new Configuration(
        "machine-performance",
        {
          period: "shift",
        },
        this.template
      ),
    };
  },
  mounted() {
    this.pulse = new Pulse({
      action: "machines/loadMachine",
      interval: 3,
      primaryKey: "machine_id",
      parameters: { machine_id: this.machine_id },
    });
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  methods: {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({ machineById: "machines/byId" }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    a_used() {
      switch (this.config.period) {
        case "shift":
          return this.machine.a_percent_shift;
        case "24h":
          return this.machine.a_percent_24;
        case "today":
          return this.machine.a_percent_today;
        case "lastknown":
          return (this.machine.last_known_cycle_time_percent * 100 || 0).toFixed(1);
      }
    },
    a_fixed() {
      if (this.a_used > 100) {
        return Math.floor(this.a_used);
      }
      return this.a_used;
    },
    t() {
      if (+this.a_used === 0) {
        return "status-gray";
      } else if (
        +this.a_used >= 100 - this.machine.speed_deviation_green_limit &&
        (this.machine.speed_over_100 ||
          +this.a_used <= 100 + this.machine.speed_deviation_green_limit)
      ) {
        return "status-green";
      } else if (
        +this.a_used >= 100 - this.machine.speed_deviation_yellow_limit &&
        (this.machine.speed_over_100 ||
          +this.a_used <= 100 + this.machine.speed_deviation_yellow_limit)
      ) {
        return "status-yellow";
      } else return "status-red";
    },
    redClass() {
      if (+this.machine.gain_loss_order_rem_qty_hours > 0) {
        return { "status-green": true };
      } else if (+this.machine.gain_loss_order_rem_qty_hours === 0) {
        return { "status-gray": true };
      }
      return { "status-red": true };
    },
    redClassYearly() {
      if (+this.machine.gain_loss_yearly_qty_hours > 0) {
        return { "status-green": true };
      } else if (+this.machine.gain_loss_yearly_qty_hours === 0) {
        return { "status-gray": true };
      }
      return { "status-red": true };
    },
    items() {
      return [
        { header: this.$t("1329") },
        {
          tlabel: "448",
          handler: () => {
            this.config.setValues({ period: "24h" });
          },
          selected: this.config.period === "24h",
        },
        {
          tlabel: "447",
          handler: () => {
            this.config.setValues({ period: "today" });
          },
          selected: this.config.period === "today",
        },
        {
          tlabel: "245",
          handler: () => {
            this.config.setValues({ period: "shift" });
          },
          selected: this.config.period === "shift",
        },
        {
          tlabel: "6072",
          handler: () => {
            this.config.setValues({ period: "lastknown" });
          },
          selected: this.config.period === "lastknown",
        },
      ];
    },
    convertedHoursGainLoss() {
      return hoursToHhMm(this.machine.gain_loss_order_rem_qty_hours);
    },
    convertedYearsGainLoss() {
      return hoursToHhMm(this.machine.gain_loss_yearly_qty_hours);
    },
  },
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
  },
};
</script>