<template>
  <div class="popup-wrap" @click.self="close">
    <div class="closer" @click.prevent="close">
      <i class="fas fa-times"></i>
    </div>
    <div id="orderdetails" class="popup large">
      <loading v-if="!loaded" />
      <div class="panel-wrap" v-else>
        <div class="panel-row">
          <div class="panel">
            <h2 class="panel-header">{{ $t("30") }}</h2>
            <table class="panel-table table">
              <tr>
                <th>{{ $t("30") }}</th>
                <th>{{ $t("603") }}</th>
                <th>{{ $t("502") }}</th>
              </tr>
              <tr>
                <td>{{ details.work_order_no }}</td>
                <td>{{ details.op_number }}</td>
                <td>{{ details.card_no }}</td>
              </tr>
              <tr>
                <th>{{ $t("1") }}</th>
                <th>{{ $t("388") }}</th>
                <th></th>
              </tr>
              <tr>
                <td>
                  {{ details.machine_id }}: {{ details.object_description }}
                </td>
                <td>{{ details.cavity_reduction }}</td>
                <td></td>
              </tr>
              <tr>
                <th>{{ $t("393") }}</th>
                <th>{{ $t("106") }}</th>
                <th></th>
              </tr>
              <tr>
                <td>{{ details.part_no }}</td>
                <td>{{ details.insatser }}</td>
                <td></td>
              </tr>
              <tr>
                <th>{{ $t("394") }}</th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td>{{ details.part_description }}</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th>{{ $t("50") }}</th>
                <th>{{ $t("458") }}</th>
                <th></th>
              </tr>
              <tr>
                <td>{{ details.start_date }}</td>
                <td>{{ details.stop_date }}</td>
                <td></td>
              </tr>
              <tr>
                <th>{{ $t("593") }}</th>
                <th>{{ $t("397") }}</th>
                <th>{{ $t("83") }}</th>
              </tr>
              <tr>
                <td>
                  {{ details.is_running != "0" ? details.order_finished : "-" }}
                </td>
                <td>
                  {{
                    details.is_running != "0"
                      ? details.remaining_order_time > 0
                        ? (+details.remaining_order_time || 0).toFixed(2)
                        : 0
                      : "-"
                  }}
                </td>
                <td>{{ details.planned_qty - details.good_prod_qty }}</td>
              </tr>
            </table>
          </div>
          <div class="panel">
            <h2 class="panel-header">{{ $t("704") }}</h2>
            <div class="table-responsive">
              <table class="panel-table table">
                <tr>
                  <th>{{ $t("777") }}</th>
                  <th>{{ $t("1539") }}</th>
                </tr>
                <tr>
                  <td>{{ details.tool_id }}</td>
                  <td>{{ details.mother_tool_id }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="panel">
            <h2 class="panel-header">{{ $t("599") }}</h2>
            <div class="table-responsive">
              <table class="panel-table table">
                <tr>
                  <th>{{ $t("1698") }}</th>
                  <th>{{ $t("1699") }}</th>
                  <th>{{ $t("1700") }}</th>
                  <th>{{ $t("599") }}</th>
                </tr>
                <tr>
                  <td>{{ (+details.t_percent || 0).toFixed(2) }}</td>
                  <td>{{ (+details.a_percent || 0).toFixed(2) }}</td>
                  <td>{{ (+details.k_percent || 0).toFixed(2) }}</td>
                  <td>{{ (+details.tak || 0).toFixed(2) }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="panel-row">
          <div class="panel">
            <h2 class="panel-header">{{ $t("2525") }}</h2>
            <div class="table-responsive">
              <table class="panel-table table">
                <tr>
                  <th>{{ $t("1227") }}</th>
                  <th>{{ $t("581") }}</th>
                  <th>{{ $t("1697") }}</th>
                </tr>
                <tr>
                  <td>{{ details.planned_qty }}</td>
                  <td>{{ details.good_prod_qty }}</td>
                  <td>{{ details.erp_prod_qty }}</td>
                </tr>
                <tr>
                  <th>{{ $t("6590") }}</th>
                  <th>{{ $t("1743") }}</th>
                  <th>{{ $t("6541") }}</th>
                </tr>
                <tr>
                  <td>{{ details.cycles }}</td>
                  <td>{{ details.machine_prod_qty }}</td>
                  <td>{{ details.reported_to_pallet_flag }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="panel">
            <h2 class="panel-header">{{ $t("27") }}</h2>
            <div class="table-responsive">
              <table class="panel-table table">
                <tr>
                  <th>{{ $t("6027") }}</th>
                  <th>{{ $t("966") }}&nbsp;(s)</th>
                  <th>{{ $t("207") }}&nbsp;(s)</th>
                </tr>
                <tr>
                  <td>
                    {{ secondsToHhMm(details.planned_total_time_seconds) }}
                  </td>
                  <td>{{ details.planned_unit_time }}</td>
                  <td>{{ (+details.planned_cycle_time || 0).toFixed(1) }}</td>
                </tr>
                <tr>
                  <th>{{ $t("6589") }}</th>
                  <th>{{ $t("6019") }}&nbsp;(s)</th>
                  <th>{{ $t("6020") }}&nbsp;(s)</th>
                </tr>
                <tr>
                  <td>{{ secondsToHhMm(details.run_time) }}</td>
                  <td>{{ (+details.real_unit_time || 0).toFixed(2) }}</td>
                  <td>{{ (+details.real_cycle_time || 0).toFixed(2) }}</td>
                </tr>
                <tr>
                  <th>{{ $t("1701") }}&nbsp;(h)</th>
                  <td>{{ secondsToHhMm(details.machine_run_time) }}</td>
                </tr>
                <tr>
                  <th>{{ $t("5908") }}&nbsp;(h)</th>
                  <td>{{ secondsToHhMm(details.run_time_man) }}</td>
                </tr>
                <tr>
                  <th>{{ $t("5913") }}&nbsp;(h)</th>
                  <td>{{ secondsToHhMm(details.planned_run_time_man) }}</td>
                </tr>
                <tr>
                  <th>{{ $t("6008") }}&nbsp;(%)</th>
                  <td>
                    {{
                      details.run_time_man != "0"
                        ? (
                            (+details.planned_run_time_man /
                              details.run_time_man) *
                              100 || 0
                          ).toFixed(2)
                        : 0
                    }}%
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("5962") }}&nbsp;(%)</th>
                  <td>
                    {{
                      (
                        (+details.run_time_man / details.machine_run_time) *
                          100 || 0
                      ).toFixed(2)
                    }}%
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("1716") }}&nbsp;(h)</th>
                  <td>
                    {{ secondsToHhMm(details.stop_time_during_prod_time) }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("1860") }}&nbsp;(h)</th>
                  <td>{{ secondsToHhMm(details.stop_time) }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="panel">
            <h2 class="panel-header">{{ $t("65") }}</h2>
            <div class="table-responsive">
              <table class="panel-table table">
                <tr>
                  <th>{{ $t("554") }}</th>
                  <th>%</th>
                  <th>{{ $t("1635") }}</th>
                </tr>
                <tr>
                  <td>{{ details.scrap_qty || 0 }}</td>
                  <td>{{ (+details.scrap_percent || 0).toFixed(2) }}</td>
                  <td>{{ details.erp_scrap_qty || 0 }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="panel">
            <h2 class="panel-header">{{ $t("1384") }}</h2>
            <div class="table-responsive">
              <table class="panel-table table">
                <tr>
                  <th>{{ $t("5958") }}</th>
                  <th>{{ $t("5959") }}</th>
                </tr>
                <tr>
                  <td>{{ details.planned_setup_time }}</td>
                  <td>{{ secondsToHhMm(details.setup_time) }}</td>
                </tr>
                <tr>
                  <th>{{ $t("5961") }}</th>
                  <th>{{ $t("5960") }}</th>
                </tr>
                <tr>
                  <td>
                    {{ (+details.planned_setup_time_man || 0).toFixed(2) }}
                  </td>
                  <td>{{ secondsToHhMm(details.setup_time_man) }}</td>
                </tr>
                <tr>
                  <th>{{ $t("1534") }}</th>
                  <th>{{ $t("1535") }}</th>
                </tr>
                <tr>
                  <td>{{ details.emp_start_wo }}</td>
                  <td>{{ details.first_piece_time }}</td>
                </tr>
                <tr>
                  <th>{{ $t("1536") }}</th>
                  <th>{{ $t("1542") }}</th>
                </tr>
                <tr>
                  <td>{{ details.emp_end_wo }}</td>
                  <td>{{ details.emp_first_piece_wo }}</td>
                </tr>
                <tr>
                  <th>{{ $t("1442") }}</th>
                </tr>
                <tr>
                  <td>{{ details.setup_type }}</td>
                </tr>
                <tr>
                  <th>{{ $t("2748") }}</th>
                </tr>
                <tr>
                  <td>{{ details.setup_note }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { flattenSingleRow } from "@/requests/requestParcer";
import { secondsToHhMm } from "@/helpers/dates";
import { mapState } from "vuex";
export default {
  props: {
    card_no: {},
    machine_id: {},
    p_order: {},
    finished: {
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      details: null,
      bottomNavOpenOnMount: false,
    };
  },
  created() {
    this.loadData();
    this.bottomNavOpenOnMount = this.bottomNavOpen;
    store.commit("bottomnav/hide");
  },
  beforeUnmount() {
    if (this.bottomNavOpenOnMount) {
      store.commit("bottomnav/enable");
    }
  },
  methods: {
    secondsToHhMm(s) {
      if (isNaN(s)) {
        s = 0;
      }
      return (s / 3600).toFixed(2);
      return secondsToHhMm(s);
    },
    close() {
      this.$emit("close");
    },
    loadData() {
      this.loaded = false;
      store
        .dispatch("orders/loadOrderDetailsForMachine", {
          machine_id: this.machine_id,
          p_order: this.p_order,
          card_no: this.card_no,
          finished: this.finished,
        })
        .then(({ data }) => {
          console.log("all done fetching details");
          this.details = flattenSingleRow(data);
          this.loaded = true;
        })
        .catch((err) => {});
    },
  },
  computed: {
    ...mapState({
      bottomNavOpen: (state) => state.bottomnav.shouldShow,
    }),
  },
};
</script>

<style>
</style>