<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget min-height align-left">
    <div class="max-align">
      <card-menu :items="items" :config="config"></card-menu>
      <div class="xl-sizing align-left left" :class="config.size">
        <h1>
          <i
            class="fas"
            :class="config.mode === 'scanner' ? 'fa-scanner' : 'fa-dryer'"
          ></i>
          {{
            config.titleOverride ||
            config.selectedValue ||
            "Parts counter scale"
          }}
        </h1>
        <h4>{{ $t("604") }}: {{ part }}</h4>

        <counter-scale
          :part="part"
          :link="selectedLink"
          :interval="config.interval"
          :mode="config.mode"
          v-if="selectedLink !== null"
        ></counter-scale>
        <button
          class="btn pull-right"
          v-if="config.mode === 'scanner'"
          @click="manualInput"
        >
          <h4><i class="fas fa-keyboard"></i></h4>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { InterfaceComponent } from "@/helpers/machine";
import { mapGetters, mapState } from "vuex";
import CounterScale from "@/components/CounterScale.vue";
import store from "@/store";

export default InterfaceComponent({
  data() {
    return {
      loadedProcessValues: false,
      configString: "parts-counter-scale",
      scannedCode: "",
      scannedPart: null,
      scannerTimeout: null,
      config: {
        interval: 5,
        selectedValue: null,
        mode: "machine",
        titleOverride: null,
        size: "l",
        regexFilter: '',
      },
      loadedData: null,
    };
  },
  mounted() {
    store
      .dispatch("livedata/loadAvailableValuesPerMachine", this.machine_id)
      .then(() => {
        this.loadedProcessValues = true;
      });
    this.enableScan();
  },
  methods: {
    enableScan() {
      this.disableScan();
      document.addEventListener("keypress", this.handleKeyPress);
      this.isScanning = true;
    },
    disableScan() {
      document.removeEventListener("keypress", this.handleKeyPress);
      this.isScanning = false;
    },
    handleKeyPress(e) {
      //usually scanners throw an 'Enter' key at the end of read
      if (this.config.mode === "scanner") {
        if (e.keyCode === 13) {
          if (this.scannedCode.length > 5) {
            this.handleCode(this.scannedCode);
            e.preventDefault();
            /// this.scannedCode ready to use
          }
          this.scannedCode = "";
        } else {
          this.scannedCode += e.key; //while this is not an 'enter' it stores the every key
        }
        //console.log(this.scannedCode);
        //run a timeout of 200ms at the first read and clear everything
        if (!this.reading) {
          this.reading = true;
          setTimeout(
            (() => {
              this.scannedCode = "";
              this.reading = false;
            }).bind(this),
            300
          );
        }
      }
    },
    handleCode(code) {
      clearTimeout(this.scannerTimeout);
      if (this.config.regexFilter != ''){
        let reg = new RegExp(this.config.regexFilter,'g');
        console.log(reg,code,code.replace(reg,''));
        code = code.replace(reg,'');
      }
      this.scannedPart = code;
      this.scannerTimeout = setTimeout(
        (() => {
          this.scannedPart = null;
        }).bind(this),
        5 * 60 * 1000
      );
    },
    manualInput() {
      store.commit("popups/addPopup", {
        type: "manual-input",
        canClose: true,
        data: {
          theader: "3012",
          header: "Input code:",
          handler: this.handleCode,
        },
      });
    },
    upsize() {
      let size = this.config.size.length > 4 ? "l" : "x" + this.config.size;
      this.config.setValues({ size });
    },
  },
  computed: {
    ...mapState({
      valuesPerMachine: (state) => state.livedata.valuesPerMachine,
    }),
    ...mapGetters({
      linkByName: "livedata/linkByName",
    }),
    availableValues() {
      return this.valuesPerMachine[this.machine_id] || [];
    },
    availableItems() {
      return this.availableValues.map((value) => {
        return {
          label: value.parameter_name,
          handler: () => {
            this.config.setValues({ selectedValue: value.parameter_name });
          },
          selected: this.config.selectedValue == value.parameter_name,
        };
      });
    },
    selectedLink() {
      return this.config.isLoaded && this.loadedProcessValues
        ? this.linkByName(this.config.selectedValue, this.machine_id)
        : null;
    },
    part() {
      return this.config.mode === "machine"
        ? this.machine.part_no
        : this.scannedPart;
    },
    items() {
      return [
        {
          input: "titleOverride",
          type: "text",
        },
        {
          header: "polling interval: " + this.config.interval + "s",
        },
        {
          input: "interval",
          type: "range",
          min: 1,
          max: 60,
        },
        {
          label: "toggle mode: " + this.config.mode,
          handler: () => {
            let newMode = this.config.mode == "machine" ? "scanner" : "machine";
            this.config.setValues({ mode: newMode });
          },
        },
        {
          label: "text size: " + this.config.size,
          handler: this.upsize,
        },
        {
          header: !this.loadedProcessValues
            ? "loading process values..."
            : "process values",
        },

        ...this.availableItems,
        {
          input: "regexFilter",
          type: "text",
        },
      ];
    },
  },
  components: {
    CounterScale,
  },
});
</script>

<style>
.align-left {
  text-align: left;
}
</style>