<template>
  <div class="sort-swap">
    <button
      class="btn"
      type="button"
      id="sortSwap"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="fa fa-ellipsis-v"></i>
    </button>
    <ul class="dropdown-menu" aria-labelledby="sortSwap" id="sub-top-dropdown">
      <li v-if="periods">
        <h6 class="dropdown-header">
          {{$t('1329')}}
        </h6>
      </li>
      <li v-if="periods">
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="setSortPeriod('24h')"
          :class="{ disabled: period == '24h' }"
          >{{ $t("448") }}</a
        >
      </li>
      <li v-if="periods">
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="setSortPeriod('shift')"
          :class="{ disabled: period == 'shift' }"
          >{{ $t("245") }}</a
        >
      </li>
      <li v-if="periods">
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="setSortPeriod('today')"
          :class="{ disabled: period == 'today' }"
          >{{ $t("447") }}</a
        >
      </li>
      <li v-if="periods">
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="setSortPeriod('calendar')"
          :class="{ disabled: period == 'calendar' }"
          >{{ $t("18") }}</a
        >
      </li>
      <div class="dropdown-divider" v-if="periods"></div>
      <slot></slot>
      <li v-if="userPermission">
        <h6 class="dropdown-header">{{$t('89')}}</h6>
      </li>
      <li v-if="userPermission">
        <router-link
          :to="'/admin/config/tables/' + this.target"
          class="dropdown-item"
          >{{$t('6731')}}</router-link
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";
export default {
  props: {
    target: String,
    periods: {
      default: true,
    },
    showAdmin:{
      default: true
    }
  },
  computed: {
    ...mapState({
      sorting: (state) => state.config.sorting.machines,
      period: (state) => state.config.periods.machines,
    }),
    ...mapGetters({ canUser: "permissions/canUser" }),
    userPermission() {
      return this.showAdmin && (
        this.canUser("change-own-webcolumns") ||
        this.canUser("change-default-webcolumns")
      );
    },
  },
  data() {
    return {};
  },
  watch: {},
  methods: {
    setSortPeriod(period) {
      store.dispatch("config/changeMachinesPeriod", { period });
    },
    setSortSorting(sorting) {
      store.dispatch("config/changeMachinesSorting", { sorting });
    },
  },
  components: {},
};
</script>
<style>
</style>