import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";
import { getStorage, setStorage, checkStorage } from "../../helpers/storage";

const state = () => ({
    index: [],
    inversed: {},
    loaded: false
});

// getters
const getters = {

}

// actions
const actions = {
    loadGroups({ commit, rootState }) {
        if (state.loaded) {
            return new Promise((resolve) => {
                resolve();
            })
        } else {
            if (!checkStorage(rootState.auth.company_id + "_groups_loaded")) {
                return new Promise((resolve, reject) => {
                    execute_json("get_stop_reason_group_list")
                        .then(({ data }) => {
                            //console.log(data,data[0]);
                            commit('setGroups', {
                                data: data,
                                company_id: rootState.auth.company_id
                            });
                            resolve();
                        })
                        .catch((err) => {
                            reject(err);
                        })
                });
            } else {
                return new Promise((resolve) => {
                    commit('setGroups', {
                        data: getStorage(rootState.auth.company_id + "_groups_loaded"),
                        company_id: rootState.auth.company_id
                    });
                    resolve();
                })
            }
        }
    },
    forceLoadGroups({ commit, rootState }) {

        return new Promise((resolve, reject) => {
            execute_json("get_stop_reason_group_list")
                .then(({ data }) => {
                    //console.log(data,data[0]);
                    commit('setGroups', {
                        data: data,
                        company_id: rootState.auth.company_id
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });


    },
}
// mutations
const mutations = {
    setGroups(state, { data, company_id }) {
        setStorage(company_id + "_groups_loaded", data);
        state.index = data;
        _.each(state.index, function (sc) {
            state.inversed[sc.code] = sc;
        })
        state.loaded = true;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}