<template>
  <div>
    <b-table
      :data="setupCodes"
      :columnConfig="columnConfig"
      primary-key="record_no"
      sort_type="numerical"
      :show-headers="false"
      :single-select="true"
      @selectionChanged="onSelectionChanged"
    >
    </b-table>
    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      machine_id: this.$route.params.machine,
      columnConfig: {
        stop_description: {
          class: "h50",
        },
      },
      selectedRows: [],
      items: [],
      loading: false,
    };
  },
  mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
      setupTypes: (state) => state.setup.index,
    }),
    ...mapGetters({
      configByMachine: "machines/configByMachine",
      machineHasSf: "machines/machineHasSf",
      setupCodesByMachine: "stopcodes/setupCodesByMachine",
    }),
    setupCodes() {
      return this.setupCodesByMachine(this.machine_id);
    },
    disableNext() {
      return this.data[this.tab.dataKey].selected === null;
    },
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length > 0 && this.selectedRows[0]) {
        this.data[this.tab.dataKey].selected = this.selectedRows[0];
      } else {
        this.data[this.tab.dataKey].selected = null;
      }
    },
  },
};
</script>

<style>
</style>