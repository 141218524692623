<template>
    <div class="indicator status-small" :class="status+' c-'+data.stop_reason_color.toLowerCase()">
        
    </div>
</template>
<script>
export default {
    props:[
        'data'
    ],
    computed:{
        status(){
            if (this.data.status === 'S'){
                if (this.data.current_stop_reason === '-'){
                    return "status-uncoded";
                }else{
                    return "status-stop";
                }
            }else{
                return "status-running"
            }
        }
    }
}
</script>