<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div class="">
      <h1 class="">
        {{ $t("446") }} - {{ periodT ? $t(periodT) : "" }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div class="row">
        <div class="col"></div>
        <div class="col">
          <gauge
            :value="tak_used"
            label="TAK%"
            tlabel="1640"
            :limits="taklimits"
          ></gauge>
        </div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">
          <gauge
            :value="t_used"
            label="T%"
            tlabel="755"
            :limits="tlimits"
          ></gauge>
        </div>
        <div class="col">
          <gauge
            :value="a_used"
            label="A%"
            tlabel="1898"
            :limits="alimits"
            limit-style="domain"
          ></gauge>
        </div>
        <div class="col">
          <gauge
            :value="k_used"
            label="K%"
            tlabel="1639"
            :limits="klimits"
          ></gauge>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Gauge from "@/components/GaugeLabelled";
import CardMenu from "@/components/card-menu";
import store from "@/store";
import { mapState, mapGetters } from "vuex";
import { Configuration } from "@/helpers/settings";
export default {
  data() {
    return {
      config: new Configuration(
        "production-statistics",
        {
          period: "shift",
        },
        this.template
      ),
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    ...mapGetters({ machineById: "machines/byId" }),
    machine() {
      return this.machineById(this.machine_id);
    },
    t_used() {
      switch (this.config.period) {
        case "shift":
          return this.machine.t_percent_shift;
          break;
        case "24h":
          return this.machine.t_percent_24;
          break;
        case "today":
          return this.machine.t_percent_today;
          break;
        case "calendar":
          return this.machine.t_percent_calendar;
          break;
      }
    },
    a_used() {
      switch (this.config.period) {
        case "shift":
          return this.machine.a_percent_shift;
          break;
        case "24h":
          return this.machine.a_percent_24;
          break;
        case "today":
          return this.machine.a_percent_today;
          break;
        case "calendar":
          return this.machine.a_percent_calendar;
          break;
      }
    },
    k_used() {
      switch (this.config.period) {
        case "shift":
          return this.machine.k_percent_shift;
          break;
        case "24h":
          return this.machine.k_percent_24;
          break;
        case "today":
          return this.machine.k_percent_today;
          break;
        case "calendar":
          return this.machine.k_percent_calendar;
          break;
      }
    },
    tak_used() {
      switch (this.config.period) {
        case "shift":
          return this.machine.tak_percent_shift;
          break;
        case "24h":
          return this.machine.tak_percent_24;
          break;
        case "today":
          return this.machine.tak_percent_today;
          break;
        case "calendar":
          return this.machine.tak_percent_calendar;
          break;
      }
    },
    tlimits() {
      return [
        { limit: +this.machine.bar_yellow_limit, color: "yellow" },
        { limit: +this.machine.bar_green_limit, color: "green" },
      ];
    },
    alimits() {
      return [
        {
          limit: +this.machine.speed_deviation_yellow_limit,
          color: "yellow",
          capped: !this.machine.speed_over_100,
        },
        {
          limit: +this.machine.speed_deviation_green_limit,
          color: "green",
          capped: !this.machine.speed_over_100,
        },
      ];
    },
    klimits() {
      return [
        { limit: +this.machine.q_yellow_limit, color: "yellow" },
        { limit: +this.machine.q_green_limit, color: "green" },
      ];
    },
    taklimits() {
      return [
        { limit: +this.machine.oee_yellow_limit, color: "yellow" },
        { limit: +this.machine.oee_green_limit, color: "green" },
      ];
    },
    periodT() {
      let periods = {
        "24h": "448",
        today: "447",
        shift: "245",
        calendar: "18",
      };
      //return "5";
      return periods[this.config.period];
    },
    items() {
      return [
        { header: this.$t("1329") },
        {
          tlabel: "448",
          handler: () => {
            this.config.setValues({ period: "24h" });
          },
          selected: this.config.period === "24h",
        },
        {
          tlabel: "447",
          handler: () => {
            this.config.setValues({ period: "today" });
          },
          selected: this.config.period === "today",
        },
        {
          tlabel: "245",
          handler: () => {
            this.config.setValues({ period: "shift" });
          },
          selected: this.config.period === "shift",
        },
        {
          tlabel: "18",
          handler: () => {
            this.config.setValues({ period: "calendar" });
          },
          selected: this.config.period === "calendar",
        },
      ];
    },
  },
  props: {
    template: {
      type: String,
    },
  },
  components: {
    Gauge,
    CardMenu,
  },
};
</script>