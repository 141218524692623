<template>
  <div>
    <p class="present-value">
      {{ machine.order_rem_hours_hh_mm }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["machine", "order", "planned"],
};
</script>

<style>
</style>