<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div
      v-if="machine"
      class="max-align xl-sizing align-left left"
      :class="config.size"
    >
      <h1>
        {{ config.titleOverride || "SQL viewer" }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div
        style="white-space: pre-wrap; text-align: left; overflow-y: auto"
        v-if="config.layout == 'text'"
      >
        <p style="text-align: left">{{ textData }}</p>
      </div>
      <div v-else>
        <b-table
          :data="autoIdData"
          primary-key="autoId"
          :columnConfig="autoConfig"
        ></b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { InterfaceComponent } from "@/helpers/machine";
import { mapState } from "vuex";
import { execute_json } from "@/helpers/requests";
import store from "@/store";
export default InterfaceComponent(
  {
    data() {
      return {
        config: {
          listensTo: [],
          query: null,
          layout: "text",
          textKey: "",
          size: "l",
          listenGlobally: false,
          titleOverride: null,
        },
        configString: "sql-viewer",
        availableLayouts: ["table", "text"],
        activeListeners: [],
        queryData: null,
      };
    },
    beforeUnmount() {
      this.activeListeners.forEach((event) => {
        store.commit("events/removeHandler", {
          event,
          handler: this.eventFetch,
        });
      });
    },
    methods: {
      registerListeners() {
        console.log("remove old listeners...");
        this.activeListeners.forEach((event) => {
          store.commit("events/removeHandler", {
            event,
            handler: this.eventFetch,
          });
        });
        this.activeListeners = [];
        console.log("listening...");
        this.config.listensTo.forEach((event) => {
          store.commit("events/addHandler", {
            event,
            handler: this.eventFetch,
          });
          this.activeListeners.push(event);
        });
      },
      onConfigLoaded() {
        this.registerListeners();
        this.fetchData();
      },
      eventFetch(e) {
        console.log(e);
        if (
          this.config.listenGlobally ||
          !e.machine_id ||
          e.machine_id == this.machine_id
        ) {
          this.fetchData();
        } else {
          console.log("event discarded due to machine specificity");
        }
      },
      fetchData() {
        console.log("got called to fetch " + this.config.query);
        if (this.config.query) {
          store
            .dispatch("queries/loadQueryData", {
              record_no: this.config.query,
              parameters: {
                "<%machine_id%>": this.machine_id,
              },
            })
            .then((data) => {
              this.queryData = data;
            });
        }
      },
      upsize() {
        let size = this.config.size.length > 4 ? "l" : "x" + this.config.size;
        this.config.setValues({ size });
      },
    },
    computed: {
      ...mapState({
        eventHandlers: (state) => state.events.handlers,
      }),
      eventNames() {
        return Object.keys(this.eventHandlers);
      },
      selectedSqlQuery() {
        return this.config.query;
      },
      autoIdData() {
        let i = 0;
        return (this.queryData || []).map((d) => {
          i++;
          return { ...d, autoId: (''+i).padStart(6,'0') };
        });
      },
      autoConfig() {
        let config = {};
        (Object.keys(this.queryData?.[0] || [])).forEach((k) => {
          config[k] = {};
        });
        return config;
      },
      textData() {
        if (this.config.textKey) {
          return this.queryData?.[0]?.[this.config.textKey] || this.queryData;
        }
        return this.queryData;
      },
      items() {
        let items = [{ header: "Sql Query:" }];
        items.push({
          select: true,
          source: "sql-queries",
          input: "query",
        });
        items.push({
          input: "textKey",
        }),
          items.push({ header: "Layouts:" });
        items.push(
          ...this.availableLayouts.map((l) => {
            return {
              label: l,
              handler: () => {
                this.config.setValues({ layout: l });
              },
              selected: this.config.layout == l,
            };
          })
        );
        items.push(
          { header: "Config:" },
          {
            input: "titleOverride",
            type: "text",
          },
          {
            label: "text size: " + this.config.size,
            handler: this.upsize,
          }
        );
        items.push({
          label: "listen globally",
          handler: () => {
            this.config.setValues({
              listenGlobally: !this.config.listenGlobally,
            });
          },
          selected: this.config.listenGlobally,
        });
        items.push({ header: "Events:" });

        items.push(
          ...this.eventNames.map((n) => {
            return {
              label: n,
              handler: () => {
                if (this.config.listensTo.includes(n)) {
                  this.config.setValues({
                    listensTo: this.config.listensTo.filter((f) => f != n),
                  });
                } else {
                  this.config.listensTo.push(n);
                }
                this.registerListeners();
              },
              selected: this.config.listensTo.includes(n),
            };
          })
        );
        return items;
      },
    },
    watch: {
      selectedSqlQuery(query) {
        this.fetchData();
      },
    },
  },
  ["notifications"]
);
</script>

<style>
</style>