import { flattenMultiRow, flattenSingleRow } from "@/requests/requestParcer";
import { daybreakDate, getMonday, getNow, getNowStart } from "@/helpers/dates";
import axios from "@/requests/axios";
import store from "@/store";

// functions

const d30 = {
    handler: "loadStopReasonsDailyLocation30d",
    updateHandler: "updateDailyLocationStopReasons",
    tlabel: "450",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: null,
    stackKey: "stop_description",
    stackColorKey: "stop_reason_color"
};

function loadStopReasonsDailyLocation30d() {
    return new Promise((resolve, reject) => {
        this.rawData = {};
        this.stopReasonDailyLocationReverseLoopLoad(0, 30).then(() => {
            this.data = this.rawData;
            resolve();
        });
    })

};

function stopReasonDailyLocationReverseLoopLoad(i, max) {
    let webservice = "balthzar_get_statistics_stop_reason_department_start_and_stop_date_3_0_C";

    if (this.locationtype === 'zone') {
        webservice = "balthzar_get_statistics_stop_reason_zone_start_and_stop_date_3_0_C";

    }
    if (this.locationtype === "category") {
        webservice = "balthzar_get_statistics_stop_reason_category_start_and_stop_date_3_0_C"

    }
    let shim = {};
    shim[this.locationtype] = this.location;
    return new Promise((resolve, reject) => {
        axios
            .post(webservice, {
                ...shim,
                start_date: getNowStart(-i),
                stop_date: i === 0 ? getNow() : getNowStart(-(i - 1))
            })
            .then(({ data }) => {
                this.rawData[getNow(-i).slice(0, 10)] = flattenMultiRow(data);
                if (i === max) {
                    resolve();
                } else {
                    return this.stopReasonDailyLocationReverseLoopLoad(i + 1, max).then(() => {
                        resolve();
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

function updateDailyLocationStopReasons() {
    let webservice = "balthzar_get_statistics_stop_reason_department_start_and_stop_date_3_0_C";

    if (this.locationtype === 'zone') {
        webservice = "balthzar_get_statistics_stop_reason_zone_start_and_stop_date_3_0_C";

    }
    if (this.locationtype === "category") {
        webservice = "balthzar_get_statistics_stop_reason_category_start_and_stop_date_3_0_C"

    }
    let shim = {};
    shim[this.locationtype] = this.location;
    return new Promise((resolve, reject) => {
        axios
            .post(webservice, {
                ...shim,
                start_date: getNowStart(),
                stop_date: getNow()
            })
            .then(({ data }) => {
                //let d = _.cloneDeep(this.data);
                let dayKey = getNow().slice(0, 10);
                if (this.data[dayKey] === undefined) {
                    //new day!
                    delete this.data[this.labels[0]];
                    console.log("new day, popping first day");
                }
                this.data[dayKey] = flattenMultiRow(data);
                //this.data = d;
                //console.warn(d);
                resolve();
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}
const graphStopreasonsDailyLocationDataSetup = {
    "30d": d30
};

const graphStopreasonsDailyLocationDataFunctions = {
    loadStopReasonsDailyLocation30d,
    stopReasonDailyLocationReverseLoopLoad,
    updateDailyLocationStopReasons
};


export { graphStopreasonsDailyLocationDataFunctions, graphStopreasonsDailyLocationDataSetup };