import { getNow } from "@/helpers/dates";
import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";

const state = () => ({
    perWeek: {

    },
    perDay: {

    },
    perHour: {

    },
    transactions: {

    }
});

// getters
const getters = {
    byId: (state) => (id) => {
        return state.list.find(zone => zone.code === id) || { empty: true };
    }
}

// actions
const actions = {
    perWeek({ commit, rootState }, { machine_id }) {
        return new Promise((resolve, reject) => {
            axios.post("balthzar_get_transaction_history_per_week_3_0_2", {
                machine_id,
                start_date: getNow(),
                stop_date: getNow()
            })
                .then(({ data }) => {
                    commit('setPerWeek', {
                        data: flattenMultiRow(data),
                        machine_id
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })

        });
    },
    perDay({ commit, rootState }, { machine_id, week }) {
        return new Promise((resolve, reject) => {
            axios.post("balthzar_get_transaction_history_per_day_15_2_26", {
                machine_id,
                start_date: getNow(),
                stop_date: getNow(),
                week_number: week
            })
                .then(({ data }) => {
                    commit('setPerDay', {
                        data: flattenMultiRow(data),
                        machine_id,
                        week
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })

        });
    },
    perHour({ commit, rootState }, { machine_id, day }) {
        return new Promise((resolve, reject) => {
            axios.post("balthzar_get_transaction_history_per_hour_15_2_26", {
                machine_id,
                the_date: day,
                manual_machine: true
            })
                .then(({ data }) => {
                    commit('setPerHour', {
                        data: flattenMultiRow(data),
                        machine_id,
                        day
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })

        });
    },
    getTransactions({ commit, rootState }, { machine_id, date, hour }) {
        return new Promise((resolve, reject) => {
            axios.post("balthzar_get_transaction_history_detail", {
                machine_id,
                the_date:date,
                hour,
                axioskey:"object_transaction"
            })
                .then(({ data }) => {
                    commit('setTransactions', {
                        data: flattenMultiRow(data[0].object_transaction || []),
                        machine_id,
                        date, hour
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })

        });
    },


}
// mutations
const mutations = {
    setPerWeek(state, { data, machine_id }) {
        state.perWeek[machine_id] = data;
    },
    setPerDay(state, { data, machine_id, week }) {
        if (state.perDay[machine_id] === undefined) {
            state.perDay[machine_id] = {};
        }
        state.perDay[machine_id][week] = data;
    },
    setPerHour(state, { data, machine_id, day }) {
        if (state.perHour[machine_id] === undefined) {
            state.perHour[machine_id] = {};
        }
        state.perHour[machine_id][day] = data;
    },
    setTransactions(state, { data, machine_id, date, hour }) {
        if (state.transactions[machine_id] === undefined) {
            state.transactions[machine_id] = {};
        }
        if (state.transactions[machine_id][date] === undefined) {
            state.transactions[machine_id][date] = {};
        }
        state.transactions[machine_id][date][hour] = data || [];
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}