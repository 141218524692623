import { execute_json } from '@/helpers/requests'
import axios from '@/requests/axios'
import { flattenMultiRow } from '@/requests/requestParcer'

const state = () => ({
  list: [],
  toolStructures:{}
})

// getters
const getters = {}

// actions
const actions = {
  loadToolList({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      execute_json('get_used_tools')
        .then(({ data }) => {
          commit('setToolsList', {
            data,
          })
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  loadSf183List({ commit, rootState }, tool_id) {
    return new Promise((resolve, reject) => {
      axios.post('balthzar_get_tool_structure',{
          tool_id
      }).then(({ data }) => {
        commit('setToolStructures', { data: flattenMultiRow(data), tool_id })
        resolve()
      })
    })
  },
}
// mutations
const mutations = {
  setToolsList(state, { data }) {
    state.list = [
      { value: null, description: '-' },
      ...data.map((i) => {
        return {
          value: i.tool_id,
          description: i.tool_description,
        }
      }),
    ]
  },
  setToolStructures(state,{data,tool_id}){
      state.toolStructures[tool_id] = data;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
