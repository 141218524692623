<template>
  <div class="popup">
    <h2>{{ $t("65") }} {{ data.material_no }}:{{ data.material_desc }}</h2>
    <p>{{ $t(step === 0 ? "861" : "574") }}</p>
    <b-table
      :data="scrapReasonsForMachine"
      :columnConfig="columnConfig"
      primary-key="code"
      sort-key="code"
      sort_dir="asc"
      v-if="step === 0"
      :single-select="true"
      :deselect="true"
      @selectionChanged="onSelectionChanged"
    >
    </b-table>
    <numerical-input
      type="number"
      v-if="step === 1"
      name=""
      id=""
      placeholder="quantity"
      v-model="quantity"
    />
    <button class="btn" @click="step--" v-if="step > 0">
      <i class="fas fa-chevron-left"></i></button
    >&nbsp;&nbsp;
    <button class="btn" @click="hide">
      <i class="fas fa-times"></i></button
    >&nbsp;&nbsp;
    <button class="btn btn-success" @click="close" :disabled="!isConfirmed" v-if="step > 0">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapGetters, mapState } from "vuex";
import { execute_json } from "@/helpers/requests";
import { flattenSingleRow } from "@/requests/requestParcer";
import store from "@/store";

export default {
  props: ["data"],
  emits: ["close"],
  data() {
    return {
      loading: true,
      quantity: null,
      step: 0,
      selectedScrapCode: null,
      selectedScrapNote: null,
    };
  },
  mounted() {
    store.dispatch("goods/loadScrapcodes");
    if (this.hasSf254) {
      store.dispatch("goods/loadScrapcodesForMachine", {
        machine_id: this.machine_id,
      });
    }
  },
  beforeUnmount() {},
  methods: {
    close() {
      //dispatch materials.reportScrap -> then ( close)
      store
        .dispatch("materials/materialReportingScrap", {
          material_card_no: this.data.material_card_no,
          machine_id: this.machine_id,
          quantity: this.quantity,
          scrap_reason: this.selectedScrapCode,
        })
        .then(() => {
          this.justClose();
        });
    },
    justClose() {
      console.log("closing...");
      this.$emit("close");
    },
    hide() {
      this.justClose();
    },
    handleEnter() {},
    fetchMaterials() {},
    onSelectionChanged({ selectedRowsData }) {
      if (selectedRowsData.length > 0 && selectedRowsData[0]) {
        this.selectedScrapCode = selectedRowsData[0].code;
        this.selectedScrapNote = selectedRowsData[0].description;
        this.step++;
      } else {
        this.selectedScrapCode = null;
        this.selectedScrapNote = null;
      }
    },
  },
  computed: {
    ...mapState({
      scrapReasons: (state) => state.goods.scrapReasons,
    }),
    ...mapGetters({
      machineSpecificScrapForMachine: "goods/machineSpecificScrapForMachine",
      machineHasSf: "machines/machineHasSf",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    hasSf254() {
      return this.machineHasSf(this.machine_id, "254");
    },
    scrapReasonsForMachine() {
      return this.hasSf254
        ? this.machineSpecificScrapForMachine(this.machine_id)
        : this.scrapReasons;
    },
    columnConfig() {
      return {
        code: {
          class: "h50",
        },
        description: {
          class: "h50",
        },
      };
    },
    selectedScrapReason() {
      return this.scrapReasons.find(
        (r) => r.code === this.data[this.tab.dataKey].code
      );
    },
    machine_id() {
      return (
        this.data.machine_id ||
        this.data.material?.machine_id ||
        this.$route.params.machine
      );
    },
    isConfirmed() {
      return this.step > 0 && this.quantity;
    },
    canClose() {
      return true;
    },
  },
  watch: {},
};
</script>