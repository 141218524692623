<template>
  <div v-if="loaded">
    <nav class="navbar main-nav navbar-dark bg-dark fixed-top">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" @click="toggleNav">
          <img
            class=""
            src="@/assets/Balthzar.png"
            alt=""
            style="max-height: 40px"
          />
        </button>
        <top-right-dropdown />
      </div>
    </nav>
    <div class="spacer" style="height: 44px"></div>
    <div
      class="sidenav"
      :class="{ open: navbar.isOpen }"
      @click.self="toggleNav"
    >
      <div class="sidenav-inner-wrap bg-secondary">
        <div class="content-wrap bg-secondary">
          <side-menu
            :hidden-for-machine="hiddenForMachine"
            :side-menu="sideMenu"
          ></side-menu>
        </div>
      </div>
    </div>
    <div class="container-xxxl" :class="{ open: navbar.isOpen }">
      <slot></slot>
    </div>

    <bottom-buttons />
  </div>
</template>
<script>
import BottomButtons from "@/components/bottom-buttons";
import SideMenu from "./submenu/SideMenu";
import TopRightDropdown from "./submenu/TopRightDropdown";
import LocationSwap from "@/components/location-swap";
import MachineSwap from "@/components/machine-swap";
import { loadLanguageFromCache } from "@/i18n";
import { useRoute } from "vue-router";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";
import { watch, ref } from "vue";
export default {
  setup() {
    const route = useRoute();

    const title = ref(route.meta.title);
    const description = ref("");
    loadLanguageFromCache();

    watch(
      () => route.path,
      () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
      }
    );

    return {
      title,
      description,
    };
  },
  mounted() {
    let promises = [];
    promises.push(store.dispatch("machines/loadMachines"));
    promises.push(store.dispatch("permissions/loadUnloadedPermissions"));
    promises.push(
      store.dispatch("config/loadBaseConfig", { base_code: "widgets" })
    );
    promises.push(
      store.dispatch("config/loadBaseConfig", { base_code: "dashboards" })
    );
    promises.push(
      store.dispatch("config/loadBaseConfig", { base_code: "liveviews" })
    );
    promises.push(store.dispatch("config/loadOwnOverviews"));
    promises.push(
      store.dispatch("config/loadBaseConfig", { base_code: "web_overviews" })
    );
    store.commit("bottomnav/disable");
    /*
    store.dispatch("machines/loadMachines");
    store.dispatch("permissions/loadUnloadedPermissions");
    store
      .dispatch("config/loadBaseConfig", { base_code: "widgets" })
      .then(() => {
        this.loaded = true;
      });
    store
      .dispatch("config/loadBaseConfig", { base_code: "dashboards" })
      .then(() => {
        this.loaded = true;
      });
      */
    Promise.all(promises).then(() => {
      if (!this.canUser("can-access-admin-panel")) {
        this.$router.push("/home");
      }
      this.loaded = true;
    });
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState({
      navbar: (state) => state.sidebar,
      lastLoc: (state) => state.sidebar.lastLocation,
      lastLocType: (state) => state.sidebar.lastLocationType,
      bottomnav: (state) => state.bottomnav,
      path: (state) => state.auth.path,
      widgetTemplates: (state) => state.config.templates.widgets,
      dashboardTemplates: (state) => state.config.templates.dashboards,
      liveviewTemplates: (state) => state.config.templates.liveviews,
      ownOverviews: (state) => state.config.ownOverviews,
    }),
    ...mapGetters({ canUser: "permissions/canUser" }),
    hiddenForMachine() {
      let exclude = [];
      if (!this.canUser("view-dashboard-templates")) {
        exclude.push(11);
      }
      if (!this.canUser("view-widget-settings")) {
        exclude.push(1);
      }
      if (
        !(
          this.canUser("change-own-webcolumns") ||
          this.canUser("change-default-webcolumns")
        )
      ) {
        exclude.push(12);
      }
      return exclude;
    },
    widgetTemplateItems() {
      let keys = Object.keys(this.widgetTemplates);
      return keys.map((v, k) => {
        return {
          icon: "fa fa-grip-horizontal",
          //tlabel: "3669",
          label: v,
          id: 200 + k,
          to: "/admin/config/widgets/" + v,
          disabled: false,
        };
      });
    },
    dashboardTemplateItems() {
      let keys = Object.keys(this.dashboardTemplates);
      return keys.map((v, k) => {
        return {
          icon: "fa fa-grip-vertical",
          //tlabel: "3669",
          label: v,
          id: 400 + k,
          to: "/admin/config/dashboards/" + v,

          disabled: !this.canUser("view-dashboard-templates"),
        };
      });
    },
    liveviewTemplateItems() {
      let keys = Object.keys(this.liveviewTemplates);
      return keys.map((v, k) => {
        return {
          icon: "fas fa-monitor-heart-rate",
          //tlabel: "3669",
          label: v,
          id: 400 + k,
          to: "/admin/config/liveviews/" + v,
          disabled: !this.canUser("view-liveviews-templates"),
        };
      });
    },
    overviewMenuItems() {
      return this.ownOverviews.map((overview) => {
        return {
          icon: "fal fa-table",
          label: overview.overview_name,
          id: "overview-" + overview.record_no,
          to: "/admin/config/overview/" + overview.record_no,
          disabled: false,
        };
      });
    },
    baseMenu() {
      return [
        {
          icon: "fa fa-home",
          tlabel: "6258",
          id: 100,
          to: this.path || "/machines/all",
          disabled: false,
        },
        {
          icon: "fa fa-grip-horizontal",
          tlabel: "1",
          id: 1,
          to: "/",
          dropdown: true,
          disabled: false,
          items: [
            ...this.widgetTemplateItems,
            {
              icon: "fa fa-plus",
              //tlabel: "3669",
              label: "add New Config",
              id: 300,
              to: "/",
              hasHandler: true,
              handler: this.addNewConfig,
              disabled: !this.canUser("add-widget-settings"),
            },
          ],
        },
        {
          icon: "fa fa-grip-vertical",
          tlabel: "3669",
          id: 11,
          to: "/",
          dropdown: true,
          disabled: false,
          implemented: false,
          items: [
            ...this.dashboardTemplateItems,
            {
              icon: "fa fa-plus",
              //tlabel: "3669",
              label: "add New Dashboard",
              id: 3001,
              to: "/",
              hasHandler: true,
              handler: this.addNewDashboard,
              disabled: !this.canUser("add-dashboard-templates"),
            },
          ],
        },
        {
          icon: "far fa-table",
          label: "Tables",
          tlabel: "1802",
          id: 12,
          to: "/",
          dropdown: true,
          disabled: !(
            this.canUser("change-own-webcolumns") ||
            this.canUser("change-default-webcolumns")
          ),
          implemented: false,
          items: [
            {
              icon: "fa fa-grip-horizontal",
              label: "Overviews",
              id: 40001,
              to: "/",
              dropdown: true,
              disabled: false,
              items: [
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Machine",
                  id: 4001,
                  to: "/admin/config/tables/machines",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },

                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "A-Active",
                  id: 4002,
                  to: "/admin/config/tables/machines_a_active",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Uncoded stops",
                  id: 4003,
                  to: "/admin/config/tables/uncoded_stops",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Planning resource",
                  id: 4003,
                  to: "/admin/config/tables/planning_resource_orders",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Notes",
                  id: 4003,
                  to: "/admin/config/tables/machine_notes",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
              ],
            },
            {
              icon: "fa fa-grip-horizontal",
              label: "Interface",
              id: 40002,
              to: "/",
              dropdown: true,
              disabled: false,
              items: [
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Running orders",
                  id: 400021,
                  to: "/admin/config/tables/running_orders",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Planned orders",
                  id: 400022,
                  to: "/admin/config/tables/planned_orders",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Good quantity history",
                  id: 400023,
                  to: "/admin/config/tables/good_quantity_history",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Scrap quantity history",
                  id: 400024,
                  to: "/admin/config/tables/scrap_quantity_history",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Pallet flag history",
                  id: 400025,
                  to: "/admin/config/tables/pallet_flag_history",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Pallet flag history SF362",
                  id: 400026,
                  to: "/admin/config/tables/pallet_flag_history_sf362",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Uncoded stops history",
                  id: 400027,
                  to: "/admin/config/tables/uncoded_stops_history",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Internal activities",
                  id: 400028,
                  to: "/admin/config/tables/internal_activities",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Quick reports",
                  id: 400029,
                  to: "/admin/config/tables/quick_reports",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Rework",
                  id: 400030,
                  to: "/admin/config/tables/rework_orders",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
              ],
            },
            {
              icon: "fa fa-grip-horizontal",
              label: "Widgets",
              id: 40003,
              to: "/",
              dropdown: true,
              disabled: false,
              items: [
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Planned orders",
                  id: 4006,
                  to: "/admin/config/tables/planned_orders_widget",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Running orders",
                  id: 4007,
                  to: "/admin/config/tables/running_orders_widget",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Finished orders",
                  id: 4008,
                  to: "/admin/config/tables/finished_orders_widget",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Line machines",
                  id: 4009,
                  to: "/admin/config/tables/line_machines_widget",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
              ],
            },

            {
              icon: "fa fa-users-cog",
              label: "Admin",
              id: 400003,
              to: "/",
              dropdown: true,
              disabled: false,
              items: [
                {
                  icon: "far fa-columns",
                  //tlabel: "3669",
                  label: "Production goals",
                  id: 400006,
                  to: "/admin/config/tables/production_goals",
                  disabled: !(
                    this.canUser("change-own-webcolumns") ||
                    this.canUser("change-default-webcolumns")
                  ),
                },
              ],
            },
          ],
        },
        {
          icon: "far fa-monitor-heart-rate",
          tlabel: "2653",
          id: 50001,
          to: "/",
          dropdown: true,
          disabled: false,
          items: [
            ...this.liveviewTemplateItems,
            {
              icon: "fa fa-plus",
              //tlabel: "3669",
              label: "add New Config",
              id: 51000,
              to: "/",
              hasHandler: true,
              handler: this.addNewLiveBoard,
              disabled: !this.canUser("add-liveviews-templates"),
            },
          ],
        },
        {
          icon: "fa fa-grip-horizontal",
          label: "Overviews",
          id: "overviews",
          to: "/",
          dropdown: true,
          disabled: false,
          items: [
            ...this.overviewMenuItems,
            {
              icon: "fa fa-plus",
              //tlabel: "3669",
              label: "add New Overview",
              id: "overview-new",
              to: "/",
              hasHandler: true,
              handler: this.addNewOverview,
              disabled: false,
            },
          ],
        },
        {
          icon: "fa fa-lock",
          tlabel: "240",
          id: 3,
          to: "/admin/security",
          disabled: !this.canUser("view-permissions"),
        },
        {
          icon: "fa fa-bullseye",
          tlabel: "729",
          id: 4,
          to: "/admin/production-goals",
          disabled: !this.canUser("view-production-goals"),
        },
      ];
    },
    sideMenu() {
      let base = this.baseMenu;
      let exclude = this.hiddenForMachine;
      return base.filter((item) => !exclude.includes(item.id));
    },
    toolIdRoute() {
      if (this.$route.name === "cavities-machine") {
        return this.$route.path;
      }
      return !this.hasActiveMachine || this.activeMachine.tool_id == ""
        ? "/"
        : "/cavities/" + this.activeMachine.machine_id;
    },
  },
  methods: {
    toggleNav() {
      console.log("Bar");
      store.commit("sidebar/toggle");
    },
    closeNav() {
      console.log("Bar");
      store.commit("sidebar/close");
    },
    logout() {
      store.dispatch("auth/logout");
    },
    addNewConfig() {
      let name = prompt("new template name");
      name = _.kebabCase(name);
      if (this.widgetTemplates[name] === undefined && name !== "all") {
        if (name.length > 1) {
          store.dispatch("config/addToBaseConfig", {
            name,
            base_code: "widgets",
          });
        } else {
          alert("name too short");
        }
      } else {
        alert("name already taken!");
      }
    },
    addNewDashboard() {
      let name = prompt("new template name");
      name = _.kebabCase(name);
      if (this.dashboardTemplates[name] === undefined && name !== "all") {
        if (name.length > 1) {
          store.dispatch("config/addToBaseConfig", {
            name,
            base_code: "dashboards",
          });
        } else {
          alert("name too short");
        }
      } else {
        alert("name already taken!");
      }
    },
    addNewLiveBoard() {
      let name = prompt("new template name");
      name = _.kebabCase(name);
      if (this.liveviewTemplates[name] === undefined && name !== "all") {
        if (name.length > 1) {
          store.dispatch("config/addToBaseConfig", {
            name,
            base_code: "liveviews",
          });
        } else {
          alert("name too short");
        }
      } else {
        alert("name already taken!");
      }
    },
    addNewOverview(){
       let name = prompt("new template name");
      name = _.kebabCase(name);
      if (this.ownOverviews.filter(o => o.overview_name === name).length === 0 && name !== "all") {
        if (name.length > 1) {
          store.dispatch("config/newOwnOverview", {
            name,
          });
        } else {
          alert("name too short");
        }
      } else {
        alert("name already taken!");
      }
    }
  },
  components: {
    BottomButtons,
    LocationSwap,
    MachineSwap,
    SideMenu,
    TopRightDropdown,
  },
};
</script>
<style lang="scss">
</style>