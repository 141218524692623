import axios from "@/requests/axios"
const state = () => ({
  texts: {

  },
});

// getters
const getters = {

}

// actions
const actions = {
  GetOperationTextForCardNo({ store, commit }, { card_no, erp_system }) {
    return new Promise((resolve, reject) => {
      if (card_no === undefined){
        reject("no card_no found.");
      }
      let text = "";
      axios
        .post("balthzar_get_operation_texts_16_1_118", {
          strCard_no: card_no,
          erp_system: "",
        })
        .then(({ data }) => {
          if (data.string && data.string._) {
            let string = data.string._;
            text = string;
          }
          commit("setTextForCardNo",{text,card_no})
          resolve(text);
        }).catch((err) => {
          reject(err);
        })
    })
  }
}

// mutations
const mutations = {
  setTextForCardNo(state, { text, card_no }) {
    state.texts[card_no] = text;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}