import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
//import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";

const state = () => ({
  selected: [],
  inversed: {},
  returnTo: null,
  test: false
});

// getters
const getters = {
  machineIds(state) {
    return [...new Set(state.selected.map(item => item.machine_id))];
  },
  getCombinedStopCodes(state, getters, rootState, rootGetters) {
    let stopCodes = [];
    for (let i = 0; i < getters.machineIds.length; i++) {
      let machine_id = getters.machineIds[i];
      stopCodes.push(rootGetters["stopcodes/stopCodesByMachine"](machine_id));
    }
    let union = _.unionBy(...stopCodes, 'stop_code');
    //console.log("unioned codes > ", union)
    let intersect = _.intersectionBy(...stopCodes, 'stop_code');
    //console.log("intersect codes > ", intersect)
    let intersection = [...new Set(intersect.map(item => item.stop_code))];
    //console.log("intersection codes > ", intersection)
    let intersectedUnion = _.map(union, (item) => {
      return {
        stop_code: item.stop_code,
        stop_description: item.stop_description,
        stop_text_flag: item.stop_text_flag,
        intersect: intersection.includes(item.stop_code),
        group_id: item.group_id,
        code_order: item.code_order
      }
    });
    return _.orderBy(intersectedUnion, 'code_order');
  },
  getCombinedGroups(state, getters, rootState, rootGetters) {
    let union = [...new Set(getters.getCombinedStopCodes.map(item => item.group_id))];
    console.log("combined groups", union);
    let intersect = [...new Set(_.filter(getters.getCombinedStopCodes, (item) => {
      return item.intersect;
    }).map(item => item.group_id))];
    console.log("combined intersect groups", intersect);
    let intersectedUnion = _.map(union, (item) => {
      console.log("mapping item:", item);
      let inversed = rootState.groups.inversed[item];
      if (inversed === undefined) {
        return undefined;
      }
      console.log("inversed", inversed);
      return {
        code: inversed.code,
        code_order: inversed.code_order,
        description: inversed.description,
        stop_group_color: inversed.stop_group_color,
        intersect: intersect.includes(item)
      }
    });
    return _.orderBy(intersectedUnion.filter(i => i !== undefined), 'code_order');
  }
}

// actions
const actions = {
  updateUncodedStop({ rootState,dispatch }, { uncoded, stopcode, note }) {
    return new Promise((resolve, reject) => {
      execute_json("update_stop_reason", {
        machine_id: uncoded.machine_id,
        stop_number: uncoded.stop,
        stop_reason: stopcode.stop_code,
        old_stop_reason: uncoded.stop_reason,
        username: rootState.auth.username,
        notes: note
      }).then(() => {
        dispatch("stopcodes/lastUsedStopCodeHandler", { machine_id:uncoded.machine_id,stopcode }, { root: true });
        resolve();
      }).catch((err) => {
        reject(err);
      })
    });
  },
  updateHistoryNote({ rootState }, { historic_stop, note }) {
    return new Promise((resolve, reject) => {
      execute_json("update_stop_reason",{
            machine_id: historic_stop.machine_id,
            stop_number: historic_stop.stop,
            stop_reason: historic_stop.stop_reason,
            old_stop_reason: historic_stop.stop_reason,
            username: rootState.auth.username,
            notes: note
        }).then(() => {
          resolve();
        }).catch((err) => {
          reject(err);
        })
    });
  },
}
// mutations
const mutations = {
  setSelected(state, data) {
    state.selected = data;
    state.returnTo = null;
  },
  setReturnTo(state, data) {
    state.returnTo = data;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}