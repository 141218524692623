import axios from "@/requests/axios";
import { flattenMultiRow, flattenSingleRow } from "@/requests/requestParcer";
import { setStorage, getStorage } from "@/helpers/storage";
import _ from 'lodash';
import { checkStorage, removeStorage } from "../../helpers/storage";
import { execute_json } from "@/helpers/requests";
import { getNow } from "../../helpers/dates";

const state = () => ({
  index: [],
  list: [],
  inverseList: {},
  loaded: false,
  listLoaded: false,
  config: {

  },
  specialFunctions: {

  },
  /*
  stopCodes: {

  },*/
  uncoded: {

  },
  uncodedLocation: {
    department: {

    },
    zone: {

    },
    category: {

    },
    mine: {
      all: null
    }
  },
  history: {

  },
  timeHistory: {

  },
  signedIn: {

  },
  notificationSettings: {

  }

});

// getters
const getters = {
  byId: (state) => (id) => {
    if (!state.loaded) {
      return null;
    }
    return state.index.find(machine => machine.machine_id === id) || { empty: true };
  },
  parametersById: (state) => (id) => {
    if (!state.listLoaded) {
      return null;
    }
    return state.list.find(machine => machine.object_no === id) || { empty: true };
  },
  machineHasSf: (state) => (machine_id, sf) => {
    let s = state.specialFunctions[machine_id] || [];
    return s.find(o => o.sf == sf) ? true : false;
  },
  configByMachine: (state) => (machine_id) => {
    return state.config[machine_id] || {};
  },
  signedInByMachine: (state) => (machine_id) => {
    return state.signedIn[machine_id] || [];
  },
  timeHistoryByMachine: (state) => (machine_id) => {
    return state.timeHistory[machine_id] || {};
  },
  notificationSettingsBymachine: (state) => (machine_id) => {
    return state.notificationSettings[machine_id] || {};
  },
  resourcePlanningMachines: (state) => {
    return state.list.filter(m => m.resource_type == '2');
  },
  planningResourcesForMachine: (state) => (machine_id) => {
    return state.list.filter(m => m.planning_resource == machine_id)
  },
  nonResourceMachines: (state, getters) => {
    return state.index.filter(m => {
      let m2 = getters.parametersById(m.machine_id);
      return m2 === null || m2.resource_type != '2';
    });
  },
  machinesByErpResource: (state) => (erp_resource) => {
    return state.index.filter(m => m.erp_resource?.split("#").some(r => erp_resource.split("#").includes(r)));
  },

  /*
  stopCodesByMachine: (state) => (machine_id) => {
    return state.stopCodes[machine_id];
  },
  setupCodesByMachine: (state, getters) => (machine_id) => {
    return getters.stopCodesByMachine(machine_id) ? getters.stopCodesByMachine(machine_id).filter(s => s.setup_time) : [];
  }
  */
}

// actions
const actions = {
  /*
  loadStopcodesForSelectedUncoded({ rootState, dispatch }) {
    let unique = [...new Set(rootState.uncoded.selected.map(item => item.machine_id))];
    let promises = [];
    for (let i = 0; i < unique.length; i++) {
      let machine_id = unique[i];
      promises.push(dispatch('loadStopcodesForMachine', { machine_id }));
      promises.push(dispatch('loadTouchConfigForMachine', { machine_id }));
    }
    return Promise.all(promises);
  },
  */

  loadMachines({ commit, dispatch, rootState }) {
    return new Promise((resolve, reject) => {
      execute_json("get_status_view_client", { "force_buffer_reset": false }).then(({ data }) => {
        //console.log(data,data[0]);
        commit('setMachines', flattenMultiRow(data));
        if (!window.machineFetchEnabled) {
          console.log("setting interval...");
          dispatch("loadListMachines");
          dispatch("updates/addUpdate", { action: "machines/loadMachines", parameters: {}, primaryKey: null, interval: 60, source: 'machines' }, { root: true });
          window.machineFetchEnabled = true;
        }
        resolve();
      })
        .catch((err) => {
          reject(err);
        })
    });
  },
  loadListMachines({ commit, state, dispatch, rootState }) {
    return new Promise((resolve, reject) => {
      if (state.listLoaded) {
        resolve();
      }
      execute_json("get_machine_list",{
        skip_touch_parameters : true
      }).then(({ data }) => {
        commit('setListMachines', data);
        resolve();
      })
        .catch((err) => {
          reject(err);
        })

    });
  },
  loadMachine({ commit, dispatch, rootState }, { machine_id }) {
    return new Promise((resolve, reject) => {
      execute_json("get_status_view_client", {
        'force_buffer_reset': false,
        machine_id
      })
        .then(({ data }) => {
          //console.warn(">>>", data, flattenSingleRow(data));
          //console.log("single machine", flattenSingleRow(data));
          commit('setMachine', { data: flattenSingleRow(data), machine_id });
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  loadUncodedForMachine({ commit, rootState }, { machine_id, shift }) {
    return new Promise((resolve, reject) => {
      let d = new Date();
      let today = d.toBalthzarTime();
      d.setDate(d.getDate() - 1);
      let yesterday = d.toBalthzarTime();
      execute_json("get_stop_without_stop_reason", {
        machine_id,
        'start_date': yesterday,
        'stop_date': today,
        'shift_code': shift,
        'interval': 2
      })
        .then(({ data }) => {
          commit('setUncodedForMachine', { machine_id, uncoded: data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
    });
  },

  loadHistoryForMachine({ commit, rootState }, { machine_id, period }) {
    return new Promise((resolve, reject) => {
      let d = new Date();
      let today = d.toBalthzarTime();
      d.setDate(d.getDate() - period);
      let previousDay = d.toBalthzarTime();
      execute_json("get_all_stops_with_stop_reason", {
        'choice': 1,
        machine_id,
        'start_date': previousDay,
        'stop_date': today,
        'stop_reason': ''
      })
        .then(({ data }) => {
          //console.log(data,data[0]);
          commit('setHistoryForMachine', { machine_id, history: data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  loadUncodedForLocation({ commit, rootState }, { request_type, request_code }) {
    return new Promise((resolve, reject) => {
      let d = new Date();
      let today = d.toBalthzarTime();
      d.setDate(d.getDate() - 1);
      let previousDay = d.toBalthzarTime();
      let location_type = request_type == 'mine' ? 'my_machines' : request_type;
      let location_code = request_type == 'mine' ? rootState.auth.username : request_code;
      if (location_type == null || location_code == null) {
        console.log("no location type or code");
        resolve();
      } else {
        /*
  
        let request_type = this.$route.params.locationtype == 'mine' ? 'my-machines' : this.$route.params.locationtype;
        let request_code = this.$route.params.locationtype == 'mine' ? this.user : this.$route.params.location;
        */
        execute_json("get_stop_without_stop_reason_dzc", {
          location_type,
          location_code,
          'start_date': previousDay,
          'stop_date': today,
        }
        )
          .then(({ data }) => {
            //console.log(data,data[0]);
            commit('setUncodedForLocation', { request_type, request_code, uncoded: data });
            resolve();
          })
          .catch((err) => {
            reject(err);
          })
      }
    });
  },
  loadTouchConfigForMachine({ commit, rootState, rootGetters }, { machine_id, reloadCachedValues }) {
    if (!checkStorage(rootState.auth.company_id + "_touch_config_machine_" + machine_id)) {
      return new Promise((resolve, reject) => {
        execute_json("get_object_touch_parameters", {
          machine_id
        })
          .then(({ data }) => {
            //console.log(data,data[0]);
            commit('setTouchConfigForMachine', {
              machine_id,
              touchConfig: data.length === 1 ? data[0] : {},
              company_id: rootState.auth.company_id
            });
            commit('events/beConfident', {}, { root: true });
            resolve();
          })
          .catch((err) => {
            reject(err);
          })
      });
    } else {
      return new Promise((resolve) => {
        commit('setTouchConfigForMachine', {
          machine_id,
          touchConfig: getStorage(rootState.auth.company_id + "_touch_config_machine_" + machine_id),
          company_id: rootState.auth.company_id
        });
        resolve();
      })
    }
  },
  clearTouchConfigs({ commit, rootState, dispatch }) {
    let keys = Object.keys(localStorage);
    let storagePath = rootState.auth.company_id + "_touch_config_machine_";
    keys.forEach((key) => {
      if (key.includes(storagePath)) {
        removeStorage(key);
      }
    });
    commit("nullTouchConfigs");
    if (rootState.auth.machine) {
      dispatch("loadTouchConfigForMachine", { machine_id: rootState.auth.machine });
    }
  },
  loadSpecialFunctionsForMachine({ commit, rootState, rootGetters }, { machine_id }) {
    if (!checkStorage(rootState.auth.company_id + "_special_functions_machine_" + machine_id)) {
      return new Promise((resolve, reject) => {
        execute_json("get_machine_special_functions", {
          machine_id
        })
          .then(({ data }) => {
            commit('setSpecialFunctionsForMachine', {
              machine_id,
              SpecialFunctions: data,
              company_id: rootState.auth.company_id
            });
            commit('events/beConfident', {}, { root: true });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    } else {
      return new Promise((resolve) => {
        commit('setSpecialFunctionsForMachine', {
          machine_id,
          SpecialFunctions: getStorage(rootState.auth.company_id + "_special_functions_machine_" + machine_id),
          company_id: rootState.auth.company_id
        });
        resolve();
      })
    }
  },
  clearSpecialFunctions({ commit, rootState, dispatch }) {
    let keys = Object.keys(localStorage);
    let storagePath = rootState.auth.company_id + "_special_functions_machine_";
    keys.forEach((key) => {
      if (key.includes(storagePath)) {
        removeStorage(key);
      }
    });
    commit("nullSpecialFunctions");
    if (rootState.auth.machine) {
      dispatch("loadSpecialFunctionsForMachine", { machine_id: rootState.auth.machine });
    }
  },
  loadSignedInForMachine({ commit, rootState }, machine_id) {
    return new Promise((resolve, reject) => {
      if (machine_id === null || machine_id === undefined) {
        console.warn("Sign in cancelled silently.")
        resolve();
        return true;
      }
      execute_json("get_signed_in_at_machine", { machine_id }
      )
        .then(({ data }) => {
          commit('setSignedInForMachine', { machine_id, data: data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  loadTimeHistoryForMachine({ commit, rootState }, { machine_id, hours = 8 }) {
    let promises = [];
    let timeslots = null;
    let cycles = null;
    /*
    let d = new Date();
    d.setHours(d.getHours() - (hours - 1));
    d.setMinutes(0);
    d.setSeconds(0);
    let startTime = d.toBalthzarTime();
    */
    let endTime = getNow();
    /*
    promises.push(new Promise((resolve, reject) => {
      axios
        .post("balthzar_get_graphical_history_data_interval", {
          axiosflag: "multiset",
          strMachine_id: machine_id,
          strStart_time: startTime,
          strEnd_time: endTime,
        })
        .then(({ data }) => {
          timeslots = flattenMultiRow(data)
            .filter((row) => row.length)
            .map((row) => flattenMultiRow(row))
            .sort((a, b) => a[0].machine_id.localeCompare(b[0].machine_id));
          resolve();
        })
        .catch((err) => {
          console.warn(err);
          reject();
        })
    }));
    */

    promises.push(new Promise((resolve, reject) => {
      execute_json("get_graphical_history_data_interval", {
        machine_id,
        interval_hours: hours,
        end_time: endTime
      }).then(({ data }) => {
        timeslots = data;
        resolve();
      })
      .catch((err) => {
        console.warn(err);
        reject();
      });
    }));

    promises.push(new Promise((resolve, reject) => {
      execute_json("get_machine_cycles_per_hour", {
        machine_id: machine_id,
        interval: hours,
      }, { multiset: true }).then(({ data }) => { cycles = data; resolve(); }).catch((err) => {
        console.warn(err);
        reject();
      })

    }));
    return new Promise((resolve, reject) => {
      Promise.all(promises).then(() => {
        commit("setTimeHistoryForMachine", { cycles, timeslots, machine_id });
        resolve();
      })
    })
  },
  loadNotificationSettings({ commit }, { machine_id }) {
    return new Promise((resolve, reject) => {
      execute_json("machine_notification", {
        machine_id,
        feature: "read"
      }).then(({ data }) => {
        commit("setNotificationSettings", { machine_id, data });
        resolve(data);
      }).catch((err) => {
        reject(err);
      })
    })
  }
}

// mutations
const mutations = {
  setMachines(state, data) {
    state.index = data.filter((m) => m.machine_active);
    state.loaded = true;
  },
  setMachine(state, { data, machine_id }) {
    if (data === null) {
      console.warn("data null for " + machine_id);
    } else {
      let index = state.index.findIndex(m => m.machine_id == machine_id);
      if (index === -1) {
        index = state.index.length;
      }
      state.index[index] = data;
    }
    //console.log(index, data, machine_id);
    //state.loaded = true;
  },
  /*
  setStopCodesForMachine(state, { machine_id, stopCodes, company_id }) {
    setStorage(company_id + "_stop_codes_machine_" + machine_id, stopCodes);
    //console.warn("stopcodes", stopCodes);
    state.stopCodes[machine_id] = stopCodes.filter(sc => sc.code_type.trim() == "1");
  },
  */
  setUncodedForMachine(state, { machine_id, uncoded }) {
    if (uncoded.length === 1 && uncoded[0].call_code !== undefined) {
      state.uncoded[machine_id] = [];
    } else {
      state.uncoded[machine_id] = uncoded;
    }
  },
  setHistoryForMachine(state, { machine_id, history }) {
    state.history[machine_id] = history;
  },
  setTimeHistoryForMachine(state, { machine_id, timeslots, cycles }) {
    state.timeHistory[machine_id] = { timeslots, cycles };
  },
  setUncodedForLocation(state, { request_type, request_code, uncoded }) {
    if (uncoded.length === 1 && uncoded.call_code !== undefined) {
      uncoded = [];
    }
    state.uncodedLocation[request_type][request_code] = uncoded.map((u) => {
      return {
        ...u,
        id: +_.uniqueId()
      }
    });
  },
  setTouchConfigForMachine(state, { machine_id, touchConfig, company_id }) {
    setStorage(company_id + "_touch_config_machine_" + machine_id, touchConfig);
    state.config[machine_id] = touchConfig;
  },
  nullTouchConfigs(state) {
    state.config = {};
  },
  setSpecialFunctionsForMachine(state, { machine_id, SpecialFunctions, company_id }) {
    setStorage(company_id + "_special_functions_machine_" + machine_id, SpecialFunctions);
    state.specialFunctions[machine_id] = SpecialFunctions;
  },
  nullSpecialFunctions(state) {
    state.specialFunctions = {};
  },
  updateStopcode(state, { machine_id, stopcode }) {
    //console.log(machine_id, stopcode);
    let machine = state.index.find(m => m.machine_id == machine_id);
    if (machine) {
      //console.log("found machine > ", machine, machine_id);
      machine.current_stop_reason = stopcode.stop_code;
      machine.stop_description = stopcode.stop_description;
      machine.stop_reason_color = stopcode.stop_reason_color;
    }
  },
  setSignedInForMachine(state, { machine_id, data }) {
    state.signedIn[machine_id] = data;
  },
  setListMachines(state, data) {
    if (data.length === 1 && data.call_code !== undefined) {
      data = [];
    }
    state.list = data;
    data.forEach(m => {
      state.inverseList[m.object_no] = {
        resource_type: m.resource_type
      }
    })
    state.listLoaded = true;
  },
  setNotificationSettings(state, { machine_id, data }) {
    state.notificationSettings[machine_id] = data.length ? data[0] : data;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}