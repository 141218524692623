//import Home from '@/views/Home.vue'
import defaultLayout from '@/layouts/DefaultLayout'
function loadView(view) {
  return () => import ( `@/views/${view}.vue`)
}

export const homeRoutes = [
  {
    path: '/home',
    name: 'Home',
    meta: {
      requiresAuth: true,
      layout: defaultLayout
    },
    component:loadView("Home")
  },
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/machines/all"
  }
]

