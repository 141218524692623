<template>
  <div class="widget-history-wrap">
    <time-history
      v-for="(chunk, key) in keyedData"
      :key="chunk.row_no"
      ref="timehistory"
      :timeslots="chunk"
      :index="chunk.row_no"
      @addSelect="toggleSelect"
      :cycles="summedCycles[key]"
    />
  </div>
</template>
<script>
import TimeHistory from "@/components/TimeHistory";
import TimeHistoryWrap from "@/components/TimeHistoryWrap";
import { mapState, mapGetters } from "vuex";
import { Pulse } from "@/helpers/heartbeat";
import store from "@/store";
import _ from "lodash";

export default {
  props: {
    machine_id: {
      required: true,
    },
  },
  data() {
    return {
      cid: _.uniqueId("th"),
      pulse: null,
      interval: 180,
      selected: [],
      events: [],
      loading: false,
    };
  },
  mounted() {
    //this.load();
    this.pulse = new Pulse({
      action: "machines/loadTimeHistoryForMachine",
      interval: 120,
      primaryKey: "machine_id",
      parameters: { machine_id: this.machine_id, hours: 24 },
    });

    Promise.resolve().then(() => {
      let container = this.$el;
      container.scrollTop = container.scrollHeight;
    });

    store.commit("events/addHandler", {
      event: "update_stop_reason",
      handler: this.forcePulse,
    });
  },
  beforeUnmount() {
    store.commit("events/removeHandler", {
      event: "update_stop_reason",
      handler: this.forcePulse,
    });
    this.pulse.stop();
  },
  methods: {
    load() {
      //store.dispatch("config/loadMyMachines");
    },
    reload() {
      this.pulse.stop();
      this.pulse = new Pulse({
        action: "machines/loadTimeHistoryForMachine",
        interval: 120,
        primaryKey: "machine_id",
        parameters: { machine_id: this.machine_id, hours: 24 },
      });

      Promise.resolve().then(() => {
        let container = this.$el;
        container.scrollTop = container.scrollHeight;
      });
    },
    forcePulse({ event_message }) {
      let { machine_id } = JSON.parse(event_message);
      if (machine_id == this.machine_id) {
        console.warn(event_message, machine_id, "pulsing");
        this.pulse.force();
      }
    },
    toggleSelect(stop) {
      console.log(this.$refs.timehistory);
      if (this.selected.findIndex((s) => s.stop === stop.stop) > -1) {
        _.each(this.$refs.timehistory, (ref) => {
          ref?.deActivateStop?.(stop);
        });
        this.selected = this.selected.filter((s) => s.stop !== stop.stop);
      } else {
        this.selected.push(stop);
        _.each(this.$refs.timehistory, (ref) => {
          ref?.activateStop?.(stop);
        });
      }
      let selectedRows = _.cloneDeep(this.selected);
      if (this.selected.length > 0 && this.selected[0]) {
        store.commit("bottomnav/enable");
        store.commit("bottomnav/setType", "uncoded-multiple");
        store.commit("bottomnav/setData", selectedRows);
        store.commit("bottomnav/setReturnTo", this.$route.path);
        let i = selectedRows.length - 1;
        store.dispatch("stopcodes/loadStopcodesForMachine", {
          machine_id: selectedRows[i].machine_id,
          reloadCachedValues: false,
        });
        store.dispatch("machines/loadTouchConfigForMachine", {
          machine_id: selectedRows[i].machine_id,
          reloadCachedValues: false,
        });
      } else {
        store.commit("bottomnav/disable");
        store.commit("bottomnav/setData", []);
      }
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({
      timeHistoryByMachine: "machines/timeHistoryByMachine",
    }),
    timeHistory() {
      return this.timeHistoryByMachine(this.machine_id);
    },
    data() {
      return this.timeHistory.timeslots || [];
    },
    cycles() {
      return this.timeHistory.cycles || [];
    },
    keyedData() {
      let keyedData = {};
      let firstHour = null;
      let lastHour = null;
      if (this.data.length === 0) {
        return {};
      }
      _.each(this.data, function (chunk) {
        let hour = chunk.belongs_to_date_hour;
        if (firstHour === null) {
          firstHour = hour;
        }
        lastHour = hour;
        if (keyedData[hour] === undefined) {
          keyedData[hour] = [];
        }
        keyedData[hour].push(chunk);
      });
      //fill out first and last hour
      let firstHourTime =
        keyedData[firstHour]
          .map((t) => Math.max(+t.stop_time, +t.run_time))
          .reduce((a, b) => a + b, 0) || 1;
      let filler = {
        run_time: 3600 - firstHourTime,
        stop_time: "1",
        belongs_to_hour: firstHour,
        stop_reason_color: "transparent",
        stop_reason: "-",
        filler: true,
      };
      //keyedData[firstHour].unshift(filler);
      if (firstHour !== lastHour) {
        let lastHourTime =
          keyedData[lastHour]
            .map((t) => Math.max(+t.stop_time, +t.run_time))
            .reduce((a, b) => a + b, 0) || 1;
        let filler2 = {
          run_time: 3599 - lastHourTime,
          stop_time: "1",
          belongs_to_hour: lastHour,
          stop_reason_color: "transparent",
          stop_reason: "-",
          filler: true,
        };
        keyedData[lastHour].push(filler2);
      }
      return keyedData;
    },
    keyedCycles() {
      let keys = Object.keys(this.keyedData);
      let keyedCycles = {};
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let padded = key.replace("_", " ");
        keyedCycles[key] = this.cycles.filter((c) => {
          return c.trans_time.slice(8, 13) == padded;
        });
      }
      return keyedCycles;
    },
    summedCycles() {
      let summed = {};
      _.each(this.keyedCycles, (v, k) => {
        summed[k] = {
          cycles: Math.round(
            v.map((t) => +t.cycles || 0).reduce((a, b) => a + b, 0) || 0
          ),
          green: Math.round(
            v
              .map((t) => +t.target_qty_oee_green_limit)
              .reduce((a, b) => a + b, 0) || 0
          ),
        };
      });
      return summed;
    },
  },
  watch: {
    data: {
      deep: true,
      handler: function () {
        Promise.resolve().then(() => {
          let container = this.$el;
          container.scrollTop = container.scrollHeight;
        });
      },
    },
    machine_id() {
      this.reload();
    },
  },
  components: {
    TimeHistory,
    TimeHistoryWrap,
  },
};
</script>