<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget left-aligned">
    <div v-if="machine">
      <h1>
        {{ $t("3669") }}
        <card-menu :items="items"></card-menu>
      </h1>
      <div class="widget-dashboard">
        <div class="row">
          <div class="col">
            <p class="label-value">
              {{ $t("1412") }}
            </p>
            <p class="present-value">
              {{ Math.round(machine.good_qty_shift2 * 100) / 100 }}
            </p>

            <p class="label-value">
              {{ $t("5768") }}
            </p>
            <p class="present-value">
              {{ Math.round(machine.target_qty_shift * 100) / 100 }}
            </p>

            <p class="label-value">
              {{ $t("4565") }}
            </p>
            <p class="present-value">
              <span
                :class="{
                  'status-green': machine.diff_qty_shift >= 0,
                  'status-red': machine.diff_qty_shift < 0,
                }"
              >
                {{ Math.round(machine.diff_qty_shift * 100) / 100 }}</span
              >
            </p>

            <p class="label-value">
              {{ $t("5769") }}
            </p>
            <p class="present-value">{{ diff_shift }}</p>
          </div>
          <div class="col-0">
            <div class="trafficlight">
              <div
                class="light red"
                :class="{ active: machine.diff_qty_shift < 0 }"
              ></div>
              <div class="light yellow"></div>
              <div
                class="light green"
                :class="{ active: machine.diff_qty_shift >= 0 }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import { secondsToHhMm } from "@/helpers/dates";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
    };
  },
  mounted() {
    this.pulse = new Pulse({
      action: "machines/loadMachine",
      interval: 3,
      primaryKey: "machine_id",
      parameters: { machine_id: this.machine_id },
    });
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({ machineById: "machines/byId" }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    diff_shift() {
      return secondsToHhMm(this.machine.diff_time_shift);
    },
    classes() {
      let classes = {};
      let keys = ["oee_1h", "oee_8h", "tak_percent_24"];
      for (let i = 0; i < keys.length; i++) {
        let k = keys[i];
        let v = +this.machine[k];
        if (v >= +this.machine.oee_green_limit) {
          classes[k] = "status-green";
        } else if (v >= this.machine.oee_yellow_limit) {
          classes[k] = "status-yellow";
        } else {
          classes[k] = "status-red";
        }
      }
      return classes;
    },
    items() {
      return [];
    },
  },

  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
  },
};
</script>