<template>
  <div>
    <Loading v-if="loading" />
    <div v-else>
      <b-table
        :data="insertOptions"
        :columnConfig="columnConfig"
        primary-key="tool_id"
        :sort_dir="['asc']"
        :single-select="true"
        :searchable="true"
        searchfield="tool_id"
        :deselect="true"
        :initial-search-example="data.tool?.tool_id+'-'"
        @selectionChanged="onSelectionChanged"
      >
      </b-table>
      <button class="tabbed-middle-button" v-if="hasOld" @click="loadOld">
        <h6 class="middle-button-header">{{$t('1219')}}</h6>
          {{oldInsert.tool_id}}
      </button>
      <button
        class="tabbed-next-button"
        @click="$emit('next')"
        :disabled="disableNext"
        :class="{ disabled: disableNext }"
      >
        <i class="fas fa-chevron-right" v-if="!islast"></i>
        <i class="fas fa-check" v-if="islast"></i>
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      machine_id: this.$route.params.machine,
      columnConfig: {
        tool_id: {
          class: "h50",
        },
        tool_description: {
          class: "h50",
        },
      },
      insertOptions: [],
      selectedRows: [],
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    this.data[this.tab.dataKey].selected = null;
    axios
      .post("balthzar_get_tool_free_insert_16_1_75", {
        department: 1,
        strMachine_id: this.machine_id,
        strTool_id: this.tool.tool_id,
      })
      .then(({ data }) => {
        console.log(data);
        this.insertOptions = flattenMultiRow(data);
        this.loading = false;
      });
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
    }),
    ...mapGetters({ machineById: "machines/byId" }),
    machine() {
      return this.machineById(this.machine_id);
    },
    ordersForMachine() {
      return this.orders[this.machine_id] || null;
    },
    disableNext() {
      return this.data[this.tab.dataKey].selected === null;
    },
    tool() {
      return this.data.tool || {};
    },
    hasOld(){
      let old_insert_id = this.data.tool?.old_insert_id;
      return this.insertOptions.findIndex(o => o.tool_id == old_insert_id) >= 0;
    },
    oldInsert(){
      let old_insert_id = this.data.tool.old_insert_id;
      return this.insertOptions.find(o => o.tool_id == old_insert_id);
    }
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length > 0 && this.selectedRows[0]) {
        this.data[this.tab.dataKey].selected = this.selectedRows[0];
      } else {
        this.data[this.tab.dataKey].selected = null;
      }
    },
    loadOld(){
      this.data[this.tab.dataKey].selected = this.oldInsert;
      this.$emit("next");
    }
  },
  watch: {},
};
</script>