<template>
  <div>
    <span v-if="data.tool">
      {{$t('785')}}: {{data.tool.insert_location}}<br/>
    </span>
    <span v-if="data.cavity.selected && data.cavity.selected.tool_id">
      {{$t('4491')}}: {{data.cavity.selected.tool_id}}<br/>
    </span>
    <textarea name="note" id="" cols="30" rows="10" class="form-control" v-model="data[tab.dataKey].value" autofocus></textarea>
    <keyboard v-model="data[tab.dataKey].value" /> 
    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>
<script>
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  computed: {
    disableNext() {
      return (
        this.data[this.tab.dataKey].value === null ||
        this.data[this.tab.dataKey].value === ""
      );
    },
  },
};
</script>