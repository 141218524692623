<template>
  <div>
    <nav
      class="navbar main-nav navbar-dark bg-dark fixed-top"
      :class="showTopMenu === false ? 'no-top' : ''"
    >
      <div class="container-fluid">
        <router-link
          to="/machines/all"
          class="navbar-toggler"
          type="button"
          tag="button"
        >
          <img
            class=""
            src="@/assets/Balthzar.png"
            alt=""
            style="max-height: 40px"
          />
        </router-link>
        <div class="location-swap">
          <button
            class="btn"
            type="button"
            id="locationSwapButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div>
              {{ activeDB ? activeDB.title : $route.params.dashboard }}
            </div>
          </button>
          <ul class="dropdown-menu" aria-labelledby="locationSwapButton">
            <li v-for="db in availableDashboards" :key="db.key">
              <router-link :to="db.key" class="dropdown-item">{{
                db.title
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="dropdown">
          <button
            class="navbar-toggler"
            type="button"
            id="userSettingsMenu"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fal fa-ellipsis-v" style="color: white"></i>
          </button>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="userSettingsMenu"
          >
            <li>
              <h6 class="dropdown-header">zoom level {{ zoom }}%</h6>
            </li>
            <li class="dropdown-item">
              <input
                type="range"
                min="10"
                max="150"
                v-model="zoom"
                step="5"
                class="slider"
              />
            </li>
            <li>
              <a class="dropdown-item" href="/home" @click="logout"
                ><i class="fas fa-sign-out"></i> Logout</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="spacer" style="height: 44px"></div>

    <div
      class="container-xxxl dashboard"
      :class="'zoom-' + zoom + ' ' + (showTopMenu === false ? 'no-top' : '')"
    >
      <loading v-if="!loaded" />
      <slot v-if="loaded"></slot>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import { mapState, mapGetters } from "vuex";
import { loadLanguageFromCache } from "@/i18n";
import BottomButtons from "@/components/bottom-buttons";
import { tryFullScreen, tryCloseFullScreen } from "@/helpers/screen";
import store from "@/store";
import { watch, ref } from "vue";
export default {
  setup() {
    const route = useRoute();

    loadLanguageFromCache();
    const title = ref(route.meta.title);
    const description = ref("");
    store.dispatch("permissions/loadUnloadedPermissions");
    watch(
      () => route.path,
      () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
      }
    );
    return {
      title,
      description,
    };
  },
  mounted() {
    this.setZoomLevel(this.$route);
    store
      .dispatch("config/loadBaseConfig", { base_code: "dashboards" })
      .then(() => {
        let promises = [];
        promises.push(
          store.dispatch("config/loadBaseConfig", { base_code: "webcolumns" })
        );
        let keys = Object.keys(this.dashboardTemplates);
        keys.forEach((key) => {
          promises.push(
            store.dispatch("config/loadConfig", {
              system_function: "dashboards",
              template: key,
            })
          );
        });
        Promise.all(promises).then(() => {
          if (
            this.dashboardTemplates[this.$route.params.dashboard] === undefined
          ) {
            console.warn("should swap out!");
            if (this.availableDashboards.length !== 0) {
              this.$router.push(
                "/dashboards/" + this.availableDashboards[0].key
              );
            }
          }
          this.loaded = true;
        });
      });

    tryFullScreen();
  },
  beforeUnmount() {
    tryCloseFullScreen();
  },
  data() {
    return {
      loaded: false,
      zoom: 100,
    };
  },
  computed: {
    ...mapState({
      state: (state) => state,
      userLevel: (state) => state.auth.usertype,
      dashboardTemplates: (state) => state.config.templates.dashboards,
      dashboardConfigs: (state) => state.config.dashboards,
      screenWidth: (state) => state.screenWidth,
      company: (state) => state.auth.company_id,
    }),
    ...mapGetters({
      canUser: "permissions/canUser",
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    browserInFullScreen() {
      return !window.screenTop && !window.screenY;
    },
    dashboards() {
      return Object.keys(this.dashboardTemplates).map((key) => {
        return {
          ...(this.dashboardConfigs[key] || {}),
          key,
        };
      });
    },
    availableDashboards() {
      return this.dashboards.filter((db) => {
        return (
          db.display === true &&
          this.userLevel >= db.userlevel &&
          ((db.suitedForMobile && this.screenWidth <= 600) ||
            (db.suitedForTablet &&
              this.screenWidth <= 1000 &&
              this.screenWidth > 600) ||
            (db.suitedForDesktops &&
              this.screenWidth > 1000 &&
              this.screenWidth < 1600) ||
            (db.suitedForXXL && this.screenWidth >= 1600))
        );
      });
    },
    activeDB() {
      return this.dashboardConfigs[this.$route.params.dashboard];
    },
    showTopMenu() {
      return this.activeDB?.showBzxBanner;
    },
  },
  methods: {
    saveZoomLevel(val) {
      console.warn("saving zoom level..");
      localStorage.setItem(
        this.company + "_zoomlevel_" + this.$route.params.dashboard,
        val
      );
    },
    setZoomLevel(to) {
      console.warn("setting zoom level...");
      this.zoom =
        localStorage.getItem(
          this.company + "_zoomlevel_" + to.params.dashboard
        ) || 100;
    },
    logout() {
      store.dispatch("auth/logout");
    },
  },
  watch: {
    $route(to) {
      this.setZoomLevel(to);
    },
    zoom(val) {
      this.saveZoomLevel(val);
    },
  },
  components: {
    BottomButtons,
  },
};
</script>
<style lang="scss">
</style>