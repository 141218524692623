<template>
  <ul class="nav flex-column bg-secondary">
    <li class="nav-item" v-for="menuItem in sideMenu" :key="menuItem.id">
      <router-link
        :to="menuItem.to"
        class="nav-link"
        :class="{ disabled: menuItem.disabled }"
        v-if="!menuItem.dropdown && !menuItem.hasHandler"
      >
        <i v-if="menuItem.icon" :class="menuItem.icon"></i>
        {{ menuItem.tlabel ? $t(menuItem.tlabel) :menuItem.label }}
      </router-link>
      <a
        href="#"
        @click.prevent="menuItem.handler"
        class="nav-link"
        :class="{ disabled: menuItem.disabled }"
        v-if="!menuItem.dropdown && menuItem.hasHandler"
      >
        <i v-if="menuItem.icon" :class="menuItem.icon"></i>
        {{ menuItem.tlabel ? $t(menuItem.tlabel) :menuItem.label }}
      </a>
      <div class="accordion-item" v-if="menuItem.dropdown">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#sidemenu-' + menuItem.id"
            aria-expanded="false"
          >
            <i v-if="menuItem.icon" :class="menuItem.icon"></i>
        {{ menuItem.tlabel ? $t(menuItem.tlabel) :menuItem.label }}
          </button>
        </h2>
        <div
          :id="'sidemenu-' + menuItem.id"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingTwo"
        >
          <div class="accordion-body">
            <side-menu :side-menu="menuItem.items" :hidden-for-machine="hiddenForMachine"></side-menu>
           
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  props: ["sideMenu", "hiddenForMachine"],
};
</script>