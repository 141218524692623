import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";
import { getStorage, setStorage, checkStorage } from "../../helpers/storage";

const state = () => ({
    index: {}
});

// getters
const getters = {
    getSaved: (state) => (dataset) => {
        return state.index[dataset] || [];
    }
}

// actions
const actions = {
    loadConfigs({ commit, state, dispatch, rootState }, dataset) {
        if (dataset === undefined) {
            return true;
        }
        execute_json("get_user_settings", {
            user_name: rootState.auth.username,
            username: rootState.auth.username,
            system_function: "web_visual_analysis_" + dataset
        })
            .then(({ data }) => {
                console.warn("web_visual_analysis_", data);
                commit("setConfig", { data, dataset });
            })
            .catch((err) => {
                console.log(err);
            });
    },
    saveDefault({ commit, state, dispatch, rootState }, { dataset, name, config }) {
        return new Promise((resolve, reject) => {
            execute_json("save_user_setting", {
                user_name: "Default",
                username: "Default",
                system_function: "web_visual_analysis_" + dataset,
                function_variable: name,
                user_setting: JSON.stringify(config)
            })
                .then(() => {
                    dispatch("loadConfigs", dataset).then(() => {
                        resolve();
                    })
                }).catch((err) => { reject(err) })
        })
    },
    saveUser({ commit, state, dispatch, rootState }, { dataset, name, config }) {
        return new Promise((resolve, reject) => {
            execute_json("save_user_setting", {
                user_name: rootState.auth.username,
                username: rootState.auth.username,
                system_function: "web_visual_analysis_" + dataset,
                function_variable: name,
                user_setting: JSON.stringify(config)
            })
                .then(() => {
                    dispatch("loadConfigs", dataset).then(() => {
                        resolve();
                    })
                }).catch((err) => { reject(err) })
        })
    },
    deleteSetting({ dispatch }, { username, dataset, name }) {
        return new Promise((resolve, reject) => {
            execute_json("delete_user_setting", {
                user_name: username,
                system_function: "web_visual_analysis_" + dataset,
                function_variable: name,
            })
                .then(({ data }) => {
                    dispatch("loadConfigs", dataset).then(() => {
                        resolve();
                    })
                })
                .catch((err) => {
                    reject(err);
                })
        });
    }
}
// mutations
const mutations = {
    setConfig(state, { data, dataset }) {
        state.index[dataset] = data.map(d => {
            return {
                name: d.function_variable,
                username: d.username,
                config: JSON.parse(d.string_value)
            }
        });

    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}