//import { flattenMultiRow, flattenSingleRow } from "@/requests/requestParcer";
import _ from "lodash";

// functions
function barDataHandler(data) {
    return _.orderBy(
        data.filter((i) => i[this.current.valueKey] != "0"),
        [
            (item) => {
                return +item[this.current.valueKey];
            },
        ],
        ["desc"]
    );
}

function clampLineDataHandler(data) {
    return data;
}

function stackedBarDataHandler(data) {
    //bit clunky, but we need to get labels, and stacked bar isn't really compatible display wise with the other ones:
    //console.log("stackedBarDataHandler", data, Object.keys(data));
    return _.orderBy(Object.keys(data).map(label => {
        return {
            [this.current.labelKey]: label
        }
    }), [this.current.labelKey], ["asc"]);
}

function stackedBarDataSetFormatter() {
    //get all unique reasons, asign them colors, push in data points at label positions, label stopreasons

    const allPoints = [];
    this.labels.forEach((label) => {
        allPoints.push(...this.data[label]);
    })
    const stacks = [...new Set(allPoints.map(item => {
        return {
            "label": item[this.current.labelKey],
            "color": item[this.current.stackColorKey]
        }
    }))];
    const stackLabels = [...new Set(stacks.map(item => item.label))];
    const stackColors = stackLabels.map(l => stacks.find(s => s.label === l).color);

    const datasets = stackLabels.map((stack, i) => {
        let data = this.valuesForStack(stack);
        return {
            label: stack,
            borderWidth: 1,
            backgroundColor: stackColors[i] !== "Automatic" ? stackColors[i].toLowerCase() : "yellow",
            barPercentage: 1,
            data
        }
    });
    //loop over the different positions, get their sum and turn into percentages

    return datasets;
}

function stackedBarTooltipLabelFormatter(tooltipItem, data) {
    let hh = Math.floor(tooltipItem.yLabel / 3600);
    let mm = Math.floor((tooltipItem.yLabel % 3600) / 60);
    let hhmm = `${hh}h${mm}m`;
    return data.datasets[tooltipItem.datasetIndex].label !== null ?
        data.datasets[tooltipItem.datasetIndex].label + ": " + hhmm + ""
        : null;
}

function valuesForStack(stack) {
    let data = this.data;
    return this.labels.map(label => {
        let datapoint = data[label].find(d => {
            return d[this.current.stackKey] === stack;
        });
        if (datapoint === undefined) {
            return null;
        }
        return +datapoint[this.current.valueKey];
    })
}

function valuesForFilledStack(stack) {
    let data = this.data;
    return this.labels.map(label => {
        let datapoint = data.find(d => {
            return d[this.current.labelKey] === label &&
                d[this.current.stackKey] === stack;
        });
        if (datapoint === undefined) {
            return null;
        }
        return +datapoint[this.current.valueKey];
    })
}

function filledStackedBarDataHandler(data) {
    //bit clunky, but we need to get labels, and stacked bar isn't really compatible display wise with the other ones:
    const labelKey = this.current.labelKey;
    const unique = [...new Set(data.map(item => item[labelKey]))];
    //console.warn(unique);
    return unique.map(label => {
        let r = {};
        r[labelKey] = label;
        return r;
    })
}

function filledStackedBarDataSetFormatter() {
    //get all unique reasons, asign them colors, push in data points at label positions, label stopreasons
    const stacks = [...new Set(this.data.map(item => item[this.current.stackKey]))];
    const colors = [...new Set(this.data.map(item => item[this.current.colorKey]))];
    //const colors = ["blue", "red", "yellow", "purple"]
    const datasets = stacks.map((stack, i) => {
        let data = this.valuesForFilledStack(stack);
        //console.log(">",data,stack);
        return {
            label: stack,
            borderWidth: 1,
            backgroundColor: stack == 'runtime' ? 'green' : colors[i % 4],
            barPercentage: 1.7,
            stack: 1,
            data
        }
    });
    let greenLimits = [];
    let yellowLimits = [];
    let redLimits = [];
    //loop over the different positions, get their sum and turn into percentages
    this.labels.forEach((label, i) => {
        let labelValues = datasets.map(ds => ds.data[i] || 0);
        let labelSum = labelValues.reduce((a, b) => {
            return a + b;
        });
        let perLabel = 100 / labelSum;
        datasets.forEach((ds, di) => {
            ds.data[i] = Math.floor(ds.data[i] * perLabel * 100) / 100;
        })
        let m = this.machines.find(m => m.machine_id == label) || {};
        let greenLimit = +m.bar_green_limit || 90;
        let yellowLimit = +m.bar_yellow_limit || 50;
        greenLimits.push(100 - greenLimit);
        yellowLimits.push(greenLimit - yellowLimit);
        redLimits.push(yellowLimit);
    })
    datasets.push({
        label: null,
        showLabel: false,
        data: redLimits,
        backgroundColor: "red",
        barPercentage: 0.2,
        stack: 2
    })
    datasets.push({
        label: null,
        data: yellowLimits,
        backgroundColor: "yellow",
        barPercentage: 0.2,
        stack: 2
    })
    datasets.push({
        label: null,
        data: greenLimits,
        backgroundColor: "green",
        barPercentage: 0.2,
        stack: 2
    })
    return datasets;
}

function filledStackedBarTooltipLabelFormatter(tooltipItem, data) {
    return data.datasets[tooltipItem.datasetIndex].label !== null ?
        data.datasets[tooltipItem.datasetIndex].label + ": " + (tooltipItem.yLabel.toFixed(1)) + ""
        : null;
}

const typeFunctions = {
    clampLineDataHandler,
    barDataHandler,
    stackedBarDataHandler,
    stackedBarDataSetFormatter,
    stackedBarTooltipLabelFormatter,
    valuesForStack,
    filledStackedBarDataHandler,
    filledStackedBarDataSetFormatter,
    filledStackedBarTooltipLabelFormatter,
    valuesForFilledStack,
}

//computed

function barScalesHandler() {
    return {
        xAxes: [
            {
                ticks: {
                    callback: (value) => {
                        return this.fullscreen ? value : value.slice(0, 12);
                    },
                    fontColor:this.axisColor
                },
                gridLines:{
                    color:this.gridColor
                }
            },
        ],
        yAxes: [
            {
                type: "linear",
                scaleLabel: {
                    display: true,
                    labelString: this && this.$t ? this.$t("633") : '-',
                    fontColor:this.axisLabelColor,
                },
                ticks: {
                    stepSize: 3600,
                    maxTicksLimit: 15,
                    beginAtZero: true,
                    callback: (value) => {
                        return value / 3600;
                    },
                    fontColor:this.axisColor
                },
                 gridLines:{
                    color:this.gridColor
                }
            },
        ],
    };
}
function rawbarScalesHandler() {
    return {
        xAxes: [
            {
                ticks: {
                    callback: (value) => {
                        return this.fullscreen ? value : value.slice(0, 12);
                    },
                    fontColor:this.axisColor
                },
                gridLines:{
                    color:this.gridColor
                }
            },
        ],
        yAxes: [
            {
                type: "linear",
                scaleLabel: {
                    display: false,
                    fontColor:this.axisLabelColor,
                },
                ticks: {
                    beginAtZero: true,
                    fontColor:this.axisColor
                },
                 gridLines:{
                    color:this.gridColor
                }
            },
        ],
    };
}

function barDisplayDataLabels() {
    return this.fullscreen ? true : false;
}

function barLegend() {
    return {
        display: false,
    }
}


function stackedBarScalesHandler() {
    return {
        xAxes: [
            {
                stacked: true,
                ticks: {
                    callback: (value, index, values) => {
                        if (this.labelStyle === 'short') {
                            return value.slice(value.length - 2, value.length);
                        }
                        if (values.length > 25) {
                            //week mode!
                            if (index !== 0) {
                                //we're not first
                                if (value.slice(0, 10) === values[index - 1].slice(0, 10)) {
                                    return null;
                                } else {
                                    return value;
                                }
                            }
                        }
                        return this.fullscreen ? value : value.slice(0, 13);
                    },
                    autoSkip: false,
                    fontColor:this.axisColor
                },
                gridLines:{
                    color:this.gridColor
                }
            },
        ],
        yAxes: [
            {
                stacked: true,
                type: "linear",
                scaleLabel: {
                    display: this.labelStyle !== 'short',
                    labelString: this && this.$t ? this.$t("633") : '-',
                    fontColor:this.axisLabelColor,
                },
                ticks: {
                    stepSize: 3600,
                    maxTicksLimit: 15,
                    beginAtZero: true,
                    fontColor:this.axisColor,
                    callback: (value) => {
                        return value / 3600;
                    },
                },
                gridLines:{
                    color:this.gridColor
                }
            },
        ],
    };
}

function stackedBarLegend() {
    return {
        display: true,
        labels:{
            fontSize:this.fontSize || 12
        }
    }
}
function stackedBarDisplayDataLabels() {
    return false;
}

function filledStackedBarScalesHandler() {
    return {
        xAxes: [
            {
                stacked: true,
                ticks:{
                    fontColor:this.axisColor
                },
                gridLines:{
                    color:this.gridColor
                }

            },
        ],
        yAxes: [
            {
                stacked: true,
                type: "linear",
                scaleLabel: {
                    display: true,
                    labelString: this && this.$t ? this.$t("5095") : '-',
                    fontColor:this.axisLabelColor,
                },
                ticks: {
                    stepSize: 10,
                    beginAtZero: true,
                    suggestedMax: 100,fontColor:this.axisColor
                },
                gridLines:{
                    color:this.gridColor
                }
            },
        ],
    };
}


function filledStackedBarDisplayDataLabels() {
    return false;
}


function filledStackedBarLegend() {
    return {
        display: true,
        labels: {
            generateLabels: (chart) => {
                let data = chart.data;
                let formatter = this.valueFormatter;
                if (data.datasets.length) {
                    let totalSum = data.labels.length;
                    return data.datasets.filter(ds => ds.label !== null).map(function (ds, i) {

                        let custom = {};
                        let getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                        let arcOpts = chart.options.elements.arc;
                        let fill = custom.backgroundColor
                            ? custom.backgroundColor
                            : getValueAtIndexOrDefault(
                                ds.backgroundColor,
                                i,
                                arcOpts.backgroundColor
                            );
                        let stroke = custom.borderColor
                            ? custom.borderColor
                            : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                        let bw = custom.borderWidth
                            ? custom.borderWidth
                            : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);


                        // We get the value of the current label
                        let value = ds.data.reduce((a, b) => {
                            return (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b)
                        }) / totalSum;
                        let formatted = "";
                        return {
                            // Instead of `text: label,`
                            // We add the value to the string
                            text: value.toFixed(1) + "% " + ds.label,
                            fillStyle: fill,
                            strokeStyle: stroke,
                            lineWidth: bw,
                            hidden: value === 0,
                            display: false,
                            index: i,
                        };
                    });
                } else {
                    return [];
                }
            },
            fontSize:this.fontSize || 12
        }
    }
}
function lineLegend() {
    return {
        display: false,
    }
}
function clampLineLegend() {
    return {
        display: false,
    }
}
function clampLineScalesHandler() {
    return {
        xAxes: [
            {
                ticks: {
                    callback: (value, index, values) => {
                        if (this.labelStyle === 'short') {
                            return value.slice(value.length - 2, value.length);
                        }
                        if (values.length > 25) {
                            //week mode!
                            if (index !== 0) {
                                //we're not first
                                if (value.slice(0, 10) === values[index - 1].slice(0, 10)) {
                                    return null;
                                } else {
                                    return value;
                                }
                            }
                        }
                        return this.fullscreen ? value : value.slice(0, 13);
                    },
                    autoSkip: false,
                    fontColor:this.axisColor
                },
                gridLines:{
                    color:this.gridColor
                }
            },
        ],
        yAxes: [
            {
                type: "linear",
                scaleLabel: {
                    display: false,
                    labelString: null,
                    fontColor:this.axisLabelColor,
                },
                ticks: {
                    maxTicksLimit: 20,
                    stepSize: 10,
                    beginAtZero: true,
                    suggestedMax: 100,
                    fontColor:this.axisColor
                },
                gridLines:{
                    color:this.gridColor
                }
            },
        ],
    };
}

function lineScalesHandler() {
    return {
        xAxes: [
            {
                ticks: {
                    callback: (value, index, values) => {
                        if (this.labelStyle === 'short') {
                            return value.slice(value.length - 2, value.length);
                        }
                        if (values.length > 25) {
                            //week mode!
                            if (index !== 0) {
                                //we're not first
                                if (value.slice(0, 10) === values[index - 1].slice(0, 10)) {
                                    return null;
                                } else {
                                    return value;
                                }
                            }
                        }
                        return this.fullscreen ? value : value.slice(0, 13);
                    },
                    autoSkip: false,
                    fontColor:this.axisColor
                },gridLines:{
                    color:this.gridColor
                }
            },
        ],
        yAxes: [
            {
                type: "linear",
                scaleLabel: {
                    display: false,
                    labelString: null,
                    fontColor:this.axisLabelColor,
                },
                ticks: {
                    maxTicksLimit: 20,
                    stepSize: 10,
                    beginAtZero: true,
                    suggestedMax: 100,
                    fontColor:this.axisColor
                },
                gridLines:{
                    color:this.gridColor
                }
            },
        ],
    };
}

function doughnutOffsetDataLabels() {
    return "0"
}

function doughnutAlignDataLabels() {
    return "center"
}

function availabilityclampLineColoring() {
    return {
        pointBackgroundColor: this.colors,
        lineTension: 0,
        greenLimit: this.machine ? +this.machine.bar_green_limit : '',
        yellowLimit: this.machine ? +this.machine.bar_yellow_limit : ''
    }
}
function availability_locationclampLineColoring() {
    return {
        pointBackgroundColor: this.colors,
        lineTension: 0,
        greenLimit: 90,
        yellowLimit: 50
    }
}

function availability_locationlineColoring() {
    return {
        pointBackgroundColor: "black",
        borderColor: "rgba(0,0,0,0.4)",
        borderWidth: "1",
        lineTension: 0,
        greenLimit: 90,
        yellowLimit: 50
    }
}

function clampLineDisplayDataLabels() {
    if (this.labelStyle === 'short') {
        return false;
    }
    return "auto";
}

function clampLineOffsetDataLabels() {
    return "6"
}



const typeComputed = {
    availabilityclampLineColoring,
    availability_locationclampLineColoring,
    availability_locationlineColoring,
    barScalesHandler,
    rawbarScalesHandler,
    barDisplayDataLabels,
    barLegend,
    stackedBarDisplayDataLabels,
    stackedBarLegend,
    stackedBarScalesHandler,
    filledStackedBarDisplayDataLabels,
    filledStackedBarLegend,
    filledStackedBarScalesHandler,
    clampLineLegend,
    clampLineScalesHandler,
    clampLineOffsetDataLabels,
    clampLineDisplayDataLabels,
    lineLegend,
    lineScalesHandler,
    doughnutOffsetDataLabels,
    doughnutAlignDataLabels
};

export { typeFunctions, typeComputed };