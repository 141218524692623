<template>
  <div class="b-multiselect-wrapper">
    <h6
      v-if="label != null"
      class="b-multiselect-header"
      :class="{ disabled: enabled === false }"
    >
      {{ label }}
      <!--
      <button
        v-if="enabled !== null"
        class="enable-toggle"
        @click="toggleEnable"
        :class="{ checked: enabled }"
      >
        <i class="fas fa-check" v-if="enabled"></i>
        <i class="fas fa-horizontal-rule" v-else></i>
      </button>
      -->
      <span class="b-slide pull-right">
        <label class="switch">
          <input type="checkbox" :checked="enabled" @input="toggleEnable" />
          <span class="slider round"></span> </label
      ></span>
    </h6>
    <div class="b-multiselect" :class="{ disabled: enabled === false }">
      <button
        class="b-multiselect-option"
        v-for="option in options"
        :key="option"
        @click="toggle(option, $event)"
        :class="{ selected: this.modelValue.includes(option) }"
      >
        {{ labels !== null ? labels[option] || option : option }}&nbsp;
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      required: true,
    },
    options: {
      required: true,
    },
    labels: {
      default: null,
    },
    //enabled/label
    enabled: {
      required: false,
      default: null,
    },
    label: {
      required: false,
      default: null,
    },
    single: {
      default: false,
    },
    deselect: {
      default: true,
    },
  },
  methods: {
    toggle(option, e) {
      let selected = this.modelValue;
      if (this.single) {
        if (this.deselect && selected.includes(option)) {
          this.$emit("update:modelValue", null);
        } else {
          this.$emit("update:modelValue", [option]);
          if (this.enabled !== null) {
            this.$emit("update:enabled", true);
          }
        }
        return true;
      }
      console.log(e);
      if (e.altKey) {
        selected = [...this.options];
      } else if (e.ctrlKey) {
        selected = [option];
      } else if (!selected.includes(option)) {
        selected.push(option);
      } else {
        selected = selected.filter((i) => i != option);
      }
      this.$emit("update:modelValue", selected);
      if (selected.length > 0 && this.enabled !== null) {
        this.$emit("update:enabled", true);
      }
      if (selected.length === this.options.length && this.enabled !== null) {
        this.$emit("update:enabled", false);
      }
    },
    toggleEnable() {
      this.$emit("update:enabled", !this.enabled);
    },
  },
};
</script>

<style>
</style>