<template>
  <div
    class="interface-navigation d-flex justify-content-between"
    @mousedown="potentialSwipe"
    @touchstart="potentialSwipe"
  >
    <button
      class="btn align-left fixed-width"
      @click="goTo(prev_machine)"
      :disabled="prev_machine == null"
      v-if="forceMachineNav"
    >
      <i class="far fa-chevron-left"></i> {{ prev_machine?.machine_id }}
    </button>
    <button
      class="btn align-left fixed-width"
      v-if="!forceMachineNav"
      @mousedown.prevent.stop="$emit('swipeleft')"
    >
      <i class="far fa-chevron-left"></i>
    </button>

    <div class="line-buttons" v-if="!hideLineNumbers && lineNo !== null">
      <button
        class="btn"
        @click="goTo(m)"
        v-for="m in lineMachines"
        :key="m.machine_id"
        :class="{ current: m.machine_id === machine_id }"
      >
        {{ m.machine_id }}
      </button>
    </div>

    <button
      class="btn align-right fixed-width"
      @click="goTo(next_machine)"
      :disabled="next_machine === null"
      v-if="forceMachineNav"
    >
      {{ next_machine?.machine_id }}
      <i class="far fa-chevron-right"></i>
    </button>

     <button
      class="btn align-right fixed-width"
      v-if="!forceMachineNav"
      @mousedown.prevent.stop="$emit('swiperight')"
    >
      <i class="far fa-chevron-right"></i>
    </button>
  </div>
</template>
<script>
import _ from "lodash";
import { mapState, mapGetters } from "vuex";

export default {
  props: {},
  emits: ["swiperight", "swipeleft"],
  data() {
    return {
      touchDown: null,
      touchUp: null,
      swipeHandler: null,
    };
  },
  mounted() {},
  beforeUnmount() {
    this.removeSwipeHandler();
  },
  computed: {
    ...mapState({
      //machines: (state) => state.machines.index,
      lastLoc: (state) => state.sidebar.lastLocation,
      lastLocType: (state) => state.sidebar.lastLocationType,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machines: "machines/nonResourceMachines",
      machineHasSf: "machines/machineHasSf",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    hideLineNumbers() {
      return this.machineHasSf(this.machine_id, "364");
    },
    forceMachineNav() {
      return this.machineHasSf(this.machine_id, "365");
    },
    sorted_machines() {
      return _.sortBy([...this.filteredMachines], "machine_id");
    },
    machine_index() {
      return this.sorted_machines.findIndex(
        (i) => i.machine_id === this.machine_id
      );
    },
    prev_machine() {
      if (this.machine_index <= 0) {
        return null;
      } else {
        return this.sorted_machines[this.machine_index - 1];
      }
    },
    next_machine() {
      if (
        this.machine_index === -1 ||
        this.machine_index === this.sorted_machines.length - 1
      ) {
        return null;
      } else {
        return this.sorted_machines[this.machine_index + 1];
      }
    },
    filteredMachines() {
      let locationtype = this.lastLocType;
      let location = this.lastLoc;
      return _.filter(this.machines, (m) => {
        return (
          location === null ||
          locationtype === null ||
          m[locationtype] == location
        );
      });
    },
    lineNo() {
      return this.machine.line_no !== "-" ? this.machine.line_no : null;
    },
    lineMachines() {
      return _.sortBy(
        [...this.machines.filter((m) => m.line_no === this.lineNo)],
        "line_position"
      );
    },
  },
  watch: {},
  methods: {
    goTo(machine) {
      if (machine !== null) {
        this.$router.push("/interface/" + machine.machine_id);
      }
    },
    potentialSwipe(e) {
      this.touchDown = e;
      console.log("TOUCHED DOWN", e);
      this.swipeHandler = document.addEventListener(
        "mousemove",
        this.ignoreMove
      );
      this.swipeHandler = document.addEventListener(
        "touchmove",
        this.trackMove
      );
      this.swipeHandler = document.addEventListener(
        "touchend",
        this.potentialSwipeFinish
      );
      this.swipeHandler = document.addEventListener(
        "mouseup",
        this.potentialSwipeFinish
      );
    },
    potentialSwipeFinish(e) {
      console.log("DID WE SWIPE?", e, this.touchDown);
      let dX = e.clientX - this.touchDown.clientX;
      if (e.type === "touchend") {
        dX =
          this.touchUp?.touches[0]?.clientX -
          this.touchDown?.touches[0]?.clientX;
      }
      if (dX > 100) {
        this.$emit("swiperight");
      } else if (dX < 100) {
        this.$emit("swipeleft");
      }
      this.removeSwipeHandler();
    },
    ignoreMove(e) {
      console.log(e.type);
      e.preventDefault();
      e.stopPropagation();
      return false;
    },
    trackMove(e) {
      this.touchUp = e;
      this.ignoreMove();
    },
    removeSwipeHandler() {
      this.touchDown = null;
      this.touchUp = null;
      document.removeEventListener("mousemove", this.ignoreMove);
      document.removeEventListener("touchmove", this.trackMove);
      document.removeEventListener("mouseup", this.potentialSwipeFinish);
      document.removeEventListener("touchend", this.potentialSwipeFinish);
    },
  },
  components: {},
};
</script>
<style lang="scss">
.line-buttons {
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow-x: auto;
  @media screen and (min-width: 600px) {
    justify-content: center;
  }
  .btn {
  }
  .current {
    border: 1px solid black;
  }
}
</style>