<template>
  <div>
    <h2 class="content-block">
      {{$t('1189')}}
    </h2>
    <table class="table table-striped">
      <tr>
        <td>{{$t('1594')}}:</td><td>{{data.article.selected.part_no}}</td>
      </tr>
      <tr>
        <td>{{$t('394')}}:</td><td>{{data.article.selected.part_description}}</td>
      </tr>
      
      <tr v-if="data.order_nr.value !== null">
        <td>{{$t('5421')}}:</td><td>{{data.order_nr.value}}</td>
      </tr>
      <tr>
        <td>{{$t('1284')}}:</td><td>{{data.order_amount.value}}</td>
      </tr>
      
      <tr>
        <td>{{$t('816')}}:</td><td>{{data.cycle_amount.value !== null ? data.cycle_amount.value : data.article.selected.quantity_per_cycle}}</td>
      </tr>
      <tr>
        <td>{{$t('603')}}:</td><td>{{data.article.selected.op_no}}</td>
      </tr>
      <tr>
        <td>{{$t('814')}}:</td><td>{{data.article.selected.op_name}}</td>
      </tr>
      <tr>
        <td>{{$t('2587')}}:</td><td>{{data.article.selected.planned_setup_time}}</td>
      </tr>
      <tr>
        <td>{{$t('966')}}:</td><td>{{data.article.selected.planned_unit_time}}</td>
      </tr>
      <tr>
        <td>{{$t('967')}}:</td><td>{{data.article.selected.planned_speed}}</td>
      </tr>
      <tr>
        <td>{{$t('817')}}:</td><td>{{data.article.selected.capacity_factor}}</td>
      </tr>
    </table>
    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>
<script>
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  computed: {
    disableNext() {
      return false;
    },
  },
};
</script>