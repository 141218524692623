import axios from 'axios';
import { extractJson } from './requestParcer';
import { Notification } from '@/helpers/notifications';
var xml2js = require('xml2js');
var parser = new xml2js.Parser({ normalizeTags: true });

//proxy work around, live version runs on https and can't run without the proxy!!
const useProxy = false; //process.env.NODE_ENV === 'production' ? true : false;
const isProduction = process.env.NODE_ENV === 'production' && !window.Cypress;

import store from '@/store';
import _ from "lodash";
//import { i18n } from "@/i18n.js";

const instance = axios.create({
    //baseURL: isProduction ? "/localws/service1.asmx" : "http://web.balthzar.com/localws/service1.asmx", 
    baseURL: isProduction ? "/localws/service1.asmx" : "https://dev.bzx21.com/localws/service1.asmx",
    headers: {
        "Content-Type": "application/x-www-form-urlencoded;"
    },
})

if (localStorage.getItem("debugMode")) {
    window.debugMode = true;
}

window.debug = function () {
    if (window.debugMode) {
        window.debugMode = false;
        localStorage.removeItem("debugMode");
        return "debug mode disabled";
    } else {
        window.debugMode = true;
        localStorage.setItem("debugMode", "true");
        return "debug mode enabled";
    }
}

instance.interceptors.request.use(request => {
    //console.log(request);
    if (request.data) {
        if (request.data.json_data) {
            request._json = request.data.json_data;
            request.data.json_data = JSON.stringify(request.data.json_data);
            if (process.env.NODE_ENV === 'development' || window.debugMode) {
                request.url = request.url + "?" + request.data.function_name;
            }
            request._fn = request.data.function_name;
        }
        if (request.data.axiosflag) {
            request.axiosflag = request.data.axiosflag;
        }
        if (request.data.axioskey) {
            request.axioskey = request.data.axioskey;
        }
        if (store.state.auth && store.state.auth.company_id) {
            request.data.company_id = store.state.auth.company_id;
            request.data.strCompany_id = store.state.auth.company_id;
            request.data.company_key = store.state.auth.company_key;
            request.data.strCompany_key = store.state.auth.company_key;
            request.data.session_id = store.state.auth.session_id;
        } else {
            request.data.company_id = "";
            request.data.company_key = "";
            request.data.session_id = "42";
        }
        // Change request data to query string.
        request.data = new URLSearchParams(request.data).toString();
    }
    return request;
}, error => {
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {

    if (response.config.url.includes("balthzar_execute_json_out")) {
        let callStatus = response.data.call_status[0];
        if (response.config.multitable) {
            console.warn("multitable!");
        } else {
            response.data = response.data.Table1;
        }
        response = tempJsonOutFix(response, callStatus);
        //console.warn("json_out",response);
        return response;
    }
    if (response.config.url.includes(".json")) {
        return response;
    }
    extraLog(response);
    // Parse response data to json.
    let parsed = {};
    parser.parseString(response.data, function (err, result) {
        parsed = extractJson(result, response.config);
        //console.log("response parsed", result);
    });
    response.data = parsed;
    //console.warn("parsed!",parsed);
    let callCode = checkForCallCode(response);
    //console.warn(callCode);
    // filter out bad 200s
    if (parsed && parsed[0] && parsed[0].result && parsed[0].result[0]) {
        response.status = 403;
        response.statusText = "Err";
        return Promise.reject(response);
    }
    if (parsed && parsed.string && parsed.string._ == "Failed") {
        response.status = 403;
        response.statusText = "Err";
        return Promise.reject(response);
    }
    if (callCode.status !== 200) {
        response.status = callCode.status;
        response.statusText = callCode.statusText;
        response.call_code = callCode.call_code;
        //console.warn("rejecting!");
        return Promise.reject(response);
    }

    //old handler, should remove
    if (parsed && parsed[0] && parsed[0].call_message && parsed[0].call_message.length) {
        let call_message = parsed[0].call_message[0];
        //console.warn(call_message);
        if (call_message == "error") {
            response.status = 500;
            response.statusText = "error";
            //add usable status text;
            console.error(call_message, response);
            return Promise.reject(response);
        }
    }

    //console.log("data parsed",parsed);
    return response;
}, error => {
    console.warn("global logging:", error.response, error.response?.config?._fn, error.response?.config?._json)
    let callStatus = error?.response?.data?.call_status?.[0];
    if (callStatus) {
        error.call_code = callStatus.call_code;
        error.statusText = callStatus.call_message;
    }
    if (!error.response.config?.disableErrorNotification) {
        let callMessage = (callStatus || {}).call_message;
        Notification.failed(callMessage, {
            header: "Request failed" + (error.response?.config?._fn ? ": " + error.response?.config?._fn : ''),
            tmessage: (callStatus || {}).call_message_translation
        });
    }
    if (callStatus?.call_message === "Invalid company_id and company_key pair.") {
        store.dispatch("auth/logout").then(
            () => {
                Notification.request({ message: "Automatic logout..." })
                new Promise(resolve => setTimeout(resolve, 1500)).then(() => { location.reload(); })
            }
        );
    }
    return Promise.reject(error);
});

function extraLog(response) {
    //console.warn(response);
    if (response.config.url === "balthzar_execute_json") {
    }
    if (response.config.url === "balthzar_get_graphical_history_data_for_machines") {
        // console.warn(response, response.config.axiosflag);
    }
}

function tempJsonOutFix(response, status) {
    //console.warn(status);
    if (Array.isArray(response.data)) {
        response.data.forEach(row => {
            row = cleanInputRow(row);
        });
    }
    return response;
}

function cleanInputRow(row) {
    let keys = Object.keys(row);
    keys.forEach((k) => {
        if (typeof row[k] === "string") {
            row[k] = row[k].trim(" ");
        }
        switch (row[k]) {
            case "on":
            case "yes":
                row[k] = true;
                break;
            case "off":
            case "no":
                row[k] = false;
                break;
            case "@!":
                if (k === 'note') row[k] = '';
                break;

        }
    });
    return row;
}

function checkForCallCode(response) {
    if (response.config.url.includes("balthzar_execute_json")) {
        //console.warn(response.data);
        if (response.data[0].call_code) {
            //
            let call_code = response.data[0].call_code[0];
            //console.warn(call_code);
            if (call_code !== "0") {
                let call_message = response.data[0].call_message[0];
                return { status: 500, statusText: call_code + ":" + call_message }
            }
        }
        return { status: 200, statusText: "ok" }
    }
    return { status: 200, statusText: "ok" }
}
export default instance;