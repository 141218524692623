<template>
  <canvas
    :id="'chart-' + cid"
    :key="cid"
    :class="{ loading }"
    style="width: 100%; height: 100%"
  ></canvas>
</template>
<script>
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import chartTrendline from "chartjs-plugin-trendline";
import { linePlugin } from "@/helpers/chartPlugins";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";

Chart.plugins.register(chartTrendline);
Chart.plugins.register(linePlugin);

export default {
  emits: ["remount", "clickOnGraph"],
  props: {
    data: {
      required: true,
    },
    yKeys: {
      required: true,
    },
    colors: {},
    type: {
      default: "bar",
    },
    stacked: {
      default: false,
    },
    colorKey: {
      default: null,
    },
    labelKey: {
      default: null,
    },
    displayLegend: {
      default: true,
    },
    displayLabels: {
      default: true,
    },
    animateUpdates: {
      default: true,
    },
    legendPosition: {
      default: "top",
    },
    redrawIfChanged: {
      default: null,
    },
    yLabelStyle: {
      default: null,
    },
    precision: {
      default: 1,
    },
    redrawDelay: {
      default: 300,
    },
    labelsOverwrite: {
      default() {
        return {};
      },
    },
    addLabelsToTooltip: {
      default: false,
    },
    beginAtZero: {
      default: false,
    },
    shouldSortData: {
      default: false,
    },
    transparentBackgroundLegend: {
      default: false,
    },
    pointBorderColor: {
      default: "rgba(0, 0, 0, 0.1)",
    },
    showPoints: {
      default: true,
    },
    lineColor: {
      default: "rgba(0, 0, 0, 0.1)",
    },
    borderColor: {
      default: "white",
    },
    yMin: {
      default: 0,
    },
    yMax: {
      default: 100,
    },
    enableTrendline: {
      default: false,
    },
    showLimits:{
      default:false
    },
    upperLimitLine:{
      default:null,
    },
    lowerLimitLine:{
      default:null,
    }

  },
  data() {
    return {
      cid: _.uniqueId("graph"),
      ctx: null,
      chart: null,
      fullscreen: false,
      drawn: false,
      loading: false,
    };
  },
  mounted() {
    this.drawn = false;
    this.ctx = document.getElementById("chart-" + this.cid);
    this.drawChart();
  },
  beforeUnmount() {},
  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    drawChart() {
      if (!this.drawn) {
        this.draw();
      } else {
        this.reDraw();
      }
    },
    draw() {
      this.chart = new Chart(this.ctx, {
        //plugins: [ChartDataLabels],
        type: this.type,
        data: {
          labels: this.labels,
          datasets: this.datasets,
        },
        options: this.options,
        lineLimits:this.lineLimits
      });
      this.drawn = true;
    },
    reDraw() {
      this.chart.type = this.type;
      this.chart.data.labels = this.labels;
      if (this.onlyValuesChange(this.chart.data.datasets, this.datasets)) {
        for (let i = 0; i < this.datasets.length; i++) {
          this.chart.data.datasets[i].data = this.datasets[i].data;
        }
      } else {
        this.chart.data.datasets = this.datasets;
      }
      this.chart.options = this.options;
      this.chart.lineLimits = this.lineLimits;
      if (this.animateUpdates) {
        this.chart.update();
      } else {
        this.chart.update({
          duration: 0,
        });
      }
    },
    destroyAndRenew() {
      //prevent multiple calls in a row
      if (this.chart) {
        this.chart.destroy();
        this.drawn = false;
        let monitors = this.$el
          .closest("div")
          .querySelectorAll(".chartjs-size-monitor");
        if (monitors) {
          monitors.forEach((e) => e.remove());
        }
        let canvas = document.getElementById("chart-" + this.cid);
        canvas.removeAttribute("height");
        canvas.removeAttribute("width");
        canvas.removeAttribute("style");
        console.warn("destroyed that graph...", monitors);
      }
      this.drawChart();
    },
    onlyValuesChange(set1, set2) {
      //if (this.type === 'pie') console.warn(set1, set2);
      if (set1.length !== set2.length) {
        return false;
      }

      for (let i = 0; i < set2.length; i++) {
        if (
          !set1[i] ||
          set1[i].label !== set2[i].label ||
          (set1[i].backgroundColor.length === undefined &&
            set1[i].backgroundColor !== set2[i].backgroundColor) ||
          (set1[i].backgroundColor.length &&
            set1[i].backgroundColor.length !==
              set2[i].backgroundColor.length) ||
          set1[i].data.length !== set2[i].data.length
        ) {
          return false;
        }
      }
      return true;
    },
    defaultLabels(chart) {
      let data = chart.data;
      let formattedValue = this.formattedValue;
      let labelsOverwrite = this.labelsOverwrite;
      let yLabelStyle = this.yLabelStyle;
      if (data.labels.length && data.datasets.length) {
        return data.labels.map(function (label, i) {
          let meta = chart.getDatasetMeta(0);
          let ds = data.datasets[0];
          let arc = meta.data[i];
          let custom = (arc && arc.custom) || {};
          let getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
          let arcOpts = chart.options.elements.arc;
          let fill = custom.backgroundColor
            ? custom.backgroundColor
            : getValueAtIndexOrDefault(
                ds.backgroundColor,
                i,
                arcOpts.backgroundColor
              );
          let stroke = custom.borderColor
            ? custom.borderColor
            : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
          let bw = custom.borderWidth
            ? custom.borderWidth
            : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

          // We get the value of the current label
          let value =
            chart.config.data.datasets[arc._datasetIndex].data[arc._index];

          let formatted = formattedValue(value);
          return {
            // Instead of `text: label,`
            // We add the value to the string
            text:
              formatted +
              (yLabelStyle === "percentage"
                ? "% | "
                : yLabelStyle === "hours"
                ? "h | "
                : " | ") +
              (labelsOverwrite[label] || label),
            color: "white",
            fillStyle: fill,
            strokeStyle: stroke,
            lineWidth: bw,
            hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
            index: i,
          };
        });
      } else {
        return [];
      }
    },
    stackedLabels(chart) {
      let data = chart.data;
      let formattedValue = this.formattedValue;
      let labelsOverwrite = this.labelsOverwrite;
      let yLabelStyle = this.yLabelStyle;
      if (data.datasets.length) {
        let totalSum = data.labels.length;
        return data.datasets
          .filter((ds) => ds.label !== null)
          .map(function (ds, i) {
            let custom = {};
            let getValueAtIndexOrDefault =
              Chart.helpers.getValueAtIndexOrDefault;
            let arcOpts = chart.options.elements.arc;
            let fill = custom.backgroundColor
              ? custom.backgroundColor
              : getValueAtIndexOrDefault(
                  ds.backgroundColor,
                  i,
                  arcOpts.backgroundColor
                );
            let stroke = custom.borderColor
              ? custom.borderColor
              : getValueAtIndexOrDefault(
                  ds.borderColor,
                  i,
                  arcOpts.borderColor
                );
            let bw = custom.borderWidth
              ? custom.borderWidth
              : getValueAtIndexOrDefault(
                  ds.borderWidth,
                  i,
                  arcOpts.borderWidth
                );

            // We get the value of the current label
            let value =
              ds.data.reduce((a, b) => {
                return (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b);
              }, 0) / totalSum;
            let formatted = "";
            return {
              // Instead of `text: label,`
              // We add the value to the string
              text:
                formattedValue(value) +
                (yLabelStyle === "percentage" ? "% " : "") +
                (labelsOverwrite[ds.label] || ds.label),
              fillStyle: fill,
              strokeStyle: stroke,
              lineWidth: bw,
              hidden: value === 0,
              display: false,
              index: i,
            };
          });
      } else {
        return [];
      }
    },
    barLabels(chart) {
      let data = chart.data;
      let formattedValue = this.formattedValue;
      let labelsOverwrite = this.labelsOverwrite;
      let transparentBackground = this.transparentBackgroundLegend;
      let yLabelStyle = this.yLabelStyle;
      if (data.datasets.length) {
        let totalSum = data.labels.length;
        return data.datasets
          .filter((ds) => ds.label !== null)
          .map(function (ds, i) {
            let custom = {
              backgroundColor: transparentBackground ? "transparent" : null,
            };
            let getValueAtIndexOrDefault =
              Chart.helpers.getValueAtIndexOrDefault;
            let arcOpts = chart.options.elements.arc;
            let fill = custom.backgroundColor
              ? custom.backgroundColor
              : getValueAtIndexOrDefault(
                  ds.backgroundColor,
                  i,
                  arcOpts.backgroundColor
                );
            let stroke = custom.borderColor
              ? custom.borderColor
              : getValueAtIndexOrDefault(
                  ds.borderColor,
                  i,
                  arcOpts.borderColor
                );
            let bw = custom.borderWidth
              ? custom.borderWidth
              : getValueAtIndexOrDefault(
                  ds.borderWidth,
                  i,
                  arcOpts.borderWidth
                );

            // We get the value of the current label
            let value = ds.data.reduce((a, b) => {
              return (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b);
            }, 0);
            let formatted = "";
            return {
              // Instead of `text: label,`
              // We add the value to the string
              text:
                formattedValue(value) +
                (yLabelStyle === "percentage"
                  ? "% "
                  : yLabelStyle === "hours"
                  ? "h "
                  : " | ") +
                (labelsOverwrite[ds.label] || ds.label),
              fillStyle: fill,
              strokeStyle: stroke,
              lineWidth: bw,
              hidden: value === 0,
              display: false,
              index: i,
            };
          });
      } else {
        return [];
      }
    },
    formattedValue(value) {
      let format =
        this.yLabelStyle === null
          ? 1
          : this.yLabelStyle == "hours"
          ? 1 / 3600
          : 100;
      let v = Math.round(value * format * this.precision) / this.precision;
      return isNaN(v) ? "-" : v;
    },
    labelFormatter(tooltipItem, data) {
      let val = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
      let label = this.addLabelsToTooltip ? this.labels[tooltipItem.index] : "";
      return (
        this.formattedValue(val * 10) / 10 +
        (this.yLabelStyle === "percentage"
          ? "%"
          : this.yLabelStyle === "hours"
          ? "h"
          : "") +
        (" " + label || "")
      );
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
    }),
    lineLimits(){
      if (this.showLimits){
        return {
          horizontal:[
            {color:'#ff0000',y:this.lowerLimitLine},
            {color:'#ff0000',y:this.upperLimitLine},
          ],
          vertical:[

          ]
        }
      }
      return null;
    },
    options() {
      let vm = this;
      let labels = {};
      if (this.stacked) {
        labels.generateLabels = this.stackedLabels;
      } else if (this.type == "bar") {
        labels.generateLabels = this.barLabels;
      } else {
        labels.generateLabels = this.defaultLabels;
      }
      let yticks = {
        display: this.displayLabels,
        beginAtZero: this.beginAtZero,
      };
      if (this.yMin && !isNaN(this.yMin)) {
        yticks.min = this.yMin;
      }
      if (this.yMax && !isNaN(this.yMax)) {
        yticks.max = this.yMax;
      }
      return {
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            //anchor:"center",
            color: "#000000",
          },
        },
        sort: this.shouldSortData,
        animation: {
          duration: 600,
          easing: "linear",
        },
        scales:
          this.type == "bar"
            ? {
                //bar graph
                xAxes: [
                  {
                    stacked: this.stacked, // this should be set to make the bars stacked,
                    ticks: {
                      display: this.displayLabels,
                    },
                  },
                ],
                yAxes: [
                  {
                    stacked: this.stacked, // this also..
                    ticks: {
                      beginAtZero: this.beginAtZero,
                      display: this.displayLabels,
                      callback: (value) => {
                        return this.formattedValue(value);
                      },
                    },
                  },
                ],
              }
            : this.type == "scatter"
            ? {
                //non-bar graph
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: this.beginAtZero,
                      display: this.displayLabels,
                      min: 0,
                    },
                    gridLines: {
                      drawOnChartArea: false,
                      color: "rgba(0, 0, 0, 0)",
                      drawBorder: false,
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: yticks,
                    gridLines: {
                      drawOnChartArea: false,
                      color: "rgba(0, 0, 0, 0)",
                      drawBorder: true,
                      display: false,
                    },
                  },
                ],
              }
            : {},
        responsive: true,
        legend: {
          display: this.displayLegend,
          position: this.legendPosition,
          labels,
        },
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          //mode:"label",
          callbacks: {
            label: this.labelFormatter,
          },
        },
        onResize: function (chart, size) {
          console.warn("saw a resize");
          chart.update();
        },
        onClick: function (event, active) {
          //console.warn(event, active, this, this.chart.data);
          vm.$emit("clickOnGraph", {
            clicked: active,
            data: this.chart.data,
            chart: this,
            e: event,
          });
        },
      };
    },
    orderedData() {
      if (this.shouldSortData && this.yKeys.length === 1) {
        return _.orderBy(
          this.data,
          [
            (item) => {
              return +item[this.yKeys[0]];
            },
          ],
          ["desc"]
        );
      } else {
        return this.data;
      }
    },
    labels() {
      let labels = [];
      if (this.labelKey) {
        _.each(this.orderedData, (v, k) => {
          labels.push(v[this.labelKey]);
        });
      } else {
        _.each(this.orderedData, (v, k) => {
          labels.push(k);
        });
      }
      return labels;
    },
    datasets() {
      let datasets = [];

      for (let i = 0; i < this.yKeys.length; i++) {
        let data = [];
        let colors = [];
        let colorKey = this.colorKey;
        let key = this.yKeys[i];
        _.each(this.orderedData, (v, k) => {
          if (this.type === "scatter") {
            data.push(v);
          } else {
            data.push(v[key]);
          }
          if (colorKey) {
            colors.push(v[colorKey] !== "Automatic" ? v[colorKey] : "gray");
          }
        });

        let dataset = {
          //yAxisID: "y-axis-" + i,
          label: key,
          data: data,
          backgroundColor: colorKey ? colors : this.colors[i] || "gray",
          showLine: true,
          lineTension: 0.05,
          pointBorderColor: this.pointBorderColor,
          pointRadius: this.showPoints ? 3 : 0,
          borderColor: this.borderColor,
          borderWidth: 1,
        };

        if (this.enableTrendline && i == 0) {
          dataset.trendlineLinear = {
            style: "rgba(55,255,55, .8)",
            lineStyle: "dotted|solid",
            width: 2,
          };
        }
        datasets.push(dataset);
      }
      return datasets;
    },
  },
  watch: {
    data: {
      deep: true,
      handler: function () {
        this.reDraw();
      },
    },
    colors: {
      deep: true,
      handler: function () {
        this.reDraw();
      },
    },
    redrawIfChanged(v) {
      let vm = this;
      //console.warn("should redraw!");
      if (v !== null) {
        setTimeout(function () {
          console.warn("resizing!");
          vm.chart.resize();
        }, 0);
      }
    },
  },
  components: {},
};
</script>