<template>
  <div class="planned-resource" v-if="orders">
    <div class="buttons-wrap" v-for="(chunk,i) in resourceChunks" :key="i">
      <button
        v-for="r in chunk"
        :key="r.object_no"
        class="router-button nowrap"
        @click.prevent="toggleResource(r)"
        href="#"
        :class="{
          selected:
            (selected_ids.includes(r.object_no) &&
              !changing.includes(r.object_no)) ||
            (changing.includes(r.object_no) &&
              !selected_ids.includes(r.object_no)),
        }"
      >
        {{ r.object_no }} |
        {{
          allPlannedForMachine
            .filter((o) => o.machine_id == r.object_no)
            .reduce((a, b) => {
              return a + b.op_total_remaining_time_hours / b._plannedIn.length;
            }, 0).toFixed(0)
        }}
        <loading v-if="changing.includes(r.object_no)" />
      </button>
    </div>
  </div>
</template>

<script>
//import { useRouter, useRoute } from 'vue-router';
import { mapState, mapGetters } from "vuex";
import { Notification } from "@/helpers/notifications";
import store from "@/store";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";
import { Configuration } from "@/helpers/settings";

export default {
  props: {},
  setup() {},
  data() {
    return {
      changing: [],
        config: new Configuration(
        "resource-planning-overview",
        {
          day_limit: 2,
        },
        this.machine_id
      ),
    };
  },
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      orders: (state) => state.bottomnav.data,
      notifications: (state) => state.notifications.index,
      plannedOrders: (state) => state.orders.plannedOrders,
    }),
    ...mapGetters({
      configByMachine: "machines/configByMachine",
      machineHasSf: "machines/machineHasSf",
      setupCodesByMachine: "stopcodes/setupCodesByMachine",
      planningResourcesForMachine: "machines/planningResourcesForMachine",
      groupedPlannedOrdersByMachine: "orders/groupedPlannedOrdersByMachine",
    }),
    resources() {
      return !this.machine_id
        ? []
        : this.planningResourcesForMachine(this.machine_id);
    },
    resourceChunks(){
      return _.chunk(this.resources,4);
    },
    machine_id() {
      return this.$route.params.machine;
    },
    selected_ids() {
      return this.orders[0]?._plannedIn || [];
      //.map((o) => o.machine_id)
      //.filter((id) => id != this.machine_id);
    },
    card_no() {
      return this.orders[0]?.card_no || null;
    },
    groupedOrders() {
      return this.groupedPlannedOrdersByMachine(this.machine_id);
    },
    allPlannedForMachine() {
      return this.plannedOrders[this.machine_id].map((o) => {
          let os = this.plannedOrders[this.machine_id].filter((or) => o.card_no == or.card_no);
          return {
            ...o,
            _plannedIn: os
              .map((o) => o.machine_id)
              .filter((id) => id != this.machine_id),
          };
        }) || []
    },
  },
  methods: {
    toggleResource(r) {
      this.changing.push(r.object_no);
      execute_json("user_planning", {
        operation_type: !this.selected_ids.includes(r.object_no)
          ? "insert"
          : "delete",
        machine_id: r.object_no,
        card_no: this.card_no,
      }).then(() => {
        store
          .dispatch("orders/loadPlannedOrdersForMachine", {
            machine_id: this.machine_id,
            day_limit:this.config.day_limit
          })
          .then(() => {
            store.commit(
              "bottomnav/setData",
              this.groupedOrders.filter((o) => o.card_no == this.card_no)
            );
            this.changing = this.changing.filter((no) => no !== r.object_no);
          });
      });
    },
  },
  watch: {
    orders() {
      this.changing = [];
    },
  },
  components: {
    // DxButton
  },
};
</script>

<style lang="scss">
</style>
