<template>
  <div :key="notification.id" class="notification">
    <div
      class="notification-content"
      @mouseenter="doNotClose = true"
      @mouseleave="doNotClose = false"
    >
      <div class="notification-label" @click="deActivate">
        <loading v-if="notification.state === 'pending'" />
        <i
          class="fas fa-check success"
          v-if="
            notification.state === 'success' || notification.state === 'done'
          "
        ></i>
        <i
          class="fas fa-exclamation-circle fail"
          v-if="notification.state === 'fail'"
        ></i>
         <i
          class="fas fa-times fail"
          v-if="notification.state === 'warning'"
        ></i>
        <br />
      </div>
      <div class="notification-description">
        <h6>
          {{ notification.theader? $t(notification.theader) : (notification.header || "-") }}
          <span class="date pull-right">{{ displayTime }}</span>
        </h6>
        <p>
          {{ notification.tmessage ? $t(""+notification.tmessage) : notification.message }}
        </p>
        <div class="action-buttons" v-if="notification.actions">
          <div
            class="action-button-wrap"
            v-for="action in notification.actions"
            :key="action.label"
            @click="deActivate({selfClosing:true})"
          >
            <router-link
              v-if="action.path"
              :to="action.path"
              class="btn btn-success"
              >{{ action.label }}</router-link
            >
            <a
              href="#"
              class="btn btn-success"
              v-if="action.handler"
              @click.prevent="action.handler(notification)"
              >{{ action.label }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="self-close-indicator"
      v-if="!doNotClose && notification.selfClosing"
      :class="{ closing }"
    ></div>
  </div>
</template>
<script>
import { getNowShort } from "@/helpers/dates";
export default {
  emits: ["deactivate"],
  props: ["notification"],
  data() {
    return {
      audio: null,
      renderedTime: new Date().getTime(),
      currentTime: null,
      aliveFor: 0,
      closing: false,
      closed: false,
      doNotClose: false,
    };
  },
  mounted() {
    if (this.notification.playSound && this.notification_state === "fail") {
      this.audio = new Audio("/sounds/duoh.wav");
      this.play();
    } else if (
      this.notification.playSound &&
      this.notification_state !== "fail"
    ) {
      this.audio = new Audio("/sounds/dududududu.wav");
      this.play();
    }
    if (this.notification.selfClosing) {
      let selfClose = this.selfClose;
      setTimeout(function () {
        selfClose();
      }, 300);
    }
  },
  computed: {
    displayTime() {
      if (this.notification.event) {
        return (
          this.notification.event.trans_time.slice(5, 10) +
          " " +
          this.notification.event.trans_time.slice(11, 16)
        );
      }
      return getNowShort();
    },
    notification_state() {
      return this.notification.state;
    },

    percentageToClose() {},
  },
  methods: {
    selfClose() {
      if (this.closed || this.doNotClose || !this.notification.selfClosing) {
        return false;
      }
      this.aliveFor = new Date().getTime() - this.renderedTime;
      this.closing = true;
      if (this.aliveFor > 3000) {
        this.closed = true;
        this.deActivate({ selfClosed: true });
      } else {
        let selfClose = this.selfClose;
        setTimeout(function () {
          selfClose();
        }, 1000);
      }
    },
    deActivate(options) {
      this.$emit("deactivate", { notification: this.notification, options });
    },
    play(){
      if (this.audio){
        this.audio.play().catch((err)=>{
          console.log("silent fail because document has not been interacted with");
        })
      }
    }
  },
  watch: {
    notification_state(now, prev) {
      if (this.notification.playSound && now === "fail" && prev) {
        if (this.audio !== null) {
          this.audio.pause();
        }
        var audio = new Audio("/sounds/duoh.wav");
        audio.play();
      }
      if (this.notification.selfClosing) {
        this.renderedTime = new Date().getTime();

        let selfClose = this.selfClose;
        setTimeout(function () {
          selfClose();
        }, 300);
      }
    },
    doNotClose(val) {
      if (!val) {
        this.renderedTime = new Date().getTime();

        let selfClose = this.selfClose;
        this.closing = false;
        setTimeout(function () {
          selfClose();
        }, 300);
      }
    },
  },
};
</script>