<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div>
      <h1>
        {{ $t("6533") }}
        {{
          config.selectedIATypes?.length
            ? "- " + config.selectedIATypes.join(", ")
            : "- " + $t("570")
        }}
        <card-menu :items="menuItems" :config="config"></card-menu>
      </h1>

      <a
        href="#"
        class="indicator status-large c-green"
        @click.prevent="openPopup"
      >
        <div class="vertically-centered-content">
          <span>
            {{ $t("6534") }}<br />
            <loading v-if="saving" />
            <p v-if="!saving && lastCreateDate">
              {{ lastDescription }}:<br />
              {{ lastCreateDate }}
            </p>
            <p v-if="config.showOpenActivities">{{ $t("3181") }}:{{filteredActivities.length}}</p>
          </span>
        </div>
      </a>
    </div>
    <div class="popup-wrap" @click.self="clicked = false" v-if="clicked">
      <div class="popup">
        <loading v-if="!loaded" />
        <b-table
          v-else
          :data="filteredActivities"
          :columnConfig="columnConfig"
          primary-key="record_no"
          :single-select="true"
          :deselect="true"
          @selectionChanged="onSelectionChanged"
        >
        </b-table>
        <input
          type="text"
          v-model="extra_info_1"
          ref="input"
          placeholder="Extra info"
          class="white-text"
          v-if="wantsNumericalInput"
        />
        <input
          type="text"
          ref="input"
          v-model="extra_info_1"
          placeholder="Extra info"
          class="white-text"
          v-if="wantsTextInput"
        />
        <a
          href="#"
          class="router-button green"
          @click.prevent="createActivity(true)"
          :disabled="!hasInputNeeded"
          :class="{ disabled: !hasInputNeeded }"
        >
          <span v-if="!saving">{{ $t("4670") }} </span>
          <loading v-else />
        </a>
        <keyboard
          v-model="extra_info_1"
          v-if="wantsTextInput || wantsNumericalInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardMenu from "@/components/card-menu";
import { mapState, mapGetters } from "vuex";
import { setStorage, getStorage } from "@/helpers/storage";
import { Notification } from "@/helpers/notifications";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import { Configuration } from "@/helpers/settings";
import _ from "lodash";
import { Pulse } from "@/helpers/heartbeat";

export default {
  data() {
    return {
      clicked: false,
      loaded: false,
      selectedRows: [],
      activities: [],
      saving: false,
      lastCreateDate: null,
      lastDescription: null,
      pulse: null,
      columnConfig: {
        description: {
          tlabel: "19",
          component: "double-row",
          config: {
            val1: "description",
            val2: "type_description",
          },
        },
      },
      extra_info_1: "",
      config: new Configuration(
        "create-internal-activity",
        {
          selectedIATypes: [],
          showOpenActivities: false,
        },
        this.template
      ),
    };
  },
  /*
 mounted() {
    this.pulse = new Pulse({
      action: "activities/loadActivitiesForSelectionOwner",
      interval: 600,
      primaryKey: "selectionOwner",
      parameters: { selectionOwner: this.machine_id },
    });
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  */
  mounted() {
    this.load();
    if (this.config.showOpenActivities) {
      this.pulse = new Pulse({
        action: "activities/loadActivitiesForSelectionOwner",
        interval: 600,
        primaryKey: "selectionOwner",
        parameters: { selectionOwner: this.machine_id },
      });
    }
  },
  beforeUnmount() {
    if (this.pulse) {
      this.pulse.stop();
    }
  },
  computed: {
    ...mapState({
      username: (state) => state.auth.username,
      auth: (state) => state.auth,
      allActivities: (state) => state.activities.index,
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    ...mapGetters({
      //machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    menuItems() {
      let items = [{ header: this.$t("497") }];
      for (let i = 0; i < this.IAOptions.length; i++) {
        let option = this.IAOptions[i];
        items.push({
          label: option,
          handler: () => {
            return this.toggleOption(option);
          },
          selected: this.config.selectedIATypes.includes(option),
        });
      }
      items.push({
        divider: true,
      });
      items.push({
        tlabel: "4796",
        handler: () => {
          this.config.setValues({
            showOpenActivities: !this.config.showOpenActivities,
          });
        },
        selected: this.config.showOpenActivities,
      });
      return items;
    },
    IAOptions() {
      return [...new Set(this.activities.map((i) => i.type_description))];
    },
    filteredTypes() {
      return this.config.selectedIATypes.filter((t) =>
        this.IAOptions.includes(t)
      );
    },
    activitiesForSelection(){
      return this.allActivities[this.machine_id] || []
    },
    filteredActivities() {
      return this.activitiesForSelection.filter(
        (a) =>
          this.filteredTypes.length === 0 ||
          this.filteredTypes.includes(a.type_description)
      );
    },
    selectedActivity() {
      return this.selectedRows.length ? this.selectedRows[0] : null;
    },
    wantsTextInput() {
      return (
        this.selectedActivity && this.selectedActivity.extra_info_1_type == "1"
      );
    },
    wantsNumericalInput() {
      return (
        this.selectedActivity && this.selectedActivity.extra_info_1_type == "0"
      );
    },
    hasInputNeeded() {
      return (
        this.selectedRows.length > 0 &&
        (!this.wantsNumericalInput ||
          (this.wantsNumericalInput && this.extra_info_1 != ""))
      );
    },
  },
  methods: {
    toggleOption(option) {
      //console.warn(option);
      let selectedIATypes = _.cloneDeep(this.config.selectedIATypes);
      if (selectedIATypes.includes(option)) {
        selectedIATypes = selectedIATypes.filter((o) => o != option);
      } else {
        selectedIATypes.push(option);
      }
      this.config.setValues({ selectedIATypes });
    },
    load() {
      //this.clicked = true;
      //this.loaded = false;
      this.selectedRows = [];
      axios
        .post("balthzar_get_manual_internal_activities_15_2_160", {
          machine_id: this.machine_id,
          op_number: "",
          part_no: "",
          tool_id: "",
          type: "",
        })
        .then(({ data }) => {
          console.log(data);
          this.activities = flattenMultiRow(data);
          this.loaded = true;
        });
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
      //this.clicked = false;
    },
    openPopup() {
      this.load();
      this.clicked = true;
    },
    checkNumeric() {
      let info1 = this.extra_info_1.replace(/\D/g, "");
      if (info1 !== this.extra_info_1) {
        this.extra_info_1 = info1;
      }
    },
    createActivity() {
      //console.log("saving");
      if (!this.saving && this.hasInputNeeded) {
        this.saving = true;
        this.lastCreateDate = null;
        this.lastDescription = null;
        let note = Notification.request({
          message: "Creating activity...",
        });
        let d = new Date();
        d.setMinutes(d.getMinutes() - 1);
        let endDate = new Date();
        endDate.setMinutes(
          endDate.getMinutes() + (+this.selectedActivity.duration || 0)
        );
        this.clicked = false;
        axios
          .post("balthzar_start_internal_activity_21_12_0", {
            tool_service_counter: "-1",
            output_on_ok_create_activity: "-1",
            output_on_ok_create_after: "-1",
            output_on_deviation_create_activity: "-1",
            output_on_deviation_create_after: "-1",
            multiple_order_setting: "0",
            output_on_create_create_activity: "-1",
            output_on_create_create_after: "0",
            output_on_create_enter_time: false,
            output_on_create_mail_address: "",
            output_on_create_mail_title: "",
            output_on_create_mail_message: "",
            output_on_create_action_list: "-1",
            output_quantity_to_create_on_create: "1",
            output_on_ok_message_group: "",
            output_on_deviation_message_group: "",
            output_on_cancel_message_group: "",
            output_on_create_message_group: "",
            ...this.selectedActivity,
            special_function: "-1",
            other_record_link: "-1",
            batch_record_link: "-1",
            event_record_no: "-1",
            op_no: "-1",
            activity_company_id: this.selectedActivity.company_id,
            machine_id: this.machine_id,
            create_date: d.toBalthzarTime(),
            due_date: endDate.toBalthzarTime(),
            use_checklist: true,
            cancel_date: endDate.toBalthzarTime(),
            created_by: this.auth.username,
            extra_info_1: this.extra_info_1,
            quantity_to_create: 1,
          })
          .then(({ data }) => {
            this.saving = false;
            this.lastCreateDate = new Date().toBalthzarTime();
            this.lastDescription = this.selectedActivity.description;
            this.extra_info_1 = "";
            this.clearDateAfterDelay();
            note.ok({
              message: "activity created",
            });
          })
          .catch((err) => {
            this.saving = false;
            note.fail({
              message: "something went wrong",
            });
          });
      }
    },
    clearDateAfterDelay() {
      let vm = this;
      setTimeout(() => {
        vm.lastCreateDate = null;
        vm.lastDescription = null;
      }, 30000);
    },
  },
  components: {
    CardMenu,
  },
  props: {
    template: {
      type: String,
    },
  },
  watch: {
    extra_info_1() {
      if (this.wantsNumericalInput) {
        this.checkNumeric();
      }
    },
    config: {
      deep: true,
      handler() {
        if (this.pulse) {
          this.pulse.stop();
        }
        if (this.config.showOpenActivities) {
          this.pulse = new Pulse({
            action: "activities/loadActivitiesForSelectionOwner",
            interval: 600,
            primaryKey: "selectionOwner",
            parameters: { selectionOwner: this.machine_id },
          });
        }
      },
    },
  },
};
</script>

<style>
</style>