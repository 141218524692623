<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div v-if="machine" class="max-align">
      <h1>
        <!-- 1549 : availability -->
        {{ $t("2322") }} - {{ periodT ? $t(periodT) : "7d" }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div class="flex has-chart" style="flex: 1">
        <graph
          period="7d"
          :machine="machine"
          labelMode="hours"
          task="stopreasons_daily"
          type="stackedBar"
          uses="bar"
          :interval="interval"
          :disableLegend="config.disableLegend"
          labelStyle="short"
          :animationDuration="1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import Graph from "@/components/Graph";
import { shortDateTime } from "@/helpers/dates";
import { mapState, mapGetters } from "vuex";
import { Configuration } from "@/helpers/settings";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 175,
       config: new Configuration(
        "stopreasons-daily-graph",
        {
          disableLegend: false,
        },
        this.template
      ),
    };
  },
  mounted() {
  },
  beforeUnmount() {},
  methods: {
    toggleLegend(){
      this.config.setValues({disableLegend:!this.config.disableLegend});  
    }
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    periodT() {
      return null;
    },
    ...mapGetters({
      machineById: "machines/byId",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    items() {
      return [
        {header:this.$t('497')},
        {
          tlabel: "1268",
          handler: this.toggleLegend,
          selected:!this.config.disableLegend
        },
      ];
    },
    order_finished_formatted_short() {
      return shortDateTime(this.machine.order_finished);
    },
  },
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
    Graph,
  },
};
</script>