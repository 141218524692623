<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div>
      <h1 class="">
        {{ $t("5882") }} - {{ periodT ? $t(periodT) : "" }}
        <card-menu :items="items"></card-menu>
      </h1>
      <router-link
        :to="setUncodedStopLink"
        class="indicator status-large"
        :class="status"
      >
        <div class="vertically-centered-content">
          <!-- machine has a stop reason -->
          <p class="align-middle text-xxl">
            {{ uncoded_used }}
          </p>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import store from "@/store";
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    machine_id() {
      return this.$route.params.machine;
    },

    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
      machineById: "machines/byId",
    }),
    has85() {
      return this.machineHasSf(this.machine_id, "85");
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    uncoded_used() {
     return this.has85
        ? this.machine.uncoded_stops_24h
        : this.machine.uncoded_stops_shift24h;
    },
    status() {
      if (+this.uncoded_used > 1) {
        return "status-stop";
      } else if (+this.uncoded_used === 1) {
        return "status-uncoded";
      }
      return "status-running";
    },
    setUncodedStopLink() {
      return "/interface/" + this.machine_id + "/uncoded";
    },
    periodT() {
      let periods = {
        "24h": "448",
        today: "447",
        shift: "245",
      };
      //return "5";
      return periods[this.has85 ? "24h" : "shift"];
    },
    items() {
      return [
      ];
    },
  },
  methods: {
    
  },
  
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
  },
};
</script>