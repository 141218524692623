<template>
  <div class="popup">
    <div class="notification-content">
      <div class="notification-label" @click="deActivate">
        <loading v-if="notification.state === 'pending'" />
        <i
          class="fas fa-check success"
          v-if="
            notification.state === 'success' || notification.state === 'done'
          "
        ></i>
        <i
          class="fas fa-exclamation-circle fail"
          v-if="notification.state === 'fail'"
        ></i>
        <br />
      </div>
      <div class="notification-description">
        <h6>
          {{
            notification.theader
              ? $t(notification.theader)
              : notification.header || "-"
          }}
        </h6>
        <p>
          {{
            notification.tmessage
              ? $t(notification.tmessage)
              : notification.message
          }}
        </p>
        <div class="action-buttons" v-if="notification.actions">
          <div
            class="action-button-wrap"
            v-for="action in notification.actions"
            :key="action.label"
          >
            <router-link
              v-if="action.path"
              :to="action.path"
              class="btn btn-success"
              @click="navTriggered"
              >{{
                action.tlabel ? $t(action.tlabel) : action.label
              }}</router-link
            >
            <a
              href="#"
              class="btn btn-success"
              v-if="action.handler"
              @click.prevent="action.handler(notification)"
              >{{ action.tlabel ? $t(action.tlabel) : action.label }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapGetters } from "vuex";
import { flattenSingleRow } from "@/requests/requestParcer";
import store from "@/store";

export default {
  props: ["notification"],
  emits: ["close"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleEnter() {},
    deActivate() {
      this.$emit("close");
    },
    navTriggered() {
      this.deActivate();
    },
  },
  computed: {
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
      signedInByMachine: "machines/signedInByMachine",
      machineById: "machines/byId",
      runningByMachine: "orders/runningByMachine",
    }),
    machine_id() {
      return this.data.machine_id;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
  },
  watch: {},
};
</script>