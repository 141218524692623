<template>
  <div class="popup-wrap">
    <div class="closer" @click.prevent="close">
      <i class="fas fa-times"></i>
    </div>
    <div class="popup-sidemenu" v-if="pageDownEnabled">
      <button class="btn" @click="scrollUp">
        <i class="fas fa-chevron-up"></i>
      </button>
      <button class="btn" @click="scrollDown">
        <i class="fas fa-chevron-down"></i>
      </button>
      <br />
      <br />
      <button class="btn" @click="close">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div id="materiallines" class="popup" ref="popup"> 
       <div v-if="!canAssignMaterials">
        <button class="btn btn-error pull-left" @click="close">
          <i class="fas fa-times"></i>
        </button>
        <p>{{$t('2347')}}</p>
      </div>
      <table
        class="table table-borderless align-left left sticky"
        v-if="unassigned === null"
      >
        <thead>
          <tr>
            <th>{{ $t("1225") }}</th>
            <th>{{ $t("1226") }}</th>
            <th>{{ $t("3370") }}</th>
            <th>{{ $t("6917") }}</th>
            <th>{{ $t("3961") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="material in materialData" :key="material.material_no">
            <td>
              {{ material.material_no }}
            </td>
            <td style="width: 100%">{{ material.material_desc || "-" }}</td>
            <td>{{ material.location }}</td>
            <td>
              <select
                class="form-control"
                name=""
                id=""
                v-model="material.line_position"
                v-if="canAssignMaterials"
              >
                <option :value="null"></option>
                <option :value="pos" v-for="pos in positions" :key="pos">
                  {{ pos }}
                </option>
              </select>
              <span v-else>
                {{ material.line_position }}
              </span>
            </td>
            <td>
              <button
                class="btn"
                :class="{
                  'btn-success': material.confirm_material,
                  'btn-warning': material.confirm_material === false,
                }"
                @click="toggleMaterial(material)"
              >
                <i class="fas fa-check" v-if="material.confirm_material"></i>
                <i class="fas fa-minus" v-else></i>
              </button>
            </td>
          </tr>

          <tr v-if="materialData?.length === 0">
            <td colspan="4">{{ $t("6918") }}</td>
          </tr>
          <tr v-if="materialData === null">
            <td colspan="4">
              <loading />
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table-borderless align-left left" v-else>
        <thead>
          <tr>
            <th>{{ $t("1225") }}</th>
            <th>{{ $t("1226") }}</th>
            <th>{{ $t("3370") }}</th>
            <th>{{ $t("6917") }}</th>
            <th>{{ $t("3961") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="material in unassigned" :key="material.material_no">
            <td>
              {{ material.material_no }}
            </td>
            <td style="width: 100%">{{ material.material_desc || "-" }}</td>
            <td>{{material.location}}</td>
            <td>
              <select
                class="form-control"
                name=""
                id=""
                v-model="material.line_position"
                v-if="canAssignMaterials"
              >
                <option :value="null"></option>
                <option :value="pos" v-for="pos in positions" :key="pos">
                  {{ pos }}
                </option>
              </select>
              <span v-else>
                {{ material.line_position }}
              </span>
            </td>
            <td>
              <button
                class="btn"
                :class="{
                  'btn-success': material.confirm_material,
                  'btn-warning': material.confirm_material === false,
                }"
                @click="toggleMaterial(material)"
              >
                <i class="fas fa-check" v-if="material.confirm_material"></i>
                <i class="fas fa-minus" v-else></i>
              </button>
            </td>
          </tr>
          <tr v-if="unassigned?.length === 0">
            <td colspan="4">{{ $t("6918") }}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="canAssignMaterials">
        <button
          class="btn btn-success"
          @click="saveAndClose"
          :disabled="
            !canClose || (materialData === null && unassigned === null)
          "
        >
          <i class="fas fa-chevron-right"></i>
        </button>
        <button class="btn btn-error pull-left" @click="close">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div v-else>
        <button class="btn btn-error pull-left" @click="close">
          <i class="fas fa-times"></i>
        </button>
        <p>{{$t('2347')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { secondsToHhMm } from "@/helpers/dates";
import { mapState, mapGetters } from "vuex";
import { execute_json } from "@/helpers/requests";
export default {
  props: {
    order: {},
    unassigned: {
      default: null,
    }
  },
  data() {
    return {
      loaded: false,
      details: null,
      bottomNavOpenOnMount: false,
      materialData: null,
      pageDownEnabled: false,
    };
  },
  created() {
    this.loadData();
    this.bottomNavOpenOnMount = this.bottomNavOpen;
    store.commit("bottomnav/hide");
  },
  beforeUnmount() {
    if (this.bottomNavOpenOnMount) {
      store.commit("bottomnav/enable");
    }
  },
  methods: {
    secondsToHhMm(s) {
      if (isNaN(s)) {
        s = 0;
      }
      return (s / 3600).toFixed(2);
      return secondsToHhMm(s);
    },
    close() {
      this.$emit("close");
    },
    saveAndClose() {
      let promises = [];
      if (this.unassigned === null) {
        this.materialData.forEach((m) => {
          m.line_no = this.machine.line_no;
          promises.push(execute_json("sf369", m));
        });
      } else {
        this.unassigned
          .filter((m) => m.confirm_material != null && m.line_position != null)
          .forEach((m) => {
            m.line_no = this.machine.line_no;
            promises.push(execute_json("sf369", m));
          });
      }
      Promise.all(promises).then(() => {
        this.$emit("close");
      });
    },
    toggleMaterial(material) {
      if (!this.canAssignMaterials) {
        return true;
      }
      if (material.confirm_material !== false) {
        material.confirm_material = false;
      } else {
        material.confirm_material = true;
      }
    },
    loadData() {
      if (this.unassigned !== null) {
        this.loaded = true;
        return true;
      }
      this.loaded = false;
      store
        .dispatch("materials/loadMaterialList", {
          machine: { ...this.order, machine_id: this.machine_id },
        })
        .then((data) => {
          this.materialData = data
            .map((m) => {
              return {
                line_position: null,
                confirm_material: null,
                ...m,
              };
            })
            .sort((a, b) => a.line_no - b.line_no);
          this.loaded = true;
        });
    },
    checkSize() {
      this.$nextTick(() => {
        let $popup = this.$refs.popup;
        if (!$popup || $popup.scrollHeight > $popup.clientHeight) {
          this.pageDownEnabled = true;
        } else {
          this.pageDownEnabled = false;
        }
      });
    },
    scrollUp() {
      let $popup = this.$refs.popup;
      let top = $popup.scrollTop;
      let height = $popup.clientHeight - 50;
      $popup.scroll({ top: top - height, left: 0, behavior: "smooth" });
    },
    scrollDown() {
      let $popup = this.$refs.popup;
      let top = $popup.scrollTop;
      let height = $popup.clientHeight - 50;
      $popup.scroll({ top: top + height, left: 0, behavior: "smooth" });
    },
  },
  computed: {
    ...mapState({
      bottomNavOpen: (state) => state.bottomnav.shouldShow,
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      canUser: "permissions/canUser",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    positions() {
      return new Set([
        ...this.machines
          .filter((m) => m.line_no === this.line_no)
          .map((m) => m.line_position)
          .sort(),
      ]);
    },
    line_no() {
      return this.machine.line_no;
    },
    canClose() {
      if (this.unassigned === null) {
        return (
          this.materialData !== null &&
          !this.materialData.some(
            (m) =>
              m.confirm_material === null ||
              m.confirm_material === undefined ||
              m.line_position === null ||
              m.line_position === undefined
          )
        );
      } else {
        return true;
      }
    },
    canAssignMaterials() {
      return this.canUser("can-assign-materials");
    },
  },
  watch: {
    loaded() {
      if (this.loaded) {
        this.checkSize();
      }
    },
  },
};
</script>

<style lang="scss">
#materiallines .sticky th {
  position: sticky;
  top: -50px;
  background: white;
}
.touch-controls {
  position: absolute;
  top: 0;
  right: -100px;
  .pagedown {
    position: fixed;
    top: 0;
    right: -100px;
    color: white;
  }
}
.popup-sidemenu {
  width: 40px;
  background: white;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(410px);
}
</style>