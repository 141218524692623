<template>
  <div class="stopcode-multiple">
    <input
      type="text"
      v-model="note"
      placeholder="Add a note..."
      class="white-text"
    />
    <p>
      {{ selectedUncoded.length }} stops: #{{ stopcode.stop_code }} -
      {{ stopcode.stop_description }}
    </p>

    <div class="buttons-wrap">
      <!-- 3300 -> set stopreason -->
      <a
        href="#"
        class="router-button"
        @click.prevent="saveStopcodes"
        :disabled="loading"
      >
        <i class="fas fa-sticky-note" v-if="stopcode.stop_text_flag"></i>
        <loading v-if="loading" :step="step" :maxStep="maxStep" />
        <span v-else>{{ $t("3300") }}</span>
      </a>
    </div>
    <div
      class="popup-wrap"
      v-if="notePopupEnabled"
      @click.self="saveStopcodes()"
      style="color: black"
    >
      <div class="popup">
        <h2>{{ $t("638") }}</h2>
        <textarea
          name=""
          v-model="note"
          class="form-control"
          id=""
          cols="30"
          rows="4"
        ></textarea>
        <br />
        <div class="buttons-wrap">
          <!-- 3300 -> set stopreason -->
          <a
            href="#"
            class="router-button btn btn-success"
            @click.prevent="saveStopcodes()"
          >
            <span v-if="!saving">{{ $t("3300") }}</span>
            <loading v-else />
          </a>
        </div>
      </div>
    </div>
    <keyboard v-model="note" v-if="stopcode.stop_text_flag" />
  </div>
</template>

<script>
//import { useRouter } from 'vue-router';
import { mapState, mapGetters } from "vuex";
import { allProgress } from "@/helpers/promises";
import store from "@/store";

export default {
  props: {},
  setup() {},
  data() {
    return {
      note: null,
      loading: false,
      notePopupEnabled: false,
      step: null,
      maxStep: null,
    };
  },
  mounted(){
    if (this.hasSf273){
      this.saveStopcodes();
    }
  },  
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      stopcode: (state) => state.bottomnav.data,
      selectedUncoded: (state) => state.uncoded.selected,
      returnTo: (state) => state.uncoded.returnTo,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    hasSf273() {
      let uncoded = this.selectedUncoded[0];
      let machine_id = uncoded.machine_id;
      return this.machineHasSf(machine_id, "273");
    },
    routerlinks() {
      return {};
    },
  },
  methods: {
    saveStopcodes() {
      let promises = [];
      this.loading = true;
      this.buttonText = "Saving...";
      let code = this.stopcode;
      if (code.stop_text_flag && this.note === null) {
        this.notePopupEnabled = true;
        this.note = "";
        return true;
      }
      let note = this.note || "";
      this.notePopupEnabled = false;
      this.note = null;
      for (let i = 0; i < this.selectedUncoded.length; i++) {
        let uncoded = this.selectedUncoded[i];
        let machine_id = uncoded.machine_id;
        let machine = this.machineById(machine_id);
        let currentStopNumberMachine = machine.stop_number;
        console.warn(currentStopNumberMachine, uncoded.stop, uncoded, machine);
        if (currentStopNumberMachine == +uncoded.stop) {
          promises.push(
            store.dispatch("stopcodes/setStopcode", {
              stopcode: this.stopcode,
              machine_id: machine_id,
            })
          );
          if (uncoded.stop_reason !== "-") {
            promises.push(
              store.dispatch("uncoded/updateUncodedStop", {
                stopcode: this.stopcode,
                uncoded,
                note,
              })
            );
          }
        } else {
          promises.push(
            store.dispatch("uncoded/updateUncodedStop", {
              stopcode: this.stopcode,
              uncoded,
              note,
            })
          );
        }
      }
      this.maxStep = promises.length;
      //allProgress instead of Promise.all
      allProgress(promises, (p) => {
        this.step = p;
      }).then(() => {
        console.log("done");
        this.$router.push(
          this.returnTo ? this.returnTo : this.$route.path.replace("/code", "")
        );
        store.commit("bottomnav/disable");
        this.loading = false;
      });
    },
  },
  components: {},
};
</script>

<style lang="scss">

#small-indicator,
#medium-indicator,
#large-indicator {
  vertical-align: middle;
  margin-right: 10px;
}

#button,
#button .dx-button-content {
  padding: 0;
}
</style>
