<template>
  <div>
    <Loading v-if="loading" />
    <div v-else>
      <p v-if="ordersForMachine !== null">
        <span
          v-if="
            ordersForMachine.length > 0 && ordersForMachine[0].status != '0'
          "
        >
           <c-table
            :data="ordersForMachine"
            primary-key="record_no"
            config-string="running_orders_tab" 
            @selectionChanged="onSelectionChanged"
            :teleport="true"
          >
          </c-table>
        </span>
        <span v-else>
          {{ $t("1259") }}
        </span>
      </p>
    
    </div>
     
  </div>
</template>
<script>
import { mapState , mapGetters} from "vuex";
import store from "@/store";
import _ from "lodash";
export default {
  props: ["data"],
  emits: ["reject","resolve"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      machine_id: this.$route.params.machine,  
      selectedRows: [],
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    store
      .dispatch("orders/loadRunningOrderForMachine", {
        machine_id: this.machine_id,
        reload: false,
      })
      .then(() => {
        this.loading = false;
      });
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
    }),
    ...mapGetters({
      canUser: "permissions/canUser",
    }),
    ordersForMachine() {
      return this.orders[this.machine_id] || null;
    },
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length > 0 && this.selectedRows[0]) {
       this.$emit('resolve',this.selectedRows[0]);
      } 
    },
  },
  watch: {
    
  },
};
</script>