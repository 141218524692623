<template>
  <div class="yesnowith">
    <yesno v-model="modelValue.entered_value" @next="checkNext()" :disabled="locked"/>
    <div class="deviation" :class="{ active: modelValue.entered_value == '0' }">
      <label for="exception_note" class="exception_note-label">{{
        item.deviation_text
      }}</label>
      <textarea
        name="exception_note"
        id=""
        cols="30"
        rows="10" :disabled="locked"
        class="form-control"
        v-model="modelValue.exception_note"
        @focus="focusOnText = true"
      >
      </textarea>
      <keyboard
        v-model="modelValue.exception_note"
        v-show="focusOnText && !locked"
        :keyboard-class="keyboardClass"
      />
    </div>
  </div>
</template>

<script>
import yesno from "./YesNo";
import _ from "lodash";

export default {
  emits: ["update:modelValue", "next", "lock"],
  props: {
    modelValue: {},
    item: {},
    active: {},
  },
  data() {
    return {
      focusOnText: false,
      keyboardClass: _.uniqueId("simple-keyboard-"),
    };
  },
  mounted(){
    this.$emit('lock',this.locked);
  },
  computed:{
    locked(){
      return !this.item.allow_user_edit
    }
  },
  components: { yesno },
  watch: {
    modelValue: {
      deep: true,
      handler: function (val) {
        this.$emit("update:modelValue", val);
      },
    },
    active(val) {
      if (!val) {
        this.focusOnText = false;
      } else if (this.modelValue.entered_value === '0') {
        this.focusOnText = true;
      }
    },
  },
  methods: {
    checkNext() {
      this.modelValue.isdone = true;
      if (this.modelValue.entered_value === '1') {
        this.$emit("next");
      }
      this.focusOnText = true;
    },
    goNext() {
      this.focusOnText = false;
      if (this.modelValue.entered_value !== null) {
        if (this.modelValue.exception_note != "") {
          this.$emit("next");
        }
      }
    },
  },
};
</script>

<style>
</style>