<template>
  <div class="machine-singular" v-if="order">
    <input
      type="number"
      v-model="cavities"
      placeholder="Cavities"
      class="white-text"
      v-if="config.ask_for_cavities"
    />
    <p>Planned order: {{ order.work_order_no }}</p>
    <div class="buttons-wrap">
      <button
        class="router-button"
        @click.prevent="startOrder"
        href="#"
        :disabled="disabled"
        :class="{ disabled }"
      >
        start
      </button>
      <router-link
        v-if="hasMaterialMenuEnabled"
        class="router-button"
        :to="'/interface/' + machine_id + '/materials-planned'"
      >
        materials
      </router-link>
    </div>
    <div class="popup-wrap" @click.self="back" v-if="Sf183PopupActive">
      <div class="popup">
        <b-table
          v-if="Sf183OptionsLoaded"
          :data="sf183Options"
          :columnConfig="sf183Config"
          primary-key="record_no"
          :show-headers="false"
          sort_dir="asc"
          caption="3977"
          :single-select="true"
          @selectionChanged="onSf183SelectionChanged"
        >
        </b-table>
        <loading style="color: black" v-else>
          checking tool structures..</loading
        >
      </div>
    </div>
    <div class="popup-wrap" @click.self="back" v-if="Sf326PopupActive">
      <div class="popup">
        <b-table
          :data="setupCodes"
          :columnConfig="stopConfig"
          primary-key="record_no"
          sort_dir="asc"
          :show-headers="false"
          caption="2319"
          :single-select="true"
          @selectionChanged="onStopcodeSelectionChanged"
        >
        </b-table>
      </div>
    </div>
    <div class="popup-wrap" @click.self="back" v-if="Sf6PopupActive">
      <div class="popup">
        <h2>{{ $t("1273") }}</h2>
        <numerical-input v-model="unit_qty"></numerical-input>
        <button
          class="btn btn-success"
          @click.prevent="startOrder"
          href="#"
          :disabled="unit_qty === null || +unit_qty == 0 || isNaN(unit_qty)"
          :class="{
            disabled: unit_qty === null || +unit_qty == 0 || isNaN(unit_qty),
          }"
        >
          start
        </button>
      </div>
    </div>
  </div>
</template>

<script>
//import { useRouter, useRoute } from 'vue-router';
import { mapState, mapGetters } from "vuex";
import { Notification } from "@/helpers/notifications";
import store from "@/store";
import _ from "lodash";

export default {
  props: {},
  setup() {},
  data() {
    return {
      cavities: null,
      stop_reason: null,
      tool_structure: null,
      Sf326PopupActive: false,
      Sf183PopupActive: false,
      Sf183OptionsLoaded: false,
      Sf6PopupActive: false,
      unit_qty: null,
      noStructures: false,
      stopConfig: {
        stop_description: {
          class: "h50",
        },
      },
      sf183Config: {
        child_tool_id: {
          class: "h50",
        },
        child_tool_description: {
          class: "h50",
        },
      },
    };
  },
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      order: (state) => state.bottomnav.data[0],
      notifications: (state) => state.notifications.index,
      toolStructures: (state) => state.tools.toolStructures,
    }),
    ...mapGetters({
      configByMachine: "machines/configByMachine",
      machineHasSf: "machines/machineHasSf",
      setupCodesByMachine: "stopcodes/setupCodesByMachine",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    tool_id() {
      return this.order.tool_id;
    },
    config() {
      return this.configByMachine(this.machine_id);
    },
    hasMaterialMenuEnabled() {
      return this.config.material_menu;
    },
    hasSf183() {
      return this.machineHasSf(this.machine_id, "183");
    },
    sf183Options() {
      return this.tool_id && this.Sf183OptionsLoaded
        ? this.toolStructures[this.tool_id]
        : [] || [];
    },
    hasSf326() {
      return this.machineHasSf(this.machine_id, "326");
    },
    hasSf6() {
      return this.machineHasSf(this.machine_id, "6");
    },
    unitQty() {
      return this.order.unit_qty;
    },
    setupCodes() {
      return this.setupCodesByMachine(this.machine_id);
    },
    routerlinks() {
      return {};
    },
    disabled() {
      let max = +this.config.max_cavities || 99;
      let min = 0;
      let cavities = _.clamp(this.cavities, min, max) || 0;
      return (
        this.config.ask_for_cavities &&
        (cavities == null || cavities == 0 || cavities == "" || cavities == NaN)
      );
    },
  },
  methods: {
    startOrder() {
      //Sf183 check
      if (this.hasSf183 && this.tool_structure == null && !this.noStructures) {
        this.Sf183PopupActive = true;
        console.log("sf 183!");
        this.fetchSf183Options();
        return true;
      }
      this.Sf183PopupActive = false;
      //Sf326 check
      if (this.hasSf326 && this.stop_reason == null) {
        this.Sf326PopupActive = true;
        return true;
      }
      this.Sf326PopupActive = false;
      //Sf6 check
      if (this.hasSf6 && this.unitQty == "0" && this.unit_qty == null) {
        this.Sf6PopupActive = true;
        return true;
      }
      this.Sf6PopupActive = false;
      let note = Notification.request({
        message: "Starting work order..",
      });
      let orderStartPayload = {
        order: this.order,
        machine_id: this.machine_id,
        cavities: this.cavities,
        stop_reason: this.stop_reason,
        tool_structure: this.tool_structure,
        unit_qty: this.unit_qty === null ? this.unitQty : this.unit_qty,
      };
      store
        .dispatch("orders/startOrder", orderStartPayload)
        .then(() => {
          note.ok({
            message: "Work order started!",
          });
        })
        .catch((err) => {
          let response =
            err.response?.data?.call_status?.[0]?.call_message || "-";
          console.log("axios error", err, err.response);
          note.close();
          if (response.indexOf("SF370") > -1) {
            let n = Notification.failed(response, {
              header: "SF370",
              popup: true,
              selfClosing: false,
              playSound: false,
            });
            n.update({
              actions: [
                {
                  label: "Assign materials",
                  handler: () => {
                    store.commit("popups/addPopup", {
                      data: orderStartPayload,
                      type: "material-lines-popup",
                      canClose: false,
                    });
                    //this.$emit("close");
                  },
                },
                {
                  label: "Try again",
                  handler: () => {
                    let n2 = Notification.request({
                      message: "Starting work order..",
                    });
                    store
                      .dispatch("orders/startOrder", orderStartPayload)
                      .then(() => {
                        n2.ok({
                          message: "Work order started!",
                        });
                        n.update({ active: false });
                      })
                      .catch((err) => {
                        n2.close();
                      });
                  },
                },
              ],
            });
          }
        });

      this.stop_reason = null;
      this.tool_structure = null;
      this.unit_qty = null;
      this.noStructures = false;
      store.commit("bottomnav/disable");
      if (this.$route.params.name !== "machine-interface") {
        this.$router.push("/interface/" + this.machine_id);
      }
    },
    back() {
      this.Sf183PopupActive = false;
      this.Sf326PopupActive = false;
    },
    onStopcodeSelectionChanged({ selectedRowsData }) {
      let stop = selectedRowsData.length ? selectedRowsData[0] : null;
      if (stop) {
        this.stop_reason = stop.stop_code;
        this.startOrder();
      }
    },
    onSf183SelectionChanged({ selectedRowsData }) {
      let structure = selectedRowsData.length ? selectedRowsData[0] : null;
      if (structure) {
        this.tool_structure = structure.child_tool_id;
        this.startOrder();
      }
    },
    fetchSf183Options() {
      //TODO: check for tool_id existence
      this.Sf183OptionsLoaded = false;
      this.noStructures = false;
      store.dispatch("tools/loadSf183List", this.tool_id).then(() => {
        this.Sf183OptionsLoaded = true;
        if (this.toolStructures[this.tool_id].length == 0) {
          this.noStructures = true;
          this.startOrder();
        }
      });
    },
  },
  watch: {
    cavities(val) {
      val = parseInt(val, 10);
      let max = +this.config.max_cavities || 99;
      let min = 0;
      this.cavities = _.clamp(val, min, max);
    },
  },
  components: {
    // DxButton
  },
};
</script>

<style lang="scss">
</style>
