import defaultLayout from '@/layouts/DefaultLayout'
import portalComponent from "@/components/portal-component";
import Machines from "@/views/overviews/machines.vue";
import MachineWidgets from "@/views/overviews/WidgetMachines.vue";

function loadView(view) {
  return () => import ( `@/views/${view}.vue`)
}

export const machineRoutes = [
  {
    path:"/machines",
    name:"machines",
    meta:{
      requiresAuth:true,
      layout:defaultLayout,
      storeSuperType:"machines",
      canHandleMine:true
    },
    children:[
      {
        path:':locationtype/:location',
        name:'machines-location',
        meta:{
          requiresAuth:true,
          shouldRemember:true,
        },
        children:[
           
        ],
        alias:':locationtype/:location/preview-for/:machine?',
        component: portalComponent
      },
      {
        path:'all',
        name:'machines-all',
        meta:{
          requiresAuth:true,
          shouldRemember:true,
          group:'all',
        },
        children:[
           
        ],
        component: portalComponent
      },
    ],
    component: Machines
  },
  {
    path:"/machine-widgets",
    name:"machine-widgets",
    meta:{
      requiresAuth:true,
      layout:defaultLayout,
      storeSuperType:"machines-widgets",
      canHandleMine:true
    },
    children:[
      {
        path:':locationtype/:location',
        name:'machine-widgets-location',
        meta:{
          requiresAuth:true,
          shouldRemember:true,
        },
        children:[
           
        ],
        component: portalComponent
      },
      {
        path:'all',
        name:'machine-widgets-all',
        meta:{
          requiresAuth:true,
          shouldRemember:true,
          group:'all',
        },
        children:[
           
        ],
        component: portalComponent
      },
    ],
    component: MachineWidgets
  },
  {
    path:"/actives",
    name:"actives",
    meta:{
      requiresAuth:true,
      layout:defaultLayout,
      storeSuperType:"actives",
      canHandleMine:true
    },
    children:[
      {
        path:':locationtype/:location',
        name:'actives-location',
        meta:{
          requiresAuth:true,
          shouldRemember:true
        },
        children:[
           
        ],
        component: portalComponent
      },
      {
        path:'all',
        name:'actives-all',
        meta:{
          requiresAuth:true,
          shouldRemember:true,
          group:'all',
        },
        children:[
           
        ],
        component: portalComponent
      },
    ],
    component: loadView("overviews/ActiveMachines")
  },
  {
    path:"/a-actives",
    name:"a-actives",
    meta:{
      requiresAuth:true,
      layout:defaultLayout,
      storeSuperType:"a-actives",
      canHandleMine:true
    },
    children:[
      {
        path:':locationtype/:location',
        name:'a-actives-location',
        meta:{
          requiresAuth:true,
          shouldRemember:true
        },
        children:[
           
        ],
        component: portalComponent
      },
      {
        path:'all',
        name:'a-actives-all',
        meta:{
          requiresAuth:true,
          shouldRemember:true,
          group:'all',
        },
        children:[
           
        ],
        component: portalComponent
      },
    ],
    component: loadView("overviews/AActiveMachines")
  },
  {
    path:"/statistics/:machine/sr-short",
    meta:{
      requiresAuth:true,
      layout:defaultLayout
    },
    component:loadView("overviews/statistics/StopreasonsShortMachine")
  },
  {
    path:'/planning/:machine/resources',
    name:"resource-planning",
    meta:{
      requiresAuth: true,
      layout: defaultLayout,
      title: "Resource planning",
      resourceType2:true
    },
    component: loadView("planning/resources")
  },
  {
    path:"/statistics/:machine/sr-daily",
    meta:{
      requiresAuth:true,
      layout:defaultLayout
    },
    component:loadView("overviews/statistics/StopreasonsDailyMachine")
  },
  {
    path:"/statistics/:machine/av-short",
    meta:{
      requiresAuth:true,
      layout:defaultLayout
    },
    component:loadView("overviews/statistics/AvailabilityShortMachine")
  },
  {
    path:"/:viewtype/:locationtype/:location/time-history",
    name:'timehistory-location',
    meta:{
      allBehavior:"empty",
      requiresAuth:true,
      layout:defaultLayout,
      canHandleMine:true,

    },
    component:loadView("overviews/statistics/TimeOverview")
  },
  {
    path:"/statistics/:locationtype/:location/sr-short",
    name:'graphs-location',
    meta:{
      allBehavior:"deny",
      requiresAuth:true,
      layout:defaultLayout
    },
    component:loadView("overviews/statistics/StopreasonsShortLocation")
  },
  {
    path:"/statistics/:locationtype/:location/sr-daily",
    name:'graphs-daily-location',
    meta:{
      allBehavior:"deny",
      requiresAuth:true,
      layout:defaultLayout
    },
    component:loadView("overviews/statistics/StopreasonsDailyLocation")
  },
  {
    path:"/statistics/:locationtype/:location/av-short",
    name:'av-graphs-location',
    meta:{
      allBehavior:"deny",
      requiresAuth:true,
      layout:defaultLayout
    },
    component:loadView("overviews/statistics/AvailabilityShortLocation")
  },
  {
    path:"/statistics/:locationtype/:location/analysis-short",
    name:'analysis-graphs-location',
    meta:{
      allBehavior:"deny",
      requiresAuth:true,
      layout:defaultLayout
    },
    component:loadView("overviews/statistics/AnalysisShortLocation")
  },
  
  {
    path:'/:viewtype/:locationtype/:location/create-activity/:machine',
    name:"create-activity-machines",
    meta:{
      requiresAuth: true,
      layout: defaultLayout,
      title: "Create activity"
    },
    component: loadView("interface/activities/create-activity")
  },
  {
    path:'/:viewtype/:locationtype/:location/run-activity/:machine',
    name:"run-activity-machines",
    meta:{
      requiresAuth: true,
      layout: defaultLayout,
      title: "run activity"
    },
    component: loadView("interface/activities/run-activity")
  },
  {
    path:'/:viewtype/:locationtype/:location/notes/:machine',
    name:"notes-machines-overview",
    meta:{
      requiresAuth: true,
      layout: defaultLayout,
      title: "Notes"
    },
    component: loadView("machinenotes/index")
  }, {
    path:'/:viewtype/:locationtype/:location/notes/all',
    name:"notes-all-overview",
    meta:{
      requiresAuth: true,
      layout: defaultLayout,
      title: "Notes"
    },
    component: loadView("machinenotes/all")
  },
  {
    path: '/machines/notifications',
    name: 'Machines: Notifications',
    meta: {
      requiresAuth: true,
      layout: defaultLayout
    },
    component: loadView("settings/notificationOverview")
  },
];
