<template>
  <div id="file-index">
    <loading v-if="!loaded" />
    <div class="row" v-else><h3 v-if="data.order.selected">order: {{data.order.selected.work_order_no}}</h3>
    
      <div
        v-for="document in files"
        :key="document.document_link"
        class="col-12 col-xl-12 col-sm-12 file-link"
        :class="{selected:data[tab.dataKey].hash == document.hash}"
        @click="
          data[tab.dataKey].value = document.document_link;
          data[tab.dataKey].hash = document.hash;
        "
      >
        <div class="file-info">
          <h6>{{ document.document_description || "-" }}</h6>
          <p>{{ document.document_link }}</p>
        </div>
      </div>
    </div>
    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>

<script>
import DocumentLink from "@/components/DocumentLink";
import { mapState, mapGetters } from "vuex";
import { flattenMultiRow } from "@/requests/requestParcer";
import store from "@/store";
import axios from "@/requests/axios";

export default {
  data() {
    return {
      loaded: true,
      path: null,
    };
  },

  props: ["data", "tab", "islast"],
  mounted() {
    this.loadFiles();
    store.commit("bottomnav/hide");
  },
  methods: {
    loadFiles() {
      if (this.machine_id) {
        this.loaded = false;
        store.dispatch("files/loadDocuments", this.documentvars).then(() => {
          this.loaded = true;
        });
      }
    },
  },
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      selections: (state) => state.selections,
      documentIndex: (state) => state.files.documents,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    hasSF296() {
      return this.machineHasSf(this.machine_id, "296");
    },
    files() {
      return this.documentIndex[this.machine_id] || [];
    },
    order() {
      return this.data.order.selected || [];
    },
    documentvars() {
      return {
        machine_id: this.machine_id,
        card_no: this.machine.card_no,
        part_no: this.order.part_no,
        tool_id: this.order.tool_id,
        part_type_id: this.order.part_type_id,
      };
    },
    disableNext() {
      return this.data[this.tab.dataKey].value == null;
    },
  },
  beforeRouteUpdate(to, from, next) {
    //this.loadFiles();
    next();
  },
  watch: {
    $route() {
      if (this.$route.params.hash === undefined) {
        this.loadFiles();
      }
    },
  },
  components: {
    DocumentLink,
  },
};
</script>

<style>
</style>