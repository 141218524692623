<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div v-if="machine">
      <h1>
        {{$t("65")}}
        <card-menu :items="items"></card-menu>
      </h1>
      <table class="table table-display">
        <tr>
          <th>
            {{ $t("729") }}%
          </th>
          <td class="status-gray first">
            <div>
            {{ 100-machine.q_green_limit }}
            </div>
          </td>
        </tr>
        <tr>
          <th>
            1 {{ $t("2644") }}
          </th>
          <td :class="classes['q_1h']">
            <div>
            {{ (100-machine.q_1h).toFixed(1) }}
            </div>
          </td>
        </tr>
        <tr>
          <th>
            8 {{ $t("2644") }}
          </th>
          <td :class="classes['q_8h']">
            <div>
            {{ (100-machine.q_8h).toFixed(1) }}
            </div>
          </td>
        </tr>
        <tr>
          <th>
            24 {{ $t("2644") }}
          </th>
          <td class="last" :class="classes['k_percent_24']">
            <div>
            {{ (100-machine.k_percent_24).toFixed(1) }}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
    };
  },
  mounted() {
     this.pulse = new Pulse({
      action: "machines/loadMachine",
      interval:3,
      primaryKey:"machine_id",
      parameters:{machine_id:this.machine_id}
    });  
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({ machineById: "machines/byId" }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    classes(){
      let classes = {};
      let keys = ["q_1h","q_8h","k_percent_24"];
      for (let i = 0;i<keys.length;i++){
        let k = keys[i];
        let v = 100-this.machine[k];
        if (v >= 100-this.machine.q_green_limit){
          classes[k] = "status-red";
        }else if (v >= 100-this.machine.q_yellow_limit){
          classes[k] = "status-yellow";
        }else{
          classes[k] = "status-green";
        }
      }
      return classes;
    },
    items() {
      return [];
    },
  },
  
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
  },
};
</script>