<template>
  <div class="stopcode-singular">
    <!-- 5773 -> selected stop reasons -->
    <p>{{ uncoded_stops.length }} {{ $t("5773") }}</p>

    <div class="buttons-wrap">
      <!-- 3477 -> edit stop stopreason -->
      <a href="#" class="router-button" @click.prevent="codeUncoded">{{
        $t("3477")
      }}</a>
    </div>
    <last-used-stopcodes @coded="reload"></last-used-stopcodes>
  </div>
</template>

<script>
//import { useRouter } from 'vue-router';
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";
import LastUsedStopcodes from "./subcomponents/LastUsedStopcodes.vue";

export default {
  props: {},
  setup() {},
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      uncoded_stops: (state) => state.bottomnav.data,
      returnTo: (state) => state.bottomnav.returnTo,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      columnConfigByTemplate: "config/columnConfigByTemplate",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    routerlinks() {
      return {
        codeUncoded: this.uncoded_link,
      };
    },
    uncoded_link() {
      if (this.$route.path.includes("/time-history") && !this.$route.path.includes('/history/')) {
        return this.$route.path + "/code";
      } else if (this.$route.params.locationtype) {
        return (
          "/machines/" +
          this.$route.params.locationtype +
          "/" +
          this.$route.params.location +
          "/uncoded/" +
          this.$route.params.machine +
          "/code"
        );
      } else {
        return "/interface/" + this.$route.params.machine + "/uncoded/code";
      }
    },
    uncoded_stopcodes() {
      //return "test";
      return _.map(this.uncoded_stops, "stop").join(",");
    },
  },
  methods: {
    codeUncoded() {
      console.warn("adding return to route", this.returnTo);
      store.commit("uncoded/setSelected", this.uncoded_stops);
      if (this.returnTo !== null) {
        store.commit("uncoded/setReturnTo", this.returnTo);
      }
      this.$router.push(this.routerlinks.codeUncoded);
    },
    reload() {
      store
        .dispatch("machines/loadUncodedForMachine", {
          machine_id: this.$route.params.machine,
          reloadCachedValues: true,
          shift: this.shift_code,
        })
        .then(() => {
          this.loaded = true;
        });
    },
  },
  components: {
    LastUsedStopcodes,
  },
};
</script>

<style lang="scss">
</style>
