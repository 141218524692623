//import  _  from "lodash";


const state = () => ({
    keyboard: null,
    touch: null,
    minimalTimeout:+localStorage.getItem("minimalTimeout") || 10,
});

// getters
const getters = {

}

// actions
const actions = {

}
// mutations
const mutations = {
    lastEvent: (state, { type, t }) => {
        state[type] = t;
    },
    setMinimalTimeout: (state,t)=>{
        state.minimalTimeout = +t;
        localStorage.setItem("minimalTimeout",+t);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}