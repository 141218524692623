<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget widget-auto-height">
    <div v-if="machine && machine.empty !== true">
      <h1>
        <!-- 32 : status -->
        {{ $t("32") }} - {{ periodT ? $t(periodT) : "" }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div class="normal-status-wrap" v-if="!machine.prepare_status">
        <router-link
          :to="setStopLink"
          class="indicator status-large"
          :class="status"
        >
          <div
            v-if="machine.status === 'S' && !currentStopIsSetup"
            class="vertically-centered-content"
          >
            <!-- machine has a stop reason -->
            <h3 class="subheader">
              {{ machine.stop_description }}
            </h3>
            <p class="align-middle text-xxl">
              {{ machine.calendar_stop_time_duration_hh_mm }}
            </p>
          </div>
          <div v-else-if="machine.status === 'S' && currentStopIsSetup">
            <h3 class="subheader">
              {{ machine.stop_description }}
            </h3>
            <p>
              {{ $t("956") }}:{{ time_left_hhmm }}<br />

              {{ $t("600") }}/{{ $t("4258") }}<br />
              {{ time_passed_hhmm }}/{{ time_planned_hhmm }}
            </p>
          </div>
          <div v-else>
            <!-- machine is going -->
            <h3 class="subheader">
              {{ $t("786") }}
            </h3>
            <p class="align-middle text-xxl">
              {{ runtimePercentage }}%<br />
              {{ runtime }}/{{ runtimeStoptime }}
            </p>
            <div
              class="warning"
              v-if="!hasSf141 && machine.work_order_no === '-'"
              style="position: absolute; left: 5px; bottom: 5px"
            >
              <span class="fa-stack fa-4x" style="color: red">
                <i class="fas fa-siren-on fa-stack-1x fade-in-out"></i>
                <i class="fas fa-siren fa-stack-1x"></i> </span
              ><br />
              <span
                class="subtext"
                style="
                  position: absolute;
                  bottom: 20px;
                  left: 20px;
                  width: 150px;
                "
                >{{ $t("6070") }}</span
              >
            </div>
          </div>
        </router-link>
        <a
          class="btn btn-danger lesser"
          href="#"
          @click.prevent="CancelForcedStop"
          v-if="machine.force_stop_enabled"
        >
          <span v-if="!pressedStop">{{ $t("2780") }}</span>
          <loading v-else />
        </a>
      </div>
      <div class="prepare-status-wrap" v-else>
        <div class="indicator status-large" :class="status">
          <h3 class="subheader">
            {{ machine.stop_description }}
          </h3>
          <p>
            {{ $t("956") }}:{{ time_left_hhmm }}<br />

            {{ $t("600") }}/{{ $t("4258") }}<br />
            {{ time_passed_hhmm }}/{{ time_planned_hhmm }}
          </p>
        </div>
        <div class="button-wrap horizontal">
          <button
            class="btn btn-success lesser"
            href="#"
            @click.prevent="ControlDone"
          >
            <span v-if="!pressedControl"> {{ $t("1281") }}</span>
            <loading v-else />
          </button>
          <a class="btn btn-danger lesser" href="#" @click.prevent="Cancel">
            <span v-if="!pressedCancel">{{ $t("5014") }}</span>
            <loading v-else />
          </a>
        </div>
      </div>
    </div>
    <div v-if="machine && machine.empty === true">Machine does not exist.</div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import { mapState, mapGetters } from "vuex";
import { Notification } from "@/helpers/notifications";
import { secondsToHhMm } from "@/helpers/dates";
import store from "@/store";
import axios from "@/requests/axios";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";
import { Configuration } from "@/helpers/settings";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      machine_id: this.$route.params.machine,
      pressedStop: false,
      pressedCancel: false,
      pressedControl: false,
      pulse: null,
      config: new Configuration(
        "production-status",
        { period: "shift" },
        this.template
      ),
    };
  },
  props: ["template"],
  mounted() {
    this.pulse = new Pulse([
      {
        action: "machines/loadMachine",
        interval: 3,
        primaryKey: "machine_id",
        parameters: { machine_id: this.machine_id },
      },
      {
        action: "orders/loadRunningOrderForMachine",
        interval: 300,
        primaryKey: "machine_id",
        parameters: { machine_id: this.machine_id, reload: false },
      },
    ]);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  methods: {
    CancelForcedStop() {
      if (!this.pressedStop) {
        this.pressedStop = true;
        execute_json("cancel_forced_stop", {
          machine_id: this.machine_id,
        })
          .then(() => {
            this.machine.force_stop_enabled = true;
            this.pressedStop = false;
          })
          .catch((err) => {
            console.log(err);
            this.pressedStop = false;
          });
      }
    },
    Cancel() {
      if (!this.pressedCancel) {
        this.pressedCancel = true;
        execute_json("resume_setup", {
          machine_id: this.machine_id,
          set_status: "off",
          //sf326_stop_code:this.stop_reason
        })
          .then(() => {
            this.pressedCancel = false;
          })
          .catch((err) => {
            console.log(err);
            this.pressedCancel = false;
          });
        this.$router.push(this.setStopLink);
      }
    },
    ControlDone() {
      if (!this.pressedControl) {
        
        if (this.shouldBlock){
          let n = new Notification({
            header:"Configuration conflict",
            message:"SF61 does not support concurrent orders",
            state:"fail",
            popup:true,
          });
          return false;
        }
        if (this.shouldWarn){
           let n = new Notification({
            header:"Potential conflict",
            message:"Machine is configured to allow multiple orders, SF61 does not support concurrent orders",
            type: "message",
            state: "warning",
            selfClosing:false
          });
          //TODO:show warning
        }
        if (this.controlNeedsInput) {
          this.$router.push(
            "/interface/" + this.machine_id + "/first-piece-ok"
          );
          return false;
        }
        this.pressedControl = true;
        let note = Notification.request({
          message: "OK'ing first piece..",
        });
        execute_json("first_piece_ok", {
          machine_id: this.machine_id,
        })
          .then(() => {
            note.ok({
              message: "First piece ok!",
            });
            this.pressedControl = false;
          })
          .catch((err) => {
            console.log(err);
            note.fail({
              message: err.statusText,
            });
            this.pressedControl = false;
          });
      }
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      runningByMachine: "orders/runningByMachine",
      configByMachine: "machines/configByMachine",
      setupCodesByMachine: "stopcodes/setupCodesByMachine",
    }),
    order() {
      return this.runningByMachine(this.machine_id);
    },
    machine() {
      return this.machineById(this.machine_id);
    },

    machineConfig() {
      return this.configByMachine(this.machine_id);
    },
    setupCodes() {
      return this.setupCodesByMachine(this.machine_id);
    },
    currentStopIsSetup() {
      return (
        this.setupCodes.findIndex(
          (sc) => sc.stop_code == this.machine.stop_code
        ) !== -1
      );
    },
    controlNeedsInput() {
      return (
        this.machineHasSf(this.machine_id, "23") ||
        this.machineHasSf(this.machine_id, "24") ||
        this.machineHasSf(this.machine_id, "29") ||
        (this.machineHasSf(this.machine_id, "61") &&
          this.machine.concurrent_orders < 2)
      );
    },
    shouldWarn() {
      return (
        this.machineHasSf(this.machine_id, "61") &&
        this.machineConfig.multi_orders
      );
    },
    shouldBlock() {
      return (
        this.machineHasSf(this.machine_id, "61") &&
        this.machine.concurrent_orders > 1
      );
    },
    hasSf141() {
      return this.machineHasSf(this.machine_id, "141");
    },
    status() {
      let status = "";
      if (this.machine.status === "S") {
        if (this.machine.current_stop_reason === "-") {
          status += "status-uncoded";
        } else {
          status += "status-stop";
        }
      } else {
        status += "status-running";
      }
      status +=
        " c-" +
        (this.machine.stop_reason_color &&
        this.machine.stop_reason_color != "Automatic"
          ? this.machine.stop_reason_color.toLowerCase()
          : "") +
        " ";
      return status;
    },
    setStopLink() {
      return "/interface/" + this.machine_id + "/stopcode";
    },
    time_left_s() {
      return this.time_planned_s - this.time_passed_s;
    },
    time_left_hhmm() {
      return secondsToHhMm(this.time_left_s + 29); //+29 for rounding up.
    },
    time_planned_s() {
      //value in h
      let planned_time =
        this.order && this.order[0] && this.order[0].planned_setup_time
          ? this.order[0].planned_setup_time
          : 0;
      return planned_time * 3600;
    },
    time_planned_hhmm() {
      return secondsToHhMm(this.time_planned_s);
    },
    time_passed_s() {
      return +this.machine.calendar_setup_time_duration || 0;
    },
    time_passed_hhmm() {
      return secondsToHhMm(this.time_passed_s);
    },
    runtime() {
      let runtime = +this.machine.today_runtime;
      if (this.config.period === "shift") {
        runtime = +this.machine.current_shift_runtime;
      }
      return secondsToHhMm(runtime);
    },
    runtimeStoptime() {
      let runtime = +this.machine.today_runtime;
      let stoptime = +this.machine.today_calendar_stoptime;
      if (this.config.period === "shift") {
        runtime = +this.machine.current_shift_runtime;
        stoptime = +this.machine.current_shift_calendar_stop_time;
      }
      return secondsToHhMm(runtime + stoptime);
    },
    runtimePercentage() {
      let runtime = +this.machine.today_runtime;
      let stoptime = +this.machine.today_calendar_stoptime;
      if (this.config.period === "shift") {
        runtime = +this.machine.current_shift_runtime;
        stoptime = +this.machine.current_shift_calendar_stop_time;
      }
      return ((runtime * 100) / (runtime + stoptime)).toFixed(1);
    },
    periodT() {
      let periods = {
        h24: "448",
        today: "447",
        shift: "245",
      };
      //return "5";
      return periods[this.config.period];
    },
    items() {
      return [
        { header: this.$t("1329") },
        {
          tlabel: "447",
          handler: () => {
            this.config.setValues({ period: "today" });
          },
          selected: this.config.period === "today",
        },
        {
          tlabel: "245",
          handler: () => {
            this.config.setValues({ period: "shift" });
          },
          selected: this.config.period === "shift",
        },
      ];
    },
  },
  components: {
    CardMenu,
  },
};
</script>
<style scoped>
.fade-in-out {
  color: white;
  animation: fade 0.5s linear infinite;
}
@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>