import { execute_json } from '@/helpers/requests'
import axios from '@/requests/axios'
import { flattenMultiRow } from '@/requests/requestParcer'

const state = () => ({
  list: [],
  data: {}
})

// getters
const getters = {
  byRecordNo: (state) => (record_no) => {
    return state.list.find(r => r.record_no == record_no);
  }
}

// actions
const actions = {
  loadQueryList({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      execute_json('sql_queries', {
        feature: "get"
      })
        .then(({ data }) => {
          commit('setQueryList', {
            data,
          })
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  loadQueryData({ commit, rootState }, { record_no, parameters = {} }) {
    return new Promise((resolve, reject) => {
      execute_json('sql_queries', {
        feature: "execute",
        record_no,
        ...parameters
      }).then(({ data }) => {
        commit('setQueryData', { data, record_no })
        resolve(data)
      }).catch((err) => {
        reject(err);
      })
    })
  },
}
// mutations
const mutations = {
  setQueryList(state, { data }) {
    state.list = data.map(q => {
      return {
        ...q,
        label: q.description + (q.sql_query && q.sql_query?.toLowerCase()?.includes("as x") && q.sql_query?.toLowerCase()?.includes("as y") ? " | dashboard ready" : ""),
        value: q.record_no
      }
    });
  },
  setQueryData(state, { data, record_no }) {
    state.data[record_no] = data.sort((a, b) => a.x < b.x ? -1 : 1);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
