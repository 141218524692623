<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget left-aligned">
    <div v-if="machine">
      <h1>
        {{ $t("886") }}
        <card-menu :items="items"></card-menu>
      </h1>
      <div
        class="configuration-error vertically-centered-content"
        v-if="!traceabilityEnabled"
      >
        Traceability not enabled for company.
      </div>
      <div
        class="configuration-error vertically-centered-content"
        v-if="runningOrders.length > 1"
      >
        More then 1 running order.
      </div>
      <div
        class="configuration-error vertically-centered-content"
        v-if="runningOrders.length === 0"
      >
        {{ $t("5591") }}
      </div>
      <div class="normal-status-wrap" v-if="runningOrder" @click="openPopup">
        <a
          href="#"
          @click.prevent="() => {}"
          class="indicator status-large"
          :class="status"
        >
          {{ $t("1243") }}
          <div class="vertically-centered-content">
            <h3>
              <i class="fas fa-exclamation-triangle" v-if="!hasBatch"></i>
              {{
                hasBatch ? runningOrder.batch_no : $t("1249") + " " + $t("1155")
              }}
            </h3>
            <loading v-if="updating"/>
          </div>
        </a>
      </div>
    </div>
    <div
      class="popup-wrap"
      v-if="runningOrder !== null && popupOpen"
      @click.self="closePopup"
    >
      <div class="popup">
        <h3>
          <i class="fas fa-exclamation-triangle" v-if="!hasBatch"></i>
          {{ hasBatch ? runningOrder.batch_no : $t("1249") + " " + $t("1155") }}
        </h3>
        <label for="">{{ $t("3367") }}</label>
        <input
          type="text"
          v-model="form.batch_no"
          class="form-control"
          @focus="activeField = 'batch_no'"
        />
        <label for="">{{ $t("944") }} {{ $t("1244") }}</label>
        <input
          type="text"
          v-model="form.batch_qty"
          class="form-control"
          @focus="activeField = 'batch_qty'"
        />
        <label for="">{{ $t("781") }}</label>
        <input
          type="text"
          v-model="form.supplier"
          class="form-control"
          @focus="activeField = 'supplier'"
        />
        <br />
        <button class="btn btn-success" @click="saveAndClose">
          <i class="fas fa-chevron-right"></i>
        </button>

        <keyboard v-model="form[activeField]" />
      </div>
    </div>
  </div>
</template>
<script>
import { InterfaceComponent } from "@/helpers/machine";
import { execute_json } from "@/helpers/requests";

export default InterfaceComponent(
  {
    data() {
      return {
        lastOpen: null,
        popupOpen: false,
        warnInterval: null,
        activeField: "batch_no",
        updating:false,
        form: {
          batch_no: "",
          batch_qty: "",
          supplier: "",
        },
      };
    },
    mounted() {
      this.autoWarn();
      this.startWarnInterval();
    },
    beforeUnmount() {
      this.stopWarnInterval();
    },
    computed: {
      traceabilityEnabled() {
        return this.machineConfig.use_traceability == 1;
      },
      runningOrder() {
        return this.runningOrders.length == 1 ? this.runningOrders[0] : null;
      },
      hasBatch() {
        return this.runningOrder?.batch_no != "";
      },
      status() {
        return this.hasBatch ? "c-green" : "c-red";
      },
      shouldValidate() {
        return this.hasSf("108");
      },
      shouldWarn() {
        return this.hasSf("156");
      },
      newBatchNoIsSame() {
        return (
          this.hasBatch && this.form.batch_no == this.runningOrder?.batch_no
        );
      },
    },
    methods: {
      startWarnInterval() {
        this.stopWarnInterval();
        this.warnInterval = setInterval(this.autoWarn, 10 * 1000);
      },
      stopWarnInterval() {
        //console.log("removed warn interval");
        clearInterval(this.warnInterval);
      },
      autoWarn() {
        //console.log("auto warn check..");
        if (
          this.shouldWarn &&
          (this.lastOpen === null ||
            new Date().getTime() > this.lastOpen + 60 * 1000) &&
          !this.hasBatch
        ) {
          this.openPopup();
        }
      },
      openPopup() {
        this.lastOpen = new Date().getTime();
        this.popupOpen = true;
      },
      closePopup() {
        this.lastOpen = new Date().getTime();
        this.activeField = "batch_no";
        this.popupOpen = false;
      },
      updateBatch() {
        return execute_json("batch", {
          feature: "update_workorder_batch",
          debug: false,
          card_no: this.runningOrder?.card_no,
          batch_unit_id: this.form.batch_no,
          batch_supplier: this.form.supplier,
          batch_qty: this.form.batch_qty,
          restart: this.newBatchNoIsSame ? true : null,
        });
      },
      validateBatch() {
        if (this.shouldValidate) {
          return execute_json("batch", {
            feature: "validate_batch",
            debug: false,
            card_no: this.runningOrder?.card_no,
            batch_unit_id: this.form.batch_no,
            batch_supplier: this.form.supplier,
            batch_qty: this.form.batch_qty,
            restart: this.newBatchNoIsSame ? true : null,
          });
        } else {
          return Promise.resolve("done");
        }
      },
      saveAndClose() {
        this.validateBatch()
          .then(() => {
            this.updating = true;
            this.updateBatch().then(() => {
              this.pulse.shock();
              this.updating = false;
            });

            this.closePopup();
          })
          .catch((err) => {
            console.log(err); //error validating
          });
      },
    },
    components: {},
  },
  ["running"]
);
</script>