<template>
  <div>
    <Loading v-if="loading" />
    <div v-else>
      <c-table
        :data="items"
        primary-key="record_no"
        config-string="internal_activities"
        default-sort-key="due_date"
        default-sort-dir="asc"
        @selectionChanged="onSelectionChanged"
        :teleport="true"
      >
      </c-table>
    </div>
    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      machine_id: this.$route.params.machine,
      location_type: this.$route.params.locationtype,
      selectedRows: [],
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    store
      .dispatch("activities/loadActivitiesForSelectionOwner", {
        selectionOwner: this.selectionOwner,
      })
      .then(() => {
        this.loading = false;
        if (this.lastCreated) {
          this.preSelect();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      username: (state) => state.auth.username,
      machines: (state) => state.machines.index,
      activities: (state) => state.activities.index,
      lastCreated: (state) => state.activities.lastCreated,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      columnConfigByTemplate: "config/columnConfigByTemplate",
    }),
    disableNext() {
      return this.data[this.tab.dataKey].selected === null;
    },
    selectionOwner() {
      return this.machine_id === "all" ? this.username : this.machine_id;
    },
    items() {
      return this.activities[this.selectionOwner] || [];
    },
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length > 0 && this.selectedRows[0]) {
        this.data[this.tab.dataKey].selected = this.selectedRows[0];
        store.dispatch("machines/loadSpecialFunctionsForMachine", {
          machine_id: this.selectedRows[0].machine_id,
          reloadCachedValues: false,
        });
      } else {
        this.data[this.tab.dataKey].selected = null;
      }
    },
    preSelect() {
      let selection = this.items.filter((i) => i.record_no == this.lastCreated);
      if (selection.length === 1) {
        this.data[this.tab.dataKey].selected = selection[0];
        store.dispatch("machines/loadSpecialFunctionsForMachine", {
          machine_id: selection[0].machine_id,
          reloadCachedValues: false,
        });
        this.$nextTick(function () {
          store.commit("activities/setLast", null);
          this.$emit("next");
        });
      }
    },
  },
  watch: {},
};
</script>