<template>
  <div class="machine-singular" v-if="machine">
    <p>{{ machine.machine_id }}</p>
    <div class="buttons-wrap">
      <router-link class="router-button" :to="routerlinks.stopcode">{{
        $t("15")
      }}</router-link>
      <router-link class="router-button" :to="routerlinks.uncoded">{{
        $t("5034")
      }}</router-link>
      <router-link class="router-button" :to="routerlinks.history">{{
        $t("612")
      }}</router-link>
      <!--
        <router-link class="router-button" :to="routerlinks.interface">{{$t('2660')}}</router-link>
        -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {},
  setup() {},
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      machine: (state) => state.bottomnav.data[0],
    }),
    routerlinks() {
      let [empty, viewcode, ...rest] = this.$route.path.split("/");
      if (viewcode === "all-machines" || viewcode === undefined) {
        viewcode = "machines";
      }
      return {
        stopcode:
          "/interface/" +
          this.machine.machine_id + "/stopcode",
        uncoded:
          "/interface/" +
          this.machine.machine_id + "/uncoded",
        history:
          "/interface/" +
          this.machine.machine_id + "/history-stops",
        interface: "/interface/" + this.machine.machine_id,
      };
    },
  },
  components: {
    // DxButton
  },
};
</script>

<style lang="scss">
</style>
