<template>
  <div class="textbox">
    <textarea
      name=""
      id=""
      cols="30"
      rows="4"
      class="form-control"
      :disabled="locked"
      v-model="modelValue.entered_value"
      @focus="focusOnText = true"
      @blur="blur"
    >
    </textarea>
    <keyboard
      v-model="modelValue.entered_value"
      v-show="focusOnText && !locked"
      :keyboard-class="keyboardClass"
    />
  </div>
</template>
<script>
import _ from "lodash";
export default {
  emits: ["update:modelValue", "next","blur", "lock"],
  props: {
    modelValue: {
      required: true,
    },
    item:{

    },
    active: {},
    required: {
      default: false,
    },
  },
  data() {
    return {
      focusOnText: false,
      keyboardClass: _.uniqueId("simple-keyboard-"),
    };
  },
  mounted(){
    this.$emit('lock',this.locked);
  },
  watch: {
    active(val) {
      if (!val) {
        this.focusOnText = false;
      } else {
        if (this.modelValue.entered_value === null){
          this.modelValue.entered_value = "";
        }
        this.modelValue.isdone = true;
        this.focusOnText = true;
      }
    },
    modelValue:{
      deep:true,
      handler:function(val){
        this.$emit("update:modelValue",val);
      }
    }
  },
  computed:{
    locked(){
      return !this.item.allow_user_edit
    }
  },
  methods: {
    blur() {
      this.$emit("blur");
    },
  },
};
</script>