<template>
  <router-link
    :to="to"
    class="fast-link-button indicator"
    v-if="
      !setup &&
      currentSetting != '-' &&
      currentSetting != null &&
      currentButton &&
      currentButton.link
    "
    :class="color"
  >
    <i :class="currentButton.icon" v-if="currentButton.icon"></i>
    <i v-else v-for="icon in currentButton.icons" :key="icon" :class="icon"
      >&nbsp;</i
    >
    <span v-if="value !== null">&nbsp;{{ value }}</span>
    <span class="caption" v-if="config.captions && currentButton.tlabel">{{
      $t(currentButton.tlabel)
    }}</span>
    <span class="caption" v-if="config.captions && currentButton.labelBy">{{
      labelBy
    }}</span>
  </router-link>
  <button
    :class="color"
    class="fast-link-button indicator"
    v-else-if="
      !setup &&
      currentSetting != '-' &&
      currentSetting != null &&
      currentButton != null
    "
    @click="handleButtonClick(currentButton.handler)"
  >
    <i
      :class="[currentButton.icon, functionLoad ? 'fa-spin' : '']"
      v-if="currentButton.icon"
    ></i>
    <i v-else v-for="icon in currentButton.icons" :key="icon" :class="icon"
      >&nbsp;</i
    >
    <span v-if="value !== null">&nbsp;{{ value }}</span>
    <span class="caption" v-if="config.captions && currentButton.tlabel">{{
      $t(currentButton.tlabel)
    }}</span>
    <span class="caption" v-if="config.captions && currentButton.labelBy">{{
      labelBy
    }}</span>
    <span
      class="superheader"
      v-if="config.captions && currentButton.superHeaderBy"
      >{{ superHeaderBy }}</span
    >
    <span class="header" v-if="config.captions && currentButton.headerBy">{{
      headerBy
    }}</span>
  </button>
  <div v-if="setup">
    <button class="fast-link-button" @click="popup">
      <i class="fas fa-cog"></i>
      <div class="chosen-config" v-if="currentButton">
        <i :class="currentButton.icon" v-if="currentButton.icon"></i>
        <i v-else v-for="icon in currentButton.icons" :key="icon" :class="icon"
          >&nbsp;</i
        >
        <span v-if="currentSetting == 'confirmSizeSf383'">{{
          sf383MaterialIndex
        }}</span>
      </div>
    </button>
    <div class="popup-wrap" @click.self="back" v-if="showPopup">
      <div class="popup">
        Button setup for {{ k }}
        <div class="flex-button-select-area">
          <div
            v-for="(v, k) in buttonOptions"
            :key="k"
            class="flex-button-picker"
          >
            <button
              class="fast-link-button indicator"
              @click="setConfig(k)"
              :class="{ 'c-green': k == currentSetting }"
            >
              <i :class="v.icon" v-if="v.icon"></i>
              <i v-else v-for="icon in v.icons" :key="icon" :class="icon"></i>
            </button>
            <p>{{ v.description }}</p>
          </div>
          <div class="flex-button-picker">
            <button
              class="fast-link-button indicator"
              @click="setConfig('-')"
              :class="{ 'c-green': '-' == currentSetting }"
            >
              <i class="fas fa-times"></i>
            </button>
            <p>Remove button</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import { execute_json } from "@/helpers/requests";
import { Notification } from "@/helpers/notifications";
import { getStorage, setStorage } from "@/helpers/storage";
export default {
  props: {
    setup: {
      required: true,
      type: Boolean,
    },
    config: {
      required: true,
      type: Object,
    },
    k: {
      required: true,
      type: String,
    },
  },
  mounted() {
    this.conditionalLoads();
    store.commit("events/addHandler", {
      event: "RFIDModule",
      handler: this.rfidEvent,
    });
    store.commit("events/addHandler", {
      event: "startWO",
      handler: this.loadSf383,
    });
    store.commit("events/addHandler", {
      event: "stopWO",
      handler: this.loadSf383,
    });
  },
  beforeUnmount() {
    store.commit("events/removeHandler", {
      event: "RFIDModule",
      handler: this.rfidEvent,
    });
    store.commit("events/removeHandler", {
      event: "startWO",
      handler: this.loadSf383,
    });
    store.commit("events/removeHandler", {
      event: "stopWO",
      handler: this.loadSf383,
    });
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders.runningOrders,
      plannedOrders: (state) => state.orders.plannedOrders,
      allActivities: (state) => state.activities.index,
      operationTexts: (state) => state.operations.texts,
      sf383info: (state) => state.parts.sf383info,
      companyId: (state) => state.auth?.company_id,
      username: (state) => state.auth.username,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      signedInByMachine: "machines/signedInByMachine",
      notificationSettingsBymachine: "machines/notificationSettingsBymachine",
      configByMachine: "machines/configByMachine",
    }),
    machine_config() {
      return this.configByMachine(this.machine_id);
    },
    hasSf195() {
      return this.machineHasSf(this.machine_id, "195");
    },
    sf195StorageString() {
      return this.companyId + "-" + this.machine_id + "-sf195value";
    },
    link() {
      return "/machines/all";
    },
    currentSetting() {
      return this.config[this.k];
    },
    currentButton() {
      return this.currentSetting
        ? this.buttonOptions[this.currentSetting]
        : null || null;
    },
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    notificationSettings() {
      return this.notificationSettingsBymachine(this.machine_id);
    },
    has85() {
      return this.machineHasSf(this.machine_id, "85");
    },
    sf383infoForMachine() {
      return (
        this.sf383info[this.machine_id]?.[this.sf383MaterialIndex] || {
          isEmpty: true,
        }
      );
    },
    uncoded_used() {
      return this.has85
        ? this.machine.uncoded_stops_24h
        : this.machine.uncoded_stops_shift24h;
    },
    status() {
      if (+this.uncoded_used > 1) {
        return "c-red";
      } else if (+this.uncoded_used === 1) {
        return "c-yellow";
      }
      return "c-green";
    },
    to() {
      return this.currentButton
        ? this.currentButton.link.replace(":machine", this.machine_id)
        : null || null;
    },
    order() {
      return this.orders[this.machine_id] || [];
    },
    pOrders() {
      return this.plannedOrders[this.machine_id] || [];
    },
    runningOrderColor() {
      return this.order.length > 0 ? "c-green" : "c-gray";
    },
    plannedOrderColor() {
      return this.pOrders.length > 0 ? "c-green" : "c-gray";
    },
    signedIn() {
      return this.signedInByMachine(this.machine_id);
    },
    signedInAmount() {
      return this.signedIn.length || 0;
    },
    signedInColor() {
      if (this.rfidError !== null) {
        return "c-red";
      }
      return this.signedInAmount > 0 ? "c-green" : "c-red";
    },
    signedInLabel() {
      if (this.rfidError !== null) {
        return "RFID:" + this.rfidError;
      }
      return this.signedInAmount === 1
        ? this.signedIn[0].employee_name
        : this.signedInAmount + " signed in.";
    },
    activitiesForSelection() {
      return this.allActivities[this.machine_id] || [];
    },
    activitiesAmount() {
      return this.activitiesForSelection.length || 0;
    },
    activitiesColor() {
      return this.activitiesAmount > 1
        ? "c-red"
        : this.activitiesAmount == 1
        ? "c-yellow"
        : "c-green";
    },
    operationTextsExists() {
      return this.order.some((order) => {
        return this.operationTexts[order.card_no]?.length > 0;
      });
    },
    operationTextColor() {
      return this.operationTextsExists ? "c-yellow" : "c-gray";
    },
    color() {
      return this.currentButton?.colorBy
        ? this[this.currentButton.colorBy]
        : null || null;
    },
    value() {
      return this.currentButton?.valueBy
        ? this[this.currentButton.valueBy]
        : null || null;
    },
    labelBy() {
      return this.currentButton?.labelBy
        ? this[this.currentButton.labelBy]
        : null || null;
    },
    headerBy() {
      return this.currentButton?.headerBy
        ? this[this.currentButton.headerBy]
        : null || null;
    },
    superHeaderBy() {
      return this.currentButton?.superHeaderBy
        ? this[this.currentButton.superHeaderBy]
        : null || null;
    },
    alarmColor() {
      return this.notificationSettings.alarm_on_stop ? "c-green" : "c-gray";
    },
    escalateColor() {
      return this.notificationSettings.escalation_active ? "c-green" : "c-gray";
    },
    materialColor() {
      return this.order.length === 0
        ? "c-gray"
        : this.order[0].confirmed_materials
        ? "c-green"
        : "c-red";
    },
    sf383Label() {
      if (this.order && this.order.length > 0) {
        let sf383infoForMachine = this.sf383infoForMachine;
        if (
          [
            sf383infoForMachine.material_qty,
            sf383infoForMachine.material_planned_qty,
          ].some((v) => v)
        ) {
          return (
            sf383infoForMachine.material_qty +
            " / " +
            sf383infoForMachine.material_planned_qty
          );
        }
      }
      return "-";
    },
    sf383MaterialIndex() {
      return this.config[this.k + "-material_index"] || 1;
    },
    sf383Header() {
      if (this.order && this.order.length > 0) {
        let sf383infoForMachine = this.sf383infoForMachine;
        if (
          [
            sf383infoForMachine.part_info11,
            sf383infoForMachine.part_info13,
            sf383infoForMachine.part_info12,
          ].some((v) => v)
        ) {
          return (
            +sf383infoForMachine.part_info11 +
            +sf383infoForMachine.part_info13 +
            " x " +
            sf383infoForMachine.part_info12
          );
        } else {
          return "? x ? ";
        }
      }
      return "";
    },
    sf383SuperHeader() {
      if (this.order && this.order.length > 0) {
        let sf383infoForMachine = this.sf383infoForMachine;
        return sf383infoForMachine.part_no || "-";
      }
      return "-";
    },
    sf383Color() {
      return this.executing383
        ? this.error383
          ? "c-red"
          : "c-green"
        : "c-gray";
    },
    hasAutoSignInEnabled() {
      return this.machineHasSf(this.machine_id, "178");
    },
  },
  watch: {
    currentSetting() {
      this.conditionalLoads();
    },
  },
  methods: {
    conditionalLoads() {
      this.loadSignedIn();

      this.loadIA();
      this.loadSf383();
    },
    rfidEvent({ machine_id, event_message }) {
      if (machine_id == this.machine_id) {
        console.log(event_message);
        this.rfidError = event_message;
        setTimeout(
          (() => {
            this.rfidError = null;
          }).bind(this),
          5000
        );
      }
    },
    popup() {
      this.showPopup = true;
    },
    back() {
      this.showPopup = false;
    },
    setConfig(key) {
      this.config.setValues({ [this.k]: key });
      if (key == "confirmSizeSf383") {
        let value = prompt("Pick material index", 1);
        this.config.setValues({ [this.k + "-material_index"]: value });
      }
      this.loadSignedIn();
      this.loadIA();
      this.loadSf383();
      this.back();
    },
    loadSignedIn() {
      if (
        this.config[this.k] === "signedIn" ||
        this.config[this.k] === "quickPrintLabel"
      ) {
        store.dispatch("machines/loadSignedInForMachine", this.machine_id);
      }
    },
    loadIA() {
      if (this.config[this.k] === "runActivity") {
        store.dispatch("activities/loadActivitiesForSelectionOwner", {
          selectionOwner: this.machine_id,
        });
      }
    },
    loadSf383() {
      if (this.config[this.k] === "confirmSizeSf383") {
        //execute_json("sf383", { machine_id: this.machine_id })
        store.dispatch("parts/Sf383fetch", {
          machine_id: this.machine_id,
          material_index: this.sf383MaterialIndex,
        });
      }
    },
    handleButtonClick(h) {
      this[h]();
    },
    resetCycletime() {
      this.functionLoad = true;
      let n = Notification.request({
        header: this.$t("948"),
        message: this.$t("948"),
      });
      execute_json(
        "reset_cycle_time",
        { machine_id: this.machine_id },
        { disableErrorNotification: true }
      )
        .then(() => {
          n.ok({
            message: "OK",
          });
        })
        .catch((err) => {
          n.fail({
            message: err.statusText,
          });
        })
        .finally(() => {
          setTimeout(
            (() => {
              this.functionLoad = false;
            }).bind(this),
            1500
          );
        });
    },
    toggleMachineNotificationsAlarm() {
      execute_json("machine_notification", {
        machine_id: this.machine_id,
        feature: "set",
        alarm_on_stop: !this.notificationSettings.alarm_on_stop,
      }).then(() => {
        this.reloadNotifications();
      });
    },
    toggleMachineNotificationsEscalation() {
      execute_json("machine_notification", {
        machine_id: this.machine_id,
        feature: "set",
        escalation_active: !this.notificationSettings.escalation_active,
      }).then(() => {
        this.reloadNotifications();
      });
    },
    reloadNotifications() {
      store.dispatch("machines/loadNotificationSettings", {
        machine_id: this.machine_id,
      });
    },
    calculateResultsSf34() {
      store.commit("popups/addPopup", {
        type: "calculate-results",
        canClose: true,
        data: {},
      });
    },
    openMaterialPopupHandler() {
      if (this.materialColor !== "c-gray") {
        store.commit("popups/addPopup", {
          type: "confirm-materials",
          data: {
            order: this.order[0],
          },
        });
      }
    },
    confirmSizeSf383() {
      if (!this.executing383 && this.sf383infoForMachine.part_no) {
        let n = Notification.request({
          header: this.$t("1364"),
          message: this.$t("2897"),
        });
        this.executing383 = true;
        execute_json("sf383", {
          machine_id: this.machine_id,
          material_index: this.sf383MaterialIndex,
        })
          .then(({ data }) => {
            store.commit("parts/setSf383Info", {
              data: data.length ? data[0] : data,
              machine_id: this.machine_id,
              material_index: this.sf383MaterialIndex,
            });

            n.ok({ message: "confirmed" });
          })
          .catch(() => {
            this.error383 = true;
            n.close();
          })
          .finally(() => {
            setTimeout(
              (() => {
                this.executing383 = false;
                this.error383 = false;
              }).bind(this),
              3000
            );
            //this.executing383 = false;
          });
      }
    },
    quickPrintLabel() {
      let machine_id = this.machine_id;
      if (
        this.order.length != 1 ||
        !(
          this.machineHasSf(this.machine_id, "385") ||
          (this.hasAutoSignInEnabled && this.signedIn.length == 1)
        )
      ) {
        //missing data, redirect
        this.$router.push("/interface/" + machine_id + "/print-label");
      } else {
        if (!this.executingStop) {
          this.executingStop = true;
          let card_no = this.order?.[0].card_no;
          let employee = this.machineHasSf(this.machine_id, "385")
            ? this.username
            : this.signedIn[0]?.employee_number;
          let n = Notification.request({
            message: "Printing labels",
          });
          execute_json("print_pallet_flag", {
            card_no,
            good_qty: 1,
            scrap_qty: 0,
            employee,
            number_of_copies: 1,
            number_of_flags: 1,
            pallet_flag_printer: 0,
            machine_id,
            pallet_flag_type: "label",
          })
            .then(() => {
              console.log("wrapping up with another call");
              n.ok({
                message: "ok!",
              });
            })
            .catch((err) => {
              n.fail({
                message: err.statusText,
              });
            })
            .finally(() => {
              setTimeout(
                (() => {
                  this.executingStop = false;
                }).bind(this),
                3000
              );
            });
        }
      }
      //console.log(card_no, employee, machine_id);
    },
    quickSelectOrder(group = "dhalt") {
      return new Promise((resolve, reject) => {
        if (this.order.length === 1) {
          resolve(this.order[0]);
        } else if (this.order.length === 0) {
          resolve(null);
        } else {
          store.commit("popups/addPopup", {
            type: "decision-halt",
            group,
            data: {
              tmessage: "1267",
              data: {},
              theader: "586",
              infoComponent: null,
              decisionComponent: "running-order-select",
              resolve,
              reject,
              rejectMessage: "Stopped by SF393",
              timeout: 120,
              timeoutAction: reject,
            },
            canClose: false,
          });
        }
      });
    },
    quickSelectQuantity(group = "dhalt") {
      return new Promise((resolve, reject) => {
        if (this.machineHasSf(this.machine_id, "386")) {
          resolve(1);
          return;
        }
        store.commit("popups/addPopup", {
          type: "decision-halt",
          group,
          data: {
            //tmessage: "1267",
            data: {},
            theader: "574",
            infoComponent: null,
            decisionComponent: "quantity-select",
            presetQuantity: this.hasSf195
              ? getStorage(this.sf195StorageString) || null
              : null,
            resolve,
            reject,
            rejectMessage: "Stopped by SF393",
            timeout: 120,
            timeoutAction: reject,
          },
          canClose: false,
        });
      });
    },
    quickSelectEmployee(group = "dhalt") {
      return new Promise((resolve, reject) => {
        if (this.machineHasSf(this.machine_id, "386")) {
          resolve(this.username);
          return;
        }
        if (this.machineHasSf(this.machine_id, "396")) {
          resolve("-");
          return;
        }
        if (this.hasAutoSignInEnabled && this.signedIn.length === 1) {
          resolve(this.signedIn[0].employee_number);
          return;
        }
        store.commit("popups/addPopup", {
          type: "decision-halt",
          group,
          data: {
            //tmessage: "1267",
            data: {},
            theader: "574",
            infoComponent: null,
            decisionComponent: "employee-select",
            resolve,
            reject,
            rejectMessage: "Stopped by SF393",
            timeout: 120,
            timeoutAction: reject,
          },
          canClose: false,
        });
      });
    },
    quickSelectAmount(group = "dhalt") {
      return new Promise((resolve, reject) => {
        store.commit("popups/addPopup", {
          type: "decision-halt",
          group,
          data: {
            //tmessage: "1267",
            data: {},
            theader: "1351",
            infoComponent: null,
            decisionComponent: "quantity-select",
            resolve,
            reject,
            rejectMessage: "Stopped by SF393",
            timeout: 120,
            timeoutAction: reject,
          },
          canClose: false,
        });
      });
    },
    quickPrintPalletflag() {
      let machine_id = this.machine_id;
      if (!this.executingStop) {
        Promise.all([
          this.quickSelectOrder("multi-pallet-flag"),
          this.quickSelectQuantity("multi-pallet-flag"),
          this.quickSelectEmployee("multi-pallet-flag"),
        ])
          .then(([order, good_qty, employee]) => {
            this.executingStop = true;
            let card_no = order.card_no;
            let n = Notification.request({
              message: "Printing pallet flags",
            });

            execute_json("print_pallet_flag", {
              card_no,
              good_qty,
              employee,
              number_of_copies:1,
              pallet_flag_printer: this.machine_config.pallet_flag_printer,
              machine_id,
              pallet_flag_type: "pallet_flag",
            })
              .then(() => {
                console.log("wrapping up with another call");
                if (this.hasSf195) {
                  console.log("setting storage");
                  setStorage(this.sf195StorageString, good_qty);
                }
                n.ok({
                  message: "ok!",
                });
              })
              .catch((err) => {
                n.fail({
                  message: err.statusText,
                });
              })
              .finally(() => {
                setTimeout(
                  (() => {
                    this.executingStop = false;
                  }).bind(this),
                  3000
                );
              });
          })
          .catch((err) => {
            store.commit("popups/closeGroup", "multi-pallet-flag");
          });
      }
      //console.log(card_no, employee, machine_id);
    },
    quickPrintMultiPalletflag() {
      let machine_id = this.machine_id;
      if (!this.executingStop) {
        Promise.all([
          this.quickSelectOrder("multi-pallet-flag"),
          this.quickSelectQuantity("multi-pallet-flag"),
          this.quickSelectEmployee("multi-pallet-flag"),
          this.quickSelectAmount("multi-pallet-flag"),
        ])
          .then(([order, good_qty, employee, number_of_copies]) => {
            console.log(order, good_qty, number_of_copies);
            this.executingStop = true;
            let card_no = order.card_no;
            let n = Notification.request({
              message: "Printing pallet flags",
            });

            execute_json("print_pallet_flag", {
              card_no,
              good_qty,
              employee,
              number_of_copies,
              pallet_flag_printer: this.machine_config.pallet_flag_printer,
              machine_id,
              pallet_flag_type: "pallet_flag",
            })
              .then(() => {
                console.log("wrapping up with another call");
                if (this.hasSf195) {
                  console.log("setting storage");
                  setStorage(this.sf195StorageString, good_qty);
                }
                n.ok({
                  message: "ok!",
                });
              })
              .catch((err) => {
                n.fail({
                  message: err.statusText,
                });
              })
              .finally(() => {
                setTimeout(
                  (() => {
                    this.executingStop = false;
                  }).bind(this),
                  3000
                );
              });
          })
          .catch((err) => {
            store.commit("popups/closeGroup", "multi-pallet-flag");
          });
      }
      //console.log(card_no, employee, machine_id);
    },
  },
  data() {
    return {
      showPopup: false,
      cGreen: "c-green",
      functionLoad: false,
      executing383: false,
      executingStop: false,
      error383: false,
      rfidError: null,
      buttonOptions: {
        machines: {
          link: "/machines/all",
          icon: "fas fa-globe",
          description: "link to machine interface",
          colorBy: "cGreen",
          tlabel: "3",
        },
        plannedOrders: {
          link: "/interface/:machine/planned-orders",
          icons: ["fas  fa-flag-checkered", "fas fa-file-alt"],
          description: "link to planned orders",
          colorBy: "plannedOrderColor",
          tlabel: "2568",
        },
        runningOrders: {
          link: "/interface/:machine/running-orders",
          icons: ["fas  fa-flag-checkered", "fas fa-running"],
          description: "link to running orders",
          colorBy: "runningOrderColor",
          tlabel: "1978",
        },
        reportQuantity: {
          link: "/interface/:machine/report-quantity",
          icon: "fas fa-thumbs-up",
          description: "link to report quantity",
          colorBy: "runningOrderColor",
          tlabel: "2709",
        },
        scrapQuantity: {
          link: "/interface/:machine/scrap-quantity",
          icon: "fas fa-thumbs-down",
          description: "link to scrap quantity",
          colorBy: "runningOrderColor",
          tlabel: "2712",
        },
        uncodedStops: {
          link: "/interface/:machine/uncoded",
          icon: "fas fa-hand-paper",
          description: "link to uncoded stops",
          colorBy: "status",
          valueBy: "uncoded_used",
          tlabel: "3471",
        },
        documents: {
          link: "/interface/:machine/documents",
          icon: "fas fa-file-alt",
          description: "link to order documents",
          colorBy: "runningOrderColor",
          tlabel: "3162",
        },
        printPalletFlag: {
          link: "/interface/:machine/print-pallet-flag",
          icons: ["fas fa-print", "fas fa-flag"],
          description: "link to print pallet flag",
          colorBy: "runningOrderColor",
          tlabel: "426",
        },
        printLabel: {
          link: "/interface/:machine/print-label",
          icons: ["fas fa-print", "fas fa-tag"],
          description: "link to print labels",
          colorBy: "runningOrderColor",
          tlabel: "426",
        },
        signedIn: {
          link: "/interface/:machine/signed-in",
          icon: "fas fa-users",
          description: "signing in",
          colorBy: "signedInColor",
          valueBy: "signedInAmount",
          labelBy: "signedInLabel",
        },
        createActivity: {
          link: "/interface/:machine/create-activity",
          icons: ["fas fa-shoe-prints", "far fa-plus"],
          description: "create activity",
          colorBy: "cGreen",

          tlabel: "4670",
        },
        runActivity: {
          link: "/interface/:machine/run-activity",
          icons: ["fas fa-shoe-prints", "far fa-running"],
          description: "run activity",
          colorBy: "activitiesColor",
          valueBy: "activitiesAmount",

          tlabel: "28",
        },
        orderByPart: {
          link: "/interface/:machine/order-by-part",
          icon: "fas fa-flag-checkered",
          description: "start order by part",
          colorBy: "cGreen",
          tlabel: "964",
        },
        printPalletFlagHistory: {
          link: "/interface/:machine/pallet-flag-history",
          icons: ["fas fa-print", "fas fa-clock"],
          description: "link to pallet flag history",
          colorBy: "runningOrderColor",
          tlabel: "426",
        },
        printLabelHistory: {
          link: "/interface/:machine/label-history",
          icons: ["fas fa-print", "fas fa-tag"],
          description: "link to label history (SF376)",
          colorBy: "runningOrderColor",
          tlabel: "1268",
        },
        materials: {
          link: "/interface/:machine/materials",
          icon: "fas fa-toolbox",
          description: "link to materials",
          colorBy: "cGreen",
          tlabel: "699",
        },
        operationText: {
          link: "/interface/:machine/operation-text",
          icon: "fas fa-sticky-note",
          description: "link to operation-text",
          colorBy: "operationTextColor",
          tlabel: "2767",
        },
        resetCycletime: {
          handler: "resetCycletime",
          icon: "fas fa-clock",
          description: "reset cycle time: SF4",
          colorBy: "cGreen",
          tlabel: "948",
        },
        toggleNotificationAlarm: {
          handler: "toggleMachineNotificationsAlarm",
          icon: "fas fa-alarm-clock",
          description: "Toggle MachineNotifications Alarm",
          colorBy: "alarmColor",
          tlabel: "852",
        },
        toggleNotificationEscalation: {
          handler: "toggleMachineNotificationsEscalation",
          icon: "fas fa-alarm-exclamation",
          description: "Toggle MachineNotifications Escalation",
          colorBy: "escalateColor",
          tlabel: "6045",
        },
        openMaterialPopup: {
          handler: "openMaterialPopupHandler",
          icons: ["fas fa-toolbox", "fas fa-check"],
          description: "Material popup hnalder",
          colorBy: "materialColor",
          tlabel: "5000",
        },
        confirmSizeSf383: {
          handler: "confirmSizeSf383",
          icon: "fas fa-shower",
          description: "Confirm size (SF383)",
          colorBy: "sf383Color",
          labelBy: "sf383Label",
          headerBy: "sf383Header",
          superHeaderBy: "sf383SuperHeader",
        },
        quickPrintLabel: {
          handler: "quickPrintLabel",
          icons: ["fas fa-tag", "fas fa-print"],
          description: "Print 1 label",
          colorBy: "runningOrderColor",
          tlabel: "7111",
        },
        quickPrintPalletflag: {
          handler: "quickPrintPalletflag",
          icons: ["fas fa-flag", "fas fa-print"],
          description: "Print 1 pallet flag",
          colorBy: "runningOrderColor",
          tlabel: "3090",
        },
        quickPrintMultiPalletflag: {
          handler: "quickPrintMultiPalletflag",
          icons: ["fas fa-flag", "fas fa-plus", "fas fa-print"],
          description: "Print x pallet flag",
          colorBy: "runningOrderColor",
          tlabel: "3090",
        },
        /*
          calculateResults:{
          handler: "calculateResultsSf34",
          icon: "fas fa-calculator",
          description: "Calculate results SF34",
          colorBy: "cGreen",
          tlabel: "2758",
        }
        */
      },
    };
  },
};
</script>

<style lang="scss">
/* TODO: move to proper place */
.fast-link-button {
  position: relative;
  .chosen-config {
    opacity: 0.5;
    bottom: -20px;
    right: 5px;
    position: absolute;
  }
  .caption {
    display: block;
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    white-space: nowrap;
    max-width: 100%;
    text-align: left;
  }
  .header {
    display: block;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    white-space: nowrap;
  }
  .superheader {
    display: none;
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 22px;
    white-space: nowrap;
  }
}
.big-button > .fast-link-button .header {
  font-size: 22px;
}
.big-button > .fast-link-button .superheader {
  display: block;
}
.flex-button-picker {
  display: flex;
  width: 33%;
  @media screen and (max-width: 600px) {
    width: 50% !important;
  }
  button {
    max-width: 80px;
  }
  p {
    padding: 10px;
  }
}
.flex-button-select-area {
  display: flex;
  flex-wrap: wrap;
}
</style>