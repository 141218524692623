<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div v-if="machine" class="max-align">
      <h1>
        <!-- 456 : status -->
        {{ $t("614") }} - {{ periodT ? $t(periodT) : "" }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div class="flex has-chart" style="flex: 1">
        <graph
          :period="config.period"
          :machine="machine"
          :labelMode="config.labelMode"
          :interval="interval"
          :addRuntime="config.addRuntime"
          :disableLegend="config.disableLegend"
          task="stopreasons"
          :type="config.type"
          labelStyle="short"
          :animationDuration="10"
          :maxN="config.maxN"
          v-if="config.isLoaded"
        />
      </div>
      <div class="center-widget" v-if="empty">
        {{ $t("5474") }}
      </div>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import Graph from "@/components/Graph";
import Chart from "chart.js";
import { mapState, mapGetters } from "vuex";
import { Configuration } from "@/helpers/settings";
//import { Pulse } from "@/helpers/heartbeat";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      machine_id: this.$route.params.machine,
      //currentAction: "stops/loadStopreasons24H",
      previousAction: null,
      empty: false,
      ctx: null,
      chart: null,
      config: new Configuration(
        this.storageKey,
        {
          maxN: null,
          type: "doughnut",
          period:"shift",
          addRuntime: true,
          disableLegend: true,
          labelMode: "percentages",
        },
        this.template
      ),
      drawn: false,
      // pulse: new Pulse([], {}),
    };
  },
  props: {
    storageKey: {
      required: true,
    },
    template: {
      type: String,
    },
  },
  mounted() {
    //store.dispatch("config/loadWidgetPeriod", { widget: this.storageKey });
  },
  beforeUnmount() {
    //this.pulse.stop();
  },
  methods: {
    toggleLegend() {
      this.config.setValues({ disableLegend: !this.config.disableLegend });
    },
    setLabelsToHours() {
      this.config.setValues({ labelMode: "hours" });
    },
    setLabelsToPercentages() {
      this.config.setValues({ labelMode: "percentages" });
    },
    toggleRuntime() {
      this.config.setValues({ addRuntime: !this.config.addRuntime });
    },
    setMaxNTo(n) {
      this.config.setValues({ maxN: n });
      //setStorage("SR_overview_widget_" + this.storageKey + "_chart_maxN", n);
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      stops: (state) => state.stops,
      periods: (state) => state.config.periods,
    }),
    period() {
      return this.periods["widget_" + this.storageKey];
    },
    ...mapGetters({ machineById: "machines/byId" }),
    machine() {
      return this.machineById(this.machine_id);
    },
    interval() {
      return ["24h", "today", "shift"].includes(this.config.period) ? 180 : 7200;
    },
    periodT() {
      let periods = {
        "24h": "448",
        today: "447",
        shift: "245",
        week: "914",
        "30d": "450",
      };
      //return "5";
      return periods[this.config.period] || "-";
    },
    items() {
      return [
        {
          header: this.$t("361"),
        },
        {
          label: "doughnut",
          handler: () => {
            this.config.setValues({ type: "doughnut" });
          },
          selected: this.config.type == "doughnut",
        },
        {
          label: "pie",
          handler: () => {
            this.config.setValues({ type: "pie" });
          },
          selected: this.config.type == "pie",
        },
        {
          tlabel: "3928",
          handler: () => {
            this.config.setValues({ type: "bar" });
          },
          selected: this.config.type == "bar",
        },
        { divider: true },
        {
          header: this.$t("1329"),
        },
        {
          tlabel: "448",
          handler: () => {
            this.config.setValues({ period: "24h" });
          },
          selected: this.config.period == "24h",
        },
        {
          tlabel: "447",
          handler: () => {
            this.config.setValues({ period: "today" });
          },
          selected: this.config.period == "today",
        },
        {
          tlabel: "245",
          handler: () => {
            this.config.setValues({ period: "shift" });
          },
          selected: this.config.period == "shift",
        },
        {
          tlabel: "914",
          handler: () => {
            this.config.setValues({ period: "week" });
          },
          selected: this.config.period == "week",
        },
        {
          tlabel: "450",
          handler: () => {
            this.config.setValues({ period: "30d" });
          },
          selected: this.config.period == "30d",
        },
        { divider: true },
        {
          header: this.$t("497"),
        },
        {
          //tlabel:this.labelMode === 'hours' ? "6204" : "697",
          label:
            this.config.labelMode === "hours"
              ? "set labels to %"
              : "Set labels to hh:mm",
          handler:
            this.config.labelMode === "hours"
              ? this.setLabelsToPercentages
              : this.setLabelsToHours,
        },
        {
          //tlabel:this.labelMode === 'hours' ? "6204" : "697",
          label: this.config.addRuntime ? "disable runtime" : "show runtime",
          handler: this.toggleRuntime,
        },
        {
          tlabel: "1268",
          handler: this.toggleLegend,
          selected: !this.config.disableLegend,
        },
        { submenu: true },
        { divider: true },
        {
          header: this.$t("1423") + " " + this.$t("2546"),
        },
        {
          label: "-",
          handler: () => {
            this.setMaxNTo(null);
          },
          selected: this.config.maxN == null,
        },
        {
          label: "5",
          handler: () => {
            this.setMaxNTo(5);
          },
          selected: this.config.maxN == 5,
        },
        {
          label: "10",
          handler: () => {
            this.setMaxNTo(10);
          },
          selected: this.config.maxN == 10,
        }
        /*
                {
                    tlabel:"697",
                    label:"hour labels",
                    handler:this.setLabelsToHours
                },
                {
                    tlabel:"6204",
                    label:"percentage labels",
                    handler:this.setLabelsToPercentages
                }
                */
      ];
    },
  },
  watch: {
    stopSet: {
      deep: true,
      handler() {
        if (this.chart !== null) {
          // this.chart.destroy();
        }
        //this.checkEmpty();
        //this.drawChart();
      },
    },
  },
  components: {
    CardMenu,
    Graph,
  },
};
</script>