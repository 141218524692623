<template>
  <div
    class="col-12 col-xl-3 col-lg-4 col-md-6 widget left-aligned"
    v-if="has14"
  >
    <div v-if="machine">
      <h1>
        {{ $t("2739") }}
        <card-menu :items="items"></card-menu>
      </h1>
      <div class="normal-status-wrap">
        <div
          href="#"
          @click.prevent="() => {}"
          class="indicator status-large"
          :class="{
            'c-green':
              (lastAttemptSuccess || isVerified) && runningOrders.length > 0,
            'c-gray': runningOrders.length === 0,
            'c-red':
              (!isVerified && lastAttemptSuccess !== true) ||
              lastAttemptSuccess === false,
          }"
        >
          <div class="vertically-centered-content">
            <h3>
              <table class="table table-borderless align-left left ean-code">
                <tr>
                  <td>
                    {{ $t("393") }}
                  </td>
                  <td>
                    {{ eanCode.art_artnr }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ $t("394") }}
                  </td>
                  <td>
                    {{ eanCode.art_ben }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ $t("1355") }}
                  </td>
                  <td>
                    {{ eanCode.art_ean }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ $t("1356") }}
                  </td>
                  <td>
                    <input type="text" v-model="lastScanned" />
                  </td>
                </tr>
              </table>
              <br />
              <i
                class="fas"
                v-if="lastAttemptSuccess !== null"
                :class="{
                  'fa-check': lastAttemptSuccess,
                  'fa-times': !lastAttemptSuccess,
                }"
              ></i>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { InterfaceComponent } from "@/helpers/machine";
import { execute_json } from "@/helpers/requests";
import { mapGetters } from "vuex";
import store from "@/store";

export default InterfaceComponent(
  {
    data() {
      return {
        scannedCode: "",
        lastScanned: "",
        reading: false,
        lastAttemptSuccess: null,
        lastAttemptTimeout: null,
      };
    },
    mounted() {
      if (this.has14) {
        this.enableScan();
        this.fetchCurrent();
      }
    },
    beforeUnmount() {
      this.disableScan();
    },
    computed: {
      ...mapGetters({
        eanCodeByWorkOrderNo: "orders/eanCodeByWorkOrderNo",
      }),
      has14() {
        return this.hasSf("14");
      },
      eanCodes() {
        return this.eanCodeByWorkOrderNo(this.work_order_no, this.machine_id);
      },
      eanCode() {
        return this.eanCodes?.length ? this.eanCodes[0] : {};
      },
      work_order_no() {
        return this.runningOrders?.[0]?.work_order_no;
      },
      isVerified() {
        return this.machine.barcode_verified;
      },
    },
    methods: {
      enableScan() {
        this.disableScan();
        document.addEventListener("keypress", this.handleKeyPress);
        this.isScanning = true;
      },
      fetchCurrent() {
        if (this.has14) {
          store.dispatch("orders/loadEanCodeForWorkOrderNo", {
            machine_id: this.machine_id,
            work_order_no: this.work_order_no,
          });
        }
      },
      disableScan() {
        document.removeEventListener("keypress", this.handleKeyPress);
        this.isScanning = false;
      },
      handleKeyPress(e) {
        //usually scanners throw an 'Enter' key at the end of read
        if (this.eanCodes[0]) {
          if (e.keyCode === 13) {
            if (this.scannedCode.length > 5) {
              this.handleEanCode();
              e.preventDefault();
              /// this.scannedCode ready to use
            }
            this.scannedCode = "";
          } else {
            this.scannedCode += e.key; //while this is not an 'enter' it stores the every key
          }
          //console.log(this.scannedCode);
          //run a timeout of 200ms at the first read and clear everything
          if (!this.reading) {
            this.reading = true;
            setTimeout(
              (() => {
                this.scannedCode = "";
                this.reading = false;
              }).bind(this),
              300
            );
          }
        }
      },
      handleEanCode() {
        console.log("final code:", this.scannedCode);
        this.lastScanned = this.scannedCode;
        if (this.scannedCode == this.eanCodes[0].art_ean) {
          this.lastAttemptSuccess = true;
          execute_json("sf14", {
            feature: "set_status",
            machine_id: this.machine_id,
            status: true,
          }).then(() => {});
        } else {
          this.lastAttemptSuccess = false;
          execute_json("sf14", {
            feature: "set_status",
            machine_id: this.machine_id,
            status: false,
          }).then(() => {});
        }
        clearTimeout(this.lastAttemptTimeout);
        this.lastAttemptTimeout = setTimeout(
          (() => {
            this.lastAttemptSuccess = null;
          }).bind(this),
          10000
        );
      },
    },
    watch: {
      work_order_no: "fetchCurrent",
    },
    components: {},
  },
  ["running"]
);
</script>
<style scoped lang="scss">
.ean-code {
  margin: 10px;
  color: inherit;
  max-width: 100%;
  td {
    max-width: 150px;
    &:first-child {
      max-width: 100px;
    }
  }
  input {
    color: inherit;
    border: none;
    border: 1px solid rgba(255, 255, 255, 0.3);
    max-width: 150px;
  }
}
</style>