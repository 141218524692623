<template>
  <div>
    <nav class="navbar main-nav navbar-dark bg-dark fixed-top">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" @click="toggleNav">
          <img
            class=""
            src="@/assets/Balthzar.png"
            alt=""
            style="max-height: 40px"
          />
        </button>
        <component :is="location"></component>
        <top-right-dropdown />
      </div>
    </nav>
    <div class="spacer" style="height: 44px"></div>
    <div
      class="sidenav"
      :class="{ open: navbar.isOpen }"
      @click.self="toggleNav"
      @touchmove.self="stopMove"
    >
      <div class="sidenav-inner-wrap bg-secondary">
        <div class="content-wrap bg-secondary">
          <side-menu
            :hidden-for-machine="hiddenForMachine"
            :side-menu="sideMenu"
          ></side-menu>
        </div>
      </div>
    </div>
    <div class="container-xxxl" :class="{ open: navbar.isOpen }">
      <slot v-if="loaded"></slot>
      <loading v-else></loading>
    </div>
    <div
      class="popup-wrap"
      @click.self="disableSf326Popup"
      v-if="Sf326PopupActive"
    >
      <div class="popup">
        <b-table
          :data="setupCodes"
          :columnConfig="stopConfig"
          primary-key="record_no"
          :show-headers="false"
          caption="2319"
          :single-select="true"
          @selectionChanged="onStopcodeSelectionChanged"
        >
        </b-table>
      </div>
    </div>
    <bottom-buttons />
  </div>
</template>
<script>
import BottomButtons from "@/components/bottom-buttons";
import SideMenu from "./submenu/SideMenu";
import TopRightDropdown from "./submenu/TopRightDropdown";
import LocationSwap from "@/components/location-swap";
import MachineSwap from "@/components/machine-swap";
import { Notification } from "@/helpers/notifications";
import { productionMenuFilter, filterExclude } from "@/helpers/production";
import { loadLanguageFromCache } from "@/i18n";
import { useRoute } from "vue-router";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import axios from "@/requests/axios";
import { watch, ref } from "vue";
import { execute_json } from "@/helpers/requests";
import {
  startTrackingActivity,
  stopTrackingActivity,
} from "@/helpers/tracking";
export default {
  setup() {
    const route = useRoute();

    const title = ref(route.meta.title);
    const description = ref("");
    loadLanguageFromCache();
    store.dispatch("config/loadChildConfigs", { base_code: "webcolumns" });
    watch(
      () => route.path,
      () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
        if (route.params.locationtype) {
          store.commit(
            "sidebar/setLastLocationType",
            route.params.locationtype
          );
        }
        if (route.params.locationtype) {
          store.commit("sidebar/setLastLocation", route.params.location);
        }
      }
    );
    return {
      title,
      description,
    };
  },
  created() {
    if (this.singleMachine !== "-1" && this.singleMachine != this.machine_id) {
      this.$router.push("/interface/" + this.singleMachine);
    }
  },
  mounted() {
    this.loadDataForRoute(this.$route);
    store.dispatch("permissions/loadUnloadedPermissions").then(() => {
      if (!this.canUser("can-access-dashboard")) {
        this.$router.push("/machines/all");
      }
    });
    startTrackingActivity();
    setInterval(
      (() => {
        this.lastTimestamp = new Date().getTime();
      }).bind(this),
      5000
    );
  },
  beforeUnmount() {
    stopTrackingActivity();
  },
  watch: {
    $route(to, from) {
      this.loadDataForRoute(to);
      if (to.params.machine !== from.params.machine) {
        store.commit("selections/unset", "order");
      }
    },
    userInactive(inactive) {
      if (inactive) {
        store.commit("events/addLocalEvent", {
          local: true,
          event_type: "UserWentInactive",
          machine_id: this.machine_id,
        });
        store.commit("selections/unset", "order");
      }
    },
  },
  data() {
    return {
      foo: "bar",
      loaded: false,
      Sf326PopupActive: false,
      stop_reason: null,
      stopConfig: {
        stop_description: {
          class: "h50",
        },
      },
      lastTimestamp: new Date().getTime(),
      menu_flags: {
        //eTouch row 1
        stop_reason_menu: [[4, 44], []],
        scrap_menu: [[5], []],
        consumption_tool_menu: [[], []],
        print_menu: [[6], []],
        machine_list_menu: [[23], []],
        statistics_menu: [[], []],
        overview_menu: [[], []],
        activities_menu: [[26], []],
        notes_menu: [[27], []],
        other_menu: [[13], []],
        material_menu: [[22], []],
        good_qty_menu: [[2], [14]],
        //eTouch row 2
        document_menu: [[24], []],
        traceability_menu: [[], []],
        functions_menu: [[3], []],
        template_machine_eTouch_menu: [[], []], // ?
        label_menu: [[], []],
        friend_menu: [[], []],
        show_order_menu: [[], []],
        part_question_menu: [[], []],
      },
    };
  },
  computed: {
    ...mapState({
      navbar: (state) => state.sidebar,
      lastLoc: (state) => state.sidebar.lastLocation,
      lastLocType: (state) => state.sidebar.lastLocationType,
      showInternal: (state) => state.auth.settings.internal_activity,
      machinePath: (state) => state.auth.defaultpath,
      machineInterfaceOnly: (state) => state.auth.only_machine_interface,
      machine: (state) => state.auth.machine,
      singleMachine: (state) => state.auth.web_single_machine,
      lastEvent: (state) => state.tracking.keyboard,
      minimalTimeout: (state) => state.tracking.minimalTimeout,
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    userInactive() {
      if (
        this.minimalTimeout > 0 &&
        this.lastTimestamp > this.lastEvent + this.minimalTimeout * 1000 * 60
      ) {
        return true;
      }
      return false;
    },
    ...mapGetters({
      machineById: "machines/byId",
      canUser: "permissions/canUser",
      machineHasSf: "machines/machineHasSf",
      configByMachine: "machines/configByMachine",
      setupCodesByMachine: "stopcodes/setupCodesByMachine",
    }),
    machine() {
      return this.machineById(this.machine_id);
    },
    machine_config() {
      return this.configByMachine(this.machine_id);
    },
    hasSf326() {
      return this.machineHasSf(this.machine_id, "326");
    },
    hasSf353() {
      return this.machineHasSf(this.machine_id, "353");
    },
    setupCodes() {
      return this.setupCodesByMachine(this.machine_id);
    },
    hasWorkOrder() {
      return (
        this.machine.work_order_no !== "-" &&
        this.machine.work_order_no !== undefined
      );
    },
    machine_link() {
      if (this.machinePath) {
        return this.machinePath;
      }
      if (this.lastLoc && this.lastLocType) {
        return `/machines/${this.lastLocType}/${this.lastLoc}`;
      } else if (
        this.$route.params.locationtype &&
        this.$route.params.location
      ) {
        return `/machines/${this.$route.params.locationtype}/${this.$route.params.location}`;
      } else {
        return "/machines";
      }
    },
    location() {
      return "machine-swap";
    },
    hiddenForMachine() {
      let exclude = [];
      for (const [key, value] of Object.entries(this.menu_flags)) {
        if (this.machine_config[key] === false) {
          exclude.push(...value[0]);
        } else {
          exclude.push(...value[1]);
        }
      }
      if (this.machineInterfaceOnly) {
        exclude.push(25);
      }
      //sf exclusions
      if (!this.machineHasSf(this.machine_id, "5")) {
        exclude.push("sf5-1");
      }
      if (!this.machineHasSf(this.machine_id, "18")) {
        exclude.push("sf18-1");
      }
      if (!this.machineHasSf(this.machine_id, "184")) {
        exclude.push("sf184-1");
      }
      if (!this.machineHasSf(this.machine_id, "43")) {
        exclude.push("sf43-1");
      }
      if (!this.machineHasSf(this.machine_id, "346")) {
        exclude.push("sf346-1");
      }
      if (!this.machineHasSf(this.machine_id, "62")) {
        exclude.push("sf62-1");
      }
      if (!this.machineHasSf(this.machine_id, "32") || !this.showInternal) {
        exclude.push("sf32-1");
        exclude.push("sf32-2");
        exclude.push("sf32-3");
      }
      if (!this.machineHasSf(this.machine_id, "376")) {
        exclude.push("sf376-1");
        exclude.push("sf376-2");
      }
      if (this.machineHasSf(this.machine_id, "382")) {
        exclude.push("sf376-2");
      }
      return exclude;
    },
    baseMenu() {
      return [
        {
          icon: "fas fa-home",
          tlabel: "6258",
          id: 1000,
          to: "/interface/" + this.machine_id,
          disabled: false,
        },
        {
          icon: "fas fa-globe",
          tlabel: "3",
          id: 25,
          to: this.machine_link,
          disabled: this.machineInterfaceOnly,
        },
        {
          icon: "fas fa-flag-checkered",
          tlabel: "2568",
          id: 1,
          to: "/interface/" + this.machine_id + "/planned-orders",
          disabled: false,
        },
        {
          icon: "fal fa-flag-checkered",
          tlabel: "1976",
          id: "finished-orders",
          to: "/interface/" + this.machine_id + "/finished-orders",
          disabled: false,
        },
        {
          icon: "fal fa-stamp",
          tlabel: "2792",
          id: "sf62-1",
          to: "/interface/" + this.machine_id + "/reporting-history",
          disabled: false,
        },
        {
          icon: this.machineHasSf(this.machine_id, "361")
            ? "fas fa-scanner-keyboard"
            : "fas fa-users",
          tlabel: "6135",
          id: "sf18-1",
          to: "/interface/" + this.machine_id + "/signed-in",
          disabled: false,
        },
        {
          icon: "fas fa-flag-checkered",
          tlabel: "964",
          id: "sf5-1",
          to: "/interface/" + this.machine_id + "/order-by-part",
          disabled: !this.machineHasSf(this.machine_id, "5"),
        },
        {
          icon: "fas fa-flag-checkered",
          //tlabel: "6537", TODO, right label already
          tlabel: "6537",
          id: "sf346-1",
          to: "/interface/" + this.machine_id + "/rework-order",
          disabled: !this.machineHasSf(this.machine_id, "346"),
          implemented: true,
        },
        {
          icon: "fas fa-flag-checkered",
          tlabel: "4240",
          id: "sf184-1",
          to: "/interface/" + this.machine_id + "/order-by-card",
          disabled: !this.machineHasSf(this.machine_id, "184"),
        },
        {
          icon: "fas fa-flag-checkered",
          tlabel: "1978",
          id: 101,
          to: "/interface/" + this.machine_id + "/running-orders",
          disabled: !this.hasWorkOrder,
        },
        {
          icon: "fas fa-thumbs-up",
          tlabel: "4468",
          id: 2,
          to: "/interface/" + this.machine_id + "/report-quantity",
          disabled: !this.hasWorkOrder,
        },
        {
          icon: "fas fa-puzzle-piece",
          tlabel: "20",
          id: 3,
          to: "/",
          disabled: true,
          implemented: false,
        },
        {
          icon: "fa fa-hand-paper",
          tlabel: "2322",
          id: 4,
          to: "/interface/" + this.machine_id + "/stopcode",
          disabled: false,
        },
        {
          icon: "fas fa-hand-paper",
          tlabel: "5882",
          id: 44,
          to: "/interface/" + this.machine_id + "/uncoded",
          disabled: false,
        },
        {
          icon: "fas fa-thumbs-down",
          tlabel: "1979",
          id: 5,
          to: "/interface/" + this.machine_id + "/scrap-quantity",
          disabled: !this.hasWorkOrder,
        },
        {
          icon: "fas fa-clock",
          tlabel: "612",
          id: 42,
          to: "/",
          disabled: false,
          dropdown: true,
          items: [
            {
              icon: "far fa-thumbs-up",
              //tlabel: "2605",
              tlabel: "4468",
              id: 102,
              to: "/interface/" + this.machine_id + "/report-quantity-history",
              disabled: false,
            },
            {
              icon: "far fa-thumbs-down",
              //tlabel: "3931",
              tlabel: "1979",
              id: 103,
              to: "/interface/" + this.machine_id + "/scrap-quantity-history",
              disabled: false,
            },
            {
              icon: "far fa-octagon",
              //tlabel: "3931",
              tlabel: "2322",
              id: 104,
              to: "/interface/" + this.machine_id + "/history-stops",
              disabled: false,
            },
            {
              icon: "fas fa-flag",
              tlabel: "895",
              id: 108,
              to: "/interface/" + this.machine_id + "/pallet-flag-history",
              disabled: !this.machine_config.pallet_flag_scrap,
              implemented: true,
            },
            {
              icon: "fas fa-tag",
              tlabel: "1268",
              id: "sf376-1",
              to: "/interface/" + this.machine_id + "/label-history",
              disabled: false,
              implemented: true,
            },
          ],
        },
        {
          icon: "fas fa-shoe-prints",
          tlabel: "3468",
          id: "sf32-1",
          to: "/",
          disabled: false,
          dropdown: true,
          items: [
            {
              icon: "far fa-plus",
              //tlabel: "2605",
              tlabel: "4670",
              id: "sf32-2",
              to: "/interface/" + this.machine_id + "/create-activity",
              disabled: false,
            },
            {
              icon: "far fa-running",
              //tlabel: "3931",
              tlabel: "4792",
              id: "sf32-3",
              to: "/interface/" + this.machine_id + "/run-activity",
              disabled: false,
            },
          ],
        },
        {
          icon: "fas fa-print",
          tlabel: "426",
          id: 6,
          to: "/",
          disabled: false,
          dropdown: true,
          items: [
            {
              icon: "fas fa-id-card",
              tlabel: "945",
              id: 7,
              to: "/",
              disabled: true,
              implemented: false,
            },
            {
              icon: "fas fa-flag",
              tlabel: "895",
              id: 8,
              to: "/interface/" + this.machine_id + "/print-pallet-flag",
              disabled: !this.machine_config.pallet_flag_scrap,
              implemented: true,
            },
            {
              icon: "fas fa-tag",
              tlabel: "1268",
              id: "sf376-2",
              to: "/interface/" + this.machine_id + "/print-label",
              disabled: false,
              implemented: true,
            },
            {
              icon: "fal fa-id-card",
              tlabel: "1269",
              id: 9,
              to: "/",
              disabled: true,
              implemented: false,
            },
            {
              icon: "fas fa-tools",
              tlabel: "841",
              id: 10,
              to: "/",
              disabled: true,
              implemented: false,
            },
            {
              icon: "fas fa-rabbit-fast",
              tlabel: "3830",
              id: 11,
              to: "/",
              disabled: true,
              implemented: false,
            },
            {
              icon: "fas fa-flag-checkered",
              tlabel: "6194",
              id: 12,
              to: "/",
              disabled: true,
              implemented: false,
            },
          ],
        },
        {
          icon: "fas fa-folder",
          tlabel: "34",
          id: "files-menu",
          to: "/",
          disabled: false,
          dropdown: true,
          items: [
            {
              icon: "fal fa-file",
              tlabel: "424",
              id: "files",
              to: "/interface/" + this.machine_id + "/files",
              disabled: false,
              implemented: true,
            },
            {
              icon: "fa fa-fast-forward",
              tlabel: "3830",
              id: "qreports",
              to: "/interface/" + this.machine_id + "/files/q-reports",
              disabled: false,
              implemented: true,
            },
          ],
        },
        {
          icon: "fas fa-search",
          tlabel: "2914",
          id: 13,
          to: "/",
          disabled: false,
          dropdown: true,
          items: [
            {
              icon: "fas fa-thumbs-up",
              tlabel: "2566",
              id: 14,
              to: "/",
              disabled: true,
              implemented: false,
            },
            {
              icon: "fas fa-flag-checkered",
              tlabel: "4731",
              hasHandler: true,
              handler: this.resumeSetup,
              id: 15,
              to: "/",
              disabled: this.machine.prepare_status,
            },
            {
              icon: "fas fa-sticky-note",
              tlabel: "2767",
              id: "sf43-1",
              to: "/interface/" + this.machine_id + "/operation-text",
              disabled: !this.machineHasSf(this.machine_id, "43"),
              implemented: true,
            },
            {
              icon: "fas fa-table",
              tlabel: "4354",
              id: 16,
              to: "/",
              disabled: true,
              implemented: false,
            },
            {
              icon: "fas fa-object-group",
              tlabel: "2490",
              id: 17,
              to: "/",
              disabled: true,
              implemented: false,
            },
            {
              icon: "fas fa-cog",
              tlabel: "3441",
              id: 18,
              to: "/",
              disabled: true,
              implemented: false,
            },
            {
              icon: "fas fa-user",
              tlabel: "4071",
              id: 19,
              to: "/",
              disabled: true,
              implemented: false,
            },
            {
              icon: "fas fa-flag",
              tlabel: "265",
              id: 20,
              to: "/",
              disabled: true,
              implemented: false,
            },
          ],
        },
        {
          icon: "fas fa-toolbox",
          tlabel: "699",
          id: 22,
          to: "/interface/" + this.machine_id + "/materials",
          disabled: false,
          implemented: true,
        },
        {
          icon: "fas fa-cog",
          tlabel: "587",
          id: 23,
          to: "/",
          disabled: true,
          implemented: false,
        },
        {
          icon: "fas fa-file-alt",
          tlabel: "871",
          id: 24,
          disabled: false,
          dropdown: true,
          items: [
            {
              icon: "fas fa-file-alt",
              tlabel: "1",
              to: "/interface/" + this.machine_id + "/documents",
              disabled: false,
              implemented: true,
            },
            {
              icon: "fas fa-flag-checkered",
              tlabel: "1978",
              to: "/interface/" + this.machine_id + "/running-order-documents",
              disabled: false,
              implemented: true,
            },
            {
              icon: "fas fa-flag-checkered",
              tlabel: "2568",
              to: "/interface/" + this.machine_id + "/planned-order-documents",
              disabled: false,
              implemented: true,
            },
          ],
        },
        {
          icon: "fas fa-sticky-note",
          tlabel: "1882",
          id: 27,
          to: "/interface/" + this.machine_id + "/notes",
          disabled: !this.hasSf353,
          implemented: true,
        },
        {
          icon: "fas fa-file-search",
          tlabel: "3539",
          id: 28,
          to: "/",
          disabled: true,
          implemented: false,
        },
        {
          icon: "fas fa-file-archive",
          tlabel: "6220",
          id: 29,
          to: "/",
          disabled: true,
          implemented: false,
        },
      ];
    },
    sideMenu() {
      let base = productionMenuFilter(this.baseMenu);
      let exclude = this.hiddenForMachine;
      return filterExclude(base, exclude);
    },
  },
  methods: {
    toggleNav() {
      console.log("Bar");
      store.commit("sidebar/toggle");
    },
    loadDataForRoute(to) {
      if (to.params.machine) {
        let promises = [];
        promises.push(store.dispatch("groups/loadGroups", {}));
        promises.push(
          store.dispatch("config/loadBaseConfig", { base_code: "widgets" })
        );
        promises.push(
          store.dispatch("stopcodes/loadStopcodesForMachine", {
            machine_id: to.params.machine,
            reloadCachedValues: false,
          })
        );
        promises.push(
          store.dispatch("machines/loadTouchConfigForMachine", {
            machine_id: to.params.machine,
            reloadCachedValues: false,
          })
        );
        promises.push(
          store.dispatch("machines/loadSpecialFunctionsForMachine", {
            machine_id: to.params.machine,
            reloadCachedValues: false,
          })
        );
        promises.push(
          store.dispatch("settings/loadConfigs", {
            sf: "web_widget_settings",
            force: false,
          })
        );
        Promise.all(promises)
          .then(() => {
            this.loaded = true;
          })
          .catch((err) => {
            console.log("some promises failed", err);
            this.loaded = true;
          });
      }
    },
    stopMove(e) {
      return false;
    },
    closeNav() {
      console.log("Bar");
      store.commit("sidebar/close");
    },
    resumeSetup() {
      if (this.hasSf326 && this.stop_reason == null) {
        this.Sf326PopupActive = true;
        return true;
      }
      this.Sf326PopupActive = false;
      store.commit("sidebar/close");
      let note = Notification.request({
        message: "Resuming setup..",
        playSound: false,
      });
      execute_json("resume_setup", {
        machine_id: this.machine_id,
        set_status: "on",
        sf326_stop_code: this.stop_reason,
      })
        .then(() => {
          note.ok({ message: "Resumed" });
          this.stop_reason = null;
        })
        .catch((err) => {
          note.fail({ message: err.statusText });
          this.stop_reason = null;
        });
    },
    disableSf326Popup() {
      this.Sf326PopupActive = false;
    },
    onStopcodeSelectionChanged({ selectedRowsData }) {
      let stop = selectedRowsData.length ? selectedRowsData[0] : null;
      if (stop) {
        this.stop_reason = stop.stop_code;
        this.resumeSetup();
      }
    },
  },
  components: {
    BottomButtons,
    LocationSwap,
    MachineSwap,
    SideMenu,
    TopRightDropdown,
  },
};
</script>
<style lang="scss">
</style>