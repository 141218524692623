import { flattenMultiRow, flattenSingleRow } from "@/requests/requestParcer";
import { daybreakDate, getMonday, getNow } from "@/helpers/dates";
import axios from "@/requests/axios";
import store from "@/store";
import { execute_json } from "../requests";

// functions
const shift = {
    handler: "loadStopReasonsShift",
    tlabel: "197",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: "stop_reason_color",
};

function loadStopReasonsShift() {
    return new Promise((resolve, reject) => {
        execute_json("get_statistics_stop_reason", {
            feature: "shift",
            machine_id: this.machine.machine_id,
        })
            .then(({ data }) => {
                let d = data;
                if (this.addRuntime) {
                    d.push({
                        stop_reason_color: "Green",
                        stop_description: this && this.$t ? this.$t('951') : "runtime",
                        stop_time: this.machine.current_shift_runtime,
                    });
                }
                this.data = d;
                console.log(this.data);
                resolve();
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const today = {
    handler: "loadStopReasonsToday",
    tlabel: "447",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: "stop_reason_color",
};

function loadStopReasonsToday() {
    return new Promise((resolve, reject) => {
        store
            .dispatch("machines/loadTouchConfigForMachine", {
                machine_id: this.machine_id,
            })
            .then(() => {
                let d = new Date();
                let today = getNow();
                let daybreak = this && this.config ? this.config.day_break_time : "0000-00-00T00:00:00.751Z";
                let start_date = daybreakDate(d, daybreak);
                execute_json("get_statistics_stop_reason", {
                    feature: "date",
                    machine_id: this.machine.machine_id,
                    start_date: start_date,
                    stop_date: today,
                })
                    .then(({ data }) => {
                        let d = data;
                        if (this.addRuntime) {
                            execute_json("get_report_run_time_single_location", {
                                feature: "machine",
                                machine_id: this.machine.machine_id,
                                start_date: start_date,
                                stop_date: today,
                            })
                                .then(({ data }) => {
                                    let runTime = data[0] ? data[0].run_time : "0";
                                    d.push({
                                        stop_reason_color: "Green",
                                        stop_description: this && this.$t ? this.$t('951') : "runtime",
                                        stop_time: runTime,
                                    });
                                    this.data = d;
                                    resolve();
                                })
                        } else {
                            this.data = d;
                            console.log(this.data);
                            resolve();
                        }
                    });
            });
    });
};

const h24 = {
    handler: "loadStopReasons24h",
    tlabel: "448",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: "stop_reason_color",
};

function loadStopReasons24h() {
    return new Promise((resolve, reject) => {
        execute_json("get_statistics_stop_reason", {
            feature: "interval",
            machine_id: this.machine.machine_id,
            interval: -1,
        })
            .then(({ data }) => {
                this.data = data;
                if (this.addRuntime) {
                    execute_json("get_report_run_time_single_location", {
                        feature: "machine",
                        machine_id: this.machine.machine_id,
                        start_date: getNow(-1),
                        stop_date: getNow()
                    })
                        .then(({ data }) => {
                            let runTime = data[0] ? data[0].run_time : "0";
                            this.data.push({
                                stop_reason_color: "Green",
                                stop_description: this && this.$t ? this.$t('951') : "runtime",
                                stop_time: runTime,
                            });
                            resolve();
                        })
                } else {
                    console.log(this.data);
                    resolve();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const week = {
    handler: "loadStopReasonsWeek",
    tlabel: "914",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: "stop_reason_color",
};

function loadStopReasonsWeek() {
    return new Promise((resolve, reject) => {
        let start_date = getMonday(new Date()).toBalthzarTime();
        let stop_date = new Date().toBalthzarTime();
        execute_json("get_statistics_stop_reason", {
            feature: "start_and_stop_date",
            machine_id: this.machine.machine_id,
            start_date,
            stop_date
        }).then(({ data }) => {
            let d = data;
            if (this.addRuntime) {
                execute_json("get_statistics_machine_date", {
                    machine_id: this.machine.machine_id,
                    start_date,
                    stop_date
                }).then(({ data }) => {
                    let runTime = data[0] !== null ? data[0].run_time : "0";
                    d.push({
                        stop_reason_color: "Green",
                        stop_description: this && this.$t ? this.$t('951') : "runtime",
                        stop_time: runTime,
                    });
                    this.data = d;
                    resolve();
                })
            } else {
                this.data = d;
                console.log(this.data);
                resolve();
            }
        })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const lastweek = {
    handler: "loadStopReasonsLastWeek",
    tlabel: "2136",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: "stop_reason_color",
};

function loadStopReasonsLastWeek() {
    return new Promise((resolve, reject) => {
        let start = getMonday(new Date());
        start.setDate(start.getDate() - 7);
        let start_date = start.toBalthzarTime();
        let stop_date = getMonday(new Date()).toBalthzarTime();
        execute_json("get_statistics_stop_reason", {
            feature: "start_and_stop_date",
            machine_id: this.machine.machine_id,
            start_date,
            stop_date
        }).then(({ data }) => {
            let d = data;
            if (this.addRuntime) {
                execute_json("get_statistics_machine_date", {
                    machine_id: this.machine.machine_id,
                    start_date,
                    stop_date
                }).then(({ data }) => {
                    let runTime = data[0] !== null ? data[0].run_time : "0";
                    d.push({
                        stop_reason_color: "Green",
                        stop_description: this && this.$t ? this.$t('951') : "runtime",
                        stop_time: runTime,
                    });
                    this.data = d;
                    resolve();
                })
            } else {
                console.log(this.data);
                this.data = d;
                resolve();
            }
        })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const d30 = {
    handler: "loadStopReasons30d",
    tlabel: "450",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: "stop_reason_color",
};

function loadStopReasons30d() {
    return new Promise((resolve, reject) => {
        execute_json("get_statistics_stop_reason", {
            feature: "interval",
            machine_id: this.machine.machine_id,
            interval: -30
        })
            .then(({ data }) => {
                this.data = data;
                if (this.addRuntime) {
                    execute_json("get_statistics_machine_date ", {
                        machine_id: this.machine.machine_id,
                        interval: -30 * 24,
                    })
                        .then(({ data }) => {
                            let runTime = flattenSingleRow(data) !== null ? flattenSingleRow(data).run_time : "0";
                            this.data.push({
                                stop_reason_color: "Green",
                                stop_description: this && this.$t ? this.$t('951') : "runtime",
                                stop_time: runTime,
                            });
                            resolve();
                        })
                } else {
                    console.log(this.data);
                    resolve();
                }
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const goal = {
    handler: "loadStopReasonsFromGoal",
    tlabel: "450",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: "stop_reason_color",
};

function loadStopReasonsFromGoal() {
    return new Promise((resolve, reject) => {
        if (this.goal === null) {
            this.data = [];
            resolve();
        } else {
            let start_date = this.goal.target_start_time;
            let stop_date = this.goal.target_end_time;
            let machine_id = this.goal.machine_id || this.machine.machine_id;
            execute_json("get_statistics_stop_reason", {
                feature: "start_and_stop_date",
                machine_id,
                start_date,
                stop_date
            }).then(({ data }) => {
                let d = data;
                if (this.addRuntime) {
                    execute_json("get_statistics_machine_date", {
                        machine_id,
                        start_date,
                        stop_date
                    }).then(({ data }) => {
                        let runTime = data[0] !== null ? data[0].run_time : "0";
                        d.push({
                            stop_reason_color: "Green",
                            stop_description: this && this.$t ? this.$t('951') : "runtime",
                            stop_time: runTime,
                        });
                        this.data = d;
                        resolve();
                    })
                } else {
                    console.log(this.data);
                    this.data = d;
                    resolve();
                }
            })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        }
    });
};

const graphStopreasonsDataSetup = {
    shift,
    today,
    calendar:today,
    "24h": h24,
    week,
    lastweek,
    "30d": d30,
    goal
};

const graphStopreasonsDataFunctions = {
    loadStopReasonsShift,
    loadStopReasonsToday,
    loadStopReasons24h,
    loadStopReasonsWeek,
    loadStopReasonsLastWeek,
    loadStopReasons30d,
    loadStopReasonsFromGoal
};


export { graphStopreasonsDataFunctions, graphStopreasonsDataSetup };