<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div>
      <h1>
        {{ $t("898") }}
        <card-menu :items="menuItems" :config="config"></card-menu>
      </h1>

      <router-link
        :to="'/interface/' + machine_id + '/create-activity'"
        v-if="config.shouldShowCreate"
        class="indicator fast-link-button small"
        :class="status"
        >{{ $t("4670") }}</router-link
      >
      <router-link :to="IALink" class="indicator status-large height-limited" :class="status">
        <p style="padding-top: 15px">
          {{ $t("898") }} ({{ this.items.length }})
        </p>
        <c-table
          :data="firstThreeItems"
          primary-key="record_no"
          config-string="internal_activities_widget"
          default-sort-key="due_date"
          default-sort-dir="asc"
          :teleport="true"
        >
        </c-table>
      </router-link>
    </div>
  </div>
</template>

<script>
import CardMenu from "@/components/card-menu";
import store from "@/store";
import { mapState, mapGetters } from "vuex";
import { Pulse } from "@/helpers/heartbeat";
import { Configuration } from "@/helpers/settings";
export default {
  data() {
    return {
      pulse: null,
      config: new Configuration(
        "internal-activities",
        {
          shouldShowCreate: true,
        },
        this.template
      ),
    };
  },
  mounted() {
    this.pulse = new Pulse({
      action: "activities/loadActivitiesForSelectionOwner",
      interval: 600,
      primaryKey: "selectionOwner",
      parameters: { selectionOwner: this.machine_id },
    });
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      username: (state) => state.auth.username,
      activities: (state) => state.activities.index,
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    ...mapGetters({
      //machineById: "machines/byId",
      canUser: "permissions/canUser",
      columnConfigByTemplate: "config/columnConfigByTemplate",
      machineHasSf: "machines/machineHasSf",
    }),

    items() {
      return this.activities[this.machine_id] || [];
    },
    firstThreeItems() {
      return this.items.slice(0, this.config.shouldShowCreate ? 2 : 3);
    },
    IALink() {
      return "/interface/" + this.machine_id + "/run-activity";
    },
    status() {
      return this.items.length > 0 ? "c-orange" : "c-green";
    },
    menuItems() {
      return [
        { header: this.$t("497") },
        {
          label: "toggle create",
          handler: this.toggleCreate,
          selected: this.config.shouldShowCreate,
        },
      ];
    },
  },
  methods: {
    toggleCreate() {
      this.config.setValues({
        shouldShowCreate: !this.config.shouldShowCreate,
      });
    },
  },
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
  },
};
</script>

<style>
</style>