function productionMenuFilter(base) {
  const showNonImplemented = process.env.NODE_ENV === 'production' ? false : true;
  if (!showNonImplemented) {
    base = inActivateItems(base)
  }
  return base;
}

function inActivateItems(menu){
  let filteredMenu = [];
  for (let i =0;i<menu.length;i++){
    let menuItem = menu[i];
    if (menuItem.implemented !== false){
      //only add if it's implemented, if it is, check if it has items
      if (menuItem.dropdown){
        let filteredItems = inActivateItems(menuItem.items);
        menuItem.items = filteredItems;
      }
      filteredMenu.push(menuItem);
    }
  }
  return filteredMenu;
}

function filterExclude(menu,exclude){
  let filteredMenu = [];
  for (let i =0;i<menu.length;i++){
    let menuItem = menu[i];
    if (!exclude.includes(menuItem.id)){
      //only add if it's implemented, if it is, check if it has items
      if (menuItem.dropdown){
        let filteredItems = filterExclude(menuItem.items,exclude);
        menuItem.items = filteredItems;
      }
      filteredMenu.push(menuItem);
    }
  }
  return filteredMenu;
}

export { productionMenuFilter, filterExclude }