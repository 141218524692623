<template>
    <div class="svg-gauge">
        <svg viewBox="0 0 100 100">
            <path class="gauge-arc-circle" stroke="gray" :stroke-width="gaugeStrokeWidth-2" stroke-linecap="round"
                :d="bgArcPath"></path>
             <path class="gauge-arc-circle animated" :class="color" :stroke-width="gaugeStrokeWidth" stroke-linecap="round" v-if="ArcPath"
                :d="ArcPath"></path> 
             <polygon v-for="(line,limit) in limitLines" :key="limit" :points="line.polyPoints" stroke="black" :fill="line.color" stroke-width="1"></polygon>   
        </svg>
    </div>
</template>
<script>

const gaugeStrokeWidth    = 15;
const triWidth = 0.6;
const largeArcLimit = 200/3;
const gaugeHalfStroke     = gaugeStrokeWidth / 2;
const gaugeCenter         = 50;
const piby200 = Math.PI/200;
const gaugeRadius         = (gaugeCenter - gaugeHalfStroke);

export default {
    props:{
        value:{
            required:true
        },
        limits:Array,
        limitStyle:{
            default:'linear'
        }
    },
    data(){
        return {
            ivalue:this.value,
            animating:false,
            gaugeStrokeWidth:gaugeStrokeWidth
        }
    },
    methods:{
        getArcPath(percent) {
            const start = this.pointXY(0);
            const end = this.pointXY(percent);
            const largeArc = percent > largeArcLimit ? '1' : '0'

            return `M ${start.x} ${start.y} ` +
                    `A ${gaugeRadius} ${gaugeRadius}, 0, ${largeArc}, 1, ${end.x} ${end.y} `
        },
        pointXY(percent){
            return {
                x:gaugeCenter - Math.sin(piby200*(3*percent+50)) * gaugeRadius,
                y:gaugeCenter + Math.cos(piby200*(3*percent+50)) * gaugeRadius
            }
        },
        lineXY(percent){
            const mid = this.pointXY(percent);
            let dx = Math.sin(piby200*(3*percent+50)) * gaugeStrokeWidth * 0.6;
            let dy = Math.cos(piby200*(3*percent+50)) * gaugeStrokeWidth * 0.6;
            return {
                x1:mid.x-dx,
                y1:mid.y+dy,
                x2:mid.x+dx-dy*triWidth,
                y2:mid.y-dy-dx*triWidth,
                x3:mid.x+dx+dy*triWidth,
                y3:mid.y-dy+dx*triWidth
            }
        },
        polyPoints(line){
            return `${line.x1},${line.y1} ${line.x2},${line.y2} ${line.x3},${line.y3}`;
        },
        animateValueTo(){
        }
    },
    computed:{
        bgArcPath(){
            return this.getArcPath(100);
        },
        ArcPath(){
            return this.ivalue ? this.getArcPath(Math.min(100,+this.ivalue)) :null;
        },
        limitLines(){
            return this.limits.map((l)=>{
                return {
                    ...l,
                    polyPoints:this.polyPoints(this.lineXY(this.limitStyle === 'linear' ? l.limit : 100-l.limit))
                }
            })
        },
        color(){
            let color = "red";
            for (let i = this.limits.length-1;i>=0;i--){
                if (this.limitStyle === 'linear'){
                    if (+this.ivalue >= this.limits[i].limit){
                        color = this.limits[i].color;
                        break;
                    }
                }else{
                    if (+this.ivalue >= 100 - this.limits[i].limit && (!this.limits[i].capped || +this.ivalue <= 100 + this.limits[i].limit)){
                        color = this.limits[i].color;
                        break;
                    }
                }
            }
            return color;
        }
    },
    watch:{
        value(){
            //TODO: update ivalue slowly
            this.ivalue = this.value;
        }
    }
}
</script>
<style>/*
    .gauge-arc-circle {
    filter: drop-shadow( 0px 5px 5px #000 );
    }*/
</style>