<template>
  <div>
    <loading v-if="!loaded" />
    <div v-else> <div class="history-summation">
        <table class="table table-borderless" style="text-align:left;">
          <tbody>
          
            <tr>
              <td>{{ $t("4566") }}</td>
              <td>
                <b>{{ data.order?.selected?.planned_op_qty ||  data.order?.selected?.planned_qty }}</b>
              </td>
            </tr>
            <tr>
              <td>{{ $t("581") }}</td>
              <td>
                <b>{{ totalFromHistory }}</b><br/>
                <div class="substraction">
                  <i class="fas fa-minus"></i>
                </div>
              </td>
            </tr>
            <tr class="bigger">
              <td>{{ $t("83") }}</td>
              <td >
                <b>{{
                  (data.order?.selected?.planned_op_qty  ||  data.order?.selected?.planned_qty )- totalFromHistory
                }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <c-table
          :data="primaHistory"
          primary-key="trans_time"
          config-string="good_quantity_tab"
          default-sort-key="trans_time"
          default-sort-dir="desc"
          @selectionChanged="onSelectionChanged"
          :teleport="true"
        >
        </c-table>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import SortSelect from "@/components/sort-select";
import store from "@/store";
export default {
  props: {
    data: {
      required: true,
    },
  },
  data(){
    return {
      loaded:false
    }
  },
  mounted() {
     store.commit("bottomnav/disable");
    let promises = [];
    promises.push(
      store.dispatch("goods/loadPrimaHistoryForCardNo", {
        machine_id: this.machine_id,
        card_no:this.card_no
      })
    );
    Promise.all(promises).then(() => {
      this.loaded = true;
      console.log("fully loaded..");
    });
  },
  methods: {
    onSelectionChanged() {
      //console.log("something");
    },
  },
  computed: {
     ...mapState({
      bottomNav: (state) => state.bottomnav,
      primaHistoriesByCard: (state) => state.goods.primaHistoryByCard,
    }),
    ...mapGetters({
    }),
    machine_id(){
      return this.data?.order?.selected?.machine_id;
    },
    primaHistory() {
      return this.primaHistoriesByCard[this.card_no] || [];
    },
    card_no(){
      return this.data.order.selected?.card_no
    },
     totalFromHistory() {
      return this.primaHistory
        .filter((p) => p.status !== "Cancelled")
        .reduce((a, b) => {
          return a + +b.good_qty;
        }, 0);
    },
  },
  watch: {
   
  },
  components: {
    SortSelect,
  },
};
</script>