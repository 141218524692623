<template>
  <div>
    <loading v-if="!loaded" />
    <div v-else style="width:100%;max-width:600px;margin:0 auto;">
        <label for="">{{$t('1351')}}</label>
      <select name="num_copies" id="" v-model="data[tab.dataKey].num_copies" class="form-control">
        <option :value="null"></option>
        <option :value="i" :key="i" v-for="i in 10">{{ i }}</option>
      </select>
        <label for="">{{$t('406')}}</label>
       <select name="printer" id="" v-model="data[tab.dataKey].printer" class="form-control">
        <option :value="null"></option>
        <option :value="printer.printer_no" :key="printer.printer_no" v-for="printer in printers">{{ printer.printer_description }}</option>
      </select>
    </div>

    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  mounted() {},
  methods: {},
  computed: {
    ...mapState({
      loaded: (state) => state.printers.loaded,
      printers: (state) => state.printers.list,
    }),
    disableNext() {
      return (
        !this.loaded ||
        this.data[this.tab.dataKey].printer === null ||
        this.data[this.tab.dataKey].printer == "" ||
        this.data[this.tab.dataKey].num_copies === null
      );
    },
  },
};
</script>