<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div v-if="machine">
      <h1>
        {{ $t("1978") }}
        <div class="card-menu-sibling" v-if="detailsMachine">
          <button class="btn" @click="details = true">
            <i class="fal fa-info-circle"></i>
          </button>
        </div>
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div v-if="order !== null">
        <div
          v-if="order.length > 0 && order[0].status != '0'"
          style="font-size: 8px"
        >
          <c-table
            :data="order"
            primary-key="record_no"
            config-string="running_orders_widget"
            @selectionChanged="onSelectionChanged"
            :colors="colors"
            :teleport="true"
          >
          </c-table>
        </div>
        <div class="center-widget" v-else>
          {{ $t("1259") }}
        </div>
      </div>
      <div class="center-widget" v-else>
        {{ $t("1817") }}
      </div>
    </div>
    <order-details
      v-if="details"
      @close="details = false"
      :machine_id="detailsMachine.machine_id"
      :card_no="detailsMachine.card_no"
      :p_order="detailsMachine.p_order"
      :finished="false"
    ></order-details>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import CardMenu from "@/components/card-menu";
import OrderDetails from "@/components/OrderDetails";
import { Configuration } from "@/helpers/settings";
import { Pulse } from "@/helpers/heartbeat";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      machine_id: this.$route.params.machine,
      interval: 120,
      selectedRows: [],
      pulse: null,
      config: new Configuration(
        "running-order",
        {
          period: "shift",
          colorKey: "a",
        },
        this.template
      ),
      details: false,
    };
  },
  mounted() {
    this.pulse = new Pulse({
      action: "orders/loadRunningOrderForMachine",
      parameters: { machine_id: this.machine_id, reload: false },
      interval: this.interval,
      primaryKey: "machine_id",
    });
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
      period: (state) => state.config.periods.widget_statistics,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      columnConfigByTemplate: "config/columnConfigByTemplate",
      machineHasSf: "machines/machineHasSf",
      canUser: "permissions/canUser",
    }),
    userPermission() {
      return (
        this.canUser("change-own-webcolumns") ||
        this.canUser("change-default-webcolumns")
      );
    },
    detailsMachine() {
      if (this.selectedRows.length) {
        return this.selectedRows[0];
      }
      if (this.order && this.order.length === 1) {
        return this.order[0];
      }
      return null;
    },
    columnConfig() {
      return this.columnConfigByTemplate("running_orders_widget");
    },
    baseColumnConfig() {
      return this.columnConfigByTemplate("running_orders_widget_baseconfig");
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    order() {
      return this.orders[this.machine_id] || null;
    },
    colors() {
      //TODO:fix
      if (this.order && this.machineHasSf(this.machine_id, "347")) {
        let colors = {};
        this.order.forEach((order) => {
          let color = this.colorValue(
            this[this.config.colorKey + "_used"],
            this.config.colorKey + "limits",
            this.config.colorKey === "a" ? "clamped" : "linear"
          );
          colors[order.work_order_no] = color;
        });
        return colors;
      }
      return null;
    },
    //TODO: it's own period?!
    t_used() {
      switch (this.config.period) {
        case "shift":
          return this.machine.t_percent_shift;
          break;
        case "24h":
          return this.machine.t_percent_24;
          break;
        case "today":
          return this.machine.t_percent_today;
          break;
        case "calendar":
          return this.machine.t_percent_calendar;
          break;
      }
    },
    a_used() {
      switch (this.config.period) {
        case "shift":
          return this.machine.a_percent_shift;
          break;
        case "24h":
          return this.machine.a_percent_24;
          break;
        case "today":
          return this.machine.a_percent_today;
          break;
        case "calendar":
          return this.machine.a_percent_calendar;
          break;
      }
    },
    k_used() {
      switch (this.config.period) {
        case "shift":
          return this.machine.k_percent_shift;
          break;
        case "24h":
          return this.machine.k_percent_24;
          break;
        case "today":
          return this.machine.k_percent_today;
          break;
        case "calendar":
          return this.machine.k_percent_calendar;
          break;
      }
    },
    tak_used() {
      switch (this.config.period) {
        case "shift":
          return this.machine.tak_percent_shift;
          break;
        case "24h":
          return this.machine.tak_percent_24;
          break;
        case "today":
          return this.machine.tak_percent_today;
          break;
        case "calendar":
          return this.machine.tak_percent_calendar;
          break;
      }
    },
    tlimits() {
      return [
        { limit: +this.machine.bar_yellow_limit, color: "yellow" },
        { limit: +this.machine.bar_green_limit, color: "green" },
      ];
    },
    alimits() {
      return [
        {
          limit: +this.machine.speed_deviation_yellow_limit,
          color: "yellow",
          capped: !this.machine.speed_over_100,
        },
        {
          limit: +this.machine.speed_deviation_green_limit,
          color: "green",
          capped: !this.machine.speed_over_100,
        },
      ];
    },
    klimits() {
      return [
        { limit: +this.machine.q_yellow_limit, color: "yellow" },
        { limit: +this.machine.q_green_limit, color: "green" },
      ];
    },
    taklimits() {
      return [
        { limit: +this.machine.oee_yellow_limit, color: "yellow" },
        { limit: +this.machine.oee_green_limit, color: "green" },
      ];
    },
    items() {
      let items = [];
      if (this.machineHasSf(this.machine_id, "347")) {
        items = [
          { header: this.$t("1329") },
          {
            tlabel: "448",
            handler: () => {
              this.config.setValues({ period: "24h" });
            },
            selected: this.config.period === "24h",
          },
          {
            tlabel: "447",
            handler: () => {
              this.config.setValues({ period: "today" });
            },
            selected: this.config.period === "today",
          },
          {
            tlabel: "245",
            handler: () => {
              this.config.setValues({ period: "shift" });
            },
            selected: this.config.period === "shift",
          },
          {
            tlabel: "18",
            handler: () => {
              this.config.setValues({ period: "calendar" });
            },
            selected: this.config.period === "calendar",
          },
        ];

        items.push({
          header: this.$t("832"),
        });

        items.push({
          tlabel: "2101",
          handler: () => {
            this.config.setValues({ colorKey: "t" });
          },
          selected: this.config.colorKey === "t",
        });
        items.push({
          tlabel: "1898",
          handler: () => {
            this.config.setValues({ colorKey: "a" });
          },
          selected: this.config.colorKey === "a",
        });
        items.push({
          tlabel: "1639",
          handler: () => {
            this.config.setValues({ colorKey: "k" });
          },
          selected: this.config.colorKey === "k",
        });
        items.push({
          tlabel: "1640",
          handler: () => {
            this.config.setValues({ colorKey: "tak" });
          },
          selected: this.config.colorKey === "tak",
        });
      }
      if (this.userPermission) {
        items.push({ header: this.$t("89") });
        items.push({
          tlabel: "506",
          to: "/admin/config/tables/running_orders_widget",
        });
      }
      return items;
    },

    workOrder() {
      return this.machine.work_order_no;
    },
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length === 0) {
        store.commit("bottomnav/disable");
        store.commit("selections/unset", "order");
      } else {
        store.commit("bottomnav/enable");
        store.commit("selections/set", {
          type: "order",
          data: this.selectedRows[0],
          subtype: "running"
        });
      }
      store.commit("bottomnav/setType", "running-order-singular");
      store.commit("bottomnav/setData", this.selectedRows);
    },
    colorValue(value, limits, limitstyle = "linear") {
      let color = "red";
      limits = this[limits];
      for (let i = limits.length - 1; i >= 0; i--) {
        if (limitstyle === "linear") {
          if (value >= limits[i].limit) {
            color = limits[i].color;
            break;
          }
        } else {
          if (
            value >= 100 - limits[i].limit &&
            (!limits[i].capped || value <= 100 + limits[i].limit)
          ) {
            color = limits[i].color;
            break;
          }
        }
      }
      return color;
    },
  },
  watch: {
    workOrder(val, old) {
      if (old !== null) {
        this.pulse.force();
      }
    },
  },
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
    OrderDetails,
  },
};
</script>