<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget left-aligned">
    <div v-if="machine">
      <h1>
        {{ $t("4420") }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div class="row">
        <div
          :class="config.stretched ? 'col-12' : 'col-4'"
          v-for="order in filteredPOrders"
          :key="order.card_no"
        >
          <a
            href="#"
            @click.prevent="swapToOrder(order)"
            class="fast-order-button indicator c-gray"
            :class="{ 'c-yellow': order._running }"
          >
            <i class="far fa-flag-checkered" v-if="!config.stretched"></i
            ><br v-if="!config.stretched" />
            {{ order.work_order_no }}<br />
            <h1
              v-if="config.stretched"
              style="
                text-align: center;
                margin: 0;
                line-height: 22px;
                font-size: 22px;
              "
            >
              {{ order.part_no }} {{ order.part_description }}, {{order.planned_op_qty}}
            </h1>
            <span v-if="!config.stretched">{{ order.part_no }}</span>
          </a>
        </div>
        <div
          :class="config.stretched ? 'col-12' : 'col-4'"
          @click.prevent="() => {}"
          v-for="i in Math.max(0, config.max - filteredPOrders.length)"
          :key="i"
        >
          <a href="#" class="fast-order-button indicator c-gray"
            ><i class="far fa-times"></i><br />-</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import { secondsToHhMm } from "@/helpers/dates";
import { filterFunc } from "@/helpers/filter";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";
import { Configuration } from "@/helpers/settings";
import { Notification } from "@/helpers/notifications";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 180,
      swapping: false,
      numericalColumns: ["record_no", "_sort"],
      config: new Configuration(
        "order-swap",
        {
          stretched: false,
          max: 6,
        },
        this.template
      ),
    };
  },
  mounted() {
    this.pulse = new Pulse([
      {
        action: "machines/loadMachine",
        interval: 3,
        primaryKey: "machine_id",
        parameters: { machine_id: this.machine_id },
      },
      {
        action: "orders/loadRunningOrderForMachine",
        parameters: { machine_id: this.machine_id, reload: false },
        interval: this.interval,
        primaryKey: "machine_id",
      },
      {
        action: "orders/loadPlannedOrdersForMachine",
        parameters: {
          machine_id: this.machine_id,
          erp_resource: this.machine.erp_resource,
          erp_connection_type: this.machine.erp_connection_type,
        },
        interval: this.interval,
        primaryKey: "machine_id",
      },
    ]);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      runningOrders: (state) => state.orders.runningOrders,
      machinePath: (state) => state.auth.defaultpath,
      plannedOrders: (state) => state.orders.plannedOrders,
      lastLoc: (state) => state.sidebar.lastLocation,
      lastLocType: (state) => state.sidebar.lastLocationType,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      columnConfigByTemplate: "config/columnConfigByTemplate",
      canUser: "permissions/canUser",
    }),
    canSaveWidgetSettings() {
      return this.canUser("save-widget-settings");
    },

    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    rOrders() {
      return this.runningOrders[this.machine_id] || [];
    },
    pOrders() {
      return this.plannedOrders[this.machine_id] || [];
    },
    baseColumnConfig() {
      return this.columnConfigByTemplate("planned_orders_baseconfig");
    },
    sortKey() {
      if (
        this.baseColumnConfig.sortKey &&
        this.baseColumnConfig.sortKey.length > 0
      ) {
        return this.baseColumnConfig.sortKey;
      }
      return ['planned_start_time'];
    },
     typedSortArray() {
      let arr = [];
      for (let i = 0; i < this.sortKey.length; i++) {
        if (this.numericalColumns.includes(this.sortKey[i])) {
          arr.push((row) => {
            return +row[this.sortKey[i]];
          });
        } else {
          arr.push(this.sortKey[i]);
        }
      }
      return arr;
    },
    sortDir() {
      if (
        this.baseColumnConfig.sortDir &&
        this.baseColumnConfig.sortDir.length > 0
      ) {
        return this.baseColumnConfig.sortDir;
      }
      return ["asc"];
    },
    activeFilter() {
      return this.baseColumnConfig.filter || null;
    },
    filteredPOrders() {
      let data = null;
      let cardNos = this.rOrders.map((o) => o.card_no);
      let pOrders = this.pOrders
        .filter((o) => !cardNos.includes(o.card_no) && (this.activeFilter == null || filterFunc(o,this.activeFilter)))
        .slice(0, this.config.max);
      if (pOrders.length < this.config.max) {
        let pOrdersRunning = this.pOrders
          .filter((o) => cardNos.includes(o.card_no))
          .slice(0, this.config.max - pOrders.length)
          .map((o) => {
            return {
              ...o,
              _running: true,
            };
          });
        data = [...pOrders, ...pOrdersRunning];
      }else{
        data = pOrders;
      }
      return _.orderBy(data,this.typedSortArray,this.sortDir);
    },

    items() {
      if (!this.canSaveWidgetSettings) {
        return [];
      }
      return [
        {
          label: "max amount",
          type: "number",
          input: "max",
          min: 3,
          max: 18,
        },
        {
          label: "toggle style",
          handler: () => {
            this.config.setValues({ stretched: !this.config.stretched });
          },
        },
      ];
    },
  },
  methods: {
    swapToOrder(order) {
      if (!this.swapping && !order._running) {
        this.swapping = true;
        store.commit("bottomnav/hide");
        let n = Notification.request({
          header: this.$t("6171"),
          message: this.$t("6171"),
        });
        let message = this.$t("644");
        store
          .dispatch("orders/swapToOrder", {
            order,
            machine_id: this.machine_id,
          })
          .then(() => {
            console.log("swapped it!");
            n.ok({ message });
          })
          .catch((err) => {
            console.log("didnt swap! :(", err);
            n.fail({ message: err });
          })
          .finally(() => {
            this.swapping = false;
          });
      }
    },
  },
  components: {
    CardMenu,
  },
};
</script>