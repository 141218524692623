import { flattenMultiRow, flattenSingleRow } from "@/requests/requestParcer";
import { daybreakDate, getMonday, getNow, getNowStart } from "@/helpers/dates";
import axios from "@/requests/axios";
import store from "@/store";
import _ from "lodash";
import { execute_json } from "../requests";

// functions

const d30 = {
    handler: "loadStopReasonsDaily30d",
    updateHandler: "updateDailyStopReasons",
    tlabel: "450",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: null,
    stackKey: "stop_description",
    stackColorKey: "stop_reason_color"
};

function loadStopReasonsDaily30d() {
    return new Promise((resolve, reject) => {
        this.rawData = {};
        this.stopReasonDailyReverseLoopLoad(0, 30).then(() => {
            this.data = this.rawData;
            console.warn(this.data);
            resolve();
        });
    })

};

const d7 = {
    handler: "loadStopReasonsDaily7d",
    updateHandler: "updateDailyStopReasons",
    tlabel: "450",
    labelKey: "stop_description",
    valueKey: "stop_time",
    colorKey: null,
    stackKey: "stop_description",
    stackColorKey: "stop_reason_color"
};

function loadStopReasonsDaily7d() {
    return new Promise((resolve, reject) => {
        this.rawData = {};
        this.stopReasonDailyReverseLoopLoad(0, 6).then(() => {
            this.data = this.rawData;
            resolve();
        });
    })

};

function stopReasonDailyReverseLoopLoad(i, max) {
    return new Promise((resolve, reject) => {
        execute_json("get_statistics_stop_reason", {
            feature: "start_and_stop_date",
            machine_id: this.machine.machine_id,
            start_date: getNowStart(-i),
            stop_date: i === 0 ? getNow() : getNowStart(-(i - 1))
        }).then(({ data }) => {
            this.rawData[getNow(-i).slice(0, 10)] = data;
            if (i === max) {
                resolve();
            } else {
                return this.stopReasonDailyReverseLoopLoad(i + 1, max).then(() => {
                    resolve();
                });
            }
        })
            .catch((err) => {
                console.log(err);
                reject(err);
            });

    });
}

function updateDailyStopReasons() {
    return new Promise((resolve, reject) => {

        execute_json("get_statistics_stop_reason", {
            feature: "start_and_stop_date",
            machine_id: this.machine.machine_id,
            start_date: getNowStart(),
            stop_date: getNow()
        })
            .then(({ data }) => {
                //let d = _.cloneDeep(this.data);
                let dayKey = getNow(0).slice(0, 10);
                if (this.data[dayKey] === undefined) {
                    //new day!
                    delete this.data[this.labels[0]];
                    console.log("new day, popping first day");
                }
                this.data[dayKey] = data;
                //this.data = d;
                //console.warn(d);
                resolve();
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
}

const graphStopreasonsDailyDataSetup = {
    "30d": d30,
    "7d": d7
};

const graphStopreasonsDailyDataFunctions = {
    loadStopReasonsDaily30d,
    loadStopReasonsDaily7d,
    stopReasonDailyReverseLoopLoad,
    updateDailyStopReasons
};


export { graphStopreasonsDailyDataFunctions, graphStopreasonsDailyDataSetup };