<template>
  <textbox v-model="modelValue" :required="true" :active="active" :item="item" @blur="blur"/>
</template>
<script>
import _ from "lodash";
import textbox from "./TextBox";
export default {
  emits: ["update:modelValue", "next","blur", "lock"],
  props: {
    modelValue: {
      required: true,
    },
    item:{

    },
    active: {},
  },
  data() {
    return {
    };
  },
  mounted(){
    this.$emit('lock',this.locked);
  },
  watch: {
    active(val) {
    },
    modelValue:{
      deep:true,
      handler:function(val){
        if (val.entered_value !== null && val.entered_value.length > 0){
          val.isdone = true;
        }else{
          val.isdone = false;
        }
        this.$emit("update:modelValue",val);
      }
    }
  },
  methods: {
    blur() {
      this.$emit("blur");
    },
  },
  computed:{
    locked(){
      return !this.item.allow_user_edit
    }
  },
  components:{
    textbox
  }
};
</script>