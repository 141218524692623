import _ from 'lodash';

function extractJson(xmljson, config = {}) {
    //normal
    if (xmljson.dataset
        && xmljson.dataset['diffgr:diffgram']
        && xmljson.dataset['diffgr:diffgram'][0]
        && xmljson.dataset['diffgr:diffgram'][0]['newdataset']
        && xmljson.dataset['diffgr:diffgram'][0]['newdataset'][0]
    ) {
        let base = xmljson.dataset['diffgr:diffgram'][0]['newdataset'][0];

        if (config.axioskey !== undefined) {
            //console.warn(config.axioskey);
            if (Object.keys(base).includes(config.axioskey)) {
                return base[config.axioskey];
            } else {
                console.warn("axioskey defined but not found");
            }
        }

        if (config.axiosflag === "multiset") {
            return base;
        }

        //console.warn("base!",base);
        if (Object.keys(base).includes("newdatatable")) {
            //console.log("events?!?!", base["newdatatable"]);
            return base["newdatatable"];
        }
        delete base.$;
        return base[Object.keys(base)[0]];
    }
    //work order tool inserts
    else if (
        xmljson.dataset
        && xmljson.dataset['diffgr:diffgram']
        && xmljson.dataset['diffgr:diffgram'][0]
        && xmljson.dataset['diffgr:diffgram'][0]['newdataset']) {
        return null;
    }
    else if (xmljson.dataset
        && xmljson.dataset['diffgr:diffgram']
        && xmljson.dataset['diffgr:diffgram'][0]
    ) {
        let base = xmljson.dataset['diffgr:diffgram'][0];
        if (config.axioskey !== undefined) {
            //console.warn(config.axioskey);
            if (Object.keys(base).includes(config.axioskey)) {
                return base[config.axioskey];
            } else {
                console.warn("axioskey defined but not found");
            }
        }
        return null;
    }
    else if (xmljson.arrayofstring) {
        return xmljson.arrayofstring.string;
    }
    else {
        console.log("weird response>", xmljson)
        return xmljson;
    }
}

function flattenMultiRow(MultiRowObj) {
    if (MultiRowObj === null) {
        return [];
    }
    //used for flattening a dataset with multiple rows and values in arrays;
    return _.map(MultiRowObj, function (row) {
        let columns = Object.keys(row);
        _.each(columns, function (c) {
            if (row[c] && row[c].length == 1) {
                if (typeof row[c][0] === "string") {
                    row[c] = row[c][0].trim(" ");
                }
                if (row[c] === "true" || row[c] === "on" || row[c] === "yes") {
                    row[c] = true;
                }
                if (row[c] === "false" || row[c] === "off" || row[c] === "no") {
                    row[c] = false;
                }
            }
        })
        delete row.$;
        return row;
    });

}

function flattenSingleRow(singleRowObj) {
    if (singleRowObj === null || singleRowObj.length === 0) {
        return null;
    }
    //used for flattening a dataset with a single row and values in arrays;
    return flattenMultiRow(singleRowObj)[0];
}

export { extractJson, flattenMultiRow, flattenSingleRow }