<template>
  <div>
    <p>{{ $t("619") }}.{{ $t("620") }}</p>
    <h1>
      {{machine.card_good_qty}}
    </h1>
    <p>
      {{ $t("621") }}
    </p>
    <h2>{{$t('1274')}}: {{scrapQty}}</h2>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["data"],
  emits: ["next"],
  computed: {
    ...mapGetters({
      machineById: "machines/byId",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    scrapQty(){
      return this.machine.card_good_qty - (+this.data.quantity.value);
    }
  },
  watch: {},
};
</script>