<template>
  <div>
    <textarea
      name="note"
      id=""
      cols="30"
      rows="10"
      class="form-control"
      v-model="data[tab.dataKey].value"
    ></textarea>
    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
    <keyboard v-model="data[tab.dataKey].value" />
  </div>
</template>
<script>
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  computed: {
    disableNext() {
      return (
        this.data[this.tab.dataKey].canBeEmpty === undefined &&
        (this.data[this.tab.dataKey].value === null ||
          this.data[this.tab.dataKey].value === "")
      );
    },
  },
};
</script>