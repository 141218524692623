import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";
import { getNow } from "@/helpers/dates";
import { getStorage, setStorage, checkStorage } from "../../helpers/storage";
import { execute_json } from "@/helpers/requests";

const state = () => ({
    index: [],
});

// getters
const getters = {
    specificGoals: (state) => (location, currentMachineValues, target, type) => {

        return state.index.filter((goal) => {
            return goal[location] == currentMachineValues[location] && goal[target] !== null && (type === null || goal.production_type == type);
        })
    }
}
// actions
const actions = {
    loadProductionGoals({ commit }) {
        return new Promise((resolve, reject) => {
            execute_json("select_target").then(({ data }) => {
                commit("setProductionGoals", data);
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    createProductionGoal({ dispatch }, { goal }) {
        return new Promise((resolve, reject) => {
            execute_json("insert_target", goal).then(() => {
                dispatch("loadProductionGoals");
                resolve();
            }).catch((err) => {
                reject(err);
            })
        });
    },
    updateProductionGoal({ dispatch }, { record_no, goal }) {

        return new Promise((resolve, reject) => {
            execute_json("update_target", goal).then(() => {
                dispatch("loadProductionGoals");
                resolve();
            }).catch((err) => { reject(err) });
        });
    },
    deleteProductionGoal({ dispatch }, { record_no }) {
        return new Promise((resolve, reject) => {
            execute_json("delete_target", { record_no }).then(() => {
                dispatch("loadProductionGoals");
                resolve();
            }).catch((err) => { reject(err) });
        });
    },
}
// mutations
const mutations = {
    setProductionGoals(state, data) {
        state.index = data;
        state.index = state.index.map(goal => {
            let searchField = '';
            let fields = [
                'machine_id', 'department_id', 'zone_id', 'category_id',
                "target_good_qty",
                "target_good_qty_alt_unit",
                "target_scheduled_hours",
                "target_earned_hours",
                'target_start_time',
                'target_end_time'
            ];
            fields.forEach((field) => {
                searchField += '|' + field + ":" + goal[field]
            });
            return {
                ...goal,
                searchField
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}