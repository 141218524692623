<template>
  <div class="buttons-wrap" v-if="hasSf356 && lastUsedForMachine">
    <button
      class="router-button nowrap"
      v-for="sr in lastUsedForMachine"
      :key="sr.stop_description"
      @click="directCode(sr)"
    >
      <i class="fas fa-sticky-note" v-if="sr.stop_text_flag"></i>
      {{ sr.stop_description }}
    </button>
    <keyboard v-model="note" v-if="notePopupEnabled" />
    <div
      class="popup-wrap"
      v-if="notePopupEnabled"
      @click.self="directCode(activeCode)"
      style="color: black"
    >
      <div class="popup">
        <h2>{{ $t("638") }}</h2>
        <textarea
          name=""
          v-model="note"
          class="form-control"
          id=""
          cols="30"
          rows="4"
        ></textarea>
        <br />
        <div class="buttons-wrap">
          <!-- 3300 -> set stopreason -->
          <a
            href="#"
            class="router-button btn btn-success"
            @click.prevent="directCode(activeCode)"
          >
            <span v-if="!saving">{{ $t("3300") }}</span>
            <loading v-else />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
export default {
  data() {
    return {
      note: null,
      notePopupEnabled: false,
      saving:false,
      activeCode:null
    };
  },
  emits: ["coded"],
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      bottomnavStops: (state) => state.bottomnav.data,
      lastUsedStops: (state) => state.stopcodes.lastStopReasons,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    routerlinks() {
      return {
        codeHistory: this.codeHistory_link,
      };
    },
    hasSf356() {
      return this.machineHasSf(this.bottomnavStops[0]?.machine_id, "356");
    },
    lastUsedForMachine() {
      return this.lastUsedStops?.[this.bottomnavStops[0]?.machine_id]?.slice(
        0,
        3
      );
    },
  },
  methods: {
    cancelNote() {
      this.notePopupEnabled = false;
      this.note = null;
      this.activeCode = null;
    },
    directCode(code) {
      if (code.stop_text_flag && this.note === null) {
        this.activeCode = code;
        this.notePopupEnabled = true;
        this.note = "";
        return true;
      }
      let note = this.note || "";
      this.note = null;
      this.notePopupEnabled = false;
      this.activeCode = null;
      let promises = [];
      this.loading = true;
      this.buttonText = "Saving...";
      for (let i = 0; i < this.bottomnavStops.length; i++) {
        let uncoded = this.bottomnavStops[i];
        let machine_id = uncoded.machine_id;
        let machine = this.machineById(machine_id);
        let currentStopNumberMachine = machine.stop_number;
        console.warn(currentStopNumberMachine, uncoded.stop, uncoded, machine);
        if (currentStopNumberMachine == +uncoded.stop) {
          promises.push(
            store.dispatch("stopcodes/setStopcode", {
              stopcode: code,
              machine_id: machine_id,
            })
          );
          if (uncoded.stop_reason !== "-") {
            promises.push(
              store.dispatch("uncoded/updateUncodedStop", {
                stopcode: code,
                uncoded,
                note,
              })
            );
          }
        } else {
          promises.push(
            store.dispatch("uncoded/updateUncodedStop", {
              stopcode: code,
              uncoded,
              note,
            })
          );
        }
      }
      Promise.all(promises).then(() => {
        this.$emit("coded");
        store.commit("bottomnav/disable");
        store.commit("config/activateBTable", null);
      });
    },
  },
};
</script>

<style>
</style>