<template>
  <div class="keyboard-wrap" v-if="keyboardEnabled" :style="keyboardPosition">
    <div :class="keyboardClass">
      <div class="movehandler" @mousedown="startKeyboardMove" @touchstart="startKeyboardMove">
        <i class="fas fa-arrows"></i>
      </div>
      <div class="movereset" @click="resetKeyboardMove">
        <i class="fas fa-undo"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Keyboard from "simple-keyboard";
import KeyboardLayouts from "simple-keyboard-layouts";
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import _ from "lodash";
import "simple-keyboard/build/css/index.css";

export default {
  emits: ["onKeyPress", "update:modelValue"],
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String,
    },
    modelValue: {
      type: String,
    },
    numeric: {
      default: false,
    },
  },
  data: () => ({
    keyboard: null,
    layouts: null,
    selectedLayout: null,
    layoutsObj: null,
    loaded: false,
  }),
  mounted() {
    if (this.keyboardEnabled) {
      this.keyboard = new Keyboard(this.keyboardClass, {
        onChange: this.onChange,
        onKeyPress: this.onKeyPress,
        layout: this.keyboardLayout,
        mergeDisplay: true,
        display: {
          "{special}": "special",
          "{normal}": "<- normal",
        },
      });
      this.keyboard.setInput(this.modelValue);
      if (!this.specialLoaded) {
        this.loadSpecials();
      }
    }
    this.loaded = true;
  },
  methods: {
    onChange(input) {
      if (this.numeric && input.replace(/\D/g, "") !== input) {
        input = input.replace(/\D/g, "");
      }
      this.$emit("update:modelValue", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
      if (button === "{special}") {
        this.keyboard.setOptions({ layoutName: "special" });
      }
      if (button === "{normal}") {
        this.keyboard.setOptions({ layoutName: "default" });
      }
      //
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
    loadSpecials() {
      store.dispatch("keyboard/loadSpecials").then(() => {});
    },
    startKeyboardMove(e) {
      document.addEventListener("mousemove", this.keyboardMove);
      document.addEventListener("touchmove", this.keyboardMove);
      document.addEventListener("mouseup", this.endKeyboardMove);
      document.addEventListener("touchend", this.endKeyboardMove);
    },
    keyboardMove(e) {
      console.log("mouse location:", e.clientX, e.clientY);
      store.commit("keyboard/setKeyboardPosition", this.screenPercentages(e));
      console.log("keyboard/setKeyboardPosition", this.screenPercentages(e));
    },
    endKeyboardMove(e) {
      document.removeEventListener("mousemove", this.keyboardMove);
      document.removeEventListener("mouseup", this.endKeyboardMove);
      document.removeEventListener("touchmove", this.keyboardMove);
      document.removeEventListener("touchend", this.endKeyboardMove);
    },
    screenPercentages(e) {
      return {
        left: Math.min(1,(Math.max(40, e.clientX || e.touches[0].clientX) / window.innerWidth)) * 100 + "%",
        bottom: (Math.max(-190, (window.innerHeight - (e.clientY || e.touches[0].clientY) - 230)) / window.innerHeight) * 100 + "%",
      };
    },
    resetKeyboardMove(e){
      
      store.commit("keyboard/setKeyboardPosition", null);
    }
  },
  computed: {
    ...mapState({
      specialLoaded: (state) => state.keyboard.specialLoaded,
    }),
    ...mapGetters({
      keyboardEnabled: "keyboard/Enabled",
      keyboardLanguage: "keyboard/Language",
      keyboardLayout: "keyboard/Layout",
      keyboardPosition: "keyboard/Position",
    }),
  },
  watch: {
    modelValue(input) {
      if (this.keyboard !== null) {
        this.keyboard.setInput(input);
      }
    },
    keyboardLayout: {
      deep: true,
      handler: function () {
        this.keyboard.setOptions({
          layout: this.keyboardLayout,
        });
      },
    },
  },
};
</script>
