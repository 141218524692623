<template>
  <div class="interface-swap">
    <button
      class="btn float-end"
      type="button"
      id="machineSwapDropdown"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      v-if="!buttonDisabled"
    >
      <span v-if="!hasSf36">{{ machine_id }} - </span>{{ machine_description }}
    </button>
    <button
      class="btn float-end"
      type="button"
      style="opacity: 0.65"
      v-else
      @click.prevent="requestPageSwap"
    >
      <span v-if="!hasSf36">{{ machine_id }} - </span>{{ machine_description }}
    </button>
    <ul
      class="dropdown-menu dropdown-menu-center"
      aria-labelledby="machineSwapDropdown"
    >
      <li v-for="m in sorted_machines" :key="m.machine_id">
        <a class="dropdown-item" href="#" @click.prevent="goTo(m)"
          ><span v-if="!hasSf36">{{ m.machine_id }} - </span
          >{{ m.object_description }}</a
        >
      </li>
      <li v-if="!showResourceType2">
        <h6 class="dropdown-header">{{ $t("573") }}:</h6>
      </li>
      <li v-if="!showResourceType2">
        <a
          href="#"
          class="dropdown-item"
          @click.prevent="setLocation($event, null, null)"
          >{{ $t("570") }}</a
        >
      </li>
      <li v-if="!showResourceType2">
        <a
          href="#"
          class="dropdown-item"
          @click.prevent="toggleMine"
          :class="{ disabled: mineMode }"
          >{{ $t("280") }}</a
        >
      </li>

      <li v-if="!showResourceType2">
        <a
          href="#"
          class="dropdown-item"
          :class="{
            disabled:
              !mineMode && l.loctype == lastLocType && l.location == lastLoc,
          }"
          @click.prevent="setLocation($event, l.loctype, l.location)"
          v-for="(l, index) in locationsCombined"
          :key="index"
          >{{ l.loctype }}:{{ l.label }}</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import store from "@/store";
export default {
  props: {},
  mounted() {
    store.dispatch("config/loadMyMachines");
    this.checkForResourceType();
  },
  computed: {
    ...mapState({
      list: (state) => state.machines.list,
      machines: (state) => state.machines.index,
      inverseList: (state) => state.machines.inverseList,
      lastLoc: (state) => state.sidebar.lastLocation,
      lastLocType: (state) => state.sidebar.lastLocationType,
      myMachines: (state) => state.config.myMachines,
      singleMachine: (state) => state.auth.web_single_machine,
    }),
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
    }),
    hasSf36() {
      return this.machineHasSf(this.machine_id, "36");
    },
    showResourceType2() {
      return this.$route.meta.resourceType2 ? true : false;
    },
    rMachines() {
      return this.showResourceType2
        ? this.list
            .filter((m) => {
              return m.resource_type == 2;
            })
            .map((m) => {
              return {
                ...m,
                machine_id: m.object_no,
                machine_description: m.object_description,
              };
            })
        : this.machines.filter((m) => {
            return this.inverseList[m.machine_id]?.resource_type != 2;
          });
    },
    machine_id() {
      return this.$route.params.machine;
    },
    machine_description() {
      return (
        this.showResourceType2
          ? this.rMachines.find((m) => m.machine_id === this.machine_id) || {}
          : this.machines.find((m) => m.machine_id === this.machine_id) || {}
      ).object_description;
    },
    sorted_machines() {
      return _.sortBy(
        this.filteredMachines,
        this.hasSf36 ? "object_description" : "machine_id"
      );
    },
    address_base() {
      return this.$route.path.replace("/" + this.machine_id, "/");
    },
    filteredMachines() {
      if (this.showResourceType2) {
        return this.rMachines;
      }
      if (this.mineMode) {
        return _.filter(this.rMachines, (m) => {
          return this.myMachines.includes(m.machine_id);
        });
      }
      let locationtype = this.lastLocType;
      let location = this.lastLoc;
      return _.filter(this.rMachines, (m) => {
        return (
          location === null ||
          locationtype === null ||
          m[locationtype] == location
        );
      });
    },
    availabledepartment() {
      let uniqueKeys = [
        ...new Set(this.machines.map((item) => item.department)),
      ];
      let unique = uniqueKeys.map((k) => {
        return {
          key: k,
          label: this.machines.find((o) => o.department === k)
            .department_description,
        };
      });
      return _.filter(unique, (department) => {
        return department.key !== undefined && department.key != "";
      });
    },
    availablezone() {
      let uniqueKeys = [...new Set(this.machines.map((item) => item.zone))];
      let unique = uniqueKeys.map((k) => {
        return {
          key: k,
          label: this.machines.find((o) => o.zone === k).zone_desc,
        };
      });
      return _.filter(unique, (zone) => {
        return zone.key !== undefined && zone.key != "";
      });
    },
    availablecategory() {
      let uniqueKeys = [...new Set(this.machines.map((item) => item.category))];
      let unique = uniqueKeys.map((k) => {
        return {
          key: k,
          label: this.machines.find((o) => o.category === k).cat_desc,
        };
      });
      return _.filter(unique, (category) => {
        return category.key !== undefined && category.key != "";
      });
    },
    locationsCombined() {
      let vm = this;
      let combined = [];
      _.each(
        [
          { loctype: "department", tkey: "2" },
          { loctype: "zone", tkey: "331" },
          { loctype: "category", tkey: "332" },
        ],
        ({ loctype, tkey }) => {
          _.each(vm["available" + loctype], ({ key, label }) => {
            combined.push({
              loctype,
              loctype_key: tkey,
              location: key,
              label,
            });
          });
        }
      );
      return combined;
    },
    buttonDisabled() {
      return this.singleMachine !== "-1";
    },
  },
  data() {
    return {
      combined: this.currentcombined,
      mineMode: false,
    };
  },
  watch: {
    combined(val) {
      let [loctype, loc] = val.split("|");
      if (this.$route.path.includes("uncoded-location")) {
        this.$router.push(
          "/machines/" + loctype + "/" + loc + "/uncoded-location"
        );
      } else {
        this.$router.push("/machines/" + loctype + "/" + loc);
      }
    },
    $route() {
      this.checkForResourceType();
    },
  },

  methods: {
    goTo(machine) {
      let location = machine[this.lastLocType];
      if (location && location !== this.lastLoc) {
        store.commit("sidebar/setLastLocation", location);
      } else if (!location) {
        store.commit("sidebar/setLastLocationType", "department");
        store.commit("sidebar/setLastLocation", machine.department);
      }

      let machinePath = this.$route.path.replace(
        "/" + this.machine_id,
        "/" + machine.machine_id
      );
      this.$router.push(machinePath);
      //this.$router.push(this.address_base + machine.machine_id);
    },
    setLocation(e, loctype, loc) {
      this.mineMode = false;
      store.commit("sidebar/setLastLocationType", loctype);
      store.commit("sidebar/setLastLocation", loc);
      e.stopPropagation();
    },
    toggleMine(e) {
      this.mineMode = true;
      e.stopPropagation();
    },
    checkForResourceType() {
      if (this.machine_id) {
        if (
          this.showResourceType2 &&
          (this.machine_id == "undefined" ||
            this.inverseList[this.machine_id]?.resource_type != 2) &&
          this.rMachines.length
        ) {
          console.log("get out of here!");
          this.goTo(this.sorted_machines[0]);
        } else if (
          !this.showResourceType2 &&
          (this.machine_id == "undefined" ||
            this.inverseList[this.machine_id]?.resource_type == 2) &&
          this.rMachines.length
        ) {
          console.log("get out of here!");
          this.goTo(this.sorted_machines[0]);
        }
      }
    },
    requestPageSwap() {
      console.log("test");
      store.commit("events/addLocalEvent", {
        local: true,
        event_type: "localPageSwap",
        machine_id: this.machine_id,
      });
    },
  },
  components: {},
};
</script>
<style>
</style>