<template>
  <div>
    <Loading v-if="loading" />
    <div v-else-if="error">
      {{ error.call_message }}
    </div>
    <div v-else>
      <p v-if="orders !== null">
        <span v-if="orders.length > 0 && orders[0].status != '0'">
          <c-table
            :data="orders"
            primary-key="card_no"
            config-string="rework_orders"
            :searchable="true"
            searchfield="searchField"
            @selectionChanged="onSelectionChanged"
            :teleport="true"
          >
          </c-table>
        </span>
        <span v-else>
          {{ $t("1259") }}
        </span>
      </p>
      <p v-else>
        {{ $t("1817") }}
      </p>
      <button
        class="tabbed-next-button"
        @click="$emit('next')"
        :disabled="disableNext"
        :class="{ disabled: disableNext }"
      >
        <i class="fas fa-chevron-right" v-if="!islast"></i>
        <i class="fas fa-check" v-if="islast"></i>
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      selectedRows: [],
      loading: false,
      error: null,
    };
  },
  mounted() {
    this.loading = true;
    this.data[this.tab.dataKey].selected = null;
    store
      .dispatch("orders/loadReworkOrdersForMachine", {
        machine_id: this.machine_id,
      })
      .then(() => {
        this.loading = false;
        if (
          this.orders.length === 1 &&
          this.data[this.tab.dataKey].jumped === undefined
        ) {
          this.data[this.tab.dataKey].selected = this.orders[0];
          this.data[this.tab.dataKey].jumped = true;
          this.$emit("next");
        }
      })
      .catch((err) => {
        this.loading = false;
        this.error = err.response?.data?.call_status?.[0];
      });
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      reworkOrders: (state) => state.orders.reworkOrders,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      columnConfigByTemplate: "config/columnConfigByTemplate",
      canUser: "permissions/canUser",
    }),
    columnConfig() {
      return this.columnConfigByTemplate("rework_orders");
    },
    baseColumnConfig() {
      return this.columnConfigByTemplate("rework_orders_baseconfig");
    },
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    orders() {
      return this.reworkOrders[this.machine_id] || null;
    },
    disableNext() {
      return this.data[this.tab.dataKey].selected === null;
    },
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length > 0 && this.selectedRows[0]) {
        this.data[this.tab.dataKey].selected = this.selectedRows[0];
      } else {
        this.data[this.tab.dataKey].selected = null;
      }
    },
  },
  watch: {},
};
</script>