<template>
  <div>
    <p class="present-value">
      {{ machine.part_no }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["machine", "order", "planned"],
};
</script>

<style>
</style>