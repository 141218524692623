<template>
  <material-lines :order="data.order" @close="deActivate"></material-lines>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapGetters } from "vuex";
import { flattenSingleRow } from "@/requests/requestParcer";
import materialLines from "@/components/MaterialLines";
import store from "@/store";

export default {
  props: ["data"],
  emits: ["close"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleEnter() {},
    deActivate() {
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
      signedInByMachine: "machines/signedInByMachine",
      machineById: "machines/byId",
      runningByMachine: "orders/runningByMachine",
    }),
    machine_id() {
      return this.data.machine_id;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
  },
  watch: {},
  components: {
    materialLines,
  },
};
</script>