<template>
  <button class="btn" @click="$emit('reject')">
    <i class="fas fa-times"></i> {{ $t("no") }}</button
  >&nbsp;&nbsp;
  <button class="btn btn-success" @click="$emit('resolve')">
    {{ $t("2996") }} <i class="fas fa-chevron-right"> </i>
  </button>
</template>

<script>
export default {
  props: {
    data: {},
  },
  emits:['resolve','reject']
};
</script>

<style>
</style>