<template>
  <div id="notification-center">
    <transition-group name="notification" v-if="!inDashBoardMode">
      <notification
        v-for="notification in activeNotifications"
        @deactivate="deActivateNotification"
        :notification="notification"
        :key="notification.id"
      >
      </notification>
    </transition-group>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Notification from "./Notification";
import _ from "lodash";
import store from "@/store";

export default {
  data() {
    return {
      intervalId: null,
      currentTime: new Date().getTime(),
      internalClock: 0,
      backgroundMode: false,
      bgModeStarted: null,
      closeOld: process.env.NODE_ENV === "production" ? true : false,
    };
  },
  mounted() {
    store.dispatch("notifications/loadHistory");
  },
  methods: {
    handleVisibilityChange() {
      if (document.visibilityState == "hidden") {
        this.backgroundMode = true;
        this.bgModeStarted = this.internalClock;
        console.log(
          "%c went into background mode at " + this.internalClock,
          "background:#000;color:#fff"
        );
      } else {
        this.backgroundMode = false;
        this.bgModeStarted = null;
        console.log(
          "%c went out of background mode at " + this.internalClock,
          "background:#000;color:#fff"
        );
      }
    },
    deActivateSelfclosing() {
      _.each(this.activeNotifications, (n) => {
        if (
          n.selfClosing &&
          this.currentTime >= n.updated + (n.closeAfter || 3000)
        ) {
          console.log(n, this.currentTime);
          store.commit("notifications/deActivate", n.id);
        }
        if (this.closeOld && this.currentTime >= n.updated + 15000) {
          store.commit("notifications/deActivate", n.id);
        }
      });
    },
    deActivateNotification({notification,options}) {
      store.commit("notifications/deActivate", {id:notification.id,options});
    },
  },
  computed: {
    ...mapState({
      notifications: (state) => state.notifications.index,
      notificationsEnabled: (state) => state.notifications.enabled
    }),
    inDashBoardMode(){
      return this.$route.path.includes("/dashboards/") && !this.$route.path.includes("/settings/");
    },
    ...mapGetters({
      logCount: "notifications/logCount"
    }),
    activeNotifications() {
      return this.notifications.filter((n) => n.active && !n.silent && !n.popup && (this.notificationsEnabled === '1' || n.event == null));
    },
  },
  components: {
    Notification,
  },
};
</script>