<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget" v-if="isManualMachine">
    <div v-if="machine && machine.empty !== true">
      <h1>
        {{ $t("880") }}
        <card-menu :items="items"></card-menu>
      </h1>
      <div class="vertically-centered-content">
        <!-- machine has a stop reason -->
        <p v-if="needsOrder">
          {{ $t("5591") }}
        </p>
        <p v-if="needsSignedIn">
          {{ $t("5016") }}
        </p>
        <div class="button-wrap horizontal spaced flexing">
          <button
            class="btn btn-danger col"
            :disabled="machine.status != 'R' || pressedStop"
            href="#"
            @click.prevent="toggleManualMachine"
          >
            <span v-if="!pressedStop">{{ $t("92") }}</span>
            <loading v-else />
          </button>
          <button
            class="btn btn-success col"
            :disabled="
              machine.status === 'R' ||
              pressedStart ||
              needsSignedIn ||
              needsOrder
            "
            href="#"
            @click.prevent="toggleManualMachine"
          >
            <span v-if="!pressedStart"> {{ $t("110") }}</span>
            <loading v-else />
          </button>
        </div>
      </div>
    </div>
    <div v-if="machine && machine.empty === true">Machine does not exist.</div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import { mapState, mapGetters } from "vuex";
import { Notification } from "@/helpers/notifications";
import axios from "@/requests/axios";
import store from "@/store";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      machine_id: this.$route.params.machine,
      pulse: null,
      pressedStart: false,
      pressedStop: false,
    };
  },
  mounted() {
    this.pulse = new Pulse({
      action: "machines/loadMachine",
      interval: 3,
      primaryKey: "machine_id",
      parameters: { machine_id: this.machine_id },
    });
    store.dispatch("machines/loadSignedInForMachine", this.machine_id);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  methods: {
    toggleManualMachine() {
      let newStatus = "S";
      if (this.machine.status === "S") {
        newStatus = "R";
        this.pressedStart = true;
      } else {
        this.pressedStop = true;
      }
      let note = Notification.request({
        message:
          newStatus == "R" ? "turning on machine" : "turning off machine",
      });
      execute_json("update_manual_machine_status", {
        machine_id: this.machine_id,
        machine_status: newStatus,
      })
        .then(() => {
          note.ok({
            message:
              newStatus == "R" ? "turned on machine" : "turned off machine",
          });
          this.pressedStart = this.pressedStop = false;
          if (newStatus != "R") {
            this.$router.push("/interface/" + this.machine_id + "/stopcode");
          }
        })
        .catch((err) => {
          console.log(err, Object.keys(err));
          note.close();
        });
    },
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      machineById: "machines/byId",
      parametersById: "machines/parametersById",
      configByMachine: "machines/configByMachine",
      signedInByMachine: "machines/signedInByMachine",
    }),
    signedIn() {
      return this.signedInByMachine(this.machine_id);
    },
    config() {
      return this.configByMachine(this.machine_id);
    },
    isManualMachine() {
      return this.config.manual_machine || false;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    machineParameters() {
      return this.parametersById(this.machine_id);
    },
    needsSignedIn() {
      if (
        this.machineParameters === null ||
        this.machineParameters.empty === true
      ) {
        return true;
      }
      if (this.machineParameters.man_machine_wo_man) {
        return false;
      }
      return this.signedIn.length === 0;
    },
    needsOrder() {
      return this.machine.work_order_no === "-";
    },
    status() {
      let status = "";
      if (this.machine.status === "S") {
        if (this.machine.current_stop_reason === "-") {
          status += "status-uncoded";
        } else {
          status += "status-stop";
        }
      } else {
        status += "status-running";
      }
      status +=
        " c-" +
        (this.machine.stop_reason_color &&
        this.machine.stop_reason_color != "Automatic"
          ? this.machine.stop_reason_color.toLowerCase()
          : "") +
        " ";
      return status;
    },
    items() {
      return [];
    },
  },
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
  },
};
</script>