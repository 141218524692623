import { h } from 'vue';
export default {
  render() {
    return h(
      this.tag, // tag name
      {style:this.style,color:this.color,},
      this.text // array of children
    );
  },
  props: {
    tag: {
      type: String,
      required: true,
    },
    text: {
      default: ""
    },
    color:{
      default:null
    },
    fontSize:{
      default:-1
    }
  },
  computed:{
    style(){
      return "white-space:pre-line;"+this.fontSize == -1 ? "" : "font-size:"+this.fontSize+"px;"+"color:"+this.color;
    }
  }
};