<template>
  <div v-if="machine">
    <h1>
      {{ $t("1976") }}
      <div class="card-menu-sibling" v-if="detailsMachine">
        <button class="btn" @click="details = true">
          <i class="fal fa-info-circle"></i>
        </button>
      </div>
      <card-menu :items="items" :config="config"></card-menu>
    </h1>
    <div v-if="orders !== null">
      <div
        v-if="orders.length > 0 && orders[0].status != '0'"
        style="font-size: 8px"
      >
        <c-table
          :data="orders"
          primary-key="record_no"
          config-string="finished_orders"
          default-sort-key="end_time"
          default-sort-dir="desc"
          :colors="colors"
          @selectionChanged="onSelectionChanged"
          :teleport="true"
        >
        </c-table>
      </div>
      <div class="center-widget" v-else>
        {{ $t("4744") }}
      </div>
    </div>
    <div class="center-widget" v-else>
      {{ $t("1817") }}
    </div>
    <order-details
      v-if="details"
      @close="details = false"
      :machine_id="detailsMachine.machine_id"
      :card_no="detailsMachine.card_no"
      :p_order="detailsMachine.p_order"
      :finished="true"
    ></order-details>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import CardMenu from "@/components/card-menu";
import OrderDetails from "@/components/OrderDetails";
import { Configuration } from "@/helpers/settings";
import { Pulse } from "@/helpers/heartbeat";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      machine_id: this.$route.params.machine,
      interval: 600,
      selectedRows: [],
      pulse: null,
      config: new Configuration(
        "finished-orders",
        {
          colorKey: "a",
        },
        this.template
      ),
      details: false,
    };
  },
  mounted() {
    this.pulse = new Pulse({
      action: "orders/loadFinishedOrdersForMachine",
      parameters: {
        machine_id: this.machine_id,
        non_cancelled: this.hasSf196,
      },
      interval: this.interval,
      primaryKey: "machine_id",
    });
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  methods: {
    showDetails() {
      this.details = true;
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length === 1){
        store.commit("selections/set", {
          type: "order",
          data: this.selectedRows[0],
          subtype:"finished"
        });
      }
      /*
      if (this.selectedRows.length === 0) {
        store.commit("bottomnav/disable");
        store.commit("selections/unset", "order");
      } else {
        store.commit("bottomnav/enable");
        store.commit("selections/set", {
          type: "order",
          data: this.selectedRows[0],
        });
      }
      store.commit("bottomnav/setType", "planned-order-singular");
      store.commit("bottomnav/setData", this.selectedRows);
      */
    },
    colorValue(value, limits, limitstyle = "linear") {
      let color = "red";
      limits = this[limits];
      for (let i = limits.length - 1; i >= 0; i--) {
        if (limitstyle === "linear") {
          if (value >= limits[i].limit) {
            color = limits[i].color;
            break;
          }
        } else {
          if (
            value >= 100 - limits[i].limit &&
            (!limits[i].capped || value <= 100 + limits[i].limit)
          ) {
            color = limits[i].color;
            break;
          }
        }
      }
      return color;
    },
  },
  watch: {
    workOrder(val, old) {
      if (old !== null) {
        this.pulse.force();
      }
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      finishedOrders: (state) => state.orders.finishedOrders,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      columnConfigByTemplate: "config/columnConfigByTemplate",
      machineHasSf: "machines/machineHasSf",
      canUser: "permissions/canUser",
    }),
    userPermission() {
      return (
        this.canUser("change-own-webcolumns") ||
        this.canUser("change-default-webcolumns")
      );
    },
    detailsMachine() {
      if (this.selectedRows.length) {
        return this.selectedRows[0];
      }
      if (this.orders && this.orders.length === 1) {
        return this.orders[0];
      }
      return null;
    },
    hasSf196() {
      return this.machineHasSf(this.machine_id, "196");
    },
    columnConfig() {
      return this.columnConfigByTemplate("finished_orders_widget");
    },
    baseColumnConfig() {
      return this.columnConfigByTemplate("finished_orders_widget_baseconfig");
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    orders() {
      return this.finishedOrders[this.machine_id] || null;
    },
    items() {
      let items = [];
      items.push({
        header: this.$t("832"),
      });

      items.push({
        tlabel: "2101",
        handler: () => {
          this.config.setValues({ colorKey: "t" });
        },
        selected: this.config.colorKey === "t",
      });
      items.push({
        tlabel: "1898",
        handler: () => {
          this.config.setValues({ colorKey: "a" });
        },
        selected: this.config.colorKey === "a",
      });
      items.push({
        tlabel: "1639",
        handler: () => {
          this.config.setValues({ colorKey: "k" });
        },
        selected: this.config.colorKey === "k",
      });
      items.push({
        tlabel: "1640",
        handler: () => {
          this.config.setValues({ colorKey: "tak" });
        },
        selected: this.config.colorKey === "tak",
      });
      if (this.userPermission) {
        items.push({ header: this.$t("89") });
        items.push({
          tlabel: "506",
          to: "/admin/config/tables/finished_orders_widget",
        });
      }
      return items;
    },
    workOrder() {
      return this.machine.work_order_no;
    },
    colors() {
      //TODO:fix
      if (this.orders && 1) {
        let colors = {};
        //let limits = this[config.limits];
        this.orders.forEach((order) => {
          colors[order.record_no] = this.colorValue(
            order[this.config.colorKey + (this.config.colorKey == 'tak' ? '' : "_percent")],
            this.config.colorKey + "limits",
            this.config.colorKey === "a" ? "clamped" : "linear"
          );
        });
        return colors;
      }
      return null;
    },
    tlimits() {
      return [
        { limit: +this.machine.bar_yellow_limit, color: "yellow" },
        { limit: +this.machine.bar_green_limit, color: "green" },
      ];
    },
    alimits() {
      return [
        {
          limit: +this.machine.speed_deviation_yellow_limit,
          color: "yellow",
          capped: !this.machine.speed_over_100,
        },
        {
          limit: +this.machine.speed_deviation_green_limit,
          color: "green",
          capped: !this.machine.speed_over_100,
        },
      ];
    },
    klimits() {
      return [
        { limit: +this.machine.q_yellow_limit, color: "yellow" },
        { limit: +this.machine.q_green_limit, color: "green" },
      ];
    },
    taklimits() {
      return [
        { limit: +this.machine.oee_yellow_limit, color: "yellow" },
        { limit: +this.machine.oee_green_limit, color: "green" },
      ];
    },
  },
  components: {
    CardMenu,
    OrderDetails,
  },
};
</script>