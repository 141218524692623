<template>
  <div>
    <div class="normal-status-wrap">
      <div
        class="indicator status-large"
        :class="{
          'c-green': partData && currentAmount > partData.nbrInPack,
          'c-gray':loading,
          'c-red': partData === null && !loading,
        }"
        style="padding: 10px"
      >
        <div v-if="part === null && mode === 'scanner'">
          <h4>{{ $t("2443") }}, {{ $t("6947") }}</h4>
        </div>
        <div v-else-if="(part === null || part === '-') && mode === 'machine'">
          <h4>{{ $t("2443") }}. {{ $t("2367") }}.</h4>
        </div>
        <div v-else-if="loading">
          <loading/>
        </div>
        <div v-else-if="partData === null">
          <h4>
            {{ lastError ? lastError : $t("4742") }} <br /><br />
            <button @click="getPartData" class="btn button btn-success">
              <i class="fa fa-sync"></i>
            </button>
          </h4>
        </div>
        <div v-else>
          <h4>{{ $t("6948") }}:</h4>
          <h1>{{ currentAmount || "0" }}/{{ partData?.nbrInPack }}</h1>

          <h4>{{ $t("1703") }}: {{ partData?.packagingType }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PropGraph from "./PropGraph.vue";
import store from "@/store";
import { Pulse } from "@/helpers/heartbeat";
import axios from "axios";
import { execute_json } from "@/helpers/requests";
import { Notification } from "@/helpers/notifications";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      store: 1,
      partData: null,
      lastError: null,
      loading: false,
    };
  },
  mounted() {
    store.commit("livedata/registerLink", {
      cid: this.cid,
      interval: this.interval,
      store: this.store,
      link: this.link?.record_no,
    });
    this.pulse = new Pulse([
      {
        action: "livedata/fetchLinkData",
        interval: +this.interval,
      },
    ]);
    this.getPartData();
  },
  beforeUnmount() {
    store.commit("livedata/removeLinksForCid", this.cid);
    this.pulse.stop();
  },
  props: {
    link: {
      required: true,
    },
    interval: {
      required: true,
    },
    part: {
      required: true,
    },
    mode: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      dataForLink: "livedata/dataForLink",
    }),
    data() {
      return this.dataForLink(this.link?.record_no, this.interval, this.store);
    },
    currentWeight() {
      return this.data?.values?.[0];
    },
    netWeight() {
      return +this.partData?.netWeight.replace(",", ".") || 0;
    },
    packagingWeight() {
      return this.partData?.packagingWeight.replace(",", ".") || 0;
    },
    currentAmount() {
      return this.partData !== null
        ? Math.max(
            Math.round(
              (this.currentWeight - this.packagingWeight) / this.netWeight
            ),
            0
          )
        : "-";
    },
  },
  methods: {
    getPartData() {
      this.lastError = null;
      this.partData = null;
      if (this.part !== null && this.part !== "-" && this.part !== '') {
        this.loading = true;
        axios
          .get(
            "https://prod-13.northeurope.logic.azure.com/workflows/6b8412bf0eb34c978256276edf38184f/triggers/manual/paths/invoke/materials/" +
              this.part +
              "?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Tpz4lfQ1w_SCgRzhtjN4537Ir5mdG6orxmfpRjPvhZE"
          )
          .then(({ data }) => {
            if (data.messageType !== "E") {
              let unfilled = [
                "packagingWeight",
                "netWeight",
                "nbrInPack",
              ].filter((k) => data[k] == "");
              if (unfilled.length === 0) {
                this.partData = data;
              } else {
                this.lastError = unfilled.join(", ") + " missing.";
                let n = Notification.failed(this.lastError);
              }
            } else {
              this.lastError = data.messageText;
              let n = Notification.failed(this.lastError);
            }
            console.log(data);
          })
          .finally(() => {
            this.loading = false;
          });
        /*
      execute_json(
          "alm40692_integration",
          {
            part_no: this.part,
          },
          { multitable: true }
        ).then(({ data }) => {
          this.partData = JSON.parse(
            data?.call_status?.[0].call_message || JSON.stringify({})
          );
          console.log(data);
        });
        */
      }
    },
  },
  watch: {
    link(link, oldLink) {
      store.commit("livedata/removeLinksForCid", this.cid);
      store.commit("livedata/registerLink", {
        cid: this.cid,
        interval: this.interval,
        store: this.store,
        link: link?.record_no,
      });
    },
    interval(int) {
      this.pulse.stop();
      this.pulse = new Pulse([
        {
          action: "livedata/fetchLinkData",
          interval: +int,
        },
      ]);
    },
    part() {
      this.getPartData();
    },
  },
  components: { PropGraph },
};
</script>