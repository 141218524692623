import defaultLayout from '@/layouts/DefaultLayout'
import interfaceLayout from "@/layouts/InterfaceLayout"

function loadView(view) {
    return () => import(/* webpackChunkName: "[request]" */ `@/views/${view}.vue`)
}

export const documentRoutes = [

    {
        path: "/files",
        name: "files index",
        meta: {
            requiresAuth: true,
            layout: defaultLayout,
            title: "Files index"
        },
        component: loadView("files/files-index")
    },
    {
        path: "/files/:record_no",
        name: "file view",
        meta: {
            requiresAuth: true,
            layout: defaultLayout,
            title: "View file"
        },
        component: loadView("files/file-view")
    },
    {
        path: "/files/q-reports",
        name: "quick reports",
        meta: {
            requiresAuth: true,
            layout: defaultLayout,
            title: "Quick reports"
        },
        component: loadView("files/quick-reports")
    },
    {
        path: "/reports/order",
        name: "order reports",
        meta: {
            requiresAuth: true,
            layout: defaultLayout,
            title: "Order reports"
        },
        component: loadView("reports/order/index"),
        children: [
            {
                path: ':reportidentity',
                component: loadView("reports/order/view"),
                meta: {
                    requiresAuth: true,
                    layout: defaultLayout,
                    title: "Order reports"
                },
                children: [
                    {
                        path: 'schedule',
                        component: loadView("reports/order/schedule"),
                        meta: {
                            requiresAuth: true,
                            layout: defaultLayout,
                            title: "Schedule"
                        }, name: "schedule report view"
                    }
                ],
                name: "report view"
            }
        ]
    },
    {
        path: "/interface/:machine/files",
        name: "interface files index",
        meta: {
            requiresAuth: true,
            layout: interfaceLayout,
            title: "Files index"
        },
        component: loadView("files/files-index")
    },
    {
        path: "/interface/:machine/files/:record_no",
        name: "interface file view",
        meta: {
            requiresAuth: true,
            layout: interfaceLayout,
            title: "View file"
        },
        component: loadView("files/file-view")
    },
    {
        path: "/interface/:machine/files/q-reports",
        name: "interface quick reports",
        meta: {
            requiresAuth: true,
            layout: interfaceLayout,
            title: "Quick reports"
        },
        component: loadView("files/quick-reports")
    },
    {
        path: "/interface/:machine/documents",
        name: "interface document index",
        meta: {
            requiresAuth: true,
            layout: interfaceLayout,
            title: "document index"
        },
        component: loadView("files/document-index")
    },
    {
        path: "/interface/:machine/documents/:hash",
        name: "interface document view",
        meta: {
            requiresAuth: true,
            layout: interfaceLayout,
            title: "document view"
        },
        component: loadView("files/document-view")
    },
    {
        path: "/interface/:machine/running-order-documents",
        name: "running order document index",
        meta: {
            requiresAuth: true,
            layout: interfaceLayout,
            title: "document index | running order"
        },
        component: loadView("files/running-order-documents")
    },
    {
        path: "/interface/:machine/planned-order-documents",
        name: "planned order document index",
        meta: {
            requiresAuth: true,
            layout: interfaceLayout,
            title: "document index | planned order"
        },
        component: loadView("files/planned-order-documents")
    },
];
