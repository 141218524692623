import axios from '@/requests/axios'
import { flattenMultiRow } from '@/requests/requestParcer'
import { execute_json } from "@/helpers/requests"
import { getNow } from '@/helpers/dates'
import { removeStorage } from '@/helpers/storage'

const state = () => ({
  runningOrders: {},
  plannedOrders: {},
  reworkOrders: {},
  finishedOrders: {},
  historicalOrders: {},
  transactions: {},
  eanCodes: {},
})

// getters
const getters = {
  runningByMachine: (state) => (machine_id) => {
    return state.runningOrders[machine_id] || []
  },
  plannedByMachine: (state) => (machine_id) => {
    return state.plannedOrders[machine_id] || []
  },
  plannedByNo: (state) => (no) => {
    return (
      state.plannedOrders.find((order) => order.work_order_no === no) || {
        empty: true,
      }
    )
  },
  groupedPlannedOrdersByMachine: (state) => (machine_id) => {
    let orders = state.plannedOrders[machine_id] || []
    let card_nos = [...new Set(orders.map((o) => o.card_no))]
    return card_nos.map((card_no) => {
      let os = orders.filter((o) => o.card_no == card_no)
      return {
        ...os[0],
        searchField:
          'part:' +
          os[0].part_no +
          '|desc:' +
          os[0].part_description +
          '|op:' +
          os[0].op_number +
          '|wo:' +
          os[0].work_order_no +
          '|card:' +
          os[0].card_no,
        _plannedIn: os
          .map((o) => o.machine_id)
          .filter((id) => id != machine_id),
      }
    })
  },
  eanCodeByWorkOrderNo: (state) => (work_order_no, machine_id) => {
    return work_order_no === null ? null : state.eanCodes[machine_id + ':' + work_order_no] || {}
  },
}

// actions
const actions = {
  loadWorkOrderTransactions({ commit, rootState }, { machine_id, card_no, time_horizon }) {
    return new Promise((resolve, reject) => {
      execute_json('get_work_order_transactions', {
        machine_id,
        card_no,
        time_horizon
      })
        .then(({ data }) => {
          commit('setWorkOrderTransactions', {
            machine_id,
            card_no,
            transactions: data,
          })
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  loadRunningOrderForMachine({ commit, rootState }, { machine_id, reload }) {
    return new Promise((resolve, reject) => {
      execute_json('get_running_orders_machine', {
        machine_id,
        force_new_data: reload,
      })
        .then(({ data }) => {
          commit('setRunningOrderForMachine', {
            machine_id,
            orders: data,
          });
          if (data?.length >= 1) {
            commit('events/addLocalEvent', {
              local: true,
              event_type: "FetchedRunningOrdersLocally",
              machine_id,
              data
            }, { root: true })
          }
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  loadPlannedOrdersForMachine(
    { commit, rootState },
    { machine_id, erp_resource, erp_connection_type, day_limit },
  ) {
    return new Promise((resolve, reject) => {
      execute_json('get_planned_orders_machine', {
        machine_id,
        erp_resource,
        exclude_running_orders: true,
        erp_connection: erp_connection_type,
        day_limit: day_limit || null,
      })
        .then(({ data }) => {
          commit('setPlannedOrderForMachine', {
            machine_id,
            orders: data,
          })
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  loadReworkOrdersForMachine({ commit, rootState }, { machine_id }) {
    return new Promise((resolve, reject) => {
      execute_json('sf346', {
        machine_id,
        feature: 'get',
      })
        .then(({ data }) => {
          commit('setReworkOrderForMachine', {
            machine_id,
            orders: data,
          })
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  loadFinishedOrdersForMachine(
    { commit, rootState },
    { machine_id, non_cancelled },
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post('balthzar_get_prod_qty_finished_orders_16_1_64', {
          machine_id,
          bolOnly_non_cancelled: non_cancelled,
        })
        .then(({ data }) => {
          commit('setFinishedOrdersForMachine', {
            machine_id,
            orders: flattenMultiRow(data),
          })
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  loadHistoricalOrdersForMachine(
    { commit, rootState },
    { machine_id },
  ) {
    return new Promise((resolve, reject) => {
      execute_json("get_historical_orders_by_machine", {
        machine_id,
        choice: 2
      })
        .then(({ data }) => {
          commit('setHistoricalOrdersForMachine', {
            machine_id,
            orders: data,
          })
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  loadOrderDetailsForMachine(
    { commit, rootState },
    { machine_id, p_order, card_no, finished },
  ) {
    return new Promise((resolve, reject) => {
      if (finished) {
        axios
          .post('balthzar_get_work_order_info_on_machine', {
            card_no,
            p_order,
            machine_id,
          })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      } else {
        axios
          .post('balthzar_create_card_history_record', {
            card_no,
            machine_id,
          })
          .then(() => {
            axios
              .post('balthzar_get_work_order_info_on_machine', {
                card_no,
                p_order,
                machine_id,
              })
              .then((data) => {
                resolve(data)
              })
              .catch((err) => {
                reject(err)
              })
          })
          .catch((err) => {
            reject(err)
          })
      }
    })
  },
  startOrder(
    { commit, rootState, dispatch },
    { order, machine_id, cavities, stop_reason, tool_structure, unit_qty },
  ) {
    return new Promise((resolve, reject) => {
      dispatch("CheckSFsBeforeStart", { machine_id }).then(() => {
        execute_json('start_work_order', {
          insatser: cavities,
          machine_id,
          card_no: order.card_no,
          work_order_no: order.work_order_no,
          part_no: order.part_no,
          work_order_qty: order.planned_op_qty,
          plan_cycle_time: order.plan_cycle_time,
          planned_setup_time: order.planned_setup_time,
          op_number: order.op_number,
          tool_id: tool_structure || null,
          unit_qty: unit_qty || null,
          stop_reason,
        })
          .then(({ data }) => {

            dispatch("machines/loadMachine", { machine_id }, { root: true });
            commit('events/addLocalEvent', {
              local: true,
              event_type: "StartedWorkOrderLocally",
              machine_id,
              order,
            }, { root: true })
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      }).catch((err) => {
        reject(err);
      })
    })

  },
  swapToOrder({ commit, rootState, dispatch, getters }, { order, machine_id }) {
    return new Promise((resolve, reject) => {
      let runningOrder = getters.runningByMachine(machine_id)[0];
      dispatch("CheckSFsBeforeStop", { machine_id, order: runningOrder }).then(() => {
        execute_json("stop_work_order", {
          machine_id,
          all: true
        }).then(() => {
          // resolve();
          //return true();
          dispatch("startOrder", { order, machine_id }).then(() => {
            //dispatch("loadPlannedOrdersForMachine", { ...order, machine_id });
            //dispatch("loadRunningOrderForMachine", { machine_id, reload: false });
            resolve();
          }).catch((err) => { reject(err) });
        }).catch((err) => {
          reject(err);
        })
      }).catch((err) => {
        reject(err);
      })
    });
  },
  startOrderFromRework({ commit, rootState }, { machine_id, data }) {
    return new Promise((resolve, reject) => {
      execute_json('sf346', {
        feature: 'rework_order',
        machine_id,
        old_machine_id: data.order.selected.machine_id,
        old_card_no: data.order.selected.card_no,
        quantity: data.quantity.value,
      })
        .then(({ data }) => {
          commit('events/addLocalEvent', {
            local: true,
            event_type: "StartedWorkOrderLocally",
            machine_id
          }, { root: true })
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  startOrderFromPart({ commit, rootState, dispatch }, { data, machine_id }) {
    return new Promise((resolve, reject) => {
      dispatch("CheckSFsBeforeStart", { machine_id }).then(() => {
        execute_json('start_work_order', {
          machine_id,
          //card_no:"",
          insatser: data.cycle_amount.value,
          work_order_no: data.order_nr.value || '0',
          part_no: data.article.selected.part_no,
          work_order_qty: data.order_amount.value,
          //plan_cycle_time:'-',
          planned_setup_time: data.article.selected.planned_unit_time,
          //start_order_by_part:"True",
          start_by_part: true,
          op_number: data.article.selected.op_no,
          part_description: data.article.selected.part_description,
          stop_reason: data.setup_code.selected?.stop_code || null
        })
          .then(({ data }) => {
            commit('events/addLocalEvent', {
              local: true,
              event_type: "StartedWorkOrderLocally",
              machine_id
            }, { root: true })
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      }).catch((err) => {
        reject(err)
      })
    })
  },
  startOrderFromCard({ commit, rootState, dispatch }, { data, machine_id }) {
    return new Promise((resolve, reject) => {
      dispatch("CheckSFsBeforeStart", { machine_id }).then(() => {
        execute_json('start_work_order', {
          machine_id,
          card_no: data.card_no.value,
          insatser: data.cycle_amount.value || null,
          stop_reason: data.setup_code.selected?.stop_code || null
        })
          .then(({ data }) => {
            commit('events/addLocalEvent', {
              local: true,
              event_type: "StartedWorkOrderLocally",
              machine_id
            }, { root: true })
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      }).catch((err) => {
        reject(err)
      })
    })
  },
  CheckSFsBeforeStart({ commit, rootGetters, dispatch }, data) {
    return new Promise((resolve, reject) => {
      let machine_id = data.machine_id;
      if (!machine_id) {
        reject({});
      }
      console.log("checking for SFs");
      let SFChecks = [dispatch("CheckSF387", { machine_id })];
      Promise.all(SFChecks).then(() => {
        resolve();
      }).catch(() => {
        reject({});
      })
    })
  },
  CheckSFsBeforeStop({ commit, rootGetters,rootState, dispatch }, data) {
    return new Promise((resolve, reject) => {
      let machine_id = data.machine_id;
      if (!machine_id) {
        reject({});
      }
      /* sf195 clear */
      let sf195StorageString = rootState.auth?.company_id+'-'+machine_id+'-sf195value';
      removeStorage(sf195StorageString);
      console.log("checking for SFs");
      let SFChecks = [
        dispatch("CheckSF387", { machine_id }),
        dispatch("CheckSF393", data),
        dispatch("CheckSF388", data),
      ];
      Promise.all(SFChecks).then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      })
    })
  },
  CheckSF387({ rootGetters, dispatch }, { machine_id }) {
    return new Promise((resolve, reject) => {
      if (rootGetters['machines/signedInByMachine'](machine_id).length === 0 && rootGetters['machines/machineHasSf'](machine_id, '387')) {
        //open popup
        dispatch("notifications/addNotification", {
          popup: true,
          state: "fail",
          type: "message",
          tmessage: "7086",
          header: "SF387",
          actions: [
            { path: "/interface/" + machine_id + "/signed-in", label: "sign in", tlabel: '2515' }
          ]

        }, { root: true });
        reject();
      }
      resolve();
    })
  },
  CheckSF393({ rootGetters, commit }, { machine_id, order }) {
    return new Promise((resolve, reject) => {
      if (order !== undefined && (rootGetters['machines/machineHasSf'](machine_id, '393'))) {
        //open popup
        if (order.card_good_qty != order.planned_op_qty) {
          commit("popups/addPopup", {
            type: "decision-halt",
            data: {
              tmessage: "2352",
              data: order,
              theader: "3972",
              infoComponent: "order-stop",
              decisionComponent: "yes-no",
              resolve,
              reject,
              rejectMessage: "Stopped by SF393",
              timeout: 120,
              timeoutAction: reject
            },
            canClose: false,
          }, { root: true });
        }else{
          resolve();
        }
      } else {
        resolve();
      }
    })
  },
  CheckSF388({ rootGetters, commit }, { machine_id, order }) {
    return new Promise((resolve, reject) => {
      if (order !== undefined && (rootGetters['machines/machineHasSf'](machine_id, '388'))) {
        //open popup
        if (order.produced_op_qty < 0.5 * order.planned_op_qty) {
          commit("popups/addPopup", {
            type: "decision-halt",
            data: {
              tmessage: "2352",
              data: order,
              theader: "3972",
              infoComponent: "order-stop",
              decisionComponent: "yes-no",
              resolve,
              reject,
              rejectMessage: "Stopped by SF388",
              timeout: 120,
              timeoutAction: reject
            },
            canClose: false,
          }, { root: true });
        }else{
          resolve();
        }
      } else {
        resolve();
      }
    })
  },
  stopOrder({ commit, rootState, dispatch }, { order, machine_id, cancel_order }) {
    return new Promise((resolve, reject) => {

      dispatch("CheckSFsBeforeStop", { machine_id, order }).then(() => {
        execute_json('stop_work_order', {
          machine_id,
          card_no: order.card_no,
          work_order_no: order.work_order_no,
          part_no: order.part_no,
          work_order_qty: order.planned_op_qty,
          plan_cycle_time: order.plan_cycle_time,
          op_number: order.op_number,
          cancel_order,
        })
          .then(({ data }) => {
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
        .catch((err) => {
          reject(err)
        })

    })
  },
  priorityUpdate({ commit, rootState, dispatch }, { machine_id, orders }) {
    return new Promise((resolve, reject) => {
      let requests = []
      orders.forEach((o) => {
        requests.push(
          execute_json('user_planning', {
            operation_type: 'update',
            machine_id: o.machine_id,
            card_no: o.card_no,
            priority: o._sort,
          }),
        )
      })
      Promise.all(requests)
        .then(({ data }) => {
          resolve();
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  loadAllRunningOrders({ commit }) {
    return new Promise((resolve, reject) => {
      execute_json("get_running_orders_machine", {
        return_all: true,
        machine_id: "-",
        all_orders: true
      }, { multitable: true }).then(({ data }) => {
        commit("setRunningOrdersForAllMachines", data);
        resolve();
      }).catch(() => {
        reject();
      })
    })
  },
  loadAllPlannedOrders({ commit }) {
    return new Promise((resolve, reject) => {
      execute_json("get_planned_orders_machine", {
        exclude_running_orders: true,
        return_all: true,
        machine_id: "-"
      }, { multitable: true }).then(({ data }) => {
        commit("setPlannedOrdersForAllMachines", data);
        resolve();
      }).catch(() => {
        reject();
      })
    })
  },
  loadEanCodeForWorkOrderNo({ commit }, { work_order_no, machine_id }) {
    return new Promise((resolve, reject) => {
      if (!work_order_no) {
        resolve();
        return true;
      }
      execute_json("sf14", {
        machine_id,
        feature: "get_ean_code",
        work_order_no
      }).then(({ data }) => {
        commit("setEanCodeForWorkOrderNo", { work_order_no, machine_id, data });
        resolve();
      }).catch((err) => {
        reject(err)
      })
    })
  }
}
// mutations
const mutations = {
  setRunningOrderForMachine(state, { machine_id, orders }) {
    if (orders.length === 1 && orders[0].call_code !== undefined) {
      orders = []
    }
    state.runningOrders[machine_id] = orders
  },
  setRunningOrdersForAllMachines(state, data) {
    console.log(data);
    let dataKeys = Object.keys(data);
    dataKeys.forEach(k => {
      if (k.includes("Table") && data[k]?.length > 0) {
        let machine_id = data[k][0]?.machine_id;
        if (machine_id) {
          state.runningOrders[machine_id] = data[k];
        }
      }
    })
  },
  setPlannedOrderForMachine(state, { machine_id, orders }) {
    if (orders.length === 1 && orders[0].call_code !== undefined) {
      orders = []
    }
    state.plannedOrders[machine_id] = orders
  },
  setPlannedOrdersForAllMachines(state, data) {
    let dataKeys = Object.keys(data);
    dataKeys.forEach(k => {
      if (k.includes("Table") && data[k]?.length > 0) {
        let machine_id = data[k][0]?.machine_id;
        if (machine_id) {
          state.plannedOrders[machine_id] = data[k];
        }
      }
    })
  },
  setFinishedOrdersForMachine(state, { machine_id, orders }) {
    if (orders.length === 1 && orders[0].call_code !== undefined) {
      orders = []
    }
    state.finishedOrders[machine_id] = orders
  },
  setHistoricalOrdersForMachine(state, { machine_id, orders }) {
    state.historicalOrders[machine_id] = orders;
  },
  setWorkOrderTransactions(state, { machine_id, card_no, transactions }) {
    if (state.transactions[card_no] === undefined) {
      state.transactions[card_no] = {}
    }
    if (transactions.length === 1 && transactions[0].call_code !== undefined) {
      transactions = []
    }
    state.transactions[card_no][machine_id] = transactions
  },
  setReworkOrderForMachine(state, { machine_id, orders }) {
    if (orders.length === 1 && orders[0].call_code !== undefined) {
      orders = []
    }
    state.reworkOrders[machine_id] = orders.map((o) => {
      return {
        ...o,
        searchField:
          'part:' +
          o.part_no +
          '|desc:' +
          o.part_description +
          '|op:' +
          o.op_number +
          '|wo:' +
          o.work_order_no +
          '|card:' +
          o.card_no,
      }
    })
  },
  setEanCodeForWorkOrderNo(state, { machine_id, work_order_no, data }) {
    state.eanCodes[machine_id + ':' + work_order_no] = data;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
