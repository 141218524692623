<template>
  <div
    class="
      col-12 col-xl-3 col-lg-4 col-md-6
      left-aligned
      widget
      min-height
      align-left
    "
  >
    <order-summary-shared
      :machine_id="$route.params.machine"
      :period="config.period"
    ></order-summary-shared>
  </div>
</template>

<script>
import OrderSummaryShared from "@/components/shared/OrderSummary.vue";
import { Configuration } from "@/helpers/settings";
export default {
  props:['template'],
  data() {
    return {
      config: new Configuration(
        "order-summary",
        {
          period: "shift",
        },
        this.template
      ),
    };
  },
  components: {
    OrderSummaryShared,
  },
};
</script>

<style>
</style>