<template>
  <div class="popup" v-if="settings">
    <div v-if="settings && loaded">
      <div class="form-group align-left">
        <b-check v-model="settings.email_on_off" tlabel="262"></b-check>

        <label for=""> {{ $t("102") }} </label>
        <input
          type="datetime-local"
          name=""
          id=""
          v-model="settings.start_time_elarm"
          class="form-control"
        />
        <label for=""> {{ $t("131") }} 1</label>
        <input
          type="email"
          name=""
          id=""
          class="form-control"
          v-model="settings.email_1"
        />
        <label for=""> {{ $t("131") }} 2</label>
        <input
          type="email"
          name=""
          id=""
          class="form-control"
          v-model="settings.email_2"
        />
        <b-check v-model="settings.sms_on_off" tlabel="263"></b-check>
         <label for=""> {{ $t("103") }} </label>
        <input
          type="datetime-local"
          name=""
          id=""
          v-model="settings.start_time_smslarm"
          class="form-control"
        />

        <label for="">{{ $t("330") }}</label>
        <input
          type="phone"
          name=""
          id=""
          class="form-control"
          v-model="settings.sms_nr"
        />
      </div>
    </div>

    <button class="btn">
      <i class="fas fa-times" @click="deActivate"></i>
    </button>
    <button class="btn">
      <i class="fas fa-check" @click="toggleMachineNotifications"></i>
    </button>
  </div>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapGetters, mapState } from "vuex";
import { flattenSingleRow } from "@/requests/requestParcer";
import materialLines from "@/components/MaterialLines";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";
import store from "@/store";

export default {
  props: ["data"],
  emits: ["close"],
  data() {
    return {
      settings: null,
      loaded: false,
    };
  },
  mounted() {
    this.settings = _.cloneDeep(this.notificationSettings);
    store.dispatch("messagegroups/loadMessageGroupList").then(() => {
      this.loaded = true;
    });
  },
  methods: {
    handleEnter() {},
    deActivate() {
      this.$emit("close");
    },
    toggleMachineNotifications() {
      execute_json("machine_notification", {
        machine_id: this.machine_id,
        feature: "set",
        alarm_on_stop: !this.notificationSettings.alarm_on_stop,
        email1_address: this.settings.email_1,
        //email_1: this.settings.email_1,
        email2_address: this.settings.email_2,
        //email_2: this.settings.email_2,
        mobile_number: this.settings.sms_nr,
        mobile_start_time: this.settings.start_time_smslarm?.replace("T"," ").replace("/","-").slice(0,19),
        email_start_time: this.settings.start_time_elarm?.replace("T"," ").replace("/","-").slice(0,19),
        email_on_off:this.settings.email_on_off,
        sms_on_off:this.settings.sms_on_off,
        debug: true,
      }).then(() => {
        this.reloadNotifications();
      });
    },
    reloadNotifications() {
      this.data.pulse.shock("notifications");
      this.deActivate();
    },
  },
  computed: {
    ...mapState({
      groups: (state) => state.messagegroups.list,
    }),
    ...mapGetters({
      notificationSettingsBymachine: "machines/notificationSettingsBymachine",
    }),
    machine_id() {
      return this.data.machine_id;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    notificationSettings() {
      return this.notificationSettingsBymachine(this.machine_id);
    },
  },
  watch: {
    notificationSettings() {
      this.settings = _.cloneDeep(this.notificationSettings);
    },
  },
  components: {
    materialLines,
  },
};
</script>