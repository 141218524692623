<template>
  <div class="dropdown">
    <router-link :to="notifications" class="btn btn-danger btn-logcount" v-if="logCount > 0">{{logCount}}</router-link>
    <button
      class="navbar-toggler"
      type="button"
      id="userSettingsMenu"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="fal fa-ellipsis-v" style="color: white"></i>
    </button>
    <ul
      class="dropdown-menu dropdown-menu-end"
      id="top-right-dropdown"
      aria-labelledby="userSettingsMenu"
    >
      <li>
        <router-link class="dropdown-item" to="/settings"
          ><i class="fas fa-user"></i> {{$t('45')}}</router-link
        >
      </li>
       <li v-if="canUser('can-access-admin-panel')">
        <router-link class="dropdown-item" to="/admin"
          ><i class="fas fa-users-cog"></i> {{$t('532')}}</router-link
        >
      </li>
      <li>
        <router-link class="dropdown-item" :to="notifications"
          ><i class="fas fa-bells"></i> {{$t('6730')}}</router-link
        >
      </li>
      <li>
        <a class="dropdown-item" href="/home" @click="logout"><i class="fas fa-sign-out"></i>{{$t('425')}}</a>
      </li>
      <li>
        <a href="#" class="dropdown-item disabled"><i class="fa fa-user"></i> {{ user }}&nbsp;&nbsp;<i class="fas fa-code-branch"></i> v{{ version }}</a>
      </li>
      <li>
        <a href="#" class="dropdown-item disabled"><i class="fa fa-code-branch"></i>{{ ws }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import store from "@/store";
import { mapState, mapGetters } from "vuex";
export default {
  methods: {
    logout() {
      store.dispatch("auth/logout");
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.username,
      version: (state) => state.version,
      ws: (state) => state.ws,
      path: (state) => state.auth.path,
      machine: (state) => state.auth.machine,
    }),
    ...mapGetters({
      logCount: "notifications/logCount",
      canUser: "permissions/canUser",
    }),
    notifications(){
      if (this.path && this.path.includes("/interface")){
        return "/interface/"+this.machine+"/notifications";
      }
      return "/machines/notifications";
    }
  },
};
</script>

<style>
.dropdown-item .fas:first-child, .fa:first-child{
  width:25px;
}
</style>