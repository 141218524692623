import { flattenMultiRow, flattenSingleRow } from "@/requests/requestParcer";
import { daybreakDate, getMonday, getNow, getNowStart } from "@/helpers/dates";
import axios from "@/requests/axios";
import store from "@/store";
import _ from "lodash";
import { execute_json } from "../requests";

// functions

const today = {
    handler: "loadStopReasonsCountToday",
    tlabel: "450",
    labelKey: "stop_description_2",
    valueKey: "stop_qty",
    colorKey: "color",
    stackKey: "stop_description_2",
    stackColorKey: "color"
};

function loadStopReasonsCountToday() {
    return new Promise((resolve, reject) => {
        this.rawData = {};
        this.stopReasonCount(1).then(() => {
            this.data = this.rawData;
            resolve();
        });
    })

};

const d7 = {
    handler: "loadStopReasonsCount7d",
    tlabel: "450",
    labelKey: "stop_description_2",
    valueKey: "stop_qty",
    colorKey: "color",
    stackKey: "stop_description_2",
    stackColorKey: "color"
};

function loadStopReasonsCount7d() {
    return new Promise((resolve, reject) => {
        this.rawData = {};
        this.stopReasonCount(7).then(() => {
            this.data = this.rawData;
            resolve();
        });
    })

};

const d30 = {
    handler: "loadStopReasonsCount30d",
    tlabel: "450",
    labelKey: "stop_description_2",
    valueKey: "stop_qty",
    colorKey: "color",
    stackKey: "stop_description_2",
    stackColorKey: "color"
};

function loadStopReasonsCount30d() {
    return new Promise((resolve, reject) => {
        this.rawData = {};
        this.stopReasonCount(30).then(() => {
            this.data = this.rawData;
            resolve();
        });
    })

};

function stopReasonCount(no_of_days) {
    return new Promise((resolve, reject) => {
        execute_json("get_no_of_stops", {
            machine_id: this.machine.machine_id,
            no_of_days
        }).then(({ data }) => {
            console.log(data);
            this.rawData = data;
            resolve();
        })
            .catch(() => {
                reject();
            })
    });
}

const graphStopreasonsCountDataSetup = {
    "today": today,
    "7d": d7,
    "30d": d30,
};

const graphStopreasonsCountDataFunctions = {
    loadStopReasonsCountToday,
    loadStopReasonsCount7d,
    loadStopReasonsCount30d,
    stopReasonCount,
};


export { graphStopreasonsCountDataFunctions, graphStopreasonsCountDataSetup };