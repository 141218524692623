<template>
  <div
    class="buttom-component-wrap"
    :class="bottomShouldShow ? 'visible' : 'hidden'"
  >
    <div
      class="bottom-buttons-component bg-dark"
      :class="bottomShouldShow ? 'visible' : 'hidden'"
    >
      <component :is="bottomnav.type"></component>
      <!--
        <machine-singular v-if="bottomnav.type == 'machine-singular'"></machine-singular>
        <stopcode-singular v-if="bottomnav.type == 'stopcode-singular'"></stopcode-singular>
        <uncoded-multiple v-if="bottomnav.type == 'uncoded-multiple'"></uncoded-multiple>
        -->
    </div>
  </div>
</template>

<script>
import * as BottomConfigs from "./bottomconfigs"
import { mapState } from "vuex";

export default {
  props: {},
  setup() {},
  computed: {
    ...mapState({
      email: (state) => state.auth.company_email,
      bottomnav: (state) => state.bottomnav,
      bottomShouldShow: (state) => state.bottomnav.shouldShow,
    }),
  },
  components: {
    ...BottomConfigs
  },
};
</script>
