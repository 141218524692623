<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <line-information-base></line-information-base>
  </div>
</template>

<script>
import LineInformationBase from "./base/LineInformationBase.vue";
export default {
  components: {
    LineInformationBase,
  },
};
</script>

<style>
</style>