import { getStorage, setStorage } from "../../helpers/storage";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";

const state = {
    index: {

    },
    lastCreated:null
};

const getters = {
};

const actions = {
    loadActivitiesForSelectionOwner({ commit }, {selectionOwner}) {
        return new Promise((resolve, reject) => {
            axios
                .post("balthzar_get_internal_activity_action_list_16_1_87", {
                    show_finished: false,
                    activity_owner: "",
                    selection_owner: selectionOwner,
                    show_only_open: false,
                    display_mode: 0,
                    show_future: false,
                })
                .then(({ data }) => {
                    commit("setActivitiesForSelectionOwner", { data: flattenMultiRow(data), selectionOwner });
                    resolve();
                })
                .catch((err) => {
                    console.log(err);
                    reject();
                });
        });
    }
};

const mutations = {
    setActivitiesForSelectionOwner(state, { selectionOwner, data }) {
        state.index[selectionOwner] = data;
    },
    setLast(state,created){
        state.lastCreated = created;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
