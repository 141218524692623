import defaultLayout from '@/layouts/DefaultLayout'

function loadView(view) {
  return () => import (/* webpackChunkName: "[request]" */ `@/views/${view}.vue`)
}

export const cavityRoutes = [
  {
    path:'/cavities/:machine',
    name:"cavities-machine",
    meta:{
      requiresAuth: true,
      layout: defaultLayout,
      title: "Cavities" 
    },
    component: loadView("overviews/cavities/index")
  },
  {
    path:'/cavities/:machine/open',
    name:"cavities-machine-open",
    meta:{
      requiresAuth: true,
      layout: defaultLayout,
      title: "Open Cavity" 
    },
    component: loadView("overviews/cavities/open")
  },
  {
    path:'/cavities/:machine/close',
    name:"cavities-machine-close",
    meta:{
      requiresAuth: true,
      layout: defaultLayout,
      title: "close Cavity" 
    },
    component: loadView("overviews/cavities/close")
  },
  {
    path:'/cavities/:machine/history',
    name:"cavities-machine-history",
    meta:{
      requiresAuth: true,
      layout: defaultLayout,
      title: "history Cavity" 
    },
    component: loadView("overviews/cavities/history")
  },
];
