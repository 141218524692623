<template>
  <div class="palletflags-multiple">
    <div class="buttons-wrap">
      <a
        href="#"
        class="router-button"
        @click.prevent="reprintPalletFlags"
        :disabled="loading"
      >
        <loading v-if="loading" :step="step" />
        <span v-else>{{
          type == "label-history" ? $t("7035") : $t("5317")
        }}</span>
      </a>
      <a
        href="#"
        class="router-button"
        @click.prevent="cancelPalletFlags"
        :disabled="loading"
      >
        <loading v-if="loading" :step="step" />
        <span v-else>{{ $t("6634") }} {{type == 'pallet-flag-history' ? $t("895") : $t('1268')}}</span>
        <!-- //cancel print -->
      </a>
      <a
        href="#"
        class="router-button"
        @click.prevent="revertPalletFlags"
        :disabled="loading"
      >
        <loading v-if="loading" :step="step" />
        <span v-else>{{ $t("4353") }}</span>
        <!-- //revert cancellation -->
      </a>
    </div>
  </div>
</template>

<script>
//import { useRouter } from 'vue-router';
import { mapState } from "vuex";
import { Notification } from "@/helpers/notifications";
import { allProgress } from "@/helpers/promises";
import store from "@/store";
import axios from "@/requests/axios";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";

export default {
  props: {},
  setup() {},
  data() {
    return {
      note: "",
      loading: false,
      step: 0,
    };
  },
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      historic_flags: (state) => state.bottomnav.data,
    }),
    type() {
      return this.$route.path.includes("pallet-flag")
        ? "pallet-flag-history"
        : "label-history";
    },
   
  },
  methods: {
    reprintPalletFlags() {
      if (!this.loading) {
        let n = Notification.request({
          message:
            this.type == "label-history"
              ? "Reprinting labels"
              : "Reprinting pallet flags",
        });
        this.loading = true;
        let promises = [];
        for (let i = 0; i < this.historic_flags.length; i++) {
          let historicFlag = this.historic_flags[i];
          promises.push(
            execute_json("update_print_job_status", {
              record_no: historicFlag.record_no,
              print_text: historicFlag.print_text,
              date: historicFlag.date,
              print_status: "New",
            })
          );
        }
        Promise.all(promises).then(() => {
          n.ok({
            message: "print jobs queued!",
          });
          this.$router.push(
            this.$route.path.replace("/pallet-flag-history", "")
          );
          this.loading = false;
        });
      }
    },
    cancelPalletFlags() {
      if (!this.loading) {
        let n = Notification.request({
          message: "Canceling pallet flag"
            
        });
        this.loading = true;
        let promises = [];
        let filteredHistoricFlags = this.historic_flags.filter(
          (flag) => flag.status !== "Cancelled"
        );
        for (let i = 0; i < filteredHistoricFlags.length; i++) {
          let historicFlag = filteredHistoricFlags[i];
          promises.push(
            execute_json("balthzar_cancel_pallet_flag_erp_reportings_status", {
              record_no: historicFlag.record_no,
            })
          );
        }
        Promise.all(promises)
          .then(() => {
            n.ok({
                message:"Cancelled"
               
            });

            store.commit("config/activateBTable", null);
            store.commit("bottomnav/disable");
            this.loading = false;
          })
          .catch(() => {
            n.close();
            this.loading = false;
          })
          .finally(() => {
            if (this.$route.path.includes("pallet-flag")) {
              store.dispatch("printers/loadPalletHistoryForMachine", {
                machine_id: this.$route.params.machine,
              });
            }
            if (this.$route.path.includes("label")) {
              store.dispatch("printers/loadLabelHistoryForMachine", {
                machine_id: this.$route.params.machine,
              });
            }
          });
      }
    },
    revertPalletFlags() {
      if (!this.loading) {
        let n = Notification.request({
          message: "Reverting cancellation",
        });
        this.loading = true;
        let promises = [];
        let filteredHistoricFlags = this.historic_flags.filter(
          (flag) => flag.status == "Cancelled"
        );
        for (let i = 0; i < filteredHistoricFlags.length; i++) {
          let historicFlag = filteredHistoricFlags[i];
          promises.push(
            execute_json("balthzar_cancel_pallet_flag_erp_reportings_status", {
              record_no: historicFlag.record_no,
            })
          );
        }
        Promise.all(promises)
          .then(() => {
            n.ok({
              message: "Reverted cancellation",
            });

            store.commit("config/activateBTable", null);
            store.commit("bottomnav/disable");
            this.loading = false;
          })
          .catch(() => {
            n.close();
            this.loading = false;
          }) 
          .finally(() => {
            if (this.$route.path.includes("pallet-flag")) {
              store.dispatch("printers/loadPalletHistoryForMachine", {
                machine_id: this.$route.params.machine,
              });
            }
            if (this.$route.path.includes("label")) {
              store.dispatch("printers/loadLabelHistoryForMachine", {
                machine_id: this.$route.params.machine,
              });
            }
          });
      }
    },
  },
  components: {},
};
</script>

<style lang="scss">
</style>
