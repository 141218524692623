<template>
  <div>
    <loading v-if="!loaded" />
    <div v-else>
      <div class="history-summation">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td>{{ $t("2436") }}</td>
              <td>
                <b>{{ data.order?.selected?.work_order_no }}</b>
              </td>
            </tr>
            <tr>
              <td>{{ $t("4566") }}</td>
              <td>
                <b>{{ data.order?.selected?.planned_op_qty }}</b>
              </td>
            </tr>
            <tr>
              <td>{{ $t("3131") }}</td>
              <td>
                <b>{{ totalFromHistory }}</b><br/>
                <div class="substraction">
                  <i class="fas fa-minus"></i>
                </div>
              </td>
            </tr>
            <tr class="bigger">
              <td>{{ $t("83") }}</td>
              <td >
                <b>{{
                  data.order?.selected?.planned_op_qty - totalFromHistory
                }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <c-table
        :data="palletHistory"
        primary-key="record_no"
        config-string="pallet_flag_history_sf362"
        @selectionChanged="onSelectionChanged"
        :selectable="false"
        :single-select="false"
        :teleport="true"
      >
      </c-table>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import SortSelect from "@/components/sort-select";
import store from "@/store";
export default {
  props: {
    data: {
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    store.commit("bottomnav/disable");
    let promises = [];
    promises.push(
      store.dispatch("printers/loadPalletHistoryForCardNo", {
        card_no: this.card_no,
      })
    );
    Promise.all(promises).then(() => {
      this.loaded = true;
      console.log("fully loaded..");
    });
  },
  methods: {
    onSelectionChanged() {
      //console.log("something");
    },
  },
  computed: {
    ...mapState({
      bottomNav: (state) => state.bottomnav,
      palletHistories: (state) => state.printers.palletHistoryByCard,
    }),
    ...mapGetters({}),
    machine_id() {
      return this.data?.order?.selected?.machine_id;
    },
    card_no() {
      return this.data?.order?.selected?.card_no;
    },
    palletHistory() {
      return this.palletHistories[this.card_no] || [];
    },
    totalFromHistory() {
      return this.palletHistory
        .filter((p) => p.status !== "Cancelled")
        .reduce((a, b) => {
          return a + +b.good_qty;
        }, 0);
    },
  },
  watch: {},
  components: {
    SortSelect,
  },
};
</script>
<style lang="scss">
.history-summation{
  width:320px;
  table{
    tr{
      td:first-child{
        white-space:pre-wrap;
        width:200px;
      }
      td:nth-child(2){
        width:120px;
        text-align:right;
      }
      td{
        padding:4px;
      }
    }
    .bigger{
      font-size:20px;
    }
  }
  .substraction{
    position:relative;
    border-top:1px solid black;
    width:100px;
    float:right;
    i{
      position:absolute;
      right:-14px;
      top:-6px;
      font-size:12px;
      line-height:12px;
    }
  }
}
</style>