<template>
  <div v-if="machine" class="max-align">
    <h1>
      {{ $t("5280") }} - {{lineNo}}
      <card-menu :items="items"></card-menu>
    </h1>
    <div class="flex" v-if="lineNo !== null">
      <c-table
          :data="lineMachines"
          primary-key="machine_id"
          config-string="line_machines_widget"
          default-sort-key="line_position"
          default-sort-dir="asc"
          :colors="colors"
          @selectionChanged="onSelectionChanged"
          :teleport="true"
        >
        </c-table>
    </div>
    <div v-else>{{$t('5354')}}</div>
      
  </div>
</template>

<script>
/* description: line information */
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import { shortDateTime } from "@/helpers/dates";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 175,
      selectedRows: [],
      highlights: [],
    };
  },
  methods:{
      onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length === 0) {
        store.commit("bottomnav/disable");
      } else {
        store.commit("bottomnav/enable");
      }
      store.commit("bottomnav/setType", "machine-singular-interface");
      store.commit("bottomnav/setData", this.selectedRows);
      if (this.selectedRows.length > 0 && this.selectedRows[0]) {
        store.dispatch("stopcodes/loadStopcodesForMachine", {
          machine_id: this.selectedRows[0].machine_id,
          reloadCachedValues: false,
        });
        store.dispatch("machines/loadTouchConfigForMachine", {
          machine_id: this.selectedRows[0].machine_id,
          reloadCachedValues: false,
        });
        store.dispatch("machines/loadSpecialFunctionsForMachine", {
          machine_id: this.selectedRows[0].machine_id,
          reloadCachedValues: false,
        });
      }
      //this.$forceUpdate();
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      columnConfigByTemplate: "config/columnConfigByTemplate",
      canUser: "permissions/canUser",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    items() {
      let items = [];
      if (this.canEditColumns) {
        items.push({'header':this.$t('89')});
        items.push({
            tlabel:"506",
            to:"/admin/config/tables/line_machines_widget"
        });
      }
      return items;
    },
    lineNo() {
      return this.machine.line_no !== "-" ? this.machine.line_no : null;
    },
    lineMachines() {
      return this.machines.filter((m) => m.line_no === this.lineNo);
    },
    columnConfig() {
      return this.columnConfigByTemplate("line_machines_widget");
    },
    baseColumnConfig() {
      return this.columnConfigByTemplate("line_machines_widget_baseconfig");
    },
    canEditColumns() {
      return (
        this.canUser("change-own-webcolumns") ||
        this.canUser("change-default-webcolumns")
      );
    },
  },
  
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
  },
};
</script>

<style>
</style>