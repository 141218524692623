import DataLayout from '@/layouts/DataLayout.vue'

function loadView(view) {
    return () => import(`@/views/${view}.vue`)
}

export const dataRoutes = [
    {
        path: "/data/history-day/:overview",
        name: "data visualization history day",
        meta: {
            requiresAuth: true,
            shouldRemember: true,
            layout: DataLayout
        },
        component: loadView("data/visualize/history-day")
    },
    {
        path: "/data/card-history/:overview",
        name: "data visualization card history",
        meta: {
            requiresAuth: true,
            shouldRemember: true,
            layout: DataLayout
        },
        component: loadView("data/visualize/card-history")
    }
];
