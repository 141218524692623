<template>
  <div class="popup">
    <h2>{{ $t("3462") }} {{ data.material_no }}:{{ data.material_desc }}</h2>
    <p>{{ $t("574") }}</p>
   
    <numerical-input
      type="number"
      name=""
      id=""
      placeholder="quantity"
      v-model="quantity"
    />
    <button class="btn" @click="hide">
      <i class="fas fa-times"></i></button
    >&nbsp;&nbsp;
    <button class="btn btn-success" @click="close" :disabled="!isConfirmed">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapGetters, mapState } from "vuex";
import { execute_json } from "@/helpers/requests";
import { flattenSingleRow } from "@/requests/requestParcer";
import store from "@/store";

export default {
  props: ["data"],
  emits: ["close"],
  data() {
    return {
      loading: true,
      quantity: null,
    };
  },
  mounted() {
  },
  beforeUnmount() {},
  methods: {
    close() {
      //dispatch materials.reportScrap -> then ( close)
      store
        .dispatch("materials/materialReportingIn", {
          material_card_no: this.data.material_card_no,
          machine_id: this.machine_id,
          quantity: this.quantity,
        })
        .then(() => {
          this.justClose();
        });
    },
    justClose() {
      console.log("closing...");
      this.$emit("close");
    },
    hide() {
      this.justClose();
    },
    handleEnter() {},
    fetchMaterials() {},
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    columnConfig() {
      return {
        code: {
          class: "h50",
        },
        description: {
          class: "h50",
        },
      };
    },
    selectedScrapReason() {
      return this.scrapReasons.find(
        (r) => r.code === this.data[this.tab.dataKey].code
      );
    },
    machine_id() {
      return (
        this.data.machine_id ||
        this.data.material?.machine_id ||
        this.$route.params.machine
      );
    },
    isConfirmed() {
      return this.quantity;
    },
    canClose() {
      return true;
    },
  },
  watch: {},
};
</script>