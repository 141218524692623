import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import { getStorage, setStorage, checkStorage } from "../../helpers/storage";

const state = () => ({
    index: {
        "change-permissions": 9,
        "view-permissions": 9,
        "can-access-admin-panel":9,
        //widget related
        "view-widget-settings":1,
        "add-widget-settings":9,
        "save-widget-settings":9,
        "delete-widget-settings":9,
        "assign-widget-settings":9,
        //dashboard related
        "view-dashboard-templates": 9,
        "add-dashboard-templates": 9,
        "change-dashboard-templates": 9,
        "delete-dashboard-templates": 9,
        "assign-dashboard-templates": 9,
        
        //liveviews related
        "view-liveviews-templates": 9,
        "add-liveviews-templates": 9,
        "change-liveviews-templates": 9,
        "delete-liveviews-templates": 9,
        "assign-liveviews-templates": 9,
        // navigation related
        "can-access-dashboard": 1,
        "can-change-cavities": 1,
        //webcolumns related
        "change-own-webcolumns": 1,
        "change-default-webcolumns": 9,
        //manual scrap
        "can-manual-scrap": 9,
        //visual analysis
        "can-save-default-visualizations":9,
        "can-delete-default-visualizations":9,
        "can-manage-user-visualizations":1,
        //production goals
        "view-production-goals":9,
        "edit-production-goals":9,
        "can-save-default-visualizations": 9,
        "can-delete-default-visualizations": 9,
        "can-manage-user-visualizations": 1,
        //material assigning
        "can-assign-materials":9
    },
    loaded: {
        "change-permissions": true,
        "view-permissions": false,
        "can-access-admin-panel":false,
        //widget
        "add-widget-settings":false,
        "save-widget-settings":false,
        "view-widget-settings":false,
        "delete-widget-settings":false,
        "assign-widget-settings":false,
        //dashboard related
        "view-dashboard-templates": false,
        "add-dashboard-templates": false,
        "change-dashboard-templates": false,
        "delete-dashboard-templates": false,
        "assign-dashboard-templates": false,
        //liveviews related
        "view-liveviews-templates": false,
        "add-liveviews-templates": false,
        "change-liveviews-templates": false,
        "delete-liveviews-templates": false,
        "assign-liveviews-templates": false,
        // navigation related
        "can-access-dashboard": false,
        "can-change-cavities": false,
        //webcolumns related
        "change-own-webcolumns": false,
        "change-default-webcolumns": false,
        //manual scrap
        "can-manual-scrap": false,
        //visual analysis
        "can-save-default-visualizations":false,
        "can-delete-default-visualizations":false,
        "can-manage-user-visualizations":false,
        //production goals
        "view-production-goals":false,
        "edit-production-goals":false,
        "can-save-default-visualizations": false,
        "can-delete-default-visualizations": false,
        "can-manage-user-visualizations": false,
        //material assigning
        "can-assign-materials":false
    }
});

// getters
const getters = {
    canUser: (state, getters, rootState) => (action) => {
        if (!state.loaded[action]) {
            return false;
        }
        return +state.index[action] <= +rootState.auth.usertype;
    }
}

// actions
const actions = {
    loadPermission({ state, rootState, commit }, action) {
        return new Promise((resolve, reject) => {
            let company_id = rootState.auth.company_id;
            execute_json("get_user_settings", {
                username: "Default",
                system_function: "web-permissions",
                function_variable: action,
            })
                .then(({ data }) => {
                    if (data && data[0] && data[0].string_value) {
                        let value = JSON.parse(data[0].string_value);
                        commit("setPermission", { action, value });
                        setStorage(company_id + "_web_permission_" + action, value);
                    } else {
                        commit("setPermission", { action, value: state.index[action] });
                        setStorage(company_id + "_web_permission_" + action, state.index[action]);
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
        })
    },
    loadUnloadedPermissions({ state, commit, rootState, dispatch }) {
        return new Promise((resolve, reject) => {
            let company_id = rootState.auth.company_id;
            execute_json("get_user_settings", {
                username: "Default",
                system_function: "web-permissions",
            })
                .then(({ data }) => {
                    data.forEach(config => {
                        let action = config.function_variable;
                        let value = JSON.parse(config.string_value);
                        commit("setPermission", { action, value });
                        setStorage(company_id + "_web_permission_" + action, value);
                    });
                    let loadKeys = Object.keys(state.loaded);
                    for (let i = 0; i < loadKeys.length; i++) {
                        let action = loadKeys[i];
                        if (state.loaded[action] === false) {
                            commit("setPermission", { action, value: state.index[action] });
                            setStorage(company_id + "_web_permission_" + action, state.index[action]);
                        }
                    }
                    //console.warn(loadKeys);
                    resolve();
                })
                .catch((err) => {
                    console.log(err);

                });
        });
    },
    updatePermission({ state, dispatch }, { action, value }) {
        return new Promise((resolve, reject) => {
            execute_json("save_user_setting", {
                //TODO: username instead of user_name!!!
                user_name: "Default",
                username: "Default",
                system_function: "web-permissions",
                function_variable: action,
                user_setting: value
            })
                .then(() => {
                    resolve();
                }).catch((err) => { console.warn(err); reject() })
        })
    }
}
// mutations
const mutations = {
    setPermission(state, { action, value }) {
        state.index[action] = value;
        state.loaded[action] = true;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}