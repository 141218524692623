import { flattenMultiRow, flattenSingleRow } from '@/requests/requestParcer'
import { daybreakDate, getMonday, getNow } from '@/helpers/dates'
import axios from '@/requests/axios'
import store from '@/store'
import _, { reject } from 'lodash'
import { execute_json } from '../requests'

// functions
const shift = {
  handler: 'loadAvailabilityLocationShift',
  tlabel: '197',
  labelKey: 'trans_time',
  valueKey: 'percentage',
  colorKey: 'colorCode',
}

function getStatisticsEff(
  { choice = 2, category, value, start_date, stop_date },
  greenLimit,
  yellowLimit
) {
  return new Promise((resolve, reject) => {
    execute_json('get_statistics_eff', {
      choice,
      category,
      value,
      start_date,
      stop_date,
    })
      .then(({ data }) => {
        let returnData = data.map((d) => {
          let percentage =
            (+d.tot_run_time * 100) / (+d.tot_run_time + +d.tot_stop_time)

          let colorCode =
            percentage >= greenLimit
              ? 'green'
              : percentage >= yellowLimit
                ? 'yellow'
                : 'red'
          return {
            ...d,
            percentage,
            colorCode,
          }
        })
        resolve(returnData)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

function loadAvailabilityLocationShift() {
  let greenLimit = 90
  let yellowLimit = 50
  let start_date = new Date(
    this.machines[0].current_shift_start_time,
  ).toBalthzarTime()
  //console.warn(greenLimit, yellowLimit, "nono limit");
  return new Promise((resolve, reject) => {
    getStatisticsEff({
      category: this.locationtype,
      value: this.location,
      start_date,
      stop_date: getNow(),
    }, greenLimit, yellowLimit).then((data) => {
      this.data = data;
      resolve(data);
    }).catch(err => reject(err))
  });
}

// functions
const today = {
  handler: 'loadAvailabilityLocationToday',
  tlabel: '447',
  labelKey: 'trans_time',
  valueKey: 'percentage',
  colorKey: 'colorCode',
}

function loadAvailabilityLocationToday() {
  let greenLimit = 90
  let yellowLimit = 50
  let d = new Date()
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  let start_date = new Date(d).toBalthzarTime()
  //console.warn(greenLimit, yellowLimit, "nono limit");
  return new Promise((resolve, reject) => {
    getStatisticsEff({
      category: this.locationtype,
      value: this.location,
      start_date,
      stop_date: getNow(),
    }, greenLimit, yellowLimit).then((data) => {
      this.data = data;
      resolve(data);
    }).catch(err => reject(err))
  });
}
const h24 = {
  handler: 'loadAvailabilityLocation24H',
  tlabel: '448',
  labelKey: 'trans_time',
  valueKey: 'percentage',
  colorKey: 'colorCode',
}

function loadAvailabilityLocation24H() {
  let greenLimit = 90
  let yellowLimit = 50
  return new Promise((resolve, reject) => {
    getStatisticsEff({
      category: this.locationtype,
      value: this.location,
      start_date: getNow(-1),
      stop_date: getNow(),
    }, greenLimit, yellowLimit).then((data) => {
      this.data = _.sortBy(data,
        ['trans_time']);
      resolve(data);
    }).catch(err => reject(err))
  });
}

const week = {
  handler: 'loadAvailabilityLocationWeek',
  tlabel: '914',
  labelKey: 'trans_time',
  valueKey: 'percentage',
  colorKey: 'colorCode',
}

function loadAvailabilityLocationWeek() {
  let greenLimit = 90
  let yellowLimit = 50
  let start_date = getMonday(new Date()).toBalthzarTime()
  let stop_date = new Date().toBalthzarTime()
  return new Promise((resolve, reject) => {
    getStatisticsEff({
      category: this.locationtype,
      value: this.location,
      start_date,
      stop_date,
    }, greenLimit, yellowLimit).then((data) => {
      this.data = _.sortBy(data,
        ['trans_time']);
      resolve(data);
    }).catch(err => reject(err))
  });
}

const lastweek = {
  handler: 'loadAvailabilityLocationLastWeek',
  tlabel: '2136',
  labelKey: 'trans_time',
  valueKey: 'percentage',
  colorKey: 'colorCode',
}

function loadAvailabilityLocationLastWeek() {
  let greenLimit = 90
  let yellowLimit = 50
  let start = getMonday(new Date())
  start.setDate(start.getDate() - 7)
  let start_date = start.toBalthzarTime()
  let stop_date = getMonday(new Date()).toBalthzarTime()
  return new Promise((resolve, reject) => {
    getStatisticsEff({
      category: this.locationtype,
      value: this.location,
      start_date,
      stop_date,
    }, greenLimit, yellowLimit).then((data) => {
      this.data = _.sortBy(data,
        ['trans_time']);
      resolve(data);
    }).catch(err => reject(err))
  });
}

const d30 = {
  handler: 'loadAvailabilityLocation30D',
  tlabel: '450',
  labelKey: 'trans_time',
  valueKey: 'percentage',
  colorKey: 'colorCode',
}

function loadAvailabilityLocation30D() {
  let greenLimit = 90
  let yellowLimit = 50
  return new Promise((resolve, reject) => {
    getStatisticsEff({
      category: this.locationtype,
      value: this.location,
      start_date: getNow(-30),
      stop_date: getNow(),
    }, greenLimit, yellowLimit).then((data) => {
      this.data = _.sortBy(data,
        ['trans_time']);
      resolve(data);
    }).catch(err => reject(err))
  });
}

const graphAvailabilityLocationDataSetup = {
  shift,
  today,
  '24h': h24,
  week,
  lastweek,
  '30d': d30,
}

const graphAvailabilityLocationDataFunctions = {
  loadAvailabilityLocationShift,
  loadAvailabilityLocationToday,
  loadAvailabilityLocation24H,
  loadAvailabilityLocationWeek,
  loadAvailabilityLocationLastWeek,
  loadAvailabilityLocation30D,
}

export {
  graphAvailabilityLocationDataFunctions,
  graphAvailabilityLocationDataSetup,
}
