const state = () => ({
  order:null,
});

// getters
const getters = {

}

// actions
const actions = {
  
}

// mutations
const mutations = {
    set(state,{type,data,subtype}){
        state[type] = data;
        state[type]._subtype = subtype;
    },
    unset(state,type){
        state[type] = null;
    },
    unsetIfSubtype(state,{type,subtype}){
      if (state[type]?._subtype === subtype){
        state[type] = null;
        console.log("unset",type,subtype);
      }
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}