<template>
  
</template>

<script>
export default {
    /* can'be used without a tool_id, which we don't have in start_order_by_part or start_order_by_card_no */
}
</script>

<style>

</style>