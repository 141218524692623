<template>
  <div class="my-machines-select">
    <button
      class="btn float-start"
      type="button"
      id="myMachineSelectDropdown"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="fas fa-star"></i>
    </button>
    <ul
      class="dropdown-menu dropdown-menu-center"
      aria-labelledby="myMachineSelectDropdown"
    >
      <li v-for="m in sorted_machines" :key="m.machine_id">
        <a
          class="dropdown-item"
          href="#"
          @click.prevent="toggle(m.machine_id, $event)"
        >
          <i class="fas fa-star" v-if="myMachines.includes(m.machine_id)"></i>
          <i class="far fa-star" v-else></i>
          {{ m.machine_id }} - {{ m.object_description }}</a
        >
      </li>
    </ul>
    <!-- SF loaded -->
    <div v-if="SFLoaded && machinesWithSF18.length" class="btn float-end">
      <i
        class="fas fa-user signin-indicator"
        :class="{ red: SF18WithoutUser.length > 0 }"
        @click.prevent="massSigning"
      ></i>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import store from "@/store";
export default {
  props: {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      lastLoc: (state) => state.sidebar.lastLocation,
      lastLocType: (state) => state.sidebar.lastLocationType,
      myMachines: (state) => state.config.myMachines,
    }),
    ...mapGetters({
      //machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine_description() {
      return (this.machines.find((m) => m.machine_id === this.machine_id) || {})
        .object_description;
    },
    sorted_machines() {
      return _.sortBy([...this.machines], "machine_id");
    },
    machinesWithSF18() {
      return this.myMachines.filter((machine_id) =>
        this.machineHasSf(machine_id, "18")
      );
    },
    SF18WithoutUser() {
      return this.sorted_machines.filter((machine) => {
        return (
          this.machinesWithSF18.includes(machine.machine_id) &&
          machine.emp_name === null
        );
      });
    },
  },
  mounted() {
    store.dispatch("config/loadMyMachines").then(() => {
      this.loadSpecialFunctions();
    });
  },
  data() {
    return {
      combined: this.currentcombined,
      SFLoaded: false,
    };
  },
  watch: {},

  methods: {
    toggle(machine_id, event) {
      event.stopPropagation();
      if (this.myMachines.includes(machine_id)) {
        //remove it
        store.dispatch("config/removeMachine", machine_id).then(() => {
          store.dispatch("config/loadMyMachines");
        });
      } else {
        //add it
        store.dispatch("config/addMachine", machine_id).then(() => {
          store.dispatch("config/loadMyMachines");
        });
        store.dispatch("machines/loadSpecialFunctionsForMachine", {
          machine_id: machine_id,
          reloadCachedValues: false,
        });
      }
    },
    loadSpecialFunctions() {
      let promises = [];
      for (let i = 0; i < this.myMachines.length; i++) {
        let machine_id = this.myMachines[i];
        promises.push(
          store.dispatch("machines/loadSpecialFunctionsForMachine", {
            machine_id: machine_id,
            reloadCachedValues: false,
          })
        );
      }
      Promise.all(promises).then(() => {
        this.SFLoaded = true;
      });
    },
    massSigning() {
      store.commit("bottomnav/enable");
      store.commit("bottomnav/setType", "mass-signing");
      store.commit("bottomnav/setData", {
        machines: this.machinesWithSF18,
        without: this.SF18WithoutUser,
      });
    },
  },
  components: {},
};
</script>
<style>
.signin-indicator.red {
  color: red;
}
</style>