<template>
  <div class="list">
    <b-list :id="item.list" v-model="modelValue.entered_value"></b-list>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  emits: ["update:modelValue", "next", "lock"],
  props: {
    modelValue: {},
    item: {},
    active: {},
  },
  mounted(){
  },
  data() {
    return {
      status:"green"
    };
  },
  components: { },
  watch: {
    modelValue: {
      deep: true,
      handler: function (val) {
        if (val.entered_value != "") {
          this.modelValue.isdone = true;
        } else{
          this.modelValue.isdone = false;
        }
        this.$emit("update:modelValue", val);
      },
    },
    status(val){
    },
    active(val) {
     
    },
    locked(val){
      this.$emit('lock',val);
    }
  },
  computed:{
    locked(){
      return !this.item.allow_user_edit;
    }
  },
  methods: {
  },
};
</script>

<style>
</style>