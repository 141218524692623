import { getNow } from "@/helpers/dates";
import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";

const state = () => ({
    list: [],
    sf383info: {

    },
    cardHistory:{

    }
});

// getters
const getters = {
}

// actions
const actions = {
    loadPartList({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            execute_json("get_part_list")
                .then(({ data }) => {
                    commit('setPartsList', {
                        data,
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })

        });
    },
    cardHistoryForPart({commit},{part_no,op_number}){
        return new Promise((resolve,reject)=>{
            let stop_date = getNow();
            let start_date = getNow(-2 * 365);
            execute_json("get_part_info",{
                part_no,
                op_no_from:op_number,
                op_no_to:op_number,
                start_date,
                stop_date
            }).then(({data})=>{
                commit("setHistoryForPart",{data,part_no,op_no:op_number});
                resolve(data);
            })
            .catch((err)=>{
                reject(err);
            })
        })
    },
    Sf383fetch({ commit, rootGetters }, { machine_id, material_index }) {
        return new Promise((resolve, reject) => {
            console.log("fetching sf 383 info for " + machine_id);
            if (rootGetters['machines/machineHasSf'](machine_id, '383')) {
                console.log(machine_id + " has SF 383 enabled");
                execute_json("sf383", { machine_id, get_active_material: true, material_index }, { disableErrorNotification: true })
                    .then(({ data }) => {
                        commit("setSf383Info", { data: data.length ? data[0] : data, machine_id, material_index });
                        resolve();
                    })
                    .catch((err) => {
                        console.log(err);
                        commit("setSf383Info", {
                            data: {
                                isEmpty:null
                            }, machine_id,
                            material_index
                        })
                        reject();
                    })
            }
            resolve();
        });
    }
}
// mutations
const mutations = {
    setPartsList(state, { data }) {
        state.list = [{ value: null, description: "-" }, ...data.map(i => {
            return {
                value: i.part_no,
                description: i.part_description
            }
        })];
    },
    setSf383Info(state, { data, machine_id, material_index }) {
        if (state.sf383info[machine_id] === undefined) {
            state.sf383info[machine_id] = {}
        }
        state.sf383info[machine_id][material_index] = data;
    },
    setHistoryForPart(state,{data,part_no,op_no}){
        if (!state.cardHistory[part_no]){
            state.cardHistory[part_no] = {};
        }
        state.cardHistory[part_no][op_no] = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}