
import axios from "@/requests/axios";
import { storeKey } from "vuex";
import { getStorage, setStorage, removeStorage, checkStorage } from "../../helpers/storage";
import { flattenSingleRow } from "@/requests/requestParcer";
import { execute_json } from "@/helpers/requests";

const state = () => ({
    username: getStorage("username") || null,
    company_key: getStorage("company_key") || null,
    company_id: getStorage("company_id") || null,
    lang_code: getStorage("lang_code") || "ENG",
    company_name: getStorage("company_name") || null,
    company_email: getStorage("company_email") || null,
    default_department: getStorage("default_department") || null,
    path: getStorage("lastpath", false) || null,
    defaultpath: getStorage("lastdefaultpath", false) || null,
    interfacepath: getStorage("lastinterfacepath", false) || null,
    supertype: getStorage("lastsupertype", false),
    session_id: 42,
    machine: getStorage("lastmachine", false) || null,
    usertype: getStorage("usertype") || null,
    last_company: getStorage("last_company", false) || null,
    web_single_machine:getStorage("web_single_machine") || '-1',
    only_machine_interface:getStorage("only_machine_interface") || false,
    settings: checkStorage("username") ? (getStorage(getStorage("username") + "_usersettings") || null) : null,
    dashboard_only:getStorage("dashboard_only") || false,
});

// getters
const getters = {

}

// actions
const actions = {
    login({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            payload.ip = "0.0.0.0";
            execute_json("verify_login",payload).then(({ data }) => {
                //console.log(data,data[0]);
                let userdata = data?.[0];
                if (!userdata || !userdata.webuser){
                    reject("User not a web user");
                    return false;
                }
                //console.log(userdata);
                let company = userdata;
                if (!company.web_single_machine){
                    company.web_single_machine = '-1';
                    console.log("overwrote default state web_single_machine");
                }
                
                let old = {
                    id: data[0].company_id[0],
                    key: data[0].company_key[0],
                    name: data[0].company_name[0],
                    email: "testing",
                    lang_code: data[0].lang_code[0],
                    usertype: data[0].usertype[0],
                    default_department: data[0].default_department[0],
                    only_machine_interface: data[0].web_only_machine_interface ? (data[0].web_only_machine_interface[0] === 'false' ? false : true) : false,
                    web_single_machine: data[0].web_single_machine ? data[0].web_single_machine[0] : '-1',
                    dashboard_only: data[0]?.dashboard_only?.[0] || false
                }
                commit('setLogin', company);
                commit('setUsername', payload.username);
                commit('setSettings', userdata);
                if (payload.rememberMe == true) {
                    commit('setRemember', company);
                }
                dispatch("notifications/loadHistory", null, { root: true });
                dispatch("notifications/checkIfEnabled", null, { root: true });
                resolve();
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            })
            return true;
            axios.post("balthzar_verify_login_20_0_19", payload)
                .then(({ data }) => {
                    //console.log(data,data[0]);
                    if (!data[0].webuser || data[0].webuser[0] == 'false' ){ 
                        reject("User not a web user");
                        return false;
                    }
                    let company = {
                        id: data[0].company_id[0],
                        key: data[0].company_key[0],
                        name: data[0].company_name[0],
                        email: "testing",
                        lang_code: data[0].lang_code[0],
                        usertype: data[0].usertype[0],
                        default_department: data[0].default_department[0],
                        only_machine_interface: data[0].web_only_machine_interface ? (data[0].web_only_machine_interface[0] === 'false' ? false : true) : false,
                        web_single_machine: data[0].web_single_machine ? data[0].web_single_machine[0] : '-1',
                        dashboard_only: data[0]?.dashboard_only?.[0] || false
                    }
                    commit('setLogin', company);
                    commit('setUsername', payload.username);
                    commit('setSettings', flattenSingleRow(data));
                    if (payload.rememberMe == true) {
                        commit('setRemember', company);
                    }
                    dispatch("notifications/loadHistory", null, { root: true });
                    dispatch("notifications/checkIfEnabled", null, { root: true });
                    resolve();
                })
                .catch((err) => {
                    console.error(err);
                    reject(err);
                })
        });
    },
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            commit("clearRemember");
            commit("setLogin", { });
            resolve();
        })
    }
}

// mutations
const mutations = {
    setLogin(state, { company_key, company_id, company_name, company_email, lang_code, default_department, usertype, web_only_machine_interface, web_single_machine,dashboard_only}) {
        state.company_key = company_key;
        state.company_id = company_id;
        state.company_name = company_name;
        state.company_email = company_email;
        setStorage("lang_code", lang_code);
        state.lang_code = lang_code;
        state.usertype = usertype;
        state.only_machine_interface = web_only_machine_interface;
        state.web_single_machine = web_single_machine;
        state.dashboard_only = dashboard_only;
        state.default_department = default_department;
        state.path = getStorage(state.company_id + "_lastpath", false) || null;
        state.defaultpath = getStorage(state.company_id + "_lastdefaultpath", false) || null;
        state.interfacepath = getStorage(state.company_id + "_lastinterfacepath", false) || null;
        state.machine = getStorage(state.company_id + "_lastmachine", false) || null;
        if (company_id !== undefined) {
            setStorage('last_company', company_id, false);
        }
    },
    setRemember(state, { company_key, company_id, company_name, company_email, default_department, usertype, web_only_machine_interface, web_single_machine,dashboard_only }) {
        setStorage('company_key', company_key);
        setStorage('company_id', company_id);
        setStorage('company_name', company_name);
        setStorage('company_email', company_email);
        setStorage('default_department', default_department);
        setStorage('usertype', usertype);
        setStorage('only_machine_interface', web_only_machine_interface);
        setStorage('web_single_machine', web_single_machine);
        setStorage('dashboard_only',dashboard_only);
        setStorage('super_confident',dashboard_only, false);
    },
    clearRemember() {
        removeStorage('company_key');
        removeStorage('company_id');
        removeStorage('company_name');
        removeStorage('company_email');
        removeStorage('default_department');
        removeStorage('usertype');
        removeStorage('only_machine_interface');
        removeStorage('web_single_machine');
        removeStorage('dashboard_only');
    },
    setUsername(state, username) {
        state.username = username;
        setStorage('username', username);
    },
    setLanguage(state, code) {
        state.lang_code = code;
        setStorage("lang_code", code);
    },
    lastPath(state, payload) {
        console.log(payload);
        let path = payload.path;
        let env = payload.env;
        state.path = path;
        setStorage(state.company_id + "_lastpath", path, false);
        setStorage("lastpath", path, false);
        if (env === 'default') {
            console.log("setting default path - "+path);
            state.defaultpath = path;
            setStorage(state.company_id + "_lastdefaultpath", path, false);
            setStorage("lastdefaultpath", path, false);
        } else if (env === 'interface') {
            state.interfacepath = path;
            setStorage(state.company_id + "_lastinterfacepath", path, false);
            setStorage("lastinterfacepath", path, false);
        }
    },
    lastSuperType(state, supertype) {
        state.supertype = supertype;
        setStorage(state.company_id + "_lastsupertype", supertype, false);
        setStorage("lastsupertype", supertype, false);
    },
    lastMachine(state, machine) {
        if (machine !== 'all' && machine !== 'undefined' && machine !== undefined) {
            state.machine = machine;
            setStorage(state.company_id + "_lastmachine", machine, false);
            setStorage("lastmachine", machine, false);
        }
    },
    setSettings(state, data) {
        state.settings = data;
        setStorage(state.username + "_usersettings", data);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}