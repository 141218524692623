import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";

const state = () => ({
    list: [],
});

// getters
const getters = {
}

// actions
const actions = {
    loadMessageGroupList({ commit, rootState }) {

        const isProduction = process.env.NODE_ENV === 'production' && !window.Cypress;
        let baseURL = isProduction ? "/adminws/WebServiceAdmin.asmx" : "https://dev.bzx21.com/adminws/WebServiceAdmin.asmx";
        return new Promise((resolve, reject) => {
            axios({
                url: "balthzar_get_message_group", data: {
                }, method: "POST", baseURL
            })
                .then(({ data }) => {
                    commit('setMessageGroupsList', {
                        data: flattenMultiRow(data),
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })

        });
    }
}
// mutations
const mutations = {
    setMessageGroupsList(state, { data }) {
        state.list = [{ value: "", label: "-" }, ...data.map(i => {
            return {
                value: i.group_id,
                label: i.group_description
            }
        })];
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}