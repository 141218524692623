import interfaceLayout from '@/layouts/InterfaceLayout'
import Interface from "@/views/interface/widgets.vue";

function loadView(view) {
  return () => import (/* webpackChunkName: "[request]" */ `@/views/${view}.vue`)
}

export const interfaceRoutes = [
   {
    path:'/interface/:machine',
    name:"machine-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "Interface",
      shouldRemember:true
    },
    component: Interface
  },
  {
    path:'/interface/:machine/stopcode',
    name:"stopcode-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "stopcode"
    },
    component: loadView("stops/stopcode")
  },
  {
    path:'/interface/:machine/weight-control',
    name:"weight-control-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "weight-control"
    },
    component: loadView("interface/company/5103/weight-control")
  },
  {
    path:'/interface/:machine/report-quantity',
    name:"report-quantity-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "report-quantity"
    },
    component: loadView("interface/report-quantity")
  },
  {
    path:'/interface/:machine/scrap-quantity',
    name:"scrap-quantity-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "scrap-quantity"
    },
    component: loadView("interface/scrap-quantity")
  },
  {
    path:'/interface/:machine/manual-scrap',
    name:"manual-scrap-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "manual-scrap"
    },
    component: loadView("interface/manual-scrap")
  },
  {
    path:'/interface/:machine/order-by-part',
    name:"order-by-part-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "order-by-part"
    },
    component: loadView("interface/order-by-part")
  },
  {
    path:'/interface/:machine/rework-order',
    name:"rework-order-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "rework-order"
    },
    component: loadView("interface/rework-order")
  },
  {
    path:'/interface/:machine/order-by-card',
    name:"order-by-card-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "order-by-card"
    },
    component: loadView("interface/order-by-card")
  },
  {
    path:'/interface/:machine/operation-text',
    name:"operation-text-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "operation-text"
    },
    component: loadView("interface/operation-text")
  },
  {
    path:'/interface/:machine/report-quantity-history',
    name:"report-quantity-history-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "report-quantity-history"
    },
    component: loadView("interface/history/report-quantity-history")
  },
  {
    path:'/interface/:machine/scrap-quantity-history',
    name:"scrap-quantity-history-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "scrap-quantity-history"
    },
    component: loadView("interface/history/scrap-quantity-history")
  },
  {
    path:'/interface/:machine/planned-orders',
    name:"planned-orders-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "planned-orders"
    },
    component: loadView("interface/planned-orders")
  },
  {
    path:'/interface/:machine/running-orders',
    name:"running-orders-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "running-orders"
    },
    component: loadView("interface/running-orders")
  },
  {
    path:'/interface/:machine/finished-orders',
    name:"finished-orders-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "finished-orders"
    },
    component: loadView("interface/finished-orders")
  },
  {
    path:'/interface/:machine/reporting-history',
    name:"reporting-history-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "reporting-history"
    },
    component: loadView("interface/reporting-history")
  },
  {
    path:'/interface/:machine/machine-test',
    name:"machine-test-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "machine-test"
    },
    component: loadView("interface/machine-test")
  },
  {
    path:'/interface/:machine/uncoded',
    name:"uncoded-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "uncoded"
    },
    component: loadView("stops/uncoded-stops")
  },
  {
    path:'/interface/:machine/uncoded/code',
    name:"code-uncoded-interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "code-uncoded"
    },
    component: loadView("stops/code-uncoded")
  },
  {
    path:'/interface/:machine/history-stops',
    name:"history interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "history interface"
    },
    component: loadView("stops/history-stops")
  },
  {
    path:'/interface/:machine/history-stops/code',
    name:"code-history interface",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "history"
    },
    component: loadView("stops/code-uncoded")
  },
  {
    path:'/interface/:machine/signed-in',
    name:"signed in",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "signed in"
    },
    component: loadView("interface/signed-in")
  },
  {
    path:'/interface/:machine/first-piece-ok',
    name:"First piece ok",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "first piece ok"
    },
    component: loadView("interface/first-piece-ok")
  },
  {
    path:'/interface/:machine/run-activity',
    name:"run activity",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "run activity"
    },
    component: loadView("interface/activities/run-activity")
  },
  {
    path:'/interface/:machine/create-activity',
    name:"create activity",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "create activity"
    },
    component: loadView("interface/activities/create-activity")
  },
  {
    path:'/interface/:machine/materials',
    name:"Materials",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "materials"
    },
    component: loadView("interface/materials")
  },
  {
    path:'/interface/:machine/materials-planned',
    name:"Materials-planned",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "materials-planned"
    },
    component: loadView("interface/materials-planned")
  },
  {
    path: '/interface/:machine/notifications',
    name: 'Interface: Notifications',
    meta: {
      requiresAuth: true,
      layout: interfaceLayout
    },
    component: loadView("settings/notificationOverview")
  },
  {
    path: '/interface/:machine/print-pallet-flag',
    name: 'Print pallet flag',
    meta: {
      requiresAuth: true,
      layout: interfaceLayout
    },
    component: loadView("interface/print-pallet-flag")
  },
  {
    path: '/interface/:machine/print-label',
    name: 'Print label',
    meta: {
      requiresAuth: true,
      layout: interfaceLayout
    },
    component: loadView("interface/print-label")
  },
  {
    path: '/interface/:machine/pallet-flag-history',
    name: 'Pallet flag history',
    meta: {
      requiresAuth: true,
      layout: interfaceLayout
    },
    component: loadView("interface/history/pallet-flags-history")
  },
  {
    path: '/interface/:machine/label-history',
    name: 'Label history',
    meta: {
      requiresAuth: true,
      layout: interfaceLayout
    },
    component: loadView("interface/history/label-history")
  },
  {
    path:'/interface/:machine/notes',
    name:"notes-machines",
    meta:{
      requiresAuth: true,
      layout: interfaceLayout,
      title: "Notes"
    },
    component: loadView("machinenotes/index")
  },
];
