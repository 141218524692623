<template>
  <div class="stopcode-singular" v-if="signable.machines">
    <p>{{ signable.machines.length }} machines with sign-in enabled</p>
    <input
      type="text"
      v-model="employee"
      :placeholder="$t('6134')"
      class="white-text"
    />
    <div class="buttons-wrap">
      <a
        href="#"
        class="router-button"
        :class="{ disabled }"
        @click.prevent="massSignIn"
        :disabled="loading || employee == null"
        >{{ $t("2515") }}</a
      >
      <a
        href="#"
        class="router-button"
        :class="{ disabled }"
        @click.prevent="massSignOut"
        :disabled="loading || employee == null"
        >{{ $t("2517") }}</a
      >
    </div>
  </div>
</template>

<script>
//import { useRouter } from 'vue-router';
import { mapState } from "vuex";
import { Notification } from "@/helpers/notifications";
import store from "@/store";
import axios from "@/requests/axios";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";

export default {
  props: {},
  data() {
    return {
      employee: null,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      signable: (state) => state.bottomnav.data,
    }),
    routerlinks() {
      return {};
    },
    disabled() {
      return this.loading || this.employee == null;
    },
  },
  methods: {
    massSignIn() {
      if (!this.disabled) {
        console.warn("mass sign in");
        let machines = this.signable.machines;
        let n = Notification.request({
          message: "Signing in on " + machines.length + " machines",
        });
        let employee = this.employee;
        console.warn(machines);
        this.loading = true;
        let promises = [];
        for (let i = 0; i < machines.length; i++) {
          let machine_id = machines[i];
          promises.push(
            new Promise((resolve, reject) => {
              execute_json("sign_in_at_machine", {
                machine_id,
                employee,
              })
                .then(({ data }) => {
                  resolve();
                })
                .catch((err) => {
                  reject(err);
                });
            })
          );
        }
        Promise.all(promises)
          .then(() => {
            console.warn("logged in on ", machines.length, "machines");
            n.ok({ message: "Succesfully signed in." });
            this.loading = false;
            store.commit("bottomnav/disable");
            store.dispatch("machines/loadMachines");
          })
          .catch((err) => {
            console.warn(err);
            n.fail({ message: err });
            this.loading = false;
          });
      }
    },
    massSignOut() {
      if (!this.disabled) {
        console.warn("mass signout");
        let machines = this.signable.machines;
        let n = Notification.request({
          message: "Signing out on " + machines.length + " machines",
        });
        let employee = this.employee;
        console.warn(machines);
        this.loading = true;
        let promises = [];
        for (let i = 0; i < machines.length; i++) {
          let machine_id = machines[i];
          promises.push(
            new Promise((resolve, reject) => {
              execute_json("sign_out_employee_at_machine", {
                machine_id: machine_id,
                employee_id: employee,
                logg_message: "-",
              })
                .then(() => {
                  resolve();
                })
                .catch((err) => {
                  console.warn(err);
                  if (err.call_code == 6) {
                    resolve();
                    return true;
                  }
                  reject(err.statusText);
                });
            })
          );
        }
        Promise.all(promises)
          .then(() => {
            console.warn("loggedout on ", machines.length, "machines");
            n.ok({ message: "Succesfully signed out." });
            this.loading = false;
            store.commit("bottomnav/disable");
            store.dispatch("machines/loadMachines");
          })
          .catch((err) => {
            console.warn(err);
            n.fail({ message: err });
            this.loading = false;
          });
      }
    },
  },
  components: {},
};
</script>

<style lang="scss">
</style>
