<template>
  <div class="machine-singular" v-if="order">
    <p v-if="hasSF47">{{ $t("1736") }} {{ $t("1737") }}</p>
    <div class="buttons-wrap" v-if="hasSF47">
      <a
        class="router-button"
        @click.prevent="this.sf47answer = true"
        href="#"
        :class="{
          selected: this.sf47answer === true,
          deselected: this.sf47answer === false,
        }"
        >{{ $t("2996") }}</a
      >
      <a
        class="router-button"
        @click.prevent="this.sf47answer = false"
        href="#"
        :class="{
          selected: this.sf47answer === false,
          deselected: this.sf47answer === true,
        }"
        >{{ $t("2997") }}</a
      >
    </div>
    <p v-if="!hasSF47">Running order: {{ order.work_order_no }}</p>
    <div class="buttons-wrap">
      <a
        class="router-button"
        @click.prevent="needsPopup ? openPopup() : stopOrder()"
        href="#"
        :disabled="disabled"
        :class="{ disabled }"
        >{{ $t("4160") }}</a
      >
    </div>
    <confirmation-popup
      v-if="sf131Popup"
      tquestion="2940"
      @accept="stopOrder"
      @reject="closePopup"
      @close="closePopup"
    ></confirmation-popup>
      <confirmation-popup
      v-if="sf388Popup"
      tquestion="7087"
      tmessage="7098"
      @accept="stopOrder"
      @reject="closePopup"
      @close="closePopup"
    ></confirmation-popup>
  </div>
</template>

<script>
//import { useRouter, useRoute } from 'vue-router';
import { mapState, mapGetters } from "vuex";
import { Notification } from "@/helpers/notifications";
import ConfirmationPopup from "@/components/ConfirmationPopup.vue";
import store from "@/store";

export default {
  props: {},
  data() {
    return {
      sf47answer: null,
      sf131Popup: false,
      sf388Popup: false,
    };
  },
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      order: (state) => state.bottomnav.data[0],
      notifications: (state) => state.notifications.index,
    }),
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
    }),
    hasSF47() {
      return this.machineHasSf(this.$route.params.machine, "47");
    },
    hasSF131() {
      return this.machineHasSf(this.$route.params.machine, "131");
    },
    hasSf388() {
      return this.machineHasSf(this.$route.params.machine, "388");
    },
    needsPopup() {
      return this.hasSF131;
    },
    routerlinks() {
      return {};
    },
    disabled() {
      return this.hasSF47 && this.sf47answer == null;
    },
  },
  methods: {
    openPopup() {
      if (this.needsSf388Popup()) {
        this.sf388Popup = true;
      } else {
        this.sf131Popup = true;
      }
    },
    needsSf388Popup() {
      /*
      if (this.hasSF47 && this.sf47answer === true && this.order.produced_op_qty < 0.5 * this.order.planned_op_qty) {
        console.log(this.order)
        return true;
      }
      */
      return false;
    },
    closePopup() {
      this.sf131Popup = false;
      this.sf388Popup = false;
    },
    stopOrder() {
      let note = Notification.request({
        message: "Stopping work order..",
      });
      store
        .dispatch("orders/stopOrder", {
          order: this.order,
          machine_id: this.$route.params.machine,
          cancel_order: this.sf47answer,
        })
        .then(() => {
          note.ok({
            message: "Work order stopped!",
          });
          this.closePopup();
        })
        .catch(() => {
          note.fail({ message: "Something went wrong" });
        });
      store.commit("bottomnav/disable");
      if (this.$route.params.name !== "machine-interface") {
        this.$router.push("/interface/" + this.$route.params.machine);
      }
    },
  },
  components: {
    // DxButton
    ConfirmationPopup,
  },
  watch: {
    order: {
      deep: true,
      handler() {
        this.sf47answer = null;
      },
    },
  },
};
</script>

<style lang="scss">
</style>
