<template>
  <div v-html="replacedHtml" class="show-html"></div>
</template>

<script>

const isProduction = process.env.NODE_ENV === 'production' && !window.Cypress;
export default {
    props:{
        value:{

        }
    },
    computed:{
        replacedHtml(){
            let html = this.value;
            let regex = /src="((file:\/\/\/).+)"/gm;
            while (html.indexOf('src="file:///') > -1){
                let src = regex.exec(html);
                src = src[1];
                let rep = isProduction ? "/serverfiles/" : "https://dev.bzx21.com/serverfiles/"+src.slice(8).replace(":",'');
                html = html.replace(src,rep);
            }
            return html
        }
    }
}
</script>

<style lang="scss">
.show-html{
    text-align:left;
    padding:20px;
    img{
        max-width:100%;
    }
}
</style>