<template>
  <div>
    <c-table
      v-if="loaded"
      :data="materialDataForLine"
      config-string="materials"
      primary-key="material_no"
      :single-select="true"
      :deselect="true"
      :teleport="true"
      @selectionChanged="onSelectionChanged"
    >
    </c-table>
    <loading v-else />
    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    this.fetchMaterials();
  },
  methods: {
    fetchMaterials() {
      if (this.data.order.selected !== null) {
        store
          .dispatch("materials/loadMaterialList", {
            machine: {...this.data.order.selected,machine_id:this.machine_id},
          })
          .then(() => {
            this.loaded = true;
          });
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      console.log(selectedRowsData[0]);
      if (selectedRowsData.length > 0) {
        store.commit("bottomnav/setData", selectedRowsData[0]);
        store.commit("bottomnav/setType", "material-singular");
        store.commit("bottomnav/enable");
      } else {
        store.commit("bottomnav/disable");
      }
    },
  },
  computed: {
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      materialListByRecordNo: "materials/materialListByRecordNo",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    hasSf368() {
      return this.machineHasSf(this.machine_id, "368");
    },
    materialList() {
      return this.data.order.selected !== null
        ? this.materialListByRecordNo(this.data.order.selected.record_no)
        : null;
    },
    materialDataForLine() {
      return this.hasSf368
        ? this.materialList.filter(
            (m) => m.line_position === this.machine.line_position //todo: fix the dev exception
          )
        : this.materialList;
    },
    disableNext() {
      return (
        this.data[this.tab.dataKey].canBeEmpty === undefined &&
        (this.data[this.tab.dataKey].value === null ||
          this.data[this.tab.dataKey].value === "")
      );
    },
  },
};
</script>