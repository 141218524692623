import { graphStopreasonsDataFunctions, graphStopreasonsDataSetup } from "./graph/stopreasons";
import { graphStopReasonsLocationDataFunctions, graphStopReasonsLocationDataSetup } from "./graph/stopreasons-location";
import { graphAvailabilityDataFunctions , graphAvailabilityDataSetup } from "./graph/availability";
import { graphAvailabilityLocationDataFunctions, graphAvailabilityLocationDataSetup } from "./graph/availability-location";
import { graphMachineAnalysisDataFunctions, graphMachineAnalysisDataSetup } from "./graph/machineanalysis";
import { graphStopreasonsDailyDataFunctions, graphStopreasonsDailyDataSetup } from "./graph/stopreasons-daily";
import { graphStopreasonsDailyLocationDataFunctions, graphStopreasonsDailyLocationDataSetup } from "./graph/stopreasons-daily-location";
import { graphStopreasonsCountDataFunctions, graphStopreasonsCountDataSetup } from "./graph/stopreasons-count";

const graphDataFunctions = {
    ...graphStopreasonsDataFunctions,
    ...graphStopReasonsLocationDataFunctions,
    ...graphAvailabilityDataFunctions,
    ...graphAvailabilityLocationDataFunctions,
    ...graphMachineAnalysisDataFunctions,
    ...graphStopreasonsDailyDataFunctions,
    ...graphStopreasonsDailyLocationDataFunctions,
    ...graphStopreasonsCountDataFunctions,
}

const graphDataSetup = {
    stopreasons: graphStopreasonsDataSetup,
    stopreasons_location:graphStopReasonsLocationDataSetup,
    availability:graphAvailabilityDataSetup,
    availability_location:graphAvailabilityLocationDataSetup,
    machineanalysis:graphMachineAnalysisDataSetup,
    stopreasons_daily:graphStopreasonsDailyDataSetup,
    stopreasons_daily_location:graphStopreasonsDailyLocationDataSetup,
    stopreasons_count:graphStopreasonsCountDataSetup,
}

export { graphDataFunctions, graphDataSetup }