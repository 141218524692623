<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div v-if="machine" class="max-align">
      <h1>
        {{ $t("2969") }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div class="flex">
        <button
          class="indicator status-large"
          :class="notificationSettings.alarm_on_stop ? 'c-green' : 'c-gray'"
          @click="tryToggle"
        >
          <i class="fas fa-alarm-clock"></i><br />
          <span class="caption">{{ $t("852") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { InterfaceComponent } from "@/helpers/machine";
import { execute_json } from "@/helpers/requests";
import store from "@/store";
export default InterfaceComponent(
  {
    data() {
      return {
        config: {
          advancedToggling: false,
        },
        configString: "alarm-order",
      };
    },
    methods: {
      tryToggle() {
        if (this.config.advancedToggling) {
          if (this.notificationSettings.alarm_on_stop) {
            this.toggleMachineNotifications();
          } else {
            store.commit("popups/addPopup", {
              data: {
                machine_id: this.machine_id,
                pulse: this.pulse,
              },
              type: "alarm-confirm",
              canClose: false,
            });
          }
        } else {
          this.toggleMachineNotifications();
        }
      },
      toggleMachineNotifications() {
        execute_json("machine_notification", {
          machine_id: this.machine_id,
          feature: "set",
          alarm_on_stop: !this.notificationSettings.alarm_on_stop,
        }).then(() => {
          this.reloadNotifications();
        });
      },
      reloadNotifications() {
        this.pulse.shock("notifications");
      },
    },
    computed: {
      items() {
        return [
          { header: this.$t("497") },
          {
            tlabel: "5413",
            handler: () => {
              this.config.setValues({
                advancedToggling: !this.config.advancedToggling,
              });
            },
            selected: this.config.advancedToggling,
          },
        ];
      },
    },
  },
  ["notifications"]
);
</script>

<style>
</style>