<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div>
      <h1>
        {{ $t("6537") }}
        <card-menu :items="items"></card-menu>
      </h1>
       <router-link
        :to="'/interface/'+machine_id+'/rework-order'"
        class="indicator status-large c-green"
      >
        <div class="vertically-centered-content">
          <span>
            {{ $t("6537") }}
          </span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import CardMenu from "@/components/card-menu";
export default {
  data() {
    return {
      items: [],
    };
  },
  computed:{
      machine_id(){
          return this.$route.params.machine;
      }
  },
  components:{
      CardMenu
  }
};
</script>

<style>
</style>