<template>
  <div class="stopcode-singular">
    <p>
      {{ $t("1") }} {{ $route.params.machine }} : #{{ stopcode.stop_code }} -
      {{ stopcode.stop_description }}
    </p>

    <div class="buttons-wrap">
      <!-- 3300 -> set stopreason -->
      <a href="#" class="router-button" @click.prevent="saveStopcode">
        <i class="fas fa-sticky-note" v-if="stopcode.stop_text_flag"></i>
        <span v-if="!saving">{{ $t("3300") }}</span>
        <loading v-else />
      </a>
    </div>

    <keyboard v-model="note" v-if="notePopupEnabled" />
    <div
      class="popup-wrap"
      v-if="notePopupEnabled"
      @click.self="cancelNote"
      style="color: black"
    >
      <div class="popup">
        <h2>{{ $t("638") }}</h2>
        <textarea
          name=""
          v-model="note"
          class="form-control"
          id=""
          cols="30"
          rows="4"
        ></textarea>
        <br />
        <div class="buttons-wrap">
          <!-- 3300 -> set stopreason -->
          <a
            href="#"
            class="router-button btn btn-success"
            @click.prevent="saveStopcode"
          >
            <span v-if="!saving">{{ $t("3300") }}</span>
            <loading v-else />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";

export default {
  props: {},
  data() {
    return {
      note: null,
      loadIndicatorVisible: false,
      buttonText: "Save stopcodes",
      saving: false,
      notePopupEnabled: false,
    };
  },
  setup() {},
  mounted() {
    this.handleSf273();
  },
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      bottomnavOpen: (state) => state.bottomnav.shouldShow,
      stopcode: (state) => state.bottomnav.data,
      path: (state) => state.auth.path,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    hasSf273() {
      let machine_id = this.$route.params.machine;
      return this.machineHasSf(machine_id, "273");
    },
    routerlinks() {
      return {};
    },
    shouldAskForNote() {
      return this.stopcode.stop_text_flag && this.note === null;
    },
  },
  methods: {
    cancelNote() {
      this.notePopupEnabled = false;
      this.note = null;
    },
    saveStopcode() {
      console.log("saving");
      if (!this.saving) {
        if (this.shouldAskForNote) {
          this.notePopupEnabled = true;
          this.note = "";
          return true;
        }

        this.saving = true;
        store
          .dispatch("stopcodes/setStopcode", {
            stopcode: this.stopcode,
            machine_id: this.$route.params.machine,
            note: this.note,
          })
          .then(() => {
            this.saving = false;
            console.log("this should be called after save..");
            this.$router.push(this.path);
            /*
            if (this.$route.params.locationtype){
            let viewtype = this.$route.params.viewtype || "machines";
              this.$router.push('/'+viewtype+'/'+this.$route.params.locationtype+'/'+this.$route.params.location);
            }else{
              this.$router.push('/interface/'+this.$route.params.machine);
            }*/
            store.commit("bottomnav/disable");
          })
          .catch((err) => {
            this.saving = false;
          })
          .finally(() => {
            this.note = null;
            this.notePopupEnabled = false;
          });
      }
    },
    handleSf273() {
      console.warn("just mounted this", this.hasSf273);
      if (this.hasSf273 && this.stopcode.stop_code) {
        this.saveStopcode();
      }
    },
  },
  watch: {
    bottomnavOpen() {
      if (this.bottomnavOpen){
        this.handleSf273();
      }
    },
  },
  components: {
    //DxButton,
    //DxTextBox
  },
};
</script>

<style lang="scss">
</style>
