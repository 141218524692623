<template>
  <div class="checkbox" @click="toggle" :class="{disabled}">
    <div
      class="checked-box"
      :class="{ checked: modelValue }"
      name="checked-box"
    >
      <i class="fas fa-check" v-if="modelValue"></i>
      <i class="fas fa-horizontal-rule" v-else></i>
    </div>
    <label for="checked-box">{{ tlabel ? $t(tlabel) : label }}</label>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      required: true,
    },
    label: {},
    tlabel: {},
    disabled: {
      default: false,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    toggle() {
      if (!this.disabled) {
        this.$emit("update:modelValue", !this.modelValue);
      }
    },
  },
};
</script>