<template>
  <stopreason-count-graph-base storageKey="stopreasons_count"></stopreason-count-graph-base>
</template>
<script>
import StopreasonCountGraphBase from "@/components/widgets/base/StopreasonCountGraphBase";

export default {
  components: {
    StopreasonCountGraphBase,
  },
};
</script>