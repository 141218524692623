<template>
  <div>
    <Loading v-if="loading" />
    <div v-else>
      <p v-if="ordersForMachine !== null">
        <span
          v-if="
            ordersForMachine.length > 0 && ordersForMachine[0].status != '0'
          "
        >
          <c-table
            :data="ordersForMachine"
            primary-key="record_no"
            config-string="finished_orders_tab"
            @selectionChanged="onSelectionChanged"
            :teleport="true"
          >
          </c-table>
        </span>
        <span v-else>
          {{ $t("1259") }}
        </span>
      </p>
      <p v-else>
        {{ $t("1817") }}
      </p>
      <button
        class="tabbed-next-button"
        @click="$emit('next')"
        :disabled="disableNext"
        :class="{ disabled: disableNext }"
      >
        <i class="fas fa-chevron-right" v-if="!islast"></i>
        <i class="fas fa-check" v-if="islast"></i>
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      machine_id: this.$route.params.machine,

      selectedRows: [],
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    this.data[this.tab.dataKey].selected = null;
    store
      .dispatch("orders/loadFinishedOrdersForMachine", {
        machine_id: this.machine_id,
        non_cancelled: this.hasSf196,
      })
      .then(() => {
        this.loading = false;
        if (
          this.ordersForMachine.length === 1 &&
          this.data[this.tab.dataKey].jumped === undefined
        ) {
          this.data[this.tab.dataKey].selected = this.ordersForMachine[0];
          this.data[this.tab.dataKey].jumped = true;
          this.$emit("next");
        }
      });
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      finishedOrders: (state) => state.orders.finishedOrders,
    }),
    ...mapGetters({
      canUser: "permissions/canUser",
      machineHasSf: "machines/machineHasSf",
    }),
    ordersForMachine() {
      return this.finishedOrders[this.machine_id] || null;
    },
    disableNext() {
      return this.data[this.tab.dataKey].selected === null;
    },
    hasSf196() {
      return this.machineHasSf(this.machine_id, "196");
    },
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length > 0 && this.selectedRows[0]) {
        this.data[this.tab.dataKey].selected = this.selectedRows[0];
      } else {
        this.data[this.tab.dataKey].selected = null;
      }
    },
  },
  watch: {},
};
</script>