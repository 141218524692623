<template>
  <div>
    <h2 class="content-block">
      <uncoded-stops-indicator></uncoded-stops-indicator>
      {{ $t("17") }} - {{ $t(reversePeriod) }}
    </h2>
    <sort-select target="machines"></sort-select>
    <my-machines-select v-if="loctype === 'mine'"></my-machines-select>
   
     <c-table
        :data="filteredMachines"
        v-if="isMounted"
        primary-key="machine_id"
        config-string="machines"
        default-sort-key="machine_id"
        default-sort-dir="asc"
        @selectionChanged="onSelectionChanged"
        :teleport="true"
      >
      </c-table>
    <router-view></router-view>
  </div>
</template>

<script>
import SortSelect from "@/components/sort-select";
import MyMachinesSelect from "@/components/my-machines-select";
import UncodedStopsIndicator from "@/components/uncoded-stops-indicator.vue";
import { mapState , mapGetters} from "vuex";
import store from "@/store";
import _ from "lodash";

export default {
  setup() {
    return {
      selectedRows: [],
    };
  },
  data() {
    return {
      highlights: [],
      reversePeriods: {
        today: "447",
        shift: "245",
        "24h": "448",
        calendar:"18"
      },
      isMounted:false
    };
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch("config/loadMachineSorting");
    if (store.state.machines.loaded) {
      next();
    } else {
      store.dispatch("machines/loadMachines").then(() => {
        next();
      });
    }
  },
  mounted() {
    store.commit("bottomnav/disable");
    this.isMounted = true;
    //store.dispatch('machines/randomCycles');
    this.checkValidLocation();
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length === 0) {
        store.commit("bottomnav/disable");
      } else {
        store.commit("bottomnav/enable");
      }
      store.commit("bottomnav/setType", "machine-singular");
      store.commit("bottomnav/setData", this.selectedRows);
      if (this.selectedRows.length > 0 && this.selectedRows[0]) {
        store.dispatch("stopcodes/loadStopcodesForMachine", {
          machine_id: this.selectedRows[0].machine_id,
          reloadCachedValues: false,
        });
        store.dispatch("machines/loadTouchConfigForMachine", {
          machine_id: this.selectedRows[0].machine_id,
          reloadCachedValues: false,
        });
        store.dispatch("machines/loadSpecialFunctionsForMachine", {
          machine_id: this.selectedRows[0].machine_id,
          reloadCachedValues: false,
        });
      }
      //this.$forceUpdate();
    },
    checkValidLocation() {
      let redirectNeeded = false;
      let locationtype = "department";
      console.log("checking valid location", this.$route);
      if (this.$route.meta.group === "all") {
        return true;
      }
      //check if locationtype is valid (department/category/zone)
      if (!["department", "category", "zone", "mine"].includes(this.loctype)) {
        redirectNeeded = true;
      } else {
        locationtype = this.loctype;
      }
      let location = this["available" + locationtype][0];
      //check if location is valid
      if (!this["available" + locationtype].includes(this.loc)) {
        redirectNeeded = true;
      } else {
        location = this.loc;
      }
      if (redirectNeeded) {
        if (this.loctype === "undefined" && this.loc === "undefined") {
          this.$router.push("/machines/all");
        } else {
          this.$router.push("/machines/" + locationtype + "/" + location);
        }
      }
    },
  },
  computed: {
    ...mapState({
      //machines: (state) => state.machines.index,
      configs: (state) => state.machines.config,
      machinesLoaded: (state) => state.machines.loaded,
      languages: (state) => state.languages,
      config: (state) => state.config,
    }),
    ...mapGetters({
      machines: "machines/nonResourceMachines"
    }),
    reversePeriod() {
      return this.reversePeriods[this.config.periods.machines] || "447";
    },
    loctype() {
      return this.$route.params.locationtype;
    },
    loc() {
      return this.$route.params.location;
    },
    availablemine() {
      return ["all"];
    },
    availabledepartment() {
      let unique = [...new Set(this.machines.map((item) => item.department))];
      return _.filter(unique, (department) => {
        return department !== undefined && department != "";
      });
    },
    availablezone() {
      let unique = [...new Set(this.machines.map((item) => item.zone))];
      return _.filter(unique, (zone) => {
        return zone !== undefined && zone != "";
      });
    },
    availablecategory() {
      let unique = [...new Set(this.machines.map((item) => item.category))];
      return _.filter(unique, (category) => {
        return category !== undefined && category != "";
      });
    },
    availableLocations() {
      return this["available" + this.loctype];
    },
    myMachines() {
      let config = this.config;
      return this.machines.filter((m) => {
        return config.myMachines.includes(m.machine_id);
      });
    },
    filteredMachines() {
      if (this.$route.meta.group === "all") {
        return this.machines;
      }
      if (this.loctype === "mine") {
        return this.myMachines;
      }
      let locationtype = this.loctype;
      let location = this.loc;
      return _.filter(this.machines, (m) => {
        return m[locationtype] == location;
      });
    },
  },
  watch: {
    $route(to) {
      //store.dispatch('machines/randomCycles');
      console.log(to);
      if (to.matched[0].name === "machines") {
        this.checkValidLocation();
      }
    },
  },
  components: {
    SortSelect,
    MyMachinesSelect,
    UncodedStopsIndicator,
  },
};
</script>
