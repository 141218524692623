<template>
  <div v-if="machine">
    <h1>{{ $t("2568") }} <card-menu :items="items"></card-menu></h1>
    <div v-if="orders !== null">
      <div
        v-if="orders.length > 0 && orders[0].status != '0'"
        style="font-size: 8px"
      >
       
         <c-table
          :data="orders"
          primary-key="card_no"
          config-string="planned_orders_widget"
          default-sort-key="work_order_no"
          default-sort-dir="asc"
          @selectionChanged="onSelectionChanged"
          :teleport="true"
        >
        </c-table>
      </div>
      <div class="center-widget" v-else>
        {{ $t("4744") }}
      </div>
    </div>
    <div class="center-widget" v-else>
      {{ $t("1817") }}
    </div>
     
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      machine_id: this.$route.params.machine,
      interval: 180,
      selectedRows: [],
      pulse: null,
    };
  },
  mounted() {
    this.pulse = new Pulse({
      action: "orders/loadPlannedOrdersForMachine",
      parameters: {
        machine_id: this.machine_id,
        erp_resource: this.machine.erp_resource,
        erp_connection_type: this.machine.erp_connection_type,
      },
      interval: this.interval,
      primaryKey: "machine_id",
    });
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length === 0) {
        store.commit("bottomnav/disable");
        store.commit("selections/unset", "order");
      } else {
        store.commit("bottomnav/enable");
        store.commit("selections/set", {
          type: "order",
          data: this.selectedRows[0],
          subtype: "planned"
        });
      }
      store.commit("bottomnav/setType", "planned-order-singular");
      store.commit("bottomnav/setData", this.selectedRows);
    },
  },
  watch: {
    workOrder(val, old) {
      if (old !== null) {
        this.pulse.force();
      }
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      plannedOrders: (state) => state.orders.plannedOrders,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      columnConfigByTemplate: "config/columnConfigByTemplate",
      canUser: "permissions/canUser",
    }),
    userPermission() {
      return (
        this.canUser("change-own-webcolumns") ||
        this.canUser("change-default-webcolumns")
      );
    },
   
    machine() {
      return this.machineById(this.machine_id);
    },
    orders() {
      return this.plannedOrders[this.machine_id] || null;
    },
    items() {
      let items = [];
      if (this.userPermission) {
        items.push({ header: this.$t("89") });
        items.push({
          tlabel: "506",
          to: "/admin/config/tables/planned_orders_widget",
        });
      }
      return items;
    },

    workOrder() {
      return this.machine.work_order_no;
    },
  },
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
  },
};
</script>