<template>
  <div>
    <numerical-input v-model="data[tab.dataKey].value"></numerical-input>
    <span class="warning" v-if="underMin && data[tab.dataKey].value > '0'"><i class="fas fa-warning"></i> {{minMessage}}<br/><br/></span>
    <span class="warning" v-if="overMax"><i class="fas fa-warning"></i> {{maxMessage}}<br/><br/></span>

    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>
<script>
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  computed: {
    disableNext() {
      return this.outsideRange || ((
        this.data[this.tab.dataKey].value === null ||
        this.data[this.tab.dataKey].value === "0."
      ) && (this.data[this.tab.dataKey].allowNull === undefined || !this.data[this.tab.dataKey].allowNull));
    },
    currentValue() {
      return this.data[this.tab.dataKey].value;
    },
    max() {
      return this.data[this.tab.dataKey].max || 0;
    },
    watchMin(){
      return this.data[this.tab.dataKey].watchMin !== undefined ? this.data[this.tab.dataKey].watchMin : null;
    },
    watchMax(){
      return this.data[this.tab.dataKey].watchMax !== undefined ? this.data[this.tab.dataKey].watchMax : null;
    },
    minMessage(){
      return this.data[this.tab.dataKey].minMessage !== undefined ? this.data[this.tab.dataKey].minMessage : null;
    },
    maxMessage(){
      return this.data[this.tab.dataKey].maxMessage !== undefined ? this.data[this.tab.dataKey].maxMessage : null;
    },
    underMin(){
      if (this.watchMin !== null && this.watchMin !== 0){
        return +this.currentValue < +this.watchMin;
      }
      return false;
    },
    overMax(){
      if (this.watchMax !== null){
        return +this.currentValue > +this.watchMax;
      }
      return false;
    },
    outsideRange(){
      return this.underMin || this.overMax;
    }
  },
  watch: {
    currentValue(val) {
      if (
        this.max != 0 &&
        this.max != undefined &&
        val > this.max
      ) {
        this.data[this.tab.dataKey].value = this.max;
      }
    },
  },
};
</script>