<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <finished-order-base></finished-order-base>
  </div>
</template>
<script>
import FinishedOrderBase from "./base/FinishedOrdersBase.vue"

export default {
  components: {
    FinishedOrderBase,
  },
};
</script>