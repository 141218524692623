import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import { getStorage, setStorage, checkStorage } from "../../helpers/storage";
import { getNow } from "@/helpers/dates";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";

const state = () => ({
  confidentTime: getStorage('confident_time') || 0,
  confidentInterval: 96 * 60 * 60 * 1000, //60 h x 60 m/h x 60 s/m x 1000 ms/s,
  index: [],
  history: [],
  lastrecord: getStorage("lastrecord") || null,
  company_loaded: false,
  company: null,
  handlers: {
    stopWO: [
      'stopWO',
    ],
    startWO: [
      'startWO'
    ],
    restartWO: [],
    UpdateUserSettings: [
      'UpdateUserSettings'
    ],
    DeleteUserSettings: [
      'UpdateUserSettings'
    ],
    FileArchiveAddedFile: [
      'PresentFileToUser'
    ],
    UpdateSF: [
      'UpdateSF'
    ],
    UpdateEtouchParameters: [
      'UpdateEtouchParameters'
    ],
    UpdateMachine: [
      'UpdateEtouchParameters'
    ],
    ManAtMachine_change: [
      'ManAtMachineChange'
    ],
    UpdateStopReasons: [
      'UpdateStopReasons'
    ],
    UpdateMachineStopReasons: [
      'UpdateMachineStopReasons'
    ],
    //local events
    StartedWorkOrderLocally:[
      'StartedWorkOrderLocally'
    ],
    FetchedRunningOrdersLocally:[
      'CheckForOpText',
      'CheckSf368Status'
    ],
    UserWentInactive:[
      'HandleInactiveUser'
    ],
    localPageSwap:[],
    update_stop_reason: [],
    confirm_materials:[],
    //dont need handling on client side yet
    finishedIA: [],
    InternalActivityStarted: [],
    fpOK: [],
    UpdateStopReasonGroup: [
      'UpdateStopReasonGroups'
    ],
    RFIDModule:[

    ]
  },
  timeouts: {
    stopWO: 60000,
    startWO: 60000,
    FileArchiveAddedFile: 60000,
    RFIDModule:30000
  }
});

// getters
const getters = {
  isConfident(state) {
    return new Date().getTime() < +state.confidentTime + state.confidentInterval;
  }
}

// actions
const actions = {
  loadEvents({ commit, state, dispatch, rootState }) {
    //console.log("%c IM LOADING EVENTS","color:red;font-size:16px;")
    return new Promise((resolve, reject) => {
      if (rootState.auth.company_id === null || rootState.auth.company_id === "") {
        resolve();
        return true;
      }
      if (!state.company_loaded) {
        commit("loadCompany", {
          company: rootState.auth.company_id
        });
      }
      execute_json("get_events", {
        record_no: state.lastrecord
      })
        .then(({ data }) => {
          //console.log(data, flattenMultiRow(data));
          commit('setEvents', flattenMultiRow(data));
          commit('beConfident');
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  loadHistory({ commit, state, dispatch, rootState }) {
    //console.log("%c IM LOADING EVENTS","color:red;font-size:16px;")
    return new Promise((resolve, reject) => {
      if (rootState.auth.company_id === null) {
        resolve();
        return true;
      }
      if (!state.company_loaded) {
        commit("loadCompany", {
          company: rootState.auth.company_id
        });
      }
      execute_json("get_events", {
        trans_time: getNow(-1)
      })
        .then(({ data }) => {
          //console.log(data, flattenMultiRow(data));
          commit('setHistory', flattenMultiRow(data));
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
}
// mutations
const mutations = {
  beConfident(state) {
    state.confidentTime = new Date().getTime();
    setStorage('confident_time', new Date().getTime());
    setStorage(state.company + '_confident_time', new Date().getTime());
  },
  setEvents(state, data) {
    if (data.length > 0 && data[0].call_code === undefined) {
      state.index.push(...data);
      state.lastrecord = data[data.length - 1].record_no;
      setStorage("lastrecord", state.lastrecord);
      setStorage(state.company + "_lastrecord", state.lastrecord);
    }
  },
  overwriteLastRecord(state, record_no) {
    state.lastrecord = record_no;
    setStorage("lastrecord", state.lastrecord);
    setStorage(state.company + "_lastrecord", state.lastrecord);
  },
  setHistory(state, data) {
    if (data.length > 0 && data[0].call_code === undefined) {
      state.history = _.orderBy(data, ["trans_time"], ['desc']);
    }
  },
  addHandler(state, { event, handler }) {
    state.handlers[event].push(handler);
  },
  removeHandler(state, { event, handler }) {
    state.handlers[event] = state.handlers[event].filter(h => h != handler);
  },
  loadCompany(state, { company }) {
    state.company = company;
    state.company_loaded = true;
    state.confidentTime = getStorage(company + '_confident_time') || 0;
    state.lastrecord = getStorage(company + '_lastrecord') || null;
    // console.log("COMPANY LOADED INTO EVENTS:", company, state.confidentTime, state.lastrecord);
  },
  addLocalEvent(state,event){
    state.index.push(event);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}