<template>
  <div class="popup-wrap" @click.self="back">
    <router-view class="popup"></router-view>

    <bottom-buttons v-if="bottom && bottomShouldShow" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import BottomButtons from "@/components/bottom-buttons";

export default {
  props: {
    bottom: { default: true },
  },
  computed: {
    ...mapState({
      path: (state) => state.auth.path,
      bottomShouldShow: (state) => state.bottomnav.shouldShow,
    }),
  },
  methods: {
    back() {
      this.$router.push(this.path);
    },
  },
  components: {
    BottomButtons,
  },
};
</script>
