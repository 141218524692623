<template>
  <div>
    <nav class="navbar main-nav navbar-dark bg-dark fixed-top">
      <div class="container-fluid">
        <router-link to="/machines/all" class="navbar-toggler" type="button">
          <img
            class=""
            src="@/assets/Balthzar.png"
            alt=""
            style="max-height: 40px"
          />
        </router-link>
        <div class="location-swap">
          <button
            class="btn"
            type="button"
            id="locationSwapButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>{{ overview }}</span>
          </button>
          <ul class="dropdown-menu" aria-labelledby="locationSwapButton">
            <template v-for="t in overviews" :key="'h6-' + t">
              <li>
                <h6 class="dropdown-header">{{ t.label }}</h6>
              </li>
              <li v-for="i in t.overviews" :key="i.slug">
                <router-link
                  :to="'/data/' + t.key + '/' + i.slug"
                  class="dropdown-item"
                  >{{ i.label }}</router-link
                >
              </li>
            </template>
          </ul>
        </div>
        <div></div>
      </div>
    </nav>
    <div class="spacer" style="height: 44px"></div>

    <div class="container-xxxl dashboard" :class="'zoom-' + zoom">
      <loading v-if="!loaded" />
      <slot v-if="loaded"></slot>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import { mapState, mapGetters } from "vuex";
import { loadLanguageFromCache } from "@/i18n";
import BottomButtons from "@/components/bottom-buttons";
import store from "@/store";
import { watch, ref } from "vue";
import { tryFullScreen,tryCloseFullScreen } from "@/helpers/screen";
export default {
  setup() {
    const route = useRoute();

    loadLanguageFromCache();
    const title = ref(route.meta.title);
    const description = ref("");
    store.dispatch("permissions/loadUnloadedPermissions");
    watch(
      () => route.path,
      () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
      }
    );
    return {
      title,
      description,
    };
  },
  mounted() {
    this.loaded = true;
    tryFullScreen();
  },
  beforeUnmount() {
    tryCloseFullScreen
  },
  data() {
    return {
      loaded: false,
      zoom: 100,
    };
  },
  computed: {
    ...mapState({
      userLevel: (state) => state.auth.usertype,
      dashboardTemplates: (state) => state.config.templates.dashboards,
      dashboardConfigs: (state) => state.config.dashboards,
      screenWidth: (state) => state.screenWidth,
      company: (state) => state.auth.company_id,
    }),
    ...mapGetters({
      canUser: "permissions/canUser",
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    browserInFullScreen() {
      return !window.screenTop && !window.screenY;
    },
    overview() {
      return this.overviewLabels[this.$route.params.overview]
        ? this.overviewLabels[this.$route.params.overview]
        : this.$route.params.overview;
    },
    overviewLabels() {
      return {
        "overview-1": this.$t("1549"),
      };
    },
    overviews() {
      return [
        {
          key: "history-day",
          label: "history-day",
          overviews: [{ slug: "overview-1", label: this.$t("1549") }],
        },
        {
          key: "card-history",
          label: "card-history",
          overviews: [{ slug: "overview-1", label: this.$t("3") }],
        },
      ];
    },
  },
  methods: {},
  watch: {},
  components: {
    BottomButtons,
  },
};
</script>
<style lang="scss">
</style>