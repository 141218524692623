import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";

const state = () => ({
    index: [],
});

// getters
const getters = {
    existingPopupsWithType: (state) => (type) => {
        return state.index.filter(t => t.type === type);
    }
}

// actions
const actions = {

}
// mutations
const mutations = {
    addPopup(state, popup) {
        state.index.push({...popup,id:_.uniqueId('p-')});
    },
    closeActive(state) {
        state.index.shift();
    },
    closeGroup(state,group){
        state.index = state.index.filter((p)=>p.group !== group);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}