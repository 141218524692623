import _ from "lodash";

// functions
function percentagesFormatter(val){
    let total = _.sum(this.datasets[0].data);
    let per = Math.round((val / total) * 1000) / 10;
    return `${per}%`;
};

function perRawFormatter(val){
    let per = val.toFixed(1);
    return `${per}%`;
};

function hoursFormatter(val, index) {
    let hh = Math.floor(val / 3600);
    let mm = Math.floor((val % 3600) / 60);
    return `${hh}h${mm}m`;
};

const labelFunctions = { percentagesFormatter, hoursFormatter, perRawFormatter}

export { labelFunctions };