<template>
  <div>
    <b-table
      :data="scrapReasonsForMachine"
      :columnConfig="columnConfig"
      primary-key="code"
      sort-key="code"
      sort_dir="asc"
      :single-select="true"
      :deselect="true"
      @selectionChanged="onSelectionChanged"
    >
    </b-table>
      <button
        class="tabbed-next-button"
        @click="$emit('next')"
        :disabled="disableNext"
        :class="{ disabled: disableNext }"
      >
        <i class="fas fa-chevron-right" v-if="!islast"></i>
        <i class="fas fa-check" v-if="islast"></i>
      </button>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import store from "@/store";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  mounted(){
    this.data[this.tab.dataKey].code = null;
    if (this.hasSf254){
      store.dispatch("goods/loadScrapcodesForMachine",{machine_id:this.machine_id});
    }
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      if (selectedRowsData.length > 0 && selectedRowsData[0]) {
        this.data[this.tab.dataKey].code = selectedRowsData[0].code;
        this.data[this.tab.dataKey].note = selectedRowsData[0].description;
      } else {
        this.data[this.tab.dataKey].code = null;
        this.data[this.tab.dataKey].note = null;
      }
    },
  },
  computed: {
    ...mapState({
      scrapReasons: (state) => state.goods.scrapReasons,
    }),
    ...mapGetters({
      machineSpecificScrapForMachine:"goods/machineSpecificScrapForMachine",
      machineHasSf:"machines/machineHasSf"
    }),
    machine_id(){
      return this.$route.params.machine;
    },
    hasSf254(){
      return this.machineHasSf(this.machine_id,'254');
    },
    scrapReasonsForMachine(){
      return this.hasSf254 ? this.machineSpecificScrapForMachine(this.machine_id) : this.scrapReasons;
    },
    columnConfig() {
      return {
        code: {
            class:'h50'
        },
        description: {
            class:'h50'
        },
      };
    },
    selectedScrapReason() {
      return this.scrapReasons.find(
        (r) => r.code === this.data[this.tab.dataKey].code
      );
    },
    disableText() {
      if (
        !this.selectedScrapReason ||
        (this.selectedScrapReason && !this.selectedScrapReason.scrap_text_flag)
      ) {
        return true;
      }
      return false;
    },
    disableNext() {
      return (
        this.data[this.tab.dataKey].code === null ||
        (!this.disableText &&
          (this.data[this.tab.dataKey].note === null ||
            this.data[this.tab.dataKey].note === ""))
      );
    },
  },
};
</script>