import store from "@/store";

const keyboardEvents = ["mouseup", "keydown", "scroll", "mousemove"];
const touchEvents = ["touchstart", "touchend", "touchcancel", "touchmove"];
let lastEvent = new Date().getTime();

const track = () => {
    let t = new Date().getTime();
    if (t > lastEvent + 5000) {
        let type = "keyboard";
        lastEvent = t;
        store.commit("tracking/lastEvent", { type, t });
        //console.log("tracking at t:", t);
    }
}

const startTrackingActivity = () => {
    console.log("starting to track activity");
    lastEvent = new Date().getTime();
    keyboardEvents.forEach((e) => {
        document.addEventListener(e, track);
    });
    touchEvents.forEach((e) => {
        document.addEventListener(e, track);
    });

}

const stopTrackingActivity = () => {
    console.log("stopping tracking activity");
    keyboardEvents.forEach((e) => {
        document.removeEventListener(e, track);
    });
    touchEvents.forEach((e) => {
        document.removeEventListener(e, track);
    });
}

export { startTrackingActivity, stopTrackingActivity }