<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div v-if="!hasSF325 || !hasWorkOrder">
      <h1>
        {{ $t("6342") }}
        <card-menu :items="items"></card-menu>
      </h1>
      <div class="center-widget" v-if="!hasSF325">SF 325 not enabled</div>
      <div class="center-widget" v-else>
        {{ $t("1259") }}
      </div>
    </div>
    <div v-if="hasSF325 && hasWorkOrder && machine" class="max-align">
      <h1>
        {{ $t("Weight Control") }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <router-link
        class="btn btn-success"
        :class="{ 'btn-warning': needsFill }"
        :style="fullSizeButton"
        tag="button"
        :to="$route.path + '/weight-control'"
      >
        {{ machine.new_weight_time }}&nbsp;
      </router-link>
      <div class="flex" style="display: flex; flex: 1" v-if="!needsFill">
        <simple-plot
          :dataset="dataSet"
          :key="numLoads"
          v-if="loaded && dataSet.length && lowerBound !== null"
          :acceptedRange="acceptedRange"
        ></simple-plot>
      </div>
    </div>
  </div>
</template>

<script>
/* description: mustad weight */
import CardMenu from "@/components/card-menu";
import SimplePlot from "@/components/SimplePlot.vue";
import { Pulse } from "@/helpers/heartbeat";
import { getNow } from "@/helpers/dates";
import { Notification } from "@/helpers/notifications";
import { getStorage, setStorage } from "@/helpers/storage";
import { Configuration } from "@/helpers/settings";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import axios from "@/requests/axios";
import _ from "lodash";
import { flattenSingleRow } from "@/requests/requestParcer";
import { execute_json } from "@/helpers/requests";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 175,
      lowerBound: null,
      upperBound: null,
      loaded: false,
      numLoads: 1,
      min: null,
      max: null,
      config: new Configuration(
        "weight-process",
        {
          time_horizon: null,
        },
        this.template
      ),
    };
  },
  mounted() {
    this.loadData();
        this.getBounds();
    store.commit("events/addHandler", {
      event: "startWO",
      handler: this.loadData,
    });
    store.commit("events/addHandler", {
      event: "restartWO",
      handler: this.loadData,
    });
  },
  beforeUnmount() {
    store.commit("events/removeHandler", {
      event: "startWO",
      handler: this.loadData,
    });
    store.commit("events/removeHandler", {
      event: "restartWO",
      handler: this.loadData,
    });
  },
  methods: {
    loadData() {
      if (this.config.isLoaded) {
        this.loaded = false;
        store
          .dispatch("orders/loadWorkOrderTransactions", {
            machine_id: this.machine_id,
            card_no: this.card_no,
            time_horizon: this.config.time_horizon,
          })
          .then(() => {
            this.loaded = true;
          });
        this.numLoads++;
        this.getBounds();
      }
    },
    getBounds() {
      let storageKey =
        "weightbounds_" + this.card_no + "_" + this.machine.part_no;
        //console.log(storageKey);
      if (getStorage(storageKey, false) !== null) {
        let bounds = JSON.parse(getStorage(storageKey, false));
        this.lowerBound = bounds.lower_tolerance;
        this.upperBound = bounds.upper_tolerance;
        this.min = bounds.lower_out_of_bounds;
        this.max = bounds.upper_out_of_bounds;
      } else {
        if (this.card_no && this.machine.op_number && this.hasSF325) {
          execute_json("check_weight_result", {
            card_no: this.card_no,
            part_no: this.machine.part_no,
            op_no: this.machine.op_number,
            machine_id: this.machine_id,
          })
            .then(({ data }) => {
              let {
                lower_tolerance,
                upper_tolerance,
                upper_out_of_bounds,
                lower_out_of_bounds,
              } = data[0];
              this.lowerBound = lower_tolerance;
              this.upperBound = upper_tolerance;
              this.min = lower_out_of_bounds;
              this.max = upper_out_of_bounds;
              setStorage(
                storageKey,
                JSON.stringify({
                  lower_tolerance,
                  upper_tolerance,
                  lower_out_of_bounds,
                  upper_out_of_bounds,
                }),
                false
              );
              this.loading = false;
            })
            .catch((err) => {
              if (this.hasSF325) {
                //let n = Notification.failed(err.statusText);
              }
              this.lowerBound = -1;
              this.upperBound = -1;
            });
        } else {
          this.lowerBound = -1;
          this.upperBound = -1;
        }
      }
    },
  },
  computed: {
    ...mapState({
      transactions: (state) => state.orders.transactions,
      orders: (state) => state.orders.runningOrders,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    hasWorkOrder() {
      return this.machine.work_order_no != "-";
    },
    card_no() {
      return this.machine.card_no;
    },
    machineTransactions() {
      return this.transactions[this.card_no]
        ? this.transactions[this.card_no][this.machine_id]
        : [];
    },
    needsFill() {
      return getNow() > this.machine.new_weight_time;
    },
    dataSet() {
      return this.machineTransactions.map((t) => {
        return {
          t: t.start_time,
          y: +t.part_info4,
        };
      });
    },
    fullSizeButton() {
      if (this.needsFill) {
        return {
          flex: 1,
          display: "flex",
          "justify-content": "center",
          "flex-direction": "column",
        };
      }
      return {};
    },
    acceptedRange() {
      return {
        begin: this.lowerBound,
        end: this.upperBound,
        min: this.min,
        max: this.max,
      };
    },
    hasSF325() {
      return this.machineHasSf(this.machine_id, "325");
    },
    items() {
      let items = [];
      items.push({ header: this.$t("1329") });
      items.push({
        tlabel: "448",
        handler: () => {
          this.config.setValues({ time_horizon: "24h" });
        },
        selected: this.config.time_horizon == "24h",
      });
      items.push({
        tlabel: "447",
        handler: () => {
          this.config.setValues({ time_horizon: "today" });
        },
        selected: this.config.time_horizon === "today",
      });
      items.push({
        tlabel: "245",
        handler: () => {
          this.config.setValues({ time_horizon: "current_shift" });
        },
        selected: this.config.time_horizon === "current_shift",
      });
      items.push({
        label: "-",
        handler: () => {
          this.config.setValues({ time_horizon: null });
        },
        selected: this.config.time_horizon === null,
      });
      return items;
    },
    timeHorizon() {
      return this.config.time_horizon;
    },
    configLoaded() {
      return this.config.isLoaded;
    },
  },
  watch: {
    timeHorizon() {
      this.loadData();
    },
    configLoaded() {
      this.loadData();
    },
  },

  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
    SimplePlot,
  },
};
</script>

<style>
</style>