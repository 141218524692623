<template>
  <div>
    <Loading v-if="loading" />
    <div v-else>
      <p v-if="orders !== null">
        <span v-if="orders.length > 0 && orders[0].status != '0'">
          <c-table
            :data="orders"
            primary-key="card_no"
            config-string="planned_orders_tab"
            @selectionChanged="onSelectionChanged"
            :teleport="true"
          >
          </c-table>
        </span>
        <span v-else>
          {{ $t("1259") }}
        </span>
      </p>
      <p v-else>
        {{ $t("1817") }}
      </p>
      <button
        class="tabbed-next-button"
        @click="$emit('next')"
        :disabled="disableNext"
        :class="{ disabled: disableNext }"
      >
        <i class="fas fa-chevron-right" v-if="!islast"></i>
        <i class="fas fa-check" v-if="islast"></i>
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import { Pulse } from "@/helpers/heartbeat";
import _ from "lodash";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      selectedRows: [],
      loading: false,
      pulse: null,
    };
  },
  mounted() {
     this.pulse = new Pulse({
      action: "orders/loadPlannedOrdersForMachine",
      parameters: {
        machine_id: this.machine_id,
        erp_resource: this.machine.erp_resource,
        erp_connection_type: this.machine.erp_connection_type,
      },
      interval: 180,
      primaryKey: "machine_id",
    });
    this.data[this.tab.dataKey].selected = null;
    if (
      this.selectedOrderIsPlanned &&
      this.data[this.tab.dataKey].jumped === undefined
    ) {
      this.data[this.tab.dataKey].selected = this.selectedOrder;
      this.data[this.tab.dataKey].jumped = true;
      this.$emit("next");
    } else if (
      this.orders && 
      this.orders.length === 1 &&
      this.data[this.tab.dataKey].jumped === undefined
    ) {
      this.data[this.tab.dataKey].selected = this.orders[0];
      this.data[this.tab.dataKey].jumped = true;
      this.$emit("next");
    }
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      plannedOrders: (state) => state.orders.plannedOrders,
      selectedOrder: (state) => state.selections.order,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      canUser: "permissions/canUser",
    }),
    selectedOrderIsPlanned() {
      return this.orders !== null
        ? this.orders.findIndex(
            (o) => o.card_no === this.selectedOrder.card_no
          ) > -1
        : false;
    },
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    orders() {
      return this.plannedOrders[this.machine_id] || null;
    },
    disableNext() {
      return this.data[this.tab.dataKey].selected === null;
    },
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length > 0 && this.selectedRows[0]) {
        this.data[this.tab.dataKey].selected = this.selectedRows[0];
      } else {
        this.data[this.tab.dataKey].selected = null;
      }
    },
  },
  watch: {},
};
</script>