//import  _  from "lodash";
import axios from "@/requests/axios";
import { flattenMultiRow } from '@/requests/requestParcer';
import { setStorage, getStorage, checkStorage } from "@/helpers/storage";
import { getNow } from "@/helpers/dates";
import { getTransitionRawChildren } from "vue";
import { execute_json } from "@/helpers/requests";

const state = () => ({
    primaHistory: {

    },
    primaHistoryByCard: {

    },
    scrapHistory: {

    },
    scrapReasons: [],
    machineSpecificScrap:{

    },
    scrapReasonsLoaded: false
});

// getters
const getters = {
    machineSpecificScrapForMachine : (state) => (machine_id) => {
        return state.machineSpecificScrap?.[machine_id] || [];
    }
}

// actions
const actions = {
    loadPrimaHistoryForMachine({ commit, rootState }, { machine_id }) {
        return new Promise((resolve, reject) => {
            execute_json("get_good_quantity_transactions", {
                machine_id,
                date: getNow()
            })
                .then(({ data }) => {
                    commit('setPrimaHistoryMachine', {
                        machine_id,
                        history: data
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },
    loadPrimaHistoryForCardNo({ commit, rootState }, { machine_id,card_no }) {
        return new Promise((resolve, reject) => {
            execute_json("get_good_quantity_transactions", {
                machine_id,
                card_no
            })
                .then(({ data }) => {
                    commit('setPrimaHistoryCardNo', {
                        card_no,
                        history: data
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },
    loadScrapHistoryForMachine({ commit, rootState }, { card_no }) {
        return new Promise((resolve, reject) => {
            execute_json("get_scrap_history", {
                card_no,
            })
                .then(({ data }) => {
                    commit('setScrapHistoryMachine', {
                        card_no,
                        history: data
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    },
    loadScrapcodes({ commit, rootState }) {
        if (state.scrapReasonsLoaded) {
            return new Promise((resolve) => {
                resolve();
            })
        } else {
            if (!checkStorage(rootState.auth.company_id + "_scrap_reasons_loaded")) {
                return new Promise((resolve, reject) => {
                    execute_json("get_scrap_reasons")
                        .then(({ data }) => {
                            //console.log(data,data[0]);
                            commit('setScrapReasons', {
                                data: data,
                                company_id: rootState.auth.company_id
                            });
                            resolve();
                        })
                        .catch((err) => {
                            reject(err);
                        })
                });
            } else {
                return new Promise((resolve) => {
                    commit('setScrapReasons', {
                        data: getStorage(rootState.auth.company_id + "_scrap_reasons_loaded"),
                        company_id: rootState.auth.company_id
                    });
                    resolve();
                })
            }
        }
    },
    loadScrapcodesForMachine({commit,rootState},{machine_id}){
        return new Promise((resolve, reject) => {
            execute_json("get_scrap_reasons",{machine_id})
                .then(({ data }) => {
                    //console.log(data,data[0]);
                    commit('setScrapReasonsForMachine', {
                        data,
                        machine_id
                    });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        });
    }
}
// mutations
const mutations = {
    setPrimaHistoryMachine(state, { machine_id, history }) {
        if (history.length === 1 && history[0].call_code !== undefined) {
            history = [];
        }
        state.primaHistory[machine_id] = history;
    },
    setPrimaHistoryCardNo(state, { card_no, history }) {
        if (history.length === 1 && history[0].call_code !== undefined) {
            history = [];
        }
        state.primaHistoryByCard[card_no] = history;
    },
    setScrapHistoryMachine(state, { card_no, history }) {
        if (history.length === 1 && history[0].call_code !== undefined) {
            history = [];
        }
        state.scrapHistory[card_no] = history;
    },
    setScrapReasons(state, { data, company_id }) {
        setStorage(company_id + "_scrap_reasons_loaded", data);
        state.scrapReasons = data;
        state.scrapReasonsLoaded = true;
    },
    setScrapReasonsForMachine(state,{data,machine_id}){
        state.machineSpecificScrap[machine_id] = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}