<template>
  <div id="dispatcher"></div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { Pulse } from "@/helpers/heartbeat";
import { Notification } from "@/helpers/notifications";
import { removeStorage } from "@/helpers/storage";
import { execute_json } from "@/helpers/requests";
import _ from "lodash";
import store from "@/store";

export default {
  data() {
    return {
      interval: 5,
      pulse: null,
      lastLength: 0,
      //timePeriod: 60000,
      eventStack: [],
      redirectTimeout: null,
    };
  },
  mounted() {
    this.pulse = new Pulse({
      action: "events/loadEvents",
      interval: this.interval,
    });
    this.handleStack();
  },
  methods: {
    handleNewEvents(events) {
      if (events.length > this.lastLength) {
        for (let i = this.lastLength; i < events.length; i++) {
          //check for duplicates down the line
          let shouldHandleEvent = true;
          for (let j = i + 1; j < events.length; j++) {
            if (
              events[j].event_type === events[i].event_type &&
              events[j].event_message === events[i].event_message
            ) {
              shouldHandleEvent = false;
              break;
            }
          }
          if (shouldHandleEvent) {
            this.eventStack.push(i);
            //this.handleEvent(events[i]);
          }
        }
        this.lastLength = events.length;
      }
    },
    handleStack() {
      if (this.eventStack.length) {
        setTimeout(this.handleStack, 0);
        let index = this.eventStack.shift();
        this.handleEvent(this.events[index]);
        //console.log("popping event out of the stack");
      } else {
        setTimeout(this.handleStack, 10);
      }
    },
    handleEvent(event) {
      if (this.handlers[event.event_type] !== undefined) {
        for (let i = 0; i < this.handlers[event.event_type].length; i++) {
          let handler = this.handlers[event.event_type][i];
          let timePassed =
            new Date().getTime() - new Date(event.trans_time).getTime();
          if (
            this.timeouts[event.event_type] &&
            timePassed > this.timeouts[event.event_type]
          ) {
            return true;
          }
          if (typeof handler === "string") {
            this[handler](event, { timePassed });
          } else {
            handler(event);
          }
        }
      } else {
        this.genericHandler(event);
      }
    },
    /* event specific handlers */
    stopWO(event, { timePassed }) {
      //alert("stop WO");
      let [machine_id, ...rest] = event.event_message.split(";");
      if (
        !this.$route.params.machine ||
        this.$route.params.machine !== machine_id
      ) {
        /*
        let n = Notification.message({
          message: "WO stopped on " + machine_id,
          theader: "758",
        }).linkEvent(event);
        */
      }
      let machine = this.machineById(machine_id);
      //store.dispatch("parts/Sf383fetch", { machine_id });
      if (machine) {
        store.dispatch("updates/clearUpdatesByErpResource", machine);
      }
      if (this.selectedOrder?.machine_id == machine_id){
        store.commit("selections/unset","order");
      }
    },
    startWO(event, { timePassed }) {
      let [machine_id, ...rest] = event.event_message.split(";");
      if (
        !this.$route.params.machine ||
        this.$route.params.machine !== machine_id
      ) {
        /*
        let n = Notification.request({
          theader: "760",
          playSound: true,
          message: "WO started on " + machine_id,
        })
          .linkEvent(event)
          .ok({
            closeAfter: 10000,
          });
          */
      }
      let machine = this.machineById(machine_id);
      //store.dispatch("parts/Sf383fetch", { machine_id });
      if (machine) {
        store.dispatch("updates/clearUpdatesByErpResource", machine);
        /*
        store.dispatch("orders/loadRunningOrderForMachine", {
          machine_id,
          reload: false,
        });
        store.dispatch("orders/loadPlannedOrdersForMachine", {
          machine_id,
          erp_resource: machine.erp_resource,
          erp_connection_type: machine.erp_connection_type,
        });
        */
      }
      if (this.selectedOrder?.machine_id == machine_id){
        store.commit("selections/unset","order");
      }
    },
    UpdateSF(event, { timePassed }) {
      if (event.machine_id) {
        removeStorage(
          this.cid + "_special_functions_machine_" + event.machine_id
        );
        store.dispatch("machines/loadSpecialFunctionsForMachine", {
          machine_id: event.machine_id,
        });
      } else {
        store.dispatch("machines/clearSpecialFunctions");
      }
    },
    UpdateEtouchParameters(event, { timePassed }) {
      if (event.machine_id) {
        removeStorage(this.cid + "_touch_config_machine_" + event.machine_id);
        store.dispatch("machines/loadTouchConfigForMachine", {
          machine_id: event.machine_id,
        });
      } else {
        store.dispatch("machines/clearTouchConfigs");
      }
    },
    UpdateStopReasons(event, { timePassed }) {
      store.dispatch("stopcodes/loadStopCodesIndex", { force: true });
    },
    UpdateMachineStopReasons(event, { timePassed }) {
      if (event.machine_id) {
        store.dispatch("stopcodes/loadStopcodesForMachine", {
          machine_id: event.machine_id,
          reloadCachedValues: true,
        });
      } else {
        store.dispatch("stopcodes/clearStopcodes");
      }
    },
    ManAtMachineChange(event, { timePassed }) {
      if (event.machine_id) {
        store.dispatch("machines/loadSignedInForMachine", event.machine_id);
      }
    },
    PresentFileToUser(event, { timePassed }) {
      let msg = JSON.parse(event.event_message);
      let goToFile = this.goToFile;
      if (
        msg.username.toLowerCase() === this.username.toLowerCase() ||
        msg.username === "all"
      ) {
        //handle
        let record = event.record_link;
        let n = Notification.message({
          header: msg.report_setting,
          playSound: true,
          message: "New report created.",
          selfClosing: false,
          actions: [
            {
              label: "view",
              handler: function () {
                return goToFile(record);
              },
            },
          ],
        }).linkEvent(event);
      }
    },
    goToFile(record) {
      //console.warn(this.$route);
      if (this.$route.path.includes("/interface/")) {
        this.$router.push(
          "/interface/" + this.$route.params.machine + "/files/" + record
        );
      } else {
        this.$router.push("/files/" + record);
      }
    },
    UpdateUserSettings(event, { timePassed }) {
      //
      let silent = true; // timePassed > 60000;
      let msg = JSON.parse(event.event_message);
      if (msg.system_function.includes("dashboard_widget_")) {
        //console.log("no event updates");
        return true;
      }
      if (msg.system_function.includes("web_visual_analysis_")) {
        //console.log("no event updates");
        return true;
      }
      if (msg.system_function.includes("web_widget_settings")) {
        //console.warn("web_widget_setting update!");
        store.dispatch("settings/loadConfigs", {
          sf: msg.system_function,
          force: true,
        });
        return true;
      }
      if (msg.system_function === "web-permissions") {
        this.UpdateWebPermission(event, { timePassed, silent });
      } else {
        let n = Notification.request({
          playSound: false,
          silent,
          message: "Loading a new config",
        }).linkEvent(event);
        store
          .dispatch("config/ForceConfigFromEvent", msg)
          .then(() => {
            n.ok();
          })
          .catch((e) => {
            n.fail();
          });
      }
    },
    UpdateWebPermission(event, { timePassed, silent }) {
      //

      let msg = JSON.parse(event.event_message);
      let n = Notification.request({
        playSound: true,
        silent,
        header: "Permission changed",
        actions: [{ label: "view", path: "/admin/security" }],
        message: msg.system_function + ": " + msg.function_variable,
      }).linkEvent(event);
      store
        .dispatch("permissions/loadPermission", msg.function_variable)
        .then(() => {
          n.ok();
        })
        .catch((e) => {
          n.fail();
        });
    },
    genericHandler(event) {
      console.log(
        "%c unhandled event " + event.event_type,
        "color:green;font-size:16px;background:lightgray;"
      );
    },
    UpdateStopReasonGroups(event) {
      store.dispatch("groups/forceLoadGroups");
    },
    StartedWorkOrderLocally(event) {
      //console.warn("an order was started, BY YOU, JUST NOW",event);
      if (this.machineHasSf(event.machine_id, "45")) {
        //console.warn("YOU HAVE SF 45 and an order just started!");
        store.commit("popups/addPopup", {
          type: "operation-text",
          data: event,
        });
      }
    },
    CheckForOpText({ machine_id, data }) {
      if (this.machineHasSf(machine_id, "43")) {
        console.warn("TODO: get op text", machine_id, data);
        data.forEach((order) => {
          store.dispatch("operations/GetOperationTextForCardNo", order);
        });
      }
    },
    CheckSf368Status({ machine_id, data }) {
      if (
        this.$route?.params?.machine == machine_id &&
        this.machineHasSf(machine_id, "368")
      ) {
        let unconfirmedOrders = data.filter((o) => {
          return o.confirmed_materials === false;
        });
        console.log(
          "TODO: SF368 check",
          machine_id,
          data,
          unconfirmedOrders,
          this.$route?.params
        );
        if (unconfirmedOrders.length > 0) {
          let machine = this.machineById(machine_id);
          let popups = this.existingPopupsWithType("confirm-materials");
          console.log("we might need a popup");
          unconfirmedOrders.forEach((order) => {
            if (popups.some((p) => p?.data?.order?.card_no === order.card_no)) {
              //check if there's a popup open for this specific order.
              return true;
            }
            store
              .dispatch("materials/loadMaterialList", {
                machine: order,
              })
              .then((data) => {
                if (
                  data.filter((m) => m.line_position === machine.line_position)
                    .length
                ) {
                  popups = this.existingPopupsWithType("confirm-materials");
                  if (
                    popups.some(
                      (p) => p?.data?.order?.card_no === order.card_no
                    )
                  ) {
                    //check if there's a popup open for this specific order.
                    return true;
                  }
                  store.commit("popups/addPopup", {
                    type: "confirm-materials",
                    data: {
                      order,
                    },
                  });
                } else {
                  //silent confirm
                  execute_json("workorder_materials", {
                    card_no: order.card_no,
                    p_order: order.p_order,
                    confirmed_materials: true,
                    feature: "update_owo_confirmed_materials",
                  });
                  console.log(
                    "don't need a popup, silently confirming",
                    order,
                    data
                  );
                }
              });
          });
          //show popup if it isn't showing!
        }
      }
    },
    HandleInactiveUser(event) {
      //console.warn("inactive user", event);
      if (
        this.$route.name != "machine-interface" &&
        this.$route?.params?.machine == event.machine_id
      ) {
        this.redirectTimeout = setTimeout(
          (() => {
            this.$router.push("/interface/" + event.machine_id);
          }).bind(this),
          3500
        );
        document.addEventListener(
          "click",
          () => {
            clearTimeout(this.redirectTimeout);
          },
          { once: true }
        );
        let n = Notification.message({
          header: "Redirecting due to inactivity...",
          playSound: false,
          message: "Inactivity detected.",
          selfClosing: true,
          actions: [
            {
              label: "stay",
              handler: () => {
                clearTimeout(this.redirectTimeout);
              },
            },
          ],
        });
      }
    },
  },
  computed: {
    ...mapState({
      cid: (state) => state.auth.company_id,
      username: (state) => state.auth.username,
      events: (state) => state.events.index,
      handlers: (state) => state.events.handlers,
      timeouts: (state) => state.events.timeouts,
      selectedOrder: state => state.selections.order
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      existingPopupsWithType: "popups/existingPopupsWithType",
    }),
  },
  watch: {
    events: {
      deep: true,
      handler: function (events) {
        this.handleNewEvents(events);
      },
    },
  },
  components: {
    Notification,
  },
};
</script>