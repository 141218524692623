import { getStorage, setStorage } from "../../helpers/storage";

const state = {
  isOpen: false,
  isActive: false,
  lastLocationType: getStorage("lastLocType") || null,
  lastMine: false,
  lastLocation: getStorage("lastLoc") || null
};

const getters = {
};

const actions = {
};

const mutations = {
  open(state) {
    state.isOpen = true;
  },
  close(state) {
    state.isOpen = false;
  },
  toggle(state) {
    state.isOpen = !state.isOpen;
  },
  setLastLocationType(state, loctype) {
    if (loctype !== "undefined" && loctype !== undefined && loctype !== 'mine') {
      if (state.lastLocationType !== loctype) {
        console.log("%c " + loctype + " has been set as active location type.", "color:white;background:blue;");
      }
      state.lastLocationType = loctype;
      setStorage("lastLocType", loctype);
    }
    state.lastMine = loctype === 'mine' ? true : false;
  },
  setLastLocation(state, loc) {
    if (loc !== "undefined" && loc !== undefined && loc !== 'all') {
      if (state.lastLocation !== loc) {
        console.log("%c " + loc + " has been set as active location.", "color:white;background:blue;");
      }
      state.lastLocation = loc;
      setStorage("lastLoc", loc);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
