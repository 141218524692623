const linePlugin = {
    yAxisKey(chart) {
        let scales = Object.keys(chart.scales);
        return scales?.find((key) => { return key.indexOf('y-axis') === 0 });
    },

    xAxisKey(chart) {
        let scales = Object.keys(chart.scales);
        return scales?.find((key) => { return key.indexOf('x-axis') === 0 });
    },

    getYAxis(chart) {
        let yAxisKey = this.yAxisKey(chart);
        if (yAxisKey) {
            const yaxis = chart.scales[yAxisKey];
            return yaxis;
        }
        return null;
    },

    getXAxis(chart) {
        let xAxisKey = this.xAxisKey(chart);
        if (xAxisKey) {
            const yaxis = chart.scales[xAxisKey];
            return yaxis;
        }
        return null;
    },


    getHorizontalLinePosition: function (chart, y) {
        //const ctx = chart.chart.ctx;
        return this.getYAxis(chart)?.getPixelForValue(y)
    },

    drawHorizontalLine: function (chart, y, color) {
        const lineLeftOffset = this.getHorizontalLinePosition(chart, y);
        if (lineLeftOffset && chart.scales && chart.chart) {
            const scale = chart.scales[this.xAxisKey(chart)];
            const ctx = chart.chart.ctx;
            // render vertical line
            ctx.beginPath();
            ctx.strokeStyle = color;
            ctx.moveTo(scale.left, lineLeftOffset);
            ctx.lineTo(scale.right - 16, lineLeftOffset);
            ctx.stroke();
        }
    },
    drawYLabel: function (chart, y, color) {
        const lineLeftOffset = this.getHorizontalLinePosition(chart, y);
        if (lineLeftOffset && chart.scales && chart.chart) {
            const ctx = chart.chart.ctx;
            const scale = chart.scales[this.xAxisKey(chart)];
            ctx.fillStyle = '#ffffff';
            ctx.font = "15px Avenir";
            ctx.textAlign = 'right';
            ctx.fillText(y, scale.right, lineLeftOffset + 5);
        }
    },

    old: function () {
        console.log(lineLeftOffset, chart.scales, Object.keys(chart.scales));
        if (lineLeftOffset && chart.scales && chart.chart) {
            console.log(chart.scales, Object.keys(chart.scales));
            const scale = chart.scales[this.xAxisKey(chart)];
            console.log(scale, "left?top?right?bottom?");
            const ctx = chart.chart.ctx;

            // render vertical line
            ctx.beginPath();
            ctx.strokeStyle = '#ff0000';
            ctx.moveTo(scale.left, lineLeftOffset);
            ctx.lineTo(scale.right - 15, lineLeftOffset);
            ctx.stroke();

            // write label
            ctx.fillStyle = "#ff0000";
            ctx.textAlign = 'center';
            ctx.fillText('MY TEXT', lineLeftOffset, (scale.bottom - scale.top) / 2 + scale.top);
        }
    },
    afterDatasetsDraw: function (chart, easing) {
        //console.log(chart);
        if (chart.config?.lineLimits) {
            chart.config.lineLimits.horizontal?.forEach(
                line => {
                    this.drawHorizontalLine(chart, line.y, line.color);
                    this.drawYLabel(chart, line.y, line.color);
                }
            )
            //this.drawHorizontalLine(chart,20,'#ff0000');
            //this.drawHorizontalLine(chart,47,'#ffff00');
        }
    }
}

export { linePlugin }