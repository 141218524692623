import { execute_json } from "@/helpers/requests";
import { iconize } from "@/helpers/comparisons";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import _ from "lodash";
import { getStorage, setStorage, checkStorage } from "../../helpers/storage";


const state = () => ({
    index: {
    },
    loaded: {},
    loading: false,
    machinePriorities:{

    },
    machinePrioritiesLoaded:false,
    MPloading:false
});

// getters
const getters = {
    config: (state) => (sf, fv) => {
        return state.index?.[sf][fv] || {};
    }
}

// actions
const actions = {
    loadConfigs({ commit, state, dispatch, rootState }, { sf, force }) {
        return new Promise((resolve, reject) => {
            if (sf === undefined) {
                reject();
            } else if (state.loaded[sf] && !force) {
                //console.warn("skipping load due to existing load!");
                resolve();
            } else {
                if (!state.loading) {
                    commit("setLoad",true);
                    //console.warn("loading user settings!");
                    execute_json("get_user_settings", {
                        username: "Default",
                        user_name: "Default",
                        system_function: sf
                    })
                        .then(({ data }) => {
                            //console.warn(sf, data);
                            commit("setConfig", { data, sf });
                            commit("setLoad",false);
                            resolve();
                        })
                        .catch((err) => {
                            console.log(err);
                            reject(err);
                        });
                }else{
                    resolve();
                }
            }
        })
    },

    saveConfig({ commit, state, dispatch, rootState }, { sf, fv, setting }) {
        return new Promise((resolve, reject) => {
            execute_json("save_user_setting", {
                user_name: "Default",
                username: "Default",
                user_name: "Default",
                system_function: sf,
                function_variable: fv,
                user_setting: JSON.stringify(setting)
            })
                .then(() => {
                    resolve();
                }).catch(() => { reject() })
        })
    },

    loadMachinePriorities({ commit, state, dispatch, rootState }, { force }) {
        return new Promise((resolve, reject) => {
            if (state.machinePrioritiesLoaded && !force) {
                //console.warn("skipping load due to existing load!");
                resolve();
            } else {
                if (!state.MPloading) {
                    commit("setMPLoad",true);
                    //console.warn("loading user settings!");
                   execute_json("machine_priority",{feature:"get"})
                        .then(({ data }) => {
                            //console.warn(sf, data);
                            commit("setMPConfig",  data );
                            commit("setMPLoad",false);
                            resolve();
                        })
                        .catch((err) => {
                            console.log(err);
                            reject(err);
                        });
                }else{
                    resolve();
                }
            }
        })
    },

}
// mutations
const mutations = {
    setConfig(state, { data, sf }) {
        console.log("setting config //should only happen once per save!");
        let dataObj = {};
        data.forEach(d => {
            dataObj[d.function_variable] = JSON.parse(d.string_value);
        });
        state.index[sf] = dataObj;
        state.loaded[sf] = true;
    },
    setLoad(state,bool){
        state.loading = bool;
    },
    localSave(state, { sf, fv, setting }) {
        if (state.index[sf] === undefined){
            state.index[sf] = {};
        }
        state.index[sf][fv] = setting;
    },
    setMPLoad(state,bool){
        state.MPloading = bool;
    },
    setMPConfig(state,data){
        data.forEach(mp => {
            state.machinePriorities[mp.code] = {
                symbol:mp.symbol,
                description:mp.description,
                icon:iconize(mp.symbol),
                machine_priority:mp.code
            }
        })
        state.machinePrioritiesLoaded = true;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}