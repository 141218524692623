<template>
  <div class="popup-wrap" @click.self="back">
    <div class="popup">
      <h1>{{ $t(tquestion) }}</h1>
      <p v-if="tmessage">{{$t(tmessage)}}</p>
      <div>
        <button class="btn btn-success" @click="accept">
          <i class="fas fa-check"></i>{{ $t(taccept) }}
        </button>
        <button class="btn btn-danger" @click="reject">
          <i class="fas fa-check"></i>{{ $t(treject) }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import BottomButtons from "@/components/bottom-buttons";

export default {
  emits:['close','reject','accept'],
  props: {
    tquestion: {
      required: true,
    },
    tmessage:{
      default:null
    },
    taccept: {
      default: "1514",
    },
    treject: {
      default: "484",
    },
  },
  computed: {
    ...mapState({
      path: (state) => state.auth.path,
      bottomShouldShow: (state) => state.bottomnav.shouldShow,
    }),
  },
  methods: {
    back() {
      this.$emit('close');
    },
    reject() {
      this.$emit('reject');
    },
    accept() {
      this.$emit('accept');
    },
  },
  components: {
    BottomButtons,
  },
};
</script>
