<template>
  <div class="popup">
    <h1>{{ data.theader ? $t(data.theader) : data.header }}</h1>
    <br/>
    <input type="text" class="form-control" v-model="value" @keyup.enter="submitAndClose" ref="input"/>
    <br/>
    <button class="btn btn-success" @click="submitAndClose">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapState, mapGetters } from "vuex";
import { secondsToHhMm } from "@/helpers/dates";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";
import { flattenSingleRow } from "@/requests/requestParcer";
import store from "@/store";

export default {
  props: ["data"],
  emits: ["close"],
  data() {
    return {
      employeeNotOk: false,
      loading: true,
      value: null,
    };
  },
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submitAndClose() {
      this.data.handler(this.value);
      this.close();
    },
    closeAndSave() {},
    calcSplitTime() {},
  },
  computed: {
    ...mapState({
      inversed: (state) => state.groups.inversed,
      lastUsedStops: (state) => state.stopcodes.lastStopReasons,
    }),
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
      signedInByMachine: "machines/signedInByMachine",
      configByMachine: "machines/configByMachine",
      stopCodesByMachine: "stopcodes/stopCodesByMachine",
      machineById: "machines/byId",
      runningByMachine: "orders/runningByMachine",
    }),
    machine_id() {
      return this.data.machine_id;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
  },
};
</script>