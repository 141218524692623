<template>
  <div class="stopcode-singular">
    <input
      type="text"
      v-model="extra_info_1"
      placeholder="Extra info"
      class="white-text"
      v-if="wantsNumericalInput"
    />
    <input
      type="text"
      v-model="extra_info_1"
      placeholder="Extra info"
      class="white-text"
      v-if="wantsTextInput"
    />
    <keyboard
      v-model="extra_info_1"
      v-if="wantsTextInput || wantsNumericalInput"
    />
    <p>
      {{ $t("1") }} {{ $route.params.machine }} : {{ activity.description }}
    </p>

    <div class="buttons-wrap">
      <a
        href="#"
        class="router-button"
        @click.prevent="createActivity(false)"
        :disabled="!hasInputNeeded"
        :class="{ disabled: !hasInputNeeded }"
      >
        <span v-if="!saving"
          >{{ $t("4670") }} <i class="fas fa-arrow-to-left"></i
        ></span>
        <loading v-else />
      </a>
      <a
        href="#"
        class="router-button"
        @click.prevent="createActivity(true)"
        :disabled="!hasInputNeeded"
        :class="{ disabled: !hasInputNeeded }"
      >
        <span v-if="!saving"
          >{{ $t("4670") }} <i class="fas fa-running"></i
        ></span>
        <loading v-else />
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Notification } from "@/helpers/notifications";
import axios from "@/requests/axios";
import store from "@/store";

export default {
  props: {},
  data() {
    return {
      saving: false,
      extra_info_1: "",
    };
  },
  setup() {},
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      activity: (state) => state.bottomnav.data,
      path: (state) => state.auth.path,
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    routerlinks() {
      return {};
    },
    wantsTextInput() {
      return this.activity.extra_info_1_type == "1";
    },
    wantsNumericalInput() {
      return this.activity.extra_info_1_type == "0";
    },
    hasInputNeeded() {
      return (
        !this.wantsNumericalInput ||
        (this.wantsNumericalInput && this.extra_info_1 != "")
      );
    },
  },
  methods: {
    checkNumeric() {
      let info1 = this.extra_info_1.replace(/\D/g, "");
      if (info1 !== this.extra_info_1) {
        this.extra_info_1 = info1;
      }
    },
    createActivity(run) {
      console.log("saving");
      if (!this.saving && this.hasInputNeeded) {
        this.saving = true;
        let note = Notification.request({
          message: "Creating activity...",
        });
        let d = new Date();
        d.setMinutes(d.getMinutes() - 5);
        let endDate = new Date();
        endDate.setMinutes(
          endDate.getMinutes() + (+this.activity.duration || 0)
        );
        axios
          .post("balthzar_start_internal_activity_21_12_0", {
            tool_service_counter: "-1",
            output_on_ok_create_activity: "-1",
            output_on_ok_create_after: "-1",
            output_on_deviation_create_activity: "-1",
            output_on_deviation_create_after: "-1",
            multiple_order_setting: "0",
            output_on_create_create_activity: "-1",
            output_on_create_create_after: "0",
            output_on_create_enter_time: false,
            output_on_create_mail_address: "",
            output_on_create_mail_title: "",
            output_on_create_mail_message: "",
            output_on_create_action_list: "-1",
            output_quantity_to_create_on_create: "1",
            output_on_ok_message_group: "",
            output_on_deviation_message_group: "",
            output_on_cancel_message_group: "",
            output_on_create_message_group: "",
            ...this.activity,
            special_function: "-1",
            other_record_link: "-1",
            batch_record_link: "-1",
            event_record_no: "-1",
            op_no: "-1",
            activity_company_id: this.activity.company_id,
            machine_id: this.machine_id,
            create_date: d.toBalthzarTime(),
            due_date: endDate.toBalthzarTime(),
            use_checklist: true,
            cancel_date: endDate.toBalthzarTime(),
            created_by: this.auth.username,
            extra_info_1: this.extra_info_1,
            quantity_to_create: 1,
          })
          .then(({ data }) => {
            let record_no = (data.int ? data.int._ : null) || null;
            console.warn(record_no);
            if (run) {
              store.commit("activities/setLast", record_no);
            }
            this.saving = false;
            this.$router.push(
              run
                ? this.$route.path.replace("/create-activity", "/run-activity")
                : this.path
            );
            note.ok({
              message: "activity created",
            });
          })
          .catch((err) => {
            console.log(err);
            this.saving = false;
            note.fail({
              message: "something went wrong",
            });
          });
      }
    },
  },
  watch: {
    activity: {
      deep: true,
      handler: function () {
        this.extra_info_1 = "";
      },
    },
    extra_info_1() {
      if (this.wantsNumericalInput) {
        this.checkNumeric();
      }
    },
  },
  components: {
    //DxButton,
    //DxTextBox
  },
};
</script>

<style lang="scss">
</style>
