//import  _  from "lodash";
import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";
import { flattenMultiRow } from '@/requests/requestParcer';

const state = () => ({
    index: {

    },
    history: {

    }
});

// getters
const getters = {

}

// actions
const actions = {
    loadCavitiesForMachine({ commit, rootState }, { machine_id, tool_id }) {
        return new Promise((resolve, reject) => {
            execute_json("get_work_order_tool_inserts", {
                machine_id: machine_id,
                tool_id: tool_id
            }).then(({ data }) => {
                //console.log("data from work order tool inserts",data);
                commit('setCavitiesForMachine', {
                    machine_id,
                    data
                });
                resolve();
            }).catch((err) => {
                reject(err);
            })
        });
    },
    loadHistoryForTool({ commit, rootState }, { machine_id, tool_id }) {
        return new Promise((resolve, reject) => {
            execute_json("get_tool_insert_log_history", {
                machine_id: machine_id,
                tool_id: tool_id
            }).then(({ data }) => {
                //console.log("data from work order tool inserts",data);
                commit('setHistoryForTool', {
                    tool_id,
                    data: data
                });
                resolve();
            }).catch((err) => {
                reject(err);
            })
        });
    },
    updateOrderAfterCavityChange({ }, { machine_id, card_no }) {
        return new Promise((resolve, reject) => {
            execute_json("restart_work_order_with_new_data", {
                machine_id,
                card_no,
                feature: "cavity_reduction"
            }).then(({ data }) => {
                console.log("restart data", data);
                resolve();
            }).catch((err) => {
                reject(err);
            })
        });
    }
}
// mutations
const mutations = {
    setCavitiesForMachine(state, { machine_id, data }) {
        state.index[machine_id] = data;
    },
    setHistoryForTool(state, { tool_id, data }) {
        state.history[tool_id] = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}