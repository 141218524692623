import { mapGetters, mapState } from "vuex"
import { Pulse } from "./heartbeat";
import CardMenu from "@/components/card-menu";
import { Configuration } from "./settings";

const InterfaceComponent = function (data, injections = []) {
    let df = data.data || function () { return {} };
    let dfCheck = df.bind(this)();
    var hasConfig = false;
    data.data = function () {
        let r = {
            pulse: null,
            injections,
            ...dfCheck,
        };
        if (r.config && r.configString) {
            r.config = new Configuration(
                r.configString,
                r.config,
                this.template
            )
        }
        return r;
    }
    if (dfCheck.config && dfCheck.configString) {
        hasConfig = true;
    }
    if (df.bind(this)().configString && data.props?.template === undefined) {
        if (data.props === undefined) {
            data.props = {};
        }
        data.props.template = {
            required: true,
        };
    }

    let computed = {
        ...mapState({
            ...data.state || {}
        }),
        ...mapGetters({
            machineById: "machines/byId",
            machineHasSf: "machines/machineHasSf",
            canUser: "permissions/canUser",
            plannedByMachine: "orders/plannedByMachine",
            runningByMachine: "orders/runningByMachine",
            configByMachine: "machines/configByMachine",
            notificationSettingsBymachine: "machines/notificationSettingsBymachine",
            ...data.getters || {},
        }),
        machine() {
            return this.machineById(this.machine_id);
        },
        machineConfig() {
            return this.configByMachine(this.machine_id);
        },
        items() {
            return [];
        },
        ...data.computed || {}
    };
    if (data.props?.machine_id === undefined) {
        computed.machine_id = function () {
            return this.$route.params.machine;
        };
    }
    if (hasConfig) {
        computed.configIsLoaded = function () {
            return this.config.isLoaded;
        }
    }
    data.computed = computed;

    let methods = {
        hasSf(sf) {
            return this.machineHasSf(this.machine_id, sf);
        },
        loadData() {
            //console.log("local load data got called.");
        },
        onConfigLoaded() {

        },
        startPulse() {
            let pulse = [{
                action: "machines/loadMachine",
                interval: 3,
                primaryKey: "machine_id",
                parameters: { machine_id: this.machine_id },
            }];
            for (let i = 0; i < injections.length; i++) {
                let injection = injections[i];
                //console.warn("injecting: " + injection);
                switch (injection) {
                    case "planned":
                        pulse.push({
                            action: "orders/loadPlannedOrdersForMachine",
                            interval: 180,
                            name: "planned",
                            parameters: { machine_id: this.machine_id },
                            primaryKey: "machine_id",
                        })
                        break;
                    case "running":
                        pulse.push({
                            action: "orders/loadRunningOrderForMachine",
                            interval: 180,
                            name: "running",
                            parameters: { machine_id: this.machine_id },
                            primaryKey: "machine_id",
                        })
                        break;
                    case "notifications":
                        pulse.push({
                            action: "machines/loadNotificationSettings",
                            name: "notifications",
                            interval: 600,
                            parameters: { machine_id: this.machine_id },
                            primaryKey: "machine_id",
                        })
                        break;
                    default:
                        break;
                }
            }
            this.pulse = new Pulse(pulse);
        },
        stopPulse() {
            this.pulse.stop();
        },
        ...data.methods || {}
    }
    data.methods = methods;


    let dm = data.mounted || function () { return {} };

    data.mounted = function () {
        console.log("interface component mounted");
        dm.bind(this)();
        this.loadData(this.machine_id);
        this.startPulse();
    }

    let dbu = data.beforeUnmount || function () { return {} };

    data.beforeUnmount = function () {
        dbu.bind(this)();
        this.stopPulse();
    }

    let watch = {
        $route(to, from) {
            let machine_id = to.params?.machine;
            if (machine_id !== undefined) {
                console.log("route change, data load");
                this.loadData(machine_id);
                this.stopPulse();
                this.startPulse();
            }
        },
        ...data.watch || {}
    }
    data.watch = watch;

    if (hasConfig) {
        console.log("adding config is loaded listener");
        data.watch.configIsLoaded = function () {
            this.onConfigLoaded();
        }
    }
    let components = {
        CardMenu,
        ...data.components || {}
    };
    data.components = components;

    return injectComputed(data, injections)
}

const injectComputed = function (data, injections) {
    for (let i = 0; i < injections.length; i++) {
        let injection = injections[i];
        switch (injection) {
            case "planned":
                data.computed.plannedOrders = function () {
                    return this.plannedByMachine(this.machine_id)
                };
                break;
            case "running":
                data.computed.runningOrders = function () {
                    return this.runningByMachine(this.machine_id)
                }
                break;
            case "notifications":
                data.computed.notificationSettings = function () {
                    return this.notificationSettingsBymachine(this.machine_id)
                }
                break;
            default:
                break;
        }
    }
    return data;
}

export { InterfaceComponent }