<template>
  <div
    class="col-widgets"
    :class="{
      double: widgets.options[tableIndex]?.double,
      souble: widgets.columns?.length == 3 && widgets.options[1]?.double,
    }"
    v-for="(table, tableIndex) in widgets.columns"
    :key="tableIndex"
  >
    <component
      :class="widgetClass(widgets.widgetConfigs[tableIndex]?.[widget])"
      v-for="(widget, i) in table"
      :key="machine_id + '-' + tableIndex + '-' + i"
      :is="widget"
      :template="'widgets_' + widgetTemplate"
    ></component>
  </div>
</template>
<script>
import * as WidgetComponents from "../../components/widgets";
import * as MustadComponents from "../../components/widgets/company/5103";
import * as EsbeComponents from "../../components/widgets/company/5011";
//testing

export default {
  mounted() {},
  computed: {
    machine_id() {
      return this.$route.params.machine;
    },
  },
  props: {
    widgets: {
      required: true,
    },
    widgetTemplate: {
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      currentPage: 0,
      transition: "slide",
    };
  },
  methods: {
    widgetClass(config) {
      if (config === undefined) {
        return {
          "height-1": true,
        };
      }
      let classes = {
        preferred: config.preferred,
      };

      switch (config.width) {
        case 2:
          classes["col-12 col-xl-6 col-lg-8 col-md-12"] = true;
          break;
        case 3:
          classes["col-12 col-xl-9 col-lg-12 col-md-12"] = true;
          break;
        case 4:
          classes["col-12 col-xl-12 col-lg-12 col-md-12"] = true;
          break;
        default:
          break;
      }

      classes["height-" + config.height] = true;
      classes["width-" + config.width] = true;
      return classes;
    },
  },
  watch: {},
  components: {
    ...WidgetComponents,
    ...MustadComponents,
    ...EsbeComponents,
  },
};
</script>
<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 1s ease;
}
.slide-leave-to {
  transform: translateX(100vw);
  position: absolute;
  width: 100vw;
}
.slide-enter-from {
  transform: translateX(-100vw);
}
.left-slide-enter-active,
.left-slide-leave-active {
  transition: all 1s ease;
}
.left-slide-leave-to {
  transform: translateX(-100vw);
  position: absolute;
  width: 100vw;
}
.left-slide-enter-from {
  transform: translateX(100vw);
}
@media screen and (max-width: 800px) {
  .slide-enter-active,
  .slide-leave-active,
  .left-slide-enter-active,
  .left-slide-leave-active {
    transition: all 0.5s ease;
  }
}
</style>