<template>
  <div>
    <h1>
      {{ $t("3658") }}
      <card-menu :items="items"></card-menu>
    </h1>
    <div v-if="materialOrder">
      <button
        v-if="selectedOrder !== null"
        class="btn align-left"
        @click="selectedOrder = null"
        style="width: 100%"
      >
        <i class="fas fa-arrow-left"> </i> {{ materialOrder.work_order_no }}
      </button>
      <c-table
        :data="materialList"
        config-string="materials_widget"
        primary-key="material_no"
        :colors="colors"
        :single-select="true"
        :deselect="true"
        :teleport="true"
        @selectionChanged="onSelectionChanged"
      >
      </c-table>
    </div>
    <div v-else-if="orders !== null" class="row" style="overflow-y: auto">
      <div class="col-12" v-for="o in orders" :key="o.p_order">
        <button
          class="btn btn-success nowrap"
          @click="selectedOrder = o.record_no"
          style="width: 100%; margin-bottom: 3px; text-align: left"
        >
          {{ o.work_order_no }}: {{ o.part_description }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import store from "@/store";
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      pulse: null,
      interval: 120,
      selectedOrder: null,
      items: [],
    };
  },
  mounted() {
    this.pulse = new Pulse({
      action: "orders/loadRunningOrderForMachine",
      parameters: { machine_id: this.machine_id, reload: false },
      interval: this.interval,
      primaryKey: "machine_id",
    });
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      runningOrders: (state) => state.orders.runningOrders,
    }),
    orders() {
      return this.runningOrders[this.machine_id] || null;
    },
    materialOrder() {
      return this.orders?.length === 1
        ? this.orders[0]
        : (this.selectedOrder
            ? this.orders?.find((o) => o.record_no == this.selectedOrder)
            : null) || null;
    },
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    ...mapGetters({
      //machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      machineById: "machines/byId",
      materialListByRecordNo: "materials/materialListByRecordNo",
    }),
    materialList() {
      return this.materialOrder !== null
        ? this.materialListByRecordNo(this.materialOrder.record_no).filter(
            (r) =>
              !this.hasSf368 || this.machine.line_position == r.line_position
          )
        : null;
    },
    hasSf368() {
      return this.machineHasSf(this.machine_id, "368");
    },
    colors() {
      if (!this.hasSf368) {
        return null;
      } else if (this.materialList !== null) {
        let colors = {};
        this.materialList.forEach((m) => {
          if (m.confirm_material){
            colors[m.material_no] = 'yellow';
          }
        });
        return colors;
      }
      return null;
    },
  },
  watch: {
    materialOrder: {
      deep: true,
      handler: function () {
        this.fetchMaterials();
      },
    },
  },
  methods: {
    fetchMaterials() {
      if (this.materialOrder != null) {
        store
          .dispatch("materials/loadMaterialList", {
            machine: { ...this.materialOrder, machine_id: this.machine_id },
          })
          .then(() => {
            this.loaded = true;
          });
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      console.log(selectedRowsData[0]);
      if (selectedRowsData.length > 0) {
        store.commit("bottomnav/setData", selectedRowsData[0]);
        store.commit("bottomnav/setType", "material-singular");
        store.commit("bottomnav/enable");
      } else {
        store.commit("bottomnav/disable");
      }
    },
  },
  props: {
    template: {
      type: String,
    },
  },
  components: {
    CardMenu,
  },
};
</script>