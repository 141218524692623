<template>
  <div>
    <Loading v-if="loading" />
    <div v-else>
      <numerical-input v-model="quantity"></numerical-input>
      <br>
      <button
      class="btn button"
      @click="$emit('reject')"
    >
      <i class="fas fa-times"></i>
    </button>
    <button
      class="btn button btn-success"
      @click="resolveQuantity"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right"></i>
    </button>
    </div>
     
  </div>
</template>
<script>
import { mapState , mapGetters} from "vuex";
import store from "@/store";
import _ from "lodash";
export default {
  props: ["data"],
  emits: ["reject","resolve"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      machine_id: this.$route.params.machine,  
      selectedRows: [],
      quantity:this.data.presetQuantity || '',
      loading: false,
    };
  },
  mounted() {
  
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
    }),
    ...mapGetters({
      canUser: "permissions/canUser",
    }),
    ordersForMachine() {
      return this.orders[this.machine_id] || null;
    },
    disableNext(){
      return this.quantity == '';
    }
  },
  methods: {
    resolveQuantity(){
       this.$emit('resolve',this.quantity);
    }
  },
  watch: {
    
  },
};
</script>