<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div v-if="machine" class="max-align">
      <h1>
        <!-- 1549 : availability -->
        {{ $t("5816") }} - {{ periodT ? $t(periodT) : "" }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div class="flex has-chart" style="flex: 1">
        <prop-graph
          :data="scrapData"
          :yKeys="['scrap']"
          labelKey="description"
          legendPosition="right"
          colorKey="color"
          type="pie"
          :addLabelsToTooltip="true"
          :redrawIfChanged="true"
        ></prop-graph>
      </div>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import PropGraph from "@/components/PropGraph";
import { Pulse } from "@/helpers/heartbeat";
import { shortDateTime } from "@/helpers/dates";
import { Configuration } from "@/helpers/settings";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      pulse: null,
      interval: 175,
      config: new Configuration(
        "scrap-graph",
        {
          period: "shift",
          unit: "default",
        },
        this.template
      ),
    };
  },
  props: {
    template: {
      type: String,
    },
  },
  beforeUnmount() {},
  methods: {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    periodT() {
      let periods = {
        "24h": "448",
        today: "447",
        shift: "245",
      };
      //return "5";
      return periods[this.config.period] || "-";
    },
    ...mapGetters({
      machineById: "machines/byId",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    scrapData() {
      return [
        { scrap: this.scrapValue, color: "red", description: "scrap" },
        { scrap: this.goodValue, color: "green", description: "good" },
      ];
    },
    scrapKey() {
      if (this.config.period === "shift") {
        if (this.config.unit == "default") {
          return "scrap_qty_shift";
        } else if (this.config.unit == "unit1") {
          return "scrap_qty_shift_altunit1";
        } else if (this.config.unit == "unit2") {
          {
            return "scrap_qty_shift_altunit2";
          }
        }
        return "scrap_qty_shift";
      }
      return "scrap_qty_shift";
    },
    scrapValue() {
      return this.machine[this.scrapKey];
    },
    goodKey() {
      if (this.config.period === "shift") {
        if (this.config.unit == "default") {
          return "good_qty_shift";
        } else if (this.config.unit == "unit1") {
          return "good_qty_shift_altunit1";
        } else if (this.config.unit == "unit2") {
          {
            return "good_qty_shift_altunit2";
          }
        }
        return "good_qty_shift";
      }
      return "good_qty_shift";
    },
    goodValue() {
      return this.machine[this.goodKey];
    },
    items() {
      return [
        { header: this.$t("1329") },
        {
          tlabel: "245",
          handler: () => {
            this.config.setValues({ period: "shift" });
          },
          selected: this.config.period === "shift",
        },
         { header: this.$t("5001") },
        {
          tlabel: "6207",
          handler: () => {
            this.config.setValues({ unit: "default" });
          },
          selected: this.config.unit === "default",
        },
          {
          tlabel: "2734",
          handler: () => {
            this.config.setValues({ unit: "unit1" });
          },
          selected: this.config.unit === "unit1",
        },
          {
          label: this.$t('2734')+" 2",
          handler: () => {
            this.config.setValues({ unit: "unit2" });
          },
          selected: this.config.unit === "unit2",
        },
      ];
    },
    order_finished_formatted_short() {
      return shortDateTime(this.machine.order_finished);
    },
  },
  components: {
    CardMenu,
    PropGraph,
  },
};
</script>