<template>
  <router-link :to="toLink" class="file-link" v-if="hash">
    <div class="file-info">
      <h6>{{ document.document_description || "-" }}</h6>
      <p>{{ document.document_link }}</p>
    </div>
  </router-link>
</template>

<script>
import { shortDateTime } from "@/helpers/dates";
import { digestMessage } from "@/helpers/storage";
import store from "@/store";

export default {
  props: {
    document: {
      required: true,
    },
  },
  data(){
    return {
      hash:null
    }
  },
  mounted(){
    this.digest();
  },
  computed: {
    toLink() {
      return "/interface/"+this.$route.params.machine+"/documents/"+this.hash;
    }
  },
  methods: {
    digest() {
      let result = escape(this.document.document_link);
      this.hash =  result;
      store.commit("files/setHash",{machine_id:this.$route.params.machine,document_link:this.document.document_link,hash:result});
      /*
      digestMessage(this.document.document_link).then((result) => {
        this.hash =  result;
        store.commit("files/setHash",{machine_id:this.$route.params.machine,document_link:this.document.document_link,hash:result});
      });
      */
    },
  },
};
</script>

<style lang="scss">
.file-link {
  display: block;
  padding: 10px;
  border: 1px solid gray;
  display: flex;
  height: 60px;
  text-decoration: none;
  box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.15);
  transition: 0.3s all;
  margin-bottom: 15px;
  &:hover {
    box-shadow: 1px 1px 7px 7px rgba(0, 0, 0, 0.25);
  }
  &.selected{
    background:black;
    .file-info,.file-type i.fas{
      color:white;
    }
  }
  .file-type {
    width: 30px;
    i.fas {
      font-size: 30px;
      color: black;
      line-height: 40px;
      text-align: center;
    }
    margin-right: 10px;
  }
  .file-info {
    flex: 1;
    text-align: left;
    color: black;
    overflow: hidden;
    h6 {
      font-weight: bold;
      margin: 0;
    }
    p {
      margin: 0;
      white-space: nowrap;
    }
  }
}
</style>