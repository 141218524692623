//i18n-setup.js
import { createI18n } from 'vue-i18n';
import { Notification } from '@/helpers/notifications';
import store from '@/store'
import { checkStorage, getStorage } from './helpers/storage';

let n = null;
let loadingLang = null;
let missingKeys = [];

export const i18n = createI18n({
  locale: store.state.auth.lang_code, // set locale
  fallbackLocale: store.state.auth.lang_code,
  availableLocales: ['ENG', 'GER', 'SWE'],
  messages: store.state.languages.messages,
  missing: (locale, key, vm, values) => {
    // handle translation missing
    if (locale === store.state.auth.lang_code && !missingKeys.includes(key)){
      //console.log("custom translation missing",locale,key,vm,values);
      loadLanguageAsync(locale);
      missingKeys.push(key);
      //console.warn(missingKeys);
    }
    return null;
  },
  inheritLocale: false
})


let loadedLanguages = store.state.languages.loadedLanguages;

function setI18nLanguage(lang) {
  i18n.locale = lang;
  if (i18n.global) {
    i18n.global.locale = lang;
  }
  document.querySelector('html').setAttribute('lang', lang)
  console.log(i18n);
  if (n !== null) {
    n.ok();
  }
  return lang
}

window.disableLanguages = function(){
  i18n.missing = (locale,key,vm,values)=> {
    return "--xx--"
  }
  i18n.global.locale = 'disabled';
  i18n.global.fallbackLocale = 'disabled';
  document.querySelector('html').setAttribute('lang', 'disabled')
}
window.enableLanguages = function(){
  i18n.missing = (locale,key,vm,values)=> {
    return "--xx--"
  }
  i18n.global.locale = 'ENG';
  i18n.global.fallbackLocale = 'ENG';
  document.querySelector('html').setAttribute('lang', 'ENG')
}
export function loadLanguageAsync(lang) {
  if (lang !== undefined && lang !== "undefined" && lang !== loadingLang) {
    console.log("loading language > "+lang, missingKeys);
    if (loadingLang !== null && n !== null){
      n.abort();
    }

    // If the same language
    if (i18n.locale === lang) {
      return Promise.resolve(setI18nLanguage(lang))
    }

    n = Notification.request({
      theader:'265',
      message: "loading language " + lang
    });
    loadingLang = lang;

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
      return Promise.resolve(setI18nLanguage(lang))
    }

    return Promise.resolve(
      store.dispatch('languages/loadLanguage', lang)
        .then(() => {
          console.log("language " + lang + " loaded");
          loadingLang = null;
          return setI18nLanguage(lang);
        })
    );
  }else return Promise.resolve();
}

export function loadLanguageFromCache() {
  let language = getStorage("lang_code");
  if (language !== null && checkStorage("lang_cache_" + language)) {
    let data = getStorage("lang_cache_" + language);
    store.commit('languages/setLanguage', { language, data });
  } else if (
    language !== null
  ) {
    loadLanguageAsync(language);
  }
}