<template>
  <div class="popup large">calculate results</div>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapState, mapGetters } from "vuex";
import { secondsToHhMm } from "@/helpers/dates";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";
import { flattenSingleRow } from "@/requests/requestParcer";
import store from "@/store";

export default {
  props: ["data"],
  emits: ["close"],
  data() {
    return {
      employeeNotOk: false,
      loading: true,
      value: null,
     
    };
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
    closeAndSave() {},
    calcSplitTime() {},
    computed: {
      ...mapState({
        inversed: (state) => state.groups.inversed,
        lastUsedStops: (state) => state.stopcodes.lastStopReasons,
      }),
      ...mapGetters({
        machineHasSf: "machines/machineHasSf",
        signedInByMachine: "machines/signedInByMachine",
        configByMachine: "machines/configByMachine",
        stopCodesByMachine: "stopcodes/stopCodesByMachine",
        machineById: "machines/byId",
        runningByMachine: "orders/runningByMachine",
      }),
      machine_id() {
        return this.data.machine_id;
      },
      machine() {
        return this.machineById(this.machine_id);
      },
      enableSearchForStops() {
        return this.filteredStopCodes.length > 10;
      },
      enableSearchForGroups() {
        return this.groupCodes.length > 10;
      },
      stopCodesForMachine() {
        return this.stopCodesByMachine(this.machine_id);
      },
      config() {
        return this.configByMachine(this.machine_id);
      },
      hasSf356() {
        return this.machineHasSf(this.machine_id, "356");
      },
      shouldGroupCodes() {
        return this.config.stopreason_by_group;
      },
    },
  },
};
</script>
<style scoped lang="scss">
.setwidth-125 {
  min-width: 125px;
  display: inline-block;
  @media screen and (max-width: 600px) {
    display: block;
    width: 100%;
  }
}
</style>