<template>
  <div class="machine-singular" v-if="machine">
    <p>{{ machine.machine_id }}</p>
    <div class="buttons-wrap">
      <router-link class="router-button" :to="routerlinks.stopcode">{{
        $t("15")
      }}</router-link>
      <router-link class="router-button" :to="routerlinks.uncoded">{{
        $t("5034")
      }}</router-link>
      <router-link class="router-button" :to="routerlinks.history">{{
        $t("612")
      }}</router-link>
      <router-link
        class="router-button"
        :to="routerlinks.notes"
        v-if="hasSf353"
        >{{ $t("638") }}</router-link
      >
      <router-link
        class="router-button"
        :to="toolIdRoute"
        v-if="toolIdRoute != null"
        ><i class="fas fa-search-minus"></i
      >{{$t('388')}}</router-link>
      <!--
        <router-link class="router-button" :to="routerlinks.interface">{{$t('2660')}}</router-link>
        -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: {},
  setup() {},
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      machine: (state) => state.bottomnav.data[0],
    }),
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
      canUser: "permissions/canUser",
    }),
    machine_id() {
      return this.machine.machine_id;
    },
    hasSf353() {
      return this.machineHasSf(this.machine_id, "353");
    },
    routerlinks() {
      let [empty, viewcode, ...rest] = this.$route.path.split("/");
      if (viewcode === "all-machines" || viewcode === undefined) {
        viewcode = "machines";
      }
      return {
        stopcode:
          "/" +
          viewcode +
          "/" +
          this.$route.params.locationtype +
          "/" +
          this.$route.params.location +
          "/stopcode/" +
          this.machine.machine_id,
        notes:
          "/" +
          viewcode +
          "/" +
          this.$route.params.locationtype +
          "/" +
          this.$route.params.location +
          "/notes/" +
          this.machine.machine_id,
        uncoded:
          "/" +
          viewcode +
          "/" +
          this.$route.params.locationtype +
          "/" +
          this.$route.params.location +
          "/uncoded/" +
          this.machine.machine_id,
        history:
          "/" +
          viewcode +
          "/" +
          this.$route.params.locationtype +
          "/" +
          this.$route.params.location +
          "/history/" +
          this.machine.machine_id,
        interface: "/interface/" + this.machine.machine_id,
      };
    },
    toolIdRoute() {
      if (!this.canUser("can-change-cavities")) {
        return null;
      }
      return !this.machine ||
        this.machine.tool_id == "" ||
        this.machine.tool_id == "-"
        ? null
        : "/cavities/" + this.machine.machine_id;
    },
  },
  components: {
    // DxButton
  },
};
</script>

<style lang="scss">
</style>
