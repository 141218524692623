<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div v-if="machine" class="max-align">
      <h1>
        <!-- 456 : status -->
        {{ $t("614") }} - {{ periodT ? $t(periodT) : "" }}
        <card-menu :items="items" :config="config"></card-menu>
      </h1>
      <div class="flex has-chart" style="flex: 1">
        <graph
          :period="config.period"
          :machine="machine"
          :labelMode="config.labelMode"
          :interval="interval"
          :addRuntime="config.addRuntime"
          :disableLegend="config.disableLegend"
          task="stopreasons_count"
          :type="config.type"
          uses="bar"
          labelStyle="short"
          :animationDuration="10"
        />
      </div>
      <div class="center-widget" v-if="empty">
        {{ $t("5474") }}
      </div>
    </div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import Graph from "@/components/Graph";
import Chart from "chart.js";
import { mapState, mapGetters } from "vuex";
import { getStorage, setStorage } from "@/helpers/storage";
import { Configuration } from "@/helpers/settings";
//import { Pulse } from "@/helpers/heartbeat";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      machine_id: this.$route.params.machine,
      //currentAction: "stops/loadStopreasons24H",
      previousAction: null,
      empty: false,
      ctx: null,
      chart: null,
      drawn: false,
      config: new Configuration(
        this.storageKey,
        {
          maxN: null,
          type: "rawbar",
          period: "7d",
          addRuntime: true,
          disableLegend: true,
          labelMode: "raw",
        },
        this.template
      ),
      // pulse: new Pulse([], {}),
    };
  },
  props: {
    storageKey: {
      required: true,
    },
    template: {
      type: String,
    },
  },
  mounted() {},
  beforeUnmount() {
    //this.pulse.stop();
  },
  methods: {
    toggleLegend() {
      this.disableLegend = !this.disableLegend;
      setStorage(
        "SR_count_widget_" + this.storageKey + "_disable_legend",
        this.disableLegend,
        false
      );
    },
    pieChart() {
      this.type = "pie";
    },
    doughnutChart() {
      this.type = "doughnut";
    },
    barChart() {
      this.type = "bar";
    },

    setLabelsToHours() {
      this.config.labelMode = "hours";
    },
    setLabelsToPercentages() {
      this.config.labelMode = "percentages";
    },

    toggleRuntime() {
      this.config.addRuntime = !this.config.addRuntime;
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      stops: (state) => state.stops,
    }),
    ...mapGetters({ machineById: "machines/byId" }),
    machine() {
      return this.machineById(this.machine_id);
    },
    interval() {
      return ["24h", "today", "shift"].includes(this.config.period)
        ? 180
        : 7200;
    },
    periodT() {
      let periods = {
        "24h": "448",
        today: "447",
        shift: "245",
        week: "914",
        "30d": "450",
      };
      //return "5";
      return periods[this.config.period] || "7d";
    },
    items() {
      return [
        {
          tlabel: "447",
          handler: () => {
            this.config.setValues({ period: "today" });
          },
          selected: this.config.period === "today",
        },
        {
          label: "7d",
            handler: () => {
            this.config.setValues({ period: "7d" });
          },
          selected: this.config.period === "7d",
        },
        {
          label: "30d",
            handler: () => {
            this.config.setValues({ period: "30d" });
          },
          selected: this.config.period === "30d",
        },
        /*
                {
                    tlabel:"697",
                    label:"hour labels",
                    handler:this.setLabelsToHours
                },
                {
                    tlabel:"6204",
                    label:"percentage labels",
                    handler:this.setLabelsToPercentages
                }
                */
      ];
    },
  },
  watch: {
    stopSet: {
      deep: true,
      handler() {
        if (this.chart !== null) {
          // this.chart.destroy();
        }
        //this.checkEmpty();
        //this.drawChart();
      },
    },
  },
  components: {
    CardMenu,
    Graph,
  },
};
</script>