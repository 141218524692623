//import axios from "@/requests/axios";
import { setStorage, getStorage, checkStorage} from "@/helpers/storage";
import _ from 'lodash';

const state = () => ({
    index: [

    ],
    history: [

    ],
    enabled:getStorage("notifications_enabled",false) || '1'
});

// getters
const getters = {
    logCount(state) {
        return state.index.filter((n) => n.event !== null && !n.viewed).length;
    },
    notificationHistory(state,getters,rootState){
        return [...state.index,...state.history].slice(0,100);
    }
}

// actions
const actions = {
    loadHistory({ state, commit, rootState }) {
        let company_id = rootState.auth.company_id;
        if (company_id) {
            commit("loadHistory", company_id);
        }
        return Promise.resolve();
    },
    addNotification({ state, commit, rootState }, note) {
        let company_id = rootState.auth.company_id;
        commit('add', note);
        setStorage(company_id + "_notifications_log", [...state.index,...state.history].slice(0,100));
        return Promise.resolve();
    },
    updateNotification({ state, commit, rootState }, note) {
        let company_id = rootState.auth.company_id;
        commit('update', note);
        setStorage(company_id + "_notifications_log", [...state.index,...state.history].slice(0,100));
        return Promise.resolve();
    },
    checkIfEnabled({state,commit,rootState}){
        //check if local storage has a notifications_enabled on user level CID_UID_notifications_enabled, if so, load it in.
        let company_id = rootState.auth.company_id;
        let user_id = rootState.auth.username.toLowerCase();
        let storagePath = company_id+"_"+user_id+"_notifications_enabled";
        let isEnabled = getStorage(storagePath,false) || '1';
        commit("setNotificationsEnabled",isEnabled);
    },
    enableNotifications({state,commit,rootState},isEnabled){
        let company_id = rootState.auth.company_id;
        let user_id = rootState.auth.username.toLowerCase();
        let storagePath = company_id+"_"+user_id+"_notifications_enabled";
        setStorage(storagePath,isEnabled,false);
        setStorage("notifications_enabled",isEnabled,false);
        commit("setNotificationsEnabled",isEnabled);
    }
}

// mutations
const mutations = {
    allViewed(state) {
        for (let i = 0; i < state.index.length; i++) {
            if (!state.index[i].viewed){
                state.index[i].viewed = true;
                state.index[i].new = true;
                state.index[i].active = false;
            }else{
                state.index[i].new = false;
            }
        }
    },
    revive(state, notification) {
        let i = state.index.findIndex(n => n.id === notification.id);
        state.index[i].active = true;
    },
    add(state, note) {
        const defaults = {
            id: +_.uniqueId(),
            updated: new Date().getTime(),
            selfClosing: true,
            event: null,
            selfUpdating: false,
            active: true,
            viewed: false,
            type: "notification",
            playSound: false,
            state: "default",
            category: "info",
            message: "",
            identifier: "default"
        };
        let n = { ...defaults, ...note };
        state.index.unshift(n);
        //state.history.unshift(n);
        return n;
    },
    update(state, { id, data }) {
        let i = state.index.findIndex(n => n.id === id);
        let currentNote = state.index[i]
        currentNote.updated = new Date().getTime();
        state.index[i] = { ...currentNote, ...data }
    },
    deActivate(state, { id, options }) {
        let i = state.index.findIndex(n => n.id === id);
        state.index[i].active = false;
        if (options && !options.selfClosed) {
            state.index[i].viewed = true;
        }
    },
    loadHistory(state, company_id) {
        state.history = checkStorage(company_id + "_notifications_log") ? getStorage(company_id + "_notifications_log") : [];
        for (let i =0;i<state.history.length;i++){
            state.history[i].historical = true;
            state.history[i].id = _.uniqueId('hnote-');
        }
    },
    setNotificationsEnabled(state,isEnabled){
        state.enabled = isEnabled;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}