
function isFullScreen() {
  return Boolean(
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  );
}

// Make DoFullScreen() reusable by passing the element as a parameter
const DoFullScreen = (el) => {
  // Use a guard clause to exit out of the function immediately
  console.log("going full screen....");
  if (isFullScreen()) return false;
  // Set a default value for your element parameter
  if (el === undefined) el = document.documentElement;
  // Test for the existence of document.fullscreenEnabled instead of requestFullscreen()
  if (document.fullscreenEnabled) {
    return el.requestFullscreen();
  } else if (document.webkitFullscreenEnabled) {
    return el.webkitRequestFullscreen();
  } else if (document.mozFullScreenEnabled) {
    return el.mozRequestFullScreen();
  } else if (document.msFullscreenEnabled) {
    return el.msRequestFullscreen();
  }
}

const tryFullScreen = (el) => {
  try {
    DoFullScreen().then().catch((err) => {
      console.log("caught it..");
    }).finally(() => {
      console.log("finally!");
    });
  } catch (error) {
    console.log("didn't work...");
    return true;
  }
}

const closeFullScreen = (el) => {
  if (document.exitFullscreen) {
    return document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    return document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    return document.webkitCancelFullScreen();
  } else if (document.msExitFullscreen) {
    return document.msExitFullscreen();
  }
}
const tryCloseFullScreen = (el) => {

  closeFullScreen().then().catch((err) => {
    console.log("couldnt cancel full screen");
  }).finally(() => {
    console.log("cancelled Full screen")
  })
}

const toggleFullScreen = () => {
  let doc = window.document;
  let docEl = doc.documentElement;

  let requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen;
  let cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen;

  if (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    try {
      requestFullScreen.call(docEl);
    } catch (error) {
      console.warn("WHYYYYYYY");
    }
  } else {
    try {
      cancelFullScreen.call(doc);
    } catch (error) {

    }
  }
};

export {
  toggleFullScreen,
  DoFullScreen,
  tryFullScreen,
  tryCloseFullScreen
};
