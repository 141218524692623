<template>
  <div class="report-singular">
    <p>
      {{
        report.report_translation
          ? $t(""+report.report_translation)
          : report.report_name
      }}: {{ report.setting_name }}
    </p>

    <div class="buttons-wrap">
      <!-- 3300 -> set stopreason -->
      <a href="#" class="router-button" @click.prevent="generateQuickReport">
        <span v-if="!saving">Generate</span>
        <loading v-else />
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Notification } from "@/helpers/notifications";
import { Steward } from "@/helpers/loading";
import store from "@/store";

export default {
  props: {},
  data() {
    return {
      //Steward:{}
      saving: false,
    };
  },
  setup() {},
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      report: (state) => state.bottomnav.data,
    }),
    routerlinks() {
      return {};
    },
  },
  methods: {
    generateQuickReport() {
      console.log("saving");
      let n = Notification.request({
        message: "report is being ordered",
      });
      if (!this.saving) {
        let s = new Steward();
        this.saving = true;
        //quick report!
        store.dispatch("files/generateQReport", this.report).then(() => {
          s.wait().then(() => {
            this.saving = false;
          });
          n.ok();
        });
      }
    },
  },
  components: {
    //DxButton,
    //DxTextBox
  },
};
</script>

<style lang="scss">
</style>
