<template>
<date-time-seconds v-model="modelValue" @update:modelValue="update" :show-seconds="false" :active="active"></date-time-seconds>
</template>

<script>
import DateTimeSeconds from "./DateTimeSeconds"
export default {
  emits: ["update:modelValue", "next", "blur", "lock"],
  props: {
    modelValue: {
      required: true,
    },
    item: {},
    active: {},
    required: {
      default: false,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  watch: {
   
  },
  methods: {
    
    update(val) {
      this.$emit("update:modelValue",val);
    },
  },
  components: {
    DateTimeSeconds,
  },
};
</script>

<style lang="scss">
.open .v3dp__datepicker {
  height: 320px;
}
</style>