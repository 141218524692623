<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <div v-if="machine && machine.empty !== true">
      <card-menu :items="items" :config="config"></card-menu>
      <div class="flex-grid">
        <div v-for="m in machinesInSelection" :key="m.machine_id">
          {{ m.machine_id }}
          <div class="normal-status-wrap">
            <div
              class="indicator"
              :class="statusses[m.machine_id]"
              v-if="!config.clickable"
            >
              <div v-if="m.status === 'S'" class="vertically-centered-content">
                <!-- m has a stop reason -->
                <h3 class="subheader">
                  {{ m.stop_description }}
                </h3>
                <p class="align-middle">
                  &nbsp;<br />
                  {{ m.calendar_stop_time_duration_hh_mm }}
                </p>
              </div>

              <div v-else>
                <!-- m is going -->
                <h3 class="subheader">
                  {{ $t("786") }}
                </h3>
                <p class="align-middle">
                  {{ runtimePercentages[m.machine_id] }}%<br />
                  {{ runtimes[m.machine_id] }}/{{
                    runtimeStoptimes[m.machine_id]
                  }}
                </p>
              </div>
            </div>
            <router-link
              v-else
              :to="'/interface/' + m.machine_id + '/stopcode'"
              class="indicator"
              :class="statusses[m.machine_id]"
            >
              <div v-if="m.status === 'S'" class="vertically-centered-content">
                <!-- m has a stop reason -->
                <h3 class="subheader">
                  {{ m.stop_description }}
                </h3>
                <p class="align-middle">
                  &nbsp;<br />
                  {{ m.calendar_stop_time_duration_hh_mm }}
                </p>
              </div>

              <div v-else>
                <!-- m is going -->
                <h3 class="subheader">
                  {{ $t("786") }}
                </h3>
                 <p class="align-middle">
                  {{ runtimePercentages[m.machine_id] }}%<br />
                  {{ runtimes[m.machine_id] }}/{{
                    runtimeStoptimes[m.machine_id]
                  }}
                </p>
              </div>
            </router-link>
          </div>
        </div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div v-if="machine && machine.empty === true">Machine does not exist.</div>
  </div>
</template>
<script>
import CardMenu from "@/components/card-menu";
import { Pulse } from "@/helpers/heartbeat";
import { mapState, mapGetters } from "vuex";
import { Notification } from "@/helpers/notifications";
import { secondsToHhMm } from "@/helpers/dates";
import store from "@/store";
import axios from "@/requests/axios";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";
import { Configuration } from "@/helpers/settings";

export default {
  data() {
    return {
      cid: _.uniqueId("w"),
      machine_id: this.$route.params.machine,
      pressedStop: false,
      pressedCancel: false,
      pressedControl: false,
      pulse: null,
      config: new Configuration(
        "group-status",
        {
          selection: "all",
          selectionFilter: "",
          period: "shift",
          clickable: false,
        },
        this.template
      ),
    };
  },
  props: ["template"],
  mounted() {
    this.pulse = new Pulse([
      {
        action: "machines/loadMachines",
        interval: 12,
      },
    ]);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  methods: {
 
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      canUser: "permissions/canUser"
    }),
      canSaveWidgetSettings() {
      return this.canUser("save-widget-settings");
    },
    machinesInSelection() {
      let machines;
      if (this.config.selection === "line") {
        machines = _.sortBy(
          this.machines.filter((m) => m.line_no === this.machine.line_no),
          "line_position"
        );
      } else if (this.config.selection === "all") {
        machines = _.sortBy(this.machines, "machine_id");
      } else if (this.config.selection === "zone") {
        machines = _.sortBy(
          this.machines.filter((m) => m.zone === this.machine.zone),
          "machine_id"
        );
      } else if (this.config.selection === "department") {
        machines = _.sortBy(
          this.machines.filter((m) => m.department === this.machine.department),
          "machine_id"
        );
      } else if (this.config.selection === "category") {
        machines = _.sortBy(
          this.machines.filter((m) => m.category === this.machine.category),
          "machine_id"
        );
      }

      if (this.config.selectionFilter === "") {
        return machines;
      } else {
        try {
          let r = new RegExp(this.config.selectionFilter);
          return machines.filter((m) => m.machine_id.match(r));
        } catch {
          console.log("invalid regex");
          return machines;
        }
      }
    },
  
    machine() {
      return this.machineById(this.machine_id);
    },
 
    statusses() {
      let statusses = {};
      this.machinesInSelection.forEach((m) => {
        let status = "";
        if (m.status === "S") {
          if (m.current_stop_reason === "-") {
            status += "status-uncoded";
          } else {
            status += "status-stop";
          }
        } else {
          status += "status-running";
        }
        status +=
          " c-" +
          (m.stop_reason_color && m.stop_reason_color != "Automatic"
            ? m.stop_reason_color.toLowerCase()
            : "") +
          " ";
        statusses[m.machine_id] = status;
      });
      return statusses;
    },
    runtimes() {
      let runtimes = {};
      this.machinesInSelection.forEach((m) => {
        let runtime = +m.today_runtime;
        if (this.config.period === "shift") {
          runtime = +m.current_shift_runtime;
        }
        runtimes[m.machine_id] = secondsToHhMm(runtime);
      });
      return runtimes;
    },
    runtimeStoptimes() {
      let runtimes = {};
      this.machinesInSelection.forEach((m) => {
        let runtime = +m.today_runtime;
        let stoptime = +m.today_calendar_stoptime;
        if (this.config.period === "shift") {
          runtime = +m.current_shift_runtime;
          stoptime = +m.current_shift_calendar_stop_time;
        }
        runtimes[m.machine_id] = secondsToHhMm(runtime + stoptime);
      });
      return runtimes;
    },
    runtimePercentages() {
      let runtimes = {};
      this.machinesInSelection.forEach((m) => {
        let runtime = +m.today_runtime;
        let stoptime = +m.today_calendar_stoptime;
        if (this.config.period === "shift") {
          runtime = +m.current_shift_runtime;
          stoptime = +m.current_shift_calendar_stop_time;
        }
        runtimes[m.machine_id] = (
          (runtime * 100) /
          (runtime + stoptime)
        ).toFixed(1);
      });
      return runtimes;
    },
    periodT() {
      let periods = {
        h24: "448",
        today: "447",
        shift: "245",
      };
      //return "5";
      return periods[this.config.period];
    },
    items() {
      if (!this.canSaveWidgetSettings){
        return [];
      }
      return [
        {
          tlabel: "899",
          handler: () => {
            this.config.setValues({ clickable: !this.config.clickable });
          },
          selected: this.config.clickable,
        },
        { header: this.$t("480") },
        {
          tlabel: "570",
          handler: () => {
            this.config.setValues({ selection: "all" });
          },
          selected: this.config.selection === "all",
        },
        {
          tlabel: "331",
          handler: () => {
            this.config.setValues({ selection: "zone" });
          },
          selected: this.config.selection === "zone",
        },
        {
          tlabel: "332",
          handler: () => {
            this.config.setValues({ selection: "category" });
          },
          selected: this.config.selection === "category",
        },
        {
          tlabel: "2",
          handler: () => {
            this.config.setValues({ selection: "department" });
          },
          selected: this.config.selection === "department",
        },
        {
          tlabel: "939",
          handler: () => {
            this.config.setValues({ selection: "line" });
          },
          selected: this.config.selection === "line",
        },

        { header: this.$t("1329") },
        {
          tlabel: "447",
          handler: () => {
            this.config.setValues({ period: "today" });
          },
          selected: this.config.period === "today",
        },
        {
          tlabel: "245",
          handler: () => {
            this.config.setValues({ period: "shift" });
          },
          selected: this.config.period === "shift",
        },

        {
          input: "selectionFilter",
          type: "text",
        },
      ];
    },
  },
  components: {
    CardMenu,
  },
};
</script>