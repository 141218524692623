import { flattenMultiRow, flattenSingleRow } from "@/requests/requestParcer";
import { daybreakDate, getMonday, getNow } from "@/helpers/dates";
import axios from "@/requests/axios";
import store from "@/store";

// functions
const shift = {
    handler: "loadAvailabilityShift",
    tlabel: "197",
    labelKey: "trans_hour",
    valueKey: "percentage",
    colorKey: 'colorCode',
};

function loadAvailabilityShift() {
    let greenLimit = +this.machine.bar_green_limit || 90;
    let yellowLimit = +this.machine.bar_yellow_limit || 50;
    let start_date = this.machine.current_shift_start_time;
    return new Promise((resolve, reject) => {
        axios
            .post("balthzar_get_statistics_machine_divided_new_15_2_37", {
                machine_id: this.machine.machine_id,
                choice: 2,
                start_date,
                stop_date: getNow(),
            })
            .then(({ data }) => {
                this.data = flattenMultiRow(data).map(d => {
                    let percentage = (+d.tot_run_time) * 100 / ((+d.tot_run_time) + (+d.tot_stop_time)) || 0;
                    let colorCode = percentage >= greenLimit ? "green" : (percentage >= yellowLimit ? "yellow" : "red");
                    return {
                        ...d,
                        percentage,
                        colorCode
                    }
                });
                resolve();
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

// functions
const today = {
    handler: "loadAvailabilityToday",
    tlabel: "447",
    labelKey: "trans_hour",
    valueKey: "percentage",
    colorKey: 'colorCode',
};

function loadAvailabilityToday() {
    let greenLimit = +this.machine.bar_green_limit || 90;
    let yellowLimit = +this.machine.bar_yellow_limit || 50;
    let d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    let start_date = new Date(d).toBalthzarTime();
    return new Promise((resolve, reject) => {
        axios
            .post("balthzar_get_statistics_machine_divided_new_15_2_37", {
                machine_id: this.machine.machine_id,
                choice: 2,
                start_date,
                stop_date: getNow(),
            })
            .then(({ data }) => {
                this.data = flattenMultiRow(data).map(d => {
                    let percentage = (+d.tot_run_time) * 100 / ((+d.tot_run_time) + (+d.tot_stop_time)) || 0;
                    let colorCode = percentage >= greenLimit ? "green" : (percentage >= yellowLimit ? "yellow" : "red");
                    return {
                        ...d,
                        percentage,
                        colorCode
                    }
                });
                resolve();
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};
const h24 = {
    handler: "loadAvailability24H",
    tlabel: "448",
    labelKey: "trans_hour",
    valueKey: "percentage",
    colorKey: 'colorCode',
};

function loadAvailability24H() {
    let greenLimit = +this.machine.bar_green_limit || 90;
    let yellowLimit = +this.machine.bar_yellow_limit || 50;
    return new Promise((resolve, reject) => {
        axios
            .post("balthzar_get_statistics_machine_divided_new_15_2_37", {
                machine_id: this.machine.machine_id,
                choice: 2,
                start_date: getNow(-1),
                stop_date: getNow(),
            })
            .then(({ data }) => {
                this.data = flattenMultiRow(data).map(d => {
                    let percentage = (+d.tot_run_time) * 100 / ((+d.tot_run_time) + (+d.tot_stop_time)) || 0;
                    let colorCode = percentage >= greenLimit ? "green" : (percentage >= yellowLimit ? "yellow" : "red");
                    return {
                        ...d,
                        percentage,
                        colorCode
                    }
                });
                resolve();
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const week = {
    handler: "loadAvailabilityWeek",
    tlabel: "914",
    labelKey: "trans_hour",
    valueKey: "percentage",
    colorKey: 'colorCode',
};

function loadAvailabilityWeek() {
    let greenLimit = +this.machine.bar_green_limit || 90;
    let yellowLimit = +this.machine.bar_yellow_limit || 50;
    let start_date = getMonday(new Date()).toBalthzarTime();
    let stop_date = new Date().toBalthzarTime();
    return new Promise((resolve, reject) => {
        axios
            .post("balthzar_get_statistics_machine_divided_new_15_2_37", {
                machine_id: this.machine.machine_id,
                choice: 2,
                start_date,
                stop_date,
            })
            .then(({ data }) => {
                this.data = flattenMultiRow(data).map(d => {
                    let percentage = (+d.tot_run_time) * 100 / ((+d.tot_run_time) + (+d.tot_stop_time)) || 0;
                    let colorCode = percentage >= greenLimit ? "green" : (percentage >= yellowLimit ? "yellow" : "red");
                    return {
                        ...d,
                        percentage,
                        colorCode
                    }
                });
                resolve();
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const lastweek = {
    handler: "loadAvailabilityLastWeek",
    tlabel: "2136",
    labelKey: "trans_hour",
    valueKey: "percentage",
    colorKey: 'colorCode',
};

function loadAvailabilityLastWeek() {
    let greenLimit = +this.machine.bar_green_limit || 90;
    let yellowLimit = +this.machine.bar_yellow_limit || 50;
    let start = getMonday(new Date());
    start.setDate(start.getDate() - 7);
    let start_date = start.toBalthzarTime();
    let stop_date = getMonday(new Date()).toBalthzarTime();
    return new Promise((resolve, reject) => {
        axios
            .post("balthzar_get_statistics_machine_divided_new_15_2_37", {
                machine_id: this.machine.machine_id,
                choice: 2,
                start_date,
                stop_date,
            })
            .then(({ data }) => {
                this.data = flattenMultiRow(data).map(d => {
                    let percentage = (+d.tot_run_time) * 100 / ((+d.tot_run_time) + (+d.tot_stop_time)) || 0;
                    let colorCode = percentage >= greenLimit ? "green" : (percentage >= yellowLimit ? "yellow" : "red");
                    return {
                        ...d,
                        percentage,
                        colorCode
                    }
                });
                resolve();
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};


const d30 = {
    handler: "loadAvailability30D",
    tlabel: "450",
    labelKey: "trans_hour",
    valueKey: "percentage",
    colorKey: 'colorCode',
};

function loadAvailability30D() {
    let greenLimit = +this.machine.bar_green_limit || 90;
    let yellowLimit = +this.machine.bar_yellow_limit || 50;
    return new Promise((resolve, reject) => {
        axios
            .post("balthzar_get_statistics_machine_divided_new_15_2_37", {
                machine_id: this.machine.machine_id,
                choice: 2,
                start_date:getNow(-30),
                stop_date:getNow(),
            })
            .then(({ data }) => {
                this.data = flattenMultiRow(data).map(d => {
                    let percentage = (+d.tot_run_time) * 100 / ((+d.tot_run_time) + (+d.tot_stop_time)) || 0;
                    let colorCode = percentage >= greenLimit ? "green" : (percentage >= yellowLimit ? "yellow" : "red");
                    return {
                        ...d,
                        percentage,
                        colorCode
                    }
                });
                resolve();
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};


const graphAvailabilityDataSetup = {
    shift,
    today,
    "24h": h24,
    week,
    lastweek,
    "30d":d30
};

const graphAvailabilityDataFunctions = {
    loadAvailabilityShift,
    loadAvailabilityToday,
    loadAvailability24H,
    loadAvailabilityWeek,
    loadAvailabilityLastWeek,
    loadAvailability30D
};


export { graphAvailabilityDataFunctions, graphAvailabilityDataSetup };