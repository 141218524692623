<template>
  <div>
    <Loading v-if="loading" />
    <div v-else>
      <b-table
      class="centered-b-table"
      :data="signedIn"
      :columnConfig="columnConfig"
      primary-key="record_no"
      :single-select="true"
      :deselect="true"
      @selectionChanged="onSelectionChanged"
    >
    </b-table>

    <numerical-input
      v-model="employeeNumber"
      v-if="isNumeric"
      :class="{ 'not-ok': employeeNotOk }"
      :leading-zeros="true"
    ></numerical-input>
    <div style="width: 100%; max-width: 600px; margin: 0 auto" v-else>
      <input
        type="text"
        class="form-control"
        v-model="employeeNumber"
        :class="{ 'not-ok': employeeNotOk }"
        autofocus
      />
      <keyboard v-model="employeeNumber" v-if="!this.selectedRows.length" />
    </div>
    </div>
       <button
      class="btn"
      @click="$emit('reject')"
    >
      <i class="fas fa-times"></i>
    </button>
      <button
      class="btn btn-success"
      :disabled="
        employeeNumber === null || employeeNumber.trim(' ').length === 0
      "
      @click="resolveEmployeeNumber"
    >
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</template>
<script>
import { mapState , mapGetters} from "vuex";
import store from "@/store";
import _ from "lodash";
export default {
  props: ["data"],
  emits: ["reject","resolve"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      machine_id: this.$route.params.machine,  
       columnConfig: {
        employee_number: {
          label: "#",
          class: "h50",
        },
        employee_name: {
          tlabel: "578",
          class: "h50",
        },
        original_in_time: {
          component: "date",
          tlabel: "3364",
          config: {
            short: true,
          },
        },
      },
      selectedRows: [],
      employeeNumber:'',
      loading: false,
    };
  },
  mounted() {
  
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
    }),
    ...mapGetters({
      canUser: "permissions/canUser",
      signedInByMachine: "machines/signedInByMachine",
      machineHasSf: "machines/machineHasSf",
    }),
    isNumeric() {
      return this.machineHasSf(this.machine_id, "209");
    },
       signedIn() {
      return this.signedInByMachine(this.machine_id);
    },
    ordersForMachine() {
      return this.orders[this.machine_id] || null;
    },
    disableNext(){
      return this.quantity == '';
    }
  },
  methods: {
    resolveEmployeeNumber(){
       this.$emit('resolve',this.employeeNumber);
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length > 0 && this.selectedRows[0]) {
       this.$emit('resolve',this.selectedRows[0].employee_number);
      } 
    },
  },
  watch: {
    
  },
};
</script>