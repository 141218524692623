<template>
  <div class="col-12 col-xl-3 col-lg-4 col-md-6 widget">
    <materials-base ></materials-base>
  </div>
</template>
<script>
import MaterialsBase from "./base/MaterialsBase.vue";

export default {
 components:{
    MaterialsBase,
  },
};
</script>