import { flattenMultiRow, flattenSingleRow } from "@/requests/requestParcer";
import { daybreakDate, getMonday, getNow } from "@/helpers/dates";
import axios from "@/requests/axios";
import _ from "lodash";
import store from "@/store";
import { execute_json } from "../requests";

// functions
const h24 = {
    handler: "loadMachineAnalysis24h",
    labelKey: "machine_id",
    valueKey: "stop_time",
    stackKey: "stop_description",
    colorKey: "stop_reason_color",
    tlabel: "448",
};

function loadMachineAnalysis24h() {
    let req = {
        machine_id_from: "1",
        machine_id_to: "ZZZZZZZZZZZZZZZZ", //should remove when analysis 16_1_77 get's changed
        machine_from: "1",
        machine_to: "ZZZZZZZZZZZZZZZZ",
        department_from: "1",
        department_to: "ZZZZZZZZZZZZZZZZ",
        category_from: "1",
        category_to: "ZZZZZZZZZZZZZZZZ",
        zone_from: "1",
        zone_to: "ZZZZZZZZZZZZZZZZ",
        start_date: getNow(-1),
        stop_date: getNow(),
        shift_from: "1",
        shift_to: "100",
        bolOrder_by_machine_id: true
    };
    req[this.locationtype + "_from"] = this.location;
    req[this.locationtype + "_to"] = this.location;
    return new Promise((resolve, reject) => {
        axios
            .post("balthzar_report_machine_analysis_16_1_77", req)
            .then(({ data }) => {
                let d = flattenMultiRow(data);
                execute_json("get_report_machine_analysis_run_stop_time", {
                    ...req,
                    status: "R",
                    order_by_description: false
                })
                    .then(({ data }) => {
                        let d2 = data;
                        //console.warn(d2);
                        d = [
                            ...d2.map(item => {
                                return {
                                    ...item,
                                    stop_time: item.run_time,
                                    stop_reason: "runtime",
                                    stop_description: "runtime"
                                }
                            }),
                            ...d,
                        ]
                        this.data = d;
                        console.log(this.data);
                        resolve();
                    })
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const d30 = {
    handler: "loadMachineAnalysis30d",
    tlabel: "450",
    labelKey: "machine_id",
    valueKey: "stop_time",
    stackKey: "stop_description",
    colorKey: "stop_reason_color",
};

function loadMachineAnalysis30d() {
    let req = {
        machine_id_from: "1",
        machine_id_to: "ZZZZZZZZZZZZZZZZ", //should remove when analysis 16_1_77 get's changed
        machine_from: "1",
        machine_to: "ZZZZZZZZZZZZZZZZ",
        department_from: "1",
        department_to: "ZZZZZZZZZZZZZZZZ",
        category_from: "1",
        category_to: "ZZZZZZZZZZZZZZZZ",
        zone_from: "1",
        zone_to: "ZZZZZZZZZZZZZZZZ",
        start_date: getNow(-30),
        stop_date: getNow(),
        shift_from: "1",
        shift_to: "100",
        bolOrder_by_machine_id: true
    };
    req[this.locationtype + "_from"] = this.location;
    req[this.locationtype + "_to"] = this.location;
    return new Promise((resolve, reject) => {
        axios
            .post("balthzar_report_machine_analysis_16_1_77", req)
            .then(({ data }) => {
                let d = flattenMultiRow(data);
                execute_json("get_report_machine_analysis_run_stop_time", {
                    ...req,
                    status: "R",
                    order_by_description: false
                })
                    .then(({ data }) => {
                        let d2 = data;
                        //console.warn(d2);
                        d = [
                            ...d2.map(item => {
                                return {
                                    ...item,
                                    stop_time: item.run_time,
                                    stop_reason: "runtime",
                                    stop_description: "runtime"
                                }
                            }),
                            ...d,
                        ]
                        this.data = d;
                        console.log(this.data);
                        resolve();
                    })
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
    });
};

const graphMachineAnalysisDataSetup = {
    "24h": h24,
    "30d": d30,
};

const graphMachineAnalysisDataFunctions = {
    loadMachineAnalysis24h,
    loadMachineAnalysis30d,
};


export { graphMachineAnalysisDataFunctions, graphMachineAnalysisDataSetup };