<template>
  <div>
    <p class="present-value">
      {{ order_finished_formatted_short }}
    </p>
  </div>
</template>

<script>
import { shortDateTime } from "@/helpers/dates";
export default {
  props: ["machine", "order", "planned"],
  computed: {
    order_finished_formatted_short() {
      //return shortDateTime("2016-05-08 15:15");
      return shortDateTime(this.machine.order_finished);
    },
  },
};
</script>

<style>
</style>