import DashboardLayout from '@/layouts/DashboardLayout.vue'
import PopupComponent from "@/components/PopupComponent.vue"

function loadView(view) {
    return () => import(`@/views/${view}.vue`)
}

export const dashboardRoutes = [
    {
        path: "/dashboards/:dashboard",
        name: "dashboards view",
        meta: {
            requiresAuth: true,
            shouldRemember:true,
            layout: DashboardLayout
        },
        component:loadView("dashboards/view"),
        children:[
            {
              name:"dashboard popup",
              path:'popup',
              meta:{
                requiresAuth:true,
              },
              children:[
                {
                    name:"dashboard set stopcode",
                    path:':machine/stopcode',
                    meta:{
                      requiresAuth:true,
                    },
                    component: loadView("stops/stopcode")
                },
                
              ],
              component: PopupComponent
            },
        ]
    }
];
