export class Steward{
    constructor(minLife = 1500){
        this.created_at = new Date().getTime();
        this.minLife = minLife;
    }

    wait(){
        let aliveFor = new Date().getTime() - this.created_at;
        if (aliveFor > this.minLife){
            return Promise.resolve();
        }else{
            let timeLeft = this.minLife - aliveFor;
            return new Promise(resolve => setTimeout(resolve,timeLeft));
        }
    }
}