<template>
  <div class="popup">
    <table class="table table-borderless align-left left">
      <thead>
        <tr>
          <th>{{ $t("1225") }}</th>
          <th>{{ $t("1226") }}</th>
          <th>{{ $t("3370") }}</th>
          <th>{{ $t("3131") }}</th>
          <th>{{ $t("7036") }}</th>
          <th>{{ $t("3961") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="material in materialDataForLine" :key="material.material_no">
          <td>
            {{ material.material_no }}
          </td>
          <td style="width: 100%">{{ material.material_desc || "-" }}</td>
          <td>{{ material.location }}</td>
          <td>{{ material.planned_qty }}</td>
          <td>{{ material.planned_qty / data.order.planned_op_qty || 0 }}</td>
          <td>
            <b-check
              v-if="material.confirm_material"
              v-model="material.confirmed_material"
              :disabled="isConfirmed"
              style="margin: -7px 0 0"
            ></b-check>
            <div v-else>-</div>
          </td>
        </tr>
        <tr v-if="materialDataForLine?.length === 0">
          <td colspan="4">{{ $t("6918") }}</td>
        </tr>
        <tr v-if="loading">
          <td colspan="4">
            <loading />
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn" @click="hide">
      <i class="fas fa-times"></i></button
    >&nbsp;&nbsp;
    <button
      class="btn btn-success"
      @click="close"
      :disabled="!canClose"
      v-if="!isConfirmed"
    >
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapGetters } from "vuex";
import { execute_json } from "@/helpers/requests";
import { flattenSingleRow } from "@/requests/requestParcer";
import store from "@/store";

export default {
  props: ["data"],
  emits: ["close"],
  data() {
    return {
      employeeNotOk: false,
      loading: true,
      value: null,
      materialData: null,
    };
  },
  mounted() {
    this.fetchMaterials();
    let vm = this;
    store.commit("events/addHandler", {
      event: "confirm_materials",
      handler: vm.justClose,
    });
  },
  beforeUnmount() {
    let vm = this;
    store.commit("events/removeHandler", {
      event: "confirm_materials",
      handler: vm.justClose,
    });
  },
  methods: {
    close() {
      console.log(this.data, this.data.order);
      execute_json("workorder_materials", {
        card_no: this.data.order.card_no,
        p_order: this.materialData?.[0]?.p_order,
        confirmed_materials: true,
      }).then(() => {
        let machine_id = this.machine_id;
        let reload = false;
        console.log("closing...");
        /*
        setTimeout(() => {
          store.dispatch("orders/loadRunningOrderForMachine", {
            machine_id,
            reload,
          });
        }, 5000);
        */

        this.$emit("close");
      });
    },
    justClose() {
      console.log("closing...");
      this.$emit("close");
    },
    hide() {
      let machine_id = this.machine_id;
      let reload = false;
      setTimeout(
        (() => {
          store.dispatch("orders/loadRunningOrderForMachine", {
            machine_id,
            reload,
          });
        }).bind(this),
        15 * 1000
      );
      this.justClose();
    },
    handleEnter() {},
    fetchMaterials() {
      if (this.data.order) {
        store
          .dispatch("materials/loadMaterialList", {
            machine: this.runningOrder,
          })
          .then((data) => {
            console.log("ehhh...");
            if (data !== null && data.length === 0) {
              this.$emit("close");
            }
            console.log(data);
            this.materialData = data.map((m) => {
              return {
                confirmed_material: false,
                ...m,
              };
            });
            this.loading = false;
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
      signedInByMachine: "machines/signedInByMachine",
      machineById: "machines/byId",
      runningByMachine: "orders/runningByMachine",
    }),
    machine_id() {
      return this.data.machine_id || this.data.order?.machine_id;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    runningOrders() {
      return this.runningByMachine(this.machine_id);
    },
    isConfirmed() {
      return this.runningOrder.confirmed_materials !== false;
    },
    runningOrder() {
      let order = this.data.order;
      let runningOrder = this.runningOrders.filter(
        (o) => order.card_no === o.card_no && order.op_no === o.op_no
      );
      return runningOrder?.length > 0 ? runningOrder[0] : this.data.order;
    },
    materialDataForLine() {
      return this.materialData
        ? this.materialData
            .filter((m) => m.line_position === this.machine.line_position)
            .sort((a, b) => a.line_no - b.line_no)
        : null;
    },
    canClose() {
      return !this.materialDataForLine?.some(
        (m) => !m.confirmed_material && m.confirm_material
      );
    },
  },
  watch: {},
};
</script>