<template>
  <router-link
    v-if="loaded && uncodedStops !== null"
    class="btn float-start"
    type="button"
    id="uncodedStopsDirectLink"
    :class="{ red: uncodedStops > 0, mine: this.loctype == 'mine' }"
    :to="to"
  >
    {{ uncodedStops }}
  </router-link>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";
import _ from "lodash";

export default {
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    this.backgroundLoadUncoded();
  },
  methods: {
    backgroundLoadUncoded() {
      if (this.validLoc) {
        store
          .dispatch("machines/loadUncodedForLocation", {
            request_type: this.$route.params.locationtype,
            request_code: this.$route.params.location,
          })
          .then(() => {
            this.loaded = true;
          });
      }
    },
  },
  computed: {
    ...mapState({
      uncodedLocation: (state) => state.machines.uncodedLocation,
    }),
    loctype() {
      return this.$route.params.locationtype;
    },
    loc() {
      return this.$route.params.location;
    },
    validLoc() {
      return (
        this.loctype &&
        this.loctype != "undefined" &&
        this.loc &&
        this.loc != "undefined" &&
        this.$route.path.indexOf("uncoded-location") === -1
      );
    },
    uncodedStops() {
      return this.loaded &&
        this.validLoc &&
        (this.uncodedLocation[this.loctype][this.loc] || false)
        ? this.uncodedLocation[this.loctype][this.loc].length
        : null;
    },
    to() {
      return `/machines/${this.loctype}/${this.loc}/uncoded-location`;
    },
  },
  watch: {
    $route(to) {
      //store.dispatch('machines/randomCycles');
      this.loaded = false;
      this.backgroundLoadUncoded();
    },
  },
};
</script>

<style lang="scss">
#uncodedStopsDirectLink {
  position: absolute;
  left: 10px;
  top: 35px;
  height: 30px;
  width: 32px;
  border-radius: 15px;
  font-size: 12px;
  padding: 0;
  line-height: 30px;
  text-align: center;
  color: white;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
  &:before {
    position: absolute;
    font-family: "Font Awesome 5 Pro";
    top: 0;
    left: 0px;
    z-index: -1;
    padding-right: 10px;
    font-weight: 900;
    content: "\f256";
    color: green;
    font-size: 30px;
    background: transparent;
    -webkit-appearance: none;
  }
  &.red {
    &:before {
      color: red;
    }
  }
  &.mine {
    display: none;
  }
}
</style>