<template>
  <div>
    <Loading v-if="loading" />
    <div v-else>
      <c-table
        :data="items"
        primary-key="part_no"
        config-string="t_articles"
        @selectionChanged="onSelectionChanged"
        :searchable="true"
        searchfield="search_field"
        :teleport="true"
      >
      </c-table>
    </div>
    <button
      class="tabbed-next-button"
      @click="$emit('next')"
      :disabled="disableNext"
      :class="{ disabled: disableNext }"
    >
      <i class="fas fa-chevron-right" v-if="!islast"></i>
      <i class="fas fa-check" v-if="islast"></i>
    </button>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import axios from "@/requests/axios";
import { flattenMultiRow } from "@/requests/requestParcer";
import { execute_json } from "@/helpers/requests";
import _ from "lodash";
export default {
  props: ["data", "tab", "islast"],
  emits: ["next"],
  data() {
    return {
      cid: _.uniqueId("tb-"),
      machine_id: this.$route.params.machine,
      selectedRows: [],
      items: [],
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    execute_json("search_part", {
      machine_id: this.$route.params.machine,
    })
      .then(({ data }) => {
        this.items = data;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  beforeUnmount() {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      orders: (state) => state.orders.runningOrders,
    }),
    ...mapGetters({
      columnConfigByTemplate: "config/columnConfigByTemplate",
      canUser: "permissions/canUser",
    }),
    ordersForMachine() {
      return this.orders[this.machine_id] || null;
    },
    disableNext() {
      return this.data[this.tab.dataKey].selected === null;
    },
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      if (this.selectedRows.length > 0 && this.selectedRows[0]) {
        this.data[this.tab.dataKey].selected = this.selectedRows[0];
      } else {
        this.data[this.tab.dataKey].selected = null;
      }
    },
  },
  watch: {},
};
</script>