<template>
  <div id="file-index">
    <Loading v-if="!loaded" />
    <div v-else>
      <h3 v-if="data.order.selected">order: {{data.order.selected.work_order_no}}</h3>
      <pdf-viewer :blob="blob" v-if="isPdf"></pdf-viewer>
      <a
        :href="ObjectUrl"
        :download="this.data.documents.value"
        target="_blank"
        v-if="!isPdf && ObjectUrl"
        >download</a
      >
    </div>
  </div>
</template>

<script>
import DocumentLink from "@/components/DocumentLink";
import { Notification } from "@/helpers/notifications";
import { digestMessage } from "@/helpers/storage";
import { mapState, mapGetters } from "vuex";
import { flattenMultiRow } from "@/requests/requestParcer";
import store from "@/store";
import _ from "lodash";
import axios from "@/requests/axios";

export default {
  data() {
    return {
      loaded: false,
      path: null,
    };
  },
  props: ["data", "tab", "islast"],
  mounted() {
    this.load();
  },
  methods: {
    load() {
      store
        .dispatch("files/loadFileByPath", {
          hash: this.data.documents.hash,
          path: this.data.documents.value,
        })
        .then(() => {
          this.loaded = true;
        })
        .catch((err) => {
          let n = Notification.failed(err.statusText);
        });
    },
  },
  computed: {
    ...mapState({
      bottomnav: (state) => state.bottomnav,
      selections: (state) => state.selections,
      documentIndex: (state) => state.files.documents,
      fetched: (state) => state.files.fetched,
      orders: (state) => state.orders.runningOrders,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    order() {
      return this.orders[this.machine_id] || [];
    },
    
    machine() {
      return this.machineById(this.machine_id);
    },
    files() {
      return this.documentIndex[this.machine_id] || [];
    },
    fileData() {
      return this.fetched[this.data.documents.hash];
    },
    fileType() {
      const regex = /(\.[a-z]*)$/m;
      let m;
      if ((m = regex.exec(this.data.documents.value)) !== null) {
        return m[0];
      }
      return null;
    },
    isPdf() {
      return this.fileType === ".pdf";
    },
    blob() {
      return this.isPdf
        ? "data:application/pdf;base64," + this.fileData.file_data
        : this.rawBlob;
    },
    rawBlob() {
      if (!this.fileData || this.fileData.file_data == undefined) {
        return null;
      }
      var byteCharacters = atob(this.fileData.file_data);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: this.fileType });
      return file;
    },
    documentvars() {
      return {
        machine_id: this.machine_id,
        part_no: this.selectedOrder.part_no,
        tool_id: this.selectedOrder.tool_id,
        part_type_id: this.selectedOrder.part_type_id,
      };
    },
    selectedOrder() {
      if (
        ["running-order-singular", "planned-order-singular"].includes(
          this.bottomnav.type
        )
      ) {
        return this.selections.order || {};
      }

      return {};
    },
    documentvars() {
      return {
        machine_id: this.machine_id,
        card_no: this.machine.card_no,
        part_no: this.selectedOrder.part_no,
        tool_id: this.selectedOrder.tool_id,
        part_type_id: this.selectedOrder.part_type_id,
      };
    },
    ObjectUrl() {
      if (this.fileData && this.blob !== null) {
        //
        //return this.blob;
        return URL.createObjectURL(this.blob);
      }
    },
  },
  watch: {
    $route() {
      //this.loadFiles();
    },
  },
  components: {
    DocumentLink,
  },
};
</script>

<style>
</style>