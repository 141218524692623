import settingsLayout from '@/layouts/SettingsLayout'

function loadView(view) {
  return () => import(/* webpackChunkName: "[request]" */ `@/views/${view}.vue`)
}

export const settingRoutes = [
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      requiresAuth: true,
      layout: settingsLayout
    },
    component: loadView("Settings")
  },
  
]

