<template>
  <div class="popup" :class="{ large: step === 1 }">
    <h3>order: {{ data.work_order_no }}</h3>
    <div v-if="step === 0">
      <!-- Order documents -->

      <loading v-if="!documentsLoaded" />
      <div class="row" v-else>
        <div
          v-for="document in files"
          :key="document.document_link"
          class="col-12 col-xl-12 col-sm-12 file-link"
          :class="{ selected: selectedHash == document.hash }"
          @click="
            selectedDocument = document;
            selectedHash = document.hash;
            step++;
            load();
          "
        >
          <div class="file-info">
            <h6>{{ document.document_description || "-" }}</h6>
            <p>{{ document.document_link }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 1">
      <!-- document showing -->
      <Loading v-if="!loaded" />
      <div v-else>
        <pdf-viewer :blob="blob" v-if="isPdf"></pdf-viewer>
        <a
          :href="ObjectUrl"
          :download="file.document_link"
          target="_blank"
          v-if="!isPdf && ObjectUrl"
          >download</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/requests/axios";
import { Notification } from "@/helpers/notifications";
import { mapGetters, mapState } from "vuex";
import { flattenSingleRow } from "@/requests/requestParcer";
import materialLines from "@/components/MaterialLines";
import _ from "lodash";
import { execute_json } from "@/helpers/requests";
import store from "@/store";

export default {
  props: ["data"],
  emits: ["close"],
  data() {
    return {
      settings: null,
      documentsLoaded: false,
      loaded: false,
      selectedDocument: null,
      selectedHash: null,
      step: 0,
    };
  },
  mounted() {
    this.loadFiles();
  },
  methods: {
    handleEnter() {},
    deActivate() {
      this.$emit("close");
    },
    loadFiles() {
      if (this.machine_id) {
        this.documentsLoaded = false;
        store.dispatch("files/loadDocuments", this.documentvars).then(() => {
          this.documentsLoaded = true;
        });
      }
    },
    load() {
      console.warn(this.selectedDocument);
      store
        .dispatch("files/loadFileByPath", {
          hash: this.selectedHash,
          path: this.selectedDocument.document_link,
        })
        .then(() => {
          this.loaded = true;
        })
        .catch((err) => {
          let n = Notification.failed(err.statusText);
        });
    },
  },
  computed: {
    ...mapState({
      groups: (state) => state.messagegroups.list,
      documentIndex: (state) => state.files.documents,
      fetched: (state) => state.files.fetched,
    }),
    ...mapGetters({
      notificationSettingsBymachine: "machines/notificationSettingsBymachine",
    }),
    machine_id() {
      return this.data.machine_id;
    },

    files() {
      return this.documentIndex[this.machine_id] || [];
    },
    fileData() {
      return this.fetched[this.selectedHash];
    },
    documentvars() {
      return {
        machine_id: this.machine_id,
        card_no: this.data.card_no,
        part_no: this.data.part_no,
        tool_id: this.data.tool_id,
        part_type_id: this.data.part_type_id,
      };
    },
    fileType() {
      const regex = /(\.[a-z]*)$/m;
      let m;
      if ((m = regex.exec(this.selectedDocument.document_link)) !== null) {
        return m[0];
      }
      return null;
    },
    isPdf() {
      return this.fileType === ".pdf";
    },
    blob() {
      return this.isPdf
        ? "data:application/pdf;base64," + this.fileData.file_data
        : this.rawBlob;
    },
    rawBlob() {
      if (!this.fileData || this.fileData.file_data == undefined) {
        return null;
      }
      var byteCharacters = atob(this.fileData.file_data);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: this.fileType });
      return file;
    },
  },
  watch: {},
  components: {
    materialLines,
  },
};
</script>