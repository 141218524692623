<template>
    <div class="gauge-wrap">
        <gauge :value="value" :limits="limits" :limitStyle="limitStyle"></gauge>
        <div class="gauge-overlay">
            <div class="gauge-value">{{value}}</div>
            <div class="gauge-label">{{tlabel? $t(tlabel) : label}}</div>
        </div>
    </div>
</template>
<script>
import Gauge from '@/components/Gauge'
export default {
    props:{
        value:{
            required:true
        },
        limits:Array,
        limitStyle:{
            default:'linear'
        },
        label:String,
        tlabel:String
    },
    data(){
        return {
        }
    },
    methods:{
        
    },
    computed:{
        color(){
            let color = "red";
            for (let i = this.tLimits.length-1;i>=0;i--){
                console.log(i,this.tLimits[i],+this.ivalue);
                if (+this.ivalue >= this.tLimits[i].limit){
                    color = this.tLimits[i].color;
                    return color;
                    break;
                }
            }
            return color;
        }
    },
    watch:{
    },
    components:{
        Gauge
        }
}
</script>